/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

/* include the style for this section */
import '../../style/SupportView.css';
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import EditorSection from '../common/EditorSection';
import StandardButton from '../common/StandardButton';
import SubNavigationList from '../common/SubNavigationList';

/**
 * Class that provides information on support
 * and help options for the user with links to
 * the various documentations as well as the 
 * contact options
 */
class SupportView extends React.Component {
    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount() {
        Culture.attachCultureComponent(this);
    }

    /**
     * Renders the component with the support
     * and help options available to users
     */
    render() {
        return (
            <>
                <ContentHeaderMenu title={Culture.getText('SUPPORT_TITLE')}
                    subtitle={Culture.getText('SUPPORT_SUBTITLE')} />
                <div className="SupportView">
                    <div className="SupportViewContainer">
                        <SubNavigationList>
                            <section code="support" name={Culture.getText('SUPPORT_HELPDESK_TITLE')}>
                                <EditorSection title={Culture.getText('SUPPORT_HELPDESK_TICKET_TITLE')}
                                    subtitle={Culture.getText('SUPPORT_HELPDESK_TICKET_SUBTITLE')}
                                    helpText={Culture.getText('SUPPORT_HELPDESK_TICKET_HELPTEXT')}
                                    introText={Culture.getText('SUPPORT_HELPDESK_TICKET_INTROTEXT')}>
                                    <div className="SupportOptionList">
                                        <div className="SupportOption" data-code="email">
                                            {Culture.getText('SUPPORT_HELPDESK_TICKET_EMAILADDRESS')}
                                        </div>
                                    </div>
                                </EditorSection>

                                <EditorSection title={Culture.getText('SUPPORT_HELPDESK_PHONE_TITLE')}
                                    subtitle={Culture.getText('SUPPORT_HELPDESK_PHONE_SUBTITLE')}
                                    helpText={Culture.getText('SUPPORT_HELPDESK_PHONE_HELPTEXT')}
                                    introText={Culture.getText('SUPPORT_HELPDESK_PHONE_INTROTEXT')}>
                                    <div className="SupportOptionList">
                                        <div className="SupportOption" data-code="phone">
                                            {Culture.getText('SUPPORT_HELPDESK_PHONE_US')}
                                        </div>
                                        <div className="SupportOption" data-code="phone">
                                            {Culture.getText('SUPPORT_HELPDESK_PHONE_EU')}
                                        </div>
                                    </div>
                                </EditorSection>
                            </section>
                            <section code="manual" name={Culture.getText('SUPPORT_MANUAL_TITLE')}>
                                <EditorSection title={Culture.getText('SUPPORT_DOCUMENTATION_TITLE')}
                                    subtitle={Culture.getText('SUPPORT_DOCUMENTATION_SUBTITLE')}
                                    helpText={Culture.getText('SUPPORT_DOCUMENTATION_HELPTEXT')}
                                    introText={Culture.getText('SUPPORT_DOCUMENTATION_INTROTEXT')}>
                                    <div className="SupportOptionList">
                                        <div className="SupportOption" data-code="documentation"
                                            onClick={() => window.open(Culture.getText('SUPPORT_DOCUMENTATION_URL'))}>
                                            {Culture.getText('SUPPORT_DOCUMENTATION_DOMAIN')}
                                        </div>
                                    </div>
                                </EditorSection>
                                <EditorSection title={Culture.getText('SUPPORT_TUTORIAL_TITLE')}
                                    subtitle={Culture.getText('SUPPORT_TUTORIAL_SUBTITLE')}
                                    helpText={Culture.getText('SUPPORT_TUTORIAL_HELPTEXT')}
                                    introText={Culture.getText('SUPPORT_TUTORIAL_INTROTEXT')}>
                                    <div className="SupportOptionList">
                                        <div className="SupportOption" data-code="youtube"
                                            onClick={() => window.open(Culture.getText('SUPPORT_YOUTUBE_URL'))}>
                                            {Culture.getText('SUPPORT_YOUTUBE_TITLE')}
                                        </div>
                                    </div>
                                </EditorSection>
                                <EditorSection title={Culture.getText('SUPPORT_SOCIALMEDIA_TITLE')}
                                    subtitle={Culture.getText('SUPPORT_SOCIALMEDIA_SUBTITLE')}
                                    helpText={Culture.getText('SUPPORT_SOCIALMEDIA_HELPTEXT')}
                                    introText={Culture.getText('SUPPORT_SOCIALMEDIA_INTROTEXT')}>
                                    <div className="SupportOptionList">
                                        <div className="SupportOption" data-code="linkedin"
                                            onClick={() => window.open(Culture.getText('SUPPORT_LINKEDIN_URL'))}>
                                            {Culture.getText('SUPPORT_LINKEDIN_TITLE')}
                                        </div>
                                        <div className="SupportOption" data-code="instagram"
                                            onClick={() => window.open(Culture.getText('SUPPORT_INSTAGRAM_URL'))}>
                                            {Culture.getText('SUPPORT_INSTAGRAM_TITLE')}
                                        </div>
                                        <div className="SupportOption" data-code="facebook"
                                            onClick={() => window.open(Culture.getText('SUPPORT_FACEBOOK_URL'))}>
                                            {Culture.getText('SUPPORT_FACEBOOK_TITLE')}
                                        </div>
                                        <div className="SupportOption" data-code="reddit"
                                            onClick={() => window.open(Culture.getText('SUPPORT_REDDIT_URL'))}>
                                            {Culture.getText('SUPPORT_REDDIT_TITLE')}
                                        </div>
                                    </div>
                                </EditorSection>
                            </section>
                        </SubNavigationList>

                        {true === false &&
                        
                        <div className="SupportOptionList">
                            <div className="SupportOption" data-code="email">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_EMAIL')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_EMAIL')}
                                </div>

                                <StandardButton className="SupportEmailButton" 
                                    text={Culture.getText('SUPPORT_ACTION_SENDEMAIL')}
                                    onClick={() => {
                                        window.open('mailto:support@lodgea.com');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="phone">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_PHONE_EU')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_PHONE_EU')}
                                </div>

                                <StandardButton className="SupportPhoneButton"
                                    text={Culture.getText('SUPPORT_ACTION_CALLPHONE_EU')}
                                    onClick={() => {
                                        window.open('tel:+4989244199410');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="phone">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_PHONE_US')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_PHONE_US')}
                                </div>

                                <StandardButton className="SupportPhoneButton" 
                                    text={Culture.getText('SUPPORT_ACTION_CALLPHONE_US')}
                                    onClick={() => {
                                        window.open('tel:+16503860777');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="youtube">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_YOUTUBE')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_YOUTUBE')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_VISITYOUTUBE')}
                                    onClick={() => {
                                        window.open('https://www.youtube.com/channel/UCZzBGtv0kRcFrz5K5fiJDRw');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="documentation">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_DOCUMENTATION')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_DOCUMENTATION')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_OPENDOCUMENTATION')}
                                    onClick={() => {
                                        window.open('https://docs.lodgea.com');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="connectivitydocumentation">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_CONNECTIVITYDOCUMENTATION')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_CONNECTIVITYDOCUMENTATION')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_OPENCONNECTIVITYDOCUMENTATION')}
                                    onClick={() => {
                                        window.open('https://docs.lodgea.com/connectivity/opentravel-api.html');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="facebook">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_FACEBOOK')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_FACEBOOK')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_VISITFACEBOOK')}
                                    onClick={() => {
                                        window.open('https://www.facebook.com/LODGEA');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="instagram">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_INSTAGRAM')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_INSTAGRAM')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_VISITINSTAGRAM')}
                                    onClick={() => {
                                        window.open('https://www.instagram.com/lodgeacom/');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="linkedin">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_LINKEDIN')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_LINKEDIN')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_VISITLINKEDIN')}
                                    onClick={() => {
                                        window.open('https://www.linkedin.com/company/lodgea/');
                                    }} />
                            </div>

                            <div className="SupportOption" data-code="reddit">
                                <div className="SupportOptionTitle">
                                    {Culture.getText('SUPPORT_OPTIONTITLE_REDDIT')}
                                </div>
                                <div className="SupportOptionText">
                                    {Culture.getText('SUPPORT_OPTIONTEXT_REDDIT')}
                                </div>

                                <StandardButton text={Culture.getText('SUPPORT_ACTION_POSTSUBREDDIT')}
                                    onClick={() => {
                                        window.open('https://www.reddit.com/r/LODGEA/');
                                    }} />
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default SupportView;