/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ApiClient from '../../class/ApiClient';

import ManagementUser from '../../class/ManagementUser';

import '../../style/InventoryEditor.css';

import InventoryEditorHeader from './InventoryEditorHeader';
import InformationEditor from './InformationEditor';
import LoadingIndicator from '../common/LoadingIndicator';
import LocationEditor from './LocationEditor';
import FacilityEditor from './FacilityEditor';
import PropertyServiceEditor from './PropertyServiceEditor';
import PolicyEditor from './PolicyEditor';
import MultiMediaEditor from './MultiMediaEditor';
import AvailabilityEditor from './AvailabilityEditor';
import InventoryConnectivity from './InventoryConnectivity';

/**
 * The Inventory Editor allows editing properties, their
 * rates, inventory, media and all other data items 
 * associated with the property content
 */
class InventoryEditor extends React.Component {
    state = {
        activeSection: 'Information',
        versionId: '',
        property: null,
        productList: [],
        languageList: [],
        snychronised: true,
        postPending: false,
        syncFailed: false
    }

    /**
     * Mounts the component and initially loads 
     * the property to be edited in this session
     */
    componentDidMount(){
        /* initially fetch the property */
        this.fetchProperty();
    }

    /**
     * Fetches the property from the api
     * and sets it in the state
     */
    async fetchProperty(){
        /* fetch the property for the provided version */
        let versionId = this.state.versionId;
        this.setState({
            activeSection: 'Information',
            versionId: versionId,
            property: null,
            languageList: [],
            snychronised: true,
            postPending: false,
            syncFailed: false
        })

        /* retrieve the property object from the api */
        let propertyItem = await ApiClient.execute('/inventory/property/get',{
            versionId: this.state.versionId,
            propertyId: this.props.property
        });

        /* ensure the retrieved property content is valid */
        if(propertyItem !== null){
            if(typeof propertyItem.property === 'object'
                && propertyItem.property !== null
                && Array.isArray(propertyItem.productList) === true){
                /* set the property to the state */
                this.setState({
                    property: propertyItem.property,
                    productList: propertyItem.productList,
                    languageList: ManagementUser.getCurrent().getTenantLanguageList()
                });
            }
        }        
    }

    /**
     * This method posts the property to the api
     * and therewith stores it on the server-side
     */
    async postProperty(){
        /* set the indicator for the process */
        this.setState({postPending: true});

        /* execute posting the property */
        let updateResult = await ApiClient.execute('/inventory/property/update',{
            propertyId: this.props.property,
            content: this.state.property,
            productList: this.state.productList
        });

        if(updateResult === null){
            this.setState({
                snychronised: false,
                postPending: false, 
                syncFailed: true
            });
        }else{
            if(updateResult.updated === true){
                /* set the updated information and 
                    reset the state indicators */
                this.setState({
                    /* reset the version id */
                    versionId: '',
                    snychronised: true,
                    postPending: false, 
                    syncFailed: false, 
                    property: updateResult.property
                });
            }else{
                /* set the state indicators to failed */
                this.setState({
                    snychronised: false,
                    postPending: false, 
                    syncFailed: true
                });
            }
        }
    }

    /**
     * Fetch the requested property version
     * and set the property state to unsynced
     * as the previous version is different to
     * the latest one and this allows the user
     * to quickly revert to the previous one
     * 
     * @param {string} versionId
     * id of the version to revert back to 
     */
    async fetchPropertyVersion(versionId){
        this.setState({versionId: versionId},() => {
            this.fetchProperty().then(() => {
                this.setState({snychronised: false});
            });
        });
    }

    /**
     * Updates the property in the state and 
     * sets the indicator to define that the
     * property is out of sync with the api
     * 
     * @param {object} value
     * property object to update in state 
     * 
     * @param {boolean} postImmediately
     * if true, will immediately post the
     * property to the endpoint and update
     * it on the remote
     */
    updatePropertyState(value,postImmediately){
        /* update the state */
        this.setState({property: value, snychronised: false},function(){
            /* post the property immediately if requested */
            if(postImmediately === true){ this.postProperty(); }
        });
    }

    /**
     * Updates the product list with all
     * rates, prices and availability
     * 
     * @param {array} value
     * the array with the products 
     */
    updateProductList(value){
        this.setState({productList: value, snychronised: false});
    }

    /**
     * Returns the currently active editor
     * section component as a result
     */
    renderSection(){
        let result = <div>{this.state.activeSection}</div>;

        let sectionList = {
            'Information': <InformationEditor property={this.state.property} 
                    languageList={this.state.languageList}
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />,

            'Policy': <PolicyEditor property={this.state.property} 
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />,

            'Location': <LocationEditor property={this.state.property} 
                    languageList={this.state.languageList}
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />,

            'Facility': <FacilityEditor property={this.state.property}
                    languageList={this.state.languageList}
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />,

            'Service': <PropertyServiceEditor property={this.state.property}
                    languageList={this.state.languageList}
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />,

            'Multimedia': <MultiMediaEditor property={this.state.property} 
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />,

            'Availability': <AvailabilityEditor property={this.state.property}
                    productList={this.state.productList}
                    onProductListUpdated={this.updateProductList.bind(this)} />,

            'Connectivity': <InventoryConnectivity property={this.state.property}
                    languageList={this.state.languageList}
                    onPropertyUpdated={this.updatePropertyState.bind(this)} />
        }

        if(sectionList.hasOwnProperty(this.state.activeSection)){
            result = sectionList[this.state.activeSection];
        }

        return result;
    }

    /**
     * Renders the property editor with the
     * header and the sub-sections of the editor
     */
    render(){
        if(this.state.property === null){
            return(
                <LoadingIndicator />
            );
        }else{
            return(
                <div className="InventoryEditor">
                    <InventoryEditorHeader activeItem={this.state.activeSection} 
                        onNavigation={(value) => this.setState({activeSection: value})}
                        property={this.state.property} 
                        productList={this.state.productList}
                        isSynchronised={this.state.snychronised}
                        isSyncFailed={this.state.syncFailed}
                        isPostPending={this.state.postPending}
                        onRequestSave={this.postProperty.bind(this)}
                        onRequestReload={this.fetchProperty.bind(this)}
                        currentVersionId={this.state.versionId}
                        onVersionSelected={this.fetchPropertyVersion.bind(this)}
                        onClose={() => {
                            /* request the closing of the editor through the parent component */
                            if(typeof this.props.onClose === 'function'){
                                this.props.onClose();
                            }
                        }} />
                    <div className="InventoryEditorSection">
                        {this.renderSection()}
                    </div>
                </div>
            );
        }
    }
}

export default InventoryEditor;