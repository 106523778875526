/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/**
 * Represents a quotation and provides method
 * to get information of the quotation object
 */
class Quotation {
    /**
     * Constructs the quotation object and
     * assign the provided content locally
     * 
     * @param {object} content
     * the content of the quotation 
     */
    constructor(content){
        if(typeof content === 'object' && content !== null){
            Object.assign(this,content);
        }
    }

    /**
     * Returns the earliest check-in time
     * of the quotation policy or returns
     * false if none is defined
     */
    getEarliestCheckInTime(){
        let result = false;

        if(typeof this.policy === 'object' && this.policy !== null){
            if(typeof this.policy.checkInTime === 'number' && this.policy.checkInTime !== 0){
                result = this.policy.checkInTime;
            }
        }

        return result;
    }

    /**
     * Returns the latest check-out time
     * of the quotation policy or returns
     * false if none is defined
     */
     getLatestCheckOutTime(){
        let result = false;

        if(typeof this.policy === 'object' && this.policy !== null){
            if(typeof this.policy.checkOutTime === 'number' && this.policy.checkOutTime !== 0){
                result = this.policy.checkOutTime;
            }
        }

        return result;
    }
}

export default Quotation;