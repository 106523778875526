/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the range slider component */
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

/* require the classes for this component */
import Culture from '../../../class/Culture';
import ModalDrawerOverlay from '../../common/ModalDrawerOverlay';

/**
 * This component allows controlling the
 * review and recommendation filter
 */
class MarketplaceReviewFilter extends React.Component {
    state = {
        reviewRange: [0, 100],
        recommendationRange: [0, 100]
    }

    /**
     * Initialise the current filter
     * values when the component is mounted
     */
    componentDidMount(){
        this.setState({
            reviewRange: [
                (Number(this.props.value.minReview) || 0),
                (Number(this.props.value.maxReview) || 100)
            ],
            recommendationRange: [
                (Number(this.props.value.minRecommendation) || 0),
                (Number(this.props.value.maxRecommendation) || 100)
            ]
        });
    }

    /**
     * Notifies the parent component about
     * any updates to the filter selection
     */
    notifyUpdate(){
        let searchQuery = this.props.value;

        if(this.state.reviewRange[0] === 0 && this.state.reviewRange[1] === 100){
            delete searchQuery.minReview;
            delete searchQuery.maxReview;
        }else{
            searchQuery.minReview = this.state.reviewRange[0];
            searchQuery.maxReview = this.state.reviewRange[1];
        }
        
        if(this.state.recommendationRange[0] === 0 && this.state.recommendationRange[1] === 100){
            delete searchQuery.minRecommendation;
            delete searchQuery.maxRecommendation;
        }else{
            searchQuery.minRecommendation = this.state.recommendationRange[0];
            searchQuery.maxRecommendation = this.state.recommendationRange[1];
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(searchQuery);
        }
    }

    /**
     * Renders the component with the selection
     * option for the review and recommendation filter
     */
    render(){
        return(
            <ModalDrawerOverlay
                onClose={this.props.onClose}
                titleText={Culture.getText('MARKETPLACE_FILTER_GUESTREVIEW_TITLE')}
                subtitleText={Culture.getText('MARKETPLACE_FILTER_GUESTREVIEW_TEXT')}
                introText={Culture.getText('MARKETPLACE_FILTER_GUESTREVIEW_INTRO')}
                submitButtonText={Culture.getText('MARKETPLACE_FILTER_APPLY')}
                onSubmit={this.notifyUpdate.bind(this)}>
                <div className="MarketplaceGuestReviewFilter MarketplaceFilter">
                    <div className="MarketplaceGuestReviewFilterText">
                        {Culture.getText('MARKETPLACE_FILTER_REVIEW')
                            .replace('{{VAR_1}}',this.state.reviewRange[0] || 0)
                            .replace('{{VAR_2}}',this.state.reviewRange[1] || 100)}
                    </div>
                    <RangeSlider
                        min={0} max={100}
                        defaultValue={[
                            (Number(this.props.value.minReview) || 0),
                            (Number(this.props.value.maxReview) || 100)
                        ]}
                        onInput={(value) => this.setState({reviewRange: value})} />
                </div>
                <div className="MarketplaceGuestReviewFilter MarketplaceFilter">
                    <div className="MarketplaceGuestReviewFilterText">
                        {Culture.getText('MARKETPLACE_FILTER_RECOMMENDATION')
                                .replace('{{VAR_1}}',this.state.recommendationRange[0] || 0)
                                .replace('{{VAR_2}}',this.state.recommendationRange[1] || 100)}
                    </div>
                    <RangeSlider
                        min={0} max={100}
                        defaultValue={[
                            (Number(this.props.value.minRecommendation) || 0),
                            (Number(this.props.value.maxRecommendation) || 100)
                        ]}
                        onInput={(value) => this.setState({recommendationRange: value})} />
                </div>
            </ModalDrawerOverlay>
        );
    }
}

export default MarketplaceReviewFilter;