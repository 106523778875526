/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* import the core classes required */
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';

/* import the required components */
import EditorSection from '../common/EditorSection';
import DataInputField from '../common/DataInputField';
import DataInputList from '../common/DataInputList';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';
import CountrySelectList from '../common/CountrySelectList';
import USStateSelectList from '../common/USStateSelectList';
import DataInputCheckBox from '../common/DataInputCheckBox';
import LoadingIndicator from '../common/LoadingIndicator';
import DataInputDocumentFile from '../common/DataInputDocumentFile';
import SubNavigationList from '../common/SubNavigationList';

/* require the static data and lists for the verification */
const MerchantCategoryList = require('../../config/payment/MerchantCategoryList.json');

/**
 * Allows viewing and editing the company details of
 * the payment account provided in the props
 */
class PaymentCompanyEditor extends React.Component {
    state = {
        updating: false,
        failed: false,
        verificationInfo: {},
        verificationUpdated: false,
        showBankAccountWindow: false,
        newBankAccountCurrency: '',
        newBankAccountCountry: '',
        newBankAccountHolder: '',
        newBankAccountNumber: '',
        newBankRoutingNumber: '',
        showPersonEditorWindow: false,
        editPersonId: false,
        newPersonCountry: 'US',
        newPersonNationality: 'US',
        newPersonFirstName: '',
        newPersonLastName: '',
        newPersonEmail: '',
        newPersonPhone: '',
        newPersonAddressLine: '',
        newPersonCity: '',
        newPersonPostalCode: '',
        newPersonState: 'AL',
        newPersonIsDirector: false,
        newPersonIsExecutive: false,
        newPersonIsRepresentative: false,
        newPersonIsOwner: false,
        newPersonOwnerShare: 0,
        newPersonSSN: '',
        newPersonIDNumber: '',
        newPersonTitle: '',
        newPersonDOB: '',
        newPersonIdentityDocumentFile: null,
        newPersonAddressDocumentFile: null,
        personErrorList: []
    }

    /**
     * Sets the default currency and
     * country for the new account based
     * on the available currencies
     */
    componentDidMount(){
        let defaultCurrencyCode = Object.keys(this.props.account.supportedPayoutCurrencyList)[0];
        let defaultCountryCode = this.props.account.supportedPayoutCurrencyList[defaultCurrencyCode][0];
        this.setState({
            newBankAccountCurrency: defaultCurrencyCode,
            newBankAccountCountry: defaultCountryCode,
            newPersonCountry: this.props.account.company.address.countryCode,
            newPersonNationality: this.props.account.company.address.countryCode
        });
    }

    /**
     * Returns the code of the provider
     * this account belongs to
     */
    getProviderCode(){
        return this.props.providerCode || 'stripe';
    }

    /**
     * Updates the verification information of the
     * field provided with the value provided in the
     * state of this component
     * 
     * @param {string} fieldName
     * code of the field to update the value of
     *  
     * @param {string} fieldValue 
     * value of the field to set for it
     */
    updateVerificationInfo(fieldName,fieldValue){
        let info = this.state.verificationInfo;
        info[fieldName] = fieldValue;
        this.setState({
            verificationInfo: info, 
            verificationUpdated: true
        });
    }

    /**
     * Returns the value of the verification information
     * for which the field name was provided in the param
     * 
     * @param {string} fieldName 
     * code of the verification field to retrieve value of
     */
    getVerificationInfoValue(fieldName){
        let result = '';

        if(typeof this.state.verificationInfo[fieldName] === 'string'){
            result = this.state.verificationInfo[fieldName];
        }

        return result;
    }

    /**
     * Posts the new bank account to the remote
     * api to update the customer account with it
     */
    async updateBankAccount(){
        await this.update({
            bankAccount: {
                countryCode: this.state.newBankAccountCountry,
                currencyCode: this.state.newBankAccountCurrency,
                accountHolder: this.state.newBankAccountHolder,
                accountNumber: this.state.newBankAccountNumber,
                routingNumber: this.state.newBankRoutingNumber
            }
        });
    }

    /**
     * Updates the person list with the new person
     */
    async updatePersonList(){
        /* define the person object */
        let personObject = {
            address: {
                city: this.state.newPersonCity,
                country: this.state.newPersonCountry,
                line1: this.state.newPersonAddressLine,
                postal_code: this.state.newPersonPostalCode
            },
            dob: (function(){
                let result = {
                    day: 1,
                    month: 1,
                    year: 1970
                };

                let dobString = this.state.newPersonDOB;
                if(dobString.indexOf('.') > 0){
                    let datePart = dobString.split('.');
                    result.day = parseInt(datePart[0]);
                    result.month = parseInt(datePart[1]);
                    result.year = parseInt(datePart[2]);
                }if(dobString.indexOf('-') > 0){
                    let datePart = dobString.split('-');
                    result.day = parseInt(datePart[2]);
                    result.month = parseInt(datePart[1]);
                    result.year = parseInt(datePart[0]);
                }

                return result;
            }).bind(this)(),
            email: this.state.newPersonEmail,
            first_name: this.state.newPersonFirstName,
            last_name: this.state.newPersonLastName,
            phone: this.state.newPersonPhone,
            relationship: {
                title: this.state.newPersonTitle,
                director: this.state.newPersonIsDirector,
                executive: this.state.newPersonIsExecutive,
                representative: this.state.newPersonIsRepresentative,
                owner: this.state.newPersonIsOwner,
                percent_ownership: (function(){
                    let result = null;

                    if(this.state.newPersonIsOwner === true){
                        result = parseFloat(this.state.newPersonOwnerShare);
                    }

                    return result;
                }).bind(this)()
            }
        };

        /* set the nationality and id number only when provided */
        if(this.state.newPersonNationality !== ''){
            personObject.nationality = this.state.newPersonNationality;
        }if(this.state.newPersonIDNumber !== ''){
            personObject.id_number = this.state.newPersonIDNumber;
        }

        /* add the information required for US persons */
        if(this.state.newPersonCountry.toUpperCase() === 'US'){
            personObject.address.state = this.state.newPersonState;
            if(this.state.newPersonSSN !== ''){
                personObject.ssn_last_4 = this.state.newPersonSSN.substring(this.state.newPersonSSN.length-4);
                personObject.id_number = ('US'+this.state.newPersonSSN);
            }
        }

        /* add the identity document file for the person if present */
        if(this.state.newPersonIdentityDocumentFile !== null){
            personObject.identityDocumentFile = this.state.newPersonIdentityDocumentFile;
        }if(this.state.newPersonAddressDocumentFile !== null){
            personObject.addressDocumentFile = this.state.newPersonAddressDocumentFile;
        }

        /* update or create the person in the list */
        let personList = this.props.account.personList || [];
        if(this.state.editPersonId === false){
            /* add the person as a new person */
            personList.push(personObject);
        }else{
            /* this is a an existing person, overwrite it */
            if(Array.isArray(this.props.account.personList)){
                personList = this.props.account.personList;
                for(let p=0; p<personList.length; p++){
                    if(personList[p].id === this.state.editPersonId){
                        /* assign the new values to the existing object */
                        Object.assign(personList[p],personObject);
                    }
                }
            }
        }

        /* update the person list with the remote */
        this.postPersonList(personList);
    }

    /**
     * Update the person list with the remote api
     * 
     * @param {array} list
     * new person list to send to the api 
     */
    async postPersonList(list){
        this.setState({updating: true});

        let updateResult = await ApiClient.execute('/booking/updatePersonList',{
            personList: list,
            providerCode: this.getProviderCode()
        });

        if(typeof updateResult === 'object' && updateResult !== null){
            if(updateResult.updated === true){
                if(typeof this.props.onUpdated === 'function'){
                    this.props.onUpdated();
                    this.setState({updating: false, failed: false});
                }
            }else{
                this.setState({updating: false, failed: true});
            }
        }else{
            this.setState({updating: false, failed: true});
        }
    }

    /**
     * Posts the verification info to the remote
     * and reloads the information afterwards
     */
    async postVerificationInfo(){
        await this.update({
            verificationInfo: this.state.verificationInfo
        });
    }

    /**
     * Updates the company information with the
     * data provided in the parameter
     * 
     * @param {object} data 
     * the data to update the company with
     */
    async update(data){
        this.setState({updating: true});

        let updateResult = await ApiClient.execute('/booking/updatePaymentConfig',{
            account: data,
            providerCode: this.getProviderCode()
        });

        if(typeof updateResult === 'object' && updateResult !== null){
            if(updateResult.updated === true){
                if(typeof this.props.onUpdated === 'function'){
                    this.props.onUpdated();
                }
            }else{
                this.setState({updating: false, failed: true});
            }
        }else{
            this.setState({updating: false, failed: true});
        }
    }

    /**
     * Returns an array with the list of required
     * person types for this organization
     */
    getRequiredPersonList(){
        let result = [];

        const accountObject = this.props.account;
        const requirementList = accountObject.verificationRequirementList;
        const personTypeList = ['owners','representative','executives','directors'];
        for(const personType of personTypeList){
            for(const requirementItem of requirementList){
                if(requirementItem.startsWith(personType+'.')){
                    if(!result.includes(personType)){
                        result.push(personType);
                    }
                }
            }
        }

        return result;
    }

    /**
     * Renders the list of persons related to
     * the company and allows creating and editing
     * the persons in this company
     */
    renderPersonList(){
        let result = [];

        let accountObject = this.props.account;
        if(Array.isArray(accountObject.personList)){
            for(const person of accountObject.personList){
                let personErrorList = [];
                if(typeof person.requirements === 'object' && person.requirements !== null){
                    if(Array.isArray(person.requirements.errors)){
                        if(person.requirements.errors.length > 0){
                            personErrorList = person.requirements.errors;
                        }
                    }
                }

                let personRequirementDue = false;
                if(Array.isArray(person.requirements.currently_due)){
                    if(person.requirements.currently_due.length > 0){
                        personRequirementDue = true;
                    }
                }

                result.push(
                    <div key={person.id} className="PaymentAccountPerson" 
                        data-hasvalidationerror={(personErrorList.length > 0 || personRequirementDue === true)}>
                        <div className="PaymentAccountPersonName" onClick={(function(){
                            this.setState({
                                showPersonEditorWindow: true, 
                                editPersonId: person.id,
                                newPersonAddressLine: person.address.line1,
                                newPersonCity: person.address.city,
                                newPersonCountry: person.address.country.toUpperCase(),
                                newPersonDOB: (function(){
                                    let result = '';

                                    if(person.dob.year !== null){
                                        result = [
                                            String(person.dob.year),
                                            String(person.dob.month).padStart(2,'0'),
                                            String(person.dob.day).padStart(2,'0')
                                        ].join('-');
    
                                        if(Culture.getCultureCode().toLowerCase() === 'de'){
                                            result = [
                                                String(person.dob.day).padStart(2,'0'),
                                                String(person.dob.month).padStart(2,'0'),
                                                String(person.dob.year)
                                            ].join('.');
                                        }
                                    }

                                    return result;
                                })(),
                                newPersonEmail: person.email,
                                newPersonFirstName: person.first_name,
                                newPersonIDNumber: '',
                                newPersonIsDirector: person.relationship.director,
                                newPersonIsExecutive: person.relationship.executive,
                                newPersonIsOwner: person.relationship.owner,
                                newPersonIsRepresentative: person.relationship.representative,
                                newPersonOwnerShare: (person.relationship.percent_ownership || '0'),
                                newPersonLastName: person.last_name,
                                newPersonNationality: '',
                                newPersonPhone: person.phone,
                                newPersonPostalCode: person.address.postal_code,
                                newPersonSSN: (person.id_number || ''),
                                newPersonState: (person.address.state || ''),
                                newPersonTitle: person.relationship.title,
                                personErrorList: personErrorList,
                                newPersonIdentityDocumentFile: null,
                                newPersonAddressDocumentFile: null
                            });
                        }).bind(this)}>
                            {[person.last_name,person.first_name].join(', ')}
                        </div>
                        <div className="PaymentAccountPersonRemoveButton" onClick={(function(personId){
                            /* remove the person and post the updated list */
                            let updatedList = [];
                            let personList = this.props.account.personList;
                            for(let p=0; p<personList.length; p++){
                                if(personList[p].id !== personId){
                                    updatedList.push(personList[p]);
                                }
                            }
                            this.postPersonList(updatedList);
                        }).bind(this,person.id)}></div>
                    </div>
                );
            }
        }

        /* add the add-button to the list */
        result.push(
            <div key="AddPersonButton">
                <StandardButton text={Culture.getText('PAYMENT_PERSON_ADDBUTTON')}
                    className="PaymentAccountAddPersonButton" onClick={() => {
                        this.setState({
                            showPersonEditorWindow: true, 
                            editPersonId: false,
                            newPersonAddressLine: '',
                            newPersonCity: '',
                            newPersonCountry: 'US',
                            newPersonDOB: '',
                            newPersonEmail: '',
                            newPersonFirstName: '',
                            newPersonIDNumber: '',
                            newPersonIsDirector: false,
                            newPersonIsExecutive: false,
                            newPersonIsOwner: false,
                            newPersonIsRepresentative: false,
                            newPersonOwnerShare: '',
                            newPersonLastName: '',
                            newPersonNationality: 'US',
                            newPersonPhone: '',
                            newPersonPostalCode: '',
                            newPersonSSN: '',
                            newPersonState: 'AL',
                            newPersonTitle: '',
                            personErrorList: [],
                            newPersonIdentityDocumentFile: null,
                            newPersonAddressDocumentFile: null
                        });
                    }} />
            </div>
        );

        return result;
    }

    /**
     * Renders the button to confirm that all applicable
     * persons for this organisation were provided if further
     * persons are required, but the user has already supplied
     * all necessary persons
     */
    renderConfirmPersonList(){
        let result = [];

        let hasOwner = false;
        let hasExecutive = false;
        let hasDirector = false;
        let personList = this.props.account.personList;
        if(Array.isArray(personList)){
            for(const person of personList){
                if(person.relationship.director === true){hasDirector = true;}
                if(person.relationship.executive === true){hasExecutive = true;}
                if(person.relationship.owner === true){hasOwner = true;}
            }
        }

        let personRequired = false;
        let requirementList = this.props.account.verificationRequirementList;
        if(Array.isArray(requirementList)){
            if(requirementList.includes('company.directors_provided') === true
                || requirementList.includes('company.executives_provided') === true
                || requirementList.includes('company.owners_provided') === true){
                personRequired = true;
            }
        }

        if(personRequired === true && hasOwner === true 
            && hasExecutive === true && hasDirector === true){
            /* render the button to confirm all owners */
            result.push(
                <div>
                    <StandardButton className="PaymentCompanyPersonListCompleteButton"
                        text={Culture.getText('PAYMENT_COMPANY_PERSONPROVIDED_BUTTON')} 
                        onClick={(function(){
                            let info = this.state.verificationInfo;
                            info['company.directors_provided'] = true;
                            info['company.executives_provided'] = true;
                            info['company.owners_provided'] = true;
                            this.setState({
                                verificationInfo: info, 
                                verificationUpdated: true
                            },this.postVerificationInfo.bind(this));
                        }).bind(this)} />
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the component based on whether
     * it is ready or updating the company
     */
    render(){
        let result = [];

        if(this.state.updating === true){
            result = (
                <div className="PaymentCompanyEditor">
                    <LoadingIndicator />
                </div>
            );
        }else{
            /* render the editor when ready */
            result = this.renderEditor();
        }

        return result;
    }

    /**
     * Renders the component for the company
     * information and allows editing missing
     * information as well as existing ones
     */
    renderEditor(){
        let accountObject = this.props.account;
        let requirementList = accountObject.verificationRequirementList;
        const requiredPersonList = this.getRequiredPersonList();
        let companyCountryName = Culture.getText('COUNTRY_'+accountObject.company
                                                .address.countryCode.toUpperCase());
        let companyMCCName = '';
        if(accountObject.company.mcc !== ''){
            for(const mccCode of Object.keys(MerchantCategoryList)){
                if(MerchantCategoryList[mccCode] === accountObject.company.mcc){
                    companyMCCName = Culture.getText('PAYMENT_MCC_'+mccCode.toUpperCase());
                }
            }
        }

        /* determine whether the bank account requires a routing number */
        let requireRoutingNumber = ['US','CA','GB','AU'].includes(this.state.newBankAccountCountry);
        let routingNumberName = Culture.getText('PAYMENT_BANK_ROUTINGNUMBER');
        let accountNumberName = Culture.getText('PAYMENT_BANKACCOUNT_NUMBER');
        if(requireRoutingNumber === true){
            accountNumberName = Culture.getText('PAYMENT_BANKACCOUNT_NUMBER_'+this.state.newBankAccountCountry);
            routingNumberName = Culture.getText('PAYMENT_BANK_ROUTINGNUMBER_'+this.state.newBankAccountCountry);
        }

        return(
            <div className="PaymentCompanyEditor" data-hasbankaccount={(accountObject.bankAccount !== false)}>
                <SubNavigationList>
                    <section code="balance" name={Culture.getText('PAYMENT_ACCOUNT_INFO')}>
                        <EditorSection title={Culture.getText('PAYMENT_ACCOUNT_BALANCE_TITLE')}
                            subtitle={Culture.getText('PAYMENT_ACCOUNT_BALANCE_SUBTITLE')}
                            helpText={Culture.getText('PAYMENT_ACCOUNT_BALANCE_HELPTEXT')}>
                            {this.state.failed === true &&
                                <div className="PaymentCompanyEditorFailed">
                                    {Culture.getText('PAYMENT_COMPANY_UPDATEFAILED')}
                                </div>
                            }

                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_ACCOUNT_STATUS')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue">
                                    {accountObject.verified === true &&
                                        <div className="PaymentAccountVerification" data-verified={true}>
                                            {Culture.getText('PAYMENT_ACCOUNT_VERIFIED')}
                                        </div>
                                    }

                                    {accountObject.verified !== true &&
                                        <div className="PaymentAccountVerification" data-verified={false}>
                                            {Culture.getText('PAYMENT_ACCOUNT_VERIFICATIONPENDING')}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_ACCOUNTBALANCE_PENDING')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue"
                                    data-negative={(accountObject.balance.pending.amount < 0)}>
                                    {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                        style: 'currency',
                                        currency: accountObject.balance.pending.currencyCode
                                    })).format(accountObject.balance.pending.amount)}
                                </div>
                            </div>

                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_ACCOUNTBALANCE_AVAILABLE')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue" 
                                    data-negative={(accountObject.balance.pending.amount < 0)}>
                                    {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                        style: 'currency',
                                        currency: accountObject.balance.available.currencyCode
                                    })).format(accountObject.balance.available.amount)}
                                </div>
                            </div>
                        </EditorSection>

                        <EditorSection title={Culture.getText('PAYMENT_ACCOUNT_HOLDER_TITLE')}
                            subtitle={Culture.getText('PAYMENT_ACCOUNT_HOLDER_SUBTITLE')}
                            helpText={Culture.getText('PAYMENT_ACCOUNT_HOLDER_HELPTEXT')}>
                            <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYNAME')}
                                    value={accountObject.company.name} disabled={true} />

                            <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYADDRESSLINE')}
                                    value={accountObject.company.address.addressLine} disabled={true} />

                            <div className="PaymentAccountCompanyAddressList">
                                <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYPOSTALCODE')}
                                        value={accountObject.company.address.postalCode} disabled={true} />

                                <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYCITY')}
                                        value={accountObject.company.address.cityName} disabled={true} />

                                {typeof accountObject.company.address.stateCode === 'string'
                                    && accountObject.company.address.stateCode !== '' &&
                                    <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYSTATE')} disabled={true}
                                        value={Culture.getText('US_STATE_' + accountObject.company.address.stateCode.toUpperCase())} /> 
                                }
                            </div>

                            <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYCOUNTRY')}
                                    value={companyCountryName} disabled={true} />

                            {(requirementList.includes('company.tax_id') === true 
                                || accountObject.company.taxId !== '') &&
                                <div className="PaymentCompanyRequirement" 
                                    data-required={requirementList.includes('company.tax_id')}>
                                    <DataInputField title={Culture.getText('PAYMENT_COMPANY_TAXID')}
                                        value={(function(){
                                            let result = accountObject.company.taxId;

                                            if(this.getVerificationInfoValue('company.tax_id') !== ''){
                                                result = this.getVerificationInfoValue('company.tax_id');
                                            }

                                            return result;
                                        }).bind(this)()} 
                                        onChange={(value) => {
                                            this.updateVerificationInfo('company.tax_id',value);
                                        }} />
                                </div>
                            }

                            <div>
                                <StandardButton disabled={(this.state.verificationUpdated !== true)}
                                    text={Culture.getText('PAYMENT_COMPANY_UPDATEBUTTON')} 
                                    onClick={this.postVerificationInfo.bind(this)} />
                            </div>
                        </EditorSection>

                        <EditorSection title={Culture.getText('PAYMENT_ACCOUNT_MCC_TITLE')}
                            subtitle={Culture.getText('PAYMENT_ACCOUNT_MCC_SUBTITLE')}
                            helpText={Culture.getText('PAYMENT_ACCOUNT_MCC_HELPTEXT')}>
                            {companyMCCName !== '' &&
                                <DataInputField title={Culture.getText('PAYMENT_COMPANY_MCC')}
                                    value={companyMCCName} disabled={true} />
                            }

                            {requirementList.includes('business_profile.mcc') === true
                                && accountObject.company.mcc === '' &&
                                <div className="PaymentCompanyRequirement" data-required={true}>
                                    <DataInputList title={Culture.getText('PAYMENT_COMPANY_MCC')} 
                                        value={this.getVerificationInfoValue('business_profile.mcc')} 
                                        list={(function(){
                                            let result = [];

                                            result.push({value: '',text: '—'});
                                            for(const code of Object.keys(MerchantCategoryList)){
                                                result.push({
                                                    value: MerchantCategoryList[code],
                                                    text: Culture.getText('PAYMENT_MCC_'+code.toUpperCase())
                                                });
                                            }

                                            result.sort(function (a, b) {
                                                return a.text.localeCompare(b.text);
                                            });

                                            return result;
                                        })()} onChange={(value) => {
                                            this.updateVerificationInfo('business_profile.mcc',value);
                                        }}/>
                                </div>
                            }

                            <div>
                                <StandardButton disabled={(this.state.verificationUpdated !== true)}
                                    text={Culture.getText('PAYMENT_COMPANY_UPDATEBUTTON')} 
                                    onClick={this.postVerificationInfo.bind(this)} />
                            </div>
                        </EditorSection>
                    </section>
                    <section code="bankaccount" name={Culture.getText('PAYMENT_BANKACCOUNT')}>
                        <EditorSection title={Culture.getText('PAYMENT_BANKACCOUNT_TITLE')}
                            subtitle={Culture.getText('PAYMENT_BANKACCOUNT_SUBTITLE')}
                            helpText={Culture.getText('PAYMENT_BANKACCOUNT_HELPTEXT')}>
                            {accountObject.bankAccount === false &&
                                <div className="PaymentCompanyNoBankAccount">
                                    <div className="PaymentCompanyNoBankAccountText">
                                        {Culture.getText('PAYMENT_BANKACCOUNT_NONE')}
                                    </div>
                                    <div className="PaymentCompanyNoBankAccountCreateButton" 
                                        onClick={() => this.setState({showBankAccountWindow: true})}>
                                        {Culture.getText('PAYMENT_BANKACCOUNT_CREATE')}
                                    </div>
                                </div>
                            }

                            {accountObject.bankAccount !== false &&
                                <>
                                    <div className="PaymentAccountInformationField">
                                        <div className="PaymentAccountInformationFieldTitle">
                                            {Culture.getText('PAYMENT_BANKACCOUNT_COUNTRY')}
                                        </div>
                                        <div className="PaymentAccountInformationFieldValue">
                                            {Culture.getText('COUNTRY_'+accountObject.bankAccount.countryCode)}
                                        </div>
                                    </div>

                                    <div className="PaymentAccountInformationField">
                                        <div className="PaymentAccountInformationFieldTitle">
                                            {Culture.getText('PAYMENT_BANKACCOUNT_BANKNAME')}
                                        </div>
                                        <div className="PaymentAccountInformationFieldValue">
                                            {accountObject.bankAccount.bankName}
                                        </div>
                                    </div>

                                    <div className="PaymentAccountInformationField">
                                        <div className="PaymentAccountInformationFieldTitle">
                                            {accountNumberName}
                                        </div>
                                        <div className="PaymentAccountInformationFieldValue">
                                            {accountObject.bankAccount.accountNumber}
                                        </div>
                                    </div>

                                    {typeof accountObject.bankAccount.routingNumber === 'string' 
                                        && accountObject.bankAccount.routingNumber !== '' &&
                                        <div className="PaymentAccountInformationField">
                                            <div className="PaymentAccountInformationFieldTitle">
                                                {routingNumberName}
                                            </div>
                                            <div className="PaymentAccountInformationFieldValue">
                                                {accountObject.bankAccount.routingNumber}
                                            </div>
                                        </div>
                                    }

                                    <div className="PaymentAccountInformationField">
                                        <div className="PaymentAccountInformationFieldTitle">
                                            {Culture.getText('PAYMENT_BANKACCOUNT_ACCOUNTHOLDER')}
                                        </div>
                                        <div className="PaymentAccountInformationFieldValue">
                                            {accountObject.bankAccount.accountHolderName}
                                        </div>
                                    </div>

                                    <div>
                                        <StandardButton className="PaymentCompanyBankAccountEditButton"
                                            text={Culture.getText('PAYMENT_BANKACCOUNT_EDITBUTTON')} 
                                            onClick={() => this.setState({showBankAccountWindow: true})} />
                                    </div>
                                </>
                            }
                        </EditorSection>
                    </section>
                    <section code="personlist" name={Culture.getText('PAYMENT_COMPANY_PERSONLIST')}>
                        <EditorSection title={Culture.getText('PAYMENT_COMPANY_PERSONLIST_TITLE')}
                            subtitle={Culture.getText('PAYMENT_COMPANY_PERSONLIST_SUBTITLE')}
                            introText={Culture.getText('PAYMENT_COMPANY_PERSONLIST_INTROTEXT')}
                            helpText={Culture.getText('PAYMENT_COMPANY_PERSONLIST_HELPTEXT')}>
                            {requiredPersonList.length > 0 &&
                                <div className="PaymentAccountPersonRequired">
                                    {Culture.getText('PAYMENT_COMPANY_REQUIREDPERSONLIST')}

                                    <ul>
                                        {requiredPersonList.map(function(requiredPerson, i){
                                            return (
                                                <li key={requiredPerson}>
                                                    {Culture.getText('PAYMENT_COMPANY_PERSONTYPE_'+requiredPerson.toUpperCase())}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            }
                            {this.renderConfirmPersonList()}

                            <div className="PaymentAccountPersonList">
                                {this.renderPersonList()}
                            </div>
                        </EditorSection>
                    </section>
                </SubNavigationList>

                {this.state.showBankAccountWindow === true &&
                    <ModalDrawerOverlay titleText={Culture.getText('PAYMENT_BANKACCOUNT_UPDATE_TITLE')}
                        subtitleText={Culture.getText('PAYMENT_BANKACCOUNT_UPDATE_SUBTITLE')} 
                        submitButtonText={Culture.getText('PAYMENT_BANKACCOUNT_UPDATEBUTTON')}
                        submitDisabled={!(
                            this.state.newBankAccountCurrency !== '' &&
                            this.state.newBankAccountCountry !== '' &&
                            this.state.newBankAccountHolder !== '' &&
                            this.state.newBankAccountNumber !== '' &&
                            (
                                this.state.newBankRoutingNumber !== ''
                                || requireRoutingNumber === false
                            )
                        )}
                        onSubmit={(function(){
                            /* hide the window and update the account */
                            this.setState({showBankAccountWindow: false},
                                        this.updateBankAccount.bind(this));
                        }).bind(this)}
                        onClose={() => this.setState({showBankAccountWindow: false})}>
                        <DataInputList title={Culture.getText('PAYMENT_BANKACCOUNT_CURRENCY')} 
                            value={this.state.newBankAccountCurrency} 
                            list={(function(){
                                let result = [];

                                for(const currencyCode of Object.keys(accountObject.supportedPayoutCurrencyList)){
                                    result.push({
                                        value: currencyCode,
                                        text: Culture.getText('CURRENCYNAME_'+currencyCode)
                                            + ' (' + currencyCode + ')'
                                    });
                                }

                                result.sort(function (a, b) {
                                    return a.text.localeCompare(b.text);
                                });

                                return result;
                            })()} onChange={(value) => {
                                this.setState({newBankAccountCurrency: value});
                            }}/>

                        <DataInputList title={Culture.getText('PAYMENT_BANKACCOUNT_COUNTRY')} 
                            value={this.state.newBankAccountCountry} 
                            list={(function(){
                                let result = [];

                                let selectedCurrencyCode = this.state.newBankAccountCurrency;
                                let countryCodeList = accountObject.supportedPayoutCurrencyList[selectedCurrencyCode];
                                if(Array.isArray(countryCodeList)){
                                    for(const countryCode of countryCodeList){
                                        result.push({
                                            value: countryCode,
                                            text: Culture.getText('COUNTRY_'+countryCode)
                                        });
                                    }
                                }

                                result.sort(function (a, b) {
                                    return a.text.localeCompare(b.text);
                                });

                                return result;
                            }).bind(this)()} onChange={(value) => {
                                this.setState({newBankAccountCountry: value});
                            }}/>

                        <DataInputField title={Culture.getText('PAYMENT_BANKACCOUNT_ACCOUNTHOLDER')}
                            value={this.state.newBankAccountHolder} 
                            onChange={(value) => this.setState({newBankAccountHolder: value})} />

                        <DataInputField title={accountNumberName}
                            value={this.state.newBankAccountNumber} 
                            onChange={(value) => this.setState({newBankAccountNumber: value})} />

                        {requireRoutingNumber === true &&
                            <DataInputField title={routingNumberName}
                                value={this.state.newBankRoutingNumber} 
                                onChange={(value) => this.setState({newBankRoutingNumber: value})} />
                        }
                    </ModalDrawerOverlay>
                }

                {this.state.showPersonEditorWindow === true &&
                    <ModalDrawerOverlay titleText={Culture.getText('PAYMENT_PERSONEDITOR_TITLE')}
                        subtitleText={Culture.getText('PAYMENT_PERSONEDITOR_SUBTITLE')}
                        submitButtonText={Culture.getText('PAYMENT_PERSON_UPDATEBUTTON')}
                        submitDisabled={(function(){
                            let result = true;

                            if(this.state.newPersonEmail !== '' && this.state.newPersonPhone !== ''
                                && this.state.newPersonCountry !== '' && this.state.newPersonCity !== ''
                                && this.state.newPersonPostalCode !== '' && this.state.newPersonAddressLine !== ''
                                && this.state.newPersonDOB !== '' && this.state.newPersonFirstName !== ''
                                && this.state.newPersonLastName !== '' && this.state.newPersonTitle !== ''){
                                /* basic person information valid, check requirements for US persons */
                                if(this.state.newPersonNationality === 'US'){
                                    if(this.state.newPersonSSN !== '' && this.state.newPersonState !== ''){
                                        result = false;
                                    }
                                }else{
                                    result = false;
                                }

                                /* check if this person is an owner */
                                if(this.state.newPersonIsOwner === true && result === true){
                                    let sharePercent = parseFloat(this.state.newPersonOwnerShare);
                                    if(isNaN(sharePercent) === false && sharePercent > 0){
                                        result = false;
                                    }
                                }
                            }
                            
                            return result;
                        }).bind(this)()}
                        onSubmit={() => this.setState({
                            showPersonEditorWindow: false
                        },this.updatePersonList)}
                        onClose={() => this.setState({showPersonEditorWindow: false})}>
                        {this.state.personErrorList.length > 0 &&
                            <div className="PaymentPersonEditorError">
                                <div className="PaymentPersonEditorErrorTitle">
                                    {Culture.getText('PAYMENT_PERSON_ERRORLIST_TITLE')}
                                </div>
                                <div className="PaymentPersonEditorErrorList">
                                    {(function(){
                                        let result = [];

                                        for(const personErrorItem of this.state.personErrorList){
                                            result.push(
                                                <div key={personErrorItem.code} className="PaymentPersonEditorErrorItem">
                                                    {personErrorItem.reason}
                                                </div>
                                            );
                                        }

                                        return result;
                                    }).bind(this)()}
                                </div>
                            </div>
                        }

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_FIRSTNAME')}
                            value={this.state.newPersonFirstName} onChange={(value) => {
                                this.setState({newPersonFirstName: value});
                        }}/>

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_LASTNAME')}
                            value={this.state.newPersonLastName} onChange={(value) => {
                                this.setState({newPersonLastName: value});
                        }}/>

                        {accountObject.businessType !== 'individual' &&
                            <DataInputField title={Culture.getText('PAYMENT_PERSON_ROLETITLE')}
                                value={this.state.newPersonTitle} onChange={(value) => {
                                    this.setState({newPersonTitle: value});
                            }}/>
                        }

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_BIRTHDATE')}
                            value={this.state.newPersonDOB} onChange={(value) => {
                                this.setState({newPersonDOB: value.replace(/[^0-9\\.\\-]/gmi,'')});
                        }}/>

                        {accountObject.businessType !== 'individual' &&
                            <div>
                                <DataInputCheckBox title={Culture.getText('PAYMENT_PERSON_ISDIRECTOR')} 
                                    checked={this.state.newPersonIsDirector} onClick={() => {
                                        this.setState({newPersonIsDirector: !this.state.newPersonIsDirector});
                                    }} 
                                />

                                <DataInputCheckBox title={Culture.getText('PAYMENT_PERSON_ISREPRESENTATIVE')} 
                                    checked={this.state.newPersonIsRepresentative} onClick={() => {
                                        this.setState({newPersonIsRepresentative: !this.state.newPersonIsRepresentative});
                                    }} 
                                />

                                <DataInputCheckBox title={Culture.getText('PAYMENT_PERSON_ISEXECUTIVE')} 
                                    checked={this.state.newPersonIsExecutive} onClick={() => {
                                        this.setState({newPersonIsExecutive: !this.state.newPersonIsExecutive});
                                    }} 
                                />

                                <DataInputCheckBox title={Culture.getText('PAYMENT_PERSON_ISOWNER')} 
                                    checked={this.state.newPersonIsOwner} onClick={() => {
                                        this.setState({newPersonIsOwner: !this.state.newPersonIsOwner});
                                    }} 
                                />
                            </div>
                        }

                        {this.state.newPersonIsOwner === true && accountObject.businessType !== 'individual' &&
                            <DataInputField title={Culture.getText('PAYMENT_PERSON_OWNERSHARE')}
                                value={this.state.newPersonOwnerShare} onChange={(value) => {
                                    this.setState({newPersonOwnerShare: value.replace(/[^0-9\\,\\.]/gmi,'')});
                            }}/>
                        }

                        <CountrySelectList title={Culture.getText('PAYMENT_PERSON_NATIONALITY')} 
                            value={this.state.newPersonNationality} onChange={(value) => {
                                this.setState({newPersonNationality: value});
                        }}/>

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_ADDRESSLINE')}
                            value={this.state.newPersonAddressLine} onChange={(value) => {
                                this.setState({newPersonAddressLine: value});
                        }}/>

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_POSTALCODE')}
                            value={this.state.newPersonPostalCode} onChange={(value) => {
                                this.setState({newPersonPostalCode: value});
                        }}/>

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_CITY')}
                            value={this.state.newPersonCity} onChange={(value) => {
                                this.setState({newPersonCity: value});
                        }}/>

                        {this.state.newPersonCountry === 'US' &&
                            <USStateSelectList
                                title={Culture.getText('PAYMENT_PERSON_STATE')} 
                                value={this.state.newPersonState.toUpperCase()} 
                                onChange={(value) => {
                                    this.setState({newPersonState: value.toUpperCase()});
                                }} />
                        }

                        <CountrySelectList title={Culture.getText('PAYMENT_PERSON_COUNTRY')} 
                            value={this.state.newPersonCountry} onChange={(value) => {
                                this.setState({newPersonCountry: value.toUpperCase()});
                        }}/>

                        {this.state.newPersonCountry.toUpperCase() !== 'US' &&
                            <DataInputField title={Culture.getText('PAYMENT_PERSON_IDNUMBER')}
                                value={this.state.newPersonIDNumber} onChange={(value) => {
                                    this.setState({newPersonIDNumber: value});
                            }}/>
                        }

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_PHONE')}
                            value={this.state.newPersonPhone} onChange={(value) => {
                                this.setState({newPersonPhone: value.replace(/[^0-9\\+\\-]/gmi,'')});
                        }}/>

                        <DataInputField title={Culture.getText('PAYMENT_PERSON_EMAIL')}
                            value={this.state.newPersonEmail} onChange={(value) => {
                                this.setState({newPersonEmail: value});
                        }}/>

                        {this.state.newPersonCountry.toUpperCase() === 'US' &&
                            <div>
                                <DataInputField title={Culture.getText('PAYMENT_PERSON_SSN')}
                                    value={this.state.newPersonSSN} onChange={(value) => {
                                        this.setState({newPersonSSN: value.replace(/[^0-9\\-]/gmi,'')});
                                }}/>
                            </div>
                        }

                        <DataInputDocumentFile 
                            title={Culture.getText('PAYMENT_PERSON_IDENTITYDOCUMENT')}
                            fileFormatInfoText={Culture.getText('PAYMENT_PERSON_IDENTITYDOCUMENT_FILEFORMAT')}
                            selectFileTitle={Culture.getText('PAYMENT_PERSON_IDENTITYDOCUMENT_SELECT')}
                            fileTypeList={['application/pdf','image/jpeg','image/png']}
                            value={this.state.newPersonIdentityDocumentFile}
                            onChange={(value) => this.setState({newPersonIdentityDocumentFile: value})} />

                        <DataInputDocumentFile 
                            title={Culture.getText('PAYMENT_PERSON_ADDRESSDOCUMENT')}
                            fileFormatInfoText={Culture.getText('PAYMENT_PERSON_IDENTITYDOCUMENT_FILEFORMAT')}
                            selectFileTitle={Culture.getText('PAYMENT_PERSON_ADDRESSDOCUMENT_SELECT')}
                            fileTypeList={['application/pdf','image/jpeg','image/png']}
                            value={this.state.newPersonAddressDocumentFile}
                            onChange={(value) => this.setState({newPersonAddressDocumentFile: value})} />
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default PaymentCompanyEditor;