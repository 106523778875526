/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

/**
 * This component allows to select and change the
 * culture of the current user session
 */
class CultureView extends React.Component {
    changeCulture(code){
        if(typeof this.props.onCultureChange === 'function'){
            this.props.onCultureChange(code);
        }
    }

    renderCultureList(){
        let result = [];

        let currentCulture = Culture.getCultureCode();
        let cultureList = Culture.getAvailableCultureList();
        cultureList.forEach((cultureItem) => {
            let classList = ['CultureViewItem'];
            if(cultureItem.code === currentCulture){
                classList.push('CultureViewItemActive');
            }

            let flagStyle = {
                backgroundImage: ('url(/img/culture/flags/' + cultureItem.code + '.png)')
            };

            result.push(
                <div key={cultureItem.code} className={classList.join(' ')} 
                    onClick={this.changeCulture.bind(this,cultureItem.code)}>
                    <div className="CultureViewItemFlag" style={flagStyle}></div>
                    <div className="CultureViewItemName">
                        {cultureItem.name}
                    </div>
                </div>
            );
        });

        return result;
    }

    render(){
        return(
            <div className="CultureView">
                <div className="CultureViewList">
                    {this.renderCultureList()}
                </div>
            </div>
        );
    }
}

export default CultureView;