/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../class/Culture';

/* require the components used in this component */
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

/**
 * This component allows defining new filter
 * types to search for accommodations
 */
class MarketplaceFilterInput extends React.Component {
    renderFilterTypeList(){
        let result = [];

        const filterTypeList = [
            'geofence',
            'country', 
            'guestreview',
            'category',
            'service',
            'unitamenity',  
            'starrating',
            'unittype', 
            'occupancy', 
            'language', 
            /* 'mealplan', */ 
            'payment'
        ]

        for(const filterTypeCode of filterTypeList){
            result.push(
                <div key={filterTypeCode} 
                    className="MarketplaceFilterTypeOption" 
                    data-filtertype={filterTypeCode}
                    onClick={(function(typeCode){
                        if(typeof this.props.onSelect === 'function'){
                            this.props.onSelect(typeCode);
                        }
                    }).bind(this,filterTypeCode)}>
                    {Culture.getText('MARKETPLACE_FILTER_'+filterTypeCode.toUpperCase())}
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the filter option list and
     * allows defining new filters
     */
    render(){
        return(
            <ModalDrawerOverlay
                onClose={this.props.onClose}
                titleText={Culture.getText('MARKETPLACE_FILTERCREATE_TITLE')}
                subtitleText={Culture.getText('MARKETPLACE_FILTERCREATE_TEXT')}>
                <div className="MarketplaceFilterTypeOptionList">
                    {this.renderFilterTypeList()}
                </div>
            </ModalDrawerOverlay>
        );
    }
}

export default MarketplaceFilterInput