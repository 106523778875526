/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';
import BlockChain from '../../class/BlockChain';
import Property from '../../class/Property';
import Quotation from '../../class/Quotation';

import LoadingIndicator from '../common/LoadingIndicator';
import EditorSection from '../common/EditorSection';
import DataInputList from '../common/DataInputList';
import SubNavigationList from '../common/SubNavigationList';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import StandardButton from '../common/StandardButton';

import ReservationExternalPaymentView from './ReservationExternalPaymentView';
import ReservationInformationField from './ReservationInformationField';
import QuotationView from './QuotationView';

import '../../style/ReservationEditor.css';

/**
 * The reservation editor allows to edit reservations
 * and update the reservations. It also stored the
 * updated reservations with the api and handles
 * all communication with the api
 */
class ReservationEditor extends React.Component {
    state = {
        updateStatusRequest: false,
        updateStatusValue: ''
    }

    /**
     * Returns the list with all available
     * reservation statuses, their code and name
     */
    getStatusList(){
        let result = [];

        let codeList = ['CONFIRMED','CONFIRMED_PENDING','PENDING',
                        'REQUESTED','REJECTED','CANCELLED'];
        for(const code of codeList){
            result.push({
                value: code,
                text: [code, Culture.getText('RESERVATION_STATUS_'+code)].join(' — ')
            });
        }

        return result;
    }

    /**
     * Updates the status of this reservation
     * after the user is prompted if the status
     * change is intended
     * 
     * @param {string} code 
     * new status code to set for this reservation
     */
    updateStatus(code){
        this.setState({
            updateStatusRequest: true,
            updateStatusValue: code
        });
    }

    /**
     * Renders the reservation contact
     * information and returns the rendered
     * component
     * 
     * @param {object} contactItem 
     * contact information structure
     */
    renderContact(contactItem){
        let result = [];

        let fieldList = ['firstName','lastName','email','phone',
                        'addressLine','city','postalCode'];
        for(const field of fieldList){
            let value = '';
            if(typeof contactItem[field] === 'string'){
                value = contactItem[field];
            }

            if(value !== ''){
                result.push(
                    <ReservationInformationField key={field} 
                        title={Culture.getText('RESERVATION_CONTACT_'+field.toUpperCase())}>
                        {value}
                    </ReservationInformationField>
                );
            }
        }

        if(typeof contactItem.countryCode === 'string' && contactItem.countryCode !== ''){
            result.push(
                <ReservationInformationField key={'countryCode'}
                    title={Culture.getText('RESERVATION_CONTACT_COUNTRY')}>
                    {Culture.getText('COUNTRY_'+contactItem.countryCode.toUpperCase())}
                </ReservationInformationField>
            );
        }

        return (
            <div className="ReservationContactFieldList">
                {result}
            </div>
        );
    }

    /**
     * Returns true when the reservation
     * is cancelled and false if it is not
     */
    isCancelled(){
        let result = false;

        let blockChain = new BlockChain(this.props.reservation);
        if(blockChain.getStatus() === 'CANCELLED'){
            result = true;
        }

        return result;
    }

    /**
     * Renders the guest list for
     * all guests available in the reservation
     * and if none are available, it'll render
     * and empty guest list for the occupancy
     * defined
     * 
     * @param {object} content
     * the content structure of the block
     */
    renderGuestList(content){
        let guestComponentList = [];

        let guestList = content.booking.guestList;
        if(Array.isArray(guestList) === false){ guestList = []; }
        if(guestList.length === 0){
            for(let a=0; a<content.quotation.adultCount; a++){
                guestList.push({
                    firstName: '',
                    lastName: '',
                    phone: ''
                });
            }
            
            for(let c=0; c<content.quotation.childCount; c++){
                guestList.push({
                    firstName: '',
                    lastName: '',
                    phone: '',
                    age: 12
                });
            }
        }

        for(let g=0; g<guestList.length; g++){
            let guestObject = guestList[g];

            let isAdult = (isNaN(guestObject.age) === true);
            guestComponentList.push(
                <tr key={g}>
                    <td>{g+1}</td>
                    <td>
                        {isAdult === true && Culture.getText('RESERVATION_GUESTTYPE_ADULT')}
                        {isAdult === false && Culture.getText('RESERVATION_GUESTTYPE_CHILD')}
                    </td>
                    <td>
                        {guestObject.lastName || '—'}
                    </td>
                    <td>
                        {guestObject.firstName || '—'}
                    </td>
                    <td>
                        {guestObject.phone || '—'}
                    </td>
                    <td>
                        {guestObject.age || '—'}
                    </td>
                </tr>
            );
        }

        return (
            <div className="ReservationGuestList">
                <table cellSpacing={0} cellPadding={0}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>{Culture.getText('RESERVATION_CONTACT_GUESTTYPE')}</th>
                            <th>{Culture.getText('RESERVATION_CONTACT_LASTNAME')}</th>
                            <th>{Culture.getText('RESERVATION_CONTACT_FIRSTNAME')}</th>
                            <th>{Culture.getText('RESERVATION_CONTACT_PHONE')}</th>
                            <th>{Culture.getText('RESERVATION_CONTACT_AGE')}</th>
                        </tr>
                        {guestComponentList}
                    </tbody>
                </table>
            </div>
        );
    }

    /**
     * Renders the block list of the
     * reservation's block chain
     * 
     * @param {object} blockChain
     * the block chain object 
     */
    renderBlockList(blockChain){
        let result = [];

        let blockList = blockChain.getBlockList();
        for(let b=0; b<blockList.length; b++){
            let block = blockList[b];

            /* get the diff between this block and the next one */
            let blockDiff = {};
            let transactionDiffList = [];
            if(b < blockList.length-1){
                blockDiff = BlockChain.getDiffList(blockList[b+1].content,block.content);

                /* show the diff of this block compared to the other */
                if(Object.keys(blockDiff).length > 0){
                    let diffFieldKey = 0;
                    for(const updatedField of Object.keys(blockDiff)){
                        let fieldNameCode = ('BLOCKCHAIN_BLOCK_FIELD_'+updatedField);
                        let fieldNameText = Culture.getText(fieldNameCode);
                        if(fieldNameText !== fieldNameCode){
                            if(typeof blockDiff[updatedField]['ADDED'] === 'string'){
                                transactionDiffList.push(
                                    <div key={diffFieldKey} className="BlockChainHistoryItemTransactionDiff">
                                        <div className="BlockChainHistoryItemTransactionDiffTitle">
                                            <div className="BlockChainHistoryItemTransactionDiffFieldName">
                                                {fieldNameText}  
                                            </div>
                                            <div className="BlockChainHistoryItemTransactionDiffFieldType">
                                                {Culture.getText('BLOCKCHAIN_BLOCK_FIELDADDED')}
                                            </div>
                                        </div>
                                        <div className="BlockChainHistoryItemTransactionDiffFieldValue" data-action="new">
                                            <div className="BlockChainHistoryItemTransactionDiffFieldNewValue">
                                                {blockDiff[updatedField]['ADDED']}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }if(typeof blockDiff[updatedField]['NEW'] === 'string'){
                                let fieldValueOld = blockDiff[updatedField]['OLD'];
                                let fieldValueNew = blockDiff[updatedField]['NEW'];
                                if(updatedField === 'STATUS'){
                                    fieldValueOld = Culture.getText('RESERVATION_STATUS_'+fieldValueOld);
                                    fieldValueNew = Culture.getText('RESERVATION_STATUS_'+fieldValueNew);
                                }

                                transactionDiffList.push(
                                    <div key={diffFieldKey} className="BlockChainHistoryItemTransactionDiff">
                                        <div className="BlockChainHistoryItemTransactionDiffTitle">
                                            <div className="BlockChainHistoryItemTransactionDiffFieldName">
                                                {fieldNameText}
                                            </div>
                                            <div className="BlockChainHistoryItemTransactionDiffFieldType">
                                                {Culture.getText('BLOCKCHAIN_BLOCK_FIELDUPDATED')}
                                            </div>
                                        </div>
                                        <div className="BlockChainHistoryItemTransactionDiffFieldValue" data-action="updated">
                                            <div className="blockChainHistoryItemTransactionDiffFieldOldValue">
                                                {fieldValueOld}
                                            </div>
                                            <div className="BlockChainHistoryItemTransactionDiffFieldNewValue">
                                                {fieldValueNew}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        diffFieldKey++;
                    }
                }
            }else{
                transactionDiffList.push(
                    <div key='first_block' className="BlockChainHistoryItemTransactionDiff">
                        <div className="BlockChainHistoryItemTransactionDiffTitle">
                            <div className="BlockChainHistoryItemTransactionDiffFieldName">
                                {Culture.getText('BLOCKCHAIN_BLOCKSTATUS_CREATED_FIELDNAME')}
                            </div>
                            <div className="BlockChainHistoryItemTransactionDiffFieldType">
                                {Culture.getText('BLOCKCHAIN_BLOCKSTATUS_CREATED_FIELDTYPE')}
                            </div>
                        </div>
                        <div className="BlockChainHistoryItemTransactionDiffFieldValue" data-action="new">
                            <div className="BlockChainHistoryItemTransactionDiffFieldNewValue">
                                {Culture.getText('BLOCKCHAIN_BLOCKSTATUS_CREATED_TEXT')}
                            </div>
                        </div>
                    </div>
                );
            }

            result.push(
                <EditorSection key={b}
                    title={(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                        dateStyle: 'short',
                        timeStyle: 'medium'
                    })).format(block.dateTime)} 
                    subtitle={block.transactionId}
                    helpText={Culture.getText('RESERVATION_BLOCKCHAIN_BLOCKINFO')}>
                    <div className="BlockChainHistoryItemTransactionDiffList">
                        {transactionDiffList}
                    </div>

                    <ReservationInformationField title={Culture.getText('RESERVATION_BLOCKCHAIN_BLOCK_HASH')}>
                        {block.blockHash}
                    </ReservationInformationField>

                    <div className="ReservationInformationFieldList">
                        <ReservationInformationField title={Culture.getText('RESERVATION_BLOCKCHAIN_BLOCK_TRANSACTIONID')}>
                            {block.transactionId}
                        </ReservationInformationField>
                        <ReservationInformationField title={Culture.getText('RESERVATION_BLOCKCHAIN_BLOCK_CREATED')}>
                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                dateStyle: 'full',
                                timeStyle: 'medium'
                            })).format(blockChain.getBlock().dateTime)} 
                        </ReservationInformationField>
                    </div>
                </EditorSection>
            );
        }

        return result;
    }

    /**
     * Renders the reservation itself
     * and displays all the fields of
     * the reservation
     */
    renderReservation(){
        /* construct the blockchain reservation object */
        let blockChain = new BlockChain(this.props.reservation,this.props.transactionId);

        /* determine whether this reservation is cancelled or not */
        let statusCode = blockChain.getStatus();

        let isCancelled = false;
        if(statusCode === 'CANCELLED'){ isCancelled = true; }

        /* true if the guest returned from the property and
            the transaction is completed, thus fully closed */
        let returnDateTs = (blockChain.getBlock().content.quotation.return*1000);
        let isTransactionClosed = (returnDateTs < Date.now());
        const isMarketplace = blockChain.isMarketplaceBooking();

        /* get the external record locator */
        let externalRecordLocator = blockChain.getExternalRecordLocator();

        /* define the customer for the header */
        let customerObject = blockChain.getBlock().content.booking.customer;
        let customerFullName = [customerObject.lastName, customerObject.firstName].join(', ');
        let arrivalDateString = Intl.DateTimeFormat(Culture.getCultureCode(),{
            dateStyle: 'full'
        }).format((blockChain.getBlock().content.quotation.arrival*1000));
        let returnDateString = Intl.DateTimeFormat(Culture.getCultureCode(),{
            dateStyle: 'full'
        }).format((blockChain.getBlock().content.quotation.return*1000));

        /* return the rendered contents */
        return(
            <>
                <ContentHeaderMenu title={customerFullName}
                    subtitle={[arrivalDateString,returnDateString].join(' – ')}
                    backLinkText={Culture.getText('RESERVATION_VIEW_BACKLINK')}
                    onBackLinkClick={() => {
                        if(typeof this.props.onClose === 'function'){
                            this.props.onClose();
                        }
                    }} />
                <div className="ReservationEditor">
                    <div className="ReservationEditorContent">
                        <SubNavigationList>
                            <section code="reservation" name={Culture.getText('RESERVATION_MENU_INFORMATION')}>
                                <EditorSection title={Culture.getText('RESERVATION_STATUS_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_STATUS_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_STATUS_HELPTEXT')}>
                                    <ReservationInformationField title={Culture.getText('RESERVATION_STATUS')}>
                                        {[statusCode, Culture.getText('RESERVATION_STATUS_'+statusCode)].join(' — ')}
                                    </ReservationInformationField>

                                    {isCancelled === false && isTransactionClosed === false && isMarketplace === false &&
                                        <div className="ReservationEditorButtonList">
                                            <StandardButton className="ReservationStatusChangeButton"
                                                text={Culture.getText('RESERVATION_CHANGESTATUS_BUTTON')}
                                                onClick={() => this.setState({
                                                    updateStatusRequest: true,
                                                    updateStatusValue: statusCode
                                                })} />

                                            <StandardButton className="ReservationStatusCancelButton"
                                                text={Culture.getText('RESERVATION_CHANGESTATUS_CANCEL')}
                                                onClick={() => this.setState({
                                                    updateStatusRequest: true,
                                                    updateStatusValue: 'CANCELLED'
                                                })} />
                                        </div>
                                    }

                                    {this.state.updateStatusRequest === true &&
                                        <ModalDrawerOverlay titleText={Culture.getText('RESERVATION_UPDATESTATUS_TITLE')}
                                            subtitleText={Culture.getText('RESERVATION_UPDATESTATUS_SUBTITLE')}
                                            introText={Culture.getText('RESERVATION_UPDATE_PROMPT')}
                                            submitButtonText={Culture.getText('RESERVATION_UPDATE_CONFIRM')}
                                            submitDisabled={(this.state.updateStatusValue === statusCode)}
                                            onSubmit={(function(){
                                                if(typeof this.props.onUpdateStatus === 'function'){
                                                    this.props.onUpdateStatus(this.state.updateStatusValue);
                                                }
        
                                                this.setState({
                                                    updateStatusRequest: false,
                                                    updateStatusValue: ''
                                                });
                                            }).bind(this)}
                                            onClose={() => this.setState({updateStatusRequest: false, updateStatusValue: ''})}>
                                            <DataInputList title={Culture.getText('RESERVATION_STATUS')} 
                                                value={this.state.updateStatusValue} list={this.getStatusList()}
                                                onChange={this.updateStatus.bind(this)}/>
                                        </ModalDrawerOverlay>
                                    }
                                </EditorSection>

                                <EditorSection title={Culture.getText('RESERVATION_INFORMATION_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_INFORMATION_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_INFORMATION_HELPTEXT')}>
                                    <div className="ReservationInformationFieldList">
                                        <ReservationInformationField title={Culture.getText('RESERVATION_RECORDLOCATOR')}>
                                            {blockChain.getRecordLocator()}
                                        </ReservationInformationField>

                                        {externalRecordLocator !== false &&
                                            <ReservationInformationField title={Culture.getText('RESERVATION_EXTERNALRECORDLOCATOR')}>
                                                {externalRecordLocator}
                                            </ReservationInformationField>
                                        }
                                    </div>

                                    <div className="ReservationInformationFieldList">
                                        <ReservationInformationField title={Culture.getText('RESERVATION_BLOCKCHAIN_CREATED')}>
                                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                dateStyle: 'full',
                                                timeStyle: 'medium'
                                            })).format(blockChain.getCreatedDate())} 
                                        </ReservationInformationField>

                                        <ReservationInformationField title={Culture.getText('RESERVATION_BLOCKCHAIN_LASTUPDATED')}>
                                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                dateStyle: 'full',
                                                timeStyle: 'medium'
                                            })).format(blockChain.getLastUpdatedDate())}
                                        </ReservationInformationField>
                                    </div>

                                    <ReservationInformationField title={Culture.getText('RESERVATION_BLOCKCHAIN_BLOCKHASH')}>
                                        {blockChain.getBlockHash()}
                                    </ReservationInformationField>
                                </EditorSection>

                                <EditorSection title={Culture.getText('RESERVATION_COMMENT_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_COMMENT_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_COMMENT_HELPTEXT')}>
                                    <ReservationInformationField title={Culture.getText('RESERVATION_COMMENT')}>
                                        {blockChain.getBlock().content.booking.comment}
                                    </ReservationInformationField>
                                </EditorSection>

                                {isMarketplace === false &&
                                    <EditorSection title={Culture.getText('RESERVATION_PAYMENT_TITLE')}
                                        subtitle={Culture.getText('RESERVATION_PAYMENT_SUBTITLE')}
                                        helpText={Culture.getText('RESERVATION_PAYMENT_HELPTEXT')}
                                        introText={(function(){
                                            let result = null;
    
                                            if(blockChain.hasExternalPayment() === false){
                                                result = Culture.getText('RESERVATION_PAYMENT_NOPAYMENT');
                                            }
    
                                            return result;
                                        })()}>
                                        {blockChain.hasExternalPayment() === true &&
                                            <ReservationExternalPaymentView value={blockChain.getExternalPayment()} /> 
                                        }
                                    </EditorSection>
                                }

                                {isCancelled === false && isTransactionClosed === false && isMarketplace === false &&
                                    <EditorSection title={Culture.getText('RESERVATION_CANCELLATION_TITLE')}
                                        subtitle={Culture.getText('RESERVATION_CANCELLATION_SUBTITLE')}
                                        introText={Culture.getText('RESERVATION_CANCELLATION_INTROTEXT')}
                                        helpText={Culture.getText('RESERVATION_CANCELLATION_HELPTEXT')}>
                                        <ReservationInformationField title={Culture.getText('RESERVATION_CANCELLATION_CHARGE_DUE')}>
                                            {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                                style: 'currency',
                                                currency: blockChain.getCommitted().content.quotation.price.currencyCode
                                            })).format(blockChain.getCommitted().content.quotation.cancellationCharge.amount)}
                                        </ReservationInformationField>
                                    </EditorSection>
                                }
                            </section>
                            <section code="services" name={Culture.getText('RESERVATION_MENU_SERVICES')}>
                                <EditorSection title={Culture.getText('RESERVATION_ACCOMMODATION_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_ACCOMMODATION_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_ACCOMMODATION_HELPTEXT')}>
                                    <ReservationInformationField title={Culture.getText('PROPERTY_NAME')}>
                                        {blockChain.getBlock().content.property.name}
                                    </ReservationInformationField>

                                    <div className="ReservationInformationFieldList">
                                        <ReservationInformationField title={Culture.getText('DATAFIELD_UNIT_NAME')}>
                                            {(function(){
                                                let content = blockChain.getBlock().content;
                                                let unitName = '';
                                                for(const unitItem of new Property(content.property).getGuestRoomList()){
                                                    if(unitItem.roomId === content.quotation.unitId){
                                                        unitName = unitItem.name;
                                                    }
                                                }

                                                return unitName;
                                            })()}
                                        </ReservationInformationField>

                                        <ReservationInformationField title={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME')}>
                                            {blockChain.getBlock().content.quotation.productName}
                                        </ReservationInformationField>
                                    </div>

                                    <div className="ReservationInformationFieldList">
                                        <ReservationInformationField title={Culture.getText('PROPERTY_AVAILABILITY_RATE_MEALPLAN')}>
                                            {(function(){
                                                let mealPlanNameList = [];
                                                let content = blockChain.getBlock().content;
                                                for(const mealPlan of content.quotation.mealPlanList){
                                                    mealPlanNameList.push(Culture.getText('MEALPLANNAME_'+mealPlan.code));
                                                }
                                                return mealPlanNameList.join(', ');
                                            })()}
                                        </ReservationInformationField>

                                        {function(){
                                            let result = null;

                                            let policy = blockChain.getBlock().content.quotation.policy;
                                            if(typeof policy ==='object' && policy !== null){
                                                if(typeof policy.petsPolicy === 'object' && policy.petsPolicy !== null){
                                                    let petsAllowed = null;
                                                    let petsPolicyTextToken = 'PROPERTY_POLICY_PETSNOTALLOWED';
                                                    if(policy.petsPolicy.allowed === true){
                                                        petsPolicyTextToken = 'PROPERTY_POLICY_PETSALLOWED';
                                                        petsAllowed = true;

                                                        if(policy.petsPolicy.freeOfCharge === true){
                                                            petsPolicyTextToken = 'PROPERTY_POLICY_PETSFREEOFCHARGE';
                                                        }if(policy.petsPolicy.byArrangement === true){
                                                            petsPolicyTextToken = 'PROPERTY_POLICY_PETSBYARRANGEMENT';
                                                        }
                                                    }else{
                                                        petsAllowed = false;
                                                    }
                                            
                                                    if(petsAllowed !== null){
                                                        result = (
                                                            <ReservationInformationField title={Culture.getText('PROPERTY_POLICY_PETSPOLICY_TITLE')}>
                                                                {Culture.getText(petsPolicyTextToken)}
                                                            </ReservationInformationField>
                                                        );
                                                    }
                                                }
                                            }

                                            return result;
                                        }()}
                                    </div>
                                </EditorSection>

                                <EditorSection title={Culture.getText('RESERVATION_STAY_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_STAY_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_STAY_HELPTEXT')}>
                                    <div className="ReservationInformationFieldList">
                                        <ReservationInformationField title={Culture.getText('QUOTATION_CHECKIN_DATE')}>
                                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                dateStyle: 'full'
                                            })).format(blockChain.getBlock().content.quotation.arrival*1000)}
                                        </ReservationInformationField>

                                        {(function(){
                                            let result = null;

                                            let quotation = new Quotation(blockChain.getBlock().content.quotation);
                                            if(quotation.getEarliestCheckInTime() !== false){
                                                result = (
                                                    <ReservationInformationField title={Culture.getText('QUOTATION_CHECKIN_TIME')}>
                                                        {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                            timeStyle: 'short'
                                                        })).format(quotation.getEarliestCheckInTime())}
                                                    </ReservationInformationField>
                                                );
                                            }

                                            return result;
                                        })()}
                                    </div>

                                    <div className="ReservationInformationFieldList">
                                        <ReservationInformationField title={Culture.getText('QUOTATION_CHECKOUT_DATE')}>
                                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                dateStyle: 'full'
                                            })).format(blockChain.getBlock().content.quotation.return*1000)}
                                        </ReservationInformationField>

                                        {(function(){
                                            let result = null;

                                            let quotation = new Quotation(blockChain.getBlock().content.quotation);
                                            if(quotation.getLatestCheckOutTime() !== false){
                                                result = (
                                                    <ReservationInformationField title={Culture.getText('QUOTATION_CHECKOUT_TIME')}>
                                                        {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                            timeStyle: 'short'
                                                        })).format(quotation.getLatestCheckOutTime())}
                                                    </ReservationInformationField>
                                                );
                                            }

                                            return result;
                                        })()}
                                    </div>

                                    <ReservationInformationField title={Culture.getText('QUOTATION_LENGTHOFSTAY')}>
                                        {(function(){
                                            let quotation = new Quotation(blockChain.getBlock().content.quotation);
                                            let result = Culture.getText('QUOTATION_LENGTHOFSTAY_NIGHTS')
                                                                .replace('{value}',quotation.lengthOfStay);
                                            if(quotation.lengthOfStay === 1){
                                                result = Culture.getText('QUOTATION_LENGTHOFSTAY_NIGHTS_SINGLE')
                                                                .replace('{value}',quotation.lengthOfStay);
                                            }

                                            return result;
                                        })()}
                                    </ReservationInformationField>
                                </EditorSection>

                                <EditorSection title={Culture.getText('RESERVATION_QUOTATION_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_QUOTATION_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_QUOTATION_HELPTEXT')}>
                                    <QuotationView content={blockChain.getBlock().content} />
                                </EditorSection>
                            </section>
                            <section code="contact" name={Culture.getText('RESERVATION_MENU_CONTACT')}>
                                <EditorSection title={Culture.getText('RESERVATION_CUSTOMER_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_CUSTOMER_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_CUSTOMER_HELPTEXT')}>
                                    {this.renderContact(blockChain.getBlock().content.booking.customer)}

                                    {blockChain.getBlock().content.booking.marketingOptIn === true &&
                                        <div className="ContentWarningMessage">
                                            <div className="ContentWarningMessageTitle">
                                                {Culture.getText('RESERVATION_MARKETINGOPTIN_TITLE')}
                                            </div>
                                            <div className="ContentWarningMessageText">
                                                {Culture.getText('RESERVATION_MARKETINGOPTIN')}
                                            </div>
                                        </div>
                                    }

                                    {blockChain.getBlock().content.booking.marketingOptIn === false &&
                                        <div className="ContentWarningMessage">
                                            <div className="ContentWarningMessageTitle">
                                                {Culture.getText('RESERVATION_NOMARKETINGOPTIN_TITLE')}
                                            </div>
                                            <div className="ContentWarningMessageText">
                                                {Culture.getText('RESERVATION_NOMARKETINGOPTIN')}
                                            </div>
                                        </div>
                                    }
                                </EditorSection>

                                <EditorSection title={Culture.getText('RESERVATION_GUESTLIST_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_GUESTLIST_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_GUESTLIST_HELPTEXT')}>
                                    {this.renderGuestList(blockChain.getBlock().content)}
                                </EditorSection>
                            </section>
                            <section code="blockchain" name={Culture.getText('RESERVATION_MENU_BLOCKCHAIN')}>
                                <EditorSection title={Culture.getText('RESERVATION_BLOCKCHAIN_TITLE')}
                                    subtitle={Culture.getText('RESERVATION_BLOCKCHAIN_SUBTITLE')}
                                    helpText={Culture.getText('RESERVATION_BLOCKCHAIN_HELPTEXT')}
                                    introText={Culture.getText('RESERVATION_BLOCKCHAIN_INTROTEXT')}>
                                </EditorSection>

                                {this.renderBlockList(blockChain)}
                            </section>
                        </SubNavigationList>
                    </div>
                </div>
            </>
        );
    }

    /**
     * Renders the fields and components
     * of the reservation editor
     */
    render(){
        if(this.props.reservation === null){
            if(this.props.isLoading === true){
                return(
                    <div className="ReservationEditorLoading">
                        <LoadingIndicator />
                    </div>
                );
            }else{
                if(this.props.failed === true){
                    return(
                        <div className="ReservationEditor">
                            <div className="ReservationEditorContent">
                                <div className="ReservationEditorRetrieveFailed">
                                    {Culture.getText('RESERVATION_RETRIEVE_FAILED')}
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }else{
            /* render the reservation editor */
            return this.renderReservation();
        }
    }
}

export default ReservationEditor;