/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the range slider component */
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

/* require the classes for this component */
import Culture from '../../../class/Culture';
import ModalDrawerOverlay from '../../common/ModalDrawerOverlay';

/**
 * This component provides the star rating filter
 * input to set the star rating for the filter
 */
class MarketplaceStarRatingFilter extends React.Component {
    state = {
        rangeValue: []
    }

    /**
     * Notifies the parent component about
     * any updates to the filter selection
     */
    notifyUpdate(){
        let selectedRange = [
            (Number(this.props.value.minStarRating) || 0),
            (Number(this.props.value.maxStarRating) || 5)
        ];

        if(this.state.rangeValue.length === 2){
            selectedRange = this.state.rangeValue;
        }

        let searchQuery = this.props.value;
        if(selectedRange[0] === 0 && selectedRange[1] === 5){
            delete searchQuery.minStarRating;
            delete searchQuery.maxStarRating;
        }else{
            searchQuery.minStarRating = selectedRange[0];
            searchQuery.maxStarRating = selectedRange[1];
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(searchQuery);
        }
    }

    /**
     * Returns the text representation of the
     * currently defined star rating filter
     */
    getSelectedText(){
        let selectedRange = [
            (Number(this.props.value.minStarRating) || 0),
            (Number(this.props.value.maxStarRating) || 5)
        ];

        if(this.state.rangeValue.length === 2){
            selectedRange = this.state.rangeValue;
        }

        return Culture.getText('MARKETPLACE_FILTER_STARRATING_VALUE')
                .replace('{{VAR_1}}',selectedRange[0])
                .replace('{{VAR_2}}',selectedRange[1]);
    }

    /**
     * Renders the component with the selection
     * option for the star rating filter
     */
    render(){
        return(
            <ModalDrawerOverlay
                onClose={this.props.onClose}
                titleText={Culture.getText('MARKETPLACE_FILTER_STARRATING_TITLE')}
                subtitleText={Culture.getText('MARKETPLACE_FILTER_STARRATING_TEXT')}
                introText={Culture.getText('MARKETPLACE_FILTER_STARRATING_INTRO')}
                submitButtonText={Culture.getText('MARKETPLACE_FILTER_APPLY')}
                onSubmit={this.notifyUpdate.bind(this)}>
                <div className="MarketplaceStarRatingFilter MarketplaceFilter">
                    <RangeSlider className="MarketplaceStarRatingFilterSlider"
                        min={0} max={5}
                        defaultValue={[
                            (Number(this.props.value.minStarRating) || 0),
                            (Number(this.props.value.maxStarRating) || 5)
                        ]}
                        onInput={(value) => this.setState({rangeValue: value})} />
                    <div className="MarketplaceStarRatingFilterText">
                        {this.getSelectedText()}
                    </div>
                </div>
            </ModalDrawerOverlay>
        );
    }
}

export default MarketplaceStarRatingFilter;