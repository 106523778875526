/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

const LOCALSTORAGE_CULTUREKEY = 'USERCULTURE';

/**
 * The culture list contains all available
 * culture settings for this application
 */
const CultureList = {
    en: require('../config/culture/en.json'),
    de: require('../config/culture/de.json')
}

/* defines the default fallback culture code */
const DefaultCultureCode = 'en';

/**
 * This class manages all culture and language
 * related settings for the user interface
 */
class Culture {
    /* defines the currently active code */
    CultureCode = null;
    CultureComponentList = [];

    /**
     * Returns the localised list
     * of all available US states
     */
    static getUSStateList(){
        let result = [];

        for(const textToken of Object.keys(CultureList[this.getCultureCode()].text)){
            if(textToken.startsWith('US_STATE_')){
                result.push({
                    code: textToken.substring(textToken.lastIndexOf('_')+1),
                    name: CultureList[this.getCultureCode()].text[textToken]
                })
            }
        }

        result.sort((a,b) => (a.name > b.name) 
            ? 1 : ((b.name > a.name) ? -1 : 0));

        return result;
    }

    /**
     * Returns the localised text for the text
     * token provided in the parameters
     * 
     * @param {string} token
     * the text token for the localised text 
     * 
     * @param {string} cultureCode
     * optional param to define culture code
     */
    static getText(token,cultureCode){
        let result = token;
        
        /* check if the propert culture code was provided
            in the parameters and if not use the currently
            set culture code for the ui */
        let selectedCode = cultureCode;
        if(typeof selectedCode !== 'string'){
            cultureCode = Culture.getCultureCode();
        }

        /* get the culture information */
        let cultureInfo = CultureList[cultureCode];
        if(cultureInfo.hasOwnProperty('text')){
            if(cultureInfo.text.hasOwnProperty(token)){
                result = cultureInfo.text[token];
            }
        }

        return result;
    }

    /**
     * Returns the thousand decimal separator
     * symbol to allowed localised transformation
     * of numbers, especially price values
     */
    static getNumberSeparatorConfig(){
        let result = {
            thousand: ",",
            decimal: "."
        };

        let cultureInfo = CultureList[Culture.getCultureCode()];
        if(cultureInfo.hasOwnProperty("thousandSeparator")){
            result.thousand = cultureInfo["thousandSeparator"];
        }if(cultureInfo.hasOwnProperty("decimalSeparator")){
            result.decimal = cultureInfo["decimalSeparator"];
        }

        return result;
    }

    /**
     * Converts the provided localised number
     * string to a float value and returns it
     * taking into account the localised number
     * format with thousand separators
     * 
     * @param {string} value 
     * the number string in the current culture
     */
    static stringToFloatLocalised(value){
        let result = 0;

        if(typeof value === 'string'){
            let numberConfig = Culture.getNumberSeparatorConfig();

            let numberString = "";
            for(let c=0; c<value.length; c++){
                if(value[c] !== numberConfig.thousand){
                    if(value[c] === numberConfig.decimal){
                        numberString += ".";
                    }else{
                        numberString += value[c];
                    }
                }
            }

            result = parseFloat(numberString);
        }

        return result;
    }

    /**
     * Formats a float number to a string using
     * the local format of the current culture
     * set for the user interface
     * 
     * @param {number} value
     * the float value to convert to string 
     */
    static formatFloatToString(value){
        let result = parseFloat(value).toLocaleString(Culture.getCultureCode(),
                            {minimumFractionDigits:2,maximumFractionDigits:8});

        /* do not render the decimals if the number does not have any */
        if(parseFloat(value) % 1 === 0){
            result = parseFloat(value).toLocaleString(Culture.getCultureCode(),
                            {minimumFractionDigits:0,maximumFractionDigits:0});
        }

        return result;
    }

    /**
     * Returns true when the provided culture
     * code is an available culture and otherwise
     * it'll return false
     * 
     * @param {string} cultureCode
     * culture code string to check support for 
     */
    static isCultureAvailable(cultureCode){
        let result = false;

        this.getAvailableCultureList().forEach((culture) => {
            if(culture.code === cultureCode){
                result = true;
            }
        });

        return result;
    }

    /**
     * Returns an array with all available
     * culture codes to use with this application
     */
    static getAvailableCultureList(){
        let result = [];

        Object.keys(CultureList).forEach((cultureCode) => {
            result.push({
                code: cultureCode,
                name: CultureList[cultureCode].name
            });
        });

        return result;
    }

    /**
     * Attaches a component that is forced to
     * rerender when the culture changes
     * 
     * @param {object} component 
     * component to rerender when culture changes
     */
    static attachCultureComponent(component){
        if(typeof component === 'object'){
            if(!Array.isArray(Culture.CultureComponentList)){
                Culture.CultureComponentList = [];
            }

            Culture.CultureComponentList.push(component);
        }
    }

    /**
     * Sets the culture code for the current
     * session and stores it in the local storage
     * 
     * @param {string} value
     * culture code to use in current session 
     */
    static setCultureCode(value){
        if(Object.keys(CultureList).includes(value)){
            Culture.CultureCode = value;

            let settings = Culture.getCultureSettings();
            if(settings === null){ settings = {}; }
            settings['cultureCode'] = value;
            localStorage.setItem(LOCALSTORAGE_CULTUREKEY,JSON.stringify(settings));

            /* call the attached handlers */
            if(Array.isArray(Culture.CultureComponentList)){
                let updatedComponentList = [];
                Culture.CultureComponentList.forEach((component) => {
                    if(component.updater.isMounted(component)){
                        if(typeof component.forceUpdate === 'function'){
                            component.forceUpdate();
                            updatedComponentList.push(component);
                        }
                    }
                });

                /* update the component list with the updated list
                    that no longer contains the unmounted components */
                Culture.CultureComponentList = updatedComponentList;
            }
        }
    }

    /**
     * Returns the currently set language
     * code for this user session
     */
    static getCultureCode(){
        let result = Culture.CultureCode;

        if(result === null || result === undefined){
            let settings = Culture.getCultureSettings();
            if(settings !== null){
                if(typeof settings.cultureCode === 'string'){
                    result = settings.cultureCode;
                }
            }
        }

        if(result === null || result === undefined){
            result = DefaultCultureCode;
        }

        return result;
    }

    /**
     * Returns the locally stored culture
     * settings for the user
     */
    static getCultureSettings(){
        let result = null;

        try{
            let cultureSetting = localStorage.getItem(LOCALSTORAGE_CULTUREKEY);
            if(cultureSetting !== null){
                result = JSON.parse(cultureSetting);
            }
        }catch(ex){
            result = null;
        }

        return result;
    }

    /**
     * Returns the local configuration
     * for the calendar or date picker
     * in the currently set language
     */
    static getCalendarLocale(){
        let monthList = [];
        for(let m=0; m<13; m++){
            let dateObject = new Date();
            dateObject.setDate(1);
            dateObject.setMonth(m);
            monthList.push(dateObject.toLocaleString(Culture.getCultureCode(), { month: "long" }));
        }

        let dayList = [];
        for(let d=0; d<7; d++){
            for(let n=1; n<8; n++){
                let dateObject = new Date(1970,1,n);
                if(dateObject.getDay() === d){
                    dayList.push(dateObject.toLocaleDateString(Culture.getCultureCode(),{weekday: 'short'}));
                }
            }
        }

        return {
            localize: {
                month: n => monthList[n],
                day: n => dayList[n],
            },
            options: {
                weekStartsOn: 1
            },
            formatLong: {
                date: () => 'yyyy-mm-dd'
            }
        };
    }
}

export default Culture;