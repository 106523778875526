/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import RangeSlider from 'react-range-slider-input/dist/components/RangeSlider';

/* require the classes for this component */
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';

/* include the style for this component */
import '../../style/MarketplaceImportInput.css'

/* import the required components for this component */
import DataInputField from '../common/DataInputField';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import LoadingIndicator from '../common/LoadingIndicator';
import ManagementUser from '../../class/ManagementUser';

/**
 * This component provides the methods and control
 * to manage the import filters of the tenant
 */
class MarketplaceImportInput extends React.Component{
    state = {
        filterId: false,
        filterList: [],
        loading: true,
        showImportFilterList: false,
        showSaveDialog: false,
        newFilterName: '',
        filterImportCount: 50,
        inProgress: false,
        updateFailed: false
    }

    /**
     * Fetches the current filter list of
     * this tenant and stores it in the state
     */
    componentDidMount(){
        this.getFilterList();
    }

    /**
     * Returns the currently selected filter
     * object or false if none is selected
     */
    getSelectedFilter(){
        let result = false;

        for(const filter of this.state.filterList){
            if(filter.filterId === this.state.filterId){
                result = filter;
            }
        }

        return result;
    }

    /**
     * Returns the name of the currently selected
     * filter or an empty string if none is defined
     */
    getSelectedFilterName(){
        return ((this.getSelectedFilter() || {}).filterName || '');
    }

    /**
     * Fetches the filter list of this tenant
     * and stores the list in the state
     */
    async getFilterList(){
        this.setState({loading: true});
        
        let apiResult = await ApiClient.execute('/market/filterList');
        if(Array.isArray(apiResult.list)){
            this.setState({filterList: apiResult.list});
        }

        /* apply the limit of the selected filter */
        let filterLimit = ((this.getSelectedFilter() || {}).limit || 50);
        this.setState({
            loading: false,
            filterImportCount: filterLimit
        });
    }

    /**
     * Creates or updates the filter with
     * the api and loads the filter item
     * 
     * @param {boolean} createAsNew
     * (OPTIONAL) if true, creates the filter as a new filter
     */
    async saveFilter(createAsNew){
        this.setState({inProgress: true});

        /* define the request object to save */
        let filterQuery = Object.assign({}, this.props.filterQuery);
        filterQuery.limit = this.state.filterImportCount;

        /* delete any offset that was defined */
        delete filterQuery.offset;

        let requestObject = {
            filterName: (this.state.newFilterName || this.getSelectedFilterName()),
            filterObject: filterQuery,
            tenantRegion: ManagementUser.getSystemRegionCode()
        };

        /* assign the filter id if any exists */
        if(this.state.filterId !== false && createAsNew !== true){
            requestObject.filterId = this.state.filterId;
        }

        /* execute the save filter request */
        const apiResult = await ApiClient.execute('/market/saveFilter',requestObject);
        if((apiResult || {}).created === true){
            /* fetch the updated filter list */
            await this.getFilterList();
            this.setState({
                inProgress: false, 
                updateFailed: false,
                filterId: apiResult.filterId,
                showCreateDialog: false,
                showSaveDialog: false
            },this.getFilterList.bind(this));

            /* reset the parent components to an empty filter */
            if(typeof this.props.onUpdate === 'function'){
                this.props.onUpdate({});
            }
        }else{
            this.setState({
                inProgress: false, 
                updateFailed: true
            });
        }
    }

    /**
     * Removes the currently selected
     * import filter from the import
     * filter list
     */
    async removeFilter(){
        if(this.state.filterId !== false){
            this.setState({inProgress: true});

            const apiResult = await ApiClient.execute('/market/removeFilter',{
                filterId: this.state.filterId
            });

            if((apiResult || {}).removed === true){
                this.setState({
                    inProgress: false, 
                    updateFailed: false,
                    filterId: false,
                    showDeleteDialog: false
                },this.getFilterList.bind(this));
            }else{
                this.setState({
                    inProgress: false, 
                    updateFailed: true
                });
            }
        }
    }

    /**
     * Renders the list of all currently active
     * import filters of this tenant and returns
     * the rendered components
     */
    renderImportFilterList(){
        let result = [];

        if(this.state.filterId !== false){
            result.push(
                <div key="createNewFilter" 
                    className="MarketplaceImportFilterListItem"
                    data-createnew={true}
                    onClick={(function(){
                        /* update the filter id in the state */
                        this.setState({
                            filterId: false,
                            showImportFilterList: false
                        });
    
                        /* notify the parent of the filter change */
                        if(typeof this.props.onUpdate === 'function'){
                            this.props.onUpdate({});
                        }
                    }).bind(this)}>
                    <div className="MarketplaceImportFilterListItemName">
                        {Culture.getText('MARKETPLACE_IMPORTFILTER_NEWIMPORTFILTER')}
                    </div>
                </div>
            );
        }

        for(const importFilter of this.state.filterList){
            result.push(
                <div key={importFilter.filterId} 
                    className="MarketplaceImportFilterListItem"
                    onClick={(function(filter){
                        /* update the filter id in the state */
                        this.setState({
                            filterId: filter.filterId,
                            showImportFilterList: false
                        });

                        /* notify the parent of the filter change */
                        if(typeof this.props.onUpdate === 'function'){
                            this.props.onUpdate(filter.filterObject);
                        }
                    }).bind(this,importFilter)}>
                    <div className="MarketplaceImportFilterListItemName">
                        {importFilter.filterName}
                    </div>
                </div>
            );
        }

        return result;
    }

    /**
     * Renders and returns the range slider for
     * the maximum number of properties to import
     */
    renderPropertyLimitSelect(){
        let maxImportCount = 50;
        let defaultCount = this.state.filterImportCount || 50;
        const tenantConfig = ManagementUser.getCurrent().getTenantConfig();
        const isImportLimited = (tenantConfig.marketplaceLimit !== false);
        if(isImportLimited === false){ maxImportCount = 5000; }

        if(this.state.showSaveDialog === true){
            let filter = this.getSelectedFilter();
            if(filter !== false){
                defaultCount = (this.state.filterImportCount || filter.filterObject.limit || 5000);
                if(defaultCount > maxImportCount){ maxImportCount = defaultCount; }
            }
        }

        /* format the numbers for both the import count and the
            import limit defined for this tenant */
        const importLimitText = Intl.NumberFormat(Culture.getCultureCode())
                                    .format(maxImportCount);
        const importCountText = Intl.NumberFormat(Culture.getCultureCode())
                                    .format(this.state.filterImportCount);

        return(
            <div className="MarketplaceImportFilterLimitSelect MarketplaceFilter">
                <RangeSlider className="single-thumb"
                    min={1} max={maxImportCount}
                    defaultValue={[1, defaultCount]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                    onInput={(value) => this.setState({filterImportCount: value[1]})} />
                <div className="MarketplaceImportFilterLimitSelectValueText">
                    {Culture.getText('MARKETPLACE_IMPORTFILTER_PROPERTYCOUNT')
                        .replace('{{VAR_1}}',importCountText)}
                </div>
                <div className="MarketplaceImportFilterLimitInfoText">
                    {Culture.getText('MARKETPLACE_IMPORTFILTER_IMPORTLIMIT_TEXT')
                        .replace('{{VAR_1}}',importLimitText)}
                </div>
            </div>
        );   
    }

    /**
     * Renders the component with the controls
     * to update and create import filters
     */
    render(){
        return(
            <div className="MarketplaceImportInput">
                <div className="MarketplaceImportInputActionList"
                    data-propertycount={this.props.totalPropertyCount}>
                    {this.state.filterId !== false &&
                        <>
                            <div className="MarketplaceImportInputActionFilterName"
                                onClick={() => this.setState({showImportFilterList: true})}>
                                {this.getSelectedFilterName()}
                            </div>

                            <div className="MarketplaceImportInputAction" 
                                data-type="save"
                                data-filterupdate={this.state.filterId !== false}
                                onClick={() => this.setState({showSaveDialog: true})}>
                                {Culture.getText('MARKETPLACE_IMPORTFILTER_SAVE')}
                            </div>
                        </>
                    }

                    <div className="MarketplaceImportInputAction" 
                        data-type="new"
                        data-filterupdate={this.state.filterId !== false}
                        onClick={() => this.setState({showCreateDialog: true, newFilterName: ''})}>
                        {this.state.filterId === false && Culture.getText('MARKETPLACE_IMPORTFILTER_SAVE_NEW')}
                        {this.state.filterId !== false && Culture.getText('MARKETPLACE_IMPORTFILTER_SAVE_AS')}
                    </div>

                    {this.state.filterId !== false &&
                        <div className="MarketplaceImportInputAction" 
                            data-type="delete"
                            onClick={() => this.setState({showDeleteDialog: true})}>
                            {Culture.getText('MARKETPLACE_IMPORTFILTER_DELETE')}
                        </div>
                    }   
                </div>
                <div className="MarketplaceImportInputStatus" 
                    data-loading={this.state.loading === true}
                    data-exist={this.state.filterList.length > 0}
                    onClick={(function(){
                        if(this.state.filterList.length > 0){
                            this.setState({showImportFilterList: true});
                        }
                    }).bind(this)}>
                    {this.state.loading === true && Culture.getText('MARKETPLACE_IMPORTFILTER_LOADING')}
                    {this.state.loading === false 
                        && this.state.filterList.length === 0
                        && Culture.getText('MARKETPLACE_IMPORTFILTER_NONE')}
                    {this.state.loading === false 
                        && this.state.filterList.length > 0
                        && Culture.getText('MARKETPLACE_IMPORTFILTER_FILTERCOUNT')
                            .replace('{{VAR_1}}',this.state.filterList.length)}
                </div>

                {this.state.showImportFilterList === true &&
                    <ModalDrawerOverlay
                        onClose={() => this.setState({showImportFilterList: false})}
                        titleText={Culture.getText('MARKETPLACE_IMPORTFILTER_LIST_TITLE')}
                        subtitleText={Culture.getText('MARKETPLACE_IMPORTFILTER_LIST_TEXT')}
                        introText={Culture.getText('MARKETPLACE_IMPORTFILTER_LIST_INTRO')}>
                        <div className="MarketplaceImportFilterList">
                            {this.renderImportFilterList()}
                        </div>
                    </ModalDrawerOverlay>
                }

                {this.state.showDeleteDialog === true &&
                    <ModalDrawerOverlay
                        onClose={() => {
                            if(this.state.inProgress !== true){
                                this.setState({
                                    showDeleteDialog: false
                                });
                            }
                        }}
                        className={(function(){
                            if(this.state.inProgress === true){
                                return 'MarketplaceImportFilterUpdateInProgressWindow';
                            }
                            return '';
                        }).bind(this)()}
                        titleText={Culture.getText('MARKETPLACE_IMPORTFILTER_DELETE_TITLE')}
                        subtitleText={Culture.getText('MARKETPLACE_IMPORTFILTER_DELETE_TEXT')}
                        introText={Culture.getText('MARKETPLACE_IMPORTFILTER_DELETE_INTRO')}
                        submitButtonText={Culture.getText('MARKETPLACE_IMPORTFILTER_DELETEBUTTON')}
                        submitDisabled={(this.state.inProgress === true)}
                        onSubmit={this.removeFilter.bind(this)}>
                        {this.state.inProgress === true &&
                            <div className="MarketplaceImportInputUpdateInProgress">
                                <LoadingIndicator />
                                {Culture.getText('MARKETPLACE_IMPORTFILTER_UPDATE_INPROGRESS')}
                            </div>
                        }

                        {this.state.inProgress === false &&
                            <>
                                {this.state.updateFailed === true &&
                                    <div className="MarketplaceImportFilterUpdateFailedMessage">
                                        {Culture.getText('MARKETPLACE_IMPORTFILTER_UPDATE_FAILED')}
                                    </div>
                                }

                                <DataInputField title={Culture.getText('MARKETPLACE_IMPORTFILTER_NAME')}
                                    disabled={true} value={this.getSelectedFilterName()} />
                            </>
                        }
                    </ModalDrawerOverlay>
                }

                {(this.state.showCreateDialog === true || this.state.showSaveDialog === true) && 
                    <ModalDrawerOverlay
                        onClose={() => {
                            if(this.state.inProgress !== true){
                                this.setState({
                                    showCreateDialog: false,
                                    showSaveDialog: false
                                });
                            }
                        }}
                        className={(function(){
                            if(this.state.inProgress === true){
                                return 'MarketplaceImportFilterUpdateInProgressWindow';
                            }
                            return '';
                        }).bind(this)()}
                        titleText={Culture.getText('MARKETPLACE_IMPORTFILTER_SAVE_TITLE')}
                        subtitleText={Culture.getText('MARKETPLACE_IMPORTFILTER_SAVE_TEXT')}
                        introText={Culture.getText('MARKETPLACE_IMPORTFILTER_SAVE_INTRO')}
                        submitButtonText={Culture.getText('MARKETPLACE_IMPORTFILTER_SAVEBUTTON')}
                        submitDisabled={((String(this.state.newFilterName).length < 3 && this.state.showSaveDialog !== true) || this.state.inProgress === true)}
                        onSubmit={this.saveFilter.bind(this,(this.state.showCreateDialog === true))}>
                        {this.state.inProgress === true &&
                            <div className="MarketplaceImportInputUpdateInProgress">
                                <LoadingIndicator />
                                {Culture.getText('MARKETPLACE_IMPORTFILTER_UPDATE_INPROGRESS')}
                            </div>
                        }

                        {this.state.inProgress === false &&
                            <>
                                {this.state.updateFailed === true &&
                                    <div className="MarketplaceImportFilterUpdateFailedMessage">
                                        {Culture.getText('MARKETPLACE_IMPORTFILTER_UPDATE_FAILED')}
                                    </div>
                                }

                                {this.state.showCreateDialog === true &&
                                    <DataInputField title={Culture.getText('MARKETPLACE_IMPORTFILTER_NAME')}
                                        value={this.state.newFilterName} 
                                        onChange={(value) => {
                                            this.setState({newFilterName: value});
                                        }} />
                                }
                                {this.state.showSaveDialog === true &&
                                    <DataInputField title={Culture.getText('MARKETPLACE_IMPORTFILTER_NAME')}
                                        value={this.getSelectedFilterName()} 
                                        disabled={true} />
                                }
                                {this.renderPropertyLimitSelect()}   
                            </>
                        }
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default MarketplaceImportInput;