/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import '../../style/ReservationExternalPaymentView.css';

import Culture from '../../class/Culture';
import ReservationInformationField from './ReservationInformationField';

/**
 * Renders the external payment view for the
 * external payment data of a booking provided
 */
class ReservationExternalPaymentView extends React.Component {
    /**
     * Renders the events of a stripe payment
     * in chronological order
     * 
     * @param {object} paymentIntent 
     * the payment intent to render events of
     */
    renderStripePaymentEventList(paymentIntent){
        let result = [];
        
        let eventList = [];
        if(typeof paymentIntent.charges === 'object' && paymentIntent.charges !== null){
            if(Array.isArray(paymentIntent.charges.data)){
                let eventId = 0;
                for(const charge of paymentIntent.charges.data){
                    eventList.push({
                        id: eventId,
                        date: charge.created,
                        amount: charge.amount,
                        isRefund: false,
                        currencyCode: charge.currency
                    });
                    eventId++;
    
                    if(Array.isArray(charge.refunds.data)){
                        if(charge.refunds.data.length > 0){
                            for(const refund of charge.refunds.data){
                                eventList.push({
                                    id: eventId,
                                    date: refund.created,
                                    amount: (refund.amount*-1),
                                    isRefund: true,
                                    currencyCode: refund.currency
                                });
                                eventId++;
                            }
                        }
                    }
                }
    
                eventList.sort((a,b) => (a.date > b.date) 
                        ? 1 : ((b.date > a.date) ? -1 : 0));
    
                for(const eventItem of eventList){
                    result.push(
                        <div key={eventItem.id} className="ReservationExternalPaymentEventItem" data-isrefund={eventItem.isRefund}>
                            <div className="ReservationExternalPaymentEventItemDate">
                                {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'short',
                                    timeStyle: 'medium'
                                })).format(eventItem.date*1000)}
                            </div>
    
                            {eventItem.isRefund === true &&
                                <div className="ReservationExternalPaymentEventItemText">
                                    {Culture.getText('RESERVATION_EXTERNALPAYMENT_EVENT_REFUND')}
                                </div>
                            }{eventItem.isRefund === false &&
                                <div className="ReservationExternalPaymentEventItemText">
                                    {Culture.getText('RESERVATION_EXTERNALPAYMENT_EVENT_CHARGE')}
                                </div>
                            }
    
                            <div className="ReservationExternalPaymentEventItemAmount">
                                {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                    style: 'currency', currency: eventItem.currencyCode
                                })).format(eventItem.amount/100)}
                            </div>
                        </div>
                    );
                }
            }
        }

        return result;
    }

    /**
     * Renders the external payment and returns
     * the rendered components
     */
    render(){
        let result = [];

        if(this.props.value.provider === 'stripe'){
            let paymentIntent = this.props.value.paymentIntent;
            if(typeof paymentIntent.charges === 'object' && paymentIntent.charges !== null){
                /* determine the status of this payment and check if it was refunded */ 
                let paymentStatusCode = paymentIntent.status.toUpperCase();
                let paymentMethod = null;
                if(Array.isArray(paymentIntent.charges.data)){
                    if(paymentIntent.charges.data.length > 0){
                        paymentMethod = paymentIntent.charges.data[0].payment_method_details;
                    }

                    for(const charge of paymentIntent.charges.data){
                        if(Array.isArray(charge.refunds.data)){
                            if(charge.refunds.data.length > 0){
                                paymentStatusCode = 'REFUNDED';
                            }
                        }
                    }
                }

                let paymentMethodText = '';
                let paymentMethodCode = '';
                let paymentMethodBrand = '';
                if(paymentMethod !== null){
                    paymentMethodCode = Object.keys(paymentMethod)[0];
                    paymentMethodBrand = paymentMethodCode;
                    paymentMethodText = Culture.getText('PAYMENT_TRANSACTIONMETHOD_'+paymentMethodCode.toUpperCase());

                    if(paymentMethodCode === 'card'){
                        paymentMethodBrand = paymentMethod.card.brand;
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {'•••• ' + paymentMethod.card.last4}
                                    </span>
                                    <span className="ReservationExternalPaymentMethodCardExpiry">
                                        {[paymentMethod.card.exp_month,paymentMethod.card.exp_year].join('/')}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'giropay'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                    {paymentMethod.giropay.verified_name 
                                        || paymentMethod.giropay.bank_name
                                        || paymentMethod.giropay.bank_code
                                        || Culture.getText('PAYMENT_TRANSACTIONMETHOD_GIROPAY')}
                                    </span>
                                    <span className="ReservationExternalPaymentMethodCardExpiry">
                                        {paymentMethod.giropay.bic}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'sepa_debit'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {'•••• ' + paymentMethod.sepa_debit.last4}
                                    </span>
                                    <span className="ReservationExternalPaymentMethodCardExpiry">
                                        {Culture.getText('COUNTRY_'+paymentMethod.sepa_debit.country.toUpperCase())}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'ideal'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {'•••• ' + paymentMethod.ideal.iban_last4}
                                    </span>
                                    <span className="ReservationExternalPaymentMethodCardExpiry">
                                        {paymentMethod.ideal.bic}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'bancontact'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {'•••• ' + paymentMethod.bancontact.iban_last4}
                                    </span>
                                    <span className="ReservationExternalPaymentMethodCardExpiry">
                                        {paymentMethod.bancontact.bic}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'p24'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {paymentMethod.p24.reference}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'eps'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {paymentMethod.eps.verified_name}
                                    </span>
                                </span>;
                    }if(paymentMethodCode === 'sofort'){
                        paymentMethodText = 
                                <span className="ReservationExternalPaymentMethodCard">
                                    <span className="ReservationExternalPaymentMethodCardNumber">
                                        {'•••• ' + paymentMethod.sofort.iban_last4}, {paymentMethod.sofort.verified_name}
                                    </span>
                                    <span className="ReservationExternalPaymentMethodCardExpiry">
                                        {paymentMethod.sofort.bank_name}
                                    </span>
                                </span>;
                    }
                }

                result = (
                    <>  
                        <ReservationInformationField title={Culture.getText('RESERVATION_EXTERNALPAYMENT_TITLE_STATUS')}>
                            <div className="ReservationExternalPaymentStatus" data-status={paymentStatusCode.toLowerCase()}>
                                {Culture.getText('RESERVATION_EXTERNALPAYMENT_STATUS_'+paymentStatusCode)}
                            </div>
                        </ReservationInformationField>

                        <div className="ReservationExternalPaymentMethod" data-method={paymentMethodCode} data-brand={paymentMethodBrand}>
                            {paymentMethodText}

                            <div className="ReservationExternalPaymentMethodAmount">
                                {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                    style: 'currency', currency: paymentIntent.currency.toUpperCase()
                                })).format(paymentIntent.amount/100)}
                            </div>
                        </div>

                        <div className="ReservationExternalPaymentEventList">
                            {this.renderStripePaymentEventList(paymentIntent)}
                        </div>
                    </>
                );
            }
        }

        return result;
    }
}

export default ReservationExternalPaymentView;