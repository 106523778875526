/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';

import EditorSection from '../common/EditorSection';
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import SubNavigationList from '../common/SubNavigationList';
import RestaurantEditor from './RestaurantEditor';
import ServiceEditor from './ServiceEditor';

import '../../style/PropertyServiceEditor.css';

/**
 * This component allows to manage all available
 * services of this property including payment, 
 * restaurant, amenities etc.
 */
class PropertyServiceEditor extends React.Component {
    state = {
        breakfastAvailable: false
    }

    componentDidMount(){
        /* Attach the handler for culture changes
            as this component uses localised text */
        Culture.attachCultureComponent(this);
    }

    /**
     * Returns the option list for all
     * available payment methods and those
     * available inside the property
     */
    getPaymentMethodList(){
        let result = [];

        let availableList = (new Property(this.props.property)).getPaymentMethodList();
        let paymentMethodList = require("../../config/ota/OTAPaymentType.json");
        Object.keys(paymentMethodList).forEach((paymentMethodId) => {
            let paymentMethodCode = paymentMethodList[paymentMethodId].code;
            
            let paymentSupported = false;
            availableList.forEach((availableMethod) => {
                if(availableMethod.code === paymentMethodCode){
                    paymentSupported = true;
                }
            });

            result.push({
                value: paymentMethodId,
                text: Culture.getText('OTA_PAYMENTMETHOD_NAME_'+paymentMethodCode.toUpperCase()),
                checked: paymentSupported
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Enables or  disables the selected
     * payment method for this property
     * 
     * @param {string} item
     * the payment method code to enable or disable 
     */
    togglePaymentMethod(item){
        let property = new Property(this.props.property);
        let paymentMethodList = require("../../config/ota/OTAPaymentType.json");
        if(paymentMethodList.hasOwnProperty(item.value)){
            property.togglePaymentMethod(paymentMethodList[item.value]);
            if(typeof this.props.onPropertyUpdated === 'function'){
                this.props.onPropertyUpdated(property);
            }
        }
    }

    /**
     * Renders the service section of
     * the inventory editor
     */
    render(){
        let property = new Property(this.props.property);

        return(
            <div className="InventoryEditorSectionContent">
                <SubNavigationList>
                    <section code="service" name={Culture.getText('PROPERTY_SERVICE_SERVICELIST')}>
                        <EditorSection title={Culture.getText('PROPERTY_SERVICE_SERVICELIST_TITLE')}
                                subtitle={Culture.getText('PROPERTY_SERVICE_SERVICELIST_SUBTITLE')}
                                helpText={Culture.getText('PROPERTY_SERVICE_SERVICELIST_HELPTEXT')}>
                            <ServiceEditor list={property.getServiceList()} 
                                defaultCurrencyCode={property.getDefaultCurrencyCode()}
                                onChange={(list) => {
                                    /* update the property's service list */
                                    property.setServiceList(list);
                                    if(typeof this.props.onPropertyUpdated === 'function'){
                                        this.props.onPropertyUpdated(property);
                                    }
                            }} />
                        </EditorSection>

                        <EditorSection title={Culture.getText('PROPERTY_SERVICE_PAYMENTOPTION_TITLE')}
                                subtitle={Culture.getText('PROPERTY_SERVICE_PAYMENTOPTION_SUBTITLE')}
                                helpText={Culture.getText('PROPERTY_SERVICE_PAYMENTOPTION_HELPTEXT')}>
                            <ModalOptionListSelect list={this.getPaymentMethodList()} 
                                onToggle={this.togglePaymentMethod.bind(this)}
                                filterPlaceholderText={Culture.getText('PROPERTY_SERVICE_PAYMENTOPTION_SEARCHPAYMENTMETHOD')}
                                addButtonText={Culture.getText('PROPERTY_SERVICE_PAYMENTOPTION_ADDPAYMENTMETHOD')}
                                emptyText={Culture.getText('PROPERTY_SERVICE_PAYMENTOPTION_ADDPAYMENTMETHOD_EMPTY')} />
                        </EditorSection> 
                    </section>
                    <section code="restaurant" name={Culture.getText('PROPERTY_SERVICE_RESTAURANTLIST')}>
                        <EditorSection title={Culture.getText('PROPERTY_SERVICE_RESTAURANTLIST_TITLE')}
                                subtitle={Culture.getText('PROPERTY_SERVICE_RESTAURANTLIST_SUBTITLE')}
                                helpText={Culture.getText('PROPERTY_SERVICE_RESTAURANTLIST_HELPTEXT')}>
                            <RestaurantEditor list={property.getRestaurantList()} onChange={(list) => {
                                /* update the property's restaurant list and notify the parent */
                                property.setRestaurantList(list);
                                if(typeof this.props.onPropertyUpdated === 'function'){
                                    this.props.onPropertyUpdated(property);
                                }
                            }} /> 
                        </EditorSection> 
                    </section>
                </SubNavigationList>
            </div>
        );
    }
}

export default PropertyServiceEditor;