/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

 import React from 'react';

 import '../../style/LoadingSlider.css';
 
 class LoadingSlider extends React.Component {
     render(){
         return(
             <div className="LoadingSlider">
                 <div className="slider">
                    <div className="line"></div>
                    <div className="subline inc"></div>
                    <div className="subline dec"></div>
                </div>
             </div>
         );
     }
 }
 
 export default LoadingSlider;