/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../class/Culture';
import MarketplaceProperty from '../../class/MarketplaceProperty'; 

/* include the style for this component */
import '../../style/MarketplaceList.css'

/**
 * Renders the list of marketplace properties
 * and provide methods to manage individual
 * properties
 */
class MarketplaceList extends React.Component {
    state = {
        searchKeyword: '',
    };

    /**
     * Returns a string with the address text
     * of the item provded in the parameter
     * 
     * @param {object} item 
     * the item to return the address text of
     */
    getItemAddressText(item){
        /* define the address text of the address item */
        const countryCode = item.countryCode.toUpperCase();
        let addressList = [];
        if(typeof item.addressLine === 'string' && item.addressLine !== ''){
            addressList.push(item.addressLine);
        }if(typeof item.cityName === 'string' && item.cityName !== ''){
            if(typeof item.postalCode === 'string' && item.postalCode !== ''){
                if(countryCode === 'US' && typeof item.stateName === 'string' && item.stateName !== ''){
                    addressList.push(item.cityName);
                    addressList.push([item.stateName, item.postalCode].join(' '));
                }else{
                    addressList.push([item.postalCode, item.cityName].join(' '));
                }
            }else{
                addressList.push(item.cityName);
            }
        }
        addressList.push(Culture.getText('COUNTRY_'+countryCode));
        return addressList.join(', '); 
    }

    /**
     * Returns the tumbnail URL of the fullsize
     * URL provided in the parameter
     * 
     * @param {string} url 
     * fullsize URL of the image to get the thumbnail URL of
     */
    getThumbnailImageUrl(url){
        let result = url;

        /* remove the query string from the result */
        if (result.indexOf('.travelapi.com/') !== -1 && result.indexOf('?') !== -1) {
            result = result.substring(0, result.indexOf('?'));
        } 

        return result;
    }

    /**
     * Defines the classification for
     * the percentage rating and returns
     * the classification as a string
     * 
     * @param {number} value 
     * the percentage value to return the
     * classification of
     */
    getPercentClassification(value){
        let result = 'good';

        if(value >= 50 && value < 80){
            result = 'average';
        }if(value < 50){
            result = 'poor';
        }

        return result;
    }

    /**
     * Renders the commission stats for the
     * property provided in the parameter
     * 
     * @param {object} property 
     * the property to get the stats for
     */
    renderCommissionStats(property){
        let result = (
            <div className="MarketplaceListItemNoCommissionStatsText">
                {Culture.getText('MARKETPLACE_LIST_NOCOMMISSION_STATS')}
            </div>
        );

        if(property.hasCommissionStats()){
            let percentRage = property.getCommissionPercentRange();
            let commissionRange = property.getCommissionActualRange();

            let commissionRangeString = Intl.NumberFormat(Culture.getCultureCode(),{
                                            maximumFractionDigits: 1
                                        }).format(percentRage.min) + '% – '
                                        + Intl.NumberFormat(Culture.getCultureCode(),{
                                            maximumFractionDigits: 0
                                        }).format(percentRage.max) + '% ('
                                        + Intl.NumberFormat(Culture.getCultureCode(),{
                                            maximumFractionDigits: 0,
                                            style: 'currency', 
                                            currency: 'EUR'
                                        }).format(commissionRange.avg) + ')';
            if(percentRage.min === percentRage.max){
                commissionRangeString = '(' + Intl.NumberFormat(Culture.getCultureCode(),{
                                        maximumFractionDigits: 0,
                                        style: 'currency', 
                                        currency: 'EUR'
                                    }).format(commissionRange.avg) + ')';
            }                            


            result = (
                <div className="MarketplaceListItemCommissionStatsValue">
                    <div className="MarketplaceListItemCommissionStatsAvgValue">
                        {Intl.NumberFormat(Culture.getCultureCode(),{
                            maximumFractionDigits: 1
                        }).format(percentRage.avg)}%
                    </div>
                    <div className="MarketplaceListItemCommissionStatsRangeValue">
                        {commissionRangeString}
                    </div>
                </div>
            );
        }

        return(
            <div className="MarketplaceListItemCommissionStats">
                {result}
            </div>
        );
    }

    /**
     * Returns the rendered list item for the
     * item object provided
     * 
     * @param {object} item 
     * item to returned the rendered item for
     */
    renderItem(item){
        /* render the list item with the contents */
        let property = new MarketplaceProperty(item);
        let thumbnailUrl = property.getThumbnailImageUrl();
        return(
            <div key={item.code} className="MarketplaceListItem">
                <div className="MarketplaceListItemImage">
                    {typeof thumbnailUrl === 'string' && thumbnailUrl !== '' && 
                        <img src={thumbnailUrl} alt={item.name} />
                    }
                </div>
                <div className="MarketplaceListItemTitle">
                    <div className="MarketplaceListItemName" data-starrating={item.starRating || 0}>
                        {property.getName()}
                    </div>
                    <div className="MarketplaceListItemAddress">
                        {this.getItemAddressText(item)}
                    </div>
                </div>

                {this.renderCommissionStats(property)}

                <div className="MarketplaceReviewItem" 
                    data-type="recommendation"
                    data-classification={this.getPercentClassification(property.getRecommendationPercentage())}>
                    <div className="MarketplaceReviewItemText">
                        {Intl.NumberFormat(Culture.getCultureCode(),{
                            maximumFractionDigits: 1
                        }).format(property.getRecommendationPercentage())} %
                    </div>
                    <div className="MarketplaceReviewItemValue">
                        <div className="MarketplaceReviewItemValueBar" 
                            style={{width: property.getRecommendationPercentage() + '%'}}>
                        </div>
                    </div>
                </div>

                <div className="MarketplaceReviewItem" 
                    data-type="review"
                    data-classification={this.getPercentClassification(property.getReviewPercentage())}>
                    <div className="MarketplaceReviewItemText">
                        {Intl.NumberFormat(Culture.getCultureCode(),{
                            maximumFractionDigits: 1
                        }).format(property.getReviewPercentage())} %
                    </div>
                    <div className="MarketplaceReviewItemValue">
                        <div className="MarketplaceReviewItemValueBar" 
                            style={{width: property.getReviewPercentage() + '%'}}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Renders each item for the list and returns
     * and array with all rendered items 
     */
    renderList() { 
        let result = [];

        if (Array.isArray(this.props.list)) {
            for(const item of this.props.list){
                result.push(this.renderItem(item));
            }
        }

        return result;
    }

    /**
     * Renders the list component itself
     */
    render() {
        return(
            <div className="MarketplaceList">
                {this.renderList()}
            </div>
        );
    }
}

export default MarketplaceList;