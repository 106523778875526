/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import DataInputField from './DataInputField';

/**
 * Input component for time input in the form
 * of hours and minutes which operates and
 * underlying DataInputField
 */
class DataInputTime extends React.Component {
    state = {
        textValue: "00:00",
        timestamp: 0
    }

    /**
     * Mount the component and set the
     * time value as defined in the props
     */
    componentDidMount(){
        if(typeof this.props.value === 'number'){
            let dateObject = new Date(this.props.value);
            let timeString = String(dateObject.getUTCHours()).padStart(2,'0') + ":"
                           + String(dateObject.getUTCMinutes()).padStart(2,'0');
            this.setState({timestamp: this.props.value, textValue: timeString});
        }
    }

    /**
     * Handle the change of the value,
     * update the local state, wait for
     * the value to be valid and then
     * notify the parent
     * 
     * @param {string} value
     * the inserted time value string 
     */
    changeValue(value){
        let timestampValue = this.state.timestamp;
        let timestampChanged = false;
        if(value.match(/[0-9]{1,2}:[0-9]{1,2}/)){
            let timePart = value.split(':');
            timestampValue = (parseInt(timePart[0])*3600000)
                            + (parseInt(timePart[1])*60000);
            timestampChanged = true;
        }

        this.setState({
            textValue: value.replace(/[^0-9:]/gi,''),
            timestamp: timestampValue
        });

        if(timestampChanged === true){
            if(typeof this.props.onChange === 'function'){
                this.props.onChange(timestampValue);
            }
        }
    }

    /**
     * Renders the DataInputField used to operate
     * the time input field
     */
    render(){
        let disabled = false;
        if(this.props.disabled === true){ disabled = true; }

        return(
            <DataInputField title={this.props.title} disabled={disabled}
                value={this.state.textValue} onChange={this.changeValue.bind(this)} />
        );
    }
}

export default DataInputTime;