/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/**
 * Represents a marketplace property
 * and provided methods to interact with it
 */
class MarketplaceProperty {
    /**
     * Constructs the property with
     * the given property data
     * 
     * @param {object} value
     * marketplace property data 
     */
    constructor(value){
        Object.assign(this,value);
    }

    /**
     * Returns true when this property has
     * commission stats and false if it does
     * not have any commission stats
     */
    hasCommissionStats(){
        return (
            (Number(this.commissionMin) || 0) !== 0
            && (Number(this.commissionMax) || 0) !== 0
            && (Number(this.commissionAvg) || 0) !== 0
            && (Number(this.commissionPercentMin) || 0) !== 0
            && (Number(this.commissionPercentMax) || 0) !== 0
            && (Number(this.commissionPercentAvg) || 0) !== 0
        );
    }

    /**
     * Returns the percentage commission range
     * of this property or 0 values if none are
     * defined for this property
     */
    getCommissionPercentRange(){
        let result = {
            min: (Number(this.commissionPercentMin) || 0),
            max: (Number(this.commissionPercentMax) || 0),
            avg: (Number(this.commissionPercentAvg) || 0)
        };

        if(result.max < result.min){
            let minValue = result.max;
            result.max = result.min;
            result.min = minValue;
        }

        return result;
    }

    /**
     * Returns the commission range in actual
     * EUR numbers or the default 0
     */
    getCommissionActualRange(){
        return {
            min: (Number(this.commissionMin) || 0),
            max: (Number(this.commissionMax) || 0),
            avg: (Number(this.commissionAvg) || 0)
        }
    }

    /**
     * Returns the unique property
     * code of this property
     */
    getCode(){
        return this.code;
    }

    /**
     * Returns the percentage value of
     * the guest recommendation of this
     * property
     */
    getRecommendationPercentage(){
        return (Number(this.recommendation) || 0);
    }

    /**
     * Returns the guest review percentage
     * of this property as a number
     */
    getReviewPercentage(){
        return (Number(this.guestReview) || 0);
    }

    /**
     * Returns the latitude and longitude
     * of the location of this property
     */
    getLatLng(){
        let result = {lat: 0, lng: 0};

        if(Array.isArray(this.coordinate)){
            if (this.coordinate.length === 2) {
                result = {
                    lat: this.coordinate[0],
                    lng: this.coordinate[1]
                };
            }
        }

        return result;
    }

    /**
     * Returns the name of this
     * property as a string
     */
    getName(){
        return this.name;
    }

    /**
     * Returns the thumbnail image of
     * the property or an empty string
     * if none exists
     */
    getThumbnailImageUrl(){
        let result = this.imageUrl || '';

        /* remove the query string from the result */
        if (result.indexOf('.travelapi.com/') !== -1 && result.indexOf('?') !== -1) {
            result = result.substring(0, result.indexOf('?'));
        } 

        return result;
    }
}

export default MarketplaceProperty;