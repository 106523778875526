/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ManagementUser from '../../class/ManagementUser';

/* placeholder to use for passwords */
const DEFAULT_PASSWORD_PLACEHOLDER = 'password';

/**
 * This editor form allows creating and editing
 * opentravel connections and notifies the parent
 * about any changes to the configuration object
 * provided in the parameters
 */
class GenericProviderConnectionEditor extends React.Component {
    /**
     * Notify the parent component about
     * the change of the config object
     * 
     * @param {object} configObject 
     * the updated configuration object
     */
    notifyUpdate(configObject){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(configObject);
        }
    }

    /**
     * Returns the username of this provider
     * config if any is present and an empty
     * string if none is present
     */
    getUserName(){
        let result = '';

        if(typeof this.props.config === 'object' && this.props.config !== null){
            let providerCode = this.props.config.providerCode;
            if(typeof providerCode === 'string'){
                if(providerCode.indexOf('#') > 0){
                    result = providerCode.split('#')[1];
                }
            }
        }

        return result;
    }

    /**
     * Returns the password value for this
     * configuration and returns an empty
     * value when none is defined or the
     * default when a password is defined
     * server-side
     */
    getPassword(){
        let result = '';

        if(typeof this.props.config === 'object' && this.props.config !== null){
            if(this.props.config.password === true){
                result = DEFAULT_PASSWORD_PLACEHOLDER;
            }if(typeof this.props.config.password === 'string'){
                result = this.props.config.password;
            }
        }

        return result;
    }

    /**
     * Returns the remote push password if
     * any was defined for this account
     */
    getRemotePushPassword(){
        let result = '';

        if(typeof this.props.config === 'object' && this.props.config !== null){
            if(this.props.config.remotePushPassword === true){
                result = DEFAULT_PASSWORD_PLACEHOLDER;
            }if(typeof this.props.config.remotePushPassword === 'string'){
                result = this.props.config.remotePushPassword;
            }
        }

        return result;
    }

    /**
     * Returns the list of linked properties
     * as an array or an empty array if no
     * properties are linked
     */
    getLinkedPropertyList(){
        let result = [];

        if(typeof this.props.config === 'object' && this.props.config !== null){
            if(Array.isArray(this.props.config.propertyList)){
                result = this.props.config.propertyList;
            }
        }

        return result;
    }

    /**
     * Updates the list of linked properties
     * for this provider configuration
     * 
     * @param {array} list
     * the list with selected properties 
     */
    updateLinkedProperty(list){
        let configObject = this.props.config;
        if(typeof configObject !== 'object' || configObject === null){
            configObject = {
                providerCode: this.props.channelCode,
                tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                propertyList: []
            };
        }

        configObject.propertyList = list;
        this.notifyUpdate(configObject);
    }

    /**
     * Updates the config value and notifies
     * the parent about any changes to the
     * config setting
     * 
     * @param {string} configKey 
     * the key name of the config setting
     * 
     * @param {any} configValue 
     * the value of the config setting
     */
    updateConfigValue(configKey,configValue){
        let configObject = this.props.config;
        if(typeof configObject !== 'object' || configObject === null){
            configObject = {
                providerCode: this.props.channelCode,
                tenantCode: ManagementUser.getCurrent().getActiveTenant()
            };
        }

        /* update the value and notify the parent */
        if(configKey === 'userName'){
            configObject.providerCode = [this.props.channelCode,configValue].join('#');
        }else{
            configObject[configKey] = configValue;
        }
        
        this.notifyUpdate(configObject);
    }

    /**
     * Returns the value of the config setting
     * for which the key was provided or returns
     * an empty string of the value
     * 
     * @param {string} configKey 
     * the config key of the config 
     * setting to return value of
     */
    getConfigValue(configKey,defaultValue){
        let result = '';

        if(defaultValue !== undefined){
            result = defaultValue;
        }

        if(typeof this.props.config === 'object' && this.props.config !== null){
            if(typeof this.props.config[configKey] === 'string'
                || typeof this.props.config[configKey] === 'number'
                || typeof this.props.config[configKey] === 'boolean'
                || (typeof this.props.config[configKey] === 'object' && this.props.config[configKey] !== null)
                || Array.isArray(this.props.config[configKey]) === true){
                /* return the config value */
                result = this.props.config[configKey];
            }
        }

        return result;
    }
}
 
export default GenericProviderConnectionEditor;