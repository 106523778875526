/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';
import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DataInputList from '../common/DataInputList';

/* require the stylesheet for this component */
import '../../style/PropertyFilterEditor.css';

class PropertyFilterEditor extends React.Component {
    state = {
        showNewFilterWindow: false,
        newFilterField: 'postalCode',
        newFilterValue: '.*',
        newFilterAriSyncOnly: false,
        newFilterAutoTranslate: false
    }

    /**
     * Updates the item at the index
     * with the item provided in the
     * parameter
     *  
     * @param {number} index
     * the index of the item to change
     *  
     * @param {object} item 
     * the object value to set for the item
     */
    updateListItem(index,item){
        let list = [];

        if(Array.isArray(this.props.list)){
            list = this.props.list;
            if(list.length > index){
                list[index] = item;
            }
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(list);
        }
    }

    /**
     * Renders the list of items
     */
    renderList(){
        let result = [];

        if(Array.isArray(this.props.list)){
            for(let i=0; i<this.props.list.length; i++){
                let item = Object.assign({},this.props.list[i]);
                let mappingField = item.mappingCode.substring(0,item.mappingCode.indexOf(':'));
                let mappingRegExText = item.mappingCode.substring(item.mappingCode.indexOf(':')+1);

                result.push(
                    <div key={i} className="PropertyFilterEditorListItem">
                        <div className="PropertyFilterEditorListItemField">
                            {mappingField}
                        </div>
                        <div className="PropertyFilterEditorListItemValue">
                            {mappingRegExText}
                        </div>

                        <div className="PropertyFilterEditorListItemOptionList">
                            <div className="PropertyFilterEditorListItemOption" 
                                title={this.props.autoTranslateText}
                                data-code="autotranslate" 
                                data-enabled={item.autoTranslate || false}></div>
                            <div className="PropertyFilterEditorListItemOption"
                                title={this.props.ariSyncOnlyText}
                                data-code="fullsync" 
                                data-enabled={(item.ariSyncOnly !== true)}></div>
                        </div>

                        <div className="PropertyFilterEditorItemRemove" onClick={(function(index){
                            let updatedList = [];
                            for(let x=0; x<this.props.list.length; x++){
                                if(x !== index){
                                    updatedList.push(this.props.list[x]);
                                }
                            }

                            if(typeof this.props.onChange === 'function'){
                                this.props.onChange(updatedList);
                            }
                        }).bind(this,i)}></div>
                    </div>
                );
            }
        }

        if(result.length === 0){
            result.push(
                <div key="PropertyFilterEditorListEmpty" 
                    className="PropertyFilterEditorListEmpty">
                    {this.props.emptyText}
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the create window
     * to create a new filter condition
     */
    renderCreateWindow(){
        let result = [];

        if(this.state.showNewFilterWindow === true){
            result = (
                <ModalDrawerOverlay titleText={Culture.getText('PROPERTYFILTEREDITOR_FILTER_TITLE')}
                    subtitleText={Culture.getText('PROPERTYFILTEREDITOR_FILTER_SUBTITLE')}
                    submitButtonText={Culture.getText('PROPERTYFILTEREDITOR_FILTER_APPLY')}
                    onSubmit={(function(){
                        let updatedList = [];
                        if(Array.isArray(this.props.list)){
                            updatedList = this.props.list;
                        }

                        updatedList.push({
                            mappingCode: [
                                this.state.newFilterField,
                                this.state.newFilterValue
                            ].join(':'),
                            ariSyncOnly: false,
                            autoTranslate: false
                        });

                        if(typeof this.props.onChange === 'function'){
                            this.props.onChange(updatedList);
                        }

                        this.setState({
                            showNewFilterWindow: false,
                            newFilterField: 'postalCode',
                            newFilterValue: '.*',
                            newFilterAriSyncOnly: false,
                            newFilterAutoTranslate: false
                        });
                    }).bind(this)}
                    onClose={() => this.setState({showNewFilterWindow: false})}>
                    <DataInputList title={Culture.getText('PROPERTYFILTEREDITOR_FIELDTYPE')} 
                        value={this.state.newFilterField} 
                        list={[
                            {value: "propertyCode", text: Culture.getText('PROPERTY_ID')},
                            {value: "countryCode", text: Culture.getText('DATAFIELD_PROPERTY_ADDRESS_COUNTRYCODE')},
                            {value: "cityName", text: Culture.getText('DATAFIELD_PROPERTY_ADDRESS_CITYNAME')},
                            {value: "postalCode", text: Culture.getText('DATAFIELD_PROPERTY_ADDRESS_POSTALCODE')}
                        ]} 
                        onChange={(value) => this.setState({newFilterField: value})}
                    />
    
                    <DataInputField title={Culture.getText('PROPERTYFILTEREDITOR_REGEX')}
                            value={this.state.newFilterValue} 
                            onChange={(value) => this.setState({newFilterValue: value})} />
    
                    {typeof this.props.ariSyncOnlyText === 'string' && 
                        <DataInputCheckBox title={this.props.ariSyncOnlyText}
                            checked={this.state.newFilterAriSyncOnly} 
                            onClick={() => this.setState({newFilterAriSyncOnly: !this.state.newFilterAriSyncOnly})}
                        />
                    }
    
                    {typeof this.props.autoTranslateText === 'string' && 
                        <DataInputCheckBox title={this.props.autoTranslateText}
                            checked={this.state.newFilterAutoTranslate} 
                            onClick={() => this.setState({newFilterAutoTranslate: !this.state.newFilterAutoTranslate})}
                        />
                    }
                </ModalDrawerOverlay>
            );
        }

        return result;
    }

    /**
     * Renders the filter editor with the
     * existing filter items and allows adding
     * new filter items
     */
    render(){
        return(
            <div className="PropertyFilterEditor">
                <div className="PropertyFilterEditorTitle">
                    {this.props.title}
                </div>
                <div className="PropertyFilterEditorList">
                    {this.renderList()}
                </div>
                <div>
                    <StandardButton text={Culture.getText('PROPERTYFILTEREDITOR_FILTER_CREATE')}
                        onClick={() => this.setState({
                            showNewFilterWindow: true,
                            newFilterField: 'postalCode',
                            newFilterValue: '.*',
                            newFilterAriSyncOnly: false,
                            newFilterAutoTranslate: false
                        })} />
                </div>

                {this.renderCreateWindow()}
            </div>
        );
    }
}

export default PropertyFilterEditor;