/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/AccountInformationMessage.css';

class AccountInformationMessage extends React.Component {
    render(){
        return(
            <div className='AccountInformationMessageBackground'>
                <div className="AccountInformationMessageRemoveButton" onClick={(function(){
                    if(typeof this.props.onRemove === 'function'){
                        this.props.onRemove();
                    }
                }).bind(this)}></div>
                <div className='AccountInformationMessageWindow'>
                    <div className='AccountInformationMessageWindowTitle'>
                        <div className='AccountInformationMessageWindowTitleText'>
                            {this.props.title}
                        </div>
                        <div className='AccountInformationMessageWindowSubtitleText'>
                            {this.props.subtitle}
                        </div>
                    </div>
                    <div className='AccountInformationMessageWindowContent'>
                        {this.props.children}
                    </div>
                    <div className='AccountInformationMessageWindowActionList'>
                        {typeof this.props.dismissText === 'string' && this.props.dismissText !== '' &&
                            <div className='AccountInformationMessageWindowActionDismiss'
                                onClick={(function(){
                                    if(typeof this.props.onDismiss === 'function'){
                                        this.props.onDismiss();
                                    }
                                }).bind(this)}>
                                {this.props.dismissText}
                            </div>
                        }
                        <div className='AccountInformationMessageWindowActionConfirm'
                            onClick={(function(){
                                if(typeof this.props.onConfirm === 'function'){
                                    this.props.onConfirm();
                                }
                            }).bind(this)}>
                            {this.props.confirmText}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountInformationMessage;