/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import '../../style/DateRangeSelect.css';

/**
 * Component that allows to pick a date range
 * and notifies the parent when a valid date
 * range was selected
 */
class DateRangeSelect extends React.Component {
    state = {
        fromDate: (new Date()).getTime(),
        untilDate: ((new Date()).getTime()+(30*86400000))
    }

    /**
     * Initliase the component with any
     * predefined values in the props
     */
    componentDidMount(){
        if(isNaN(this.props.fromDate) === false && isNaN(this.props.untilDate) === false){
            this.setState({
                fromDate: this.props.fromDate,
                untilDate: this.props.untilDate
            });
        }
    }

    /**
     * Notifies the parent component
     * about the change in the date range
     */
    notifyChange(){
        if(typeof this.props.onChange === 'function'){
            if(this.state.fromDate > this.state.untilDate){
                this.props.onChange(this.state.untilDate,this.state.fromDate);
            }else{
                this.props.onChange(this.state.fromDate,this.state.untilDate);
            }
        }
    }

    /**
     * Returns the date string in YYYY-MM-DD
     * format for the timestamp provided
     * 
     * @param {number} timestamp 
     * timestamp to return the YYYY-MM-DD string for
     */
    getDateString(timestamp){
        let dateObject = new Date(timestamp);
        return [
            dateObject.getFullYear(),
            String(dateObject.getMonth()+1).padStart(2,'0'),
            String(dateObject.getDate()).padStart(2,'0')
        ].join('-');
    }

    /**
     * Returns the timestamp for the YYYY-MM-DD
     * string provided in the parameter
     * 
     * @param {string} dateString 
     * the YYYY-MM-DD string to return the timestamp for 
     */
    getTimestampFromDateString(dateString){
        let partList = dateString.split('-');

        return (new Date(
            Number(partList[0]),
            (Number(partList[1])-1),
            Number(partList[2])
        )).getTime();
    }

    /**
     * Renders the component for the date
     * range selection component
     */
    render(){
        return(
            <div className="DateRangeSelect">
                {typeof this.props.title === 'string' && this.props.title !== '' &&
                    <div className="DateRangeSelectTitle">
                        {this.props.title}
                    </div>
                }
                <div className="DateRangeSelectFieldList">
                    <div className="DateRangeSelectField">
                        <div className="DateRangeSelectFieldTitle">
                            {Culture.getText('DATERANGEINPUT_FROMDATE')}
                        </div>
                        <input type="date" className="DateRangeSelectInput" 
                            value={this.getDateString(this.state.fromDate)}
                            onChange={(e) => {
                                let valueTimestamp = this.getTimestampFromDateString(e.target.value);
                                this.setState({fromDate: valueTimestamp},this.notifyChange.bind(this));
                            }} />
                    </div>
                    <div className="DateRangeSelectField">
                        <div className="DateRangeSelectFieldTitle">
                            {Culture.getText('DATERANGEINPUT_UNTILDATE')}
                        </div>
                        <input type="date" className="DateRangeSelectInput" 
                            value={this.getDateString(this.state.untilDate)}
                            onChange={(e) => {
                                let valueTimestamp = this.getTimestampFromDateString(e.target.value);
                                this.setState({untilDate: valueTimestamp},this.notifyChange.bind(this));
                            }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default DateRangeSelect;