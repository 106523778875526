/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the necessary core classes */
import Culture from '../../class/Culture';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DataInputArray from '../common/DataInputArray';

/**
 * This editor form allows creating and editing
 * hostaway connections and notifies the parent
 * about any changes to the configuration object
 * provided in the parameters
 */
class HostawayConnectionEditor extends GenericProviderConnectionEditor {
    /**
     * Notifies the parent object about
     * the change and attaches the provider
     * specific configuration values
     * 
     * @param {object} configObject 
     * the config object to notify update of
     */
    notifyUpdate(configObject) {
        /* set the hostaway specific settings */
        configObject.remoteSystemCode = 'hostaway';
        configObject.requireSetup = true;

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(configObject);
        }
    }

    /**
     * Renders the component with all
     * the required fields for this provider
     */
    render() {
        return (
            <div className="ProviderConnectionEditor">
                <DataInputField title={Culture.getText('CONNECTIVITY_HOSTAWAYACCOUNTID')} disabled={this.props.editMode}
                    value={this.getUserName()} onChange={(value) => this.updateConfigValue('userName', value)} />

                <DataInputField title={Culture.getText('CONNECTIVITY_HOSTAWAYAPIKEY')} 
                    disabled={this.props.editMode} value={this.getConfigValue('apiKey', '')} 
                    onChange={(value) => this.updateConfigValue('apiKey', value)} />

                <DataInputCheckBox title={Culture.getText('CONNECTIVITY_OVERRIDECONTENT')}
                    checkedTitle={Culture.getText('CONNECTIVITY_OVERRIDECONTENT')}
                    checked={this.getConfigValue('overrideContent', true)} onClick={(function () {
                        this.updateConfigValue('overrideContent', !this.getConfigValue('overrideContent', true));
                    }).bind(this)}
                />

                <div className="ContentWarningMessage">
                    <div className="ContentWarningMessageTitle">
                        {Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT_INFO_TITLE')}
                    </div>
                    <div className="ContentWarningMessageText">
                        {Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT_INFO_TEXT')}
                    </div>
                </div>

                <DataInputArray list={this.getConfigValue('propertyList', [])}
                    onChange={(value) => this.updateConfigValue('propertyList', value)}
                    title={Culture.getText('CONNECTIVITY_HOSTAWAYPROPERTYLIST')}
                    addTextPlaceholder={Culture.getText('CONNECTIVITY_HOSTAWAYLISTINGID')}
                    listEmptyText={Culture.getText('CONNECTIVITY_HOSTAWAYPROPERTY_IMPORTALL')} />
            </div>
        );
    }
}

export default HostawayConnectionEditor;