/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the core classes for this component */
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';
import ManagementUser from '../../class/ManagementUser';

/* require the sub-components for this view */
import LoadingIndicator from '../common/LoadingIndicator';
import StandardButton from '../common/StandardButton';
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import ConnectionEditor from './ConnectionEditor';
import MarketplaceView from '../marketplace/MarketplaceView';

/* require the stylesheet for the connectivity view */
import '../../style/ConnectivityView.css';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

/* require the available channel list configuration */
const ChannelList = require('../../config/connectivity/channel.json');

/**
 * This class handles the connectivity listing,
 * creation and editing to manage the channel
 * connections of the tenant
 */
class ConnectivityView extends React.Component {
    state = {
        list: [],
        loaded: false,
        showEditWindow: false,
        showDeleteWindow: false,
        editWindowChannel: 'opentravel',
        editMode: false,
        configObject: null,
        createFailed: false,
        deleteFailed: false,
        activeSection: 'connectivity_direct'
    }
    
    /**
     * Fetch the list with the configuration
     * when the component is initially loaded
     */
    componentDidMount(){
        this.fetchList();
    }

    /**
     * Fetches the config list with the existing
     * configuration of this tenant
     */
    async fetchList(){
        this.setState({ loaded: false });
        let listResult = await ApiClient.execute('/connectivity/configList',{});
        if(Array.isArray(listResult.list)){
            this.setState({
                list: listResult.list,
                loaded: true,
                showEditWindow: false,
                editWindowChannel: 'opentravel',
                editMode: false,
                configObject: null
            });
        }
    }

    /**
     * Updates the configuration value with
     * the api and reloads the list
     * 
     * @param {object} value
     * the configuration value to update 
     */
    async updateConfig(value){
        if(value !== null){
            /* show the loading indicator while updating */
            this.setState({ loaded: false, showEditWindow: false });

            /* set the region code for this config object with the current region */
            value.inventoryRegion = ManagementUser.getSystemRegionCode();

            /* post the config value to the api */
            let updateResult = await ApiClient.execute('/connectivity/createConfig',value);
            if(updateResult === null){
                /* network failure or remote failure */
                this.scrollEditorWindowToTop();
                this.setState({
                    loaded: true,
                    createFailed: true,
                    showEditWindow: true
                });
            }else{
                if(updateResult.failed === false){
                    /* channel config created, reload list */
                    this.fetchList();
                }else{
                    /* show the error message */
                    this.scrollEditorWindowToTop();
                    this.setState({
                        loaded: true,
                        createFailed: true,
                        showEditWindow: true
                    });
                }
            }
        }else{
            /* just reset the config when it was
                not updated and the provided param
                is just null (config not updated) */
            this.setState({
                loaded: true,
                showEditWindow: false,
                editWindowChannel: 'opentravel',
                editMode: false,
                configObject: null
            });
        }
    }

    /**
     * Deletes the configuration in the state
     * through the api and updates the list
     */
    async deleteConfig(){
        let configObject = this.state.configObject;
        if(configObject !== null){
            /* show the loading indicator while deleting */
            this.setState({ loaded: false, showDeleteWindow: false });

            /* execute the deletion of the config with the api */
            let deleteResult = await ApiClient.execute('/connectivity/deleteConfig',{
                providerCode: configObject.providerCode
            });

            if(deleteResult !== null){
                if(deleteResult.failed === false){
                    /* reset the state and fetch the list */
                    this.setState({deleteFailed: false},this.fetchList);
                }else{
                    /* reset the state and set the failure flag */
                    this.setState({loaded: true, showDeleteWindow: false, deleteFailed: true});
                }
            }else{
                /* reset the state and set the failure flag */
                this.setState({loaded: true, showDeleteWindow: false, deleteFailed: true});
            }
        }
    }

    /**
     * Scrolls the connection editor window
     * to the top of the window's content
     */
    scrollEditorWindowToTop(){
        for(const editorContent of document.getElementsByClassName('ConnectionEditorContent')){
            editorContent.scrollTo(0,0);
        }
    }

    /**
     * Returns the name of the channel
     * if any is available or returns
     * the code if none was found
     * 
     * @param {string} code 
     * code of the channel to return name of
     */
    getChannelName(code){
        let result = code;

        for(const channelItem of ChannelList){
            if(channelItem.code === code){
                result = channelItem.name;
            }
        }

        return result;
    }

    /**
     * Returns the synchronisation type
     * code for the channel code provided
     * 
     * @param {string} code 
     * code of the channel to get sync type for
     */
    getChannelSyncType(code){
        let result = 'ari';

        for(const channelItem of ChannelList){
            if(channelItem.code === code){
                result = channelItem.syncType;
            }
        }

        return result;  
    }

    /**
     * Returns true when the provided code
     * belongs to an existing channel and
     * false if not.
     * 
     * @param {string} code 
     * code of the channel to check existence of
     */
    channelExists(code){
        let result = false;

        for(const channelItem of ChannelList){
            if(channelItem.code === code){
                result = true;
            }
        }

        return result;
    }

    /**
     * Returns true when the channel or provider
     * for which the code was provided is already
     * connected to the tenants system
     * 
     * @param {string} code 
     * code of the channel to check if connected
     */
    channelConnected(code){
        let result = false;

        for(const configItem of this.state.list){
            if(configItem.providerCode === code 
                || configItem.remoteSystemCode === code){
                result = true;
            }
        }

        return result;
    }

    /**
     * Renders the list of connected channels
     * and returns the rendered list elements 
     */
    renderConnectedList(){
        let result = [];

        for(const configItem of this.state.list){
            /* determine the name of this connection which
                is either the channel name or the user name
                if the channel has a user appended with a # */
            let configName = this.getChannelName(configItem.providerCode);
            
            /* determine the provider code for this item */
            let providerCode = configItem.providerCode;
            let providerExists = false;
            if(providerCode.indexOf('#') > 0){
                /* determine the config name */
                configName = providerCode.split('#')[1];
                if(configName.startsWith('_global_')){
                    configName = configName.substring(8);
                }                

                /* determine the actual provider code */
                providerCode = providerCode.split('#')[0];

                /* ensure the provider exists and is valid */
                providerExists = this.channelExists(providerCode);
                if(typeof configItem.remoteSystemCode === 'string'){
                    providerCode = configItem.remoteSystemCode;
                }
            }else{
                /* check if the provider exists and is valid as
                    experimental providers will not be shown in
                    the console */
                providerExists = this.channelExists(providerCode);
            }

            if(providerExists === true){
                /* define the sync status text for this connected
                    channel based on the last updated timestamp */
                let syncStatusCode = 'wait';
                let syncStatusText = Culture.getText('CONNECTIVITY_SYNCSTATUS_WAIT');
                if(isNaN(configItem.lastSync) === false){
                    if(configItem.lastSync<((new Date()).getTime()-604800000)){
                        /* report contact lost with last contact date */
                        syncStatusText = Culture.getText('CONNECTIVITY_SYNCSTATUS_DISCONNECTED');
                        syncStatusCode = 'disconnected';
                    }else{
                        /* show the last sync status time and date in short */
                        syncStatusText = Culture.getText('CONNECTIVITY_SYNCSTATUS_UPDATED');
                        syncStatusCode = 'updated';
                    }
                }

                result.push(
                    <div key={configItem.providerCode} data-code={providerCode} className="ConnectivityItem ConnectivityItemConnected">
                        <div className="ConnectivityItemProvider" data-code={providerCode}
                            onClick={() => this.setState({
                                showEditWindow: true,
                                editMode: true,
                                editWindowChannel: providerCode,
                                configObject: Object.assign({},configItem)
                            })}></div>
                        <div className="ConnectivityItemName" onClick={() => this.setState({
                                showEditWindow: true,
                                editMode: true,
                                editWindowChannel: providerCode,
                                /* copy the config to avoid overwriting the
                                    object as it is referenced and thus if
                                    the editing is cancelled, will remain
                                    overwritten */
                                configObject: Object.assign({},configItem)
                            })}>
                            {configName}
                        </div>
                        <div className="ConnectivityItemSyncStatus" 
                            data-code={syncStatusCode}
                            onClick={() => this.setState({
                                showEditWindow: true,
                                editMode: true,
                                editWindowChannel: providerCode,
                                /* copy the config to avoid overwriting the
                                    object as it is referenced and thus if
                                    the editing is cancelled, will remain
                                    overwritten */
                                configObject: Object.assign({},configItem)
                            })}>
                            {syncStatusText}
                        </div>
                        <div className="ConnectivityItemRemoveButton" 
                            onClick={() => this.setState({
                                showDeleteWindow: true,
                                configObject: Object.assign({},configItem)
                            })}>
                        </div>
                    </div>
                );
            }
        }

        if(result.length === 0){
            result.push(
                <div key="NoChannelConnectedText" className="NoChannelConnectedText">
                    {Culture.getText('CONNECTIVITY_NOCHANNELCONNECTED')}
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the list of available channels
     * and returns the rendered elements
     */
    renderAvailableList(){
        let result = [];

        let sortedList = ChannelList;
        sortedList.sort((a,b) => (a.name > b.name) ? 1 
                        : ((b.name > a.name) ? -1 : 0));

        for(const channelItem of sortedList){
            /* only show the channel when it is not 
                yet connected or allows multiple connections */
            if(channelItem.multiple === true || this.channelConnected(channelItem.code) === false){
                result.push(
                    <div key={channelItem.code} data-code={channelItem.code} className="ConnectivityItem">
                        <div className="ConnectivityItemProvider" 
                            data-code={channelItem.code} 
                            onClick={() => this.setState({
                                showEditWindow: true,
                                editWindowChannel: channelItem.code,
                                editMode: false
                        })}></div>
                        <div className="ConnectivityItemName" onClick={() => this.setState({
                                showEditWindow: true,
                                editWindowChannel: channelItem.code,
                                editMode: false
                            })}>
                            {channelItem.name}
                        </div>

                        <div className="ConnectivityItemSyncType">
                            {Culture.getText('CONNECTIVITY_SYNCTYPE_'+channelItem.syncType.toUpperCase())}
                        </div>
                        <StandardButton className="ConnectivityItemButton ConnectivityItemAddButton"
                            text={Culture.getText('CONNECTIVITY_ADDCONNECTION')}
                            onClick={() => this.setState({
                                showEditWindow: true,
                                editWindowChannel: channelItem.code,
                                editMode: false
                            })} />
                    </div>
                );   
            }
        }

        return result;
    }

    /**
     * Renders the delete window and handles
     * the deletion of configurations
     */
    renderDeleteWindow(){
        let result = null;

        if(this.state.showDeleteWindow === true){
            result = (
                <ModalDrawerOverlay titleText={Culture.getText('CONNECTIVITY_DELETECONNECTION')}
                    subtitleText={Culture.getText('CONNECTIVITY_DELETECONNECTION_SUBTITLE')}
                    introText={Culture.getText('CONNECTIVITY_DELETECONNECTION_TEXT')}
                    submitButtonText={Culture.getText('CONNECTIVITY_DELETECONNECTION')}
                    onSubmit={this.deleteConfig.bind(this)}
                    onClose={() => this.setState({showDeleteWindow: false, configObject: null})} />
            );
        }

        return result;
    }

    /**
     * Renders the connectivity components
     * after the tenants list was fetched
     */
    render(){
        return(
            <>
                <ContentHeaderMenu title={Culture.getText('CONNECTIVITY_TITLE')}
                    subtitle={Culture.getText('CONNECTIVITY_SUBTITLE')}
                    onChange={(sectionCode) => this.setState({ activeSection: sectionCode })}
                    menuList={[
                        {
                            code: 'connectivity_direct', 
                            title: Culture.getText('CONNECTIVITY_DIRECTCONNECT'), 
                            active: (this.state.activeSection === 'connectivity_direct')
                        },
                        {
                            code: 'connectivity_market', 
                            title: Culture.getText('CONNECTIVITY_MARKETPLACE'), 
                            active: (this.state.activeSection === 'connectivity_market')
                        }
                    ]} />
                <div className="ConnectivityView">
                    <div className="ConnectivityViewContainer">
                        {this.state.activeSection === 'connectivity_market' && 
                            <MarketplaceView />
                        }

                        {this.state.activeSection === 'connectivity_direct' && 
                            <>
                                {this.state.loaded !== true &&
                                    <LoadingIndicator />
                                }

                                {this.state.loaded === true &&
                                    <div className="ConnectivityList">
                                        <div className="ConnectivityConnectedView ConnectivityProviderListView">
                                            <div className="ConnectivityProviderListHeader">
                                                <div className="ConnectivityProviderListTitle">
                                                    {Culture.getText('CONNECTIVITY_CONNECTEDLIST')}
                                                </div>
                                            </div>

                                            {this.state.deleteFailed === true &&
                                                <div className="ConnectivityDeleteFailed">
                                                    {Culture.getText('CONNECTIVITY_CONFIGDELETE_FAILED')}
                                                </div>
                                            }

                                            <div className="ConnectivityConnectedList ConnectivityProviderList">
                                                {this.renderConnectedList()}
                                            </div>
                                        </div>
                                        <div className="ConnectivityAvailableView ConnectivityProviderListView">
                                            <div className="ConnectivityProviderListHeader">
                                                <div className="ConnectivityProviderListTitle">
                                                    {Culture.getText('CONNECTIVITY_AVAILABLELIST')}
                                                </div>
                                            </div>

                                            <div className="ConnectivityAvailableList ConnectivityProviderList">
                                                {this.renderAvailableList()}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.state.showEditWindow === true &&
                                    <ConnectionEditor editMode={this.state.editMode} 
                                        updateFailed={this.state.createFailed}
                                        channelCode={this.state.editWindowChannel}
                                        config={this.state.configObject}
                                        onClose={() => this.setState({showEditWindow: false})}
                                        onChange={this.updateConfig.bind(this)} />
                                }

                                {this.renderDeleteWindow()}
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default ConnectivityView;