/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/DataInputField.css';

/**
 * Simple checkbox component that allows to
 * check or uncheck a field. Also allows to
 * show a different title based on its state
 */
class DataInputCheckBox extends React.Component {
    /**
     * Renders the checkbox component
     */
    render(){
        let classList = ['DataInputCheckBox'];
        if(this.props.checked === true){
            classList.push('DataInputCheckBoxChecked');
        }

        if(this.props.disabled === true){
            classList.push('DataInputCheckBoxDisabled');
        }

        /* checks if the checked title is present and
            the element is checked. This allows the user
            to define a different text based on the state
            of the element */
        let titleText = this.props.title;
        if(this.props.checked === true 
            && typeof this.props.checkedTitle === 'string' 
            && this.props.checkedTitle !== ''){
            titleText = this.props.checkedTitle;
        }

        return(
            <div className={classList.join(' ')} data-warning={(this.props.showWarning === true)}>
                <div className="DataInputCheckBoxTitle" onClick={() => {
                    if(this.props.disabled !== true){
                        if(typeof this.props.onClick === 'function'){
                            this.props.onClick();
                        }
                    }
                }}>
                    <div className="DataInputCheckBoxTitleText">
                        {titleText}
                    </div>
                </div>
            </div>
        );
    }
}

export default DataInputCheckBox;