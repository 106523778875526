/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ManagementUser from '../../class/ManagementUser';
import Culture from '../../class/Culture';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import StandardButton from '../common/StandardButton';

/* define the constants for the iGMS credentials */
const IGMS_AUTH_CLIENT_ID = 130;

/* iGMS only supports a single callback URL domain and thus
    as the callback is only an HTML page that triggers a window
    event, the US management console will be used for the callback
    and subsequently the JS event will be raised which can then
    be handled by any console region */
const IGMS_CALLBACK_URL = 'https://console.us.lodgea.com/callback.html';

/* the scope required to be requested for the integration to have
    sufficient privileges to access the desired information */
const IGMS_SCOPELIST = [
    'listings', 'calendar-control', 'direct-bookings'
];

/**
 * The iGMS editor allows performing auth with the iGMS system
 * and once successfull creates the configuration for iGMS where
 * the server-side will finalise the connection
 */
class IGMSConnectionEditor extends GenericProviderConnectionEditor {
    /* state handling the current state
        of config creation to avoid multiple
        calls to the config creation */
    state = {
        configCreate: false
    }

    /**
     * Mounts the component and adds the listener
     * for the window event with the authentication
     * information attached
     */
    componentDidMount(){
        /* bind the event listener to the message event */
        window.addEventListener('message',this.handleAuthEvent.bind(this),false);

        /* ensure the window URL is clean before doing auth */
        window.history.pushState(null,null,'/');
    }

    /**
     * Handles the authentication event and processes
     * the result of the event based on the message
     * received from the callback
     * 
     * @param {object} event 
     * the event object received from the callback
     */
    handleAuthEvent(event){
        try{
            if(this.state.configCreate === false){
                let messageString = String(event.data);
                if(messageString.indexOf('connectivitiy_provider') >= 0){
                    const message = JSON.parse(event.data);
                    if(message.connectivitiy_provider === 'igms'){
                        /* close the previously opened window */
                        this.windowHandle.close();

                        /* set the state to creating */
                        this.setState({configCreate: true});

                        /* the auth came back with a response */
                        if(typeof message.code === 'string' && message.code !== ''){
                            /* auth was successful and return the correct code */
                            if(typeof this.props.onChange === 'function'){
                                this.props.onChange({
                                    tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                                    providerCode: 'igms',
                                    authToken: message.code,
                                    /* the server-side remote api requires to
                                        provide the auth url again for verification */
                                    redirectUri: this.getCallbackUrl()
                                });
                            }
                        }else{
                            /* auth failed, just close the config */
                            if(typeof this.props.onClose === 'function'){
                                this.props.onClose();
                            }
                        }
                    }
                }
            }
        }catch(ex){
            console.log('Failed to handle auth event: ' + ex);
        }
    }

    /**
     * Returns the remote url for the authentication
     * with the iGMS system to be used in the iframe
     */
    getRemoteAuthUrl(){
        return 'https://igms.com/app/auth.html'
            + '?client_id=' + IGMS_AUTH_CLIENT_ID 
            + '&redirect_uri=' + encodeURIComponent(this.getCallbackUrl())
            + '&scope=' + IGMS_SCOPELIST.join(',');
    }

    /**
     * Creates the target url and returns it
     * as a string to be used for the callback
     */
    getCallbackUrl(){
        let targetEnv = 'eu';
        if(window.location.hostname === 'localhost'){
            targetEnv = 'dev';
        }else{
            let matchList = (/console\.([a-z]{2,3})\./gmi).exec(window.location.hostname);
            if(Array.isArray(matchList)){
                if(matchList.length === 2){
                    targetEnv = matchList[1];
                }
            }
        }

        /* create the complete target url and return it */
        return IGMS_CALLBACK_URL + '?connectivitiy_provider=' + ['1',targetEnv,'igms'].join(':');
    }

    /**
     * Renders the iframe or the connection information
     * and also handles the events of the iframe
     */
    render(){
        return(
            <>
                {this.props.editMode === false &&
                    <>
                        <div className="IGMSConnectionEditorText">
                            {Culture.getText('CONNECTIVITY_IGMS_CONNECT_INTRO')}
                        </div>

                        <div>
                            <StandardButton className="IGMSConnectionEdtiorConnectButton" 
                                text={Culture.getText('CONNECTIVITY_IGMS_CONNECTACCOUNT')}
                                onClick={() => {
                                    /* define the position of the window */
                                    const width = 800;
                                    const height = 650;
                                    const leftPos = window.screen.width/2 - width/2;
                                    const topPos = window.screen.height/2 - height/2;

                                    /* open the window with the connection */
                                    const url = this.getRemoteAuthUrl();
                                    this.windowHandle = window.open(url,'igms_window','toolbar=no,location=no,status=no,'
                                                        + 'menubar=no,scrollbars=yes,resizable=yes,width=' + width 
                                                        + ',height=' + height + ',left=' + leftPos + ',top=' + topPos);

                                    /* add the wait attribute to the container */
                                    let containerList = document.getElementsByClassName('ConnectionEditorContainer');
                                    for(let container of containerList){
                                        container.setAttribute('data-waiting','true');
                                    }

                                    /* handle the closing of the window */
                                    this.closeInterval = setInterval((function(){
                                        if(this.windowHandle.closed){
                                            clearInterval(this.closeInterval);
                                            if(typeof this.props.onClose === 'function'){
                                                this.props.onClose();
                                            }
                                        }
                                    }).bind(this),1000);
                                }} />
                        </div>

                        <div className="IGMSConnectionEditorWaitMessage">
                            {Culture.getText('CONNECTIVITY_IGMS_CONNECT_WAITMESSAGE')}
                        </div>
                    </>
                }
            </>
        );
    }
}

export default IGMSConnectionEditor;