/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */


import React from 'react';
import DatePicker from 'react-datepicker';

import Culture from '../../class/Culture';
import RatePlan from '../../class/RatePlan';

import '../../style/RatePlanEditor.css';

import DataInputField from '../common/DataInputField';
import StandardButton from '../common/StandardButton';
import DataInputPrice from '../common/DataInputPrice';
import DataInputList from '../common/DataInputList';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DateRangeSelect from '../common/DateRangeSelect';

/* defines the system maximum for length of stay */
const SYS_MAX_LENGTH_OF_STAY = 30;

/**
 * Rate plan editor allows editing rate plan
 * objects associated  with accommodation products
 */
class RatePlanEditor extends React.Component {
    state = {
        selectedDate: new Date((new Date()).getTime()+86400000),
        showEditWindow: false,
        currencyCode: 'EUR',
        priceAmount: 0,
        minLengthOfStay: 1,
        maxLengthOfStay: 30,
        occupancy: false,
        selectedOccupancy: 1,
        ratePlanName: '',
        ratePlanCode: '',
        ratePlanNameErrorText: '',
        ratePlanCodeErrorText: '',
        updatePriceMode: 'update',
        priceChangeFrom: false,
        priceChangeUntil: false
    }

    /**
     * Sets the name and code of the rate plan
     * from the provided prop object
     */
    componentDidMount(){
        if(typeof this.props.ratePlan.name === 'string'
            && typeof this.props.ratePlan.code === 'string'){
            this.setState({
                ratePlanName: this.props.ratePlan.name,
                ratePlanCode: this.props.ratePlan.code
            });
        }
    }

    /**
     * Notifies the parent component about changes
     * to the rate plan object and passes the updated
     * object to the parent
     * 
     * @param {object} value
     * the updated rate plan object 
     */
    notifyUpdate(value){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(value);
        }
    }

    /**
     * Updates the name and code of the rate plan
     * or shows an error message when the provided
     * details are insufficient
     */
    updateRatePlanInfo(){
        if(this.state.ratePlanName.length >= 3
            && this.state.ratePlanCode.length >= 3){
            /* update the values in the rate plan */
            let ratePlan = this.props.ratePlan;
            ratePlan.code = this.state.ratePlanCode;
            ratePlan.name = this.state.ratePlanName;
            this.notifyUpdate(ratePlan);
            this.setState({showEditWindow: false});
        }else{
            /* show an error message for invalid values */
            if(this.state.ratePlanName.length < 3){
                this.setState({ratePlanNameErrorText: Culture.getText('PROPERTY_RATEPLAN_NAME_INVALID')});
            }if(this.state.ratePlanCode.length < 3){
                this.setState({ratePlanCodeErrorText: Culture.getText('PROPERTY_RATEPLAN_CODE_INVALID')});
            }
        }
    }

    /**
     * Select the data and update the state
     */
    selectDate(dateObject){
        /* convert the provided date to UTC */
        let dateUTC = new Date(Date.UTC(dateObject.getFullYear(),
                    dateObject.getMonth(),dateObject.getDate()));
        this.setState({selectedDate: dateUTC});
    }

    /**
     * Toggles the active switch of this
     * rate plan making it inactive when
     * it is active and the other way around
     */
    toggleActive(){
        let ratePlan = new RatePlan(this.props.ratePlan);
        ratePlan.setActive(!ratePlan.isActive());
        this.notifyUpdate(ratePlan);
    }

    /**
     * Returns the list with all occupancy
     * options as a list for the list select
     */
    getOccupancyList(){
        let result = [{
            value: false,
            text: Culture.getText('PROPERTY_RATEPLAN_OCCUPANCY_ANY')
        }];

        for(let i=this.props.minOccupancy; i<=this.props.maxOccupancy; i++){
            result.push({
                value: i,
                text: i
            });
        }

        return result;
    }

    /**
     * Applies a price update to the
     * pricing list with the information
     * defined in the state. 
     */
    applyPriceUpdate(){
        /* iterate through each day in the selected range */
        let ratePlan = new RatePlan(this.props.ratePlan);
        for(let i=this.state.priceChangeFrom.getTime(); i<=this.state.priceChangeUntil.getTime(); i = i + 86400000){
            if(this.state.occupancy === false){
                /* iterate through all occupancy variations and update all */
                for(let o=parseInt(this.props.minOccupancy); o<=parseInt(this.props.maxOccupancy); o++){
                    ratePlan.changeLOSPrice(new Date(i),parseFloat(this.state.priceAmount),
                        this.state.currencyCode,o,this.state.minLengthOfStay,this.state.maxLengthOfStay);                    
                }
            }else{
                /* update the rate in the rate plan object */
                ratePlan.changeLOSPrice(new Date(i),parseFloat(this.state.priceAmount),
                                this.state.currencyCode,this.state.occupancy,
                                this.state.minLengthOfStay,this.state.maxLengthOfStay);
            }
        }
        this.notifyUpdate(ratePlan);
    }

    /**
     * Clears the price list in the defined
     * date range by removing all prices on
     * all days in that range
     */
    clearPriceList(){
        let ratePlan = new RatePlan(this.props.ratePlan);
        ratePlan.blockDateRange(this.state.priceChangeFrom,this.state.priceChangeUntil);
        this.notifyUpdate(ratePlan);
    }

    /**
     * Sets the occupancy view value
     * for the current price list view
     * 
     * @param {number} value
     * occupancy value to show 
     */
    selectOccupancyView(value){
        this.setState({selectedOccupancy: value});
    }

    /**
     * Applies the change of a single pricing
     * item in the price list for a given occupancy
     * 
     * @param {number} lengthOfStay 
     * the length of stay to change the price for
     * 
     * @param {object} value
     * the value object with value and currency code 
     */
    changeLengthOfStayPriceItem(lengthOfStay,value){
        let ratePlan = new RatePlan(this.props.ratePlan);
        let amountValue = parseInt(value.value);
        if(!isNaN(amountValue)){
            ratePlan.changeSinglePriceInDateRange(this.state.selectedDate,this.state.selectedDate,
                        lengthOfStay,this.state.selectedOccupancy,amountValue,value.currencyCode);
            this.notifyUpdate(ratePlan);
        }
    }

    /**
     * Blocks out any arrival or stay in
     * the time frame selected in the calendar
     */
    blockSelectedDateRange(){
        let ratePlan = new RatePlan(this.props.ratePlan);
        ratePlan.blockDateRange(this.state.selectedDate,this.state.selectedDate);
        this.notifyUpdate(ratePlan);
    }

    /**
     * Rendering method for single day
     * in the calendar view. This also
     * shows the availability information
     * on each calendar day
     * 
     * @param {number} day
     * the number of the day of month
     *  
     * @param {object} date
     * the date object of this calendar day
     */
    renderDayContent(day,date){
        let statusClassList = ['RatePlanCalendarDayStatus'];

        let ratePlan = new RatePlan(this.props.ratePlan);
        let priceText = ratePlan.getLowestPriceByDate(date);
        if(priceText === false){ 
            priceText = "—";
            statusClassList.push('RatePlanCalendarDayStatusUnavailable');
        }else{
            priceText = (new Intl.NumberFormat(Culture.getCultureCode(), {
                style: 'currency', 
                currency: priceText.currencyCode,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })).format(Math.round(priceText.amount*100)/100);
        }

        return(
            <div className="RatePlanCalendarDay">
                <div className="RatePlanCalendarDayNumber">{day}</div>
                <div className="RatePlanCalendarDayPrice">{priceText}</div>
                <div className={statusClassList.join(' ')}></div>
            </div>
        );
    }

    /**
     * Renders the price list for the current
     * date range selected in the calendar
     */
    renderPriceList(){
        /* get the pricing list from the rate plan object */
        let ratePlan = new RatePlan(this.props.ratePlan);
        let pricingLosList = ratePlan.getPricingListByDateRange(this.state.selectedDate,
                                    this.state.selectedDate,this.state.selectedOccupancy);

        /* render the length of stay pricing list */
        let list = [];
        Object.keys(pricingLosList).forEach((lengthOfStay) => {
            let priceValue = pricingLosList[lengthOfStay].amount;
            let currencyCode = pricingLosList[lengthOfStay].currencyCode;
            if(priceValue === false){ priceValue = null; }

            /* define the length of stay text for the list */
            let lengthOfStayText = Culture.getText('PROPERTY_RATEPLAN_PRICELIST_DAY_MULTIPLE').replace('{$num}',lengthOfStay);
            if(parseInt(lengthOfStay) === 1){
                lengthOfStayText = Culture.getText('PROPERTY_RATEPLAN_PRICELIST_DAY_SINGLE').replace('{$num}',lengthOfStay);
            }

            list.push(
                <div key={(lengthOfStay+priceValue+currencyCode)} className="RatePlanPriceItem">
                    <div className="RatePlanPriceItemLengthOfStay">
                        {lengthOfStayText}
                    </div>
                    <div className="RatePlanPriceItemAmount">
                        {(new Intl.NumberFormat(Culture.getCultureCode(), {
                            style: 'currency', 
                            currency: currencyCode,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })).format(priceValue)}
                    </div>
                </div>
            );
        });

        /* render the occupancy view selection */
        let occupancyList = [];
        for(let o=this.props.minOccupancy; o<=this.props.maxOccupancy; o++){
            let occupancyClassList = ['RatePlanPriceListOccupancyItem'];
            if(o === this.state.selectedOccupancy){
                occupancyClassList.push('RatePlanPriceListOccupancyItemSelected');
            }

            occupancyList.push(
                <div key={'occupancy'+o} className={occupancyClassList.join(' ')}
                    onClick={this.selectOccupancyView.bind(this,o)}>
                    {o}
                </div>
            );
        }

        /* show an info message when there are no prices in range */
        if(list.length === 0){
            return(
                <div className="RatePlanPriceList">
                    <div className="RatePlanPriceListOccupancyList">
                        <div className="RatePlanPriceListOccupancyListTitle">
                            {Culture.getText('PROPERTY_RATEPLAN_OCCUPANCYLIST_TITLE')}
                        </div>
                        <div className="RatePlanPriceListOccupancyListSelect">
                            {occupancyList}
                        </div>
                    </div>
                    <div key="RatePlanPriceListEmpty" className="RatePlanPriceListEmpty">
                        {Culture.getText('PROPERTY_RATEPLAN_DATE_EMPTY')}
                    </div>
                </div>
            );
        }else{
            return (
                <div className="RatePlanPriceList">
                    <div className="RatePlanPriceListOccupancyList">
                        <div className="RatePlanPriceListOccupancyListTitle">
                            {Culture.getText('PROPERTY_RATEPLAN_OCCUPANCYLIST_TITLE')}
                        </div>
                        <div className="RatePlanPriceListOccupancyListSelect">
                            {occupancyList}
                        </div>
                    </div>
                    <div className="RatePlanPriceListLengthOfStayList">
                        <div className="RatePlanPriceListHeader">
                            <div className="RatePlanPriceListHeaderDay">
                                {Culture.getText('PROPERTY_RATEPLAN_PRICELIST_HEADER_DAY')}
                            </div>
                            <div className="RatePlanPriceListHeaderPrice">
                                {Culture.getText('PROPERTY_RATEPLAN_PRICELIST_HEADER_PRICE')}
                            </div>
                        </div>
                        {list}
                    </div>
                </div>
            );
        }
    }

    /**
     * Renders the price config section
     * to change the daily rates accordingly
     */
    renderPriceConfig(){
        let result = [];

        if(this.props.isEditAllowed === true && this.state.showEditWindow === true){
            result = (
                <ModalDrawerOverlay onClose={() => this.setState({showEditWindow: false})}
                    transparentBackground={true}
                    titleText={Culture.getText('PROPERTY_RATEPLAN_PRICE_TITLE')}
                    subtitleText={Culture.getText('PROPERTY_RATEPLAN_PRICE_SUBTITLE')}
                    submitButtonText={Culture.getText('PROPERTY_RATEPLAN_PRICE_APPLY')}
                    submitDisabled={(
                        (this.state.priceChangeFrom === false || this.state.priceChangeUntil === false) 
                        || (this.state.updatePriceMode === 'update' && this.state.priceAmount <= 0)
                    )}
                    onSubmit={(function(){
                        if(this.state.updatePriceMode === 'update'){
                            this.applyPriceUpdate();
                            this.setState({showEditWindow: false});
                        }if(this.state.updatePriceMode === 'block'){
                            this.clearPriceList();
                            this.setState({showEditWindow: false});
                        }
                    }).bind(this)}>
                    <DataInputList title={Culture.getText('PROPERTY_RATEPLAN_UPDATEMODE')} 
                        value={this.state.updatePriceMode} 
                        list={[
                            {value: 'update', text: Culture.getText('PROPERTY_RATEPLAN_UPDATEMODE_PRICEUPDATE')},
                            {value: 'block', text: Culture.getText('PROPERTY_RATEPLAN_UPDATEMODE_BLOCKAVAILABILITY')}
                        ]} onChange={(value) => this.setState({updatePriceMode: value})}/>

                    <DateRangeSelect onChange={(function(from,until){
                            this.setState({
                                priceChangeFrom: (new Date(from)),
                                priceChangeUntil: (new Date(until))
                            });
                        }).bind(this)} 
                    />

                    {this.state.updatePriceMode === 'update' &&
                        <>
                            <DataInputPrice title={Culture.getText('PROPERTY_RATEPLAN_PRICE')} 
                                value={{value: this.state.priceAmount, currencyCode: this.state.currencyCode}}
                                onChange={(value) => this.setState({
                                    priceAmount: value.value, currencyCode: value.currencyCode
                                })}
                            />
            
                            <DataInputList title={Culture.getText('PROPERTY_RATEPLAN_OCCUPANCY')} 
                                value={this.state.occupancy} 
                                list={this.getOccupancyList()} onChange={(value) => {
                                    let occupancyValue = false;
                                    if(!isNaN(parseInt(value))){ occupancyValue = parseInt(value); }
                                    this.setState({occupancy: occupancyValue});
                            }}/>
            
                            <DataInputField title={Culture.getText('PROPERTY_RATEPLAN_MINLOS')} 
                                value={this.state.minLengthOfStay}
                                onChange={(value) => {
                                    let numberValue = parseInt(value.replace(new RegExp('[^0-9]','gmi'),''));
                                    if(isNaN(numberValue)){ numberValue = 1; }
                                    if(numberValue < 1){ numberValue = 1; }
                                    if(numberValue > SYS_MAX_LENGTH_OF_STAY){ numberValue = SYS_MAX_LENGTH_OF_STAY; }
            
                                    this.setState({minLengthOfStay: numberValue});
                            }} />
            
                            <DataInputField title={Culture.getText('PROPERTY_RATEPLAN_MAXLOS')} value={this.state.maxLengthOfStay}
                                onChange={(value) => {
                                    let numberValue = parseInt(value.replace(new RegExp('[^0-9]','gmi'),''));
                                    if(isNaN(numberValue)){ numberValue = 1; }
                                    if(numberValue < 1){ numberValue = 1; }
                                    if(numberValue > SYS_MAX_LENGTH_OF_STAY){ numberValue = SYS_MAX_LENGTH_OF_STAY; }
            
                                    this.setState({maxLengthOfStay: numberValue});
                            }} />
                        </>
                    }
                </ModalDrawerOverlay>
            );
        }

        return result;
    }

    /**
     * Renders the rate plan editor with the
     * name, code header, activation, the calendar
     * and the rate as well as the price input
     */
    render(){
        return(
            <>
                <ModalDrawerOverlay onClose={this.props.onClose}
                    titleText={Culture.getText('PROPERTY_RATEPLAN_TITLE')}
                    subtitleText={Culture.getText('PROPERTY_RATEPLAN_SUBTITLE')}>
                    <DataInputCheckBox title={Culture.getText('PROPERTY_RATEPLAN_ACTIVATE')}
                        checkedTitle={Culture.getText('PROPERTY_RATEPLAN_DEACTIVATE')}
                        checked={(this.props.ratePlan.active === true)}
                        onClick={this.toggleActive.bind(this)} />

                    <DataInputField title={Culture.getText('PROPERTY_RATEPLAN_CODE')} 
                            value={this.state.ratePlanCode}
                            errorText={this.state.ratePlanCodeErrorText}
                            onChange={(value) => {
                                let newRatePlanCode = value.replace(new RegExp('[^a-zA-Z0-9\\_\\-\\\\]','gmi'),'');
                                this.setState({ratePlanCode: newRatePlanCode});
                            }} />

                    <DataInputField title={Culture.getText('PROPERTY_RATEPLAN_NAME')} 
                            value={this.state.ratePlanName}
                            errorText={this.state.ratePlanNameErrorText}
                            onChange={(value) => {
                                let newRatePlanName = value.replace(new RegExp('[^a-zA-Z0-9\\_\\-\\\\ ]','gmi'),'');
                                this.setState({ratePlanName: newRatePlanName});
                            }} />

                    <div className="RatePlanCalendar">
                        <DatePicker inline={true} 
                            locale={Culture.getCalendarLocale()}
                            monthsShown={1} minDate={(new Date())}
                            selected={this.state.selectedDate}
                            renderDayContents={this.renderDayContent.bind(this)}
                            onChange={this.selectDate.bind(this)} />
                    </div>

                    {this.props.isEditAllowed === true &&
                        <div className="RatePlanCalendarButtonList">
                            <StandardButton className="RatePlanCalendarChangeButton"
                                text={Culture.getText('PROPERTY_RATEPLAN_PRICE_UPDATE')} 
                                onClick={() => this.setState({showEditWindow: true})} />
                            <StandardButton className="RatePlanCalendarBlockButton"
                                text={Culture.getText('PROPERTY_RATEPLAN_CALENDAR_BLOCK')} 
                                onClick={this.blockSelectedDateRange.bind(this)} />
                        </div>
                    }
                    {this.renderPriceList()}
                </ModalDrawerOverlay>
                {this.renderPriceConfig()}
            </>
        );
    }
}

export default RatePlanEditor;