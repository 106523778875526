/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */


import React from 'react';

/* import the Stripe Elements library */
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements,
    ElementsConsumer, 
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement, 
    IbanElement
} from '@stripe/react-stripe-js';

import Culture from '../../class/Culture';
import PaymentClient from '../../class/PaymentClient';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputCheckBox from '../common/DataInputCheckBox';
import LoadingIndicator from '../common/LoadingIndicator';

import '../../style/UpdatePaymentInput.css';

const FONT_FAMILY_STRING = "Roboto, -apple-system, BlinkMacSystemFont, "
            + "'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', "
            + "'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";


/**
 * Allows the user to authorize a new payment method
 * and notifies the parent component when the new payment
 * method was setup for billing with the stripe client
 */
class UpdatePaymentInput extends React.Component {
    state = {
        loaded: false,
        stripePromise: null,
        paymentType: 'card',
        ibanComplete: false,
        sepaPolicyAccepted: false,
        cardNumberComplete: false,
        expiryComplete: false,
        cvcComplete: false,
        completed: false,
        stripe: null,
        elementRef: null,
        clientSecret: '',
        updating: false,
        updateInitiated: false
    }

    /**
     * Mounts the component and sets the
     * stripe client handle in the state
     */
    componentDidMount(){
        this.setState({
            stripePromise: loadStripe(PaymentClient.getStipeKey(),{
                locale: Culture.getCultureCode()
            })
        },this.fetchClientSecret.bind(this));
    }

    /**
     * Fetches a new client secret
     * and sets it locally in the state
     */
    async fetchClientSecret(){
        try{
            let queryUrl = this.props.billingApiUrl + '/subscription/paymentSetupIntent';
            let response = await fetch(queryUrl,{method: 'post'});
            let setupResponse = await response.json();
            if(setupResponse.failed === false){
                this.setState({
                    loaded: true,
                    clientSecret: setupResponse.clientSecret
                });
            }else{
                if(typeof this.props.onFail === 'function'){
                    this.props.onFail();
                }
            }
        }catch(ex){
            if(typeof this.props.onFail === 'function'){
                this.props.onFail();
            }
        }
    }

    /**
     * Sets up the new payment method and
     * notifies the parent component when
     * the payment method was set up successfully
     * so that the subscription can be updated
     * with the new payment method
     */
    async applyPaymentMethod(){
        try{
            this.setState({updateInitiated: true});

            let paymentId = await PaymentClient.getPaymentMethod(
                this.props.customerName,this.props.customerEmail,
                this.state.clientSecret, this.state.paymentType,
                this.state.stripe,this.state.elementRef
            );

            this.setState({updating: true});

            if(typeof paymentId === 'string' && paymentId !== ''){
                if(typeof this.props.onUpdate === 'function'){
                    this.props.onUpdate(paymentId);
                }
            }else{
                this.props.onFail();
            }
        }catch(ex){
            console.log('Failed to apply payment: ' + ex);
            if(typeof this.props.onFail === 'function'){
                this.props.onFail();
            }
        }
    }

    /**
     * Raises the onReady event when all
     * the required details were provided
     * and the transaction can be intiated
     * otherwise does nothing
     */
    handleChange(stripe,elements){
        let isReady = false;

        let elementRef = null;
        if(this.state.paymentType === 'sepa_debit' 
            && this.state.ibanComplete === true
            && this.state.sepaPolicyAccepted === true){
            /* is ready, set the ref to the iban field */
            elementRef = elements.getElement(IbanElement);
            isReady = true;
        }if(this.state.paymentType === 'card'
            && this.state.cardNumberComplete === true
            && this.state.expiryComplete === true
            && this.state.cvcComplete === true){
            /* is ready, set the ref to the card field */
            elementRef = elements.getElement(CardNumberElement);
            isReady = true;
        }

        if(isReady === true){
            this.setState({
                completed: true,
                stripe: stripe,
                elementRef: elementRef
            });
        }else{
            this.setState({
                completed: false,
                stripe: null,
                elementRef: null
            });
        }
    }

    /**
     * Updates the payment type in the state
     * and notifies the parent about the reset
     * 
     * @param {string} code 
     * payment type code to change to
     */
    updatePaymentType(code){
        this.setState({
            paymentType: code,
            ibanComplete: false,
            sepaPolicyAccepted: false,
            cardNumberComplete: false,
            expiryComplete: false,
            cvcComplete: false,
            completed: false
        });
    }

    /**
     * Renders the SEPA payment input
     * form and returns it
     */
    renderSEPAInput(){
        return(
            <div className="PaymentFormInputFieldContainer">
                <div className="PaymentFormInputFieldTitle">
                    {Culture.getText('BILLING_ACCOUNT_PAYMENT_IBAN')}
                </div>
                <Elements key={Culture.getCultureCode()}
                    stripe={this.state.stripePromise} 
                    locale={Culture.getCultureCode()}>
                    <ElementsConsumer>
                        {({elements, stripe}) => (
                            <div className="PaymentFormInputFieldList">
                                <div className="PaymentFormInputField"
                                    data-validated={this.state.ibanComplete}>
                                    <IbanElement 
                                        options={{
                                            supportedCountries: ['SEPA'],
                                            placeholderCountry: 'DE',
                                            style: {
                                                base: {
                                                    fontFamily: FONT_FAMILY_STRING,
                                                    fontSize: '14px',
                                                    fontWeight: 'normal',
                                                    color: '#2B313D',
                                                    '::placeholder': {
                                                        color: '#c3c9d5',
                                                    },
                                                },
                                                invalid: {
                                                    color: '#C03333',
                                                },
                                            }
                                        }} 
                                        onChange={(function(e){
                                            if(e.complete === true){
                                                this.setState({ibanComplete: true},
                                                    this.handleChange.bind(this,stripe,elements));
                                            }
                                        }).bind(this)}
                                    />  
                                </div>

                                <DataInputCheckBox title={Culture.getText('BILLING_ACCOUNT_PAYMENT_SEPA_POLICY')} 
                                    checked={this.state.sepaPolicyAccepted} onClick={() => {
                                        this.setState({sepaPolicyAccepted: !this.state.sepaPolicyAccepted},
                                            this.handleChange.bind(this,stripe,elements));
                                }} />
                            </div>
                        )}
                    </ElementsConsumer>
                </Elements>
            </div>
        );
    }

    /**
     * Renders the card payment input
     * form and returns it
     */
    renderCardInput(){
        /* style config for the card fields */
        let optionStyleConfig = {
            base: {
                fontFamily: FONT_FAMILY_STRING,
                fontSize: '14px',
                fontWeight: 'normal',
                color: '#2B313D',
                '::placeholder': {
                    color: '#c3c9d5',
                },
            },
            invalid: {
                color: '#C03333',
            },
        };

        return(
            <Elements key={Culture.getCultureCode()}
                stripe={this.state.stripePromise} 
                locale={Culture.getCultureCode()}>
                <ElementsConsumer>
                    {({elements, stripe}) => (
                        <>
                            <div className="PaymentFormInputFieldContainer">
                                <div className="PaymentFormInputFieldTitle">
                                    {Culture.getText('BILLING_ACCOUNT_PAYMENT_CARDNUMBER')}
                                </div>
                                <div className="PaymentFormInputField" 
                                    data-validated={this.state.cardNumberComplete}>
                                    <CardNumberElement options={{
                                            showIcon: true,
                                            style: optionStyleConfig
                                        }} 
                                        onChange={(function(e){
                                            if(e.complete === true){
                                                this.setState({cardNumberComplete: true},
                                                    this.handleChange.bind(this,stripe,elements));
                                            }
                                        }).bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="PaymentFormInputFieldContainer">
                                <div className="PaymentFormInputFieldTitle">
                                    {Culture.getText('BILLING_ACCOUNT_PAYMENT_CARDEXPIRY')}
                                </div>
                                <div className="PaymentFormInputField" 
                                    data-validated={this.state.expiryComplete}>
                                    <CardExpiryElement options={{
                                        style: optionStyleConfig
                                    }} 
                                    onChange={(function(e){
                                        if(e.complete === true){
                                            this.setState({expiryComplete: true},
                                                this.handleChange.bind(this,stripe,elements));
                                        }
                                    }).bind(this)} />
                                </div>
                            </div>

                            <div className="PaymentFormInputFieldContainer">
                                <div className="PaymentFormInputFieldTitle">
                                    {Culture.getText('BILLING_ACCOUNT_PAYMENT_CARDCODE')}
                                </div>
                                <div className="PaymentFormInputField"
                                    data-validated={this.state.cvcComplete}>
                                    <CardCvcElement options={{
                                        style: optionStyleConfig
                                    }} 
                                    onChange={(function(e){
                                        if(e.complete === true){
                                            this.setState({cvcComplete: true},
                                                this.handleChange.bind(this,stripe,elements));
                                        }
                                    }).bind(this)} />
                                </div>
                            </div>  
                        </>
                    )}
                </ElementsConsumer>
            </Elements>
        );
    }

    /**
     * Renders the component including the stripe
     * elements to insert the payment details
     */
    render(){
        return(
            <ModalDrawerOverlay className="UpdateBillingPaymentWindow"
                titleText={Culture.getText('BILLING_ACCOUNT_UPDATEPAYMENT')}
                subtitleText={Culture.getText('BILLING_ACCOUNT_UPDATEPAYMENT_SUBTITLE')}
                submitButtonText={Culture.getText('BILLING_ACCOUNT_APPLYPAYMENTMETHOD')}
                submitDisabled={(this.state.completed === false || this.state.updateInitiated === true)}
                onSubmit={this.applyPaymentMethod.bind(this)}
                onClose={this.props.onCancel}>
                {this.state.loaded === false &&
                    <div className="UpdateBillingPaymentForm">
                        <LoadingIndicator />
                    </div>
                }
                {this.state.loaded === true && this.state.updating === false &&
                    <div className="UpdateBillingPaymentForm">
                        {this.props.sepaPaymentSupported === true &&
                            <div className="PaymentFormTypeSelectList" 
                                data-selectedtype={this.state.paymentType}>
                                <div className="PaymentFormTypeSelect" data-typecode="cc" 
                                    data-selected={this.state.paymentType === 'card'}
                                    onClick={this.updatePaymentType.bind(this,'card')}>
                                    {Culture.getText('BILLING_ACCOUNT_PAYMENT_METHOD_CARD')}
                                </div>
                                <div className="PaymentFormTypeSelect" data-typecode="sepa" 
                                    data-selected={this.state.paymentType === 'sepa_debit'}
                                    onClick={this.updatePaymentType.bind(this,'sepa_debit')}>
                                    {Culture.getText('BILLING_ACCOUNT_PAYMENT_METHOD_SEPA')}
                                </div>
                            </div>
                        }

                        <div className="PaymentFormInput">
                            {(function(){
                                let result = null;

                                if(this.state.stripePromise !== null){
                                    if(this.state.paymentType === 'sepa_debit'){
                                        result = this.renderSEPAInput(Culture);
                                    }else{
                                        result = this.renderCardInput(Culture);
                                    }
                                }

                                return result;
                            }).bind(this)()}
                        </div>
                    </div>
                }

                {(this.state.updating === true || this.state.updateInitiated === true) &&
                    <div className="UpdateBillingPaymentFormUpdateStatus">
                        <LoadingIndicator />
                    </div>
                }
            </ModalDrawerOverlay>
        );
    }
}

export default UpdatePaymentInput;