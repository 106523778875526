/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

class InventoryEditorButton extends React.Component {
    render(){
        let classList = ['InventoryEditorButton'];
        if(typeof this.props.className === 'string'){
            classList.push(this.props.className);
        }

        return(
            <div className={classList.join(' ')} onClick={() => {
                /* call the parent click handler if attached */
                if(typeof this.props.onClick === 'function'){
                    this.props.onClick();
                }
            }}>
                {this.props.title}
            </div>
        );
    }
}

export default InventoryEditorButton;