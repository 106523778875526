/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import DataInputField from '../common/DataInputField';
import DataInputList from '../common/DataInputList';
import StandardButton from '../common/StandardButton';
import Property from '../../class/Property';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

import '../../style/ContactEditor.css';

/**
 * The contact editor provides all functionality
 * to manage the contacts of a property which includes
 * creating, updating and deleting contacts
 */
class ContactEditor extends React.Component {
    state = {
        showOptionWindow: false,
        showEditor: false,
        contactIndex: null,
        profileType: 'general',
        gender: '',
        companyName: '',
        addressLine: '',
        postalCode: '',
        cityName: '',
        state: '',
        countryCode: '',
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: '',
        jobTitle: '',
        emailAddress: '',
        emailAddressError: '',
        phoneNumber: '',
        phoneNumberError: '',
        mobileNumber: '',
        mobileNumberError: '',
        faxNumber: '',
        faxNumberError: ''
    };

    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Returns the list with all countries, their
     * ISO-3166-1 country code and the localised name
     * in the currently set culture
     */
    getCountryList(){
        let result = [];

        /* get the list of countries and their localised name */
        let codeList = require('../../config/common/iso31661.json');
        codeList.forEach((code) => {
            let countryName = Culture.getText('COUNTRY_' + code.toUpperCase());
            result.push({
                value: code,
                text: countryName
            });
        });

        /* sort the country list by the localised name */
        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        /* add the empty item as first element regardless of sorting */
        result = [{
            value: "",
            text: Culture.getText('COUNTRY_NONE')
        }].concat(result);

        return result;
    }

    /**
     * Returns the localised gender list
     * with all available genders
     */
    getGenderList(){
        return [
            {value: "Unspecified", text: Culture.getText("CONTACT_GENDER_UNSPECIFIED")},
            {value: "Female", text: Culture.getText("CONTACT_GENDER_FEMALE")},
            {value: "Male", text: Culture.getText("CONTACT_GENDER_MALE")}
        ];
    }

    /**
     * Returns the localised option list
     * for all available job titles
     */
    getJobTitleList(){
        return [
            {value: "Administration Employee", text: Culture.getText("CONTACT_JOBTITLE_AMINISTRATIONEMPLOYEE")},
            {value: "Director of Business Development", text: Culture.getText("CONTACT_JOBTITLE_DIRECTORBUSINESSDEVELOPMENT")},
            {value: "E-Commerce Manager", text: Culture.getText("CONTACT_JOBTITLE_ECOMMERCEMANAGER")},
            {value: "Finance Manager", text: Culture.getText("CONTACT_JOBTITLE_FINANCEMANAGER")},
            {value: "Front Office Employee", text: Culture.getText("CONTACT_JOBTITLE_FRONTOFFICEEMPLOYEE")},
            {value: "Front Office Manager", text: Culture.getText("CONTACT_JOBTITLE_FRONTOFFICEMANAGER")},
            {value: "General Manager", text: Culture.getText("CONTACT_JOBTITLE_GENERALMANAGER")},
            {value: "Marketing Manager", text: Culture.getText("CONTACT_JOBTITLE_MARKETINGMANAGER")},
            {value: "Owner", text: Culture.getText("CONTACT_JOBTITLE_OWNER")},
            {value: "Reservations Employee", text: Culture.getText("CONTACT_JOBTITLE_RESERVATIONSEMPLOYEE")},
            {value: "Reservations Manager", text: Culture.getText("CONTACT_JOBTITLE_RESERVATIONSMANAGER")},
            {value: "Revenue Manager", text: Culture.getText("CONTACT_JOBTITLE_REVENUEMANAGER")},
            {value: "Rooms Division Manager", text: Culture.getText("CONTACT_JOBTITLE_ROOMSDIVISIONMANAGER")},
            {value: "Sales & Marketing Manager", text: Culture.getText("CONTACT_JOBTITLE_SALESMARKETINGMANAGER")},
            {value: "Sales Executive", text: Culture.getText("CONTACT_JOBTITLE_SALESEXECUTIVE")},
            {value: "Sales Manager", text: Culture.getText("CONTACT_JOBTITLE_SALESMANAGER")},
            {value: "Unknown", text: Culture.getText("CONTACT_JOBTITLE_UNKNOWN")}
        ];
    }

    /**
     * Returns the localised option list
     * with all available contact types
     */
    getContactTypeList(){
        return [
            {value: "general", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_GENERAL")},
            {value: "contract", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_CONTRACT")},
            {value: "reservations", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_RESERVATIONS")},
            {value: "invoices", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_INVOICES")},
            {value: "availability", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_AVAILABILITY")},
            {value: "site_content", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_SITE_CONTENT")},
            {value: "parity", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_PARITY")},
            {value: "requests", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_REQUESTS")},
            {value: "central_reservations", text: Culture.getText("GENERAL_PROPERTY_CONTACTTYPE_CENTRAL_RESERVATIONS")}
        ]
    }

    /**
     * Sets the contact index whose item the
     * user wants to edit and sets the values
     * for the editor
     * 
     * @param {number} contactIndex
     * index of the contact to edit 
     */
    editContactItem(contactIndex){
        let contactList = (new Property(this.props.property)).getContactList();
        for(let i=0; i<contactList.length; i++){
            if(i === contactIndex){
                let contactItem = contactList[i];

                let stateValue = {
                    showEditor: true,
                    contactIndex: contactIndex,
                    profileType: contactItem.profileType,
                    gender: '',
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    emailAddress: '',
                    phoneNumber: '',
                    mobileNumber: '',
                    faxNumber: '',
                };

                /* add the values from the address list */
                if(Array.isArray(contactItem.addressList)){
                    if(contactItem.addressList.length > 0){
                        let addressItem = contactItem.addressList[0];
                        Object.keys(addressItem).forEach((addressKey) => {
                            stateValue[addressKey] = addressItem[addressKey];
                        });
                    }
                }

                /* add the values from the person list */
                if(Array.isArray(contactItem.personList)){
                    if(contactItem.personList.length > 0){
                        let personItem = contactItem.personList[0];
                        Object.keys(personItem).forEach((personKey) => {
                            stateValue[personKey] = personItem[personKey];
                        });
                    }
                }

                /* add the values from the phone list */
                if(Array.isArray(contactItem.phoneList)){
                    let phoneTypeList = {
                        1: 'phoneNumber', 5: 'mobileNumber', 3: 'faxNumber'
                    };

                    contactItem.phoneList.forEach((phoneItem) => {
                        if(phoneTypeList.hasOwnProperty(phoneItem.typeCode)){
                            stateValue[phoneTypeList[phoneItem.typeCode]] = phoneItem.number;
                        }
                    });
                }

                /* add the email address value from the list */
                if(Array.isArray(contactItem.emailList)){
                    if(contactItem.emailList.length > 0){
                        stateValue.emailAddress = contactItem.emailList[0];
                    }
                }

                this.setState(stateValue);
            }
        }
    }

    /**
     * Deletes or removes the contact at the
     * provided index from the contact list
     * 
     * @param {number} contactIndex
     * index of the contact to delete 
     */
    deleteContactItem(contactIndex){
        let property = new Property(this.props.property);
        property.removeContact(contactIndex);
        this.setState({contactIndex: null});

        /* notify the parent component about the update */
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Renders the contact item detail view
     * with all the information of the contact
     * provided as a parameter
     * 
     * @param {object} contactItem
     * the contact item to render 
     */
    renderContactItem(contactItem,contactIndex){
        let personName = '';

        let addressComponent = [];
        if(Array.isArray(contactItem.personList)){
            if(contactItem.personList.length > 0){
                personName = [
                    contactItem.personList[0].firstName,
                    contactItem.personList[0].lastName
                ].join(' ');

                addressComponent.push(
                    <div key="personName" className="ContactItemPersonName">
                        {contactItem.personList[0].firstName} {contactItem.personList[0].lastName}
                    </div>
                );
            }
        }

        if(Array.isArray(contactItem.addressList)){
            if(contactItem.addressList.length > 0){
                let addressItem = contactItem.addressList[0];

                ['jobTitle','companyName','addressLine'].forEach((addressKey) => {
                    if(addressItem.hasOwnProperty(addressKey)){
                        if(addressKey !== 'jobTitle' && addressItem[addressKey] !== 'Unknown'){
                            if(addressItem[addressKey] !== null && addressItem[addressKey] !== ""){
                                addressComponent.push(
                                    <div key={addressKey} className="ContactItemAddressComponent">
                                        {addressItem[addressKey]}
                                    </div>
                                );
                            }
                        }
                    }
                });

                let cityTextList = [];
                ['cityName', 'state', 'postalCode'].forEach((addressKey) => {
                    if(addressItem.hasOwnProperty(addressKey)){
                        if(addressItem[addressKey] !== null && addressItem[addressKey] !== ""){
                            cityTextList.push(addressItem[addressKey]);
                        }
                    }
                });

                if(cityTextList.length > 0){
                    addressComponent.push(
                        <div key="cityText" className="ContactItemAddressComponent">
                            {cityTextList.join(' ')}
                        </div>
                    );
                }

            }
        }

        /* render all the communication parts */
        let communicationComponent = [];

        if(Array.isArray(contactItem.emailList)){
            let e = 0;
            contactItem.emailList.forEach((emailItem) => {
                if(emailItem !== "" && emailItem !== null){
                    communicationComponent.push(
                        <div key={'email'+e} className={"ContactItemAction ContactItemEmailAction"}
                            onClick={() => window.location.href = ('mailto:' + emailItem)}>
                            {emailItem}
                        </div>
                    );
                    e++;
                }
            });
        }

        if(Array.isArray(contactItem.phoneList)){
            let p = 0;
            contactItem.phoneList.forEach((phoneItem) => {
                communicationComponent.push(
                    <div key={'phone'+p} className={"ContactItemAction ContactItemPhoneAction"+phoneItem.typeCode}
                        onClick={() => window.location.href = ('tel:' + this.getE164NumberString(phoneItem.number))}>
                        {this.getE164NumberString(phoneItem.number)}
                    </div>
                );
                p++;
            });
        }

        return (
            <ModalDrawerOverlay 
                titleText={personName}
                subtitleText={Culture.getText('GENERAL_PROPERTY_CONTACTTYPE_'+contactItem.profileType.toUpperCase())}
                onClose={() => this.setState({
                    showOptionWindow: false,
                    contactIndex: null
                })}>

                <div className="ContactItemDetailViewAddress">
                    {addressComponent}
                </div>
                <div className="ContactItemDetailViewCommunication">
                    {communicationComponent}
                </div>

                <div className="ContactItemDetailViewOptionList">
                    <div key="edit" className="ContactItemAction ContactItemEditAction"
                        onClick={this.editContactItem.bind(this,contactIndex)}>
                        {Culture.getText('GENERAL_PROPERTY_CONTACTITEM_EDIT')}
                    </div>
                    <div key="delete" className="ContactItemAction ContactItemDeleteAction"
                        onClick={this.deleteContactItem.bind(this,contactIndex)}>
                        {Culture.getText('GENERAL_PROPERTY_CONTACTITEM_DELETE')}
                    </div>
                </div>
            </ModalDrawerOverlay>
        );
    }

    /**
     * Renders the contact with all contacts
     * associated with this property
     */
    renderContactList(){
        let result = [];

        let property = new Property(this.props.property);
        let contactList = property.getContactList();

        for(let i=0; i<contactList.length; i++){
            let contactItem = contactList[i];

            let nameList = [];
            if(Array.isArray(contactItem.personList)){
                contactItem.personList.forEach((person) => {
                    nameList.push(person.firstName + ' ' + person.lastName);
                });
            }

            result.push(
                <div key={i} className="ContactListItemView">
                    <div className="ContactListItem">
                        <div className="ContactListItemName">
                            <div className="ContactListItemNameList">{nameList.join(', ')}</div>
                        </div>
                        <div className="ContactListItemType">
                            {Culture.getText('GENERAL_PROPERTY_CONTACTTYPE_'+contactItem.profileType.toUpperCase())}
                        </div>
                        <div className="ContactListItemOptionButton" onClick={(function(contactIndex){
                            this.setState({
                                showOptionWindow: true,
                                contactIndex: contactIndex
                            });
                        }).bind(this,i)}></div>
                    </div>
                </div>
            );
        };

        if(contactList.length === 0){
            result.push(
                <div key="none" className="ContactListEmpty">
                    {Culture.getText('GENERAL_PROPERTY_CONTACTLIST_EMPTY')}
                </div>
            );
        }

        return result;
    }

    /**
     * Formats a text string with a phone number
     * to a number following ITU's E.164 standard,
     * but leaving aside the leading plus sign to
     * allow storing it as a number
     * 
     * @param {string} numberString
     * the string to format into e164 
     */
    formatE164Number(numberString){
        let result = '';

        if(numberString === '+'){
            result = '+';
        }else{
            let formatted = numberString.replace(/([^0-9])/gi,'');
            if(formatted !== ''){
                result = parseInt(formatted);
            }
        }

        return result;
    }

    /**
     * Formats the number in the E.164 format
     * provided as a number to a string with
     * a leading plus sign
     * 
     * @param {number} numberValue
     * the number in e.164 format without the plus sign 
     */
    getE164NumberString(numberValue){
        let result = numberValue;

        if(typeof numberValue === 'number'){
            result = '+' + numberValue;
        }

        return result;
    }

    /**
     * Update the property's contact with
     * the information provided in the state
     */
    updateContact(){    
        let isContactIncomplete = false;

        /* reset all error values for the form fields */
        this.setState({
            firstNameError: '', lastNameError: '', emailAddressError: '',
            phoneNumberError: '', mobileNumberError: '', faxNumberError: ''
        });

        /* check if the contact has incomplete fields */
        if(this.state.emailAddress.length > 0){
            if(!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(this.state.emailAddress)){
                isContactIncomplete = true;
                this.setState({
                    emailAddressError: Culture.getText('DATAFIELD_CONTACT_ERROR_EMAILFORMAT')
                });
            }
        }if(this.state.firstName.length < 2){
            isContactIncomplete = true;
            this.setState({
                firstNameError: Culture.getText('DATAFIELD_CONTACT_FIRSTNAME_ERROR')
            });
        }if(this.state.lastName.length < 2){
            isContactIncomplete = true;
            this.setState({
                lastNameError: Culture.getText('DATAFIELD_CONTACT_LASTNAME_ERROR')
            });
        }

        /* validate all provided phone numbers */
        let componentObject = this;
        ['phoneNumber','mobileNumber','faxNumber'].forEach((numberType) => {
            if(typeof componentObject.state[numberType] === 'number'){
                if(String(componentObject.state[numberType]).length < 5){
                    isContactIncomplete = true;
                    componentObject.setState({
                        [numberType+'Error']: Culture.getText('DATAFIELD_CONTACT_' 
                                            + numberType.toUpperCase() + '_ERROR')
                    });
                }
            }else{
                if(componentObject.state[numberType] !== ''){
                    isContactIncomplete = true;
                    componentObject.setState({
                        [numberType+'Error']: Culture.getText('DATAFIELD_CONTACT_' 
                                            + numberType.toUpperCase() + '_ERROR')
                    });
                }
            }
        });

        /* update the property with the contact details */
        if(isContactIncomplete === false){
            /* instanciate the property object */
            let property = new Property(this.props.property);

            /* upsert contact with the new contact information */
            property.updateContact(this.state.contactIndex,this.state.profileType,
                this.state.gender,this.state.firstName,this.state.lastName,
                this.state.addressLine, this.state.postalCode, this.state.cityName,
                this.state.state, this.state.countryCode, this.state.companyName,
                this.state.jobTitle,this.state.emailAddress,this.state.phoneNumber,
                this.state.mobileNumber,this.state.faxNumber);

            /* notify the parent component about the update */
            if(typeof this.props.onPropertyUpdated === 'function'){
                this.props.onPropertyUpdated(property);
            }

            this.setState({showEditor: false});
        }
    }

    /**
     * Renders the contact editor with the list
     * of contacts above the editor to add or
     * edit contacts
     */
    render(){
        return(
            <div className="InventoryEditorContact">
                <div className="ContactEditorList">
                    {this.renderContactList()}
                </div>

                <StandardButton className="ContactEditorAddContactButton" 
                    text={Culture.getText('PROPERTY_CONTACT_ADDCONTACT')}
                    onClick={() => this.setState({
                        contactIndex: null, 
                        showEditor: true,
                        gender: '',
                        companyName: '',
                        addressLine: '',
                        postalCode: '',
                        cityName: '',
                        state: '',
                        countryCode: '',
                        firstName: '',
                        firstNameError: '',
                        lastName: '',
                        lastNameError: '',
                        jobTitle: '',
                        emailAddress: '',
                        emailAddressError: '',
                        phoneNumber: '',
                        phoneNumberError: '',
                        mobileNumber: '',
                        mobileNumberError: '',
                        faxNumber: '',
                        faxNumberError: ''
                    })} />

                {this.renderOptionWindow()}
                {this.renderEditWindow()}
            </div>
        );
    }

    /**
     * Renders the option window for the
     * selected contact and returns it
     */
    renderOptionWindow(){
        let result = null;

        if(this.state.showOptionWindow === true
            && this.state.contactIndex !== null){
            let contactList = (new Property(this.props.property)).getContactList();
            result = this.renderContactItem(contactList[this.state.contactIndex],this.state.contactIndex);
        }

        return result;
    }

    /**
     * Renders the editor window that allows to
     * edit or create a contact from the list
     */
    renderEditWindow(){
        if(this.state.showEditor === false){
            return null;
        }else{
            let titleText = Culture.getText('GENERAL_PROPERTY_ADDCONTACT');
            let buttonText = Culture.getText('PROPERTY_CONTACT_ADDCONTACT');
            if(this.state.contactIndex !== null){
                titleText = Culture.getText('GENERAL_PROPERTY_EDITCONTACT');
                buttonText = Culture.getText('PROPERTY_CONTACT_UPDATECONTACT');
            }

            return(
                <ModalDrawerOverlay onClose={() => this.setState({showEditor: false})}
                    titleText={buttonText} subtitleText={titleText} 
                    submitButtonText={buttonText}
                    onSubmit={this.updateContact.bind(this)}
                    submitDisabled={(this.state.firstName === '' || this.state.lastName === '')}
                    transparentBackground={(this.state.contactIndex !== null)}>
                    <DataInputList title={Culture.getText('DATAFIELD_CONTACT_TYPE')} 
                        value={this.state.profileType} 
                        list={this.getContactTypeList()} onChange={(value) => {
                        this.setState({profileType: value});
                    }}/>

                    <DataInputList title={Culture.getText('DATAFIELD_CONTACT_GENDER')} 
                        value={this.state.gender} 
                        list={this.getGenderList()} onChange={(value) => {
                        this.setState({gender: value});
                    }}/>

                    <DataInputField title={Culture.getText('DATAFIELD_CONTACT_COMPANYNAME')} 
                        value={this.state.companyName} onChange={(value) => {
                        this.setState({companyName: value});
                    }} />

                    <DataInputField required={true}
                        title={Culture.getText('DATAFIELD_CONTACT_FIRSTNAME')} 
                        errorText={this.state.firstNameError}
                        value={this.state.firstName} onChange={(value) => {
                        this.setState({firstName: value});
                    }} />

                    <DataInputField required={true}
                        title={Culture.getText('DATAFIELD_CONTACT_LASTNAME')} 
                        errorText={this.state.lastNameError}
                        value={this.state.lastName} onChange={(value) => {
                        this.setState({lastName: value});
                    }} />

                    <DataInputList title={Culture.getText('DATAFIELD_CONTACT_JOBTITLE')} 
                        value={this.state.jobTitle} 
                        list={this.getJobTitleList()} onChange={(value) => {
                        this.setState({jobTitle: value});
                    }}/>

                    <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_ADDRESSLINE')} 
                        value={this.state.addressLine} onChange={(value) => {
                        this.setState({addressLine: value});
                    }} />

                    <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_POSTALCODE')} 
                        value={this.state.postalCode} onChange={(value) => {
                        this.setState({postalCode: value});
                    }} />

                    <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_CITYNAME')} 
                        value={this.state.cityName} onChange={(value) => {
                        this.setState({cityName: value});
                    }} />

                    <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_STATE')} 
                        value={this.state.state} onChange={(value) => {
                        this.setState({state: value});
                    }} />

                    <DataInputList title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_COUNTRYCODE')} 
                        value={this.state.countryCode} 
                        list={this.getCountryList()} onChange={(value) => {
                            this.setState({countryCode: value});
                    }}/>

                    <DataInputField title={Culture.getText('DATAFIELD_CONTACT_EMAILADDRESS')} 
                        errorText={this.state.emailAddressError}
                        value={this.state.emailAddress} onChange={(value) => {
                        this.setState({emailAddress: value});
                    }} />

                    <DataInputField title={Culture.getText('DATAFIELD_CONTACT_PHONENUMBER')} 
                        errorText={this.state.phoneNumberError}
                        value={this.getE164NumberString(this.state.phoneNumber)} onChange={(value) => {
                        this.setState({phoneNumber: this.formatE164Number(value)});
                    }} />

                    <DataInputField title={Culture.getText('DATAFIELD_CONTACT_MOBILENUMBER')} 
                        errorText={this.state.mobileNumberError}
                        value={this.getE164NumberString(this.state.mobileNumber)} onChange={(value) => {
                        this.setState({mobileNumber: this.formatE164Number(value)});
                    }} />

                    <DataInputField title={Culture.getText('DATAFIELD_CONTACT_FAXNUMBER')} 
                        errorText={this.state.faxNumberError}
                        value={this.getE164NumberString(this.state.faxNumber)} onChange={(value) => {
                        this.setState({faxNumber: this.formatE164Number(value)});
                    }} />
                </ModalDrawerOverlay>
            );
        }
    }
}

export default ContactEditor;