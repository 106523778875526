/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import '../../style/ReservationView.css';
import ContentHeaderMenu from '../common/ContentHeaderMenu';

import LoadingIndicator from '../common/LoadingIndicator';
import ReservationEditor from './ReservationEditor';
import ReservationList from './ReservationList';

/**
 * Handles the reservation view with the list
 * of all bookings as well as the view of individual
 * bookings in the booking view
 */
class ReservationView extends React.Component {
    state = {
        list: [],
        isLoading: false,
        isSearching: false,
        isReservationLoading: false,
        reservationFailed: false,
        updateFailed: false,
        recordLocator: '',
        accessKey: '',
        reservation: null,
        filter: null
    }

    /**
     * Handles the mount of the component
     * and initialises the components. It
     * also fetches the initial batch of
     * the reservation list
     */
    componentDidMount(){
        this.setState({isLoading: true, isSearching: true});

        /* fetch the reservation list */
        this.fetchReservationList();
    }

    /**
     * Returns the filter for the reservation
     * list query to filter with
     */
    getListFilter(){
        /* the default filter is for all reservations
            which an arrival between today and the next
            90 days in advance */
        let today = Math.round(((new Date()).getTime())/1000);
        let result = {
            createdFrom: today-(180*86400),
            createdUntil: today+(86400)
        };

        if(this.state.filter !== null){
            result = this.state.filter;
        }

        return result;
    }

    /**
     * Sets the filter object for querying
     * the reservation list and fetches the
     * list again
     * 
     * @param {object} paramList 
     * object with the params as keys
     */
    updateListFilter(paramList){
        if(typeof paramList === 'object'){
            this.setState({filter: paramList},this.fetchReservationList.bind(this));
        }
    }

    /**
     * Fetches all reservations and sets
     * them locally in the state
     */
    async fetchReservationList(){
        this.setState({isSearching: true});

        let apiResult = await ApiClient.execute('/booking/list',this.getListFilter());
        if(apiResult !== null){
            if(apiResult.failed === false){
                let list = apiResult.list;
    
                /* sort the list by the created date descending */
                list.sort((a,b) => (a.created < b.created) ? 1 : 
                            ((b.created < a.created) ? -1 : 0));
    
                this.setState({
                    list: list, 
                    isSearching: false, 
                    isLoading: false
                });
            }else{
                this.setState({
                    list: [], 
                    isSearching: false, 
                    isLoading: false
                });                
            }
        }else{
            this.setState({
                list: [], 
                isSearching: false, 
                isLoading: false
            });
        }
    }

    /**
     * Updates the current reservation's status
     * to the status code provided in the parameter
     * 
     * @param {string} code
     * status code to update the reservation to
     */
    async updateReservationStatus(code){
        let recordLocator = this.state.recordLocator;
        let accessKey = this.state.accessKey;

        /* reset all the states before updating */
        this.setState({
            isSearching: false, updateFailed: false,
            isReservationLoading: true, reservationFailed: false, 
            recordLocator: '', accessKey: '', reservation: null
        });

        /* execute the change of the reservation */
        let apiResult = await ApiClient.execute('/booking/update',{
            recordLocator: recordLocator,
            accessKey: accessKey,
            data: {status: code}
        });

        if(apiResult.failed === false){
            /* update the state and reload the list */
            this.setState({
                isSearching: false, 
                isReservationLoading: false,
                reservationFailed: false, 
                updateFailed: false,
                recordLocator: recordLocator,
                accessKey: accessKey, 
                reservation: apiResult.blockChain
            });

            /* reload the list to update the status of
                the reservation in the list as well */
            this.fetchReservationList();
        }else{
            this.setState({
                isSearching: false, 
                isReservationLoading: false,
                reservationFailed: false, 
                updateFailed: true,
                recordLocator: recordLocator,
                accessKey: accessKey, 
                reservation: null
            });
        }
    }

    /**
     * Fetches the reservation for which the
     * record locator and access key was provided
     * 
     * @param {string} recordLocator
     * the record locator of the reservation
     *  
     * @param {string} accessKey
     * access key to access the reservation with 
     */
    async fetchReservation(recordLocator,accessKey){
        this.setState({
            recordLocator: recordLocator, 
            isReservationLoading: true,
            reservation: null
        });

        let apiResult = await ApiClient.execute('/booking/view',{
            recordLocator: recordLocator,
            accessKey: accessKey
        });

        if(apiResult.failed === false){
            this.setState({
                recordLocator: recordLocator, 
                reservation: apiResult.blockChain,
                accessKey: accessKey,
                isReservationLoading: false,
                reservationFailed: false
            });
        }else{
            this.setState({
                recordLocator: recordLocator, 
                reservation: null,
                accessKey: '',
                isReservationLoading: false,
                reservationFailed: true
            });
        }
    }

    /**
     * Renders the bookings with the
     * components for both the list
     * as well as the individual view
     */
    render(){
        if(this.state.isLoading === true){
            return (
                <div className="ReservationLoadingIndicator">
                    <LoadingIndicator />
                </div>
            );
        }else{
            if(this.state.recordLocator !== ''){
                return(
                    <ReservationEditor isLoading={this.state.isReservationLoading} 
                        failed={this.state.reservationFailed}
                        reservation={this.state.reservation}
                        recordLocator={this.state.recordLocator}
                        reservationKey={this.state.accessKey}
                        updateFailed={this.state.updateFailed}
                        onUpdateStatus={this.updateReservationStatus.bind(this)}
                        onClose={() => this.setState({recordLocator: ''})} />
                );
            }else{
                return(
                    <>
                        <ContentHeaderMenu title={Culture.getText('RESERVATIONLIST_TITLE')}
                            subtitle={Culture.getText('RESERVATIONLIST_SUBTITLE')} />
                        <div className="ReservationView">
                            <div className="ReservationViewContainer">
                                <ReservationList isSearching={this.state.isSearching} 
                                    recordLocator={this.state.recordLocator} 
                                    onSelect={this.fetchReservation.bind(this)}
                                    onFilterUpdated={this.updateListFilter.bind(this)}
                                    list={this.state.list} />
                            </div>
                        </div>
                    </>
                );
            }
        }
    }
}

export default ReservationView;