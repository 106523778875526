/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the necessary core classes */
import Culture from '../../class/Culture';
import ManagementUser from '../../class/ManagementUser';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import DataInputField from '../common/DataInputField';
import StandardButton from '../common/StandardButton';
import LoadingIndicator from '../common/LoadingIndicator';
import DataInputCheckBox from '../common/DataInputCheckBox';
import ModalOptionListSelect from '../common/ModalOptionListSelect';

/* define the api url to fetch the unit list with */
const VOFFICE_APIURL = 'https://api2.v-office.com/api/json?actionName=getUnits&token=';

/**
 * The VOffice connection editor allows creating
 * and editing VOffice system connections
 */
class VOfficeConnectionEditor extends GenericProviderConnectionEditor {
    state = {
        apiKeyValue: '',
        connectInProgress: false,
        connectFailed: false,
        unitList: []
    }

    /**
     * Mounts the component and fetches the
     * necessary information for editing
     */
    componentDidMount(){
        if(this.props.editMode === true){
            /* initialise the unit list in edit mode */
            this.setState({
                apiKeyValue: this.getConfigValue('apiKey','')
            },this.fetchUnitList.bind(this));
        }
    }

    /**
     * Notifies the parent object about
     * the change and attaches the provider
     * specific configuration values
     */
    applyConfig(){
        if(typeof this.props.onCreate === 'function'){
            let configObject = {
                apiKey: this.getConfigValue('apiKey'),
                autoTranslate: this.getConfigValue('autoTranslate',false),
                overrideContent: this.getConfigValue('overrideContent',true),
                remoteSystemCode: 'voffice',
                providerCode: ['voffice',this.getUserName()].join('#'),
                tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                unitList: this.getConfigValue('unitList',[]),
                checkInTimeFieldId: this.getConfigValue('checkInTimeFieldId',0),
                checkOutTimeFieldId: this.getConfigValue('checkOutTimeFieldId',0),
                requireSetup: true
            };

            this.props.onCreate(configObject);
        }
    }

    /**
     * Fetches the unit list from the remote
     * and updates the state with the unit list
     * as well as the remote configuration
     */
    async fetchUnitList(){
        this.setState({
            connectInProgress: true, 
            connectFailed: false
        });

        let requestFailed = true;
        let unitList = [];
        let memberId = false;

        try{
            /* connect the voffice api and fetch all units */
            let hasMore = true;
            let lastId = false;

            while(hasMore === true){
                hasMore = false;
                let requestUrl = VOFFICE_APIURL+this.state.apiKeyValue;
                if(lastId !== false){
                    requestUrl = requestUrl + '&lastid=' + lastId;
                }
    
                let response = await fetch(requestUrl);
                if(response.status === 200){
                    let jsonResponse = await response.json();
                    if(Array.isArray(jsonResponse.units)){
                        requestFailed = false;
    
                        for(const unitObject of jsonResponse.units){
                            if(unitObject.info.isfacility !== true){
                                if(memberId === false && isNaN(unitObject.info.member) === false){
                                    memberId = unitObject.info.member;
                                }
    
                                unitList.push({
                                    id: unitObject._id,
                                    name: unitObject.info.fullName
                                });
                            }
                        }
                    }

                    if(isNaN(jsonResponse.lastid) === true){
                        hasMore = false;
                        lastId = false;
                    }else{
                        hasMore = true;
                        lastId = jsonResponse.lastid;
                    }
                }
            }

            if(requestFailed === false){
                let configObject = this.props.config;
                if(typeof configObject !== 'object' || configObject === null){
                    configObject = {
                        providerCode: this.props.channelCode,
                        tenantCode: ManagementUser.getCurrent().getActiveTenant()
                    };
                }

                configObject.apiKey = this.state.apiKeyValue;
                configObject.providerCode = [this.props.channelCode,memberId].join('#');
                this.notifyUpdate(configObject);
            }

            this.setState({
                connectInProgress: false,
                connectFailed: requestFailed,
                unitList: unitList
            });
        }catch(ex){
            console.log('Failed to connect vOffice: ' + ex.stack);
        }
    }

    /**
     * Renders the component and allows editing
     * or setting up the voffice connection
     */
    render(){
        /* determine the title text for the window and buttons */
        let titleText = Culture.getText('CONNECTIVITY_ADDCONNECTION');
        if(this.props.editMode === true){
            titleText = Culture.getText('CONNECTIVITY_EDITCONNECTION');
        }

        return(
            <>
                {this.getConfigValue('apiKey') === '' && this.state.connectInProgress === false &&
                    <>
                        <div className="VOfficeConnectionEditorContent">                    
                            <div className="ConnectionEditorInformationText">
                                {Culture.getText('CONNECTIVITY_VOFFICEAPIKEY_INTRO')}
                            </div>

                            {this.state.connectFailed === true &&
                                <div className="ConnectionEditorErrorMessage">
                                    {Culture.getText('CONNECTIVITY_VOFFICEAPIKEY_FAILED')}
                                </div>                        
                            }
                            <DataInputField title={Culture.getText('CONNECTIVITY_VOFFICEAPIKEY')}
                                value={this.state.apiKeyValue} onChange={(value) => {
                                    this.setState({apiKeyValue: value});
                                }} />
                        </div>
                        <div className="VOfficeConnectionEditorContentButtonList">
                            <StandardButton className="VOfficeConnectionEditorConnectButton" 
                                disabled={(this.state.apiKeyValue === '')}
                                text={Culture.getText('CONNECTIVITY_VOFFICE_TESTCONNECTION')}
                                onClick={this.fetchUnitList.bind(this)} />
                        </div>
                    </>
                }

                {this.state.connectInProgress === true &&
                    <div>
                        <LoadingIndicator />
                    </div>
                }

                {this.getConfigValue('apiKey') !== '' && this.state.connectInProgress === false &&
                    <>
                        <div className="VOfficeConnectionEditorContent">
                            <DataInputField title={Culture.getText('CONNECTIVITY_VOFFICEAPIKEY')}
                                value={this.getConfigValue('apiKey')} disabled={true} />

                            <DataInputField title={Culture.getText('CONNECTIVITY_VOFFICEMEMBERID')}
                                value={this.getUserName()} disabled={true} />

                            <DataInputCheckBox title={Culture.getText('CONNECTIVITY_AUTOTRANSLATE')} 
                                checkedTitle={Culture.getText('CONNECTIVITY_AUTOTRANSLATE')}
                                checked={this.getConfigValue('autoTranslate',true)} onClick={(function(){
                                    this.updateConfigValue('autoTranslate',!this.getConfigValue('autoTranslate',true));
                                }).bind(this)} 
                            />

                            <DataInputCheckBox title={Culture.getText('CONNECTIVITY_OVERRIDECONTENT')} 
                                checkedTitle={Culture.getText('CONNECTIVITY_OVERRIDECONTENT')}
                                checked={this.getConfigValue('overrideContent',true)} onClick={(function(){
                                    this.updateConfigValue('overrideContent',!this.getConfigValue('overrideContent',true));
                                }).bind(this)} 
                            />

                            <div className="ContentWarningMessage">
                                <div className="ContentWarningMessageTitle">
                                    {Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT_INFO_TITLE')}
                                </div>
                                <div className="ContentWarningMessageText">
                                    {Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT_INFO_TEXT')}
                                </div>
                            </div>

                            <ModalOptionListSelect transparentBackground={true}
                                title={Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT')} 
                                filterPlaceholderText={Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT_FILTERTEXT')}
                                addButtonText={Culture.getText('CONNECTIVITY_LIMITPROPERTYIMPORT_ADDPROPERTY')}
                                list={(function(){
                                    let result = [];

                                    let filterList = this.getConfigValue('unitList',[]);
                                    for(const unitObject of this.state.unitList){
                                        result.push({
                                            value: unitObject.id,
                                            text: unitObject.name,
                                            checked: filterList.includes(unitObject.id)
                                        });
                                    }

                                    result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

                                    return result;
                                }).bind(this)()} 
                                onToggle={(function(item){
                                    let itemExists = false;
                                    let updatedList = [];
                                    let filterList = this.getConfigValue('unitList',[]);

                                    let itemUnitId = parseInt(item.value);
                                    for(const unitId of filterList){
                                        if(unitId === itemUnitId){
                                            itemExists = true;
                                        }else{
                                            updatedList.push(unitId);
                                        }
                                    }

                                    if(itemExists === false){
                                        updatedList.push(itemUnitId);
                                    }

                                    this.updateConfigValue('unitList',updatedList);
                                }).bind(this)} />

                            <DataInputField title={Culture.getText('CONNECTIVITY_VOFFIC_CHECKINTIMEFIELD')}
                                value={this.getConfigValue('checkInTimeFieldId',0)}
                                onChange={(value) => {
                                    this.updateConfigValue('checkInTimeFieldId',(Number(value.replace(/[^0-9]/gmi,'')) || 0));
                                }} />

                            <DataInputField title={Culture.getText('CONNECTIVITY_VOFFIC_CHECKOUTTIMEFIELD')}
                                value={this.getConfigValue('checkOutTimeFieldId',0)}
                                onChange={(value) => {
                                    this.updateConfigValue('checkOutTimeFieldId',(Number(value.replace(/[^0-9]/gmi,'')) || 0));
                                }} />
                        </div>
                        
                        <div className="VOfficeConnectionEditorContentButtonList">
                            <StandardButton className="VOfficeConnectionEditorConnectButton" 
                                text={titleText} onClick={this.applyConfig.bind(this)} />
                        </div>
                    </>
                }
            </>
        );
    }
}

export default VOfficeConnectionEditor;