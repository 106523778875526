/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import ContentHeaderMenu from '../common/ContentHeaderMenu';
import UserListView from './UserListView';
import BillingView from './BillingView';
import MessagingConfigView from './MessagingConfigView';

import '../../style/SettingsView.css';
import ApiConfigView from './ApiConfigView';

class SettingsView extends React.Component {
    state = {
        activeSection: 'user'
    }

    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);

        if(this.props.billingIssue === true
            && this.state.activeSection !== 'billing'){
            this.setState({activeSection: 'billing'});
        }
    }

    /**
     * Handle the component update to handle any
     * billing issue that might exist with the tenant
     */
    componentDidUpdate(){
        if(this.props.billingIssue === true
            && this.state.activeSection !== 'billing'){
            this.setState({activeSection: 'billing'});
        }
    }

    /**
     * Defines the components for the sections
     * and renders the currently active section
     */
    renderSection(){
        let result = [];

        /* the list with all section components */
        let sectionList = {
            'user': <UserListView />,
            'api': <ApiConfigView />,
            'messaging': <MessagingConfigView />,
            'billing': <BillingView billingIssue={this.props.billingIssue}
                            onSignOut={this.props.onSignOut} />
        }

        /* set the component as result when present */
        if(sectionList.hasOwnProperty(this.state.activeSection)){
            result = sectionList[this.state.activeSection];
        }

        return result;
    }

    /**
     * Renders the settings view with the
     * configuration of the environment
     */
    render(){
        let menuList = [];
        let sectionList = ['user','billing','messaging','api'];
        sectionList.forEach((sectionCode) => {
            let isActive = false;
            if(this.state.activeSection === sectionCode){
                isActive = true;
            }
            
            menuList.push({
                code: sectionCode,
                active: isActive,
                title: Culture.getText('SETTINGS_SECTION_'+sectionCode.toUpperCase())
            });
        });

        /* only show the billing section when there is
            a billing issue with this account to force
            the user to resolve the issue before continuing
            to use the system */
        if(this.props.billingIssue === true){
            /* reset the menu to billing */
            menuList = [{
                code: 'billing',
                active: true,
                title: Culture.getText('SETTINGS_SECTION_BILLING')
            }];
        }

        return(
            <div className="SettingsView">
                <ContentHeaderMenu title={Culture.getText('SETTINGS_TITLE')} 
                        subtitle={Culture.getText('SETTINGS_SUBTITLE')}
                        menuList={menuList} 
                        onChange={(code) => this.setState({activeSection: code})} />

                <div className="SettingsContentView">
                    <div className="SettingsContentViewContainer">
                        {this.renderSection()}
                    </div>
                </div>
            </div>
        );
    }
}

export default SettingsView;