/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* include the core libraries for this view */
import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

/* include the components used in this view */
import LoadingIndicator from '../common/LoadingIndicator';
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import OptionListSelect from '../common/OptionListSelect';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';

/* include the stylesheet for this view */
import '../../style/StoreFrontView.css';
import ManagementUser from '../../class/ManagementUser';
import StandardButton from '../common/StandardButton';

/**
 * The store front view operates the actual website
 * builder or storefront builder that allows editing
 * the site of the storefront
 */
class StoreFrontView extends React.Component {
    state = {
        loading: true,
        failed: false,
        domainConfigLoading: false,
        useSubdomain: false,
        canSetupDomain: false,
        showSetupWindow: true,
        showDomainConnectWindow: false,
        defaultSiteLanguage: '',
        entriToken: '',
        domainName: '',
        dnsRecords: [],
        selectedSiteId: '',
        list: [],
    }

    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    async componentDidMount(){
        // attach the Entri script to the page if not present
        if(typeof window.entri === 'undefined'){
            let script = document.createElement('script');
            script.src = 'https://cdn.goentri.com/entri.js';
            document.body.appendChild(script);
        }

        Culture.attachCultureComponent(this);
        this.fetchSiteList();

        let languages = ManagementUser.getCurrent().getTenantLanguageList();
        if(languages.length > 0){
            this.setState({defaultSiteLanguage: languages[0]});
        }
    }

    /**
     * Fetches the Entri token and stores it in
     * the state of this component to be used with
     * the domain configuration when needed
     */
    async fetchEntriToken(){
        let url = '/widget/entri/token';
        let entriToken = await ApiClient.get(url);
        this.setState({entriToken: entriToken.token});
    }

    /**
     * Returns the SSO (single sign on) link for the given site
     * 
     * @param {string} siteId
     * the site id to get the SSO link for
     *  
     * @returns {string}
     * the SSO link for the given site
     */
    async getSiteSSOLink(siteId){
        let url = '/widget/sso/' + siteId;
        let ssoLink = await ApiClient.get(url);
        return ssoLink.url;
    }

    /**
     * Fetches the list of sites configured
     * for this tenant to configure storefront for
     */
    async fetchSiteList(){
        this.setState({loading: true});

        let url = '/widget/site/' + ManagementUser.getCurrent().activeTenant;
        let siteList = await ApiClient.get(url);
        if(Array.isArray(siteList.site)){
            let hasConfiguredDomain = false;

            let list = siteList.site;
            for(let i = 0; i < list.length; i++){
                list[i].ssoLink = await this.getSiteSSOLink(list[i].siteId);

                if(typeof list[i].distributionArn === 'string' && list[i].distributionArn !== ''){
                    // fetch the dns records for this domain
                    let domainResponse = await ApiClient.get('/widget/domain/' + list[i].siteId);
                    if(Array.isArray(domainResponse.records)){
                        list[i].dnsRecords = domainResponse.records;
                        hasConfiguredDomain = true;
                    }
                }
            }

            if(hasConfiguredDomain === true){
                await this.fetchEntriToken();
            }

            this.setState({list: siteList.site, loading: false});
        }else{
            this.setState({list: [], loading: false});
        }
    }

    /**
     * Returns the root domain for the given domain
     * 
     * @param {string} domain
     * the domain to get the root domain for
     *  
     * @returns {string}
     * the root domain for the given domain
     */    
    getRootDomain(domain) {
        const extTld = [
            '.co.uk',
            '.co.il',
            '.co.nz',
        ];

        const extRegex = /([a-zA-Z.-]{1,})\.([a-zA-Z-]{1,})\.(([a-zA-Z]{2}))\.(([a-zA-Z]{2}))/gmi;
        for(let i = 0; i < extTld.length; i++){
            if(domain.endsWith(extTld[i])){
                const match = extRegex.exec(domain);
                if (match && match.length === 7) {
                    return match[2] + '.' + match[3] + '.' + match[5];
                }
            }
        }

        const regex = /([a-zA-Z.-]{1,})\.([a-zA-Z-]{1,})\.(([a-zA-Z]{2,}))/gmi
        const match = regex.exec(domain);
        if (match && match.length === 5) {
            return match[2] + '.' + match[3];
        }

        return domain;
    }

    /**
     * Returns the subdomain for the given domain
     * 
     * @param {string} domain
     * the domain to get the subdomain for
     *  
     * @returns {string}
     * the subdomain for the given domain
     */
    getSubdomain(domain) {
        const rootDomain = this.getRootDomain(domain);
        let result = domain.substring(0, domain.length - rootDomain.length - 1);
        if(result.endsWith('.')){
            result = result.substring(0, result.length - 1);
        }
        return result;
    }

    /**
     * Creates a new website for the given language
     * and reloads the list of websites afterwards
     */
    async createWebsite(){
        this.setState({loading: true, showSetupWindow: false});

        await ApiClient.execute('/widget/create', {
            primaryLanguage: this.state.defaultSiteLanguage,
            secondaryLanguages: [],
        });

        this.fetchSiteList();
    }

    /**
     * Connects the domain to the selected site
     */
    async connectDomain(){
        if(this.state.canSetupDomain === true){
            this.setState({showDomainConnectWindow: false});

            let entriConfig = {
                applicationId: 'lodgea',
                token: this.state.entriToken,
                locale: Culture.getCultureCode(),
                prefilledDomain: this.state.domainName,
                wwwRedirect: (this.state.useSubdomain === false),
                whiteLabel: {
                    hideConfetti: true
                },
                dnsRecords: (function(){
                    let result = [];

                    for(let i = 0; i < this.state.dnsRecords.length; i++){
                        let item = this.state.dnsRecords[i];
                        result.push({
                            host: this.getSubdomain(item.name),
                            type: item.type,
                            value: item.value,
                            ttl: 300,
                        });
                    }

                    return result;
                }).bind(this)(),
            };

            window.addEventListener('onEntriClose', this.fetchEntriToken.bind(this), false);
            window.entri.showEntri(entriConfig);
        } else {
            this.setState({domainConfigLoading: true});
            await this.fetchEntriToken();

            let actualDomainName = this.state.domainName;
            if(this.state.useSubdomain === false){
                actualDomainName = 'www.' + actualDomainName;
            }

            let url = '/widget/domain/' + this.state.selectedSiteId + '/' + actualDomainName;
            let result = await ApiClient.execute(url, {});

            if(typeof result.site === 'object' && result.site !== null){
                // fetch the DNS records for the domain
                let domainResponse = await ApiClient.get('/widget/domain/' + this.state.selectedSiteId);
                if(Array.isArray(domainResponse.records)){
                    this.setState({
                        dnsRecords: domainResponse.records,
                        canSetupDomain: true,
                        domainConfigLoading: false,
                    });
                }
            }
        }
    }

    renderWebsiteList(){
        if(this.state.list.length > 0){
            return this.state.list.map((site, index) => {
                return(
                    <div className="StoreFrontViewWebsite" key={index}>
                        <div className="StoreFrontViewWebsitePreviewImage">
                            <img src={site.previewImageUrl} alt={site.domainName} onError={() => {
                                site.previewImageUrl = "https://de.cdn-website.com/5b1c0626027b463cb4f3055649360266"
                                    + "/screenshots/Screenshot.png?v=1704379616000&updated=true";
                                this.forceUpdate();
                            }} />
                        </div>
                        <div className="StoreFrontViewWebsiteDescription">
                            <div className="StoreFrontViewWebsiteTitle">
                                <div className="StoreFrontViewWebsiteName">
                                    {site.domainName || Culture.getText('STOREFRONT_WEBSITES_NODOMAIN')}
                                </div>

                                {site.domainStatus !== 'none' &&
                                    <div className="StoreFrontViewWebsiteDomainStatus" data-domainstatus={site.domainStatus}>
                                        {Culture.getText('STOREFRONT_WEBSITES_DOMAINSTATUS_' + site.domainStatus.toUpperCase())}
                                    </div>
                                }
                            </div>
                            <div className="StoreFrontViewWebsiteText">
                                {Culture.getText(site.isPublished === true ? 'STOREFRONT_WEBSITES_PUBLISHED' : 'STOREFRONT_WEBSITES_UNPUBLISHED')}
                            </div>

                            <div className="StoreFrontViewWebsiteActionList">
                                <StandardButton className="StoreFrontWebsiteEditButton"
                                        text={Culture.getText('STOREFRONT_WEBSITES_EDITBUTTON')}
                                        onClick={(function(ssoLoginUrl){
                                            window.open(ssoLoginUrl, '_blank');
                                        }).bind(this,site.ssoLink)} />

                                {site.domainStatus !== 'active' &&
                                    <StandardButton className="StoreFrontWebsiteConnectDomain"
                                        text={Culture.getText('STOREFRONT_WEBSITES_CONNECTDOMAIN')}
                                        onClick={(function(siteObject){
                                            if(Array.isArray(siteObject.dnsRecords)){
                                                this.setState({
                                                    showDomainConnectWindow: true,
                                                    selectedSiteId: siteObject.siteId,
                                                    domainName: siteObject.domainName,
                                                    dnsRecords: siteObject.dnsRecords,
                                                    canSetupDomain: true,
                                                });
                                            } else {
                                                this.setState({
                                                    showDomainConnectWindow: true,
                                                    selectedSiteId: siteObject.siteId,
                                                });
                                            }
                                        }).bind(this, site)} />
                                }
                            </div>
                        </div>
                    </div>
                );
            });
        }else{
            const templatePreviewUrl = "https://de.cdn-website.com/5b1c0626027b463cb4f3055649360266"
                                    + "/screenshots/Screenshot.png?v=1704379616000&updated=true";

            return(
                <>
                    <div className="StoreFrontViewWebsite">
                        <div className="StoreFrontViewWebsitePreviewImage">
                            <img src={templatePreviewUrl} alt={Culture.getText('STOREFRONT_WEBSITES_TITLE')} />
                        </div>

                        <div className="StoreFrontViewWebsiteDescription">
                            <div className="StoreFrontViewWebsiteName">{Culture.getText('STOREFRONT_WEBSITES_SETUP')}</div>
                            <div className="StoreFrontViewWebsiteText">
                                {Culture.getText('STOREFRONT_WEBSITES_SETUP_DESCRIPTION')}
                            </div>

                            <div className="StoreFrontViewWebsiteActionList">
                                <StandardButton className="StoreFrontWebsiteCreateButton"
                                        text={Culture.getText('STOREFRONT_WEBSITES_CREATE')}
                                        onClick={() => this.setState({showSetupWindow: true})} />
                            </div>
                        </div>
                    </div>

                    {this.state.showSetupWindow === true &&
                        <ModalDrawerOverlay className="StoreFrontViewWebsiteLanguageWindow"
                            titleText={Culture.getText('STOREFRONT_WEBSITES_CREATE')} 
                            subtitleText={Culture.getText('STOREFRONT_WEBSITES_CREATE_DESCRIPTION')}
                            onClose={() => this.setState({showSetupWindow: false})}
                            submitButtonText={Culture.getText('STOREFRONT_WEBSITES_CREATE')}
                            onSubmit={this.createWebsite.bind(this)}>
                            <OptionListSelect enableFilter={false}
                                list={(() => {
                                    let result = [];

                                    let languages = ManagementUser.getCurrent().getTenantLanguageList();
                                    for(let i = 0; i < languages.length; i++){
                                        result.push({
                                            value: languages[i], 
                                            text: Culture.getText('OTA_LANGUAGE_NAME_' + languages[i].toUpperCase()),
                                            checked: languages[i] === this.state.defaultSiteLanguage
                                        });
                                    }

                                    return result;
                                })()} onToggle={(item) => {
                                    this.setState({defaultSiteLanguage: item.value});
                            }} />
                        </ModalDrawerOverlay>
                    }
                </>
            );
        }
    }

    /**
     * Returns true if the domain name is valid
     * and false if not
     * 
     * @returns {boolean}
     * true if the domain name is valid and false if not
     */
    hasValidDomainName(){
        if(this.state.useSubdomain === true){
            // ensure the domain name has more than two dots in it
            return this.state.domainName.length > 0 && (/.+\..+\..+/).test(this.state.domainName);
        }

        // ensure the domain name has at least one dot in it
        return this.state.domainName.length > 0 && (/.+\..+/).test(this.state.domainName);
    }

    /**
     * Renders the component base with the
     * status indicators and error messages
     */
    render(){
        if(this.state.loading === true){
            /* show the loading indicator when loading */
            return(
                <div className="StoreFrontView">
                    <LoadingIndicator />
                </div>
            );
        }else{
            return(
                <>
                    <ContentHeaderMenu 
                        title={Culture.getText('STOREFRONT_WEBSITES_TITLE')} 
                        subtitle={Culture.getText('STOREFRONT_WEBSITES_DESCRIPTION')} />

                    <div className="StoreFrontView">
                        <div className="StoreFrontViewContainer">
                            {this.renderWebsiteList()}
                        </div>
                    </div>

                    {this.state.showDomainConnectWindow === true &&
                        <ModalDrawerOverlay className="StoreFrontViewWebsiteDomainWindow"
                            titleText={Culture.getText('STOREFRONT_WEBSITES_CONNECTDOMAIN')} 
                            subtitleText={Culture.getText('STOREFRONT_WEBSITES_CONNECTDOMAIN_DESCRIPTION')}
                            onClose={() => this.setState({showDomainConnectWindow: false})}
                            submitButtonText={Culture.getText('STOREFRONT_WEBSITES_DOMAINNAME_CONTINUE')}
                            submitDisabled={this.hasValidDomainName() === false}
                            onSubmit={this.connectDomain.bind(this)}>
                            {this.state.domainConfigLoading === true &&
                                <LoadingIndicator />
                            }

                            {this.state.domainConfigLoading === false && this.state.canSetupDomain === false &&
                            <>
                                <DataInputField
                                    errorText={(() => {
                                        let result = '';

                                        if(this.state.domainName.length > 0 && this.hasValidDomainName() === false){
                                            result = Culture.getText('STOREFRONT_WEBSITES_DOMAINNAME_INVALID');
                                        }

                                        return result;
                                    })()}
                                    onBlur={(function(){
                                        if(this.state.useSubdomain === false){
                                            this.setState({domainName: this.getRootDomain(this.state.domainName)});
                                        }
                                    }).bind(this)}
                                    title={Culture.getText('STOREFRONT_WEBSITES_DOMAINNAME')}
                                    value={this.state.domainName} onChange={(value) => {
                                        // replace anything that is not a letter, number or dash
                                        value = value.replace(/[^a-zA-Z0-9-.]/g,'');
                                        this.setState({domainName: value});
                                    }} />

                                <DataInputCheckBox title={Culture.getText('STOREFRONT_WEBSITES_DOMAINNAME_SUBDOMAIN')}
                                        checked={this.state.useSubdomain} onClick={() => {
                                            this.setState({useSubdomain: !this.state.useSubdomain});
                                        }}
                                    />
                            </>
                            }

                            {this.state.domainConfigLoading === false && this.state.canSetupDomain === true &&
                                <div className="StoreFrontViewWebsiteDomainNameText">
                                    {Culture.getText('STOREFRONT_WEBSITES_DOMAINNAME_TEXT')}
                                </div>
                            }
                        </ModalDrawerOverlay>
                    }
                </>
            );
        }
    }
}

export default StoreFrontView;