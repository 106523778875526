/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';

import PolicyDocumentEditor from './PolicyDocumentEditor';
import StandardButton from '../common/StandardButton';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

import '../../style/PolicyEditor.css';

/**
 * Component that provides a modal policy
 * editor to edit policies in a modal overlay window
 */
class ModalPolicyDocumentEditor extends React.Component {
    state = {
        showEditWindow: false,
        policyObject: null
    }

    /**
     * Sets the policy from the props
     * to the state so that the changes
     * to the policy are not immediately
     * applied to the property
     */
    componentDidMount(){
        if(typeof this.props.policy === 'object' && this.props.policy !== null){
            this.setState({policyObject: this.props.policy});
        }
    }

    /**
     * Notifies the parent component about
     * the changes to the policy
     * 
     * @param {object} value
     * the policy object to update 
     */
    updatePolicy(value){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(value);
            this.setState({policyObject: value});
        }
    }

    /**
     * Renders the policy input field and, if activated,
     * the overlay window with the policy document editor
     */
    render(){
        let property = new Property(this.props.property);
        let policyObject = property.getEmptyPolicy();
        let policyExists = false;
        if(typeof this.state.policyObject === 'object' && this.state.policyObject !== null 
            && Object.keys(this.state.policyObject).length > 0){
            policyExists = true;
            policyObject = this.state.policyObject;
        }

        let policyTitle = "";
        if(typeof this.props.policy === 'object' && this.props.policy !== null){
            if(this.props.policy.name !== "" && typeof this.props.policy.name === 'string'){
                policyTitle = this.props.policy.name;
            }
        }

        return(
            <div className="ModalPolicyInputField">
                <div className="ModalPolicyInputFieldTitle">
                    {this.props.title}
                </div>
                {policyExists === false &&
                    <div className="ModalPolicyInputFieldValue" onClick={() => this.setState({showEditWindow: true})}>
                        <div className="ModalPolicyInputFieldValueText ModalPolicyInputFieldValueUnset">
                            {Culture.getText('PROPERTY_NOPOLICY_DEFINED')}
                        </div>
                    </div>
                }
                {policyExists === true &&
                    <div className="ModalPolicyInputFieldValue">
                        <div className="ModalPolicyInputFieldValueText ModalPolicyInputFieldValueEdit"
                             onClick={() => this.setState({showEditWindow: true})}>
                            {policyTitle !== "" &&
                                <span>{policyTitle}</span>
                            }
                            {policyTitle === "" &&
                                <span>{Culture.getText('PROPERTY_POLICY_EDIT')}</span>
                            }
                        </div>
                        <div>
                            <StandardButton className="ModalPolicyInputFieldValueRemove"
                                text={Culture.getText('PROPERTY_POLICY_REMOVE')}
                                onClick={() => this.updatePolicy(null)} />
                        </div>
                    </div>
                }

                {this.state.showEditWindow === true &&
                    <ModalDrawerOverlay className="ModalPolicyEditorWindow" 
                        titleText={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTPOLICY_TITLE')}
                        subtitleText={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTPOLICY_SUBTITLE')}
                        submitButtonText={Culture.getText('PROPERTY_POLICY_UPDATE')}
                        onSubmit={() => {
                            this.updatePolicy(this.state.policyObject);
                            this.setState({showEditWindow: false});
                        }}
                        onClose={() => this.setState({showEditWindow: false})}>
                        <PolicyDocumentEditor 
                            policy={policyObject} 
                            property={this.props.property}
                            onChange={(value) => {
                                this.setState({policyObject: value});
                            }} />
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default ModalPolicyDocumentEditor;