/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import ConnectivityEndpointView from './ConnectivityEndpointView';
import SmoobuPropertyMappingEditor from './SmoobuPropertyMappingEditor';
import DataInputField from '../common/DataInputField';
import Culture from '../../class/Culture';

/**
 * Editor for a connection to the smoobu
 * system allowing to create and edit the
 * connections to these systems
 */
class SmoobuConnectionEditor extends GenericProviderConnectionEditor {
    /**
     * Mounts the component and initialises
     * the endpoint id for configs that do
     * not have an endpoint id
     */
    componentDidMount(){
        /* determine if there is an endpoint id and if not, create one */
        let endpointId = this.getConfigValue('endpointId',false);
        if(endpointId === false){
            this.updateConfigValue('endpointId', this.generateRandomId());
        }
    }

    /**
     * Generates a random id to be used as the
     * endpoint id for new connections to identify
     * with as the smoobu webhook does not contain
     * any form of authentication, the endpoint uses
     * a specific path to identify
     */
    generateRandomId() {
        const length = 16;
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    /**
     * Renders the component for the smoobu
     * connection and allows editing the
     * connection
     */
    render() {
        return (
            <div className="ProviderConnectionEditor">
                <ConnectivityEndpointView 
                    channelCode={this.props.channelCode} 
                    path={this.getConfigValue('endpointId')} />

                {this.props.editMode === false &&
                    <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERUSERID')}
                        value={this.getUserName()} 
                        onChange={(value) => this.updateConfigValue('userName',value.replace(/[^0-9]/,''))}
                        onBlur={(value) => {
                            if(value !== ''){
                                this.updateConfigValue('userName',value.replace(/[^0-9]/,'').replace(/^0+/g, ''));
                            }
                        }} />
                }

                {this.props.editMode === true &&
                    <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERUSERID')}
                        value={this.getUserName()} disabled={true} />
                }

                <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERAPIKEY')}
                    value={this.getConfigValue('apiKey')} onChange={(value) => {
                        this.updateConfigValue('apiKey', value);
                    }} />

                <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERCHANNELID')}
                    value={this.getConfigValue('channelId')} onChange={(value) => {
                        this.updateConfigValue('channelId', value);
                    }} />

                <SmoobuPropertyMappingEditor list={this.getConfigValue('mappingList',{})} 
                    remotePropertyIdName={Culture.getText('CONNECTIVITY_CHANNEL_SMOOBU_ACCOMMODATIONID')}
                    apiKey={this.getConfigValue('apiKey')}
                    onChange={(value) => {
                        this.updateConfigValue('mappingList', value);
                    }} />
            </div>
        );
    }
}

export default SmoobuConnectionEditor;