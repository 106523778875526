/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* require the user class to get the access token 
    and the culture to automatically append the 
    culture configuration */
import ManagementUser from "./ManagementUser";
import Culture from "./Culture";

/* import the file extension definitions */
const FileExtensionList = require('../config/common/FileExtensionList.json');

/**
 * The api client provides the interface to
 * the api and all available api methods
 */
class ApiClient {
    /**
     * Returns the api access token for authenticated
     * request against the api with the token attached
     */
    static getApiAccessToken(){
        return ManagementUser.getCurrent().getAccessToken();
    }

    /**
     * Returns the endpoint for the api based on the
     * domain of this application
     */
    static getApiEndpoint(){
        /* set the default api endpoint url */
        let result = "api.uat.lodgea.net";

        /* determine the url from the hostname */
        let hostName = window.location.hostname;
        if(hostName.match(/console\.([a-z.-]*)/gmi)){
            result = hostName.replace("console.","api.");
        }

        return 'https://' + result;
    }

    /**
     * Returns the endpoint for the media content
     * based on the domain of this application
     */
    static getMediaEndpoint(){
        /* set the default api endpoint url */
        let result = "media.uat.lodgea.net";

        /* determine the url from the hostname */
        let hostName = window.location.hostname;
        if(hostName.match(/console\.([a-z.-]*)/gmi)){
            result = hostName.replace("console.","media.");
        }

        return 'https://' + result;      
    }

    /**
     * Attaches a function that is notified when any
     * errors or exception with the network connection
     * arise to be able to handle it
     * 
     * @param {function} func
     * the function to be called on network errors 
     */
    static attachNetworkErrorListener(func){
        if(typeof func === 'function'){
            if(!Array.isArray(ApiClient.ErrorListenerList)){
                ApiClient.ErrorListenerList = [];
            }

            /* attach the function to the listeners */
            ApiClient.ErrorListenerList.push(func);
        }
    }

    /**
     * This method notifies any attached network error
     * listeners about the connectivity issue
     * 
     * @param {object} exceptionObject
     * the exception object for network errors 
     */
    static notifyNetworkErrorListener(exceptionObject){
        if(Array.isArray(ApiClient.ErrorListenerList)){
            ApiClient.ErrorListenerList.forEach((listenerFunc) => {
                listenerFunc(exceptionObject);
            });
        }
    }

    /**
     * Attaches a function that is notified when
     * the authentication of the user in this session
     * becomes invalid (e.g. by expiring)
     * 
     * @param {function} func
     * function to call when authentication fails 
     */
    static attachAuthFailListener(func){
        if(typeof func === 'function'){
            if(!Array.isArray(ApiClient.AuthFailListenerList)){
                ApiClient.AuthFailListenerList = [];
            }

            /* attach the function to the listeners */
            ApiClient.AuthFailListenerList.push(func);
        }
    }

    /**
     * Notifies the listeners for authentication
     * failure to allow handling expired or failed
     * authentication tokens
     */
    static notifyAuthFailListener(){
        if(Array.isArray(ApiClient.AuthFailListenerList)){
            ApiClient.AuthFailListenerList.forEach((listenerFunc) => {
                listenerFunc();
            });
        }
    }

    /**
     * Performs a simple GET query to
     * get ressources from the API
     * 
     * @param {string} path
     * path of the resource to get
     *  
     * @param {string} languageCode 
     * code of the language to get the resource in
     */
    static async get(path,languageCode){
        let result = null;
    
        /* ensure the path has a leading forward slash */
        let requestUrl = this.getApiEndpoint() + path;
        if(!path.startsWith('/')){
            requestUrl = this.getApiEndpoint() + '/' + path;
        }

        /* handle any network errors */
        let response = null;
        try{
            /* define the request language which is the current
                user language by default unless overwritten by
                the language parameter provided. This is especially
                required when data needs to be queried in the frontend
                configuration language and not the current user language */
            let requestLanguageCode = Culture.getCultureCode();
            if(typeof languageCode === 'string' && languageCode !== ''){
                requestLanguageCode = languageCode;
            }

            /* execute the api request */
            response = await fetch(requestUrl,{
                method: 'get',
                /* define the access token of this user as well as
                    the language from the culture configuration and
                    the currently set tenant for the operation */
                headers: {
                    'x-auth-token': ApiClient.getApiAccessToken(),
                    'x-auth-user': ManagementUser.getCurrent().getUserId(),
                    'x-auth-role': ManagementUser.getCurrent().getUserRole(),
                    'x-auth-tenant': ManagementUser.getCurrent().getActiveTenant(),
                    /* ip address not required for console authenticated
                        users as it is only required for frontend operations
                        were the user or consumer is not authenticated */
                    'x-auth-ipaddr': '0.0.0.0',
                    'x-preferred-language': requestLanguageCode
                }
            });
        }catch(ex){
            /* notify the network error listeners */
            ApiClient.notifyNetworkErrorListener(ex);
        }

        if(response != null){
            result = await response.json();

            if(result.failed === true){
                /* check if there is authentication failure
                    and report the listeners to allow the
                    user to log in to the system again */
                if(result.errorCode === "AUTH_FAIL"){
                    this.notifyAuthFailListener();
                }

                result = null;
            }
        }

        return result;
    }

    /**
     * Performs a simple DELETE query to
     * delete ressources from the API
     * 
     * @param {string} path
     * path of the resource to delete
     *  
     * @param {string} languageCode 
     * code of the language to get the resource in
     */
    static async delete(path,languageCode){
        let result = null;
    
        /* ensure the path has a leading forward slash */
        let requestUrl = this.getApiEndpoint() + path;
        if(!path.startsWith('/')){
            requestUrl = this.getApiEndpoint() + '/' + path;
        }

        /* handle any network errors */
        let response = null;
        try{
            /* define the request language which is the current
                user language by default unless overwritten by
                the language parameter provided. This is especially
                required when data needs to be queried in the frontend
                configuration language and not the current user language */
            let requestLanguageCode = Culture.getCultureCode();
            if(typeof languageCode === 'string' && languageCode !== ''){
                requestLanguageCode = languageCode;
            }

            /* execute the api request */
            response = await fetch(requestUrl,{
                method: 'delete',
                /* define the access token of this user as well as
                    the language from the culture configuration and
                    the currently set tenant for the operation */
                headers: {
                    'x-auth-token': ApiClient.getApiAccessToken(),
                    'x-auth-user': ManagementUser.getCurrent().getUserId(),
                    'x-auth-role': ManagementUser.getCurrent().getUserRole(),
                    'x-auth-tenant': ManagementUser.getCurrent().getActiveTenant(),
                    /* ip address not required for console authenticated
                        users as it is only required for frontend operations
                        were the user or consumer is not authenticated */
                    'x-auth-ipaddr': '0.0.0.0',
                    'x-preferred-language': requestLanguageCode
                }
            });
        }catch(ex){
            /* notify the network error listeners */
            ApiClient.notifyNetworkErrorListener(ex);
        }

        if(response != null){
            result = await response.json();

            if(result.failed === true){
                /* check if there is authentication failure
                    and report the listeners to allow the
                    user to log in to the system again */
                if(result.errorCode === "AUTH_FAIL"){
                    this.notifyAuthFailListener();
                }

                result = null;
            }
        }

        return result;
    }

    /**
     * Executes an authenticated api request for
     * the path provided with the body provided
     * using the users locally stored authentication
     * token for the authentication with the api
     * 
     * @param {string} path
     * the path to request the data from
     *  
     * @param {object} body 
     * the request object to post as JSON string
     * 
     * @param {string} languageCode
     * (OPTIONAL) language code to override current culture settings
     */
    static async execute(path,body,languageCode){
        let result = null;

        /* attach the provided body to the request body */
        let requestBody = {};
        if(typeof body === 'object'){
            requestBody = Object.assign(requestBody,body);
        }
    
        /* ensure the path has a leading forward slash */
        let requestUrl = this.getApiEndpoint() + path;
        if(!path.startsWith('/')){
            requestUrl = this.getApiEndpoint() + '/' + path;
        }

        /* handle any network errors */
        let response = null;
        try{
            /* define the request language which is the current
                user language by default unless overwritten by
                the language parameter provided. This is especially
                required when data needs to be queried in the frontend
                configuration language and not the current user language */
            let requestLanguageCode = Culture.getCultureCode();
            if(typeof languageCode === 'string' && languageCode !== ''){
                requestLanguageCode = languageCode;
            }

            /* execute the api request */
            response = await fetch(requestUrl,{
                method: 'post',
                /* define the access token of this user as well as
                    the language from the culture configuration and
                    the currently set tenant for the operation */
                headers: {
                    'x-auth-token': ApiClient.getApiAccessToken(),
                    'x-auth-user': ManagementUser.getCurrent().getUserId(),
                    'x-auth-role': ManagementUser.getCurrent().getUserRole(),
                    'x-auth-tenant': ManagementUser.getCurrent().getActiveTenant(),
                    /* ip address not required for console authenticated
                        users as it is only required for frontend operations
                        were the user or consumer is not authenticated */
                    'x-auth-ipaddr': '0.0.0.0',
                    'x-preferred-language': requestLanguageCode
                },
                body: JSON.stringify(requestBody)
            });
        }catch(ex){
            /* notify the network error listeners */
            ApiClient.notifyNetworkErrorListener(ex);
        }

        if(response != null){
            result = await response.json();

            if(result.failed === true){
                /* check if there is authentication failure
                    and report the listeners to allow the
                    user to log in to the system again */
                if(result.errorCode === "AUTH_FAIL"){
                    this.notifyAuthFailListener();
                }

                result = null;
            }
        }

        return result;
    }

    /**
     * This method uploads a media file to the
     * media server for the property specified
     * and reports the status to the callback
     * method provided
     * 
     * @param {string} propertyCode
     * code or identifier of the property to upload for
     *  
     * @param {string} fileName
     * the file name of the file to upload
     * 
     * @param {object} fileObject 
     * the file object to upload
     */
    static async uploadMediaFile(propertyCode,fileName,fileObject){
        let result = false;

        /* get the upload url for this file upload */
        let uploadUrlResponse = await ApiClient.execute('/inventory/property/media/upload',{
            propertyCode: propertyCode, fileName: fileName
        });

        /* ensure a valid upload url was provided by the api */
        if(uploadUrlResponse.failed === false && uploadUrlResponse.url !== ''){
            let targetUrl = uploadUrlResponse.url;
            let content = await ApiClient.getFileContent(fileObject);
            
            /* initiate the upload processing with HTTP PUT */
            let response = await fetch(targetUrl,{
                method: 'put',
                headers: {
                    'Content-Type': fileObject.type
                },
                body: content
            });

            /* check if the object was succesfully uploaded */
            if(response.ok === true){
                result = true;
            }
        }

        return result;
    }

    /**
     * Uploads a static content or stylesheet file
     * to the frontend file storage of the tenant
     * 
     * @param {string} fileType 
     * the file type to upload (stylesheet or file)
     * 
     * @param {object} fileObject 
     * the file object to upload
     */
    static async uploadContentFile(fileType,fileObject){
        let result = false;

        /* get the upload url for this file upload */
        let uploadUrlResponse = await ApiClient.execute('/frontend/fileUpload',{
            fileTypeCode: fileType, fileName: fileObject.name
        });

        /* ensure a valid upload url was provided by the api */
        if(uploadUrlResponse.failed === false && uploadUrlResponse.url !== ''){
            let targetUrl = uploadUrlResponse.url;
            let content = await ApiClient.getFileContent(fileObject);
            
            /* initiate the upload processing with HTTP PUT */
            let response = await fetch(targetUrl,{
                method: 'put',
                headers: {
                    'Content-Type': fileObject.type,
                    'Cache-Control': 'max-age=31536000'
                },
                body: content
            });

            /* check if the object was succesfully uploaded */
            if(response.ok === true){
                result = true;
            }
        }

        return result;
    }

    /**
     * Uploads a static content or stylesheet file
     * to the froontend file storage of the tenant
     * with the base64 content being given in the
     * parameters
     * 
     * @param {string} fileType 
     * type of the file to upload (file or stylesheet)
     * 
     * @param {string} mimeType 
     * mime type of the file to upload
     * 
     * @param {string} fileName 
     * the name of the file to upload
     * 
     * @param {string} base64Content 
     * the base64 content of the file to upload
     */
    static async uploadContentFileBase64(fileType,mimeType,fileName,base64Content){
        /* convert the base64 string to a uint8Array */
        let byteString = atob(base64Content);
        let n = byteString.length;
        let uint8Array = new Uint8Array(n);
        while(n--){ uint8Array[n] = byteString.charCodeAt(n); }

        /* create the file object and upload it */
        let fileObject = new File([uint8Array], fileName, {type:mimeType});
        await ApiClient.uploadContentFile(fileType,fileObject);
    }

    /**
     * Returns the default file extensions for
     * the provided mime type as a string or
     * the default if no extension is known
     * 
     * @param {string} mimeType 
     * mime type to return default extension of
     */
    static getFileExtensionByMimeType(mimeType){
        let result = 'dat';

        if(typeof FileExtensionList[mimeType] === 'string'
            && FileExtensionList[mimeType] !== ''){
            result = FileExtensionList[mimeType];
        }

        return result;
    }

    /**
     * Fetches a content meida file from the url
     * provided and returns the content with mime
     * type as an object
     * 
     * @param {string} url 
     * url to return as object
     */
    static async getContentMediaFile(url){
        let result = null;

        try{
            let response = await fetch(url);
            if(response.status === 200){
                let fileBuffer = await response.arrayBuffer();
                result = {
                    mimeType: response.headers.get('Content-Type'),
                    content: Buffer.from(fileBuffer).toString('base64')
                };
            }
        }catch(ex){
            console.log('Failed to fetch content media file: ' + ex);
            result = null;
        }

        return result;
    }

    /**
     * Returns the full url of the filename
     * provided in the parameters for the
     * property provided
     * 
     * @param {string} propertyCode
     * code or identifier of the property the image belongs to
     * 
     * @param {string} fileName 
     * the file name of the image 
     * 
     * @param {Number} lastUpdated
     * optional timestamp when the media item was last updated to
     * ensure the most current version of the item is loaded
     */
    static getMediaContentUri(propertyCode,fileName,lastUpdated){
        let result = "";

        if(typeof fileName === 'string' && fileName !== '' && fileName.indexOf('https://') < 0){
            result = this.getMediaEndpoint() + '/' 
                    + ManagementUser.getCurrent().getActiveTenant() 
                    + '/' + propertyCode + '/' + fileName;
        }

        /* only attach the update timestamp when it was
            actually provided in the parameter, otherwise
            omit it to avoid permanent reloading of the
            relatively large images */
        if(typeof lastUpdated === 'number'){
            if(lastUpdated > 0){
                result = result + '?ts=' + lastUpdated;
            }
        }

        return result;
    }

    /**
     * Returns the contents of the file object
     * provided to be uploaded to the destination
     * 
     * @param {object} fileObject 
     * the file object that represents the readable
     * file to be uploaded
     */
    static getFileContent(fileObject){
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();  
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.readAsArrayBuffer(fileObject)
        });
    }

    /**
     * Returns the content of the file as a
     * base64 url encoded string
     * 
     * @param {object} fileObject
     * the file object to return contents for 
     */
    static getFileContentDataURL(fileObject){
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();  
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.readAsDataURL(fileObject)
        });
    }

    /**
     * Executes the authentication with the
     * api and returns the result form the
     * authentication call
     * 
     * @param {string} provider
     * name of the provider the token belongs to
     *  
     * @param {string} token 
     * the token as string
     * 
     * @param {string} options
     * option options object to include possible params:
     * - setupTenantCode
     * - setupKey
     */
    static async authenticateUser(provider,token,options){
        let result = null;

        let authRequestObject = {
            provider: provider,
            token: token            
        };

        /* set the tenant code and setupKey for 
            the initial setup if both were provided */
        if(typeof options === 'object' && options !== null){
            /* check if initial setup credentials are attached */
            if(typeof options.setupTenantCode === 'string' && typeof options.setupKey === 'string'){
                /* attach the setup credentials */
                authRequestObject.setup = {
                    tenantCode: options.setupTenantCode,
                    setupKey: options.setupKey
                }
            }

            if(typeof options.invitationCode === 'string' && options.invitationCode !== ''){
                /* attach the invitation code for this user to sign up */
                authRequestObject.invitationCode = options.invitationCode;
            }
        }

        let response = await fetch(this.getApiEndpoint() + '/account/auth',{
            method: 'post', body: JSON.stringify(authRequestObject)
        });

        if(response != null){
            let authResponse = await response.json();

            if(authResponse.failed === false){
                result = authResponse.credentials;
            }else{
                result = { failed: true };

                if(authResponse.errorCode === 'UNASSIGNED'
                    && typeof authResponse.user === 'object'
                    && authResponse.user !== null){
                    /* return the user information */
                    result = { 
                        failed: true,
                        unassigned: true,
                        userObject: authResponse.user
                    };
                }
            }
        }

        return result;
    }
}

export default ApiClient;