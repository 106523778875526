/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import EditorSection from '../common/EditorSection';
import DataInputList from '../common/DataInputList';
import DataInputField from '../common/DataInputField';
import StandardButton from '../common/StandardButton';
import DataInputCheckBox from '../common/DataInputCheckBox';
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import SubNavigationList from '../common/SubNavigationList';

import '../../style/PaymentSetup.css';
import HelpArticleView from '../account/HelpArticleView';

/* require the list of available countries for payment services */
const PaymentCountryList = require('../../config/payment/PaymentCountryList.json');
const USLegalFormList = require('../../config/payment/USLegalFormList.json');

/**
 * This component allows the user to configure and sign up
 * for the payment services to accept card payments and other
 * forms of payment
 */
class PaymentSetup extends React.Component { 
    state = {
        firstName: '',
        lastName: '',
        companyName: '',
        companyLegalForm: 'private_corporation',
        companyCountry: 'us',
        businessType: 'company',
        companyAddressLine: '',
        companyPostalCode: '',
        companyCity: '',
        companyState: '',
        companyPhone: '',
        companyUrl: '',
        termsChecked: false
    }

    /**
     * Set defaults based on environment settings
     * when this component is first mounted
     */
    componentDidMount(){
        /* set the default country to germany when the
            language of the user interface is german */
        if(Culture.getCultureCode().toLowerCase() === 'de'){
            this.setState({companyCountry: 'de'});
        }
    }

    /**
     * Returns the select list with the
     * available countries for payment services
     */
    getAvailableCountryList(){
        let result = [];

        for(const countryCode of PaymentCountryList){
            result.push({
                value: countryCode,
                text: Culture.getText('COUNTRY_'+countryCode.toUpperCase())
            });
        }

        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        return result;
    }

    /**
     * Returns true when the setup form is
     * completed and otherwise false if it
     * is not yet completed
     */
    isFormCompleted(){
        let result = true;

        let fieldList = ['companyName', 'companyLegalForm','companyCountry',
                        'businessType','companyAddressLine','companyPostalCode',
                        'companyCity','companyPhone','companyUrl'];
        if(this.state.businessType === 'individual'){
            fieldList = ['firstName', 'lastName', 'companyCountry',
                    'businessType','companyAddressLine','companyPostalCode',
                    'companyCity','companyPhone','companyUrl'];
        }

        for(const field of fieldList){
            if(typeof this.state[field] !== 'string' || this.state[field] === ''){
                result = false;
            }
        }

        if(this.state.termsChecked !== true){
            result = false;
        }

        return result;
    }

    /**
     * Renders the setup component with
     * the required input fields
     */
    render(){
        const providerCode = this.props.providerCode || 'stripe';
        return(
            <>
                <ContentHeaderMenu title={Culture.getText('PAYMENT_SETUP_TITLE_' + providerCode.toUpperCase().replace(/-/gmi,'_'))}
                    subtitle={Culture.getText('PAYMENT_SETUP_SUBTITLE_' + providerCode.toUpperCase().replace(/-/gmi,'_'))}
                    backLinkText={Culture.getText('PAYMENT_ACCOUNT_BACK')}
                    onBackLinkClick={this.props.onClose} />

                <div className="PaymentSetup">
                    <div className="PaymentSetupForm">
                        <SubNavigationList>
                            <section code="setup" name={Culture.getText('PAYMENT_SETUP_MENUTITLE')}>
                                <EditorSection title={Culture.getText('PAYMENT_SETUP_REGION_TITLE')}
                                    subtitle={Culture.getText('PAYMENT_SETUP_REGION_SUBTITLE')}
                                    helpText={
                                        <>
                                            <div>
                                                {Culture.getText('PAYMENT_SETUP_REGION_HELPTEXT')}
                                            </div>
                                            {providerCode === 'stripe' &&
                                                <>
                                                    <br />
                                                    <HelpArticleView article="payment_setup" />    
                                                </>
                                            }
                                        </>
                                    }
                                    introText={Culture.getText('PAYMENT_SETUP_INTROTEXT_' + providerCode.toUpperCase().replace(/-/gmi,'_'))}>
                                    {this.props.showCreateError === true &&
                                        <div className="PaymentSetupErrorMessage">
                                            {Culture.getText('PAYMENT_SETUP_FAILED')}
                                        </div>
                                    }

                                    <DataInputList title={Culture.getText('PAYMENT_SETUP_COMPANYCOUNTRY')} 
                                        value={this.state.companyCountry}
                                        list={this.getAvailableCountryList()} 
                                        onChange={(value) => {
                                            if(value.toUpperCase() === 'US'){
                                                this.setState({
                                                    companyCountry: value, 
                                                    companyState: 'AL',
                                                    businessType: 'company'
                                                });
                                            }else{
                                                this.setState({
                                                    companyCountry: value, 
                                                    companyState: '',
                                                    businessType: 'company'
                                                });
                                            }
                                        }} />

                                    <DataInputList title={Culture.getText('PAYMENT_SETUP_BUSINESSTYPE')} 
                                        value={this.state.businessType}
                                        list={(function(){
                                            let result = [
                                                {value: 'company', text: Culture.getText('PAYMENT_SETUP_BUSINESSTYPE_COMPANY')},
                                                {value: 'non_profit', text: Culture.getText('PAYMENT_SETUP_BUSINESSTYPE_NONPROFIT')}
                                            ];

                                            if(this.state.companyCountry.toUpperCase() === 'US'){
                                                result.push({
                                                    value: 'individual', 
                                                    text: Culture.getText('PAYMENT_SETUP_BUSINESSTYPE_INDIVIDUAL')
                                                });
                                            }

                                            return result;
                                        }).bind(this)()} 
                                        onChange={(value) => {this.setState({businessType: value});}} />

                                    {this.state.companyCountry.toUpperCase() === 'US' &&
                                        <div className="ContentWarningMessage">
                                            <div className="ContentWarningMessageTitle">
                                                {Culture.getText('PAYMENT_SETUP_USINFO_TITLE')}
                                            </div>
                                            <div className="ContentWarningMessageText">
                                                {Culture.getText('PAYMENT_SETUP_USINFO_TEXT')}
                                            </div>
                                        </div>
                                    }

                                    {this.state.businessType === 'individual' &&
                                        <div className="ContentWarningMessage">
                                            <div className="ContentWarningMessageTitle">
                                                {Culture.getText('PAYMENT_SETUP_BUSINESSTYPE_INDIVIDUAL_INFOTITLE')}
                                            </div>
                                            <div className="ContentWarningMessageText">
                                                {Culture.getText('PAYMENT_SETUP_BUSINESSTYPE_INDIVIDUAL_INFOTEXT')}
                                            </div>
                                        </div>
                                    }

                                    {this.state.companyCountry.toUpperCase() === 'US' 
                                        && this.state.businessType !== 'individual' &&
                                        <DataInputList title={Culture.getText('PAYMENT_SETUP_COMPANYLEGALFORM')} 
                                            value={this.state.companyLegalForm}
                                            list={(function(){
                                                let result = [];

                                                for(const legalForm of USLegalFormList[this.state.businessType]){
                                                    result.push({
                                                        value: legalForm,
                                                        text: Culture.getText('PAYMENT_COMPANY_LEGALFORM_'+legalForm.toUpperCase())
                                                    });
                                                }

                                                result.sort(function (a, b) {
                                                    return a.text.localeCompare(b.text);
                                                });

                                                return result;
                                            }).bind(this)()} 
                                            onChange={(value) => {this.setState({companyLegalForm: value});}} />
                                    }
                                </EditorSection>

                                <EditorSection title={Culture.getText('PAYMENT_SETUP_COMPANYINFO_TITLE')}
                                    subtitle={Culture.getText('PAYMENT_SETUP_COMPANYINFO_SUBTITLE')}
                                    helpText={Culture.getText('PAYMENT_SETUP_COMPANYINFO_HELPTEXT')}>
                                    {this.state.businessType !== 'individual' &&
                                        <>
                                            {this.state.companyCountry.toUpperCase() === 'US' &&
                                                <div className="ContentWarningMessage">
                                                    <div className="ContentWarningMessageTitle">
                                                        {Culture.getText('PAYMENT_SETUP_USCOMPANY_TITLE')}
                                                    </div>
                                                    <div className="ContentWarningMessageText">
                                                        {Culture.getText('PAYMENT_SETUP_USCOMPANY_TEXT')}
                                                    </div>
                                                </div>
                                            }

                                            <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYNAME')}
                                                value={this.state.companyName} onChange={(value) => {
                                                    this.setState({companyName: value});
                                                }} />
                                        </>
                                    }

                                    {this.state.businessType === 'individual' &&
                                        <>
                                            <DataInputField title={Culture.getText('PAYMENT_PERSON_FIRSTNAME')}
                                                value={this.state.firstName} onChange={(value) => {
                                                    this.setState({firstName: value});
                                                }} />

                                            <DataInputField title={Culture.getText('PAYMENT_PERSON_LASTNAME')}
                                                value={this.state.lastName} onChange={(value) => {
                                                    this.setState({lastName: value});
                                                }} />
                                        </>
                                    }

                                    <DataInputField title={(function(){
                                            let result = Culture.getText('PAYMENT_SETUP_COMPANYADDRESSLINE');

                                            if(this.state.businessType === 'individual'){
                                                result = Culture.getText('PAYMENT_PERSON_ADDRESSLINE');
                                            }

                                            return result;
                                        }).bind(this)()}
                                        value={this.state.companyAddressLine} onChange={(value) => {
                                            this.setState({companyAddressLine: value});
                                        }} />

                                    <DataInputField title={(function(){
                                            let result = Culture.getText('PAYMENT_SETUP_COMPANYPOSTALCODE');

                                            if(this.state.businessType === 'individual'){
                                                result = Culture.getText('PAYMENT_PERSON_POSTALCODE');
                                            }

                                            return result;
                                        }).bind(this)()}
                                        value={this.state.companyPostalCode} onChange={(value) => {
                                            this.setState({companyPostalCode: value});
                                        }} />

                                    <DataInputField title={(function(){
                                            let result = Culture.getText('PAYMENT_SETUP_COMPANYCITY');

                                            if(this.state.businessType === 'individual'){
                                                result = Culture.getText('PAYMENT_PERSON_CITY');
                                            }

                                            return result;
                                        }).bind(this)()}
                                        value={this.state.companyCity} onChange={(value) => {
                                            this.setState({companyCity: value});
                                        }} />

                                    {this.state.companyCountry.toUpperCase() === 'US' &&
                                        <DataInputList title={(function(){
                                                let result = Culture.getText('PAYMENT_SETUP_COMPANYSTATE');

                                                if(this.state.businessType === 'individual'){
                                                    result = Culture.getText('PAYMENT_PERSON_STATE');
                                                }

                                                return result;
                                            }).bind(this)()}
                                            value={this.state.companyState}
                                            list={(function(){
                                                let result = [];

                                                let stateList = Culture.getUSStateList();
                                                for(const stateInfo of stateList){
                                                    result.push({
                                                        value: stateInfo.code,
                                                        text: stateInfo.name
                                                    });
                                                }

                                                result.sort(function (a, b) {
                                                    return a.text.localeCompare(b.text);
                                                });

                                                return result;
                                            })()} 
                                            onChange={(value) => {this.setState({companyState: value});}} />
                                    }
                                </EditorSection>

                                <EditorSection title={Culture.getText('PAYMENT_SETUP_COMPANYCONTACT_TITLE')}
                                    subtitle={Culture.getText('PAYMENT_SETUP_COMPANYCONTACT_SUBTITLE')}
                                    helpText={Culture.getText('PAYMENT_SETUP_COMPANYCONTACT_HELPTEXT')}
                                    introText={Culture.getText('PAYMENT_SETUP_COMPANYCONTACT_INTROTEXT')}>
                                    <DataInputField title={(function(){
                                            let result = Culture.getText('PAYMENT_SETUP_COMPANYPHONE');

                                            if(this.state.businessType === 'individual'){
                                                result = Culture.getText('PAYMENT_PERSON_PHONE');
                                            }

                                            return result;
                                        }).bind(this)()}
                                        value={this.state.companyPhone} onChange={(value) => {
                                            this.setState({companyPhone: value.replace(/[^0-9-\\+]/gmi,'')});
                                        }} />

                                    <DataInputField title={Culture.getText('PAYMENT_SETUP_COMPANYURL')}
                                        value={this.state.companyUrl} onChange={(value) => {
                                            this.setState({companyUrl: value});
                                        }} />
                                </EditorSection>

                                <EditorSection title={Culture.getText('PAYMENT_SETUP_INFO_TITLE')}
                                    subtitle={Culture.getText('PAYMENT_SETUP_INFO_SUBTITLE')}
                                    helpText={
                                        <>
                                            {providerCode === 'stripe' &&
                                                <>
                                                    <div>
                                                        {Culture.getText('PAYMENT_SETUP_INFO_TEXT')}
                                                    </div>
                                                    <br />
                                                    <HelpArticleView article="payment_charges" />
                                                </>
                                            }

                                            {providerCode === 'stripe-market' && Culture.getText('PAYMENT_SETUP_INFO_TEXT_STRIPE_MARKET')}
                                        </>
                                    }
                                    introText={Culture.getText('PAYMENT_SETUP_TERMSTEXT')}>

                                    <DataInputCheckBox checked={this.state.termsChecked}  
                                        title={Culture.getText('PAYMENT_SETUP_TERMSTITLE')} 
                                        onClick={() => { this.setState({termsChecked: !this.state.termsChecked}); }} 
                                    />

                                    <ul className="PaymentSetupTermsDocumentList">
                                        <li>
                                            <a href={Culture.getText('PAYMENT_SETUP_TERMSPAYMENT_URL')} 
                                                target="_blank" rel="noopener noreferrer">
                                                {Culture.getText('PAYMENT_SETUP_TERMSPAYMENT_TITLE')}
                                            </a>
                                        </li>
                                        <li>
                                            <a href={('https://stripe.com/' + this.state.companyCountry.toUpperCase() + '/connect-account/legal/full')} 
                                                target="_blank" rel="noopener noreferrer">
                                                {Culture.getText('PAYMENT_SETUP_TERMS_LEGALFULL')}
                                            </a>
                                        </li>
                                        <li>
                                            <a href={('https://stripe.com/' + this.state.companyCountry.toUpperCase() + '/ssa')} 
                                                target="_blank" rel="noopener noreferrer">
                                                {Culture.getText('PAYMENT_SETUP_TERMS_SSA')}
                                            </a>
                                        </li>
                                    </ul>

                                    <div>
                                        <StandardButton className="PaymentSetupCreateAccountButton"
                                            disabled={!this.isFormCompleted()}
                                            text={Culture.getText('PAYMENT_SETUP_CREATEACCOUNT')} 
                                            onClick={() => {
                                                if(this.isFormCompleted() === true){
                                                    if(typeof this.props.onCreate === 'function'){
                                                        this.props.onCreate(Object.assign({},this.state));
                                                    }
                                                }
                                            }} />
                                    </div>
                                </EditorSection>
                            </section>
                        </SubNavigationList>
                    </div>
                </div>
            </>
        );
    }
}

export default PaymentSetup;