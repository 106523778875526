/* (C) 2020 Jan Kammerath
* All Rights Reserved.
* 
* All information contained herein is, and remains
* the property of Jan Kammerath and its suppliers,
* if any.  The intellectual and technical concepts 
* contained herein are proprietary to Jan Kammerath
* and its suppliers and may be covered by EU 
* and other Foreign Patents, patents in process, and 
* are protected by trade secret or copyright law.
* Dissemination of this information or reproduction 
* of this material is strictly forbidden unless prior 
* written permission is obtained from Jan Kammerath.
*/

import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import LoadingIndicator from '../common/LoadingIndicator';
import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';
import SubNavigationList from '../common/SubNavigationList';
import EditorSection from '../common/EditorSection';

/**
 * This component renders and handles the table to
 * view and management the properties in the system
 */
class InventoryTable extends React.Component {
    state = {
        propertyList: [],
        nextPageToken: '',
        searchKeyword: '',
        isKeywordSearch: false,
        isKeywordSearchLoading: false,
        searchId: 0,
        isLoading: true,
        showCreateWindow: false,
        showOptionWindow: false,
        showDeleteWindow: false,
        showCopyWindow: false,
        propertyCreateFailed: false,
        /* state values for the property creation */
        newPropertyCodeCustom: false,
        newPropertyCode: "",
        newPropertyCodeDefined: false,
        newPropertyCodeErrorText: "",
        newPropertyName: "",
        newPropertyNameErrorText: "",
        /* state values for the property copy */
        copyPropertyCode: "",
        copyPropertyName: "",
        /* state values for the property options */
        optionPropertyCode: "",
        optionPropertyImageUrl: "",
        optionPropertyName: "",
        optionPropertyAddressText: ""
    }
        
    /**
     * Mounts the component and initially loads
     * the property list from the api
     */
    componentDidMount(){
        /* fetch the property list */
        this.fetchPropertyList(false);

        /* Attach the handler for culture changes
            as this component uses localised text */
        Culture.attachCultureComponent(this);
    }

    /**
     * Fetches the property list from the api
     * and sets the state so that it gets rendered
     * 
     * @param {boolean} usePageToken
     * if false, does not use the page token
     */
    async fetchPropertyList(usePageToken){
        /* set the search id to avoid a race condition */
        let querySearchId = Date.now();
        this.setState({
            searchId: querySearchId,
            isKeywordSearchLoading: true
        });

        let requestObject = { keyword: this.state.searchKeyword };
        if(usePageToken !== false){
            requestObject.pageToken = this.state.nextPageToken;
        }

        let list = [];
        let requestMore = true;
        let nextPageToken = '';
        while(requestMore === true){
            requestMore = false;

            let apiResult = await ApiClient.execute('/inventory/property/list',requestObject);
            if(apiResult !== null){
                if(Array.isArray(apiResult.list)){
                    list = list.concat(apiResult.list);

                    /* query as many pages as required to have at least
                        10 results or no additional result pages available */
                    if(typeof apiResult.pageToken === 'string' 
                        && apiResult.pageToken !== ''){
                        if(list.length < 10){
                            /* indicate to have more and update the page token */
                            requestObject.pageToken = apiResult.pageToken;
                            requestMore = true;
                        }

                        nextPageToken = apiResult.pageToken;
                    }else{
                        nextPageToken = '';
                    }

                    if(this.state.searchId !== querySearchId){
                        /* abandon any subsequent queries as a new
                            search has already been initiated */
                        requestMore = false;
                    }
                }
            }
        }

        /* only update when this query is still the active search */
        if(this.state.searchId === querySearchId){
            /* append the result to the existing list if
                this is a paginated result */
            let resultList = this.state.propertyList;
            if(usePageToken !== false){
                resultList = resultList.concat(list);
            }else{
                resultList = list;
            }

            this.setState({
                propertyList: resultList, 
                nextPageToken: nextPageToken,
                isLoading: false,
                isKeywordSearchLoading: false
            });
        }
    }

    /**
     * Selects a property and notifies the parent
     * component to handle the selection of the property
     * 
     * @param {string} propertyId
     * id of the property to select 
     */
    selectProperty(propertyId){
        if(typeof this.props.onSelectProperty === 'function'){
            this.props.onSelectProperty(propertyId);
        }
    }

    /**
     * Deletes the property currently
     * set for the options in the state
     */
    async deleteProperty(){
        this.setState({isLoading: true, showOptionWindow: false, showDeleteWindow: false});

        /* try to permanently delete the property */
        let deleteResult = await ApiClient.execute('/inventory/property/delete',{
            code: this.state.optionPropertyCode
        });

        if(deleteResult.deleted === true){
            this.setState({searchKeyword: ''},
                this.fetchPropertyList.bind(this,false));
        }

        this.setState({isLoading: false});
    }

    /**
     * Requests copying the property to the new
     * property and reloads the property list
     */
    async copyProperty(){
        this.setState({isLoading: true, showOptionWindow: false, showCopyWindow: false});

        /* try to permanently delete the property */
        let copyResult = await ApiClient.execute('/inventory/property/copy',{
            propertyCode: this.state.optionPropertyCode,
            targetPropertyCode: this.state.copyPropertyCode,
            targetPropertyName: this.state.copyPropertyName
        });

        if(copyResult.copied === true){
            this.setState({searchKeyword: ''},this.fetchPropertyList.bind(this,false));
        }

        this.setState({isLoading: false});
    }

    /**
     * Adds a new property for this tenant or
     * shows an error message when the data is
     * insufficient
     */
    async createNewProperty(){
        /* check if both mandatory fields are inserted */
        if(this.state.newPropertyCode === ""){
            this.setState({newPropertyCodeErrorText: Culture.getText('PROPERTY_ID_REQUIRED')});
        }if(this.state.newPropertyName === ""){
            this.setState({newPropertyNameErrorText: Culture.getText('PROPERTY_NAME_REQUIRED')});
        }

        /* create the property when all required fields were inserted */
        if(this.state.newPropertyCode !== "" && this.state.newPropertyName !== ""){
            this.setState({isLoading: true});

            let insertResult = await ApiClient.execute('/inventory/property/create',{
                code: this.state.newPropertyCode, name: this.state.newPropertyName 
            });

            /* once the property is created, transfer the user */
            if(insertResult.failed === false){
                /* get the property code to direct the user to the new property */
                let createdPropertyCode = this.state.newPropertyCode;

                /* reset the values from the state */
                this.setState({
                    showCreateWindow: false,
                    newPropertyCode: "",
                    newPropertyCodeErrorText: "",
                    newPropertyName: "",
                    newPropertyNameErrorText: "",
                    nextPageToken: ''
                },this.selectProperty.bind(this,createdPropertyCode));
            }else{
                /* show the error when it failed */
                this.setState({
                    isLoading: false,
                    showCreateWindow: true,
                    propertyCreateFailed: true,
                    newPropertyCodeErrorText: "",
                    nextPageToken: '',
                    newPropertyNameErrorText: Culture.getText('CREATE_PROPERTY_FAILED')
                });
            }
        }
    }

    /**
     * Renders the create window to create
     * a new property for the current tenant
     */
    renderCreateWindow(){
        return (
            <ModalDrawerOverlay titleText={Culture.getText('CREATE_PROPERTY')}
                introText={Culture.getText('CREATE_PROPERTY_INTRO')}
                subtitleText={Culture.getText('CREATE_PROPERTY_TEXT')}
                onClose={() => this.setState({showCreateWindow: false})}
                submitButtonText={Culture.getText('CREATE_PROPERTY')}
                submitDisabled={(
                    this.state.newPropertyCode === ''
                    || this.state.newPropertyName === ''
                )}
                onSubmit={this.createNewProperty.bind(this)}>

                <DataInputField title={Culture.getText('DATAFIELD_PROPERTYNAME')} 
                    value={this.state.newPropertyName} 
                    errorText={Culture.getText(this.state.newPropertyNameErrorText)}
                    onChange={(function(value){
                        /* create a property id when none is defined yet */
                        let propertyCode = this.state.newPropertyCode;
                        if(this.state.newPropertyCodeDefined === false){
                            propertyCode = value.toLowerCase().replace(new RegExp("[^a-zA-Z0-9 \\-\\_\\.]",'gmi'),'').replace(/ /gmi,'-');
                        }

                        /* update the property name */
                        this.setState({
                            newPropertyName: value,
                            newPropertyCode: propertyCode
                        });
                    }).bind(this)} />

                <DataInputField title={Culture.getText('DATAFIELD_PROPERTYID')}
                    disabled={(this.state.newPropertyCodeCustom !== true)} 
                    value={this.state.newPropertyCode} 
                    errorText={Culture.getText(this.state.newPropertyCodeErrorText)}
                    onChange={(value) => {
                        let cleanedValue = value.replace(new RegExp("[^a-zA-Z0-9 \\-\\_\\.]",'gmi'),'');

                        let codeDefined = true;
                        if(cleanedValue === ''){
                            codeDefined = false;
                        }

                        this.setState({
                            newPropertyCode: cleanedValue,
                            newPropertyCodeDefined: codeDefined
                        });
                    }} />

                <DataInputCheckBox title={Culture.getText('PROPERTYID_CUSTOM_OPTION')} 
                        checked={this.state.newPropertyCodeCustom} onClick={() => {
                        this.setState({newPropertyCodeCustom: !this.state.newPropertyCodeCustom});
                    }} />
            </ModalDrawerOverlay>
        );
    }

    /**
     * Renders the option window for
     * the currently selected property
     */
    renderOptionWindow(){
        return (
            <ModalDrawerOverlay 
                titleText={this.state.optionPropertyName}
                subtitleText={this.state.optionPropertyAddressText}
                introText={Culture.getText('PROPERTY_OPTION_TEXT')}
                onClose={() => this.setState({
                showOptionWindow: false,
                showDeleteWindow: false,
                showCopyWindow: false
            })}>
                <ul className="InventoryListPropertyOptionList">
                    <li data-action="edit" onClick={this.selectProperty.bind(this,this.state.optionPropertyCode)}>
                        {Culture.getText('EDIT_PROPERTY')}
                    </li>
                    <li data-action="copy" onClick={() => this.setState({
                        showCopyWindow: true,
                        copyPropertyCode: [this.state.optionPropertyCode,(Math.floor(Math.random()*90000)+10000)].join('-'),
                        copyPropertyName: this.state.optionPropertyName
                    })}>
                        {Culture.getText('COPY_PROPERTY')}
                    </li>
                    <li data-action="delete" onClick={() => this.setState({showDeleteWindow: true})}>
                        {Culture.getText('DELETE_PROPERTY')}
                    </li>
                </ul>
            </ModalDrawerOverlay>
        );
    }

    /**
     * Renders the property copy window that allows
     * copying the property into another property
     */
    renderCopyWindow(){
        return(
            <ModalDrawerOverlay className="CopyPropertyWindow"
                transparentBackground={true}
                onClose={() => this.setState({showCopyWindow: false})}
                titleText={Culture.getText('COPY_PROPERTY')}
                subtitleText={this.state.optionPropertyName}
                introText={Culture.getText('COPY_PROPERTY_TEXT')}
                submitButtonText={Culture.getText('COPY_PROPERTY_BUTTON')}
                onSubmit={this.copyProperty.bind(this)}
                submitDisabled={(this.state.copyPropertyCode === '' 
                            || this.state.copyPropertyName === '')}>
                <DataInputField required={true}
                    title={Culture.getText('DATAFIELD_COPYPROPERTYNAME')} 
                    value={this.state.copyPropertyName} 
                    onChange={(function(value){
                        this.setState({copyPropertyName: value});
                    }).bind(this)} />

                <DataInputField required={true}
                    title={Culture.getText('DATAFIELD_PROPERTYID')}
                    value={this.state.copyPropertyCode} 
                    onChange={(value) => {
                        let cleanedValue = value.replace(new RegExp("[^a-zA-Z0-9 \\-\\_\\.]",'gmi'),'');
                        this.setState({copyPropertyCode: cleanedValue});
                    }} />
            </ModalDrawerOverlay>
        );
    }

    /**
     * Renders the window to confirm the deletion
     * of the currently selected property
     */
    renderDeleteWindow(){
        return (
            <ModalDrawerOverlay className="DeletePropertyWindow"
                transparentBackground={true}
                onClose={() => this.setState({showDeleteWindow: false})}
                titleText={Culture.getText('DELETE_PROPERTY')}
                subtitleText={this.state.optionPropertyName}
                introText={Culture.getText('DELETE_PROPERTY_TEXT')}
                submitButtonText={Culture.getText('DELETE_PROPERTY_PERMANENTLY')}
                onSubmit={this.deleteProperty.bind(this)}>
            </ModalDrawerOverlay>
        );
    }

    /**
     * Renders the table with properties stored
     * in the local state
     */
    renderTable(){
        let result = [];

        this.state.propertyList.forEach((propertyItem) => {
            /* check if an address text is available and
                set the address text, if not set a placeholder */
            let addressText = "—";
            if(typeof propertyItem.address === 'string' && propertyItem.address !== ""){
                addressText = propertyItem.address;
            }

            /* determine the background image url for
                the main image of this property */
            let backgroundImageUrl = '';
            if(propertyItem.image !== ''){
                backgroundImageUrl = ApiClient.getMediaContentUri(propertyItem.propertyId,propertyItem.image);
            }

            result.push(
                <div key={propertyItem.propertyId} className="InventoryTablePropertyItem" >
                    <div className="InventoryTablePropertyItemImageColumn"
                        onClick={this.selectProperty.bind(this,propertyItem.propertyId)}>
                        {backgroundImageUrl !== '' &&
                            <div className="InventoryTablePropertyItemImage" 
                                style={{backgroundImage:'url("' + backgroundImageUrl + '")'}}></div>
                        }
                        {backgroundImageUrl === '' &&
                            <div className="InventoryTablePropertyItemImage InventoryTablePropertyItemNoImage"></div>
                        }
                    </div>
                    <div className="InventoryTablePropertyItemDetail"
                        onClick={this.selectProperty.bind(this,propertyItem.propertyId)}>
                        <div className="InventoryTablePropertyItemName">{propertyItem.name}</div>
                        <div className="InventoryTablePropertyItemAddress">{addressText}</div>
                    </div>

                    {typeof propertyItem.lastUpdated === 'number' && propertyItem.lastUpdated !== 0 &&
                        <div className="InventoryTablePropertyItemLastUpdated">
                            <div className="InventoryTablePropertyItemLastUpdatedLabel">
                                {Culture.getText('PROPERTYLIST_ITEM_LASTUPDATED')}
                            </div>
                            <div className="InventoryTablePropertyItemLastUpdatedValue">
                                {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'full',
                                    timeStyle: 'medium'
                                })).format(propertyItem.lastUpdated)}
                            </div>
                        </div>
                    }

                    <div className="InventoryTableActionMenu" 
                        onClick={() => this.setState({
                            showOptionWindow: true,
                            optionPropertyCode: propertyItem.propertyId,
                            optionPropertyName: propertyItem.name,
                            optionPropertyImageUrl: backgroundImageUrl,
                            optionPropertyAddressText: addressText
                        })}></div>
                </div>
            );
        });

        if(result.length === 0){
            result.push(
                <div key="InventoryTableListEmpty" className="InventoryTableListEmpty">
                    {Culture.getText('PROPERTYLIST_SEARCH_EMPTY')}
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the inventory list component and also
     * the loading status indicator for this component
     */
    render(){
        if(this.state.isLoading === true){
            return(
                <div className="InventoryTableLoadingIndicator">
                    <LoadingIndicator />
                </div>
            );
        }else{
            if(this.state.propertyList.length > 0 || this.state.isKeywordSearch === true){
                return(
                    <div className="InventoryTable">
                        <div className="InventoryTableContent">
                            <div className="InventoryTableHeader">
                                <div className="InventoryTableSearch">
                                    <div className="InventoryTableSearchField" 
                                        data-searchloading={this.state.isKeywordSearch === true && this.state.isKeywordSearchLoading === true}>
                                        {this.state.isKeywordSearch === true && 
                                        this.state.isKeywordSearchLoading === true &&
                                            <LoadingIndicator />
                                        }
                                        <input className="InventoryTableSearchFieldInput" 
                                            type="text" placeholder={Culture.getText('PROPERTYLIST_SEARCH_PLACEHOLDER')}
                                            onChange={(function(e){
                                                this.setState({
                                                    searchKeyword: e.target.value,
                                                    nextPageToken: '',
                                                    isKeywordSearch: true
                                                },this.fetchPropertyList.bind(this,false));
                                            }).bind(this)} 
                                        />
                                    </div>
                                </div>

                                <div className="InventoryTableActionList">
                                    <div className="InventoryTableActionCreate" onClick={() => this.setState({showCreateWindow: true})}>
                                        {Culture.getText('CREATE_PROPERTY')}
                                    </div>
                                </div>
                            </div>
                            <div className="InventoryTableList">
                                {this.renderTable()}

                                <div className="InventoryTableListNext">
                                    {this.state.nextPageToken !== '' &&
                                        <div className="InventoryTableListNextButton" 
                                            onClick={this.fetchPropertyList.bind(this)}>
                                            {Culture.getText('SHOW_PROPERTYLIST_NEXTPAGE')}
                                        </div>
                                    }
                                </div>
                            </div>
                            {this.state.showCreateWindow === true &&
                                <div>
                                    {this.renderCreateWindow()}
                                </div>
                            }
                            {this.state.showOptionWindow === true &&
                                <div>
                                    {this.renderOptionWindow()}
                                </div>
                            }
                            {this.state.showDeleteWindow === true &&
                                <div>
                                    {this.renderDeleteWindow()}
                                </div>
                            }
                            {this.state.showCopyWindow === true &&
                                <div>
                                    {this.renderCopyWindow()}
                                </div>
                            }
                        </div>
                    </div>
                ); 
            }else{
                return(
                    <div className="InventoryTable">
                        <div className="InventoryTableContent InventoryFirstPropertyContent">
                            <SubNavigationList>
                                <section code="firstproperty" name={Culture.getText('CREATE_FIRST_PROPERTY_MENUTITLE')}>
                                    <EditorSection title={Culture.getText('CREATE_FIRST_PROPERTY_TITLE')}
                                        subtitle={Culture.getText('CREATE_FIRST_PROPERTY_SUBTITLE')}
                                        helpText={Culture.getText('CREATE_FIRST_PROPERTY_HELPTEXT')}
                                        introText={Culture.getText('CREATE_FIRST_PROPERTY_TEXT')}>
                                        <div>
                                            <StandardButton className="InventoryFirstPropertyCreateButton"
                                                text={Culture.getText('CREATE_FIRST_PROPERTY_BUTTON')}
                                                onClick={() => this.setState({showCreateWindow: true})} />
                                        </div>
                                        {this.state.showCreateWindow === true &&
                                            <>
                                                {this.renderCreateWindow()}
                                            </>
                                        }
                                    </EditorSection>

                                    <EditorSection title={Culture.getText('CREATE_FIRST_PROPERTY_SYNC_TITLE')}
                                        subtitle={Culture.getText('CREATE_FIRST_PROPERTY_SYNC_SUBTITLE')}
                                        helpText={Culture.getText('CREATE_FIRST_PROPERTY_SYNC_HELPTEXT')}
                                        introText={Culture.getText('CREATE_FIRST_PROPERTY_SYNC_INTROTEXT')}>
                                        <div>
                                            <StandardButton className="InventoryFirstPropertySyncButton"
                                                text={Culture.getText('CREATE_FIRST_PROPERTY_SYNC_BUTTON')}
                                                onClick={() => this.props.onShowView('connectivity')} />
                                        </div>
                                    </EditorSection>
                                </section>
                            </SubNavigationList>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default InventoryTable;