/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';
import Property from '../../class/Property';
import ManagementUser from '../../class/ManagementUser';

/* import the required components */
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import MessageListEditor from '../inventory/MessageListEditor';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

/* defines the maxmimum number of parallel file uploads */
const MAX_PARALLEL_UPLOAD = 3;

/* defines the allowed mime types that can be uploaded */
const ALLOWED_FILETYPELIST = [
    'image/jpeg', 'image/jpg', 'image/png'
];

/* maxmimum allowed file size per media file is 5 mb */
const ALLOWED_MAXFILESIZE = 5242880;

/**
 * Component that allows to manage multi
 * media lists and also upload media
 */
class MultiMediaListInput extends React.Component {
    state = {
        fileUploadList: [],
        fileContent: {},
        showEditWindow: false,
        invalidFileSelected: false,
        editItem: null,
        restrictedItem: null,
        lastModified: 0
    }

    /**
     * Constructs this component and creates
     * the reference for the file input control
     */
    constructor(){
        super();

        /* reference to the file input is required
            so that the add button can trigger a
            click on that element and the user can
            select the files to upload */
        this.fileInputRef = React.createRef(null);
        this.addButtonRef = React.createRef(null);
    }

    /**
     * Returns true when this media list manages
     * the media content for a property. If not,
     * this method returns false
     */
    isPropertyMediaList(){
        let result = false;

        if(this.props.propertyElement === "$property"){
            result = true;
        }

        return result;
    }

    /**
     * Notifies the parent component about
     * updates to the list as files might
     * have been uploaded or removed
     */
    notifyListUpdate(list){
        if(typeof this.props.onChange === 'function'){
            /* set the first image as the main image */
            for(let i=0; i<list.length; i++){
                list[i].sortOrder = i;
                list[i].isMainImage = false;
                if(i === 0){ list[i].isMainImage = true; }
            }

            this.props.onChange(list);
        }
    }

    /**
     * Joins the provided media list with
     * the files recently uploaded
     */
    joinMediaList(){
        let result = [];

        if(Array.isArray(this.props.mediaList)){
            result = result.concat(this.props.mediaList);
        }

        for(let f=0; f<this.state.fileUploadList.length; f++){
            let fileItem = this.state.fileUploadList[f];
            result.push({
                isMainImage: false,
                sortOrder: 99999,
                url: this.getCleanFileName(fileItem.name),
                tagList: []
            });
        }

        /* define the sort order values */
        for(let s=0; s<result.length; s++){
            result[s].sortOrder = (s + 1000);
        }

        return result;
    }

    /**
     * Checks if the media file already exists
     * in the list or not. If it does, this will
     * return true and otherwise false
     * 
     * @param {string} fileName
     * file name of the media file 
     */
    mediaFileExists(fileName){
        let result = false;

        if(Array.isArray(this.props.mediaList)){
            this.props.mediaList.forEach((mediaItem) => {
                if(mediaItem.url === fileName){
                    result = true;
                }
            });
        }

        return result;
    }

    /**
     * Returns the media item for the file name
     * provided in the parameter or null if such
     * media file with that name does not exist
     * 
     * @param {string} fileName
     * file name of the media file 
     */
    getMediaFileByFileName(fileName){
        let result = null;

        if(Array.isArray(this.props.mediaList)){
            this.props.mediaList.forEach((mediaItem) => {
                if(mediaItem.url === fileName){
                    result = mediaItem;
                }
            });
        }

        return result;
    }

    /**
     * Returns the cleaned file name for
     * the file name provided in the param
     * 
     * @param {string} fileName 
     * the file name to return the clean name of
     */
    getCleanFileName(fileName){
        let result = fileName.toLowerCase().replace(/[^a-z0-9\-_\\.]/gmi,'');

        /* replace any JPEG extension with a jpg-extension */
        if(result.endsWith('.jpeg')){
            result = (result.substring(0,result.lastIndexOf('.'))+'.jpg');
        }

        return result;
    }

    /**
     * Performs the upload operation for the
     * media files provided in the params
     * 
     * @param {array} fileList
     * the list of files to upload as an array 
     */
    async uploadFileList(fileList){
        let uploadList = [];
        let selectedInvalidFile = false;
        for(let i=0; i<fileList.length; i++){
            let fileItem = fileList[i];
            if(ALLOWED_FILETYPELIST.includes(fileItem.type.toLowerCase()) === true
                && fileItem.size < ALLOWED_MAXFILESIZE 
                && this.mediaFileExists(fileItem.name) === false){
                /* add the file to the list */
                uploadList.push(fileItem);
            }else{
                selectedInvalidFile = true;
            }
        }

        /* update the state when the files included an invalid file */
        this.setState({invalidFileSelected: selectedInvalidFile});

        /* update the state with the pending files */
        this.setState({fileUploadList: uploadList});

        if(uploadList.length > 0){
            /* get the image contents of all selected files */
            let imageList = {};
            for(let f=0; f<uploadList.length; f++){
                imageList[uploadList[f].name] = await ApiClient.getFileContentDataURL(uploadList[f]);
            }
            this.setState({fileContent: imageList});

            /* process all the files provided */
            let promiseList = [];
            for(let u=0; u<uploadList.length; u++){
                promiseList.push(ApiClient.uploadMediaFile(
                    this.props.property.code, 
                    this.getCleanFileName(uploadList[u].name),
                    uploadList[u]
                ));

                /* execute all uploads when they hit the 
                    maxmimum number of parallel uploads */
                if(promiseList.length === MAX_PARALLEL_UPLOAD){
                    await Promise.all(promiseList);
                    promiseList = []; 
                }
            }
            
            /* wait for all file uploads to complete */
            await Promise.all(promiseList);

            /* notify about the uploaded files */
            this.notifyListUpdate(this.joinMediaList());
            this.setState({
                fileUploadList: [], 
                fileContent: {}, 
                lastModified: parseInt((new Date()).getTime()/1000)
            });
        }
    }

    /**
     * Handles content drop operations
     * on this component which allows
     * the user to drag and drop the files
     * she wants to upload
     * 
     * @param {number} targetIndex
     * target index of dropped onto any media 
     * item for sorting. If dropped anywhere,
     * just pass in 0
     * 
     * @param {object} event
     * the drop event raised 
     */
    handleMediaContentDrop(targetIndex,event){
        if(event.dataTransfer !== null){
            event.preventDefault();

            if(event.dataTransfer.types.includes('Files')){
                /* this is a file upload drop */
                let fileList = event.dataTransfer.files;
                this.uploadFileList(fileList);
            }

            if(event.dataTransfer.types.includes('text/plain') === true
                && typeof targetIndex === 'number'){
                /* this is the drop of a media item to sort it */
                let plainTextData = event.dataTransfer.getData('text/plain');
                try{
                    let draggedData = JSON.parse(plainTextData);
                    let draggedFileName = draggedData.fileName;
                    let draggedFile = this.getMediaFileByFileName(draggedFileName);
                    if((this.mediaFileExists(draggedData.fileName) === false 
                        && draggedData.propertyElement !== this.props.propertyElement)
                        || draggedData.propertyElement === this.props.propertyElement){
                        /* check if the media element is not from this element */
                        if(draggedData.propertyElement !== this.props.propertyElement){
                            /* this file was dragged from another media
                                element, so request the parent component
                                to transfer the media into this list */
                            if(typeof this.props.getForeignMedia === 'function'){
                                /* only request the transfer when the file does
                                    not already exist in this list */
                                if(!this.mediaFileExists(draggedData.fileName)){
                                    draggedFile = this.props.getForeignMedia(draggedData.fileName);
                                }
                            }
                        }
        
                        if(draggedFile !== null){
                            let sortedList = [];
                            for(let m=0; m<this.props.mediaList.length; m++){
                                let currentMediaFile = this.props.mediaList[m];
                                if(m === targetIndex){ sortedList.push(draggedFile); }
                                if(currentMediaFile.url !== draggedFileName){
                                    sortedList.push(currentMediaFile);
                                }
                            }
            
                            /* append the file when it was dragged to the very end */
                            if(targetIndex >= this.props.mediaList.length){
                                sortedList.push(draggedFile);
                            }
            
                            /* notify about the changed list */
                            this.notifyListUpdate(sortedList); 
                        }  
                    }
                }catch(ex){
                    console.log('Failed to handle dropped file: ' + ex);
                }
            }
        }
    }

    /**
     * Handles the start of the media element
     * drag and sets the data for the drag 
     * operation so that the targets where the
     * element is dropped can identify the content
     * 
     * @param {string} fileName
     * the file name of the media to be dragged
     *  
     * @param {object} event 
     * the event object of the drag start event
     */
    handleMediaContentDragStart(fileName,event){
        event.dataTransfer.setData("text/plain",JSON.stringify({
            fileName: fileName, propertyElement: this.props.propertyElement
        }));
        event.dataTransfer.effectAllowed = "copy";
    }

    /**
     * Handles content selection operations
     * through the open file window and
     * initiates the upload of these files
     */
    handleMediaContentSelect(){
        if(this.fileInputRef.current !== null){
            let fileList = this.fileInputRef.current.files;
            this.uploadFileList(fileList);
        }
    }

    /**
     * Shows the file open dialog by triggering
     * the click event on the file input which
     * is referenced by the ref created in the
     * constructor
     */
    showOpenFileDialog(){
        if(this.fileInputRef.current !== null){
            this.fileInputRef.current.click();
        }
    }

    /**
     * Removes the media item from the media
     * list and updates the parent component
     * 
     * @param {string} fileName
     * the file name of the media item to remove 
     */
    removeMediaItem(fileName){
        if(Array.isArray(this.props.mediaList)){
            let upatedList = [];
            this.props.mediaList.forEach((mediaItem) => {
                if(mediaItem.url !== fileName){
                    upatedList.push(mediaItem);
                }
            });
            this.notifyListUpdate(upatedList);
        }
    }

    /**
     * Handles the drag over the media elements
     * for sorting the media and displaying the
     * position where the media is dropped
     * 
     * @param {object} imageRef
     * ref to the image that is dragged over
     *  
     * @param {boolean} isDragOver
     * true when dragged over, otherwise false 
     */
    handleMediaSortDragOver(imageRef,isDragOver){
        if(imageRef.current !== null){
            let dragOverClassName = "MultiMediaItemDragOver";
            if(isDragOver === true){
                imageRef.current.classList.add(dragOverClassName);
            }else{
                imageRef.current.classList.remove(dragOverClassName);
            }
        }
    }

    /**
     * Resets the drag cursor when the drag
     * operation was finished by the user
     */
    resetDragCursor(){
        let dragOverClassName = "MultiMediaItemDragOver";
        let dragElementList = window.document.getElementsByClassName(dragOverClassName);
        for(let e=0; e<dragElementList.length; e++){
            dragElementList[e].classList.remove(dragOverClassName);
        }
    }

    /**
     * Renders the provided media list
     * of the files that are already 
     * present
     */
    renderMediaList(){
        let result = [];

        if(Array.isArray(this.props.mediaList)){
            let mediaIndex = 0;
            this.props.mediaList.forEach((mediaItem) => {
                /* get the full url of the media file to display the image */
                let imageUrl = ApiClient.getMediaContentUri(this.props.property.code,
                                            mediaItem.url,this.state.lastModified);
                let imageRef = React.createRef();

                /* check if there are moderation labels attached to this media
                    item, restrict the editing capabilities and show the 
                    reason for the moderation of the media item */
                let mediaIsModerated = false;
                let classList = ['MultiMediaItemContainer'];
                if(Array.isArray(mediaItem.moderationLabelList)){
                    if(mediaItem.moderationLabelList.length > 0){
                        classList.push('MultiMediaItemContainerModerated');
                        mediaIsModerated = true;
                    }
                }

                result.push(
                    <div ref={imageRef} key={mediaItem.url} className={classList.join(' ')}
                        data-isfirst={(mediaIndex === 0)}
                        data-islast={(mediaIndex === (this.props.mediaList.length-1))}>
                        <div className="MultiMediaItemDragCursor"></div>
                        <div className="MultiMediaItem MultiMediaExistItem" draggable={true}
                            onDragStart={this.handleMediaContentDragStart.bind(this,mediaItem.url)}
                            onDragOver={this.handleMediaSortDragOver.bind(this,imageRef,true)}
                            onDragLeave={this.handleMediaSortDragOver.bind(this,imageRef,false)}
                            onDrop={this.handleMediaContentDrop.bind(this,mediaIndex)}>
                            <div className="MultiMediaItemBackground" 
                                style={{backgroundImage: 'url("' + imageUrl + '")'}}>
                            </div>

                            {mediaIsModerated === true &&
                                <div className="MultiMediaItemModerated">
                                    <div className="MultiMediaItemModeratedText" 
                                        onClick={() => {
                                            this.setState({
                                                restrictedItem: mediaItem
                                            });
                                        }}>
                                        {Culture.getText('MEDIA_RESTRICTED')}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="MultiMediaItemRemove" 
                            onClick={this.removeMediaItem.bind(this,mediaItem.url)}>
                        </div>
                        <div className="MultiMediaItemEdit" onClick={() => {
                            /* set the item as the active item to edit and show the window */ 
                            this.setState({editItem: mediaItem, showEditWindow: true});
                        }}></div>
                        <div className="MultiMediaItemMoveForwardButton MultiMediaItemMoveButton"
                            onClick={(function(currentIndex){
                                /* move this image one position forward in the list */
                                if(currentIndex > 0){
                                    let updatedList = this.props.mediaList;
                                    let element = updatedList[currentIndex];
                                    updatedList.splice(currentIndex, 1);
                                    updatedList.splice((currentIndex-1),0,element);
                                    this.notifyListUpdate(updatedList);
                                }
                            }).bind(this,mediaIndex)}></div>
                        <div className="MultiMediaItemMoveBackwardButton MultiMediaItemMoveButton"
                            onClick={(function(currentIndex){
                                /* move this image one position backward in the list */
                                if(currentIndex < (this.props.mediaList.length-1)){
                                    let updatedList = this.props.mediaList;
                                    let element = updatedList[currentIndex];
                                    updatedList.splice(currentIndex, 1);
                                    updatedList.splice((currentIndex+1),0,element);
                                    this.notifyListUpdate(updatedList);
                                }
                            }).bind(this,mediaIndex)}></div>
                    </div>
                );
                mediaIndex++;
            });
        }

        return result;
    }

    /**
     * Renders the file upload list in the
     * state with the pending and active
     * files
     */
    renderFileUploadList(){
        let result = [];

        let uploadList = this.state.fileUploadList;
        for(let f=0; f<uploadList.length; f++){
            let fileUpload = uploadList[f];

            let backgroundImageUrl = '';
            if(this.state.fileContent.hasOwnProperty(fileUpload.name)){
                backgroundImageUrl = this.state.fileContent[fileUpload.name];
            }

            result.push(
                <div key={fileUpload.name} className="MultiMediaItem">
                    <div className="MultiMediaItemBackground" 
                        style={{backgroundImage: 'url(' + backgroundImageUrl + ')'}}>
                    </div>
                    <div className="MultiMediaItemProgressBar"></div>
                </div>
            );            
        }

        return result;
    }

    /**
     * Returns the option list with all
     * available media tags as an array
     */
    getMediaTagOptionList(){
        let result = [];

        let mediaItem = this.state.editItem;
        let mediaTagList = require('../../config/ota/OTAImageTypeCode.json');
        Object.keys(mediaTagList).forEach((mediaTagCode) => {
            let mediaTagChecked = false;
            if(mediaItem !== null){
                if(Array.isArray(mediaItem.tagList)){
                    mediaItem.tagList.forEach((tagItem) => {
                        if(parseInt(tagItem.code) === parseInt(mediaTagCode)){
                            mediaTagChecked = true;
                        }
                    });
                }
            }

            result.push({
                value: mediaTagCode,
                text: Culture.getText('MEDIATAG_NAME_'+mediaTagCode),
                checked: mediaTagChecked
            });
        });

        return result;
    }

    /**
     * Toggles the media tag for the current
     * item that is being edited with the editor
     * 
     * @param {object} value
     * the media tag option item 
     */
    toggleMediaTag(value){
        let mediaItem = this.state.editItem;
        if(mediaItem !== null){
            /* define the tag code for this media tag */
            let toggleTagCode = parseInt(value.value);

            /* ensure the media item has a tag list array */
            if(!Array.isArray(mediaItem.tagList)){
                mediaItem.tagList = [];
            }

            /* get the tag item definitions from the config */
            let mediaTagList = require('../../config/ota/OTAImageTypeCode.json');

            let tagExists = false;
            let updatedTagList = [];
            mediaItem.tagList.forEach((tagItem) => {
                if(parseInt(tagItem.code) !== parseInt(toggleTagCode)){
                    updatedTagList.push(tagItem);
                }else{
                    tagExists = true;
                }
            });
            mediaItem.tagList = updatedTagList;

            /* add the media tag when it does not exist */
            if(!tagExists){
                mediaItem.tagList.push({
                    code: parseInt(toggleTagCode),
                    name: mediaTagList[toggleTagCode]
                });
            }

            /* update the media item in the state */
            this.setState({editItem: mediaItem});
            this.notifyListUpdate(this.props.mediaList);
        }
    }

    /**
     * Returns true when the provided media item
     * already went through refinement, including
     * media analysis and moderation. If not, this
     * will return false
     * 
     * @param {object} mediaItem
     * the media item to check if it went through
     * the refinement process already 
     */
    isMediaItemRefined(mediaItem){
        let result = true;

        let fieldList = ['moderationLabelList','propertyAmenityList','roomAmenityList'];
        fieldList.forEach((fieldKey) => {
            if(!Array.isArray(mediaItem[fieldKey])){
                result = false;
            }
        });

        return result;
    }

    /**
     * Renders the edit window for the
     * selected item or renders nothing
     * when editing is not enabled
     */
    renderEditWindow(){
        let result = [];

        if(this.state.showEditWindow === true && this.state.editItem !== null){
            /* get the url of the current item */
            let mediaItem = this.state.editItem;
            let imageUrl = ApiClient.getMediaContentUri(this.props.property.code,
                                        mediaItem.url,this.state.lastModified);            

            /* check if the media item went through refinement already */
            let itemIsRefined = this.isMediaItemRefined(mediaItem);

            /* define the preview component for this image */
            let previewComponent = (
                <div className="MediaEditWindowPreviewContainer">
                    <div className="MediaEditWindowPreview" 
                        style={{backgroundImage: 'url("' + imageUrl + '")'}}>
                    </div>
                    <div className="MediaEditWindowPreviewOpenNew" 
                        onClick={() => window.open(imageUrl)}>
                        {Culture.getText('MEDIA_INFO_PREVIEW_LINK')}
                    </div>
                </div>
            );

            /* render the actual window */
            if(itemIsRefined === false){
                result.push(
                    <ModalDrawerOverlay key="editWindow"
                        titleText={Culture.getText('MEDIA_INFO_TITLE')}
                        subtitleText={Culture.getText('MEDIA_INFO_SUBTITLE')}
                        onClose={() => this.setState({showEditWindow: false})}>
                        <div className="MediaEditWindowInfoContent">
                            <div className="MediaEditWindowUnprocessed">
                                <div className="MediaEditWindowUnprocessedText">
                                    {Culture.getText('MEDIA_PROCESSING_PENDING')}
                                </div>
                            </div>

                            {previewComponent}
                        </div>
                    </ModalDrawerOverlay>
                );
            }else{
                result.push(
                    <ModalDrawerOverlay key="editWindow"
                        titleText={Culture.getText('MEDIA_INFO_TITLE')}
                        subtitleText={Culture.getText('MEDIA_INFO_SUBTITLE')}
                        onClose={() => this.setState({showEditWindow: false})}
                        submitButtonText={Culture.getText('MEDIA_FILE_UPDATE')}
                        onSubmit={() => this.setState({showEditWindow: false})}>
                        {previewComponent}
    
                        <MessageListEditor title={Culture.getText('MEDIA_FILE_DESCRIPTION')} 
                            languageList={ManagementUser.getCurrent().getTenantLanguageList()} 
                            content={mediaItem.messageList}
                            onChange={(value) => {
                                /* update the message list of the media item */
                                let mediaItem = this.state.editItem;
                                mediaItem.messageList = value;
                                this.notifyListUpdate(this.props.mediaList);
                            }}
                        />
    
                        <ModalOptionListSelect title={Culture.getText('MEDIATAG_LIST_TITLE')}
                            filterPlaceholderText={Culture.getText('UNIT_AMENITY_FILTERTEXT')} 
                            addButtonText={Culture.getText('MEDIATAG_LIST_ADDTAG')}
                            list={this.getMediaTagOptionList()} 
                            onToggle={this.toggleMediaTag.bind(this)}
                            transparentBackground={true} />
                    </ModalDrawerOverlay>
                );
            }
        }

        return result;
    }

    /**
     * Returns the array with the recommended
     * property amenities from the images as
     * identified by the refinement process
     */
    getPropertyRecommendationList(){
        let result = [];

        /* iterate through this media list and collect
            all recommendations available from all media */
        let recommenderItemList = [];
        let amenityKeyList = [];
        if(Array.isArray(this.props.mediaList)){
            this.props.mediaList.forEach((mediaItem) => {
                if(Array.isArray(mediaItem.propertyAmenityList)){
                    mediaItem.propertyAmenityList.forEach((propertyAmenity) => {
                        if(!amenityKeyList.includes(propertyAmenity.code)){
                            recommenderItemList.push(propertyAmenity);
                            amenityKeyList.push(propertyAmenity.code);
                        }
                    });
                }
            });
        }        

        /* iterate through the existing services of
            the property and only returns those that
            are not already set */
        if(this.props.property !== null){
            if(Array.isArray(this.props.property.serviceList)){
                let serviceList = this.props.property.serviceList;
                recommenderItemList.forEach((recommenderItem) => {
                    let itemExists = false;
                    serviceList.forEach((serviceItem) => {
                        if(parseInt(serviceItem.code) === parseInt(recommenderItem.code)){
                            /* this item exists already */
                            itemExists = true;
                        }
                    });

                    if(!itemExists){ result.push(recommenderItem); }
                });
            }
        }

        return result;
    }

    /**
     * Renders the property amenity recommendation
     * list if this is a property input and recommendations
     * are available. If not, this will not render or
     * return anything but an empty array
     */
    renderPropertyRecommendationList(){
        let result = [];

        let recommenderList = this.getPropertyRecommendationList();
        if(this.isPropertyMediaList() === true && recommenderList.length > 0){
            let renderList = [];
            recommenderList.forEach((item) => {
                renderList.push(
                    <div key={item.code} className="RecommenderOptionInputItem" onClick={() => {
                        if(typeof this.props.onAddPropertyService === 'function'){
                            this.props.onAddPropertyService(item);
                        }
                    }}>
                        <div className="RecommenderOptionInputItemText">
                            {Culture.getText('PROPERTY_AMENITYNAME_'+item.code)}
                        </div>
                    </div>
                );
            });

            result.push(
                <div key="MediaPropertyRecommendation" 
                    className="MediaPropertyRecommendation MediaRecommendationView">
                    <div className="MediaRecommendationTitle">
                        {Culture.getText('MEDIA_PROPERTY_RECOMMENDATION_TITLE')}
                    </div>
                    <div className="MediaRecommendationText">
                        {Culture.getText('MEDIA_PROPERTY_RECOMMENDATION_TEXT')}
                    </div>
                    <div className="RecommenderOptionInputList">
                        {renderList}
                    </div>
                </div>
            );
        }

        return result;
    }

    /**
     * Returns the recommended unit amenities
     * for the unit the media is associated with
     */
    getUnitRecommendationList(){
        let result = [];

        if(this.props.property !== null){
            /* identify the unit and get the amenity list */
            let property = new Property(this.props.property);
            let amenityList = [];
            property.getGuestRoomList().forEach((guestRoom) => {
                if(guestRoom.roomId === this.props.propertyElement){
                    if(Array.isArray(guestRoom.amenityList)){
                        guestRoom.amenityList.forEach((existAmenity) => {
                            amenityList.push(parseInt(existAmenity.code));
                        });
                    }
                }
            });

            /* add those amenities to the recommender that
                are not already set for the unit itself */
            let amenityKeyList = [];
            if(Array.isArray(this.props.mediaList)){
                this.props.mediaList.forEach((mediaItem) => {
                    if(Array.isArray(mediaItem.roomAmenityList)){
                        mediaItem.roomAmenityList.forEach((roomAmenity) => {
                            if(amenityList.includes(parseInt(roomAmenity.code)) === false
                                && amenityKeyList.includes(parseInt(roomAmenity.code)) === false){
                                /* this amenity item is not already present */
                                result.push(roomAmenity);
                                amenityKeyList.push(parseInt(roomAmenity.code));
                            }
                        });
                    }
                });
            }
        }

        return result;
    }

    /**
     * Renders the unit amenity recommendation
     * list if this is a unit input and recommendations
     * for the unit are available. If not it'll just 
     * return or render nothing (empty array)
     */
    renderUnitRecommendationList(){
        let result = [];

        /* it is a unit inpput, when it is not a property one */
        if(this.isPropertyMediaList() === false){
            let recommenderList = this.getUnitRecommendationList();
            if(recommenderList.length > 0){
                let renderList = [];
                recommenderList.forEach((item) => {
                    renderList.push(
                        <div key={item.code} className="RecommenderOptionInputItem" onClick={() => {
                            if(typeof this.props.onAddUnitAmenity === 'function'){
                                this.props.onAddUnitAmenity(item);
                            }
                        }}>
                            <div className="RecommenderOptionInputItemText">
                                {Culture.getText('UNIT_AMENITYTYPENAME_'+item.code)}
                            </div>
                        </div>
                    );
                });

                result.push(
                    <div key="MediaUnitRecommendation" 
                        className="MediaUnitRecommendation MediaRecommendationView">
                        <div className="MediaRecommendationTitle">
                            {Culture.getText('MEDIA_UNIT_RECOMMENDATION_TITLE')}
                        </div>
                        <div className="MediaRecommendationText">
                            {Culture.getText('MEDIA_UNIT_RECOMMENDATION_TEXT')}
                        </div>
                        <div className="RecommenderOptionInputList">
                            {renderList}
                        </div>
                    </div>
                );
            }
        }

        return result;
    }

    /**
     * Renders the window with the restricted item
     * and shows the reason for the restriction of
     * the media item
     */
    renderRestrictedItemWindow(){
        let result = [];

        if(this.state.restrictedItem !== null){
            /* get the url of the restricted item */
            let mediaItem = this.state.restrictedItem;
            let imageUrl = ApiClient.getMediaContentUri(this.props.property.code,
                                        mediaItem.url,this.state.lastModified);  

            result.push(
                <ModalDrawerOverlay key="editWindow"
                        titleText={Culture.getText('MEDIA_RESTRICTED_TITLE')}
                        subtitleText={Culture.getText('MEDIA_RESTRICTED_SUBTITLE')}
                        onClose={() => this.setState({restrictedItem: null})}>
                        <div className="MediaEditWindowPreviewContainer">
                            <div className="MediaEditWindowPreview MediaEditWindowPreviewRestricted" 
                                style={{backgroundImage: 'url("' + imageUrl + '")'}}>
                            </div>
                            <div className="MediaEditWindowPreviewOpenNew" 
                                onClick={() => window.open(imageUrl)}>
                                {Culture.getText('MEDIA_INFO_PREVIEW_LINK')}
                            </div>
                        </div>
                        <div className="MediaRestrictedText">
                            {Culture.getText('MEDIA_RESTRICTED_TEXT')}
                        </div>
                        <ul className="MediaRestrictedList">
                            {mediaItem.moderationLabelList.map((value, index) => {
                                return (
                                    <li key={value} className="MediaRestrictedListItem">
                                        {Culture.getText('MEDIA_MODERATIONLABEL_'+value.toUpperCase())}
                                    </li>
                                )
                            })}
                        </ul>
                </ModalDrawerOverlay>
            );
        }

        return result;
    }

    /**
     * Renders the component with the file
     * list and the various file upload options
     * which can be selecting files with the
     * file open dialog or dragging them onto
     * this component from the systems file
     * manager
     */
    render(){
        let lastMediaIndex = 0;
        if(Array.isArray(this.props.mediaList)){
            lastMediaIndex = this.props.mediaList.length;
        }

        return(
            <div className="MultiMediaListInputView">
                {this.state.invalidFileSelected === true &&
                    <div className="MultiMediaListInputInvalidFile">
                        {Culture.getText('MEDIA_INVALID_FILE_SELECTED')}
                    </div>
                }

                <div className="MultiMediaListInput"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={this.handleMediaContentDrop.bind(this,false)}>
                    <div className="MultiMediaDragInfoText">
                        {Culture.getText('PROPERTY_MEDIALIST_DRAGINFO')}
                    </div>
                    <div className="MultiMediaItemList" onDragEnd={this.resetDragCursor.bind(this)}>
                        {this.renderMediaList()}
                        {this.renderFileUploadList()}

                        <div ref={this.addButtonRef} className="MultiMediaItemContainer">
                            <div className="MultiMediaItemDragCursor"></div>
                            <div ref={this.addButtonRef} className="MultiMediaAddButton" onClick={this.showOpenFileDialog.bind(this)}
                                onDragOver={this.handleMediaSortDragOver.bind(this,this.addButtonRef,true)}
                                onDragLeave={this.handleMediaSortDragOver.bind(this,this.addButtonRef,false)}
                                onDrop={this.handleMediaContentDrop.bind(this,lastMediaIndex)}>
                                <input ref={this.fileInputRef} type="file" multiple={true}
                                    onChange={this.handleMediaContentSelect.bind(this)} />
                            </div>
                        </div>
                    </div>

                    {this.renderEditWindow()}
                </div>

                {this.renderPropertyRecommendationList()}
                {this.renderUnitRecommendationList()}
                {this.renderRestrictedItemWindow()}
            </div>
        );
    }
}

export default MultiMediaListInput;
