/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import LoadingIndicator from '../common/LoadingIndicator';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';

class PaymentTransactionList extends React.Component {
    state = {
        showMonthSelection: false,
        showCSVExport: false,
        reportingMonth: '',
        nextPageToken: '',
        fetching: false,
        list: []
    }

    /**
     * Fetches the transaction list when
     * the component was mounted
     */
    componentDidMount(){
        let today = new Date();
        let reportingMonth = [today.getUTCFullYear(),
                        String(today.getUTCMonth()+1)
                        .padStart(2,'0')].join('-');

        this.setState({
            reportingMonth: reportingMonth
        },this.fetchList.bind(this));
    }

    /**
     * Fetches the transaction list of the
     * data defined in the state or the current
     * date if none is defined
     */
    async fetchList(){
        this.setState({fetching: true});

        let listResult = await ApiClient.execute('/booking/getPaymentTransactionList',{
            month: this.state.reportingMonth,
            nextPageToken: this.state.nextPageToken
        });

        if(Array.isArray(listResult.list)){
            this.setState({
                list: this.state.list.concat(listResult.list), 
                nextPageToken: listResult.nextPageToken,
                fetching: false
            });
        }
    }

    /**
     * Performs the CSV export of the transactions
     * and the payments. Executes the download of the
     * CSV export when finished
     */
    async exportCSV(){
        this.setState({showCSVExport: true});

        let transactionList = [];

        let hasMore = true;
        let pageToken = '';
        while(hasMore === true){
            hasMore = false;

            let listResult = await ApiClient.execute('/booking/getPaymentTransactionList',{
                month: this.state.reportingMonth,
                nextPageToken: pageToken
            });

            if (Array.isArray(listResult.list)) {
                transactionList = transactionList.concat(listResult.list);

                if (listResult.nextPageToken !== '' && listResult.nextPageToken !== false) {
                    hasMore = true;
                    pageToken = listResult.nextPageToken;
                }
            }
        }

        /* fetch all bookings for the period to attach to the transactions */
        let bookingMonthDate = new Date(this.state.reportingMonth+'-01T00:00:00.000Z');
        let bookingFromTs = bookingMonthDate.getTime();
        let bookingUntilTs = new Date(bookingMonthDate.getFullYear(),bookingMonthDate.getMonth() + 1, 0).getTime();

        let bookingResult = await ApiClient.execute('/booking/list',{
            createdFrom: Math.floor(bookingFromTs / 1000),
            createdUntil: Math.floor(bookingUntilTs / 1000)
        });

        if (Array.isArray(bookingResult.list)) {
            for(const booking of bookingResult.list){
                for(const transaction of transactionList){
                    if(transaction.recordLocator === booking.recordLocator){
                        transaction.booking = booking;
                    }
                }
            }
        }

        let content = 'Status,Created,Arrival,PropertyName,BookingCode,ExternalBookingCode,CustomerName,TotalAmount,RefundAmount,Amount,PaymentFee,CurrencyCode\n';
        for (const transaction of transactionList) {
            let arrivalDateString = "0";
            let externalBookingCode = "not_available";
            let propertyName = "not_available";
            let customerName = "not_available";
            if(typeof transaction.booking === 'object' && transaction.booking !== null){
                arrivalDateString = (new Date(transaction.booking.arrival * 1000)).toISOString();
                externalBookingCode = transaction.booking.externalRecordLocator || 'not_available';
                propertyName = transaction.booking.propertyName.replace(/[^0-9a-zA-Z]/g,' ').replace(/([ ]{2,})/g,' ').trim();
                customerName = transaction.booking.customerName.replace(/[^0-9a-zA-Z]/g,' ').replace(/([ ]{2,})/g,' ').trim();
            }

            let amountRefunded = 0;
            if(transaction.refunded === true){
                amountRefunded = transaction.amountRefunded;
            }

            content += [
                transaction.status,
                (new Date(transaction.created * 1000)).toISOString(),
                arrivalDateString,
                propertyName,
                (transaction.recordLocator || 'not_available'),
                externalBookingCode,
                customerName,
                transaction.amount,
                amountRefunded,
                (transaction.amount - amountRefunded),
                transaction.paymentFee,
                transaction.currencyCode.toUpperCase()
            ].join(',') + '\n';
        }


        var downloadLink = document.createElement("a");
        downloadLink.href = encodeURI("data:text/csv;charset=utf-8," + content);
        downloadLink.download = 'tx_' + this.state.reportingMonth + '.csv';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.setState({showCSVExport: false});
    }

    /**
     * Renders the payment method display in
     * the transaction list for each payment
     * method available
     * 
     * @param {object} paymentMethod 
     * the payment method to render
     */
    renderPaymentMethod(paymentMethod){
        let paymentMethodType = Object.keys(paymentMethod)[0].toUpperCase();
        let result = (
            <div className="PaymentAccountTransactionListItemMethod"
                data-paymentmethod={paymentMethodType.toLowerCase()}>
                <span className="PaymentAccountTransactionListItemMethodText">
                    {Culture.getText('PAYMENT_TRANSACTIONMETHOD_'+paymentMethodType)}
                </span>
            </div>
        );

        if(paymentMethodType === 'CARD'){
            result = (
                <div className="PaymentAccountTransactionListItemMethod"
                    data-paymentmethod={paymentMethodType.toLowerCase()}
                    data-cardtype={paymentMethod.card.brand}>
                    <span className="PaymentAccountTransactionListItemMethodText">
                        •••• {paymentMethod.card.last4}
                    </span>
                </div>
            );
        }if(paymentMethodType === 'GIROPAY'){
            result = (
                <div className="PaymentAccountTransactionListItemMethod"
                    data-paymentmethod="giropay">
                    <span className="PaymentAccountTransactionListItemMethodText">
                        {paymentMethod.giropay.verified_name 
                            || paymentMethod.giropay.bank_name
                            || paymentMethod.giropay.bank_code
                            || Culture.getText('PAYMENT_TRANSACTIONMETHOD_GIROPAY')}
                    </span>
                </div>
            );
        }if(paymentMethodType === 'SEPA_DEBIT'){
            result = (
                <div className="PaymentAccountTransactionListItemMethod"
                    data-paymentmethod="sepa_debit">
                    <span className="PaymentAccountTransactionListItemMethodText">
                        •••• {paymentMethod.sepa_debit.last4}
                    </span>
                </div>
            );
        }if(paymentMethodType === 'BANCONTACT'){
            result = (
                <div className="PaymentAccountTransactionListItemMethod"
                    data-paymentmethod="bancontact">
                    <span className="PaymentAccountTransactionListItemMethodText">
                        •••• {paymentMethod.bancontact.iban_last4}
                    </span>
                </div>
            );
        }if(paymentMethodType === 'IDEAL'){
            result = (
                <div className="PaymentAccountTransactionListItemMethod"
                    data-paymentmethod="ideal">
                    <span className="PaymentAccountTransactionListItemMethodText">
                        •••• {paymentMethod.ideal.iban_last4}
                    </span>
                </div>
            );
        }if(paymentMethodType === 'SOFORT'){
            result = (
                <div className="PaymentAccountTransactionListItemMethod"
                    data-paymentmethod="sofort">
                    <span className="PaymentAccountTransactionListItemMethodText">
                        {paymentMethod.sofort.bank_name}
                    </span>
                </div>
            );
        }

        return result;
    }

    /**
     * Renders all transactions in the list
     * and returns the rendered contents
     */
    renderTransactionList(){
        let result = [];

        for(const transaction of this.state.list){
            result.push(
                <tr key={transaction.id} className="PaymentAccountTransactionListItem">
                    <td>
                        {transaction.refunded === false &&
                            <div className="PaymentAccountTransactionListItemStatus"
                                data-status={transaction.status}>
                                {Culture.getText('PAYMENT_TRANSACTIONSTATUS_'+transaction.status.toUpperCase())}
                            </div>
                        }
                        {transaction.refunded !== false &&
                            <div className="PaymentAccountTransactionListItemStatus"
                                data-status="refunded">
                                {Culture.getText('PAYMENT_TRANSACTIONSTATUS_REFUNDED')}
                            </div>
                        }
                    </td>
                    <td className="PaymentAccountTransactionListItemAmount">
                        <span>
                            {(new Intl.NumberFormat(Culture.getCultureCode(), {
                            style: 'currency', currency: transaction.currencyCode
                            })).format(transaction.amount)}
                        </span>
                    </td>
                    <td>{this.renderPaymentMethod(transaction.method)}</td>
                    <td>
                        {(function(){
                            let result = <span>&mdash;</span>;

                            if(typeof transaction.recordLocator === 'string' && transaction.recordLocator !== ''){
                                result = transaction.recordLocator;
                            }

                            return result;
                        })()}
                    </td>
                    <td>
                        {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                            dateStyle: 'short',
                            timeStyle: 'short'
                        })).format(transaction.created*1000)}
                    </td>
                    <td>
                        {(function(){
                            let result = <span>&mdash;</span>;

                            if(transaction.amountRefunded !== false){
                                result = (new Intl.NumberFormat(Culture.getCultureCode(), {
                                    style: 'currency', currency: transaction.currencyCode
                                    })).format(transaction.amountRefunded);
                            }

                            return result;
                        })()}
                    </td>
                    <td>
                        {(function(){
                            let result = <span>&mdash;</span>;

                            if(transaction.refunded !== false){
                                result = (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'short',
                                    timeStyle: 'short'
                                })).format(transaction.refunded*1000);
                            }

                            return result;
                        })()}
                    </td>
                </tr>
            );
        }

        return result;
    }

    /**
     * Returns the month name and year
     * for the month provided as YYYY-MM
     * in the parameter
     * 
     * @param {string} monthYear 
     * YYYY-MM month string to return name for
     */
    getMonthName(monthYear){
        let result = monthYear;

        if(typeof monthYear === 'string' && monthYear !== ''){
            let datePart = monthYear.split('-');
            if(datePart.length === 2){
                let dateObject = new Date(Number(datePart[0]),(Number(datePart[1])-1),1,0,0,0,0);
                return Intl.DateTimeFormat(Culture.getCultureCode(),{
                    month: 'long',
                    year: 'numeric'
                }).format(dateObject);
            }
        }

        return result;
    }

    /**
     * Renders the component with the transaction list
     * or the loading indicator if it is actively querying
     */
    render(){
        if(this.state.fetching === true && this.state.list.length === 0){
            return(
                <div className="PaymentAccountTransaction">
                    <LoadingIndicator />
                </div>
            );
        }else{
            return(
                <div className="PaymentAccountTransaction">
                    <div className="PaymentAccountTransactionView">
                        <div className="PaymentAccountTransactionHeader">
                            <div className="PaymentAccountTransactionHeaderTitle">
                                {Culture.getText('PAYMENT_TRANSACTIONLIST_TITLE')}
                            </div>
                            <div className="PaymentAccountTransactionHeaderMonth"
                                onClick={() => this.setState({showMonthSelection: true})}>
                                {this.getMonthName(this.state.reportingMonth)}
                            </div>
                            {this.state.list.length > 0 &&
                                <StandardButton className="PaymentAccountTransactionExport"
                                    text={Culture.getText('PAYMENT_TRANSACTIONLIST_CSVEXPORT')}
                                    onClick={this.exportCSV.bind(this)} />
                            }
                        </div>
                        {this.state.list.length === 0 &&
                            <div className="PaymentAccountTransactionListEmpty">
                                {Culture.getText('PAYMENT_TRANSACTIONLIST_EMPTY')}
                            </div>
                        }
                        {this.state.list.length > 0 &&
                            <div className="PaymentAccountTransactionList">
                                <table cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th>{Culture.getText('PAYMENT_TRANSACTIONLIST_STATUS')}</th>
                                            <th className="PaymentAccountTransactionListAmountHeader">
                                                <span>{Culture.getText('PAYMENT_TRANSACTIONLIST_AMOUNT')}</span>
                                            </th>
                                            <th>{Culture.getText('PAYMENT_TRANSACTIONLIST_METHOD')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSACTIONLIST_RECORDLOCATOR')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSACTIONLIST_CREATED')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSACTIONLIST_REFUNDAMOUNT')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSACTIONLIST_REFUNDED')}</th>
                                        </tr>
                                        {this.renderTransactionList()}
                                    </tbody>
                                </table>

                                {this.state.nextPageToken !== false && this.state.fetching === false &&
                                    <div className="PaymentAccountTransactionListMoreView">
                                        <StandardButton className="PaymentAccountTransactionListMoreButton"
                                            text={Culture.getText('PAYMENT_TRANSACTIONLIST_LOADMOREBUTTON')}
                                            onClick={this.fetchList.bind(this)} />
                                    </div>
                                }

                                {this.state.nextPageToken !== false && this.state.fetching === true &&
                                    <div className="PaymentAccountTransactionListMoreView">
                                        <div className="PaymentAccountTransactionListLoadingMore">
                                            <LoadingIndicator />
                                            {Culture.getText('PAYMENT_TRANSACTIONLIST_LOADMORESTATUS')}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {this.state.showCSVExport === true &&
                        <ModalDrawerOverlay titleText={Culture.getText('PAYMENT_TRANSACTIONLIST_CSVEXPORT_TITLE')}
                            subtitleText={Culture.getText('PAYMENT_TRANSACTIONLIST_CSVEXPORT_SUBTITLE')}>
                            <LoadingIndicator />
                        </ModalDrawerOverlay>
                    }

                    {this.state.showMonthSelection === true &&
                        <ModalDrawerOverlay titleText={Culture.getText('PAYMENT_REPORTINGMONTH_TITLE')}
                            subtitleText={Culture.getText('PAYMENT_REPORTINGMONTH_SUBTITLE')}
                            onClose={() => this.setState({showMonthSelection: false})}>
                            <ul className="PaymentAccountTransactionMonthList">
                                {(function(){
                                    let result = [];

                                    let d = new Date();
                                    d.setDate(1);
                                    for (let i=0; i<=23; i++) {
                                        let monthValue = [d.getFullYear(),String(d.getMonth()+1).padStart(2,'0')].join('-');
                                        result.push(
                                            <li key={monthValue} onClick={() => {
                                                this.setState({
                                                    reportingMonth: monthValue,
                                                    nextPageToken: '',
                                                    list: [],
                                                    showMonthSelection: false
                                                },this.fetchList.bind(this));
                                            }}>
                                                {this.getMonthName(monthValue)}
                                            </li>
                                        );
                                        d.setMonth(d.getMonth() - 1);
                                    }

                                    return result;
                                }).bind(this)()} 
                            </ul>
                        </ModalDrawerOverlay>
                    }
                </div>
            );
        }
    }
}

export default PaymentTransactionList;