/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import '../../style/DataInputField.css';

class StarRatingInputField extends React.Component {
    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    changeValue(value){
        let updatedValue = value;

        /* when the value equals the updated value, reset the
            updated value to 0 to allow a toggle effect on the
            last star of the selection */
        if(this.props.value === updatedValue){
            updatedValue = 0;
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(updatedValue);
        }
    }

    render(){
        let ratingValue = 0;
        if(typeof this.props.value === 'number'){
            ratingValue = this.props.value;
            if(ratingValue < 0){ ratingValue = 0; }
            if(ratingValue > 5){ ratingValue = 5; }
        }

        let starList = [];
        for(let s=1; s<6; s++){
            let classList = [
                "StarRatingInputFieldItem",
                ("StarRatingInputFieldItemStar"+s)
            ];

            if(s <=  ratingValue){
                classList.push("StarRatingInputFieldItemAwarded");
            }

            starList.push(
                <div key={s} className={classList.join(' ')} onClick={this.changeValue.bind(this,s)}></div>
            );
        }

        return(
            <div className="StarRatingInputField">
                <div className="StarRatingInputFieldTitle">{Culture.getText('STARRATING_INPUT_TITLE')}</div>
                <div className="StarRatingInputFieldList">
                    {starList}
                </div>
                <div className="StarRatingInputFieldValueText">{Culture.getText('STARRATING_INPUT_VALUE_'+ratingValue)}</div>
            </div>
        );
    }
}

export default StarRatingInputField;
