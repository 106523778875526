/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* require json diff to perform the diff operation */
const jsonDiff = require('json-diff');

/**
 * Represents a reservation blockchain
 * and provides methods to manipulate
 * the blockchain
 */
class BlockChain {
    /**
     * Constructs the blockchain class with
     * the chain array including all blocks
     * 
     * @param {array} chainList 
     * array with all blocks of the chain
     */
    constructor(chainList,transactionId){
        this.chain = [];
        if(Array.isArray(chainList)){
            if(chainList.length > 0){
                this.chain = chainList;
                this.committed = chainList[0];
            }
        }

        this.transactionId = '';
        if(typeof transactionId === 'string'){
            this.transactionId = transactionId;
        }
    }

    /**
     * Returns true when this booking is a
     * marketplace booking and false if not
     */
    isMarketplaceBooking(){
        return (typeof this.committed.content.booking.marketProvider === 'string'
        && this.committed.content.booking.marketProvider !== '');
    }

    /**
     * Returns true if this blockchain has an
     * external payment processed with a payment
     * provider and false if it does not
     */
    hasExternalPayment(){
        return (typeof this.committed.content.booking.externalPayment === 'object'
                    && this.committed.content.booking.externalPayment !== null);
    }

    /**
     * Returns the external payment of this
     * booking if any is present or null if
     * this reservation does not have any
     * external payment
     */
    getExternalPayment(){
        let result = null;

        if(this.hasExternalPayment() === true){
            result = this.committed.content.booking.externalPayment;
        }

        return result;
    }

    /**
     * Returns the timestamp of the
     * date when the record was created
     */
    getCreatedDate(){
        return this.committed.content.created*1000;
    }

    /**
     * Returns the timestamp of the
     * date when the record was last
     * updated
     */
    getLastUpdatedDate(){
        return this.committed.dateTime;
    }

    /**
     * Returns the committed block
     * which is the current state of
     * the reservation object
     */
    getCommitted(){
        return this.committed;
    }

    /**
     * Returns the status of the reservation
     * as string with the status code
     */
    getStatus(){
        return this.committed.content.status;
    }

    /**
     * Updates the reservation status
     * code in the committed block
     * 
     * @param {string} code
     * reservation status code to update to 
     */
    setStatus(code){
        this.committed.content.status = code;
    }

    /**
     * Returns the record locator
     * of this block chain reservation
     */
    getRecordLocator(){
        return this.committed.recordLocator;
    }

    /**
     * Returns the external record locator
     * of this blockchain reservation if it
     * has any or false if it does not have
     * an external record locator assigned
     */
    getExternalRecordLocator(){
        let result = false;

        let bookingRecord = this.getCommitted().content.booking;
        if(typeof bookingRecord.externalRecordLocator === 'string' 
            && bookingRecord.externalRecordLocator !== ''){
            result = bookingRecord.externalRecordLocator;
        }

        return result;
    }

    /**
     * Returns the block hash of
     * this block chain reservation block
     */
    getBlockHash(){
        return this.committed.blockHash;
    }

    /**
     * Returns the payment reference
     * for this reservation or null if
     * none is available
     */
    getPaymentReference(){
        let result = null;

        let bookingObject = this.getCommitted().content.booking;
        if(typeof bookingObject.paymentRef === 'string'
            && bookingObject.paymentRef !== ''
            && typeof bookingObject.paymentTypeCode === 'string'
            && bookingObject.paymentTypeCode !== ''){
            result = {
                referenceCode: bookingObject.paymentRef,
                typeCode: bookingObject.paymentTypeCode
            };
        }

        return result;
    }

    /**
     * Returns an array with the block
     * identification of all blocks in
     * the reservations chain
     */
    getBlockList(){
        let result = [];

        if(Array.isArray(this.chain)){
            result = this.chain;
        }

        return result;
    }

    /**
     * Returns the block in the chain for
     * which the block id was set locally
     */
    getBlock(){
        let result = null;

        if(Array.isArray(this.chain)){
            if(this.chain.length > 0){
                result = this.chain[0];

                if(this.transactionId !== ''){
                    for(const block of this.chain){
                        if(block.transactionId === this.transactionId){
                            result = block;
                        }
                    }
                }
            }
        }

        return result;
    }

    /**
     * Returns an array with the changed fields
     * from the old block and the new block
     * 
     * @param {object} oldBlock
     * the old or previous block of the chain
     *  
     * @param {object} newBlock 
     * the new or latest block of the chain
     */
    static getDiffList(oldBlock,newBlock){
        let result = {};

        /* perform the diff of both blocks */
        let diffResult = jsonDiff.diff(oldBlock,newBlock);
        let diffFieldList = {};
        BlockChain.traverseBlock(diffResult,'',function(key,value){
            let fieldCode = key.substring(1).toUpperCase();
            diffFieldList[fieldCode] = value;
        });
        
        for(const fieldKey of Object.keys(diffFieldList)){
            let fieldName = fieldKey.substring(0,fieldKey.lastIndexOf('__'));
            if(fieldName.endsWith('_')){
                fieldName = fieldName.substring(0,fieldName.length-1);
            }

            let fieldActionCode = fieldKey.substring(fieldKey.lastIndexOf('__')+2);
            if(typeof result[fieldName] !== 'object' || result[fieldName] === null){
                result[fieldName] = {};
            }

            result[fieldName][fieldActionCode] = diffFieldList[fieldKey];
        }

        return result;
    }
    
    /**
     * traverses a block and returns the
     * changed fields from the diff result
     * 
     * @param {object} o
     * the object to traverse
     *  
     * @param {string} prefix
     * the prefix/path of the current position
     *  
     * @param {function} func 
     * the function to trigger at the end
     */
    static traverseBlock(o,prefix,func){
        for (var i in o) {
            if (o[i] !== null && typeof(o[i])=="object") {
                BlockChain.traverseBlock(o[i],prefix+'_'+i,func);
            }else{
                func.apply(this,[prefix+'_'+i,o[i]]);
            }
        }
    }
}

export default BlockChain;