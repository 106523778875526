/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import '../../style/ContentHeaderMenu.css';

class ContentHeaderMenu extends React.Component {
    /**
     * Notifies the parent about the
     * selected menu section
     * 
     * @param {string} code 
     * the selected menu section
     */
    changeSection(code){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(code);
        }
    }

    /**
     * Renders all menu items provided
     * in the props for this list
     */
    renderList(){
        let result = [];

        if(Array.isArray(this.props.menuList)){
            this.props.menuList.forEach((menuItem) => {
                let classList = ['ContentHeaderMenuItem'];
                if(menuItem.active === true){
                    classList.push('ContentHeaderMenuItemActive');
                }

                result.push(
                    <div key={menuItem.code} data-menucode={menuItem.code} className={classList.join(' ')}
                        onClick={this.changeSection.bind(this,menuItem.code)}>
                        {menuItem.title}
                    </div>
                );
            });
        }

        return result;
    }

    /**
     * Renders the actual component
     * with the title and the menu
     */
    render(){
        return(
            <div className="ContentHeaderMenu">
                <div className="ContentHeaderMenuContent">
                    {typeof this.props.backLinkText === 'string' && this.props.backLinkText !== '' &&
                        <div className="ContentHeaderMenuBackLink" onClick={(function(){
                            if(typeof this.props.onBackLinkClick === 'function'){
                                this.props.onBackLinkClick();
                            }
                        }).bind(this)}>
                            {this.props.backLinkText}
                        </div>
                    }

                    <div className="ContentHeaderMenuTitle">
                        <div className="ContentHeaderMenuMainTitle">
                            {this.props.title}
                        </div>
                        <div className="ContentHeaderMenuSubTitle">
                            {this.props.subtitle || '...'}
                        </div>
                    </div>
                    <div className="ContentHeaderMenuList">
                        {this.renderList()}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContentHeaderMenu;