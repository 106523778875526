/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';

import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import { SketchPicker } from 'react-color';

import '../../style/ColorSelectInput.css';

/**
 * Color selection component that allows to
 * pick colors and provides the hex code for it
 */
class ColorSelectInput extends React.Component {
    state = {
        selectedColor: '',
        showSelectWindow: false
    };

    /**
     * Renders the color picker and notifies
     * the parent when the user selected a color
     */
    render(){
        return(
            <>
                <div className="ColorSelectInput">
                    <div className="ColorSelectInputTitle">
                        {this.props.title}
                    </div>
                    <div className="ColorSelectInputValue">
                        <input ref={this.inputField} 
                            className="ColorSelectInputField"
                            type="color" value={this.props.value} 
                            onChange={(e) => {
                                if(typeof this.props.onChange === 'function'){
                                    this.props.onChange(e.target.value);
                                }
                            }} 
                        />   
                        <div className="ColorSelectInputValuePreview" 
                            style={{backgroundColor: this.props.value}}
                            onClick={(function(){
                                this.setState({
                                    showSelectWindow: true,
                                    selectedColor: this.props.value
                                });
                            }).bind(this)}>
                        </div>
                        <input type="text" value={this.props.value} 
                            className="ColorSelectInputValueText"
                            onChange={(e) => {
                                if(typeof this.props.onChange === 'function'){
                                    this.props.onChange(e.target.value);
                                }
                            }} />
                    </div>
                </div>
                {this.state.showSelectWindow === true &&
                    <ModalDrawerOverlay className="ColorSelectInputOverlayPicker"
                        titleText={Culture.getText('COLORPICKER_TITLETEXT')}
                        subtitleText={Culture.getText('COLORPICKER_SUBTITLETEXT')}
                        submitButtonText={Culture.getText('COLORPICKER_APPLYBUTTON')}
                        transparentBackground={(this.props.transparentBackground || false)}
                        onSubmit={(function(){
                            if(typeof this.props.onChange === 'function'){
                                this.props.onChange(this.state.selectedColor);
                                this.setState({showSelectWindow: false});
                            }
                        }).bind(this)}
                        onClose={() => this.setState({showSelectWindow: false})}>
                        <SketchPicker color={this.state.selectedColor}
                            onChangeComplete={(value) => this.setState({selectedColor: value.hex})} />
                    </ModalDrawerOverlay>
                }
            </>
        );
    }
}

export default ColorSelectInput;