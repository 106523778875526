/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/LoadingIndicator.css';

class LoadingIndicator extends React.Component {
    render(){
        return(
            <div className="LoadingIndicator">
                <div id="wrapper">
                    <div className="profile-main-loader">
                        <div className="loader">
                            <svg className="circular-loader"viewBox="25 25 50 50" >
                                <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#70c542" strokeWidth="2" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingIndicator;