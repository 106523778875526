/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import OptionListSelect from './OptionListSelect';
import ModalDrawerOverlay from './ModalDrawerOverlay';

import '../../style/OptionListSelect.css';
import Culture from '../../class/Culture';

/**
 * The modal option list component provides
 * the option list in the form of an input
 * field with the actual optiona list an
 * overlay window
 */
class ModalOptionListSelect extends React.Component {
    state = {
        visible: false
    }

    /**
     * Renders the list of values for the option list
     */
    renderValueList(){
        let result = [];

        if(Array.isArray(this.props.list)){
            let selectable = (typeof this.props.onSelectItem === 'function');
            this.props.list.forEach((item) => {
                if(item.checked === true){
                    result.push(
                        <div key={item.value} data-selectable={selectable}
                            className="ModalOptionListSelectItem">
                            <div className="ModalOptionListSelectItemText"
                                onClick={(function(itemCode){
                                    if(typeof this.props.onSelectItem === 'function'){
                                        this.props.onSelectItem(itemCode);
                                    }
                                }).bind(this,item.value)}>
                                {item.text}
                            </div>
                            <div className="ModalOptionListSelectItemRemove" onClick={() => {
                                if(typeof this.props.onToggle === 'function'){
                                    this.props.onToggle(item);
                                }
                            }}></div>
                        </div>
                    );
                }
            });
        }

        if(result.length === 0){
            result = [
                <div key="empty" className="ModalOptionListSelectEmpty"
                    onClick={() => this.setState({visible: true})}>
                    {this.props.emptyText || '...'}
                </div>
            ]
        }

        return result;
    }

    /**
     * Renders the component itself
     */
    render(){
        let classList = ["ModalOptionListSelect"];
        if(typeof this.props.className === "string"){
            classList.push(this.props.className);
        }

        return(
            <div className={classList.join(' ')}>
                <div className="ModalOptionListSelectField">
                    <div className="ModalOptionListSelectTitle">
                        {this.props.title}
                    </div>
                    <div className="ModalOptionListSelectTitleValueList">
                        {this.renderValueList()}
                    </div>
                    <div className="ModalOptionListSelectButtonList">
                        <div key="AddItemButton" className="ModalOptionListSelectAddItemButton"
                            onClick={() => this.setState({visible: true})}>
                            {this.props.addButtonText}
                        </div>
                    </div>
                </div>

                {this.state.visible === true &&
                    <ModalDrawerOverlay className="ModalOptionListDrawerOverlay"
                        transparentBackground={(this.props.transparentBackground || false)}
                        submitButtonText={Culture.getText('APPLYSELECTION_TEXT')}
                        onSubmit={() => this.setState({visible: false})}
                        onClose={() => this.setState({visible: false})}>
                        <OptionListSelect enableFilter={true}
                            filterPlaceholderText={this.props.filterPlaceholderText}
                            title={this.props.title} 
                            list={this.props.list} onToggle={(item) => {
                                if(typeof this.props.onToggle === 'function'){
                                    this.props.onToggle(item);

                                    if(this.props.closeOnSelect === true){
                                        this.setState({visible: false});
                                    }
                                }
                        }} />
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default ModalOptionListSelect;