/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';

import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';
import CountrySelectList from '../common/CountrySelectList';
import EditorSection from '../common/EditorSection';
import AttractionEditor from './AttractionEditor';
import ContactEditor from './ContactEditor';
import SubNavigationList from '../common/SubNavigationList';

import '../../style/LocationEditor.css';

/* require the geo hierarchy list to determine which geo elements
    can be blocked by the users and which one's can't */
const GeoHierarchyList = require('../../config/common/GeoHierarchy.json');

/**
 * Allows entering and editing the location and geo
 * information of a property 
 */
class LocationEditor extends React.Component {
    state = {
        geoDataSelectedLanguage: null,
        latitudeText: "",
        longitudeText: ""
    }

    /**
     * Sets the preselected language for
     * the geo data when the component mounted
     */
    componentDidMount(){
        if(this.state.geoDataSelectedLanguage === null){
            let geo = (new Property(this.props.property)).getGeoData();
            let languageList = Object.keys(geo);
            if(languageList.length > 0){
                this.setState({geoDataSelectedLanguage: languageList[0]});
            }
        }

        /* Attach the handler for culture changes
            as this component uses localised text */
        Culture.attachCultureComponent(this);

        /* initialise latitude and longitude */
        let property = new Property(this.props.property);
        let location = property.getLocationCoordinates();
        if(location.latitude !== 0 && location.longitude !== 0){
            this.setState({
                latitudeText: location.latitude.toFixed(20),
                longitudeText: location.longitude.toFixed(20)
            });
        }
    }

    /**
     * Notifies the parent component of the update
     * to the property and provides the update property
     * to the parent component
     * 
     * @param {object} value
     * the updated property object 
     * 
     * @param {boolean} postImmediately
     * if true, will request to immediately update property
     */
    requestUpdateProperty(value,postImmediately){
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(value,postImmediately);
        }
    }

    /**
     * Renders the address field list
     */
    renderAddressFieldList(){
        /* get the property object */
        let property = new Property(this.props.property);
        let physicalAddress = property.getPhysicalAddress();
        return (
            <EditorSection title={Culture.getText('GENERAL_PROPERTY_LOCATION_ADDRESS_TITLE')}
                subtitle={Culture.getText('GENERAL_PROPERTY_LOCATION_ADDRESS_SUBTITLE')}
                helpText={Culture.getText('GENERAL_PROPERTY_LOCATION_ADDRESS_HELPTEXT')}>
                <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_PROPERTYNAME')} 
                    value={physicalAddress.propertyName} onChange={(value) => {
                        property.setPhysicalAddressField('propertyName',value);
                        this.requestUpdateProperty(property);
                    }} />

                <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_ADDRESSLINE')} 
                    value={physicalAddress.addressLine} onChange={(value) => {
                        property.setPhysicalAddressField('addressLine',value);
                        this.requestUpdateProperty(property);
                    }} />

                <div className="PropertyLocationCity">
                    <DataInputField 
                        className="PropertyLocationCityName"
                        title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_CITYNAME')} 
                        value={physicalAddress.cityName} onChange={(value) => {
                            property.setPhysicalAddressField('cityName',value);
                            this.requestUpdateProperty(property);
                        }} />

                    <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_STATE')} 
                        value={physicalAddress.state} onChange={(value) => {
                            property.setPhysicalAddressField('state',value);
                            this.requestUpdateProperty(property);
                        }} />

                    <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_POSTALCODE')} 
                        value={physicalAddress.postalCode} onChange={(value) => {
                            property.setPhysicalAddressField('postalCode',value);
                            this.requestUpdateProperty(property);
                        }} />
                </div>

                <CountrySelectList title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_COUNTRYCODE')} 
                    value={physicalAddress.countryCode} onChange={(value) => {
                        property.setPhysicalAddressField('countryCode',value);
                        this.requestUpdateProperty(property);
                    }}/>

                <DataInputCheckBox checked={(property.geoOverrideAddress === true)}
                    title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_OVERRIDE_GEOADDRESS')}
                    onClick={() => {
                        let overrideFlag = property.geoOverrideAddress === true ? false : true;
                        property.geoOverrideAddress = overrideFlag;
                        this.requestUpdateProperty(property);
                    }} />
            </EditorSection>
        );
    }

    /**
     * Defines the active language code for
     * the current display of the geo data
     * 
     * @param {string} languageCode
     * the language code to display geo data in 
     */
    changeGeoDataLanguage(languageCode){
        this.setState({geoDataSelectedLanguage: languageCode});
    }

    /**
     * Renders the read-only display of
     * the automated geo data from refinement
     */
    renderGeoData(){
        /* get the property from the props */
        let property = new Property(this.props.property);
        let geo = property.getGeoData(); 

        /* render all languages available for this geo data */
        let languageOptionList = [];
        Object.keys(geo).forEach((languageCode) => {
            let classList = ["GeoLanguage"];
            if(this.state.geoDataSelectedLanguage === languageCode){
                classList.push("GeoLanguageSelected");
            }

            languageOptionList.push(
                <div key={languageCode} className={classList.join(' ')} 
                    onClick={this.changeGeoDataLanguage.bind(this,languageCode)}>    
                    {Culture.getText('OTA_LANGUAGE_NAME_'+languageCode.toUpperCase())}
                </div>
            );
        });

        /* render all the available fields for this language */
        let fieldComponentList = [];
        let mainComponentList = [];
        if(geo.hasOwnProperty(this.state.geoDataSelectedLanguage)){
            let languageGeoData = geo[this.state.geoDataSelectedLanguage];
            Object.keys(languageGeoData).forEach((dataItem) => {
                if(dataItem.toLowerCase() !== 'place_id'){
                    let value = languageGeoData[dataItem];
                    if(typeof value === 'object'){
                        if(dataItem === 'location'){
                            value = (value.lat + ', ' + value.lng);
                        }else{
                            value = JSON.stringify(value);
                        }
                    }
    
                    if(['location','formatted_address'].includes(dataItem)){
                        mainComponentList.push(
                            <div key={dataItem} className="GeoDataListItem">
                                <div className="GeoDataListItemTitle">
                                    {Culture.getText("GEODATA_" + dataItem.toUpperCase())}
                                </div>
                                <div className="GeoDataListItemValue">
                                    {value}
                                </div>
                            </div>
                        );
                    }else{
                        if(dataItem !== 'language'){
                            /* check if the user is allowed to block
                                this item from the geo data of the
                                property */
                            let allowBlock = (dataItem.toLowerCase() !== 'country' 
                                && GeoHierarchyList.includes(dataItem.toLowerCase()) === true);

                            /* check if this geodata record is already blocked */
                            let isBlocked = false;
                            let geoDataBlockToggleText = Culture.getText('PROPERTY_GEODATA_BLOCK');
                            if(Array.isArray(this.props.property.geoBlockList)){
                                isBlocked = (this.props.property.geoBlockList.includes([
                                    dataItem.toLowerCase(),
                                    value
                                ].join(':')) === true);
                                if(isBlocked === true){
                                    geoDataBlockToggleText = Culture.getText('PROPERTY_GEODATA_UNBLOCK');
                                }
                            }

                            fieldComponentList.push(
                                <div key={dataItem} className="GeoDataListItem" data-isblocked={isBlocked}>
                                    <div className="GeoDataListItemTitle">
                                        {Culture.getText("GEODATA_" + dataItem.toUpperCase())}
                                    </div>
                                    <div className="GeoDataListItemValue">
                                        {value}
                                    </div>

                                    {allowBlock === true &&
                                        <div>
                                            <div className="GeoDataListItemBlockToggle" 
                                                data-isblocked={isBlocked}
                                                onClick={(function(geoItem,geoItemValue){
                                                    let propertyObject = this.props.property;
                                                    if(!Array.isArray(propertyObject.geoBlockList)){
                                                        propertyObject.geoBlockList = [];
                                                    }

                                                    let geoBlockKey = [ geoItem, geoItemValue ].join(':');
                                                    if(propertyObject.geoBlockList.includes(geoBlockKey)){
                                                        /* is blocked, re-enable it by removing it from
                                                            the block list (the array) */
                                                        propertyObject.geoBlockList = propertyObject.geoBlockList.filter(e => e !== geoBlockKey);
                                                    }else{
                                                        /* not blocked, add it to the list */
                                                        propertyObject.geoBlockList.push(geoBlockKey);
    
                                                    }

                                                    if(typeof this.props.onPropertyUpdated === 'function'){
                                                        this.props.onPropertyUpdated(propertyObject);
                                                    }
                                                }).bind(this,dataItem.toLowerCase(),value)}>
                                                {geoDataBlockToggleText}
                                            </div>
                                        </div>
                                    }
                                </div>
                            );
                        }
                    }
                }
            });
        }

        /* determine whether the geo data exists or not as it
            might take time for the system to process geo data */
        let geoDataExists = true;
        if(Object.keys(geo).length === 0 || typeof geo !== 'object' || geo === null){
            geoDataExists = false;
        }

        return (
            <section code="geodata" name={Culture.getText('GENERAL_PROPERTY_GEODATA')}>
                {geoDataExists === true &&
                    <EditorSection title={Culture.getText('GENERAL_PROPERTY_GEODATA_RESET_TITLE')}
                        subtitle={Culture.getText('GENERAL_PROPERTY_GEODATA_RESET_SUBTITLE')}
                        helpText={Culture.getText('GENERAL_PROPERTY_GEODATA_RESET_HELPTEXT')}
                        introText={Culture.getText('GENERAL_PROPERTY_GEODATA_RESET_INTROTEXT')}>
                        {property.geoResetRequested !== true &&
                            <div>
                                <div className="GeoDataManualResetButton" onClick={() => {
                                    /* set the flag and set the reset request marker */
                                    property.geoResetRequested = true;
                                    this.requestUpdateProperty(property,true);
                                }}>
                                    {Culture.getText('PROPERTY_GEODATA_MANUALRESET_BUTTON')}
                                </div>
                            </div>
                        }

                        {property.geoResetRequested === true &&
                            <div className="GeoDataManualReset">
                                <div className="GeoDataManualResetText">
                                    {Culture.getText('PROPERTY_GEODATA_MANUALRESET_INPROGRESS_TEXT')}
                                </div>
                            </div>
                        }
                    </EditorSection>
                }

                {geoDataExists === false &&
                    <EditorSection title={Culture.getText('PROPERTY_GEODATA_UNAVAILABLE_TITLE')}
                        subtitle={Culture.getText('PROPERTY_GEODATA_UNAVAILABLE_SUBTITLE')}
                        helpText={Culture.getText('PROPERTY_GEODATA_UNAVAILABLE_HELPTEXT')}
                        introText={Culture.getText('PROPERTY_GEODATA_UNAVAILABLE_INTROTEXT')}>
                    </EditorSection>
                }

                {geoDataExists === true &&
                    <EditorSection title={Culture.getText('GENERAL_PROPERTY_GEODATA_TITLE')}
                        subtitle={Culture.getText('GENERAL_PROPERTY_GEODATA_SUBTITLE')}
                        helpText={Culture.getText('GENERAL_PROPERTY_GEODATA_HELPTEXT')}>
                        <div className="GeoDataLanguageList">
                            {languageOptionList}
                        </div>
                        <div className="GeoDataList" data-type="main">
                            {mainComponentList}
                        </div>
                    </EditorSection>
                }

                {geoDataExists === true &&
                    <EditorSection title={Culture.getText('GENERAL_PROPERTY_GEODATA_COMPONENT_TITLE')}
                        subtitle={Culture.getText('GENERAL_PROPERTY_GEODATA_COMPONENT_SUBTITLE')}
                        helpText={Culture.getText('GENERAL_PROPERTY_GEODATA_COMPONENT_HELPTEXT')}>
                        <div className="GeoDataList">
                            {fieldComponentList}
                        </div>
                    </EditorSection>
                }
            </section>
        );
    }

    /**
     * Renders the component itself
     */
    render(){
        /* get the property from the props */
        let property = new Property(this.props.property);

        return(
            <div className="InventoryEditorSectionContent">
                <SubNavigationList>
                    <section code="location" name={Culture.getText('GENERAL_PROPERTY_LOCATION')}>
                        {this.renderAddressFieldList()}

                        <EditorSection title={Culture.getText('GENERAL_PROPERTY_LOCATION_COORDINATES_TITLE')}
                            subtitle={Culture.getText('GENERAL_PROPERTY_LOCATION_COORDINATES_SUBTITLE')}
                            helpText={Culture.getText('GENERAL_PROPERTY_LOCATION_COORDINATES_HELPTEXT')}>
                            <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_LOCATION_LATITUDE')} 
                                value={this.state.latitudeText} onChange={(value) => {
                                this.setState({latitudeText: value.replace(/([\\,])/gmi,'.').replace(new RegExp("[^0-9\\.-]"),'')});

                                /* update the latitude of this property */
                                let numberValue = parseFloat(value);
                                if(!isNaN(numberValue)){
                                    property.setLocationCoordinates(numberValue,null);
                                    this.requestUpdateProperty(property);
                                }
                            }} />

                            <DataInputField title={Culture.getText('DATAFIELD_PROPERTY_LOCATION_LONGITUDE')} 
                                value={this.state.longitudeText} onChange={(value) => {
                                this.setState({longitudeText: value.replace(/([\\,])/gmi,'.').replace(new RegExp("[^0-9\\.-]"),'')});

                                /* update the longitude of this property */
                                let numberValue = parseFloat(value);
                                if(!isNaN(numberValue)){
                                    property.setLocationCoordinates(null,numberValue);
                                    this.requestUpdateProperty(property);
                                }
                            }} />

                            <DataInputCheckBox checked={(property.geoOverrideCoordinate === true)}
                                title={Culture.getText('DATAFIELD_PROPERTY_ADDRESS_OVERRIDE_GEOCOORDINATES')}
                                onClick={() => {
                                    let overrideFlag = property.geoOverrideCoordinate === true ? false : true;
                                    property.geoOverrideCoordinate = overrideFlag;
                                    this.requestUpdateProperty(property);
                                }} />
                        </EditorSection>
                    </section>
                    <section code="area" name={Culture.getText('GENERAL_PROPERTY_SURROUNDINGS')}>
                        <EditorSection title={Culture.getText('GENERAL_PROPERTY_LOCATION_POILIST_TITLE')}
                                subtitle={Culture.getText('GENERAL_PROPERTY_LOCATION_POILIST_SUBTITLE')}
                                helpText={Culture.getText('GENERAL_PROPERTY_LOCATION_POILIST_HELPTEXT')}>
                                <AttractionEditor property={property} 
                                    onPropertyUpdated={this.requestUpdateProperty.bind(this)} />
                        </EditorSection>
                    </section>
                    <section code="contact" name={Culture.getText('GENERAL_PROPERTY_LOCATION_CONTACT')}>
                        <EditorSection title={Culture.getText('GENERAL_PROPERTY_LOCATION_CONTACT_TITLE')}
                                subtitle={Culture.getText('GENERAL_PROPERTY_LOCATION_CONTACT_SUBTITLE')}
                                helpText={Culture.getText('GENERAL_PROPERTY_LOCATION_CONTACT_HELPTEXT')}>
                                <ContactEditor property={property}
                                    onPropertyUpdated={this.requestUpdateProperty.bind(this)} />
                        </EditorSection>
                    </section>
                    {this.renderGeoData()}
                </SubNavigationList>
            </div>
        );
    }
}

export default LocationEditor;