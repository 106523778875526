/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import '../../style/InventoryVersionEditor.css';

/**
 * The version editor component loads and shows
 * the version list for the property provided in
 * the props and allows to select a previous version
 */
class InventoryVersionEditor extends React.Component {
    state = {
        listLoaded: false,
        versionList: [],
        currentVersionId: ""
    }

    /**
     * Mounts the component and starts
     * the initial loading of the version
     * list from the api
     */
    componentDidMount(){
        this.fetchVersionList();
    }

    /**
     * Fetches the version list from
     * the api and updates the state
     * with the version list
     */
    async fetchVersionList(){
        /* fetch the version list from the api */
        let response = await ApiClient.execute('/inventory/property/version/list',{
            propertyCode: this.props.property.code
        });

        /* update the state with the version list */
        if(response !== null){
            if(Array.isArray(response.versionList)){
                this.setState({listLoaded: true, versionList: response.versionList});
            }else{
                this.setState({listLoaded: true, versionList: []});
            }
        }

        /* get the version list and determine the active version */
        let list = this.state.versionList;
        let currentVersionId = "";
        if(typeof this.props.currentVersionId === 'string'
            && this.props.currentVersionId !== ""){
            /* use the version provided in the props */
            currentVersionId = this.props.currentVersionId;
        }else{
            /* if no version is set, then use the first one */
            if(list.length > 0){
                currentVersionId = list[0].versionId;
            }
        }

        /* update the set current version in the state */
        this.setState({currentVersionId: currentVersionId});
    }

    /**
     * Notifies the parent to load the
     * version selected and reload the
     * property object
     */
    selectVersion(){
        if(typeof this.props.onVersionSelected === 'function'){
            this.props.onVersionSelected(this.state.currentVersionId);
        }
    }

    /**
     * Renders the version list from
     * the state and returns the components
     */
    renderList(){
        let result = [];

        /* render the version list */
        let list = this.state.versionList;
        if(Array.isArray(list)){
            list.forEach((version) => {
                /* determine whether this version is the one
                    currently used by the user */
                let classList = ['InventoryVersionItem'];
                if(version.versionId === this.props.selectedVersionId){
                    classList.push('InventoryVersionItemActive');
                }

                result.push(
                    <div key={version.versionId} className={classList.join(' ')} 
                        onClick={(function(selectedVersionId){
                            if(typeof this.props.onVersionSelected === 'function'){
                                this.props.onVersionSelected(selectedVersionId);
                            }
                        }).bind(this,version.versionId)}>
                        <div className="InventoryVersionItemInfo">
                            <div className="InventoryVersionItemDate InventoryVersionItemField">
                                <div className="InventoryVersionItemValue">
                                    {(new Date(version.lastModified)).toLocaleString(Culture.getCultureCode())}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }

        return result;
    }

    /**
     * Renders the component with the
     * header, the status indicator and
     * the list once it is loaded
     */
    render(){
        return(
            <div className="InventoryVersionEditor">
                <div className="InventoryVersionEditorList">
                    {this.state.listLoaded === false &&
                        <div className="InventoryVersionEditorLoadingInfo">
                            <div className="InventoryVersionEditorLoadingText">
                                {Culture.getText('PROPERTY_VERSION_LOADING')}
                            </div>
                            <div className="InventoryVersionEditorLoadingProgressBar"></div>
                        </div>
                    }

                    {this.state.listLoaded === true &&
                        <div className="InventoryVersionList">
                            {this.renderList()}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default InventoryVersionEditor;