/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import { GoogleMap, InfoWindowF, LoadScript, MarkerF, Polygon } from "@react-google-maps/api";

/* require the classes for this component */
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';
import MarketplaceProperty from '../../class/MarketplaceProperty';

/* require the components to be used in this component */
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

/* include the style for this component */
import '../../style/MarketplaceGeofenceEditor.css'

/* require the configuration files */
const GoogleMapsConfig = require('../../config/GoogleMapsConfig.json');

/* define the constants for this component */
const POLYGON_COLOR = '#6391fe';

/**
 * Provides the editor to create geofences and
 * query properties inside the geofence
 */
class MarketplaceGeofenceEditor extends React.Component {
    state = {
        polygonPath: [],
        mapZoom: 5,
        mapCenter: {
            lat: 50.958436137098964,
            lng: 10.46329609837306
        },
        propertyList: [],
        propertyListUpdating: false,
        propertyUpdateTime: 0,
        selectedPropertyCode: ''
    }

    /**
     * Constructs the component and sets
     * up the required reference for the map
     * 
     * @param {object} props
     * props to pass to the base class 
     */
    constructor(props) {
        super(props);
        this.googleMap = React.createRef();
        this.mapInstance = null;
        this.polygonInstance = null;
    }

    /**
     * Set the polygon path if any was provided
     * in the value prop and update the map
     */
    componentDidMount(){
        /* set the polygon if provided in the props */
        if((this.props.value || []).length > 0){
            setTimeout(this.setPolygonFromProp.bind(this),200);
        }
    }

    /**
     * Updates the polygon on the map with the
     * existing polygon provided in the value prop
     */
    setPolygonFromProp(){
        if (typeof this.mapInstance === 'object' && this.mapInstance !== null){
            if((this.props.value || []).length > 0){
                let polygonArray = [];
                for(const pointArray of this.props.value){
                    polygonArray.push({
                        lat: pointArray[1],
                        lng: pointArray[0]
                    });
                }
                this.setState({polygonPath: polygonArray});
            }
        }else{
            setTimeout(this.setPolygonFromProp.bind(this),200);
        }
    }

    /**
     * Updates the property list within the
     * bounds of the current map and sets
     * them locally
     */
    async updatePropertyList() {
        /* determine the bounds of the current map */
        if (typeof this.mapInstance === 'object'
            && this.mapInstance !== null
            && this.state.propertyListUpdating === false) {
            /* do not load properties when not zoomed close enough */
            let currentZoom = this.mapInstance.getZoom();
            if (currentZoom >= 8) {
                let updateTs = (new Date()).getTime();
                this.setState({ propertyListUpdating: true, propertyUpdateTime: updateTs });

                const northEast = this.mapInstance.getBounds().getNorthEast();
                const southWest = this.mapInstance.getBounds().getSouthWest();
                const geoFilter = [
                    [southWest.lng(), northEast.lat()],
                    [southWest.lng(), southWest.lat()],
                    [northEast.lng(), southWest.lat()],
                    [northEast.lng(), northEast.lat()],
                    [southWest.lng(), northEast.lat()]
                ];

                /* fetch the property list from the api */
                let apiResult = await ApiClient.execute('/market/search', {
                    geo: geoFilter,
                    limit: 5000
                });

                let propertyList = [];
                if (typeof apiResult === 'object' && apiResult !== null) {
                    if (Array.isArray(apiResult.list)) {
                        propertyList = apiResult.list;
                    }
                }

                if (this.state.propertyUpdateTime === updateTs) {
                    this.setState({
                        propertyList: propertyList,
                        propertyListUpdating: false
                    });
                }
            }
        }
    }

    /**
     * Sets the map instance when the map
     * loaded and mounted properly to access
     * the maps methods
     * 
     * @param {object} value
     * the mounted map instance to set 
     */
    setMapInstance(value) {
        this.mapInstance = value;
        setTimeout(this.updatePropertyList.bind(this), 1000);
    }

    /**
     * Sets the polygon instance when the
     * polygon loaded and mounted properly
     * 
     * @param {object} value
     * the mounted polygon instance to set 
     */
    setPolygonInstance(value) {
        this.polygonInstance = value;
    }

    /**
     * Returns the currently created polygon
     * or false if no polygon is created
     */
    getPolygonCoordinateList() {
        let result = false;

        if (this.state.polygonPath.length > 0) {
            result = this.state.polygonPath;
        }

        return result;
    }

    /**
     * Inserts a new polygon within the
     * current bounds of the map
     */
    insertPolygon() {
        if (this.mapInstance !== null) {
            /* zoom in, get the bounds and zoom back out */
            let currentZoom = this.mapInstance.getZoom();
            if(currentZoom === 22){ currentZoom = 21; }
            this.mapInstance.setZoom(currentZoom + 1);

            const northEast = this.mapInstance.getBounds().getNorthEast();
            const southWest = this.mapInstance.getBounds().getSouthWest();

            /* zoom out into the initial zoom factor */
            this.mapInstance.setZoom(currentZoom);

            const polygonPath = [
                { lat: northEast.lat(), lng: southWest.lng() },
                { lat: southWest.lat(), lng: southWest.lng() },
                { lat: southWest.lat(), lng: northEast.lng() },
                { lat: northEast.lat(), lng: northEast.lng() }
            ];
            this.setState({ polygonPath });
        }
    }

    /**
     * Returns the geofence array in the 
     * format as required by the filter
     */
    getGeoFenceList() {
        let result = [];

        if (this.polygonInstance !== null && this.state.polygonPath.length > 0) {
            const polygonList = this.polygonInstance.getPath().getArray();
            if (polygonList.length > 0) {
                for (const coordinate of polygonList) {
                    result.push([
                        coordinate.lng(),
                        coordinate.lat()
                    ]);
                }

                /* add the first point to close the polygon */
                result.push(result[0]);
            }
        }

        return result;
    }

    /**
     * Updates the polygon in the state when
     * it was edited by the user on the map
     */
    notifyPolygonUpdate() {
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(this.getGeoFenceList());
        }
    }

    /**
     * Selects a specific property on
     * the map to show the info window
     * 
     * @param {string} code
     * code of the property to show 
     */
    selectProperty(code) {
        this.setState({ selectedPropertyCode: code });
    }

    /**
     * Returns true when the provided latitude
     * and longitude is inside the provided polygon
     * 
     * @param {number} latitude 
     * latitude of the point to check
     * 
     * @param {number} longitude 
     * longitude of the point to check
     * 
     * @param {array} polygon 
     * the polygon to check
     */
    isInsidePolygon (latitude, longitude, polygon) {
        let result = false;

        if (typeof latitude !== 'number' || typeof longitude !== 'number') {
            throw new TypeError('Invalid latitude or longitude. Numbers are expected')
        } else if (!polygon || !Array.isArray(polygon)) {
            throw new TypeError('Invalid polygon. Array with locations expected')
        } else if (polygon.length === 0) {
            throw new TypeError('Invalid polygon. Non-empty Array expected')
        }
      
        const x = latitude; const y = longitude
      
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
          const xi = polygon[i][0]; const yi = polygon[i][1]
          const xj = polygon[j][0]; const yj = polygon[j][1]
      
          const intersect = ((yi > y) !== (yj > y)) &&
                  (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
          if (intersect) result = !result
        }
      
        return result;
    };

    /**
     * Returns the text with the number of
     * properties in the view and within the
     * polygon selection or an empty string
     * when none is available
     */
    getPropertyCountText(){
        let result = Culture.getText('MARKETPLACE_GEOFENCEDITOR_ZOOMIN');

        if (this.mapInstance !== null) {
            let currentZoom = this.mapInstance.getZoom();
            if (currentZoom >= 8) {
                /* determine the number of properties inside the view
                    and the number of properties inside the polygon */
                const northEast = this.mapInstance.getBounds().getNorthEast();
                const southWest = this.mapInstance.getBounds().getSouthWest();
                const currentViewPolygon = [
                    [northEast.lat(), southWest.lng()],
                    [southWest.lat(), southWest.lng()],
                    [southWest.lat(), northEast.lng()],
                    [northEast.lat(), northEast.lng()]
                ];
    
                const currentPolygon = [];
                if (this.polygonInstance !== null){
                    const currentPolygonPath = this.polygonInstance.getPath().getArray();
                    for(const polygonPoint of currentPolygonPath){
                        currentPolygon.push([
                            polygonPoint.lat(),
                            polygonPoint.lng()
                        ]);
                    }
                }
    
                let propertyInViewCount = 0;
                let propertyInPolygonCount = 0;
                for (const item of this.state.propertyList) {
                    let property = new MarketplaceProperty(item);
                    let location = property.getLatLng();
                    if(this.isInsidePolygon(location.lat,location.lng,currentViewPolygon)){
                        propertyInViewCount++;
                    }
    
                    if(currentPolygon.length > 0 && this.state.polygonPath.length > 0){
                        if(this.isInsidePolygon(location.lat,location.lng,currentPolygon)){
                            propertyInPolygonCount++;
                        }
                    }
                }
    
                if(propertyInViewCount > 0){
                    result = propertyInPolygonCount + ' / ' + propertyInViewCount;
                }
            }
        }

        return result;
    }

    /**
     * Renders all markers for the property
     * list and returns them to be rendered
     * on the map
     */
    renderPropertyList() {
        let result = [];

        for (const item of this.state.propertyList) {
            let property = new MarketplaceProperty(item);
            let thumbnailUrl = property.getThumbnailImageUrl();

            result.push(
                <MarkerF key={property.getCode()}
                    position={property.getLatLng()}
                    icon="/img/maps/marker.png"
                    onClick={this.selectProperty.bind(this, property.getCode())}>
                    {property.getCode() === this.state.selectedPropertyCode &&
                        <InfoWindowF position={property.getLatLng()}>
                            <div className="MarketplaceGeofenceEditorPropertyView">
                                {thumbnailUrl !== '' &&
                                    <img src={thumbnailUrl} alt={property.getName()} />
                                }

                                <div className="MarketplaceGeofenceEditorPropertyViewName">
                                    {property.getName()}
                                </div>
                            </div>
                        </InfoWindowF>
                    }
                </MarkerF>
            )
        }

        return result;
    }

    /**
     * Renders the search input controls that
     * allow to search for properties and apply
     * the desired filters for the search
     */
    render() {
        /* get the coordinates of the polygon */
        let polygonCoordList = this.getPolygonCoordinateList();

        /* render the map with the polygon */
        return (
            <ModalDrawerOverlay className="MarketplaceGeofenceEditor"
                onClose={this.props.onClose}
                titleText={Culture.getText('MARKETPLACE_GEOFENCEEDITOR_TITLE')}
                subtitleText={Culture.getText('MARKETPLACE_GEOFENCEEDITOR_TEXT')}
                submitButtonText={Culture.getText('MARKETPLACE_GEOFENCEDITOR_APPLY')}
                onSubmit={this.notifyPolygonUpdate.bind(this)}>
                <div className="MarketplaceGeofenceEditorToolbar">
                    {polygonCoordList !== false &&
                        <div onClick={() => this.setState({ polygonPath: [] })}
                            className="MarketplaceGeofenceEditorToolbarAction"
                            data-actiontype="removepolygon">
                            {Culture.getText('MARKETPLACE_GEOFENCEDITOR_REMOVEPOLYGON')}
                        </div>
                    }

                    {polygonCoordList === false &&
                        <div onClick={this.insertPolygon.bind(this)}
                            className="MarketplaceGeofenceEditorToolbarAction"
                            data-actiontype="insertpolygon">
                            {Culture.getText('MARKETPLACE_GEOFENCEDITOR_INSERTPOLYGON')}
                        </div>
                    }

                    <div className="MarketplaceGeofenceEditorToolbarPropertyCount">
                        {this.getPropertyCountText()}
                    </div>
                </div>
                <LoadScript googleMapsApiKey={GoogleMapsConfig.apiKey}>
                    <GoogleMap ref={this.googleMap}
                        onLoad={this.setMapInstance.bind(this)}
                        onCenterChanged={this.updatePropertyList.bind(this)}
                        onZoomChanged={this.updatePropertyList.bind(this)}
                        zoom={this.state.mapZoom}
                        center={this.state.mapCenter}
                        options={{
                            streetViewControl: false,
                            styles: [{
                                featureType: 'poi',
                                elementType: 'labels.icon',
                                stylers: [{
                                    visibility: 'off'
                                }]
                            }]
                        }}
                        mapContainerClassName="MarketplaceGeofenceEditorMapContainer">
                        {this.renderPropertyList()}

                        {polygonCoordList !== false &&
                            <Polygon path={polygonCoordList}
                                draggable={true}
                                editable={true}
                                onMouseUp={this.updatePropertyList.bind(this)}
                                onLoad={this.setPolygonInstance.bind(this)}
                                options={{
                                    fillColor: POLYGON_COLOR,
                                    fillOpacity: 0.4,
                                    strokeColor: POLYGON_COLOR,
                                    strokeOpacity: 0.8,
                                    strokeWeight: 3
                                }} />
                        }
                    </GoogleMap>
                </LoadScript>
            </ModalDrawerOverlay>
        );
    }
}

export default MarketplaceGeofenceEditor;