/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the core classes for this component */
import ApiClient from '../../class/ApiClient';

/* require the components for this view */
import MarketplaceSearchInput from './MarketplaceSearchInput';
import MarketplaceList from './MarketplaceList';
import Culture from '../../class/Culture';
import MarketplaceImportInput from './MarketplaceImportInput';

const RESULTPAGE_SIZE = 20;

/**
 * The Marketplace view handles the search and filter
 * operations for the marketplace and allows users to
 * manage the import of properties
 */
class MarketplaceView extends React.Component {
    state = {
        searchQuery: {},
        resultList: [],
        totalResultCount: -1,
        isKeywordSearch: true,
        isSearchLoading: false,
        hasMore: false,
        searchTimestamp: 0,
        searchRequestId: 0
    }

    /**
     * Initialises the component when it is
     * first mounted by executing the search
     * request defined in the state
     */
    componentDidMount(){
        this.search(0);
    }

    /**
     * Executes the search with the provided
     * query and updates the list in the state
     * 
     * @param {string} searchRequestId
     * unique id of the search request to avoid
     * executing outdated request when typing
     */
    async search(searchRequestId){
        if(searchRequestId === this.state.searchRequestId){
            const searchTimestamp = (new Date()).getTime();
            const resultCount = this.state.totalResultCount;
            this.setState({ 
                isSearchLoading: true,
                searchTimestamp: searchTimestamp,
                totalResultCount: -1
            });

            let query = this.state.searchQuery || {};
            let apiResult = await ApiClient.execute('/market/search',query);
            if (typeof apiResult === 'object' && apiResult !== null) {
                if (Array.isArray(apiResult.list)) {
                    let queryOffset = query.offset || 0;
                    if (queryOffset === 0) {
                        /* ensure the timestamp matches to use the latest
                            search query when a race condition occurs between
                            the different queries */
                        if(this.state.searchTimestamp === searchTimestamp){
                            this.setState({ 
                                resultList: apiResult.list, 
                                isSearchLoading: false,
                                hasMore: (apiResult.list.length === RESULTPAGE_SIZE)
                            });

                            if(apiResult.list.length >= RESULTPAGE_SIZE){
                                this.getResultCount();
                            }else{
                                this.setState({totalResultCount: apiResult.list.length});
                            }
                        }
                    }else{
                        this.setState({ 
                            resultList: this.state.resultList.concat(apiResult.list), 
                            isSearchLoading: false,
                            hasMore: (apiResult.list.length >= RESULTPAGE_SIZE),
                            totalResultCount: resultCount
                        });
                    }
                }
            }
        }
    }

    /**
     * Determines the total number of properties
     * matching the search criteria and sets them
     * locally
     */
    async getResultCount(){
        let searchQuery = Object.assign({}, (this.state.searchQuery || {}));
        searchQuery.countOnly = true;
        let apiResult = await ApiClient.execute('/market/search',searchQuery);
        if (typeof apiResult === 'object' && apiResult !== null) {
            if(isNaN(Number(apiResult.count)) === false){
                this.setState({
                    totalResultCount: Number(apiResult.count)
                });
            }
        }
    }

    /**
     * Loads more properties form the
     * api by adding the offset to the
     * request and executing the search
     */
    showMore(){
        let query = this.state.searchQuery || {};
        query.offset = this.state.resultList.length;

        let searchRequestId = String((new Date()).getTime());
        this.setState({
            isKeywordSearch: false,
            searchQuery: query,
            searchRequestId: searchRequestId 
        },this.search.bind(this,searchRequestId));
    }

    /**
     * Renders the component with the search input
     * and the search result list with the properties
     */
    render() {
        return(
            <div className="MarketplaceView">
                <MarketplaceImportInput 
                    totalPropertyCount={this.state.totalResultCount}
                    filterQuery={this.state.searchQuery}
                    onUpdate={(value) => {
                        let searchRequestId = String((new Date()).getTime());
                        let queryObject = Object.assign({},value);
                        delete queryObject.limit;
                        delete queryObject.offset;
                        this.setState({ 
                            searchQuery: queryObject,
                            isKeywordSearch: true,
                            searchRequestId: searchRequestId
                        },(function(){
                            this.search(searchRequestId);
                        }).bind(this))
                    }} />
                <MarketplaceSearchInput 
                    value={this.state.searchQuery}
                    totalPropertyCount={this.state.totalResultCount}
                    isLoading={this.state.isKeywordSearch === true 
                                && this.state.isSearchLoading === true}
                    onUpdate={(value) => {
                        let searchRequestId = String((new Date()).getTime());
                        let queryObject = value;
                        delete queryObject.countOnly;
                        delete queryObject.offset;
                        this.setState({ 
                            searchRequestId: searchRequestId,
                            searchQuery: queryObject,
                            isKeywordSearch: true 
                        },(function(){
                            setTimeout(this.search.bind(this,searchRequestId),1000);
                        }).bind(this));
                    }} 
                />

                <MarketplaceList list={this.state.resultList} />

                {this.state.hasMore === true &&
                    <div className="MarketplaceViewMore">
                        <div className="MarketplaceViewMoreButton" onClick={this.showMore.bind(this)}>
                            {Culture.getText('MARKETPLACE_SEARCHMORE_BUTTON')}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default MarketplaceView;