/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import ModalOptionListSelect from '../common/ModalOptionListSelect';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DataInputPrice from '../common/DataInputPrice';
import OperationTimeInput from '../common/OperationTimeInput';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

import '../../style/ServiceEditor.css';

/**
 * This component allows to edit the services
 * of a property, adding, removed and editing them
 */
class ServiceEditor extends React.Component {
    state = {
        showMultiCreateWindow: false,
        showCreateWindow: false,
        isEditMode: false,
        serviceType: 173,
        serviceIncluded: true,
        serviceFee: {value: 0, currencyCode: "EUR"},
        defaultCurrencyCode: "EUR",
        operationTimeList: [],
        featureList: [],
        typeList: [],
        itemList: []
    }

    /**
     * Sets the default values from the 
     * props when the component is mounted
     */
    componentDidMount(){
        /* check if a default currency code was provided */
        if(typeof this.props.defaultCurrencyCode === 'string'){
            let serviceFeeObject = this.state.serviceFee;
            serviceFeeObject.currencyCode = this.props.defaultCurrencyCode;
            this.setState({
                serviceFee: serviceFeeObject,
                defaultCurrencyCode: this.props.defaultCurrencyCode
            });
        }
    }

    /**
     * Toggles a list item of a list such as
     * the dietary options in the state
     * 
     * @param {string} listName
     * name of the list property in the state
     *  
     * @param {string} item
     * name of the item to toggle in the list 
     */
    toggleListItem(listName,item){
        /* toggle the item in the state's code list */
        let updatedList = [];
        if(this.state[listName].includes(item.value)){
            this.state[listName].forEach((code) => {
                if(item.value !== code){
                    updatedList.push(code);
                }
            });
        }else{
            /* add the new code item to the list */
            updatedList = this.state[listName];
            updatedList.push(item.value);
        }

        /* update the cuisine code list in the state */
        this.setState({[listName]: updatedList});
    }

    /**
     * Sets the content of the service to edit in 
     * the state and shows the editor
     * 
     * @param {number} serviceCode
     * code of the service to show editor for 
     */
    showEditService(serviceCode){
        let stateObject = {
            showMultiCreateWindow: false,
            showCreateWindow: true,
            isEditMode: true,
            serviceType: serviceCode,
            serviceIncluded: true,
            serviceFee: {value: 0, currencyCode: this.state.defaultCurrencyCode},
            operationTimeList: [],
            featureList: [],
            typeList: [],
            itemList: []
        };

        let serviceList = this.props.list;
        if(Array.isArray(serviceList)){
            serviceList.forEach((serviceItem) => {
                if(serviceItem.code === serviceCode){
                    /* set the inclusion information */
                    stateObject.serviceIncluded = serviceItem.included;

                    /* this is the item to edit, update the state */
                    stateObject.serviceFee = {
                        value: serviceItem.price,
                        currencyCode: serviceItem.currencyCode
                    };

                    /* set the operation time list */
                    stateObject.operationTimeList = serviceItem.operationTimeList;

                    /* add all the feature list codes */
                    serviceItem.featureList.forEach((featureItem) => {
                        /* note: the feature is not a number, but a string
                            and it does not use a 'code' prop, but the name */
                        stateObject.featureList.push(featureItem.name);
                    });

                    /* add all the type list codes */
                    serviceItem.typeList.forEach((typeItem) => {
                        stateObject.typeList.push(parseInt(typeItem.code));
                    });

                    /* add all the item list codes */
                    serviceItem.itemList.forEach((itemObject) => {
                        stateObject.itemList.push(parseInt(itemObject.code));
                    });

                    /* show the editor by setting the state */
                    this.setState(stateObject);
                }
            });
        }
    }

    /**
     * Returns the list of all applicable
     * service type option as an array
     */
    getServiceTypeList(){
        let result = [];

        let codeList = require('../../config/ota/OTAHotelAmenityCode.json');
        Object.keys(codeList).forEach((itemKey) => {
            let isChecked = false;
            if(parseInt(itemKey) === this.state.serviceType){
                isChecked = true;
            }

            result.push({
                value: itemKey,
                text: Culture.getText('PROPERTY_AMENITYNAME_'+itemKey),
                checked: isChecked
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Returns the available options for
     * the multiple option list
     */
    getMultipleServiceList(){
        let result = [];

        let codeList = require('../../config/ota/OTAHotelAmenityCode.json');
        Object.keys(codeList).forEach((itemKey) => {
            let isChecked = false;
            if(Array.isArray(this.props.list)){
                this.props.list.forEach((listItem) => {
                    if(parseInt(listItem.code) === parseInt(itemKey)){
                        isChecked = true;
                    }
                });
            }

            result.push({
                value: itemKey,
                text: Culture.getText('PROPERTY_AMENITYNAME_'+itemKey),
                checked: isChecked
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;        
    }

    /**
     * Returns the service name as 
     * specified in the OTA specs
     * 
     * @param {number} code
     * the code of the service from the specs 
     */
    getServiceNameFromSpec(code){
        let result = "";

        let codeList = require('../../config/ota/OTAHotelAmenityCode.json');
        Object.keys(codeList).forEach((itemKey) => {
            if(parseInt(itemKey) === parseInt(code)){
                result = codeList[itemKey];
            }
        });

        return result;
    }

    /**
     * Returns the breakfast type name
     * as defined in the specification
     * 
     * @param {number} code
     * the breakfast type code number 
     */
    getBreakfastTypeNameFromSpec(code){
        let result = "";

        let codeList = require('../../config/ota/OTABreakfastTypeCode.json');
        Object.keys(codeList).forEach((itemKey) => {
            if(parseInt(itemKey) === parseInt(code)){
                result = codeList[itemKey];
            }
        });

        return result;
    }

    /**
     * Returns the breakfast item name
     * as dedfined in the specification
     * 
     * @param {number} code
     * the breakfast item code number 
     */
    getBreakfastItemNameFromSpec(code){
        let result = "";

        let codeList = require('../../config/ota/OTABreakfastItemCode.json');
        Object.keys(codeList).forEach((itemKey) => {
            if(parseInt(itemKey) === parseInt(code)){
                result = codeList[itemKey];
            }
        });

        return result;
    }
    
    /**
     * Creates the service object for the
     * property, attaches it to the list
     * or updates the existing service in
     * the list and notifies the parent
     */
    addService(){
        /* construct the object as required for the service list */
        let serviceObject = {
            code: this.state.serviceType,
            name: this.getServiceNameFromSpec(this.state.serviceType),
            exists: true,
            included: this.state.serviceIncluded,
            price: this.state.serviceFee.value,
            currencyCode: this.state.serviceFee.currencyCode,
            operationTimeList: this.state.operationTimeList,
            featureList: [],
            typeList: [],
            itemList: []
        };

        /* add all the features, creating the proper object. 
            Note: this ignores the 'charge' for any feature
            as this does not really make any sense since the
            provided features aren't really anything that
            would be useful being charged */
        this.state.featureList.forEach((featureItem) => {
            /* just set the charge to 0 by default */
            serviceObject.featureList.push({
                name: featureItem, charge: 0
            });
        });

        /* add all the breakfast types */
        this.state.typeList.forEach((typeItem) => {
            serviceObject.typeList.push({
                code: typeItem,
                name: this.getBreakfastTypeNameFromSpec(typeItem)
            });
        });

        /* add all the breakfast items selected */
        this.state.itemList.forEach((itemCode) => {
            serviceObject.itemList.push({
                code: itemCode,
                name: this.getBreakfastItemNameFromSpec(itemCode)
            });
        });

        /* get the list and update it */
        let serviceList = this.props.list;
        if(Array.isArray(serviceList)){
            let serviceExists = false;
            let updatedList = [];
            serviceList.forEach((existService) => {
                if(parseInt(existService.code) === parseInt(serviceObject.code)){
                    /* replace with the existing service */
                    updatedList.push(serviceObject);
                    serviceExists = true;
                }else{
                    /* just keep any other services */
                    updatedList.push(existService);
                }
            });

            /* add the service when it did not exists already */
            if(!serviceExists){ updatedList.push(serviceObject); }
            serviceList = updatedList;
        }else{
            /* create a new list when none is provided */
            serviceList = [serviceObject];
        }

        /* notify the parent component */
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(serviceList);
        }    

        /* reset the state and close any window */
        this.setState({
            showMultiCreateWindow: false, showCreateWindow: false, isEditMode: false,
            serviceType: 173, serviceIncluded: true, serviceFee: {value: 0, currencyCode: this.state.defaultCurrencyCode},
            operationTimeList: [], featureList: [], typeList: [], itemList: []
        });
    }

    /**
     * Toggles an item from the service list,
     * updates the service list by removing or
     * adding the item and notifying the parent
     * 
     * @param {object} item
     * the item to toggle in the service list 
     */
    toggleServiceListItem(item){
        let list = this.props.list;
        if(!Array.isArray(list)){ list = []; }

        /* create the new default service object */
        let serviceObject = {
            code: parseInt(item.value),
            price: 0,
            exists: true,
            included: false,
            currencyCode: this.state.defaultCurrencyCode,
            featureList: [],
            typeList: [],
            itemList: [],
            operationTimeList: [],
            name: this.getServiceNameFromSpec(item.value)
        };

        let updatedList = [];
        let itemExists = false;
        list.forEach((listItem) => {
            if(parseInt(serviceObject.code) !== parseInt(listItem.code)){
                updatedList.push(listItem);
            }else{
                itemExists = true;
            }
        });

        if(!itemExists){ updatedList.push(serviceObject); }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(updatedList);
        }
    }

    /**
     * Returns the option list of all available
     * service features and the localised
     * name of each feature - these are equal
     * to the features of restaurants
     */
    getFeatureOptionList(){
        let featureList = ["a la carte", "buffet", "guests only", 
                    "accepts reservations", "outdoor seating"];

        let result = [];
        featureList.forEach((featureText) => {
            let localisedText = Culture.getText('RESTAURANT_FEATURENAME_' 
                        + featureText.replace(/ /gi,'_').toUpperCase());

            result.push({
                value: featureText,
                text: localisedText,
                checked: this.state.featureList.includes(featureText)
            });
        });

        return result;
    }

    /**
     * Returns all available breakfast type
     * option as an array of objects
     */
    getBreakfastTypeOptionList(){
        let result = [];

        let breakfastTypeList = require('../../config/ota/OTABreakfastTypeCode.json');
        Object.keys(breakfastTypeList).forEach((breakfastCode) => {
            result.push({
                value: parseInt(breakfastCode),
                text: Culture.getText('BREAKFAST_TYPENAME_'+breakfastCode),
                checked: this.state.typeList.includes(parseInt(breakfastCode))
            });
        });

        return result;
    }

    /**
     * Returns the option list with available
     * breakfast items to be served
     */
    getBreakfastItemList(){
        let result = [];

        let breakfastItemList = require('../../config/ota/OTABreakfastItemCode.json');
        Object.keys(breakfastItemList).forEach((itemCode) => {
            result.push({
                value: parseInt(itemCode),
                text: Culture.getText('BREAKFAST_ITEMNAME_'+itemCode),
                checked: this.state.itemList.includes(parseInt(itemCode))
            });
        });

        return result;
    }

    /**
     * Returns true when the provided service code
     * identifies a form of breakfast for which
     * breakfast-specific fields need to be provided
     */
    isBreakfastService(){
        let result = false;
        
        /* define the list of breakfast codes */
        let breakfastCodeList = [287,5000,173,249,157,324,250,
                                159,138,142,141,161,252,251];
        if(breakfastCodeList.includes(this.state.serviceType)){ result = true; }

        return result;
    }

    /**
     * Returns true when the provided service code
     * identifies a food and beverage service. If 
     * not this method will return false 
     */    
    isFoodService(){
        let result = false;

        let foodCodeList = [6001,175,150,151,143,6000,174,125,124,5099,
                            341,333,165,284,5119,342,72,330,85,337,340];

        let code = this.state.serviceType;
        result = this.isBreakfastService(code);
        if(foodCodeList.includes(code)){ result = true; }

        return result;
    }

    /**
     * Renders the list alongside the window
     * for adding an extended component if the
     * state requires the window to render
     */
    render(){
        let editWindowTitle = Culture.getText('PROPERTY_SERVICE_ADDEXTENDEDSERVICE_TITLE');
        let editWindowSubtitle = Culture.getText('PROPERTY_SERVICE_ADDEXTENDEDSERVICE_SUBTITLE');
        let editButtonTitle = Culture.getText('PROPERTY_SERVICE_ADDEXTENDEDSERVICE');
        if(this.state.isEditMode === true){
            editButtonTitle = Culture.getText('PROPERTY_SERVICE_UPDATESERVICE');
            editWindowTitle = Culture.getText('PROPERTY_SERVICE_UPDATESERVICE_TITLE');
            editWindowSubtitle = Culture.getText('PROPERTY_SERVICE_UPDATESERVICE_SUBTITLE');
        }

        return(
            <div className="ServiceEditor">
                <div className="ServiceEditorActionList">
                    <div className="ServiceEditorAction ServiceEditorAddServiceAction">
                        <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_SERVICE_ADDSERVICE')} 
                            title={Culture.getText('PROPERTY_SERVICE_SERVICELIST_TITLE')}
                            filterPlaceholderText={Culture.getText('PROPERTY_SERVICE_SEARCHSERVICE')}
                            emptyText={Culture.getText('PROPERTY_SERVICE_ADDSERVICE_EMPTY')} 
                            list={this.getMultipleServiceList()}
                            onToggle={this.toggleServiceListItem.bind(this)}
                            onSelectItem={(value) => this.showEditService(Number(value))} />
                    </div>
                    <div>
                        <div className="ServiceEditorAction ServiceEditorAddExtendedServiceAction" onClick={() => {
                            /* show the editor window and reset all the state variables */
                            this.setState({
                                showMultiCreateWindow: false, showCreateWindow: true, isEditMode: false,
                                serviceType: 173, serviceIncluded: true, serviceFee: {value: 0, currencyCode: this.state.defaultCurrencyCode},
                                operationTimeList: [], featureList: [], typeList: [], itemList: []
                            });
                        }}>
                            {Culture.getText('PROPERTY_SERVICE_ADDEXTENDEDSERVICE')}
                        </div>
                    </div>
                </div>

                {this.state.showCreateWindow === true &&
                    <ModalDrawerOverlay titleText={editWindowTitle} 
                        subtitleText={editWindowSubtitle}
                        onClose={() => this.setState({showCreateWindow: false})}
                        submitButtonText={editButtonTitle}
                        onSubmit={this.addService.bind(this)}>
                        <ModalOptionListSelect className="ServiceEditorServiceType"
                            title={Culture.getText('PROPERTY_SERVICE_SERVICETYPE')}
                            onToggle={(item) => { this.setState({serviceType: parseInt(item.value)}); }}
                            closeOnSelect={true} list={this.getServiceTypeList()}
                            addButtonText={Culture.getText('PROPERTY_SERVICE_CHANGE_SERVICETYPE')}
                            filterPlaceholderText={Culture.getText('PROPERTY_SERVICE_SEARCHSERVICE')}
                            transparentBackground={true} />

                        <DataInputCheckBox title={Culture.getText('PROPERTY_SERVICE_SERVICEINCLUDED')} 
                                checked={this.state.serviceIncluded} onClick={() => {
                                    this.setState({serviceIncluded: !this.state.serviceIncluded});
                        }} />

                        {this.state.serviceIncluded === false &&
                            <DataInputPrice title={Culture.getText('PROPERTY_SERVICE_SERVICEFEE')} 
                                disabled={this.state.serviceIncluded} value={this.state.serviceFee}
                                onChange={(value) => this.setState({serviceFee: value})} />
                        }

                        <OperationTimeInput list={this.state.operationTimeList}
                            title={Culture.getText('PROPERTY_SERVICE_OPERATIONTIME')}
                            onChange={(value) => this.setState({operationTimeList: value})} />

                        {this.isFoodService() === true &&
                            <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_SERVICE_ADDFEATURE')}
                                filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')} 
                                title={Culture.getText('PROPERTY_SERVICE_FEATURETYPE')}
                                emptyText={Culture.getText('PROPERTY_SERVICE_ADDFEATURE_EMPTY')}
                                list={this.getFeatureOptionList()}
                                onToggle={this.toggleListItem.bind(this,'featureList')}
                                transparentBackground={true} />
                        }

                        {this.isBreakfastService() === true &&
                            <div className="ServiceEditorBreakfastConfiguration">
                                <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_SERVICE_ADDBREAKFASTTYPE')} 
                                    filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                                    title={Culture.getText('PROPERTY_SERVICE_BREAKFASTTYPE')}
                                    emptyText={Culture.getText('PROPERTY_SERVICE_ADDBREAKFASTTYPE_EMPTY')}
                                    list={this.getBreakfastTypeOptionList()}
                                    onToggle={this.toggleListItem.bind(this,'typeList')}
                                    transparentBackground={true} />

                                <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_SERVICE_ADDBREAKFASTITEM')} 
                                    filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                                    title={Culture.getText('PROPERTY_SERVICE_BREAKFASTITEM')}
                                    emptyText={Culture.getText('PROPERTY_SERVICE_ADDBREAKFASTITEM_EMPTY')} 
                                    list={this.getBreakfastItemList()}
                                    onToggle={this.toggleListItem.bind(this,'itemList')}
                                    transparentBackground={true} />
                            </div>
                        }
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default ServiceEditor;