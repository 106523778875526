/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* Library that provides authentication through Microsoft Azure AD */
import { PublicClientApplication } from '@azure/msal-browser';

/* the config values for all authentication providers */
import AuthConfig from '../config/AuthenticationConfig';

/**
 * This Authentication class provides abstraction to the
 * actual underlying authentication providers used for
 * authenticating users
 */
class Authentication {
    /**
     * Returns the Google client id to perform
     * the authentication with
     */
    static getGoogleClientId(){
        return AuthConfig.google.clientId;
    }

    /**
     * Authenticates the user through Microsoft 365
     * and returns the authentication token as string
     * or if authentication failed, it'll return an
     * empty string
     */
    static async authMicrosoft(){
        let result = '';

        try{
            /* instantiate the microsoft authentication application */
            let clientApp = new PublicClientApplication({
                auth: {
                    clientId: AuthConfig.microsoft.clientId,
                    redirectUri: Authentication.getRedirectUri()
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true
                }
            });    
    
            /* bring up the authentication popup with microsoft */
            await clientApp.loginPopup({
                scopes: AuthConfig.microsoft.scopeList,
                prompt: "select_account"
            });
    
            /* get the user's account list */
            let accountList = clientApp.getAllAccounts();
            if (accountList.length > 0){
                /* silently acquire the token for the authenticated user */
                let silentResult = await clientApp.acquireTokenSilent({
                    scopes: AuthConfig.microsoft.scopeList, account: accountList[0]
                });

                /* set the token as the result to return */
                result = silentResult.accessToken;
            };

        }catch(ex){
            /* exception is mainly when the user cancelled
                the authentication process with microsoft */
            let logException = true;
            if(typeof ex === 'object'){
                if(ex.hasOwnProperty('errorCode')){
                    /* do not log the exception when the user
                        cancelled to authorisation process and
                        reset the result when it was cancelled */
                    if(ex.errorCode === 'user_cancelled'){
                        logException = false;
                        result = '';
                    }
                }
            }

            if(logException === true){
                console.log('Exception during authentication: ' + JSON.stringify(ex));
            }
        }

        return result;
    }

    /**
     * Returns the redirect URL for the authentication
     * as a string to return the user to after successful
     * authentication
     */
    static getRedirectUri(){
        /* only return host name and protocol as otherwise any
            parameters would result in a security warning as
            the domain might not be registered with the auth
            provider and the auth provider might reject any
            query string parameters */
        let hostName = window.location.hostname;
        if(window.location.port !== ''){
            hostName = [hostName, window.location.port].join(':');
        }

        return [window.location.protocol, hostName].join('//');
    }
}

export default Authentication;