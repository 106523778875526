/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';
import Recommender from '../../class/Recommender';

import EditorSection from '../common/EditorSection';
import DataInputField from '../common/DataInputField';
import DataInputFieldUnit from '../common/DataInputFieldUnit';
import DataInputList from '../common/DataInputList';
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import DataInputCheckBox from '../common/DataInputCheckBox';
import MessageListEditor from './MessageListEditor';
import RecommenderOptionInput from '../common/RecommenderOptionInput';
import StandardButton from '../common/StandardButton';
import SubNavigationList from '../common/SubNavigationList';

import '../../style/FacilityEditor.css';

/**
 * The facility editor allows creating, updating
 * and deleting the units or rooms of the property
 */
class FacilityEditor extends React.Component {
    state = {
        showCreateUnit: false,
        newUnitId: "",
        newUnitIdErrorText: "",
        newUnitName: "",
        newUnitNameErrorText: ""
    }

    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Updates a guest room and notifies the
     * parent component of the updates guest room
     * 
     * @param {object} value
     * the update guest room to attach to the propertty 
     */
    updateGuestRoom(value){
        let property = new Property(this.props.property);
        property.updateGuestRoom(value);

        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Removes a guest room from the property
     * and notifies the parent about the change
     * 
     * @param {object} value
     * the guest room or unit to remove  
     */
    removeGuestRoom(value){
        let property = new Property(this.props.property);
        property.removeGuestRoom(value.roomId);

        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Returns the option list for all available
     * room type codes and their localised names
     */
    getUnitTypeList(){
        let result = [];
        
        let roomTypeCodeList = require('../../config/ota/OTARoomTypeCode.json');
        Object.keys(roomTypeCodeList).forEach((roomTypeCode) => {
            result.push({
                value: roomTypeCode,
                text: Culture.getText('UNIT_TYPENAME_'+roomTypeCode)
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Returns the unit amenity list with all
     * available amenities of the housing unit
     * 
     * @param {object} guestRoom
     * the guest room or unit for which the 
     * amenity list should be returned 
     */
    getUnitAmenityList(guestRoom){
        let result = [];

        let amenityList = [];
        if(Array.isArray(guestRoom.amenityList)){
            guestRoom.amenityList.forEach((amenityItem) =>{
                if(typeof amenityItem.quantity === 'number'){
                    if(amenityItem.quantity > 0){
                        let amenityCodeValue = amenityItem.code;
                        if (Array.isArray(amenityCodeValue)) {
                            if(amenityCodeValue.length > 0){
                                amenityCodeValue = amenityCodeValue[0];
                            }
                        }

                        amenityList.push(Number(amenityCodeValue));
                    }
                }
            });
        }

        let amenityTypeList = require('../../config/ota/OTARoomAmenityType.json');
        Object.keys(amenityTypeList).forEach((amenityTypeCode) => {
            result.push({
                value: amenityTypeCode,
                text: Culture.getText('UNIT_AMENITYTYPENAME_'+amenityTypeCode),
                checked: amenityList.includes(Number(amenityTypeCode))
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Returns the unit description message
     * list for this guest room by either returning
     * the existing one or creating it from
     * the existing description text
     * 
     * @param {object} guestRoom
     * guest room to check for text 
     */
    getUnitDescriptionMessageList(guestRoom){
        let result = [];

        let defaultLanguage = 'en';
        if(typeof this.props.property.languageCode === 'string'){
            defaultLanguage = this.props.property.languageCode;
        }

        if(Array.isArray(guestRoom.messageList)){
            result = guestRoom.messageList;
        }else{
            if(typeof guestRoom.descriptionText === 'string'){
                result = [{
                    languageCode: defaultLanguage,
                    text: guestRoom.descriptionText
                }];
            }
        }

        return result;
    }

    /**
     * Returns a list of options with amenities to
     * recommend being inserted for this property unit
     * 
     * @param {object} guestRoom 
     * the guest room object to check for recommendations
     */
    getAmenityRecommenderList(guestRoom){
        let result = [];

        if(Array.isArray(guestRoom.messageList)){
            if(guestRoom.messageList.length > 0){
                let amenityList = (new Recommender()).getUnitAmenityFromMessageList(guestRoom.messageList);
                amenityList.forEach((amenityCode) => {
                    let amenityExists = false;

                    if(Array.isArray(guestRoom.amenityList)){
                        guestRoom.amenityList.forEach((existAmenity) => {
                            if(existAmenity.code === amenityCode){
                                amenityExists = true;
                            }
                        });
                    }

                    if(amenityExists === false){
                        result.push({
                            value: amenityCode,
                            text: Culture.getText('UNIT_AMENITYTYPENAME_'+amenityCode)
                        });
                    }
                });
            }
        }
    
        return result;
    }

    /**
     * Toggles an amenity for a guest room
     * so that it is added when it does not
     * exist or removed if it does
     * 
     * @param {object} guestRoom
     * the guest room object to toggle amenity for
     *  
     * @param {object} item
     * the amenity item to toggle 
     */
    toggleAmenityItem(guestRoom,item){
        /* update the amenity in the guest room */
        if(Array.isArray(guestRoom.amenityList)){
            /* check if the amenity needs to be updated or not */
            let updatedList = [];
            let amenityExists = false;
            guestRoom.amenityList.forEach((amenityItem) => {
                /* check if its an array as the data may have
                    been corrupted on the way (LOD-435) */
                let amenityCodeValue = amenityItem.code;
                if(Array.isArray(amenityCodeValue)){
                    if (amenityCodeValue.length > 0) {
                        amenityCodeValue = amenityCodeValue[0];
                    }
                    
                }

                if(Number(amenityCodeValue) !== Number(item.value)){
                    updatedList.push(amenityItem);
                }else{
                    if(amenityItem.quantity < 1){
                        amenityItem.quantity = 1;
                        updatedList.push(amenityItem);
                    }else{
                        amenityExists = true;
                    }
                }
            });

            /* add the amenity if it did not exist */
            if(amenityExists === false){
                updatedList.push({
                    code: item.value,
                    quantity: 1
                });
            }

            guestRoom.amenityList = updatedList;
            this.updateGuestRoom(guestRoom);
        }else{
            /* push a new amenity list */
            guestRoom.amenityList = [{
                code: item.value,
                quantity: 1
            }];
            this.updateGuestRoom(guestRoom);
        }
    }

    /**
     * Adds a new unit to this property from
     * the information provided in the state
     */
    addNewUnit(){
        let unitIdExists = false;
        let property = new Property(this.props.property);
        property.getGuestRoomList().forEach((guestRoom) => {
            if(guestRoom.roomId === this.state.newUnitId){
                unitIdExists = true;
            }
        });

        /* the unit id needs to be unique, ensure it is */
        if(!unitIdExists){
            let unitDataSufficient = true;
            if(this.state.newUnitId.length === 0){
                /* set the error that the unit id is required */
                this.setState({newUnitIdErrorText: 'FACILITY_NEW_UNIT_ID_REQUIRED'});
                unitDataSufficient = false;
            }if(this.state.newUnitName.length === 0){
                /* set the error that the unit id is required */
                this.setState({newUnitNameErrorText: 'FACILITY_NEW_UNIT_NAME_REQUIRED'});
                unitDataSufficient = false;
            }

            if(unitDataSufficient === true){
                /* let the property create the new room */
                property.createGuestRoom(this.state.newUnitId,this.state.newUnitName);

                /* reset the state */
                this.setState({newUnitId: "", newUnitIdErrorText: "", newUnitName: "", 
                                newUnitNameErrorText: "", showCreateUnit: false});

                /* call the handler of the parent component */
                if(typeof this.props.onPropertyUpdated === 'function'){
                    this.props.onPropertyUpdated(property);
                }
            }
        }else{
            /* set the error text when the unit id exists already */
            this.setState({newUnitIdErrorText: 'FACILITY_NEW_UNIT_ID_EXISTS'});
        }
    }

    /**
     * Renders the list of units in this property
     */
    renderUnitList(){
        let result = [];

        let property = new Property(this.props.property);
        let guestRoomList = property.getGuestRoomList();
        guestRoomList.sort((a,b) => (a.name > b.name) 
                            ? 1 : ((b.name > a.name) ? -1 : 0));

        let roomKey = 0;
        guestRoomList.forEach((guestRoom) => {
            /* push the section for this room onto the results */
            result.push(
                <section key={'__unit_'+roomKey} code={'__unit_'+roomKey} name={guestRoom.name}>
                    <EditorSection title={Culture.getText('PROPERTY_FACILITY_UNIT_TITLE')}
                        subtitle={Culture.getText('PROPERTY_FACILITY_UNIT_SUBTITLE')}
                        helpText={Culture.getText('PROPERTY_FACILITY_UNIT_HELPTEXT')}>
                        <div className="FacilityGuestRoomId">
                            {guestRoom.roomId}
                        </div>

                        <DataInputCheckBox title={Culture.getText('DATAFIELD_UNIT_ACTIVATE')} 
                            checkedTitle={Culture.getText('DATAFIELD_UNIT_ISACTIVE')}
                            showWarning={(guestRoom.isActive === false)}
                            checked={guestRoom.isActive} onClick={() => {
                                guestRoom.isActive = !guestRoom.isActive;
                                this.updateGuestRoom(guestRoom);
                            }} 
                        />

                        <DataInputField title={Culture.getText('DATAFIELD_UNIT_NAME')}
                            value={guestRoom.name} onChange={(value) => {
                                guestRoom.name = value;
                                this.updateGuestRoom(guestRoom);
                        }} />

                        <DataInputList key="unitTypeList" title={Culture.getText('DATAFIELD_UNIT_TYPE')} 
                            value={guestRoom.roomTypeCode} 
                            list={this.getUnitTypeList()} onChange={(value) => {
                                /* update the type code of the room */
                                guestRoom.roomTypeCode = value;
                                this.updateGuestRoom(guestRoom);
                        }}/>

                        <MessageListEditor title={Culture.getText('PROPERTY_UNIT_DESCRIPTION')} 
                            languageList={this.props.languageList} content={this.getUnitDescriptionMessageList(guestRoom)}
                            onChange={(value) => {
                                /* update the message list of this guest room */
                                guestRoom.messageList = value;
                                this.updateGuestRoom(guestRoom);
                        }} />
                    </EditorSection>

                    <EditorSection title={Culture.getText('PROPERTY_FACILITY_UNIT_AMENITY_TITLE')}
                        subtitle={Culture.getText('PROPERTY_FACILITY_UNIT_AMENITY_SUBTITLE')}
                        helpText={Culture.getText('PROPERTY_FACILITY_UNIT_AMENITY_HELPTEXT')}>
                        <RecommenderOptionInput optionList={this.getAmenityRecommenderList(guestRoom)}
                            title={Culture.getText('PROPERTY_UNIT_AMENITY_RECOMMENDATION')} 
                            text={Culture.getText('PROPERTY_UNIT_AMENITY_RECOMMENDATION_TEXT')}
                            onSelect={this.toggleAmenityItem.bind(this,guestRoom)} />
                        <ModalOptionListSelect title={Culture.getText('DATAFIELD_UNIT_AMENITIES')} 
                            filterPlaceholderText={Culture.getText('UNIT_AMENITY_FILTERTEXT')}
                            addButtonText={Culture.getText('UNIT_EDITAMENITY')}
                            emptyText={Culture.getText('UNIT_EDITAMENITY_EMPTY')}
                            list={this.getUnitAmenityList(guestRoom)} 
                            onToggle={this.toggleAmenityItem.bind(this,guestRoom)} />
                    </EditorSection>

                    <EditorSection title={Culture.getText('PROPERTY_FACILITY_UNIT_OCCUPANCY_TITLE')}
                        subtitle={Culture.getText('PROPERTY_FACILITY_UNIT_OCCUPANCY_SUBTITLE')}
                        helpText={Culture.getText('PROPERTY_FACILITY_UNIT_OCCUPANCY_HELPTEXT')}>
                        <div className="FacilityEditorOccupancyList">
                            <DataInputField title={Culture.getText('DATAFIELD_UNIT_MAXOCCUPANCY')}
                                value={guestRoom.maxOccupancy} onChange={(value) => {
                                    guestRoom.maxOccupancy = parseInt(value);
                                    if(isNaN(guestRoom.maxOccupancy) === true){
                                        delete guestRoom.maxOccupancy;
                                    }
                                    this.updateGuestRoom(guestRoom);
                            }} />

                        <DataInputField title={Culture.getText('DATAFIELD_UNIT_MAXADULTOCCUPANCY')}
                                value={guestRoom.maxAdultOccupancy} onChange={(value) => {
                                    guestRoom.maxAdultOccupancy = parseInt(value);
                                    if(isNaN(guestRoom.maxAdultOccupancy) === true){
                                        delete guestRoom.maxAdultOccupancy;
                                    }
                                    this.updateGuestRoom(guestRoom);
                            }} />

                        <DataInputField title={Culture.getText('DATAFIELD_UNIT_MAXCHILDOCCUPANCY')}
                                value={guestRoom.maxChildOccupancy} onChange={(value) => {
                                    guestRoom.maxChildOccupancy = parseInt(value);
                                    if(isNaN(guestRoom.maxChildOccupancy) === true){
                                        delete guestRoom.maxChildOccupancy;
                                    }
                                    this.updateGuestRoom(guestRoom);
                            }} />
                        </div>                        
                    </EditorSection>

                    <EditorSection title={Culture.getText('PROPERTY_FACILITY_UNIT_SIZE_TITLE')}
                        subtitle={Culture.getText('PROPERTY_FACILITY_UNIT_SIZE_SUBTITLE')}
                        helpText={Culture.getText('PROPERTY_FACILITY_UNIT_SIZE_HELPTEXT')}>
                        <div className="FacilityEditorSpaceCountList">
                            <DataInputField title={Culture.getText('DATAFIELD_UNIT_BEDCOUNT')}
                                value={guestRoom.bedCount} onChange={(value) => {
                                    guestRoom.bedCount = parseInt(value);
                                    this.updateGuestRoom(guestRoom);
                            }} /> 

                            <DataInputField title={Culture.getText('DATAFIELD_UNIT_BEDROOMCOUNT')}
                                value={guestRoom.bedroomCount} onChange={(value) => {
                                    guestRoom.bedroomCount = parseInt(value);
                                    this.updateGuestRoom(guestRoom);
                            }} />

                            <DataInputField title={Culture.getText('DATAFIELD_UNIT_BATHROOMCOUNT')}
                                value={guestRoom.bathroomCount} onChange={(value) => {
                                    guestRoom.bathroomCount = parseInt(value);
                                    this.updateGuestRoom(guestRoom);
                            }} />
                        </div>
                        
                        <DataInputFieldUnit title={Culture.getText('DATAFIELD_UNIT_UNITSIZE')}
                            value={(guestRoom.unitSize || {value: 0, unitType: 'sqm'})}
                            unitTypeList={[
                                {code: 'sqm', name: Culture.getText('DATAFIELD_UNIT_UNITSIZE_SQM')},
                                {code: 'sqft', name: Culture.getText('DATAFIELD_UNIT_UNITSIZE_SQFT')}
                            ]}
                            onChange={(value) => {
                                guestRoom.unitSize = value;
                                this.updateGuestRoom(guestRoom);
                        }} />
                    </EditorSection>

                    <EditorSection title={Culture.getText('PROPERTY_FACILITY_UNIT_DELETE_TITLE')}
                        subtitle={Culture.getText('PROPERTY_FACILITY_UNIT_DELETE_SUBTITLE')}
                        helpText={Culture.getText('PROPERTY_FACILITY_UNIT_DELETE_HELPTEXT')}
                        introText={Culture.getText('PROPERTY_FACILITY_UNIT_DELETE_INTROTEXT')}>
                        <div>
                            <StandardButton className="FacilityEditorRemoveUnit"
                                text={Culture.getText('PROPERTY_FACILITY_REMOVEUNIT')} 
                                onClick={() => {
                                    /* remove the guest room from thje property */
                                    this.removeGuestRoom(guestRoom);
                            }} /> 
                        </div>
                    </EditorSection>
                </section>
            );
            roomKey++;
        });

        return result;
    }

    /**
     * Renders all facilities in this property
     */
    render(){
        return(
            <div className="InventoryEditorSectionContent FacilityEditorContent">
                <SubNavigationList>
                    {this.renderUnitList()}
                    <section code="__create_new_unit" name={Culture.getText('PROPERTY_FACILITY_ADDUNIT')} type="action">
                        <EditorSection title={Culture.getText('PROPERTY_FACILITY_ADDUNIT_TITLE')}
                            subtitle={Culture.getText('PROPERTY_FACILITY_ADDUNIT_SUBTITLE')}
                            helpText={Culture.getText('PROPERTY_FACILITY_ADDUNIT_HELPTEXT')}
                            introText={Culture.getText('PROPERTY_FACILITY_ADDUNIT_INTROTEXT')}> 
                            <DataInputField title={Culture.getText('DATAFIELD_UNIT_NAME')} 
                                        errorText={Culture.getText(this.state.newUnitNameErrorText)}
                                        value={this.state.newUnitName} onChange={(value) => this.setState({newUnitName: value})} />
                            <DataInputField title={Culture.getText('DATAFIELD_UNIT_ROOMID')} 
                                errorText={Culture.getText(this.state.newUnitIdErrorText)}
                                value={this.state.newUnitId} onChange={(value) => {
                                    this.setState({newUnitId: value.replace(/[^a-zA-Z0-9_-]/g,'')});
                                }} />

                            <div>
                                <StandardButton text={Culture.getText('PROPERTY_FACILITY_ADDUNIT_BUTTON')} 
                                        onClick={this.addNewUnit.bind(this)} />
                            </div>
                        </EditorSection>
                    </section>
                </SubNavigationList>
            </div>
        );
    }
}

export default FacilityEditor;