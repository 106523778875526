/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/OptionListSelect.css';

/**
 * Option select list componentt to pick 
 * multiple options from a list
 */
class OptionListSelect extends React.Component {
    state = {
        filterText: ""
    }

    /**
     * Constructs this component and the
     * reference for the filter input field
     */
    constructor(){
        super();

        this.filterInputRef = React.createRef();
    }

    /**
     * Toggles the selection of the item meaning
     * it is either selected or deselected and the
     * parent component notified about the selection
     * 
     * @param {object} item
     * the item that is supposed to be toggled 
     */
    toggleItem(item){
        if(typeof this.props.onToggle === 'function'){
            this.props.onToggle(item);
        }
    }

    /**
     * Handles the filter text update and sets
     * the filter text locally
     * 
     * @param {object} e
     * event object from the input control 
     */
    setFilterText(e){
        this.setState({filterText: e.target.value});
    }

    /**
     * Returns true when the provided text
     * matches the current filter text. If the
     * filter is not enabled or the filter text
     * is empty, this method will return true
     * 
     * @param {string} text
     * text to match with filter 
     */
    matchFilter(text){
        let result = false;
        
        if(this.props.enableFilter === true && this.state.filterText !== ''){
            if(text.toLowerCase().indexOf(this.state.filterText.toLowerCase()) >= 0){
                result = true;
            }
        }else{
            result = true;
        }

        return result;
    }

    /**
     * Renders the component of the option list
     * and 
     */
    render(){
        /* create the components for the options to render inside the list */
        let componentList = [];
        if(Array.isArray(this.props.list)){
            this.props.list.forEach((optionItem) => {
                let classList = ["OptionListItem"];
                if(optionItem.checked === true){
                    classList.push("OptionListItemChecked");
                }

                if(this.matchFilter(optionItem.text) === true){
                    componentList.push(
                        <div key={optionItem.value} className={classList.join(' ')} onClick={this.toggleItem.bind(this,optionItem)}>
                            <div className="OptionListItemContent">
                                <div className="OptionListItemLabel">{optionItem.text}</div>
                            </div>
                        </div>
                    );
                }
            });
        }

        /* define the placeholder text for the filter field */
        let filterPlaceholderText = "...";
        if(typeof this.props.filterPlaceholderText === 'string'){
            filterPlaceholderText = this.props.filterPlaceholderText;
        }

        return(
            <div className="OptionListView">
                {this.props.enableFilter === true &&
                    <div className="OptionListFilter" onClick={() => {
                        if(this.filterInputRef.current !== null){
                            this.filterInputRef.current.focus();
                        }
                    }}>
                        <input ref={this.filterInputRef} className="OptionListFilterInput" type="text" 
                            value={this.state.filterText} onChange={this.setFilterText.bind(this)} 
                            placeholder={filterPlaceholderText} />
                    </div>
                }
                <div className="OptionListContainer">
                    <div className="OptionList">
                        {componentList}
                    </div>
                </div>
            </div>
        );
    }
}

export default OptionListSelect;
