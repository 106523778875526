/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../class/Culture';
import HubSpotAnalytics from '../class/HubSpotAnalytics';

/**
 * The side bar component manages the navigation
 * and the main menu for the console application
 */
class SideBar extends React.Component {
    /**
     * Notifies the parent component about
     * the navigation event and requests it
     * to navigate the user to the desired section
     * 
     * @param {string} item
     * name of the target to navigate user to 
     */
    navigate(item){
        if(typeof this.props.onNavigate === 'function'){
            HubSpotAnalytics.trackSectionNavigation(item);
            this.props.onNavigate(item);
        }
    }

    /**
     * Renders the menu items for a list of
     * menu items which can be placed anywhere
     * inside the sidebar
     */
    renderItemList(itemList){
        let result = [];

        let activeItem = '';
        if(typeof this.props.activeItem === 'string'){
            activeItem = this.props.activeItem;
        }

        itemList.forEach((item) => {
            let classList = ['SideBarMenuItem'];
            if(item.id === activeItem){
                classList.push('SideBarMenuItemActive');
            }
            
            result.push(
                <div key={item.id} className={classList.join(' ')} 
                    onClick={this.navigate.bind(this,item.id)} 
                    data-menuitem={item.id}>
                        <div className="SideBarMenuItemTitle">
                            <div className="SideBarMenuItemTitleText">
                                {item.title}
                            </div>
                            <div className="SideBarMenuItemTitleInfo">
                                {item.text}
                            </div>
                        </div>
                    </div>
            );
        });

        return result;
    }

    /**
     * Renders the component itself
     */
    render(){
        /* define which settings this user has access
            to and determine the setting item list */
        let settingList = [{ 
            id: 'support', 
            title: Culture.getText('MENUITEM_SUPPORT'), 
            text: Culture.getText('MENUITEM_SUPPORT_INFO')
        }];

        if(this.props.user.getUserRole() === 'admin'){
            settingList.push({
                id: 'settings',
                title: Culture.getText('MENUITEM_SETTINGS'),
                text: Culture.getText('MENUITEM_SETTINGS_INFO')
            });
        }

        /* default users only have inventory and booking */
        let menuItemList = [
            { id: 'inventory', title: Culture.getText('MENUITEM_INVENTORY'), text: Culture.getText('MENUITEM_INVENTORY_INFO') },
            { id: 'booking', title: Culture.getText('MENUITEM_BOOKING'), text: Culture.getText('MENUITEM_BOOKING_INFO')}
        ];

        /* managers and admin have all main menu items available */
        if(this.props.user.getUserRole() === 'admin' 
            || this.props.user.getUserRole() === 'manager'){
            menuItemList = [
                { id: 'inventory', title: Culture.getText('MENUITEM_INVENTORY'), text: Culture.getText('MENUITEM_INVENTORY_INFO') },
                { id: 'storefront', title: Culture.getText('MENUITEM_STOREFRONT'), text: Culture.getText('MENUITEM_STOREFRONT_INFO')},
                { id: 'booking', title: Culture.getText('MENUITEM_BOOKING'), text: Culture.getText('MENUITEM_BOOKING_INFO')},
                { id: 'payment', title: Culture.getText('MENUITEM_PAYMENT'), text: Culture.getText('MENUITEM_PAYMENT_INFO')},
                { id: 'connectivity', title: Culture.getText('MENUITEM_CONNECTIVITY'), text: Culture.getText('MENUITEM_CONNECTIVITY_INFO')}
            ];
        }

        /* define the style for the user box
            with the user profile picture */
        let userBoxStyle = {
            backgroundImage: 'url(data:' + this.props.user.user.picture + ')'
        };

        return (
            <div className="SideBarMenu" data-disabled={(this.props.disabled || false)}>
                <div className="SideBarMenuList">
                    <div className="SideBarAppIcon">
                        <img src="/img/icon.svg" alt="LODGEA" className="SideBarAppIconImage" />
                    </div>
                    
                    {this.renderItemList(menuItemList)}
                </div>
                <div className="SideBarBottomList">
                    {this.renderItemList(settingList)}

                    <div className="SideBarUserItem">
                        <div className="UserPicture" style={userBoxStyle} onClick={() => {
                            if(typeof this.props.onShowAccountView === 'function'){
                                this.props.onShowAccountView();
                            }
                        }}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SideBar;