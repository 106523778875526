/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import LoadingIndicator from '../common/LoadingIndicator';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';

class PaymentTransferList extends React.Component {
    state = {
        showMonthSelection: false,
        reportingMonth: '',
        showTransferDetail: false,
        transferDetailNumber: 0,
        fetching: false,
        list: []
    }

    /**
     * Returns the month name and year
     * for the month provided as YYYY-MM
     * in the parameter
     * 
     * @param {string} monthYear 
     * YYYY-MM month string to return name for
     */
    getMonthName(monthYear) {
        let result = monthYear;

        if (typeof monthYear === 'string' && monthYear !== '') {
            let datePart = monthYear.split('-');
            if (datePart.length === 2) {
                let dateObject = new Date(Number(datePart[0]), (Number(datePart[1]) - 1), 1, 0, 0, 0, 0);
                return Intl.DateTimeFormat(Culture.getCultureCode(), {
                    month: 'long',
                    year: 'numeric'
                }).format(dateObject);
            }
        }

        return result;
    }

    /**
     * Fetches the transaction list when
     * the component was mounted
     */
    componentDidMount() {
        let today = new Date();
        let reportingMonth = [today.getUTCFullYear(),
        String(today.getUTCMonth() + 1)
            .padStart(2, '0')].join('-');

        this.setState({
            reportingMonth: reportingMonth
        }, this.fetchList.bind(this));
    }

    /**
     * Fetches the transaction list of the
     * data defined in the state or the current
     * date if none is defined
     */
    async fetchList() {
        this.setState({ fetching: true });

        let listResult = await ApiClient.execute('/booking/getPaymentTransferList', {
            month: this.state.reportingMonth
        });

        if (Array.isArray(listResult.list)) {
            this.setState({ list: listResult.list, fetching: false });
        }
    }

    /**
     * Creates the export file for the transfers
     * as CSV file with the transactions
     */
    createFileExport() {
        let content = 'Status,Created,Transferred,BookingCode,TotalAmount,RefundAmount,Amount,PaymentFee,ApplicationFee,CurrencyCode\n';
        for (const transfer of this.state.list) {
            for (const transaction of transfer.transactionList) {
                content += [
                    transfer.status,
                    (new Date(transfer.created * 1000)).toISOString(),
                    (new Date(transfer.arrival * 1000)).toISOString(),
                    (transaction.recordLocator || 'not_available'),
                    transaction.netAmount,
                    transaction.refundAmount,
                    (transaction.netAmount - transaction.refundAmount),
                    transaction.paymentFee,
                    transaction.applicationFee,
                    transfer.currencyCode.toUpperCase()
                ].join(',') + '\n';
            }
        }

        /* open the csv contents in a new window */
        window.open(encodeURI("data:text/csv;charset=utf-8," + content));
    }

    /**
     * Shows the modal window with the
     * details of the selected transfer
     */
    renderTransferDetail() {
        let result = null;

        if(this.state.showTransferDetail === true){
            let transferNumber = 0;
            for (const transfer of this.state.list) {
                if(transferNumber === this.state.transferDetailNumber){
                    result = (
                        <ModalDrawerOverlay key={transferNumber}
                            titleText={Culture.getText('PAYMENT_TRANSFERDETAIL_TITLE')}
                            subtitleText={Culture.getText('PAYMENT_TRANSFERDETAIL_SUBTITLE')}
                            onClose={() => this.setState({showTransferDetail: false})}>
                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_TRANSFER_STATUS')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue">
                                    <div className="PaymentAccountTransferStatus"
                                        data-status={transfer.status}>
                                        {Culture.getText('PAYMENT_TRANSFERSTATUS_' + transfer.status.toUpperCase())}
                                    </div>
                                </div>
                            </div>

                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_TRANSFER_AMOUNT')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue"
                                    data-negative={(transfer.amount < 0)}>
                                    {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                        style: 'currency', currency: transfer.currencyCode
                                    })).format(transfer.amount)}
                                </div>
                            </div>

                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_TRANSFER_CREATED')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue">
                                    {(new Intl.DateTimeFormat(Culture.getCultureCode(), {
                                        dateStyle: 'full',
                                        timeStyle: 'medium'
                                    })).format(transfer.created * 1000)}
                                </div>
                            </div>

                            <div className="PaymentAccountInformationField">
                                <div className="PaymentAccountInformationFieldTitle">
                                    {Culture.getText('PAYMENT_TRANSFER_ARRIVAL')}
                                </div>
                                <div className="PaymentAccountInformationFieldValue">
                                    {(new Intl.DateTimeFormat(Culture.getCultureCode(), {
                                        dateStyle: 'full',
                                        timeStyle: 'medium'
                                    })).format(transfer.arrival * 1000)}
                                </div>
                            </div>

                            {transfer.transactionList.length > 0 &&
                                <div className="PaymentAccountTransferTransactionView">
                                    <div className="PaymentAccountTransferTransactionViewTitle">
                                        {Culture.getText('PAYMENT_TRANSFER_TRANSACTIONLIST')}
                                    </div>
                                    <div className="PaymentAccountTransferTransactionViewList">
                                        {(function(){
                                            let result = [];

                                            let transactionNumber = 0;
                                            for(const transaction of transfer.transactionList){
                                                result.push(
                                                    <div key={'tx_'+transactionNumber} className="PaymentAccountTransferTransaction">
                                                        {(function(){
                                                            /* determine the record locator text for this transfer transaction item */
                                                            let recordLocatorText = Culture.getText('PAYMENT_TRANSFER_TRANSACTIONLIST_UNASSIGNED_TRANSFER');
                                                            let transactionUnassigned = true;
                                                            if(transaction.recordLocator !== ''){
                                                                recordLocatorText = transaction.recordLocator;
                                                                transactionUnassigned = false;
                                                            }

                                                            return (
                                                                <div className="PaymentAccountTransferTransactionViewListItem">
                                                                    <div className="PaymentAccountTransferTransactionViewListItemRecordLocator"
                                                                        data-unassigned={transactionUnassigned}>
                                                                        {recordLocatorText}
                                                                    </div>
                                                                    <div className="PaymentAccountTransferTransactionViewListItemAmount"
                                                                        data-negative={((transaction.netAmount-transaction.refundAmount)<0)}>
                                                                        {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                                                            style: 'currency', currency: transfer.currencyCode
                                                                        })).format(transaction.netAmount-transaction.refundAmount)}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })()}
                                                    </div>
                                                );

                                                transactionNumber++;
                                            }

                                            return result;
                                        })()}
                                    </div>
                                </div>
                            }
                        </ModalDrawerOverlay>
                    );
                }

                transferNumber++;
            }
        }

        return result;
    }

    /**
     * Renders all transfers in the list
     * and returns the rendered contents
     */
    renderTransferList() {
        let result = [];

        let transferNumber = 0;
        for (const transfer of this.state.list) {
            result.push(
                <tr key={transferNumber} className="PaymentAccountTransfer"
                    onClick={(function(number){
                        this.setState({
                            showTransferDetail: true,
                            transferDetailNumber: number
                        });
                    }).bind(this,transferNumber)}>
                    <td className="PaymentAccountTransferAmount">
                        <span>
                            {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                style: 'currency', currency: transfer.currencyCode
                            })).format(transfer.amount)}
                        </span>
                    </td>
                    <td>
                        <div className="PaymentAccountTransferStatus"
                            data-status={transfer.status}>
                            {Culture.getText('PAYMENT_TRANSFERSTATUS_' + transfer.status.toUpperCase())}
                        </div>
                    </td>
                    <td>
                        {(new Intl.DateTimeFormat(Culture.getCultureCode(), {
                            dateStyle: 'short',
                            timeStyle: 'short'
                        })).format(transfer.created * 1000)}
                    </td>
                    <td>
                        {(new Intl.DateTimeFormat(Culture.getCultureCode(), {
                            dateStyle: 'short',
                            timeStyle: 'short'
                        })).format(transfer.arrival * 1000)}
                    </td>
                    <td>
                        {(function(){
                            let result = '—';

                            if(transfer.transactionList.length > 0){
                                result = (
                                    <div className="PaymentAccountTransferIncludedTransactionCount">
                                        {Culture.getText('PAYMENT_TRANSFER_PAYOUT_COUNT')
                                        .replace('{number}',transfer.transactionList.length)}
                                    </div>  
                                );
                            }

                            return result;
                        })()}
                    </td>
                </tr>
            );
            transferNumber++;
        }

        return result;
    }

    /**
     * Renders the component with the transaction list
     * or the loading indicator if it is actively querying
     */
    render() {
        if (this.state.fetching === true) {
            return (
                <div className="PaymentAccountTransaction">
                    <LoadingIndicator />
                </div>
            );
        } else {
            return (
                <div className="PaymentAccountTransaction">
                    <div className="PaymentAccountTransactionView">
                        <div className="PaymentAccountTransactionHeader">
                            <div className="PaymentAccountTransactionHeaderTitle">
                                {Culture.getText('PAYMENT_TRANSFERLIST_TITLE')}
                            </div>

                            <div className="PaymentAccountTransactionHeaderMonth"
                                onClick={() => this.setState({showMonthSelection: true})}>
                                {this.getMonthName(this.state.reportingMonth)}
                            </div>

                            <StandardButton className="PaymentAccountTransferExportButton"
                                text={Culture.getText('PAYMENT_TRANSFER_EXPORTFILE')}
                                onClick={this.createFileExport.bind(this)} />
                        </div>

                        {this.state.list.length === 0 &&
                            <div className="PaymentAccountTransactionListEmpty">
                                {Culture.getText('PAYMENT_TRANSFERLIST_EMPTY')}
                            </div>
                        }
                        {this.state.list.length > 0 &&
                            <div className="PaymentAccountTransferList">
                                <table cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <th className="PaymentAccountTransferListAmountHeader">
                                                <span>{Culture.getText('PAYMENT_TRANSFER_AMOUNT')}</span>
                                            </th>
                                            <th>{Culture.getText('PAYMENT_TRANSFER_STATUS')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSFER_CREATED')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSFER_ARRIVAL')}</th>
                                            <th>{Culture.getText('PAYMENT_TRANSFER_TRANSACTIONLIST')}</th>
                                        </tr>
                                        {this.renderTransferList()}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>

                    {this.state.showMonthSelection === true &&
                        <ModalDrawerOverlay titleText={Culture.getText('PAYMENT_REPORTINGMONTH_TITLE')}
                            subtitleText={Culture.getText('PAYMENT_REPORTINGMONTH_SUBTITLE')}
                            onClose={() => this.setState({showMonthSelection: false})}>
                            <ul className="PaymentAccountTransactionMonthList">
                                {(function(){
                                    let result = [];

                                    let d = new Date();
                                    d.setDate(1);
                                    for (let i=0; i<=23; i++) {
                                        let monthValue = [d.getFullYear(),String(d.getMonth()+1).padStart(2,'0')].join('-');
                                        result.push(
                                            <li key={monthValue} onClick={() => {
                                                this.setState({
                                                    reportingMonth: monthValue,
                                                    showMonthSelection: false
                                                },this.fetchList.bind(this));
                                            }}>
                                                {this.getMonthName(monthValue)}
                                            </li>
                                        );
                                        d.setMonth(d.getMonth() - 1);
                                    }

                                    return result;
                                }).bind(this)()} 
                            </ul>
                        </ModalDrawerOverlay>
                    }

                    {this.renderTransferDetail()}
                </div>
            );
        }
    }
}

export default PaymentTransferList;