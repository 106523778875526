/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';

/* require the components for this component */
import SubNavigationList from '../common/SubNavigationList';
import EditorSection from '../common/EditorSection';
import LoadingIndicator from '../common/LoadingIndicator';

import StandardButton from '../common/StandardButton';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputField from '../common/DataInputField';

import '../../style/ApiConfigView.css';
import ManagementUser from '../../class/ManagementUser';

/* require the SDK configuration for rendering */
const SdkList = require('../../config/api/sdk.json');

/**
 * This component allows managing the api configuration
 * as well as api keys and provides information about the
 * usage of the api like the docs and the sdks
 */
class ApiConfigView extends React.Component {
    state = {
        isLoading: true,
        appList: [],
        showCreateWindow: false,
        newAppName: '',
        showDeleteWindow: false,
        deleteApiKey: '',
        showInfoWindow: false,
        infoApiKey: ''
    }
    
    /**
     * Initially fetches the apps
     * from the api when the component
     * was first mounted
     */
    componentDidMount(){
        this.fetchAppList();
    }

    /**
     * Fetches the list with all apps
     * from the API and updates the state
     */
    async fetchAppList(){
        this.setState({isLoading: true});

        let url = '/api/tenants/' + ManagementUser.getCurrent().activeTenant + '/keys';
        let appList = await ApiClient.get(url);
        if(Array.isArray(appList)){
            this.setState({appList: appList, isLoading: false});
        }else{
            this.setState({appList: [], isLoading: false});
        }
    }

    /**
     * Creates a new app with the app
     * name provided in the state
     */
    async createApp(){
        this.setState({isLoading: true, showCreateWindow: false});

        if(this.state.newAppName !== ''){
            let url = '/api/tenants/' + ManagementUser.getCurrent().activeTenant + '/keys';
            await ApiClient.execute(url,{
                name: this.state.newAppName
            });

            this.fetchAppList();
        }
    }

    /**
     * Deletes the app for which the
     * api key was provided in the parameter
     */
    async deleteApp(){
        this.setState({isLoading: true, showDeleteWindow: false});

        let url = '/api/tenants/' + ManagementUser.getCurrent().activeTenant 
                    + '/keys/' + this.state.deleteApiKey;
        await ApiClient.delete(url);

        this.fetchAppList();
    }

    /**
     * Renders the list of all apps
     * for this tenant and returns 
     * the component list
     */
    renderAppList(){
        let result = [];

        for(const appConfig of this.state.appList){
            result.push(
                <div key={appConfig.apiKey} className="ApiConfigAppListItem">
                    <div className="ApiConfigAppListItemName" onClick={() => {
                            this.setState({showInfoWindow: true, infoApiKey: appConfig.apiKey})
                        }}>
                        {appConfig.name}
                    </div>
                    <div className="ApiConfigAppListItemActionList">
                        <div className="ApiConfigAppListItemActionShowKey" onClick={() => {
                            this.setState({showInfoWindow: true, infoApiKey: appConfig.apiKey})
                        }}>
                            {Culture.getText('SETTINGS_API_APPLIST_SHOWAPIKEY')}
                        </div>
                        <div className="ApiConfigAppListItemActionDelete" onClick={() => {
                            this.setState({showDeleteWindow: true, deleteApiKey: appConfig.apiKey})
                        }}></div>
                    </div>
                </div>
            )
        }

        if(result.length === 0){
            result.push(
                <div key="empty" className="ApiConfigAppListEmpty">
                    {Culture.getText('SETTINGS_API_APPLIST_EMPTY')}
                </div>
            )
        }

        return result;
    }

    /**
     * Renders the component
     */
    render(){
        return(
            <>
                <SubNavigationList>
                    <section code="applist" name={Culture.getText('SETTINGS_API_APPLIST')}>
                        <EditorSection title={Culture.getText('SETTINGS_API_APPLIST_TITLE')}
                            subtitle={Culture.getText('SETTINGS_API_APPLIST_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_API_APPLIST_HELPTEXT')}>
                            {this.state.isLoading === true &&
                                <LoadingIndicator />
                            }

                            {this.state.isLoading === false && 
                                <>
                                    <div className="ApiConfigAppList">
                                        {this.renderAppList()}
                                    </div>
                                    <div>
                                        <StandardButton className="ApiConfigCreateAppButton"
                                            text={Culture.getText('SETTINGS_API_APPLIST_CREATEAPP')}
                                            onClick={() => this.setState({
                                                showCreateWindow: true,
                                                newAppName: ''
                                            })} />
                                    </div>
                                </>
                            }
                        </EditorSection>
                    </section>
                    <section code="sdklist" name={Culture.getText('SETTINGS_API_SDKLIST')}>
                        <EditorSection title={Culture.getText('SETTINGS_API_SDKLIST_TITLE')}
                            subtitle={Culture.getText('SETTINGS_API_SDKLIST_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_API_SDKLIST_HELPTEXT')}
                            introText={Culture.getText('SETTINGS_API_SDKLIST_INTROTEXT')}>
                            <div className="ApiConfigSdkList">
                                {(function(){
                                    let result = [];

                                    for(const sdkItem of SdkList){
                                        result.push(
                                            <div key={sdkItem.language} 
                                                className="ApiConfigSdkListItem"
                                                data-language={sdkItem.language}
                                                onClick={() => window.open(sdkItem.sdkUrl)}>
                                                <div className="ApiConfigSdkListItemInfo">
                                                    <div className="ApiConfigSdkListItemTitle">
                                                        {Culture.getText('SETTINGS_API_SDK_TITLE_'+sdkItem.language.toUpperCase())}
                                                    </div>
                                                    <div className="ApiConfigSdkListItemText">
                                                        {Culture.getText('SETTINGS_API_SDK_SUBTITLE_'+sdkItem.language.toUpperCase())}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                    return result;
                                })()}
                            </div>
                        </EditorSection>

                        <EditorSection title={Culture.getText('SETTINGS_API_DOCS_TITLE')}
                            subtitle={Culture.getText('SETTINGS_API_DOCS_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_API_DOCS_HELPTEXT')}
                            introText={Culture.getText('SETTINGS_API_DOCS_INTROTEXT')}>
                            <a href={Culture.getText('SETTINGS_API_DOCS_URL')} 
                                target="_blank" rel="noopener noreferrer"
                                className="ApiConfigDocLink">
                                {Culture.getText('SETTINGS_API_DOCS_WEBSITE')}
                            </a>
                        </EditorSection>
                    </section>
                </SubNavigationList>

                {this.state.showCreateWindow === true &&
                    <ModalDrawerOverlay 
                        titleText={Culture.getText('SETTINGS_API_CREATEAPP_TITLE')}
                        subtitleText={Culture.getText('SETTINGS_API_CREATEAPP_SUBTITLE')}
                        introText={Culture.getText('SETTINGS_API_CREATEAPP_INTROTEXT')}
                        submitButtonText={Culture.getText('SETTINGS_API_CREATEAPP_BUTTON')}
                        submitDisabled={(this.state.newAppName === '')}
                        onSubmit={this.createApp.bind(this)}
                        onClose={() => this.setState({showCreateWindow: false})}>
                        <DataInputField required={true}
                            title={Culture.getText('SETTINGS_API_APPNAME')} 
                            value={this.state.newAppName} 
                            onChange={(value) => this.setState({newAppName: value})} />
                    </ModalDrawerOverlay>
                }

                {this.state.showDeleteWindow === true &&
                    <ModalDrawerOverlay 
                        titleText={Culture.getText('SETTINGS_API_DELETEAPP_TITLE')}
                        subtitleText={Culture.getText('SETTINGS_API_DELETEAPP_SUBTITLE')}
                        introText={Culture.getText('SETTINGS_API_DELETEAPP_INTROTEXT')}
                        submitButtonText={Culture.getText('SETTINGS_API_DELETEAPP_BUTTON')}
                        onSubmit={this.deleteApp.bind(this)}
                        onClose={() => this.setState({showDeleteWindow: false})}>
                        <div className="AppInformationField">
                            <div className="AppInformationFieldTitle">
                                {Culture.getText('SETTINGS_API_APPNAME')}
                            </div>
                            <div className="AppInformationFieldValue">
                                {(function(){
                                    let result = '...';

                                    for(const appConfig of this.state.appList){
                                        if(appConfig.apiKey === this.state.deleteApiKey){
                                            result = appConfig.name;
                                        }
                                    }

                                    return result;
                                }).bind(this)()}
                            </div>
                        </div>

                        <div className="AppInformationField">
                            <div className="AppInformationFieldTitle">
                                {Culture.getText('SETTINGS_API_APIKEY')}
                            </div>
                            <div className="AppInformationFieldValue">
                                {this.state.deleteApiKey}
                            </div>
                        </div>
                    </ModalDrawerOverlay>
                }

                {this.state.showInfoWindow === true &&
                    <ModalDrawerOverlay 
                        titleText={(function(){
                            let result = '...';

                            for(const appConfig of this.state.appList){
                                if(appConfig.apiKey === this.state.infoApiKey){
                                    result = appConfig.name;
                                }
                            }

                            return result;
                        }).bind(this)()}
                        introText={Culture.getText('SETTINGS_API_APPINFO_INTROTEXT')}
                        subtitleText={Culture.getText('SETTINGS_API_APPINFO_SUBTITLE')}
                        onClose={() => this.setState({showInfoWindow: false})}>
                        <div className="AppInformationField">
                            <div className="AppInformationFieldTitle">
                                {Culture.getText('SETTINGS_API_APPNAME')}
                            </div>
                            <div className="AppInformationFieldValue">
                                {(function(){
                                    let result = '...';

                                    for(const appConfig of this.state.appList){
                                        if(appConfig.apiKey === this.state.infoApiKey){
                                            result = appConfig.name;
                                        }
                                    }

                                    return result;
                                }).bind(this)()}
                            </div>
                        </div>

                        <div className="AppInformationField">
                            <div className="AppInformationFieldTitle">
                                {Culture.getText('SETTINGS_API_APIKEY')}
                            </div>
                            <div className="AppInformationFieldValue">
                                {this.state.infoApiKey}
                            </div>
                        </div>
                    </ModalDrawerOverlay>
                }
            </>
        );
    }
}

export default ApiConfigView;