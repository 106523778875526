/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import './App.css';

/* require the classes for the operations in this component */
import ManagementUser from './class/ManagementUser';
import Culture from './class/Culture';
import ApiClient from './class/ApiClient';
import HubSpotAnalytics from './class/HubSpotAnalytics';

/* require the main components */
import SignInView from './component/SignInView';
import ConsoleApp from './component/ConsoleApp';
import AccountInformationMessage from './component/account/AccountInformationMessage';

/* require the legal configuration */
const LegalConfig = require('./config/LegalConfig.json');

/**
 * This is the main App entry point where the
 * application loads all required components
 */
class App extends React.Component {
    state = {
        loggedIn: false,
        currentCulture: 'en',
        billingIssue: false,
        billingApiUrl: '',
        billingAccessCode: '',
        subscriptionCancellationTime: false,
        requireConfirmTerms: false
    }

    /**
     * Initialises the app with the user
     * and the sign in state of it
     */
    componentDidMount(){
        let user = ManagementUser.getCurrent();
        this.setState({
            loggedIn: user.isLoggedIn(),
            currentCulture: Culture.getCultureCode()
        });

        /* check for any possible billing issues */
        if(user.isLoggedIn() === true){
            this.checkBillingStatus();
        }

        /* attach Microsoft Clarity SaaS analytics */
        try{
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "7158n7bpga");

            /* initialize the hubspot crm tracking */
            HubSpotAnalytics.initialize();
        }catch(ex){
            console.log('Failed to attach Clarity: ' + ex.stack);
        }
    }

    /**
     * This method checks for any potential
     * billing issues and shows the billing
     * view if there are any issues with
     * billing of this tenant
     */
    async checkBillingStatus(){
        /* average user can't do anything about billing, only check with admins */
        if(ManagementUser.getCurrent().getUserRole() === 'admin'){
            /* get the billing information from the api */
            let codeResult = await ApiClient.execute('/account/billing');
            if(codeResult !== null){
                if(codeResult.failed === false && typeof codeResult.accessCode === 'string'){
                    /* code retrieved, fetch the billing information */
                    let queryUrl = codeResult.apiUrl + '/subscription/view';
                    let response = await fetch(queryUrl,{
                        method: 'post',
                        body: JSON.stringify({
                            tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                            accessCode: codeResult.accessCode,
                            userName: ManagementUser.getCurrent().user.name,
                            userId: ManagementUser.getCurrent().user.userId
                        })
                    });

                    /* get the json account response object */
                    let accountResponse = await response.json();
                    let hasBillingIssue = false;
                    let cancellationTime = false;
                    let requireConfirmTerms = false;
                    if(typeof accountResponse === 'object' && accountResponse !== null){
                        let accountObject = accountResponse.account;
                        if(typeof accountObject === 'object' && accountObject !== null){
                            /* check if the account has been cancelled */
                            if(typeof accountObject.subscription.cancellationTime === 'number'){
                                cancellationTime = accountObject.subscription.cancellationTime;
                            }

                            /* define the problematic subscription states that require
                                immediate action by the user to recheck the subscription */
                            let issueStatusList = [
                                'incomplete', 'incomplete_expired', 'past_due', 'unpaid'
                            ];

                            if(issueStatusList.includes(accountObject.status.toLowerCase())){
                                /* set the state to billing issue for the user to resolve */
                                hasBillingIssue = true;
                            }

                            /* check if a CRM id was attached and identify with the CRM */
                            if(typeof accountObject.crmId === 'string' && accountObject.crmId !== ''){
                                HubSpotAnalytics.identifyContact(accountObject.crmId,accountObject.crmEmail);
                            }

                            /* check if the terms and conditions need to be accepted after being updated */
                            requireConfirmTerms = (LegalConfig.termsTimestamp > accountObject.acceptedTerms);
                        }
                    }

                    /* update the state with the billing issue */
                    this.setState({
                        billingIssue: hasBillingIssue,
                        billingAccessCode: codeResult.accessCode,
                        billingApiUrl: codeResult.apiUrl,
                        subscriptionCancellationTime: cancellationTime,
                        /* require accepting terms when the terms were updated */
                        requireConfirmTerms: requireConfirmTerms
                    });
                }
            }
        }
    }

    /**
     * Reactivates the subscription of the tenant
     * in the background by requesting the re-activation
     * from the billing service
     */
    async reactivateSubscription(){
        if(this.state.billingApiUrl !== '' && this.state.billingAccessCode !== ''){
            const queryUrl = (this.state.billingApiUrl + '/subscription/update');
            await fetch(queryUrl,{
                method: 'post',
                body: JSON.stringify({
                    tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                    accessCode: this.state.billingAccessCode,
                    action: 'REACTIVATE'
                })
            });
        }
    }

    /**
     * Updates the subscription to accept the
     * updated terms and conditions
     */
    async acceptUpdatedTerms(){
        this.setState({requireConfirmTerms: false});
        const queryUrl = (this.state.billingApiUrl + '/subscription/update');
        await fetch(queryUrl,{
            method: 'post',
            body: JSON.stringify({
                tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                accessCode: this.state.billingAccessCode,
                acceptedTerms: (new Date()).getTime()
            })
        });
    }

    /**
     * Signs out the currently signed in
     * user, updates the state and thus
     * leads the user back to the sign in view
     */
    signOut(){
        let user = ManagementUser.getCurrent();
        user.removeAuthenticatedUser();
        this.setState({loggedIn: user.isLoggedIn()});
    }

    /**
     * Check if this is a setup session and
     * the setup key as well as the tenant
     * information are available in the 
     * query string parameters
     */
    isSetupSession(){
        let result = false;

        const urlParam = new URLSearchParams(window.location.search);
        if(urlParam.get('tenantCode') !== null && urlParam.get('setupKey') !== null){
            result = true;
        }

        return result;
    }

    /**
     * Renders this component which is the
     * sign in view in the default state and
     * passes the handling over to the console
     * app class when the user is signed in
     */
    render(){
        let user = ManagementUser.getCurrent();
        if(this.state.loggedIn === true){
            return (
                <div className="App">
                    <ConsoleApp user={user} 
                        subscriptionCancellationTime={this.state.subscriptionCancellationTime}
                        onSubscriptionReactivate={this.reactivateSubscription.bind(this)}
                        billingIssue={this.state.billingIssue}
                        onSignOut={() => this.signOut()}
                        onTenantChange={this.checkBillingStatus.bind(this)}
                        onCultureChange={(value) => {
                            Culture.setCultureCode(value);
                            this.setState({currentCulture: Culture.getCultureCode()});
                    }} />

                    {this.state.requireConfirmTerms === true &&
                        <AccountInformationMessage
                            title={Culture.getText('TERMS_CHANGED_TITLE')}
                            subtitle={Culture.getText('TERMS_CHANGED_SUBTITLE')}
                            confirmText={Culture.getText('TERMS_CHANGED_BUTTON')}
                            onConfirm={this.acceptUpdatedTerms.bind(this)}
                            onRemove={() => this.setState({requireConfirmTerms: false})}>
                            <div className="TermsChangedText">
                                {Culture.getText('TERMS_CHANGED_TEXT')}
                            </div>
                            <a className="TermsChangedDocumentLink"
                                href={Culture.getText('TERMS_URL')} 
                                target="_blank" rel="noopener noreferrer">
                                {Culture.getText('TERMS_TITLE')}
                            </a>
                        </AccountInformationMessage>
                    }
                </div>
            );
        }else{
            return (
                <div className="App">
                    <SignInView isSetupSession={this.isSetupSession()}
                        onLoggedIn={(function(userInstance,userObject){
                            /* store the authenticated credentials */
                            userInstance.setAuthenticatedUser(userObject);
                            window.location.reload();
                        }).bind(this,user)} 
                        onCultureChange={(function(value){
                            Culture.setCultureCode(value);
                            this.setState({currentCulture: Culture.getCultureCode()})
                        }).bind(this)}
                    />
                </div>
            );
        }
    }
}

export default App;
