/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';
import Property from '../../class/Property';

import SubNavigationList from '../common/SubNavigationList';
import EditorSection from '../common/EditorSection';
import DataInputCheckBox from '../common/DataInputCheckBox';

import '../../style/InventoryConnectivity.css';

/**
 * The component to manage the inventory connectivity
 * for the property provided to this component
 */
class InventoryConnectivity extends React.Component {
    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Notifies the parent component about any
     * updates to the property and provides the
     * updated property to the event handler attached
     * 
     * @param {object} property
     * the updated property to pass to the handler 
     */
    notifyUpdate(property){
        if(typeof property === 'object' && property !== null){
            if(typeof this.props.onPropertyUpdated === 'function'){
                this.props.onPropertyUpdated(property);
            }
        }
    }

    /**
     * Renders the list of available connectivity
     * options that are available for properties
     */
    renderConnectivityOptionList(){
        let result = [];

        /* get the currently set transaction method for this property */
        let property = new Property(this.props.property);
        let transactionMethod = property.getTransactionMethodCode();
        
        /* defines the list of all available transaction methods or options */
        let optionList = [
            'MANDATORY_BOOKING','RECONFIRM_BOOKING', 'REQUEST_ONLY' /* , 'EXTERNAL_LINK' */
        ];

        /* render all the options for transaction methods */
        optionList.forEach((option) => {
            let classList = ['InventoryConnectivityOption'];
            if(transactionMethod === option){
                classList.push('InventoryConnectivityOptionActive');
            }

            result.push(
                <div key={option} className={classList.join(' ')} onClick={() => {
                    property.setTransactionMethod(option);
                    this.notifyUpdate(property);
                }}>
                    <div className="InventoryConnectivityOptionInfo">
                        <div className="InventoryConnectivityOptionTitle">{Culture.getText(option+'_TITLE')}</div>
                        <div className="InventoryConnectivityOptionText">{Culture.getText(option+'_TEXT')}</div>
                    </div>
                </div>
            );
        });

        return result;
    }

    /**
     * Renders the contact options for
     * transaction handling and that
     * will receive notifications for
     * transactions
     */
    renderBookingContactOptionList(){
        let result = [];

        let property = new Property(this.props.property);
        let optionList = ['reservations', 'central_reservations', 'general'];
        optionList.forEach((option) => {
            result.push(
                <DataInputCheckBox key={option}
                    title={Culture.getText('GENERAL_PROPERTY_CONTACTTYPE_'+option.toUpperCase())} 
                    checked={property.getTransactionContactList().includes(option)} 
                    onClick={() => {
                        /* toggle the contact type option for this property */
                        let contactList = property.getTransactionContactList();
                        if(contactList.includes(option)){
                            let updatedList = [];
                            contactList.forEach((item) => {
                                if(item !== option){ updatedList.push(item); }
                            });
                            contactList = updatedList;
                        }else{
                            contactList.push(option);
                        }

                        /* set the new option and notify the parent */
                        property.setTransactionContactList(contactList);
                        this.notifyUpdate(property);
                    }} 
                />                
            );
        });

        return result;
    }

    /**
     * Renders the component for the connectivity
     * configuration of this inventory property
     */
    render(){
        let property = new Property(this.props.property);
        return(
            <div className="InventoryEditorSectionContent InventoryConnectivityContent">
                <SubNavigationList>
                    <section code="config" name={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_TITLE')}>
                        <EditorSection title={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_TITLE')}
                            subtitle={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_SUBTITLE')}
                            helpText={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_HELP')}>
                            {property.isChannelConnected() === true && 
                                <div className="ConnectivityInfoMessage">
                                    <div className="ConnectivityInfoMessageTitle">
                                        {Culture.getText('PROPERTY_CHANNELCONNECTED_TITLE')}
                                    </div>
                                    <div className="ConnectivityInfoMessageText">
                                        {Culture.getText('PROPERTY_CHANNELCONNECTED_TEXT')}
                                    </div>
                                </div>
                            }

                            {property.isChannelConnected() === false && 
                                <div className="InventoryConnectivityOptionList">
                                    {this.renderConnectivityOptionList()}
                                </div>
                            }
                        </EditorSection>
                    </section>
                    <section code="contact" name={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_CONTACT_TITLE')}>
                        <EditorSection title={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_CONTACT_TITLE')}
                            subtitle={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_CONTACT_SUBTITLE')}
                            introText={Culture.getText('PROPERTY_BOOKING_CONFIGURATION_CONTACT_TEXT')}
                            helpText={Culture.getText('PROPERTY_BOOKING_CONTACT_WARNING_TEXT')}>
                            {this.renderBookingContactOptionList()}
                        </EditorSection>
                    </section>
                </SubNavigationList>
            </div>
        );
    }
}

export default InventoryConnectivity;