/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';
import DataInputField from '../common/DataInputField';
import StarRatingInputField from '../common/StarRatingInputField';
import EditorSection from '../common/EditorSection';
import MessageListEditor from './MessageListEditor';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DataInputTime from '../common/DataInputTime';
import DataInputList from '../common/DataInputList';
import SubNavigationList from '../common/SubNavigationList';
import ModalOptionListSelect from '../common/ModalOptionListSelect';

class InformationEditor extends React.Component {
	state = {
		gracePeriodAfterBooking: '',
		gracePeriodBeforeCheckIn: '',
		activeSection: 'info',
	};

	/**
	 * Attach the handler for culture changes
	 * as this component uses localised text
	 */
	componentDidMount() {
		Culture.attachCultureComponent(this);

		/* initialise the cancellation grace period values */
		let property = new Property(this.props.property);
		let cancellationGracePeriod = property.getCancellationGracePeriod();

		if (cancellationGracePeriod.hoursAfterBooking > 0) {
			this.setState({
				gracePeriodAfterBooking: Culture.formatFloatToString(
					cancellationGracePeriod.hoursAfterBooking
				),
			});
		}
		if (cancellationGracePeriod.weeksBeforeCheckIn > 0) {
			this.setState({
				gracePeriodBeforeCheckIn: Culture.formatFloatToString(
					cancellationGracePeriod.weeksBeforeCheckIn
				),
			});
		}
	}

	/**
	 * Returns the option list for all
	 * available property type options
	 */
	getPropertyTypeOptionList() {
		let result = [];

		/* get the selected categories */
		let selectedList = new Property(this.props.property).getCategoryList();

		/* get the property type class definitions */
		let propertyTypeClass = require('../../config/ota/OTAPropertyClassType.json');
		Object.keys(propertyTypeClass).forEach((typeCode) => {
			let categoryChecked = false;
			if (selectedList.includes(parseInt(typeCode))) {
				categoryChecked = true;
			}

			result.push({
				value: parseInt(typeCode),
				text: Culture.getText('OTA_PROPERTY_CATEGORY_' + typeCode),
				checked: categoryChecked,
			});
		});

		result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

		return result;
	}

	/**
	 * Returns the option list with all
	 * available languages for the property
	 */
	getLanguageOptionList() {
		let result = [];

		/* get the list of currently set languages */
		let selectedList = new Property(this.props.property).getLanguageList();

		let languageCodeList = require('../../config/ota/OTALanguageCodeList.json');
		Object.keys(languageCodeList).forEach((languageCode) => {
			result.push({
				value: languageCode,
				text: Culture.getText(
					'OTA_LANGUAGE_NAME_' + languageCode.toUpperCase()
				),
				checked: selectedList.includes(languageCode),
			});
		});

		result.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

		return result;
	}

	/**
	 * Returns the option list with all available
	 * currency codes to use with the system
	 */
	getCurrencyOptionList() {
		let result = [];

		let currencyCodeList = require('../../config/ota/CurrencyCode.json');
		currencyCodeList.forEach((currencyCode) => {
			let currencyText =
				currencyCode.toUpperCase() +
				' (' +
				Culture.getText('CURRENCYNAME_' + currencyCode.toUpperCase()) +
				')';
			result.push({
				value: currencyCode,
				text: currencyText,
			});
		});

		return result;
	}

	/**
	 * Returns the option list with available
	 * grace periods after the booking is made
	 */
	getGracePeriodListAfterBooking() {
		let result = [];

		let gracePeriodHourList = [0, 1, 4, 24, 48, 72];
		gracePeriodHourList.forEach((gracePeriodHour) => {
			result.push({
				value: gracePeriodHour,
				text: Culture.getText(
					'PROPERTY_CANCELLATIONGRACEPRIOD_HOURSAFTERBOOKING_' + gracePeriodHour
				),
			});
		});

		return result;
	}

	/**
	 * Returns the option list with available
	 * grace periods before the guest checks in
	 */
	getGracePeriodListBeforeCheckIn() {
		let result = [];

		let gracePeriodWeekList = [0, 4, 8, 12];
		gracePeriodWeekList.forEach((gracePeriodWeek) => {
			result.push({
				value: gracePeriodWeek,
				text: Culture.getText(
					'PROPERTY_CANCELLATIONGRACEPRIOD_WEEKSBEFORECHECKIN_' +
						gracePeriodWeek
				),
			});
		});

		return result;
	}

	/**
	 * Notifies the parent component, if a handler was
	 * attached, that the property updated and requests
	 * it to handle the updated property
	 *
	 * @param {object} value
	 * the updated property object
	 */
	notifyPropertyUpdate(value) {
		if (typeof this.props.onPropertyUpdated === 'function') {
			this.props.onPropertyUpdated(value);
		}
	}

	/**
	 * Renders the component with the editor sections
	 * for the property contents that are edited through
	 * this componentt and its sub-components
	 */
	render() {
		/* get the property from the props */
		let property = new Property(this.props.property);
		let guestInfo = property.getGuestInfo();

		return (
			<div className="InventoryEditorSectionContent">
				<SubNavigationList>
					<section
						code="info"
						name={Culture.getText('GENERAL_PROPERTY_INFORMATION')}
					>
						<EditorSection
							title={Culture.getText('GENERAL_PROPERTY_INFORMATION_NAME_TITLE')}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_NAME_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_NAME_HELPTEXT'
							)}
						>
							<DataInputCheckBox
								title={Culture.getText('PROPERTY_INFORMATION_PUBLISH')}
								showWarning={!property.isPublished()}
								checkedTitle={Culture.getText(
									'PROPERTY_INFORMATION_ISPUBLISHED'
								)}
								checked={property.isPublished()}
								onClick={() => {
									property.publish(!property.isPublished());
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputField
								title={Culture.getText('DATAFIELD_PROPERTYNAME')}
								value={property.name}
								onChange={(value) => {
									property.name = value;
									this.notifyPropertyUpdate(property);
								}}
							/>

							<MessageListEditor
								title={Culture.getText('PROPERTY_INFORMATION_DESCRIPTION')}
								languageList={this.props.languageList}
								content={property.getPropertyInfoText()}
								onChange={(value) => {
									/* update the message list and notify the parent about the update */
									property.setPropertyMessageList(value);
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>

						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_CATEGORY_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_CATEGORY_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_CATEGORY_HELPTEXT'
							)}
						>
							<ModalOptionListSelect
								title={Culture.getText('PROPERTY_INFORMATION_PROPERTYTYPE')}
								filterPlaceholderText={Culture.getText(
									'PROPERTY_INFORMATION_PROPERTYTYPE_FILTERTEXT'
								)}
								addButtonText={Culture.getText(
									'PROPERTY_INFORMATION_PROPERTYTYPE_ADDTYPE'
								)}
								emptyText={Culture.getText(
									'PROPERTY_INFORMATION_PROPERTYTYPE_ADDTYPE'
								)}
								list={this.getPropertyTypeOptionList()}
								onToggle={(item) => {
									/* select or deselect the item that was toggled */
									if (
										property.getCategoryList().includes(item.value) === true
									) {
										/* remove this category from the property */
										property.removeCategory(item.value);
									} else {
										/* add this property category to the property */
										property.addCategory(item.value);
									}

									/* notify the parent component about the update */
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>

						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_RATING_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_RATING_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_INFORMATION_RATING_HELPTEXT'
							)}
						>
							<StarRatingInputField
								value={property.getStarRating()}
								onChange={(value) => {
									/* update the star rating and notify the parent about the update */
									property.setStarRating(value);
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>
					</section>
					<section
						code="global"
						name={Culture.getText('GENERAL_PROPERTY_GLOBALIZATION')}
					>
						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_GLOBALIZATION_DEFAULTCURRENCY_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_GLOBALIZATION_DEFAULTCURRENCY_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_GLOBALIZATION_DEFAULTCURRENCY_HELPTEXT'
							)}
						>
							<DataInputList
								title={Culture.getText('DATAFIELD_PROPERTY_DEFAULTCURRENCY')}
								value={property.getDefaultCurrencyCode()}
								list={this.getCurrencyOptionList()}
								onChange={(value) => {
									property.setDefaultCurrencyCode(value);
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>

						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_GLOBALIZATION_LANGUAGESPOKEN_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_GLOBALIZATION_LANGUAGESPOKEN_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_GLOBALIZATION_LANGUAGESPOKEN_HELPTEXT'
							)}
						>
							<ModalOptionListSelect
								title={Culture.getText('PROPERTY_INFORMATION_LANGUAGELIST')}
								filterPlaceholderText={Culture.getText(
									'PROPERTY_INFORMATION_LANGUAGELIST_FILTERTEXT'
								)}
								addButtonText={Culture.getText(
									'PROPERTY_INFORMATION_LANGUAGELIST_ADDLANGUAGE'
								)}
								emptyText={Culture.getText(
									'PROPERTY_INFORMATION_LANGUAGELIST_ADDLANGUAGE_EMPTY'
								)}
								list={this.getLanguageOptionList()}
								onToggle={(optionItem) => {
									/* select or deselect the language that was toggled */
									let languageList = property.getLanguageList();
									if (languageList.includes(optionItem.value)) {
										/* remove the value from the language list */
										let updatedList = [];
										languageList.forEach((langCode) => {
											if (langCode !== optionItem.value) {
												updatedList.push(langCode);
											}
										});

										/* assign the updated language list */
										property.setLanguageList(updatedList);
									} else {
										/* add the value to the language list */
										languageList.push(optionItem.value);
										property.setLanguageList(languageList);
									}

									/* notify the parent component about the update */
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>
					</section>
					<section
						code="restriction"
						name={Culture.getText('GENERAL_PROPERTY_RESTRICTION')}
					>
						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_GUESTDATAREQUIREMENT_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_GUESTDATAREQUIREMENT_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_GUESTDATAREQUIREMENT_HELPTEXT'
							)}
						>
							<DataInputCheckBox
								title={Culture.getText(
									'PROPERTY_INFORMATION_REQUIREGUESTDETAIL'
								)}
								checked={guestInfo.guestNameListRequired}
								onClick={() => {
									property.updateGuestInfo(
										'guestNameListRequired',
										!guestInfo.guestNameListRequired
									);
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputCheckBox
								title={Culture.getText(
									'PROPERTY_INFORMATION_REQUIREGUESTADDRESS'
								)}
								checked={guestInfo.guestAddressRequired}
								onClick={() => {
									property.updateGuestInfo(
										'guestAddressRequired',
										!guestInfo.guestAddressRequired
									);
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputCheckBox
								title={Culture.getText(
									'PROPERTY_INFORMATION_REQUIREGUESTCONTACTNUMBER'
								)}
								checked={guestInfo.guestContactNumberRequired}
								onClick={() => {
									property.updateGuestInfo(
										'guestContactNumberRequired',
										!guestInfo.guestContactNumberRequired
									);
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>

						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_GRACEPERIOD_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_GRACEPERIOD_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_GRACEPERIOD_HELPTEXT'
							)}
						>
							<DataInputField
								title={Culture.getText(
									'PROPERTY_INFORMATION_CANCELLATIONGRACEPRIOD_HOURSAFTERBOOKING'
								)}
								value={this.state.gracePeriodAfterBooking}
								onChange={(value) => {
									this.setState({
										gracePeriodAfterBooking: value.replace(/[^0-9,\\.]/gim, ''),
									});
								}}
								onBlur={(value) => {
									let hourValue = 0;
									if (isNaN(Culture.stringToFloatLocalised(value)) === false) {
										hourValue = Culture.stringToFloatLocalised(value);
										/* maximum grace period is 2 years */
										if (hourValue > 18000) {
											hourValue = 18000;
										}
									}

									property.setCancellationGracePeriod({
										hoursAfterBooking: hourValue,
									});
									if (hourValue > 0) {
										this.setState({
											gracePeriodAfterBooking:
												Culture.formatFloatToString(hourValue),
										});
									} else {
										this.setState({ gracePeriodAfterBooking: '' });
									}

									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputField
								title={Culture.getText(
									'PROPERTY_INFORMATION_CANCELLATIONGRACEPRIOD_WEEKSBEFORECHECKIN'
								)}
								value={this.state.gracePeriodBeforeCheckIn}
								onChange={(value) => {
									this.setState({
										gracePeriodBeforeCheckIn: value.replace(
											/[^0-9,\\.]/gim,
											''
										),
									});
								}}
								onBlur={(value) => {
									let weekValue = 0;
									if (isNaN(Culture.stringToFloatLocalised(value)) === false) {
										weekValue = Culture.stringToFloatLocalised(value);
										/* maximum grace period is 2 years */
										if (weekValue > 200) {
											weekValue = 200;
										}
									}

									property.setCancellationGracePeriod({
										weeksBeforeCheckIn: weekValue,
									});
									if (weekValue > 0) {
										this.setState({
											gracePeriodBeforeCheckIn:
												Culture.formatFloatToString(weekValue),
										});
									} else {
										this.setState({ gracePeriodBeforeCheckIn: '' });
									}

									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>

						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_AGELIMIT_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_AGELIMIT_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_AGELIMIT_HELPTEXT'
							)}
						>
							<DataInputCheckBox
								title={Culture.getText('PROPERTY_INFORMATION_AGERESTRICTION')}
								checked={guestInfo.hasAgeRestriction}
								onClick={() => {
									property.updateGuestInfo(
										'hasAgeRestriction',
										!guestInfo.hasAgeRestriction
									);
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputField
								title={Culture.getText(
									'PROPERTY_INFORMATION_AGERESTRICTIONMIN'
								)}
								disabled={!guestInfo.hasAgeRestriction}
								value={guestInfo.ageRestrictionMin}
								onChange={(value) => {
									if (isNaN(parseInt(value))) {
										value = 0;
									}
									property.updateGuestInfo(
										'ageRestrictionMin',
										parseInt(value)
									);
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputField
								title={Culture.getText(
									'PROPERTY_INFORMATION_AGERESTRICTIONMAX'
								)}
								disabled={!guestInfo.hasAgeRestriction}
								value={guestInfo.ageRestrictionMax}
								onChange={(value) => {
									if (isNaN(parseInt(value))) {
										value = 0;
									}
									property.updateGuestInfo(
										'ageRestrictionMax',
										parseInt(value)
									);
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>

						<EditorSection
							title={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_CURFEW_TITLE'
							)}
							subtitle={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_CURFEW_SUBTITLE'
							)}
							helpText={Culture.getText(
								'GENERAL_PROPERTY_RESTRICTION_CURFEW_HELPTEXT'
							)}
						>
							<DataInputCheckBox
								title={Culture.getText('PROPERTY_INFORMATION_CURFEW')}
								checked={guestInfo.hasCurfew}
								onClick={() => {
									property.updateGuestInfo('hasCurfew', !guestInfo.hasCurfew);
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputTime
								title={Culture.getText('PROPERTY_INFORMATION_CURFEWSTART')}
								disabled={!guestInfo.hasCurfew}
								value={guestInfo.curfewStart}
								onChange={(value) => {
									property.updateGuestInfo('curfewStart', value);
									this.notifyPropertyUpdate(property);
								}}
							/>

							<DataInputTime
								title={Culture.getText('PROPERTY_INFORMATION_CURFEWEND')}
								disabled={!guestInfo.hasCurfew}
								value={guestInfo.curfewEnd}
								onChange={(value) => {
									property.updateGuestInfo('curfewEnd', value);
									this.notifyPropertyUpdate(property);
								}}
							/>
						</EditorSection>
					</section>
				</SubNavigationList>
			</div>
		);
	}
}

export default InformationEditor;
