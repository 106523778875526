/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import ConnectivityEndpointView from './ConnectivityEndpointView';
import DataInputField from '../common/DataInputField';
import Culture from '../../class/Culture';
import PropertyFilterEditor from '../inventory/PropertyFilterEditor';

/**
 * This editor form allows creating and editing
 * opentravel connections and notifies the parent
 * about any changes to the configuration object
 * provided in the parameters
 */
class BookingPalConnectionEditor extends GenericProviderConnectionEditor {
    render() {
        return (
            <div className="ProviderConnectionEditor">
                <ConnectivityEndpointView channelCode={this.props.channelCode} />

                <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERAPIKEY')}
                    value={this.getConfigValue('apiKey')} onChange={(value) => {
                        this.updateConfigValue('apiKey',value);
                    }} />

                <PropertyFilterEditor list={this.getConfigValue('importList',[])}
                    title={Culture.getText('CONNECTIVITY_PROPERTYFILTERLIST')}
                    emptyText={Culture.getText('CONNECTIVITY_PROPERTYFILTERLIST_EMPTY')}
                    ariSyncOnlyText={Culture.getText('CONNECTIVITY_PROPERTYFILTERLIST_ARISYNCONLY')}
                    autoTranslateText={Culture.getText('CONNECTIVITY_PROPERTYFILTERLIST_AUTOTRANSLATE')}
                    onChange={(function(value){
                        this.updateConfigValue('importList',value);
                    }).bind(this)} />
            </div>
        );
    }
}

export default BookingPalConnectionEditor;