/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';
import ManagementUser from '../../class/ManagementUser';

import SubNavigationList from '../common/SubNavigationList';
import EditorSection from '../common/EditorSection';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';
import DataInputList from '../common/DataInputList';
import DataInputField from '../common/DataInputField';

import '../../style/UserListView.css';

/**
 * The user list allows to manage users
 * and organisation for access to the system
 */
class UserListView extends React.Component {
    state = {
        loading: false,
        list: [],
        invitationList: [],
        invitationEmail: '',
        invitiationUserRole: 'manager',
        showInvitationRevokeWindow: false,
        revokeCode: '',
        isEditMode: false,
        errorText: '',
        userAccountType: 'user',
        userName: '',
        userNameError: '',
        userRole: 'manager',
        userId: '',
        userIdError: '',
        userAccountProvider: 'microsoft',
        groupList: [],
        showCreateInvitationWindow: false,
        showEditWindow: false,
        showDeleteWindow: false,
        deleteUserName: '',
        deleteUserId: ''
    }
    
    /**
     * Fetches all initial content and
     * initialises the state for use
     */
    componentDidMount(){
        this.fetchUserList();
    }

    /**
     * Fetches the user list of all users
     * of this tenant and sets it in the state
     */
    async fetchUserList(){
        this.setState({loading: true});
        let listResult = await ApiClient.execute('/account/user/list');
        if(listResult.failed === false){
            if(Array.isArray(listResult.list)){
                let sortedList = listResult.list;
                sortedList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

                let sortedInvitationList = listResult.invitationList;
                sortedInvitationList.sort((a,b) => (a.invitationTtl > b.invitationTtl) 
                                ? 1 : ((b.invitationTtl > a.invitationTtl) ? -1 : 0));

                this.setState({
                    list: sortedList, 
                    invitationList: sortedInvitationList
                });
            }
        }
        this.setState({loading: false});
    }

    /**
     * Creates a new user invitation with
     * the details provided in the state
     */
    async createInvitation(){
        this.setState({loading: true, showCreateInvitationWindow: false});
        let createResult = await ApiClient.execute('/account/user/invite',{
            email: this.state.invitationEmail,
            userRole: this.state.invitiationUserRole,
            loginUrl: ('http://' + window.location.hostname),
            languageCode: Culture.getCultureCode()
        });

        if(createResult !== null && createResult.failed === false){
            /* invitation creation succeeded */
            this.fetchUserList();
        }else{
            /* invitation creation failed */
            this.setState({
                errorText: Culture.getText('SETTINGS_USER_UPDATE_FAILED'),
                loading: false
            });
        }
    }

    /**
     * Revokes the selected invitation code
     * with the api and renders the updated list
     */
    async revokeInvitation(){
        this.setState({loading: true, showInvitationRevokeWindow: false});
        let revokeResult = await ApiClient.execute('/account/user/revoke',{
            revokeCode: this.state.revokeCode
        });

        if(revokeResult !== null && revokeResult.failed === false){
            this.fetchUserList();
        }else{
            this.setState({
                errorText: Culture.getText('SETTINGS_USER_UPDATE_FAILED'),
                loading: false
            });
        }        
    }

    /**
     * Deletes the user currently selected
     * for deletion in the state and updates
     * the list
     */
    async deleteUser(){
        /* get the user id and update the state */
        let deleteUserId = this.state.deleteUserId;
        this.setState({loading: true, showDeleteWindow: false, deleteUserId: ''});
        
        /* execute the api call to modify the user */
        let modified = await ApiClient.execute('/account/user/modify',{
            mode: 'delete', userId: deleteUserId
        });

        /* set the error message when it failed */
        if(modified === null || modified.failed === true){
            this.setState({errorText: Culture.getText('SETTINGS_USER_UPDATE_FAILED')});
        }

        /* fetch the user list again */
        await this.fetchUserList();
    }

    /**
     * Shows the window prompting the 
     * user to confirm the deletion of
     * the selected user account
     */
    showDeleteWindow(userData){
        this.setState({
            showDeleteWindow: true, 
            deleteUserId: userData.userId,
            deleteUserName: userData.name
        });
    }

    /**
     * Creates or updates a user from the data provided
     * in the state and fetches the user list again
     */
    async updateUser(){
        if(typeof this.state.userName === 'string' && this.state.userName !== ''
            && typeof this.state.userId === 'string' && this.state.userId !== ''){
            /* required data is valid, create user and submit */
            let userObject = {
                tenant: ManagementUser.getCurrent().getActiveTenant(),
                name: this.state.userName,
                id: [
                    this.state.userAccountProvider,
                    this.state.userAccountType,
                    this.state.userId
                ].join(':'),
                role: this.state.userRole
            };

            /* update the state to loading */
            this.setState({
                loading: true,
                list: [],
                isEditMode: false,
                errorText: '',
                userAccountType: 'user',
                userName: '',
                userNameError: '',
                userRole: 'manager',
                userId: '',
                userIdError: '',
                groupList: [],
                showEditWindow: false,
                showDeleteWindow: false
            });

            /* execute the api call to modify the user */
            let modified = await ApiClient.execute('/account/user/modify',{
                mode: 'update', user: userObject
            });

            /* set the error message when it failed */
            if(modified === null || modified.failed === true){
                this.setState({errorText: Culture.getText('SETTINGS_USER_UPDATE_FAILED')});
            }

            /* fetch the user list again */
            await this.fetchUserList();
        }else{
            /* show error messages for missing data */
            if(typeof this.state.userName !== 'string' || this.state.userName === ''){
                this.setState({userNameError: Culture.getText('SETTINGS_USER_NAME_INVALID')});
            }if(typeof this.state.userId !== 'string' || this.state.userId === ''){
                this.setState({userIdError: Culture.getText('SETTINGS_USER_ID_INVALID')});
            }
        }
    }

    /**
     * Shows the edit window for the
     * selected user to be edited
     * 
     * @param {object} userData
     * the user data object to edit 
     */
    showEditWindow(userData){
        let extUserId = userData.userId;
        let extAccountType = 'user';
        let extUserProvider = 'microsoft';
        if(extUserId.indexOf(':') > 0){
            let partList = extUserId.split(':');
            if(partList.length > 2){
                extUserProvider = partList[0];
                extUserId = partList[2];
            }
        }

        let groupList = [];
        if(Array.isArray(userData.groupList)){
            groupList = userData.groupList;
        }

        this.setState({
            showEditWindow: true,
            isEditMode: true,
            userAccountProvider: extUserProvider,
            userAccountType: extAccountType,
            userName: userData.name,
            userNameError: '',
            userRole: userData.role,
            userId: extUserId,
            groupList: groupList,
            userIdError: '',
        });
    }

    /**
     * Renders the list of all users
     * and returns the components list
     */
    renderList(){
        let result = [];

        if(Array.isArray(this.state.list)){
            this.state.list.forEach((item) => {
                /* get all the user details */
                let userValue = item.userId.split(':');
                let classList = [
                    'UserListItem', 
                    ('UserListItem_Provider_'+userValue[0]),
                    ('UserListItem_AccountType_'+userValue[1])
                ];

                /* define the name value of the user */
                let userName = "—";
                if(typeof item.name === 'string' && item.name !== ''){
                    userName = item.name;
                }

                /* define the user role text */
                let userRoleText = "—";
                if(typeof item.role === 'string' && item.role !== ''){
                    userRoleText = Culture.getText('ROLE_'+item.role.toUpperCase());
                }if(Array.isArray(item.groupList)){
                    item.groupList.forEach((groupItem) => {
                        userRoleText = Culture.getText('ROLE_'+groupItem.role.toUpperCase());
                    });
                }

                /* create the component for the user */
                result.push(
                    <div key={item.userId} className={classList.join(' ')}>
                        <div className="UserListItemContent" data-provider={item.userId.split(':')[0]}>
                            <div className="UserListItemDetail" onClick={this.showEditWindow.bind(this,item)}>
                                <div className="UserListItemName">{userName}</div>
                                <div className="UserListItemRole">{userRoleText}</div>
                            </div>
                        </div>
                        {this.state.list.length > 1 &&
                            <div className="UserListItemDeleteButton"
                                onClick={this.showDeleteWindow.bind(this,item)}>
                            </div>
                        }
                    </div>
                );
            });
        }

        return result;
    }

    /**
     * Renders the list of all available users
     * and also renders the windows for editing,
     * creating and deleting users
     */
    render(){
        let deleteUserIdText = '';
        if(this.state.deleteUserId.indexOf(':') > 0){
            let partList = this.state.deleteUserId.split(':');
            if(partList.length > 2){
                deleteUserIdText = partList[2];
            }
        }

        return(
            <SubNavigationList>
                <section code="userlist" name={Culture.getText('SETTINGS_USER_LIST')}>
                    <EditorSection title={Culture.getText('SETTINGS_USER_INVITATION_LIST_TITLE')}
                        subtitle={Culture.getText('SETTINGS_USER_INVITATION_LIST_SUBTITLE')}
                        helpText={Culture.getText('SETTINGS_USER_INVITATION_LIST_HELPTEXT')}>
                        {this.state.errorText !== '' &&
                            <div className="UserListError">
                                {Culture.getText('SETTINGS_USER_UPDATE_FAILED')}
                            </div>
                        }
                        {this.state.loading === true &&
                            <div className="UserListLoading">
                                {Culture.getText('SETTINGS_USER_LIST_LOADING')}
                            </div>
                        }

                        {this.state.invitationList.length === 0 &&
                            <div className="UserInvitationListEmpty">
                                {Culture.getText('SETTINGS_USER_INVITATION_LIST_EMPTY')}
                            </div>
                        }

                        {this.state.invitationList.length > 0 &&
                            <div className="UserInvitationList">
                                {(function(){
                                    let result = [];

                                    for(const invitation of this.state.invitationList){
                                        let invitiationUrl = 'https://' + window.location.hostname
                                                        + '/?invitationCode=' + invitation.invitationCode;

                                        result.push(
                                            <div key={invitation.invitationCode} className="UserInvitationItem">
                                                <div className="UserInvitationInfo">
                                                    <div className="UserInvitationEmail">
                                                        {invitation.email}
                                                    </div>
                                                    <div className="UserInvitationExpires">
                                                        <span className="UserInvitationExpiresLabel">
                                                            {Culture.getText('SETTINGS_USER_INVITATION_EXPIRES')}
                                                        </span>
                                                        <span className="UserInvitationExpiresValue">
                                                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                                                dateStyle: 'full',
                                                                timeStyle: 'short'
                                                            })).format(invitation.invitationTtl*1000)}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="UserInvitationAction">
                                                    <StandardButton className="InvitationCopyLinkButton"
                                                        text={Culture.getText('SETTINGS_USER_INVITATION_COPYURL')} 
                                                        onClick={() => { navigator.clipboard.writeText(invitiationUrl); }} 
                                                    />

                                                    <StandardButton className="InvitationRevokeButton"
                                                        onClick={(function(code){
                                                            this.setState({
                                                                showInvitationRevokeWindow: true,
                                                                revokeCode: code
                                                            });
                                                        }).bind(this,invitation.invitationCode)} 
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }

                                    return result;
                                }).bind(this)()}
                            </div>
                        }

                        <div>
                            <StandardButton className="CreateInvitationButton"
                                text={Culture.getText('SETTINGS_USER_INVITATION_CREATE')} 
                                onClick={() => {
                                    this.setState({showCreateInvitationWindow: true});
                                }} 
                            />
                        </div>
                    </EditorSection>

                    <EditorSection title={Culture.getText('SETTINGS_USER_LIST_TITLE')}
                        subtitle={Culture.getText('SETTINGS_USER_LIST_SUBTITLE')}
                        helpText={Culture.getText('SETTINGS_USER_LIST_HELPTEXT')}>
                        {this.state.errorText !== '' &&
                            <div className="UserListError">
                                {Culture.getText('SETTINGS_USER_UPDATE_FAILED')}
                            </div>
                        }
                        {this.state.loading === true &&
                            <div className="UserListLoading">
                                {Culture.getText('SETTINGS_USER_LIST_LOADING')}
                            </div>
                        }

                        {this.state.loading === false &&
                            <div className="UserView">
                                <div className="UserList">
                                    {this.renderList()}
                                </div>

                                <StandardButton className="CreateInvitationButton"
                                    text={Culture.getText('SETTINGS_USER_CREATE')} 
                                    onClick={() => {
                                        this.setState({
                                            showEditWindow: true,
                                            isEditMode: false,
                                            userAccountType: 'user',
                                            userName: '',
                                            userNameError: '',
                                            userRole: 'manager',
                                            userId: '',
                                            userIdError: '',
                                            groupList: []
                                        });
                                    }} 
                                />
                            </div>
                        }
                    </EditorSection>

                    {this.state.showInvitationRevokeWindow === true &&
                        <ModalDrawerOverlay titleText={Culture.getText('SETTINGS_USER_INVITATION_REVOKE_TITLE')}
                            subtitleText={Culture.getText('SETTINGS_USER_INVITATION_REVOKE_SUBTITLE')}
                            introText={Culture.getText('SETTINGS_USER_INVITATION_REVOKE_PROMPT')}
                            submitButtonText={Culture.getText('SETTINGS_USER_INVITATION_REVOKE')}
                            onSubmit={this.revokeInvitation.bind(this)}
                            onClose={() => {this.setState({showInvitationRevokeWindow: false})}} />
                    }

                    {this.state.showCreateInvitationWindow === true &&
                        <ModalDrawerOverlay titleText={Culture.getText('SETTINGS_USER_INVITATION_CREATE_TITLE')}
                            subtitleText={Culture.getText('SETTINGS_USER_INVITATION_CREATE_SUBTITLE')}
                            introText={Culture.getText('SETTINGS_USER_INVITATION_LIST_HELPTEXT')}
                            submitButtonText={Culture.getText('SETTINGS_USER_INVITATION_CREATE')}
                            onSubmit={this.createInvitation.bind(this)}
                            submitDisabled={(function(){
                                let result = true;

                                const emailRegEx = new RegExp('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:'
                                                    + '\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]'
                                                    + '+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$','i');
                                if(this.state.invitationEmail.match(emailRegEx)){
                                    result = false;
                                }

                                return result;
                            }).bind(this)()}
                            onClose={() => {this.setState({showCreateInvitationWindow: false})}}>
                            <DataInputField title={Culture.getText('SETTINGS_USER_INVITATION_LIST_EMAIL')} 
                                        value={this.state.invitationEmail}
                                        onChange={(value) => this.setState({invitationEmail: value})} />

                            <DataInputList title={Culture.getText('SETTINGS_USER_ACCOUNT_ROLE')} 
                                value={this.state.invitiationUserRole} 
                                list={[
                                    {value: "supplier", text: Culture.getText('ROLE_SUPPLIER')},
                                    {value: "manager", text: Culture.getText('ROLE_MANAGER')},
                                    {value: "admin", text: Culture.getText('ROLE_ADMIN')}
                                ]} 
                                onChange={(value) => {
                                    this.setState({invitiationUserRole: value});
                            }}/>
                        </ModalDrawerOverlay>
                    }

                    {this.state.showDeleteWindow === true &&
                        <ModalDrawerOverlay titleText={Culture.getText('SETTINGS_USER_ACTION_DELETE')}
                            subtitleText={Culture.getText('SETTINGS_USER_ACTION_DELETE_SUBTITLE')}
                            introText={Culture.getText('SETTINGS_USER_ACTION_DELETE_PROMPT')}
                            submitButtonText={Culture.getText('SETTINGS_USER_ACTION_DELETE_CONFIRM')}
                            onSubmit={this.deleteUser.bind(this)}
                            onClose={() => {this.setState({showDeleteWindow: false});}}>
                            <div className="DeleteUserInfoField">
                                <div className="DeleteUserInfoFieldTitle">
                                    {Culture.getText('SETTINGS_USER_ACCOUNT_NAME')}
                                </div>
                                <div className="DeleteUserInfoFieldText">
                                    {this.state.deleteUserName}
                                </div>
                            </div>
                            <div className="DeleteUserInfoField">
                                <div className="DeleteUserInfoFieldTitle">
                                    {Culture.getText('SETTINGS_USER_ACCOUNT_USERID')}
                                </div>
                                <div className="DeleteUserInfoFieldText">
                                    {deleteUserIdText}
                                </div>
                            </div>
                        </ModalDrawerOverlay>
                    }

                    {this.state.showEditWindow === true &&
                        <ModalDrawerOverlay titleText={(function(){
                                let result = Culture.getText('SETTINGS_USER_CREATE');
                                if(this.state.isEditMode === true){ result = Culture.getText('SETTINGS_USER_ACTION_EDIT'); }
                                return result;
                            }).bind(this)()}
                            subtitleText={(function(){
                                let result = Culture.getText('SETTINGS_USER_CREATE_SUBTITLE');
                                if(this.state.isEditMode === true){ result = Culture.getText('SETTINGS_USER_ACTION_EDIT_SUBTITLE'); }
                                return result;
                            }).bind(this)()}
                            introText={(function(){
                                let result = null;
                                if(this.state.isEditMode === false){ result = Culture.getText('SETTINGS_USER_CREATE_INTRO'); }
                                return result;
                            }).bind(this)()}
                            submitButtonText={Culture.getText('SETTINGS_USER_APPLY')}
                            onSubmit={this.updateUser.bind(this)}
                            onClose={() => {this.setState({showEditWindow: false});
                        }}>
                            <DataInputList title={Culture.getText('SETTINGS_USER_PROVIDER')} 
                                value={this.state.userAccountProvider} 
                                list={[
                                    {value: "microsoft", text: Culture.getText('SETTINGS_USER_PROVIDER_MICROSOFT')},
                                    {value: "google", text: Culture.getText('SETTINGS_USER_PROVIDER_GOOGLE')}
                                ]} 
                                onChange={(value) => {
                                    this.setState({userAccountProvider: value});
                            }}/>

                            <DataInputField title={Culture.getText('SETTINGS_USER_ACCOUNT_NAME')} 
                                        value={this.state.userName} 
                                        onChange={(value) => this.setState({userName: value})}
                                        errorText={this.state.userNameError} />

                            <DataInputField title={Culture.getText('SETTINGS_USER_ACCOUNT_USERID')} 
                                        value={this.state.userId} 
                                        onChange={(value) => this.setState({userId: value})}
                                        errorText={this.state.userIdError} />

                            <DataInputList title={Culture.getText('SETTINGS_USER_ACCOUNT_ROLE')} 
                                value={this.state.userRole} 
                                list={[
                                    {value: "supplier", text: Culture.getText('ROLE_SUPPLIER')},
                                    {value: "manager", text: Culture.getText('ROLE_MANAGER')},
                                    {value: "admin", text: Culture.getText('ROLE_ADMIN')}
                                ]} 
                                onChange={(value) => {
                                    this.setState({userRole: value});
                            }}/>
                        </ModalDrawerOverlay>
                    }                    
                </section>
            </SubNavigationList>
        );
    };
}

export default UserListView;