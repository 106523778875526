/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* key to store the user data in */
const LOCALSTORAGE_USERITEMKEY = 'USERDATA';

/* require the product configuration */
const PRODUCT_CONFIG = require('../config/ProductConfig.json');

/* list of available regions for the systems */
const SystemRegionConfig = require('../config/SystemRegionConfig.json');

/**
 * Represents the frontend user of the management
 * console and provides all interfaces to managing
 * the account and authentication
 */
class ManagementUser {    
    /**
     * Constructs a management user for
     * the management console to operate
     */
    constructor(){
        this.loggedIn = false;
        this.user = null;
        this.activeTenant = '';

        /* retrieve previously stored information */
        this.getLocalUserData();
    }

    /**
     * Returns the region code of the region this
     * console is active in depending on the domain
     * of this system
     */
    static getSystemRegionCode(){
        let result = 'EU';

        let domainRegeEx = /^([a-z]*)\.([a-z]{2,3})\.([a-z]*)\.(com|net)$/gmi;
        let regionMatch = domainRegeEx.exec(window.location.hostname);
        if(Array.isArray(regionMatch)===true){
            if(regionMatch.length >= 5){
                let domainRegion = regionMatch[2].toUpperCase();
                if(Object.keys(SystemRegionConfig).includes(domainRegion)){
                    result = domainRegion;
                }
            }
        }

        return result;
    }

    /**
     * Returns the code of the current
     * edition the user is subscribed to
     */
    getProductEdition(){
        /* default edition is basic */
        let result = 'BASIC';

        let tenantConfig = this.getTenantConfig();
        if(typeof tenantConfig.accountType === 'string'){
            result = tenantConfig.accountType;
        }

        return result;
    }

    /**
     * Returns the product configuration
     * of the product this tenant has 
     * subscribed to
     */
    getProductConfig(){
        return PRODUCT_CONFIG[this.getProductEdition()];
    }

    /**
     * Returns the id of this user
     * as a string
     */
    getUserId(){
        let result = "";

        if(this.user !== null){
            if(typeof this.user.userId === 'string'){
                result = this.user.userId;
            }
        }

        return result;
    }

    /**
     * Returns the current role of the
     * user with the currently active tenant
     */
    getUserRole(){
        let result = "";

        if(this.user !== null){
            if(Array.isArray(this.user.privilegeList)){
                this.user.privilegeList.forEach((privilege) => {
                    if(privilege.tenant === this.getActiveTenant()){
                        result = privilege.role;
                    }
                });
            }
        }

        return result;
    }

    /**
     * Returns the list of configured languages
     * for the tenant as an array of string
     */
    getTenantLanguageList(){
        let result = [];

        if(Array.isArray(this.user.privilegeList)){
            this.user.privilegeList.forEach((privilege) => {
                if(privilege.tenant === this.getActiveTenant()){
                    /* this is the currently set tenant, return
                        the languages for that tenant */
                    if(typeof privilege.config === 'object' && privilege.config !== null){
                        if(Array.isArray(privilege.config.languageList)){
                            result = privilege.config.languageList;
                        }
                    }
                }
            });
        }

        return result;
    }

    /**
     * Returns the config of the currently active
     * tenant as an object or null when none is present
     */
    getTenantConfig(){
        let result = null;

        if(Array.isArray(this.user.privilegeList)){
            this.user.privilegeList.forEach((privilege) => {
                if(privilege.tenant === this.getActiveTenant()){
                    /* this is the currently set tenant, return
                        the languages for that tenant */
                    if(typeof privilege.config === 'object' && privilege.config !== null){
                        result = privilege.config;
                    }
                }
            });
        }

        return result;
    }

    /**
     * Sets the active tenant for the
     * current user to the tenant provided
     * in the parameter
     * 
     * @param {string} value
     * name of the tenant to set as active 
     */
    setActiveTenant(value){
        this.activeTenant = value;
    }

    /**
     * Returns the active tenant that
     * the user is currently working in
     */
    getActiveTenant(){
        let result = this.activeTenant;

        /* just set the first available non-root group
            for the user if no active group is set yet */
        if(result === ''){
            if(Array.isArray(this.user.privilegeList)){
                this.user.privilegeList.forEach((tenantItem) => {
                    if(tenantItem.tenant !== 'root' && result === ''){
                        result = tenantItem.tenant;
                        this.activeTenant = tenantItem.tenant;
                    }
                });
            }
        }

        return result;
    }

    /**
     * Returns true when this user is a 
     * member of the tenant for which the
     * code was provided as a parameter
     * 
     * @param {string} tenantCode
     * code of the tenant to check membership of
     */
    isMemberOfTenant(tenantCode){
        let result = false;

        if(Array.isArray(this.user.privilegeList)){
            this.user.privilegeList.forEach((item) => {
                if(item.tenant === tenantCode){
                    result = true;
                }
            });
        }

        return result;
    }

    /**
     * Loads previous authentication
     * data from the cookie if present
     */
    getLocalUserData(){
        try{
            let userData = localStorage.getItem(LOCALSTORAGE_USERITEMKEY);
            if(userData !== null && typeof userData === 'string'){
                this.user = JSON.parse(userData);
                if(this.user.expiry > (new Date().getTime())){
                    this.loggedIn = true;

                    /* get the last active group, check if it is still
                        a group the user belongs to and set it to the
                        currently active group */
                    if(typeof this.user.lastActiveTenant === "string"){
                        if(this.isMemberOfTenant(this.user.lastActiveTenant)){
                            /* set the last active group as the current */
                            this.activeTenant = this.user.lastActiveTenant;
                        }
                    }
                }else{
                    this.user = null;
                    localStorage.removeItem(LOCALSTORAGE_USERITEMKEY);
                }
            }
        }catch(ex){
            console.log('Failed to retrieve local data: ' + ex);
        }
    }

    /**
     * Updates the user data in 
     * the local storage so it
     * is updated to be available
     * on next login
     */
    updateLocalUserData(){
        this.user.lastActiveTenant = this.getActiveTenant();
        localStorage.setItem(LOCALSTORAGE_USERITEMKEY,JSON.stringify(this.user));
    }

    /**
     * Returns whether this user is logged
     * in to the management system or not.
     * True when logged in, otherwise false.
     */
    isLoggedIn(){
        return this.loggedIn;
    }

    /**
     * Removes the authenticated user data from
     * the local storage which results in the user
     * being logged out or signed out
     */
    removeAuthenticatedUser(){
        localStorage.removeItem(LOCALSTORAGE_USERITEMKEY);
        this.user = null;
        this.loggedIn = false;
    }

    /**
     * Sets the authenticated user and the
     * user information provided by the api
     * 
     * @param {object} value
     * the user object to set locally 
     */
    setAuthenticatedUser(value){
        this.user = value;
        this.loggedIn = true;

        /* set expiry for the storage item */
        value.expiry = (new Date().getTime())+86400000;

        /* store the authentication information */
        localStorage.setItem(LOCALSTORAGE_USERITEMKEY,JSON.stringify(value));
    }

    /**
     * Returns the access token required to
     * access the authenticated services of
     * the api. If none is provied, an empty
     * string will be returned.
     */
    getAccessToken(){
        let result = '';

        if(this.user !== null){
            if(typeof this.user.token === 'string'){
                result = this.user.token;
            }
        }

        return result;
    }

    /**
     * Returns the currently active management
     * user object and if no user is active, it'll
     * return a new instance of a management user
     */
    static getCurrent(){
        let result = new ManagementUser();

        if(ManagementUser.hasOwnProperty('CurrentInstance')){
            if(typeof ManagementUser.CurrentInstance === 'object'){
                result = ManagementUser.CurrentInstance;
            }
        }else{
            /* load the local user data and set the 
                singleton instance for this session */
            result.getLocalUserData();
            ManagementUser.CurrentInstance = result;
        }

        return result;
    }
}

export default ManagementUser;