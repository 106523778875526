/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the necessary core classes */
import Culture from '../../class/Culture';

/* require the components used in this component */
import StandardButton from '../common/StandardButton';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import PropertySelectInput from '../inventory/PropertySelectInput';

/* require the stylesheet for this component */
import '../../style/PropertyMappingEditor.css';
import ApiClient from '../../class/ApiClient';

/**
 * The property mapping editor allows mapping
 * local properties with the properties of the
 * channel system
 */
class SmoobuPropertyMappingEditor extends React.Component {
    state = {
        showPropertyMapWindow: false,
        internalPropertyCode: '',
        externalPropertyCode: '',
        externalPropertyList: []
    }

    /**
     * Fetches the remote property list and
     * stores it in the state for rendering
     * in the list of external properties
     */
    async fetchExternalPropertyList(){
        try{
            if(typeof this.props.apiKey === 'string' && this.props.apiKey !== ''){
                let listResponse = await ApiClient.execute('/connectivity/smoobuPropertyList',{
                    apiKey: this.props.apiKey
                });
    
                if(Array.isArray(listResponse.list)){
                    this.setState({externalPropertyList: listResponse.list});
                }
            }
        }catch(ex){
            console.log('Failed to fetch external property list: ' + ex.stack);
        }
    }

    /**
     * Returns the array with the currently
     * existing mappings and if none exists,
     * return a list with one single empty
     * item
     */
    getList(){
        let result = [];

        for(const propertyCode of Object.keys(this.props.list)){
            result.push({
                propertyCode: propertyCode,
                remotePropertyCode: this.props.list[propertyCode]
            });
        }

        if(result.length === 0){
            result.push({
                propertyCode: '',
                remotePropertyCode: ''
            });
        }

        return result;
    }

    /**
     * Updates the list and notifies the parent
     * component about the updated list with the
     * correct mapping list format as an object
     * 
     * @param {array} list
     * the array list format to be transformed to an object 
     */
    updateList(list){
        let updated = {};
        for(const item of list){
            updated[item.propertyCode] = item.remotePropertyCode;
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(updated)
        }
    }

    /**
     * Renders the mapping list an allows
     * editing each item in the list
     */
    renderList(){
        let result = [];

        let list = this.getList();
        for(let i=0; i<list.length; i++){
            result.push(
                <div key={i} className="PropertyMappingEditorListItem" 
                    data-incomplete={(list[i].propertyCode === '' || list[i].remotePropertyCode === '')}>
                    <input className="PropertyMappingEditorListItemInput" type="text" value={list[i].propertyCode} 
                        placeholder={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_PROPERTYCODE')}
                        onChange={(function(index,e){
                            list[index].propertyCode = e.target.value;
                            this.updateList(list);
                        }).bind(this,i)} />

                    <input className="PropertyMappingEditorListItemInput" type="text" value={list[i].remotePropertyCode} 
                        placeholder={(this.props.remotePropertyIdName || Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_REMOTEPROPERTYCODE'))}
                        onChange={(function(index,e){
                            list[index].remotePropertyCode = e.target.value;
                            this.updateList(list);
                        }).bind(this,i)} />

                    {list.length > 0 &&
                        <div className="PropertyMappingEditorListRemoveButton" onClick={(function(index){
                            let updated = [];
                            for(let n=0; n<list.length; n++){
                                if(n !== index){
                                    updated.push(list[n]);
                                }
                            }
                            this.updateList(updated);
                        }).bind(this,i)}></div>
                    }
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the component and allows adding
     * and removing components for the property 
     * mapping
     */
    render(){
        return(
            <div className="PropertyMappingEditor">
                <div className="PropertyMappingEditorTitle">
                    {Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING')}
                </div>
                <div className="PropertyMappingEditorList">
                    {this.renderList()}
                </div>
                <div className="PropertyMappingEditorActionList">
                    <StandardButton className="PropertyMappingEditorAddButton"
                        text={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_ADDBUTTON')}
                        onClick={() => this.setState({showPropertyMapWindow: true},this.fetchExternalPropertyList.bind(this))} />
                </div>

                {this.state.showPropertyMapWindow === true &&
                    <ModalDrawerOverlay transparentBackground={true}
                        titleText={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_ADDTITLE')}
                        subtitleText={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_ADDSUBTITLE')}
                        submitButtonText={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_CREATEBUTTON')}
                        onClose={() => this.setState({showPropertyMapWindow: false})}
                        submitDisabled={(this.state.internalPropertyCode === '' || this.state.externalPropertyCode === '')}
                        onSubmit={() => {
                            let list = this.getList();
                            list.push({
                                propertyCode: this.state.internalPropertyCode,
                                remotePropertyCode: this.state.externalPropertyCode
                            });
                            this.updateList(list);
                            this.setState({showPropertyMapWindow: false});
                        }}>
                        
                        <PropertySelectInput 
                            title={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_PROPERTYCODE')}
                            selected={(function(){
                                let result = [];

                                if(this.state.internalPropertyCode !== ''){
                                    result = [this.state.internalPropertyCode];
                                }

                                return result;
                            }).bind(this)()} 
                            onChange={(function(value){
                                if(value.length > 0){
                                    this.setState({internalPropertyCode: value[value.length-1]});
                                }else{
                                    this.setState({internalPropertyCode: ''});
                                }
                            }).bind(this)} />
                        
                            <ModalOptionListSelect transparentBackground={true}
                                title={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_EXTERNALTITLE')} 
                                emptyText={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_EXTERNALEMPTY')}
                                filterPlaceholderText={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_EXTERNALPLACEHOLDER')}
                                addButtonText={Culture.getText('CONNECTIVITY_PROVIDER_PROPERTYMAPPING_EXTERNALSELECT')} 
                                list={(function(){
                                    let result = [];

                                    for(const item of this.state.externalPropertyList){
                                        result.push({
                                            value: String(item.id),
                                            text: '(' + item.id + ') ' + item.name,
                                            checked: (String(item.id) === this.state.externalPropertyCode)
                                        });
                                    }

                                    return result;
                                }).bind(this)()}
                                onToggle={(item) => {
                                    this.setState({externalPropertyCode: item.value});
                                }} />
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default SmoobuPropertyMappingEditor;