/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';

import '../../style/FeeTaxPolicyInput.css';

import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputList from '../common/DataInputList';
import DataInputPrice from '../common/DataInputPrice';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DataInputPercent from '../common/DataInputPercent';
import OptionListSelect from '../common/OptionListSelect';

/* constants defining specific codes that need to be
    handled separately than the standard fee or tax codes */
const PARKINGFEE_CODE = 5036;
const CLEANINGFEE_CODE = 5009;
const CREDITCARDFEE_CODE = 5033;
const INTERNETFEE_CODE = 5035;
const PER_PERSON_PER_NIGHT_CHARGECODE = 21;

/**
 * This component allows to create or edit
 * fee or tax policies and also handles 
 * the policies within these policies
 */
class FeeTaxPolicyInput extends React.Component {
    state = {
        showCreateWindow: false,
        isEditMode: false,
        editIndex: null,
        inventoryCode: "",
        isPercentCharge: false,
        typeCode: 0,
        amount: 0,
        percent: 0,
        chargeType: "included",
        currencyCode: "EUR",
        chargeFrequencyCode: PER_PERSON_PER_NIGHT_CHARGECODE,
        parkingType: "on_site",
        parkingReservation: "not_available",
        parkingProperty: "public",
        internetType: "wifi",
        internetCoverage: "entire_property",
        conditionList: []
    }

    /**
     * Sets the default currency of the attached
     * property to the current currency of this
     * fee or tax policy input
     */
    componentDidMount(){
        if(typeof this.props.property === 'object' && this.props.property !== null){
            let property = new Property(this.props.property);
            this.setState({currencyCode: property.getDefaultCurrencyCode()});
        }
    }

    /**
     * Returns whether this input component
     * is set to input taxes instead of fees
     */
    isTax(){
        let result = false;

        if(this.props.isTax === true){
            result = true;
        }

        return result;
    }

    /**
     * Adds a new fee or tax or updates an
     * existing fee or tax
     */
    addFeeTaxPolicy(){
        /* default props used by all fees and taxes */
        let propList = ['typeCode', 'amount', 'percent', 'chargeType',
                'chargeFrequencyCode', 'inventoryCode', 'currencyCode',
                'conditionList'];

        /* conditional props to set if fee code matches */
        let conditionPropList = {
            [PARKINGFEE_CODE]: ['parkingType','parkingReservation','parkingProperty'],
            [INTERNETFEE_CODE]: ['internetType','internetCoverage'],
            [CLEANINGFEE_CODE]: ['conditionList']
        }

        /* set the default fee and tax props */
        let feeTaxObject = {};
        propList.forEach((propName) => {
            if(this.state.hasOwnProperty(propName) === true){
                if(this.state[propName] !== null && this.state[propName] !== "" && this.state[propName] !== 0){
                    feeTaxObject[propName] = this.state[propName];
                }
            }
        });

        /* check the conditional props */
        if(conditionPropList.hasOwnProperty(this.state.typeCode) === true){
            conditionPropList[this.state.typeCode].forEach((propName) => {
                if(this.state.hasOwnProperty(propName) === true){
                    if(this.state[propName] !== null && this.state[propName] !== "" && this.state[propName] !== 0){
                        feeTaxObject[propName] = this.state[propName];
                    }
                }
            });
        }

        /* update or create the list with all fees or taxes */
        let feeTaxList = this.props.list;
        if(this.state.isEditMode === true && typeof this.state.editIndex === 'number'){
            if(feeTaxList.length > this.state.editIndex){
                feeTaxList[this.state.editIndex] = feeTaxObject;
            }
        }else{
            if(!Array.isArray(feeTaxList)){ feeTaxList = []; }
            feeTaxList.push(feeTaxObject);
        }

        /* notify the parent about the updated list */
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(feeTaxList);
        }

        /* close the window */
        this.setState({showCreateWindow: false});
    }

    /**
     * Returns the option list with all
     * available rooms or units that this
     * policy applies to as well as an
     * empty value to associate with
     * all rooms or units
     */
    getInventoryCodeList(){
        let result = [];

        result.push({
            value: "", text: Culture.getText('PROPERTY_POLICY_INVENTORYCODE_APPLYALL')
        });

        if(typeof this.props.property === 'object' && this.props.property !== null){
            let property = new Property(this.props.property);
            let unitList = property.getGuestRoomList();
            unitList.forEach((unitItem) => {
                let unitName = (
                    unitItem.name + ' — '
                    + Culture.getText('UNIT_TYPENAME_' + unitItem.roomTypeCode) 
                    + ' (' + unitItem.roomId + ')' 
                );
                
                result.push({
                    value: unitItem.roomId, text: unitName
                });
            });
        }

        return result;
    }

    /**
     * Returns the list of available
     * charge frequencies for taxes and fees
     */
    getChargeFrequencyList(){
        let result = [];

        let chargeTypeCodeList = require('../../config/ota/OTAChargeTypeCode.json');
        Object.keys(chargeTypeCodeList).forEach((chargeKey) => {
            result.push({
                value: parseInt(chargeKey),
                text: Culture.getText('PROPERTY_CHARGEFREQUENCY_'+chargeKey)
            });
        });

        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        return result;       
    }

    /**
     * Returns all tax or fee types depending
     * on whether this input is set to tax or
     * fee mode for insertion
     */
    getTypeCodeList(){
        let result = [];

        let feeTaxTypeList = require('../../config/ota/OTAFeeTaxTypeCode.json');
        feeTaxTypeList.forEach((feeTaxCode) => {
            if(feeTaxCode.isTax === this.isTax()){
                result.push({
                    value: parseInt(feeTaxCode.code), 
                    text: Culture.getText('PROPERTY_POLICY_FEETAXTYPENAME_'+feeTaxCode.code)
                });
            }
        });

        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        return result;
    }

    /**
     * Returns the charge type list which
     * cane be inclusive, exclusive or 
     * conditional for certain charges
     */
    getChargeTypeList(){
        let result = [
            {value: "included", text: Culture.getText('PROPERTY_POLICY_CHARGETYPE_INCLUSIVE')},
            {value: "excluded", text: Culture.getText('PROPERTY_POLICY_CHARGETYPE_EXCLUSIVE')}
        ];        

        /**
         * Accepts: inclusive, exclusive or conditional. 
         * Conditional is only allowed when type code is:
         * 
         * 5009 (cleaning fees)
         */
        if(this.state.typeCode === CLEANINGFEE_CODE){
            result.push(
                {value: "conditional", text: Culture.getText('PROPERTY_POLICY_CHARGETYPE_CONDITIONAL')}
            );
        }

        return result;
    }

    /**
     * Returns true when the fee or tax code provided
     * belongs to a doubtful or dubios fee which might
     * be handled differently
     * 
     * @param {number} code
     * code of the fee or tax to check 
     */
    isDoubtfulFee(code){
        let result = false;

        let feeTaxTypeList = require('../../config/ota/OTAFeeTaxTypeCode.json');
        feeTaxTypeList.forEach((feeTaxCode) => {
            if(parseInt(feeTaxCode.code) === parseInt(code)){
                result = feeTaxCode.doubtful;
            }
        });

        return result;
    }

    /**
     * Sets the data of the fee or tax to
     * edit in the state and shows the editor
     * window with the data
     * 
     * @param {number} index
     * the index of the tax or fee to edit 
     */
    editFeeTaxItem(index){
        if(Array.isArray(this.props.list)){
            if(this.props.list.length > index){
                let feeTaxObject = this.props.list[index];

                let stateObject = Object.assign({
                    showCreateWindow: true, isEditMode: true,
                    editIndex: index, inventoryCode: "",
                    isPercentCharge: false, typeCode: 0,
                    amount: 0, percent: 0, chargeType: "included",
                    chargeFrequencyCode: PER_PERSON_PER_NIGHT_CHARGECODE,
                    parkingType: "", parkingReservation: "",
                    parkingProperty: "", internetType: "", internetCoverage: "",
                    conditionList: []
                },feeTaxObject);

                if(stateObject.percent > 0){ stateObject.isPercentCharge = true; }
                this.setState(stateObject);
            }
        }
    }

    /**
     * Renders the list of all existing fees or 
     * taxes assigned to the current list
     */
    renderList(){
        let result = [];

        if(Array.isArray(this.props.list)){
            let i = 0;
            this.props.list.forEach((item) => {
                /* determine the value for this fee or tax item */
                let itemValue = Culture.formatFloatToString(item.amount) + " " + item.currencyCode;
                if(item.percent > 0){ itemValue = item.percent + " %"; }

                result.push(
                    <div key={i} className="FeeTaxPolicyInputListItem">
                        <div className="FeeTaxPolicyInputListItemType" onClick={this.editFeeTaxItem.bind(this,i)}>
                            <div className="FeeTaxPolicyInputListItemName">
                                {Culture.getText('PROPERTY_POLICY_FEETAXTYPENAME_'+item.typeCode)}
                            </div>
                        </div>
                        <div className="FeeTaxPolicyInputListItemInfo">
                            <div className="FeeTaxPolicyInputListItemValue">
                                {itemValue}
                            </div>
                            <div className="FeeTaxPolicyInputListItemRemoveButton" onClick={(function(removeIndex){
                                /* remove the fee or tax from the list */
                                let updatedList = [];
                                for(let c=0; c<this.props.list.length; c++){
                                    if(c !== removeIndex){
                                        updatedList.push(this.props.list[c]);
                                    }
                                }

                                if(typeof this.props.onChange === 'function'){
                                    this.props.onChange(updatedList);
                                }
                            }).bind(this,i)}></div>
                        </div>
                    </div>
                );
                i++;
            });
        }

        if(result.length === 0){
            let emptyListText = Culture.getText('PROPERTY_POLICY_EMPTYFEELIST');
            if(typeof this.props.emptyListText === "string"){
                emptyListText = this.props.emptyListText;
            }

            result.push(
                <div key="listempty" className="FeeTaxPolicyInputListEmpty">
                    {emptyListText}
                </div>
            );
        }

        return result;
    }

    /**
     * Renders this component with the title, list
     * and the editor component to edit and create
     * new taxes or fees
     */
    render(){
        /* define the add button text from the props or use default */
        let addButtonText = Culture.getText('PROPERTY_POLICY_ADDFEEPOLICY');
        if(typeof this.props.addButtonText === "string"){
            addButtonText = this.props.addButtonText;
        }

        let editButtonText = Culture.getText('PROPERTY_POLICY_ADDFEEPOLICY');
        if(typeof this.props.editButtonText === "string"){
            editButtonText = this.props.editButtonText;
        }

        let feeTaxTypeTitle = Culture.getText('PROPERTY_POLICY_FEETYPE');
        if(this.props.isTax === true){
            feeTaxTypeTitle = Culture.getText('PROPERTY_POLICY_TAXTYPE');
        }

        let submitButtonText = addButtonText;
        let titleText = (this.props.addTitleText || '...');
        let subtitleText = (this.props.addSubtitleText || '...');
        if(this.state.isEditMode === true){
            submitButtonText = editButtonText;
            titleText = (this.props.editTitleText || '...');
            subtitleText = (this.props.editSubtitleText || '...');
        }

        return(
            <div className="FeeTaxPolicyInput">
                {typeof this.props.title === "string" && this.props.title !== "" &&
                    <div className="FeeTaxPolicyInputTitle">
                        {this.props.title}
                    </div>
                }

                <div className="FeeTaxPolicyInputList">
                    {this.renderList()}
                </div>

                <div>
                    <div className="FeeTaxPolicyInputAddButton" onClick={() => this.setState({
                        showCreateWindow: true, isEditMode: false, editIndex: null,
                        inventoryCode: "", isPercentCharge: false, typeCode: 0,
                        amount: 0, percent: 0, chargeType: "included",
                        chargeFrequencyCode: PER_PERSON_PER_NIGHT_CHARGECODE,
                        parkingType: "on_site", parkingReservation: "not_available",
                        parkingProperty: "public", internetType: "wifi",
                        internetCoverage: "entire_property", conditionList: []
                    })}>
                        {addButtonText}
                    </div>
                </div>

                {this.state.showCreateWindow === true &&
                    <ModalDrawerOverlay
                        onClose={() => this.setState({showCreateWindow: false})}
                        titleText={titleText} subtitleText={subtitleText}
                        submitButtonText={submitButtonText}
                        onSubmit={this.addFeeTaxPolicy.bind(this)}>
                        <DataInputList title={feeTaxTypeTitle} value={this.state.typeCode} 
                            list={this.getTypeCodeList()} onChange={(value) => {
                                this.setState({typeCode: parseInt(value)});
                        }}/>

                        {this.state.typeCode === CREDITCARDFEE_CODE &&
                            <div className="ContentWarningMessage">
                                <div className="ContentWarningMessageTitle">
                                    {Culture.getText('PROPERTY_POLICY_CREDITCARDFEE_TITLE')}
                                </div>
                                <div className="ContentWarningMessageText">
                                    {Culture.getText('PROPERTY_POLICY_CREDITCARDFEE_TEXT')}
                                </div>
                            </div>
                        }

                        {this.state.typeCode !== CREDITCARDFEE_CODE && this.isDoubtfulFee(this.state.typeCode) === true &&
                            <div className="ContentWarningMessage">
                                <div className="ContentWarningMessageTitle">
                                    {Culture.getText('PROPERTY_POLICY_DOUBTFULCHARGES_TITLE')}
                                </div>
                                <div className="ContentWarningMessageText">
                                    {Culture.getText('PROPERTY_POLICY_DOUBTFULCHARGES_TEXT')}
                                </div>
                            </div>
                        }

                        <DataInputList title={Culture.getText('PROPERTY_POLICY_CHARGETYPE')} 
                            value={this.state.chargeType} 
                            list={this.getChargeTypeList()} onChange={(value) => {
                                this.setState({chargeType: value});
                        }}/>

                        {this.state.typeCode === CLEANINGFEE_CODE && this.state.chargeType === 'conditional' &&
                            <>
                                <div className="ContentWarningMessage">
                                    <div className="ContentWarningMessageTitle">
                                        {Culture.getText('PROPERTY_POLICY_DOUBTFULCHARGES_TITLE')}
                                    </div>
                                    <div className="ContentWarningMessageText">
                                        {Culture.getText('PROPERTY_POLICY_DOUBTFULCHARGES_TEXT')}
                                    </div>
                                </div>

                                <div className="FeeTaxPolicyInputChargeCondition">
                                    <div className="FeeTaxPolicyInputChargeConditionTitle">
                                        {Culture.getText('PROPERTY_POLICY_CHARGECONDITION')}
                                    </div>
                                    <OptionListSelect list={[
                                        {
                                            value: "guest_brings_pet", 
                                            checked: this.state.conditionList.includes("guest_brings_pet"), 
                                            text: Culture.getText('PROPERTY_POLICY_CHARGECONDITION_GUESTBRINGSPET')
                                        },
                                        {
                                            value: "guest_doesnt_clean_before_checkout", 
                                            checked: this.state.conditionList.includes("guest_doesnt_clean_before_checkout"), 
                                            text: Culture.getText('PROPERTY_POLICY_CHARGECONDITION_DOESNTCLEANBEFORECHECKOUT')
                                        },
                                        {
                                            value: "guest_smokes", 
                                            checked: this.state.conditionList.includes("guest_smokes"), 
                                            text: Culture.getText('PROPERTY_POLICY_CHARGECONDITION_GUESTSMOKES')
                                        }
                                    ]} onToggle={(item) => {
                                        /* toggle the condition code in the condition list */
                                        let updatedList = this.state.conditionList;
                                        if(this.state.conditionList.includes(item.value)){
                                            updatedList = [];
                                            this.state.conditionList.forEach((conditionCode) => {
                                                if(conditionCode !== item.value){
                                                    updatedList.push(conditionCode);
                                                }
                                            });
                                        }else{
                                            updatedList.push(item.value);
                                        }
                                        this.setState({conditionList: updatedList});
                                    }} />
                                </div>
                            </>
                        }

                        <DataInputList title={Culture.getText('PROPERTY_POLICY_INVENTORYCODE')} 
                            value={this.state.inventoryCode} 
                            list={this.getInventoryCodeList()} onChange={(value) => {
                                this.setState({inventoryCode: value});
                        }}/>

                        <DataInputCheckBox title={Culture.getText('PROPERTY_POLICY_ISPERCENTAGE')} 
                                checked={this.state.isPercentCharge} onClick={() => {
                                    this.setState({isPercentCharge: !this.state.isPercentCharge});
                        }} />

                        {this.state.isPercentCharge === false &&
                            <DataInputPrice title={Culture.getText('PROPERTY_POLICY_CHARGEPRICE')} 
                                value={{value: this.state.amount, currencyCode: this.state.currencyCode}}
                                onChange={(value) => this.setState({
                                    amount: value.value, currencyCode: value.currencyCode, percent: 0
                            })} />
                        }

                        {this.state.isPercentCharge === true &&
                            <DataInputPercent title={Culture.getText('PROPERTY_POLICY_CHARGEPERCENT')} 
                                value={this.state.percent} onChange={(value) => this.setState({percent: value, amount: 0}) } />
                        }

                        <DataInputList title={Culture.getText('PROPERTY_POLICY_CHARGEFREQUENCY')} 
                            value={this.state.chargeFrequencyCode} 
                            list={this.getChargeFrequencyList()} onChange={(value) => {
                                this.setState({chargeFrequencyCode: parseInt(value)});
                        }}/>

                        {this.state.typeCode === PARKINGFEE_CODE &&
                            <>
                                <DataInputList title={Culture.getText('PROPERTY_POLICY_PARKINGTYPE')} 
                                    value={this.state.parkingType} 
                                    list={[
                                        {value: "on_site", text: Culture.getText('PROPERTY_POLICY_PARKINGTYPE_ONSITE')},
                                        {value: "location_nearby", text: Culture.getText('PROPERTY_POLICY_PARKINGTYPE_LOCATIONNEARBY')},
                                        {value: "none", text: Culture.getText('PROPERTY_POLICY_PARKINGTYPE_NONE')}
                                    ]} 
                                    onChange={(value) => {
                                        this.setState({parkingType: value});
                                }}/>

                                <DataInputList title={Culture.getText('PROPERTY_POLICY_PARKINGRESERVATION')} 
                                    value={this.state.parkingReservation} 
                                    list={[
                                        {value: "needed", text: Culture.getText('PROPERTY_POLICY_PARKINGRESERVATION_NEEDED')},
                                        {value: "not_needed", text: Culture.getText('PROPERTY_POLICY_PARKINGRESERVATION_NOTNEEDED')},
                                        {value: "not_available", text: Culture.getText('PROPERTY_POLICY_PARKINGRESERVATION_NOTAVAILABLE')}
                                    ]} 
                                    onChange={(value) => {
                                        this.setState({parkingReservation: value});
                                }}/>

                                <DataInputList title={Culture.getText('PROPERTY_POLICY_PARKINGPROPERTY')} 
                                    value={this.state.parkingProperty} 
                                    list={[
                                        {value: "private", text: Culture.getText('PROPERTY_POLICY_PARKINGPROPERTY_PRIVATE')},
                                        {value: "public", text: Culture.getText('PROPERTY_POLICY_PARKINGPROPERTY_PUBLIC')}
                                    ]} 
                                    onChange={(value) => {
                                        this.setState({parkingProperty: value});
                                }}/>
                            </>
                        }

                        {this.state.typeCode === INTERNETFEE_CODE &&
                            <>
                                <DataInputList title={Culture.getText('PROPERTY_POLICY_INTERNETTYPE')} 
                                    value={this.state.internetType} 
                                    list={[
                                        {value: "wifi", text: Culture.getText('PROPERTY_POLICY_INTERNETTYPE_WIFI')},
                                        {value: "wired", text: Culture.getText('PROPERTY_POLICY_INTERNETTYPE_WIRED')},
                                        {value: "none", text: Culture.getText('PROPERTY_POLICY_INTERNETTYPE_NONE')}
                                    ]} 
                                    onChange={(value) => {
                                        this.setState({internetType: value});
                                }}/>

                                <DataInputList title={Culture.getText('PROPERTY_POLICY_INTERNETCOVERAGE')} 
                                    value={this.state.internetCoverage} 
                                    list={[
                                        {value: "entire_property", text: Culture.getText('PROPERTY_POLICY_INTERNETCOVERAGE_ENTIREPROPERTY')},
                                        {value: "public_areas", text: Culture.getText('PROPERTY_POLICY_INTERNETCOVERAGE_PUBLICAREAS')},
                                        {value: "all_rooms", text: Culture.getText('PROPERTY_POLICY_INTERNETCOVERAGE_ALLROOMS')},
                                        {value: "some_rooms", text: Culture.getText('PROPERTY_POLICY_INTERNETCOVERAGE_SOMEROOMS')},
                                        {value: "business_centre", text: Culture.getText('PROPERTY_POLICY_INTERNETCOVERAGE_BUSINESSCENTRE')}
                                    ]} 
                                    onChange={(value) => {
                                        this.setState({internetCoverage: value});
                                }}/>
                            </>
                        }
                     </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default FeeTaxPolicyInput;