/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

/* include the style for this overlay window */
import '../../style/AccountView.css';

/**
 * Class that provides an overlay window with
 * a transparent black background
 */
class AccountView extends React.Component {
    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Sets the active tenant for the current
     * user session to the tenant selected
     * 
     * @param {string} value
     * name of the tenant to set as active 
     */
    setActiveTenant(value){
        this.props.user.setActiveTenant(value);
        if(typeof this.props.onTenantChanged === 'function'){
            this.props.onTenantChanged(value);
        }
    }

    /**
     * Renders the list of all tenants
     * that the user is a member of
     */
    renderTenantList(){
        let result = [];

        let activeTenant = this.props.user.getActiveTenant();

        /* sort the list alphabetically to make it easier to navigate */
        let privilegeList = this.props.user.user.privilegeList;
        privilegeList.sort((a,b) => (a.tenant > b.tenant) 
                    ? 1 : ((b.tenant > a.tenant) ? -1 : 0));

        privilegeList.forEach((privilege) => {
            /* exclude the system root tenant which is not
                a tenant, but the superuser sys-internal tenant */
            if(privilege.tenant !== 'root'){
                let tenantClassList = ['AccountViewTenantItem'];
                if(privilege.tenant === activeTenant){
                    tenantClassList.push('AccountViewTenantItemActive');
                }

                /* get the translated role name */
                let roleName = Culture.getText('ROLE_' + privilege.role.toUpperCase());

                result.push(
                    <div key={privilege.tenant} className={tenantClassList.join(' ')} onClick={this.setActiveTenant.bind(this,privilege.tenant)}>
                        <div className="AccountViewTenantItemName">
                            {privilege.tenant}
                        </div>
                        <div className="AccountViewTenantItemRole">
                            {roleName}
                        </div>
                    </div>
                );
            }
        });

        return result;
    }

    /**
     * Returns the number of actual valid tenants
     * that the user owns and returns them as integer
     */
    getTenantCount(){
        let result = 0;

        this.props.user.user.privilegeList.forEach((tenant) => {
            if(tenant.tenant !== 'root'){ result++; }
        });

        return result;
    }

    /**
     * Renders the component with the user
     * information, group/tenant list and
     * the sign out button
     */
    render(){
        return(
            <div className="AccountView">
                <div className="AccountViewTenantList" data-islargelist={(this.getTenantCount() > 4)} data-tenantcount={this.getTenantCount()}>
                    {this.renderTenantList()}
                </div>
            </div>
        );
    }
}

export default AccountView;