/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';
import Property from '../../class/Property';

import '../../style/ProductEditor.css';

import EditorSection from '../common/EditorSection';
import DataInputField from '../common/DataInputField';
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import ModalPolicyDocumentEditor from './ModalPolicyDocumentEditor';
import RatePlanEditor from './RatePlanEditor';
import StandardButton from '../common/StandardButton';

class ProductEditor extends React.Component {
    state = {
        editRatePlanIndex: false
    }

    /**
     * Notifies the parent component
     * about the update of the product
     * 
     * @param {object} value
     * the updated product object 
     */
    notifyUpdate(value){
        if(typeof this.props.onProductUpdated === 'function'){
            this.props.onProductUpdated(value);
        }
    }

    /**
     * Toggles a meal plan item in this
     * product. Removes it when it exists
     * or adds it if it doesn't
     * 
     * @param {object} value 
     * the meal plan option item
     */
    toggleMealPlan(value){
        let product = this.props.product;
        if(this.hasMealPlan(value.value)){
            let updatedList = [];
            product.mealPlanList.forEach((mealPlan) => {
                if(parseInt(mealPlan.code) !== parseInt(value.value)){
                    updatedList.push(mealPlan);
                }
            });

            product.mealPlanList = updatedList;
        }else{
            if(!Array.isArray(product.mealPlanList)){
                product.mealPlanList = [];
            }

            product.mealPlanList.push(this.getMealPlan(value.value));
        }

        this.notifyUpdate(product);
    }

    /**
     * Returns the meal planm for the
     * meal plan code provided in the
     * parameter
     * 
     * @param {number} code
     * the code of the meal plan to return 
     */
    getMealPlan(code){
        let result = {
            code: code,
            name: ''
        };

        let mealPlanList = require('../../config/ota/OTAMealPlanTypeCode.json');
        Object.keys(mealPlanList).forEach((mealPlanCode) => {
            if(parseInt(mealPlanCode) === parseInt(code)){
                result = {
                    code: parseInt(mealPlanCode),
                    name: mealPlanList[mealPlanCode]
                };
            }
        });

        return result;
    }

    /**
     * Returns true when this product has the
     * provided meal plan. If not, this will
     * return false
     * 
     * @param {number} code
     * meal plan code to check if it exists 
     */
    hasMealPlan(code){
        let result = false;
        
        if(Array.isArray(this.props.product.mealPlanList)){
            this.props.product.mealPlanList.forEach((mealPlan) => {
                if(parseInt(mealPlan.code) === parseInt(code)){
                    result = true;
                }
            });
        }

        return result;
    }

    /**
     * Returns the option list for the available
     * accommodation units of this property to be
     * attached to this product
     */
    getAccommodationUnitOptionList(){
        let result = [];

        let property = new Property(this.props.property);
        property.getGuestRoomList().forEach((guestRoom) => {
            /* define the title name for this room option */
            let titleName = Culture.getText('UNIT_TYPENAME_' + guestRoom.roomTypeCode) 
                            + ' — ' + guestRoom.name;

            let guestRoomSelected = false;
            this.props.product.roomTypeList.forEach((productRoom) => {
                if(productRoom.typeCode === guestRoom.roomId){
                    guestRoomSelected = true;
                }
            });

            result.push({
                value: guestRoom.roomId,
                text: titleName,
                checked: guestRoomSelected
            });            
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Toggles an accommodation unit for
     * this product. It is being removed
     * if it exists already or added if 
     * it does not exist, yet. This method
     * ensures that there is at least a 
     * single accommodation associated with
     * this product.
     * 
     * @param {object} value 
     * option for the accommodation unit
     */
    toggleAccommodationUnit(value){
        let hasUnit = false;

        let product = this.props.product;
        let updatedList = [];
        product.roomTypeList.forEach((productRoom) => {
            if(productRoom.typeCode === value.value){
                hasUnit = true;
            }else{
                updatedList.push(productRoom);
            }
        });

        if(hasUnit === false){
            updatedList.push({typeCode: value.value});
        }

        if(updatedList.length > 0){
            product.roomTypeList = updatedList;
            this.notifyUpdate(product);
        }
    }

    /**
     * Returns the option list with all
     * available meal plans 
     */
    getMealPlanOptionList(){
        let result = [];

        let mealPlanList = require('../../config/ota/OTAMealPlanTypeCode.json');
        Object.keys(mealPlanList).forEach((mealPlanCode) => {
            result.push({
                value: parseInt(mealPlanCode),
                text: Culture.getText('MEALPLANNAME_'+mealPlanCode),
                checked: this.hasMealPlan(mealPlanCode)
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Creates a new empty rate plan
     * object for this product
     */
    createRatePlan(){
        let product = this.props.product;
        if(typeof product !== 'object' || product === null){
            product = {ratePlanList: []};
        }if(!Array.isArray(product.ratePlanList)){
            product.ratePlanList = [];
        }

        /* add the new empty rate plan */
        product.ratePlanList.push({
            active: false,
            name: "",
            code: "",
            propertyId: this.props.property.code,
            pricingList: []
        });
    }

    /**
     * Updates the rate plan with the
     * value provided at the index
     * defined in the parameter
     * 
     * @param {number} index
     * the index of the rate plan to update
     *  
     * @param {object} value 
     * the rate plan object to set at the index
     */
    updateRatePlan(index,value){
        let product = this.props.product;
        if(Array.isArray(product.ratePlanList)){
            if(product.ratePlanList.length > index){
                product.ratePlanList[index] = value;
                this.notifyUpdate(product);
            }
        }
    }

    /**
     * Returns the maximum and minimum
     * occupancy for the selected units
     * in this product as an object with
     * min and max properties
     */
    getOccupancy(){
        let result = {min: 1, max: 1};

        let property = new Property(this.props.property);
        this.props.product.roomTypeList.forEach((productRoom) => {
            property.getGuestRoomList().forEach((guestRoom) => {
                if(productRoom.typeCode === guestRoom.roomId){
                    if(guestRoom.maxOccupancy > result.max){
                        result.max = guestRoom.maxOccupancy;
                    }
                }
            });
        });

        return result;
    }

    /**
     * Renders the rate plan list for this
     * product and returns a list of components
     * with each rateplan as a component
     */
    renderRatePlanItem(){
        let result = [];

        if(this.state.editRatePlanIndex !== false){
            if(Array.isArray(this.props.product.ratePlanList)){
                for(let p=0; p<this.props.product.ratePlanList.length; p++){
                    if(p === this.state.editRatePlanIndex){
                        let occupancy = this.getOccupancy();
                        let ratePlan = this.props.product.ratePlanList[p];
                        result.push(
                            <RatePlanEditor key={p} ratePlan={ratePlan} 
                                isEditAllowed={this.props.isRatePlanEditAllowed}
                                minOccupancy={occupancy.min} maxOccupancy={occupancy.max}
                                onChange={this.updateRatePlan.bind(this,p)}
                                onClose={() => this.setState({editRatePlanIndex: false})} />
                        );
                    }
                }
            }
        }

        return result;
    }

    /**
     * Renders the product editor for the
     * product provided in the props
     */
    render(){
        let product = this.props.product;
        return(
            <>
                <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME_TITLE')}
                    subtitle={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME_HELPTEXT')}>
                    <DataInputField title={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME')} 
                        value={product.name} 
                        onChange={(value) => {
                            product.name = value;
                            this.notifyUpdate(product);
                        }}
                    />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_RATEPLAN_TITLE')}
                    subtitle={Culture.getText('PROPERTY_AVAILABILITY_RATEPLAN_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_AVAILABILITY_RATEPLAN_HELPTEXT')}>
                    {this.props.isRatePlanEditAllowed === false &&
                        <div className="ConnectivityInfoMessage ConnectivityInfoMessageRatePlan">
                            <div className="ConnectivityInfoMessageTitle">
                                {Culture.getText('PROPERTY_CHANNEL_RATEPLANLOCKED_TITLE')}
                            </div>
                            <div className="ConnectivityInfoMessageText">
                                {Culture.getText('PROPERTY_CHANNEL_RATEPLANLOCKED_TEXT')}
                            </div>
                        </div>
                    }

                    <div className="ProductRatePlanList">
                        {(function(){
                            let result = [];

                            if(Array.isArray(this.props.product.ratePlanList)){
                                for(let p=0; p<this.props.product.ratePlanList.length; p++){
                                    let ratePlan = this.props.product.ratePlanList[p];
                                    result.push(
                                        <div key={p} className="ProductRatePlanListItem">
                                            <div className="ProductRatePlanListItemName" onClick={(function(ratePlanIndex){
                                                this.setState({editRatePlanIndex: ratePlanIndex});
                                            }).bind(this,p)}>
                                                {ratePlan.name}
                                            </div>
                                            {this.props.isRatePlanEditAllowed === true &&
                                                <div className="ProductRatePlanListItemDelete" 
                                                    onClick={(function(ratePlanCode){
                                                    /* remove the rate plan from the product */
                                                    let updatedList = [];
                                                    for(const existRatePlan of this.props.product.ratePlanList){
                                                        if(existRatePlan.code !== ratePlanCode){
                                                            updatedList.push(existRatePlan);
                                                        }
                                                    }
                        
                                                    /* update the product and notify about it */
                                                    let updatedProduct = this.props.product;
                                                    updatedProduct.ratePlanList = updatedList;
                                                    this.notifyUpdate(updatedProduct);
                                                }).bind(this,ratePlan.code)}></div>
                                            }
                                        </div>
                                    );
                                }
                            }

                            if(result.length === 0){
                                result = [
                                    <div key="rateplanlist_empty" className="ProductRatePlanListItem">
                                        <div className="ProductRatePlanListEmpty">
                                            {Culture.getText('PROPERTY_AVAILABILITY_RATEPLAN_EMPTY')}
                                        </div>
                                    </div>
                                ];
                            }

                            return result;
                        }).bind(this)()}
                    </div>

                    {this.props.isRatePlanEditAllowed === true &&
                        <div>
                            <StandardButton text={Culture.getText('PROPERTY_AVAILABILITY_RATEPLAN_CREATE')}
                                onClick={(function(productObject){
                                    /* create a default name for the new rate plan */
                                let ratePlanRandom = Math.floor(Math.random() * (10000 - 1000) + 1000);
                                let ratePlanName = (productObject.name + ' ' + ratePlanRandom);
                                let ratePlanCode = productObject.name.toLowerCase().replace(' ','-')
                                                    .replace(/[^a-z0-9\\-]/gmi,'')
                                                    + '-' + ratePlanRandom;

                                /* create the new rate plan in the product */
                                productObject.ratePlanList.push({
                                    active: false,
                                    name: ratePlanName,
                                    code: ratePlanCode,
                                    propertyId: productObject.propertyId,
                                    pricingList: []
                                });

                                /* notify about the product update */
                                this.notifyUpdate(productObject);
                            }).bind(this,product)} />
                        </div>
                    }
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_UNIT_TITLE')}
                    subtitle={Culture.getText('PROPERTY_AVAILABILITY_UNIT_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_AVAILABILITY_UNIT_HELPTEXT')}
                    introText={Culture.getText('PROPERTY_AVAILABILITY_UNIT_TEXT')}>
                    <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_AVAILABILITY_ADDROOM')} 
                        filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                        title={Culture.getText('PROPERTY_AVAILABILITY_ROOMLIST')}
                        list={this.getAccommodationUnitOptionList()} 
                        onToggle={this.toggleAccommodationUnit.bind(this)} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_MEALPLAN_TITLE')}
                    subtitle={Culture.getText('PROPERTY_AVAILABILITY_MEALPLAN_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_AVAILABILITY_MEALPLAN_HELPTEXT')}>
                    <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_AVAILABILITY_RATE_MEALPLAN_ADD')} 
                        filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                        title={Culture.getText('PROPERTY_AVAILABILITY_RATE_MEALPLAN')}
                        list={this.getMealPlanOptionList()} 
                        onToggle={this.toggleMealPlan.bind(this)} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_POLICY_TITLE')}
                    subtitle={Culture.getText('PROPERTY_AVAILABILITY_POLICY_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_AVAILABILITY_POLICY_HELPTEXT')}
                    introText={Culture.getText('PROPERTY_AVAILABILITY_POLICY_TEXT')}>
                    <ModalPolicyDocumentEditor policy={product.policyInfo}
                        property={this.props.property}
                        title={Culture.getText('PROPERTY_PRODUCT_POLICY_TITLE')}
                        onChange={(value) => {
                            product.policyInfo = value;
                            this.notifyUpdate(product);
                    }} />
                </EditorSection>

                {this.renderRatePlanItem()}
            </>
        );
    }
}

export default ProductEditor;