/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import '../../style/PolicyEditor.css';

import Culture from '../../class/Culture';
import Property from '../../class/Property';

import EditorSection from '../common/EditorSection';
import StandardButton from '../common/StandardButton';
import PolicyDocumentEditor from './PolicyDocumentEditor';
import SubNavigationList from '../common/SubNavigationList';

/**
 * 
 */
class PolicyEditor extends React.Component {
    /**
     * Notifies the Culture singleton about
     * this component to listen for any changes
     * in the culture configuration
     */
    componentDidMount(){
        /* Attach the handler for culture changes
            as this component uses localised text */
        Culture.attachCultureComponent(this);
    }

    /**
     * Creates a new policy default policy
     * for this property
     */
    createPolicy(){
        /* retrieve the existing policy list */
        let property = new Property(this.props.property);
        let policyList = property.getPolicyList();

        /* create the empty policy document */
        policyList.push(property.getEmptyPolicy());

        /* update the property's policy list */
        property.setPolicyList(policyList);

        /* notify the parent about the policy document */
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Updates the policy at the defined index
     * with the provided policy document object
     * 
     * @param {number} index
     * the index to update the policy at
     *  
     * @param {object} policy
     * the policy document to update 
     */
    updatePolicy(index,policy){
        let property = new Property(this.props.property);
        let policyList = property.getPolicyList();

        /* change the policy document */
        policyList[index] = policy;
        property.setPolicyList(policyList);

        /* notify the parent about the policy document */
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Removes the policy at the index
     * provided in the parameter
     * 
     * @param {number} index
     * the index at which to remove the policy 
     */
    removePolicyItem(index){
        let property = new Property(this.props.property);
        let policyList = property.getPolicyList();

        let updatedList = [];
        for(let p=0; p<policyList.length; p++){
            if(p !== index){ updatedList.push(policyList[p]); }
        }

        /* set the updated list as the new policy list */
        property.setPolicyList(updatedList);

        /* notify the parent about the policy document */
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Renders the policy list with all policy
     * documents associated with this property
     */
    renderPolicyList(){
        let result = [];

        let property = new Property(this.props.property);
        let policyList = property.getPolicyList();

        let p = 0;
        policyList.forEach((policy) => {
            /* define the title for this policy as policies do not have names */
            let policyTitle = Culture.getText('PROPERTY_POLICY_NUMBER').replace('{number}',(p+1));
            if(typeof policy.name === 'string' && policy.name !== ''){
                policyTitle = policy.name;
            }

            result.push(
                <section key={'policy'+p} code={'policy'+p} name={policyTitle}>
                    <PolicyDocumentEditor policy={policy} property={property}
                        onChange={this.updatePolicy.bind(this)}
                        subNavList={[
                            Culture.getText('PROPERTY_POLICY_NAME_TITLE'),
                            Culture.getText('PROPERTY_POLICY_CHECKINOUTTIME_TITLE'),
                            Culture.getText('PROPERTY_POLICY_ADVANCEBOOKING_TITLE'),
                            Culture.getText('PROPERTY_POLICY_ADULTSONLY_TITLE'),
                            Culture.getText('PROPERTY_POLICY_PETSPOLICY_TITLE'),
                            Culture.getText('PROPERTY_POLICY_PREPAYMENT_TITLE'),
                            Culture.getText('PROPERTY_POLICY_GUARANTEEPOLICY_TITLE'),
                            Culture.getText('PROPERTY_POLICY_CANCELLATIONPOLICY_TITLE'),
                            Culture.getText('PROPERTY_POLICY_FEETAX_TITLE')
                        ]} />

                    <EditorSection title={Culture.getText('PROPERTY_POLICY_REMOVEPOLICY_TITLE')}
                        subtitle={Culture.getText('PROPERTY_POLICY_REMOVEPOLICY_SUBTITLE')}
                        introText={Culture.getText('PROPERTY_POLICY_REMOVEPOLICY_INTROTEXT')}
                        helpText={Culture.getText('PROPERTY_POLICY_REMOVEPOLICY_HELPTEXT')}>
                        <div>
                            <StandardButton className="StandardRemoveButton"
                                text={Culture.getText('PROPERTY_POLICY_REMOVEPOLICY')} 
                                onClick={this.removePolicyItem.bind(this,p)} />
                        </div>
                    </EditorSection>
                </section>
            );

            p++;
        });

        return result;
    }

    /**
     * Renders the policy view and displays
     * information on the different policies
     */
    render(){
        return(
            <div className="InventoryEditorSectionContent PolicyEditorContent">
                <SubNavigationList>
                    {this.renderPolicyList()}
                    <section code="__create_policy" name={Culture.getText('PROPERTY_POLICY_ADDPOLICY')} type="action">
                        <EditorSection title={Culture.getText('PROPERTY_POLICY_ADDPOLICY_TITLE')}
                            subtitle={Culture.getText('PROPERTY_POLICY_ADDPOLICY_SUBTITLE')}
                            introText={Culture.getText('PROPERTY_POLICY_ADDPOLICY_INTROTEXT')}
                            helpText={Culture.getText('PROPERTY_POLICY_ADDPOLICY_HELPTEXT')}>
                            <div>
                                <StandardButton className="PolicyEditorCreatePolicyButton"
                                    text={Culture.getText('PROPERTY_POLICY_ADDPOLICY')} 
                                    onClick={this.createPolicy.bind(this)} />
                            </div>
                        </EditorSection>
                    </section>
                </SubNavigationList>
            </div>
        );
    }
}

export default PolicyEditor;