/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the style for this navigation element */
import '../../style/SubNavigationList.css';

/**
 * This component provides navigation rendering
 * and content rendering for the elements provided
 * as children in sections and thus provides navigation
 * through the provided children elements
 */
class SubNavigationList extends React.Component {
    state = {
        selected: ''
    }

    /**
     * Sets the first section as the default section
     * when the component is mounted so it will always
     * render the first section provided by default
     */
    componentDidMount(){
        let childrenArray = React.Children.toArray(this.props.children);
        if(childrenArray.length > 0){
            this.setState({selected: childrenArray[0].props.code});
        }
    }

    /**
     * Navigates the user to the subitem menu for which
     * the name was provided in the parameter or does nothing
     * when no element for the item could be found
     * 
     * @param {string} subItemName 
     * name of the sub item to navigate to
     */
    navigateToSubItem(subItemName){
        /* get all title elements and check if they have
            the requested name to scroll the user to them */
        let elementList = window.document.getElementsByClassName('ContentEditorTitleText');
        for(let e=0; e<elementList.length; e++){
            let element = elementList[e];
            if(element.textContent === subItemName){
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }
    }

    /**
     * Renders the sub-element list of the currently
     * active section and returns them as an array
     */
    renderSubMenuList(){
        let result = [];

        let childrenArray = React.Children.toArray(this.props.children);
        for(const item of childrenArray){
            if(item.props.code === this.state.selected){
                let elementList = React.Children.toArray(item.props.children);

                for(let e=0; e<elementList.length; e++){
                    if(typeof elementList[e].props === 'object' && elementList[e].props !== null){
                        if(Array.isArray(elementList[e].props['subNavList'])){
                            for(let i=0; i<elementList[e].props['subNavList'].length; i++){
                                let item = elementList[e].props['subNavList'][i];
                                result.push(
                                    <div key={'subnavlist_'+e+'_'+i} className="SubNavigationListItemSubListItem"
                                        onClick={this.navigateToSubItem.bind(this,item)}>
                                        {item}
                                    </div>
                                );
                            }
                        }else{
                            if(typeof elementList[e].props.title === 'string'
                                && elementList[e].props.title !== ''){
                                /* add this title to the sub element list */
                                result.push(
                                    <div key={'subnaveditor_'+e} className="SubNavigationListItemSubListItem"
                                        onClick={this.navigateToSubItem.bind(this,elementList[e].props.title)}>
                                        {elementList[e].props.title}
                                    </div>
                                );
                            }
                        }
                    }
                }
            }
        }

        return result;
    }

    /**
     * Renders the list of all sections and returns
     * all the title elements as array
     */
    renderList(){
        let result = [];
        
        let childrenArray = React.Children.toArray(this.props.children);
        for(const item of childrenArray){
            if(item.type === 'section'){
                let isSelected = (this.state.selected === item.props.code);
                let subMenuList = [];
                if(isSelected === true){
                    subMenuList = this.renderSubMenuList();
                }

                result.push(
                    <div key={item.props.code} 
                        className="SubNavigationListItem" 
                        data-code={item.props.code}
                        data-selected={isSelected}
                        data-itemtype={(item.props.type || 'default')}>
                        <div className="SubNavigationListItemTitle" 
                            data-code={item.props.code}
                            onClick={(function(code){
                            this.setState({selected: code});
                        }).bind(this,item.props.code)}>
                            {item.props.name}
                        </div>

                        {subMenuList.length > 0 &&
                            <div className="SubNavigationListItemSubList">
                                {subMenuList}
                            </div>
                        }
                    </div>
                );
            }
        }

        return result;
    }

    /**
     * Renders this component, the navigation list and
     * determines the component for the active section
     */
    render(){
        /* determine the section content for the
            currently active section */
        let sectionContent = [];
        let childrenArray = React.Children.toArray(this.props.children);
        for(const sectionItem of childrenArray){
            if(sectionItem.type === 'section'
                && sectionItem.props.code === this.state.selected){
                /* set the children of this section as content */
                sectionContent = sectionItem.props.children;
            }
        }

        return(
            <div className="SubNavigation">
                <div className="SubNavigationList">
                    {this.renderList()}
                </div>
                <div className="SubNavigationContent">
                    {sectionContent}
                </div>
            </div>
        );
    }
}

export default SubNavigationList;