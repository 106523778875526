/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';
import ManagementUser from '../../class/ManagementUser';

import ContentHeaderMenu from '../common/ContentHeaderMenu';
import SubNavigationList from '../common/SubNavigationList';
import EditorSection from '../common/EditorSection';
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import DataInputList from '../common/DataInputList';
import StandardButton from '../common/StandardButton';
import LoadingIndicator from '../common/LoadingIndicator';

import '../../style/SetupView.css';

/* include the list of currently supported languages for the system */
const SystemLanguageList = require('../../config/common/LanguageList.json');

/**
 * Renders the setup view to configure
 * the account and inventory for the first
 * time use of the system
 */
class SetupView extends React.Component {
    state = {
        languageList: [],
        defaultCurrencyCode: 'EUR',
        defaultLanguageCode: '',
        defaultUnitSystem: 'metric',
        configuring: false,
        failed: false
    }

    /**
     * Returns the language option list
     * for the option list input selection
     */
    getLanguageOptionList(){
        let result = [];

        SystemLanguageList.forEach((languageCode) => {
            result.push({
                value: languageCode,
                text: Culture.getText('OTA_LANGUAGE_NAME_'+languageCode.toUpperCase()),
                checked: this.state.languageList.includes(languageCode)
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Returns the option list with all available
     * currency codes to use with the system
     */
    getCurrencyOptionList(){
        let result = [];

        let currencyCodeList = require('../../config/ota/CurrencyCode.json');
        currencyCodeList.forEach((currencyCode) => {
            let currencyText = currencyCode.toUpperCase() + " ("
                                + Culture.getText('CURRENCYNAME_'+currencyCode.toUpperCase())
                                + ")";
            result.push({
                value: currencyCode,
                text: currencyText
            });
        });

        return result;
    }

    /**
     * Updates the configuration of the account
     * and locally updates the state when it
     * successfully completed
     */
    async updateConfiguration(){
        this.setState({configuring: true});

        let response = await ApiClient.execute('/account/config',{
            languageList: this.state.languageList,
            defaultCurrencyCode: this.state.defaultCurrencyCode,
            defaultLanguageCode: this.state.defaultLanguageCode,
            defaultUnitSystem: this.state.defaultUnitSystem
        });

        if(response.failed === false){
            if(typeof this.props.onCompleted === 'function'){
                this.props.onCompleted();
            }
        }else{
            this.setState({configuring: false, failed: true});
        }
    }

    /**
     * Renders the view for the initial
     * setup of the tenant account with
     * the language and currency 
     * configuration
     */
    render(){
        /* get the product configuration of this tenant */
        let productConfig = ManagementUser.getCurrent().getProductConfig();
        return(
            <>
                <ContentHeaderMenu title={Culture.getText('ACCOUNT_INITIAL_SETUP_TITLE')}
                    subtitle={Culture.getText('ACCOUNT_INITIAL_SETUP_SUBTITLE')} />
                <div className="SetupView">
                    <div className="SetupViewContent">
                        {this.state.configuring === true &&
                            <LoadingIndicator />
                        }

                        {this.state.configuring === false &&
                            <SubNavigationList>
                                <section code="setup" name={Culture.getText('ACCOUNT_INITIAL_SETUP_TITLE')}>
                                    <EditorSection title={Culture.getText('ACCOUNT_INITIAL_SETUP_TITLE')}
                                        subtitle={Culture.getText('ACCOUNT_INITIAL_SETUP_SUBTITLE')}
                                        helpText={Culture.getText('SUPPORT_HELPDESK_TICKET_HELPTEXT')}
                                        introText={Culture.getText('ACCOUNT_INITIAL_SETUP_TEXT')}>
                                        {this.state.failed === true &&
                                            <div className="SetupError">
                                                {Culture.getText('ACCOUNT_INITIAL_SETUP_FAILED')}
                                            </div>
                                        }
                                    </EditorSection>

                                    <EditorSection title={Culture.getText('ACCOUNT_LANGUAGEINFO_TITLE')}
                                        subtitle={Culture.getText('ACCOUNT_LANGUAGEINFO_SUBTITLE')}
                                        helpText={Culture.getText('ACCOUNT_LANGUAGEINFO_TEXT')}>
                                        <ModalOptionListSelect 
                                            title={Culture.getText('ACCOUNT_LANGUAGEINFO_TITLE')} 
                                            emptyText={Culture.getText('ACCOUNT_LANGUAGEINFO_PLACEHOLDER')}
                                            filterPlaceholderText={Culture.getText('ACCOUNT_LANGUAGEINFO_SEARCH_PLACEHOLDER')}
                                            addButtonText={Culture.getText('ACCOUNT_LANGUAGEINFO_ADDLANGUAGE')}
                                            list={this.getLanguageOptionList()}
                                            onToggle={(optionItem) => {
                                                let languageList = this.state.languageList;
                                                if(languageList.includes(optionItem.value)){
                                                    let updatedList = [];
                                                    for(const languageCode of languageList){
                                                        if(languageCode !== optionItem.value){
                                                            updatedList.push(languageCode);
                                                        }
                                                    }

                                                    languageList = updatedList;
                                                }else{
                                                    if(languageList.length < productConfig.languageCount){
                                                        languageList.push(optionItem.value);
                                                    }
                                                }

                                                if(this.state.defaultLanguageCode === '' && languageList.length > 0){
                                                    this.setState({defaultLanguageCode: languageList[0]});
                                                }

                                                this.setState({languageList});
                                            }} />

                                            {this.state.languageList.length > 0 &&
                                                <DataInputList title={Culture.getText('ACCOUNT_DEFAULT_LANGUAGE')} 
                                                    value={this.state.defaultLanguageCode} 
                                                    list={(function(){
                                                        let result = [];

                                                        for(const languageCode of this.state.languageList){
                                                            result.push({
                                                                value: languageCode,
                                                                text: Culture.getText('OTA_LANGUAGE_NAME_'+languageCode.toUpperCase())
                                                            });
                                                        }

                                                        return result;
                                                    }).bind(this)()} 
                                                    onChange={(value) => {
                                                        this.setState({defaultLanguageCode: value});
                                                    }}
                                                />
                                            }
                                    </EditorSection>

                                    <EditorSection title={Culture.getText('ACCOUNT_DEFAULT_SETTING_TITLE')}
                                        subtitle={Culture.getText('ACCOUNT_DEFAULT_SETTING_SUBTITLE')}
                                        helpText={Culture.getText('ACCOUNT_DEFAULT_SETTING_HELPTEXT')}>
                                        <DataInputList title={Culture.getText('ACCOUNT_DEFAULT_CURRENCY')} 
                                            value={this.state.defaultCurrencyCode} 
                                            list={this.getCurrencyOptionList()} onChange={(value) => {
                                                this.setState({defaultCurrencyCode: value});
                                        }}/>

                                        <DataInputList title={Culture.getText('ACCOUNT_DEFAULT_UNITSYSTEM')} 
                                            value={this.state.defaultUnitSystem} 
                                            list={[
                                                {value: 'metric', text: Culture.getText('SETTINGS_UNITSYSTEM_METRIC')},
                                                {value: 'imperial', text: Culture.getText('SETTINGS_UNITSYSTEM_IMPERIAL')}
                                            ]} 
                                            onChange={(value) => { this.setState({defaultUnitSystem: value});}}
                                        />
                                    </EditorSection>

                                    <EditorSection title={Culture.getText('ACCOUNT_CONFIGCONFIRM_TITLE')}
                                        subtitle={Culture.getText('ACCOUNT_CONFIGCONFIRM_SUBTITLE')}
                                        introText={Culture.getText('ACCOUNT_CONFIGCONFIRM_TEXT')}
                                        helpText={Culture.getText('ACCOUNT_CONFIGCONFIRM_HELPTEXT')}>
                                        <div>
                                            <StandardButton className="SetupConfirmButton"
                                                text={Culture.getText('ACCOUNT_INITALCONFIGURATION_BUTTONTEXT')}
                                                disabled={!(this.state.languageList.length > 0
                                                    && this.state.defaultCurrencyCode !== ''
                                                    && this.state.defaultLanguageCode !== ''
                                                    && this.state.defaultUnitSystem !== '')}
                                                onClick={this.updateConfiguration.bind(this)} 
                                            />
                                        </div>
                                    </EditorSection>
                                </section>
                            </SubNavigationList>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default SetupView;