/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';
import ManagementUser from '../../class/ManagementUser';

/* require the components for this page */
import PaymentSetup from './PaymentSetup';
import LoadingIndicator from '../common/LoadingIndicator';
import PaymentAccountView from './PaymentAccountView';
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import StandardButton from '../common/StandardButton';

/* require the style definition for this component */
import '../../style/PaymentView.css';

/**
 * Coordinates the payment management components
 * based on the state of the payment configuration
 */
class PaymentView extends React.Component {
    state = {
        loaded: false,
        account: null,
        accountList: [],
        createProviderCode: false,
        available: false
    }

    /**
     * Initially retrieve the payment account
     * of this tenant when the component mounted
     */
    componentDidMount(){
        this.getPaymentConfigList();
    }

    /**
     * Checks if payment processing and handling
     * is available for this account and update
     * the status locally
     */
    async isPaymentAvailable(){
        const config = await ApiClient.execute('/booking/getPaymentConfig');
        this.setState({available: (config.available === true)});
    }

    /**
     * Returns the list of payment accounts
     * available for the current tenant
     * 
     * @param {string} selectedProvider
     * code of the provider to select
     */
    async getPaymentConfigList(selectedProvider){
        this.setState({loaded: false});

        /* check if payment processing is available */
        await this.isPaymentAvailable();

        let configList = await ApiClient.execute('/booking/paymentConfigList',{
            tenantCode: ManagementUser.getCurrent().getActiveTenant()
        });
        if(Array.isArray(configList.list)){
            let accountObject = null;
            let accountList = configList.list;
            for(let i=0; i<accountList.length; i++){
                accountList[i].config = await ApiClient.execute('/booking/getPaymentConfig',{
                    providerCode: accountList[i].providerCode
                });

                if(accountList[i].providerCode === selectedProvider){
                    accountObject = accountList[i];
                }
            }

            /* update the state with the list and
                the configured values */
            this.setState({
                accountList: configList.list,
                account: accountObject,
                loaded: true,
                createProviderCode: false
            });
        }
    }

    /**
     * Retrieves the account of the provided
     * provider code and updates it in the state
     * 
     * @param {string} providerCode
     * code of the provider to get the account of 
     */
    async getPaymentConfig(providerCode){
        let account = this.state.account;
        account.config.config.account = await ApiClient.execute('/booking/getPaymentConfig',{
            providerCode: providerCode
        });
        this.setState({account: account});
    }

    /**
     * Creates a new payment processing account with
     * the account information provided in the parameter
     * 
     * @param {object} providerCode
     * code of the provider to create the account with
     * 
     * @param {object} accountInfo
     * account information to create account with 
     */
    async createAccount(providerCode,accountInfo){
        /* reset all the state information */
        this.setState({loaded: false, failed: false, 
            account: null, configured: false, 
            createFailed: false});

        console.log(providerCode);
        console.log(accountInfo);

        /* request the api to create a new config */
        let createResult = await ApiClient.execute('/booking/createPaymentConfig',{
            account: accountInfo,
            providerCode: providerCode
        });

        let failed = true;
        if(createResult !== null){
            if(createResult.created === true){
                this.getPaymentConfigList();
                failed = false;
            }
        }

        if(failed === true){
            /* create failed, reset the state */
            this.setState({loaded: true, failed: false, 
                account: null, configured: false, 
                createFailed: true});
        }
    }

    /**
     * Renders the account overview for the
     * specified provider and returns the 
     * rendered contents
     * 
     * @param {string} providerCode
     * code of the provider to render account of 
     */
    renderPaymentAccount(providerCode){
        /* check if the account is already configured or not */
        let account = null;
        for(let accountObject of this.state.accountList){
            if(accountObject.providerCode === providerCode){
                account = accountObject;
            }
        }

        if(account !== null){
            /* define the internal account object */
            let accountObject = account.config.config.account;
            let verificationRequired = (accountObject.verified === false);

            return(
                <div className="PaymentAccountListItem" 
                    data-provider={providerCode}
                    data-verificationrequired={verificationRequired}
                    data-configured={true}>
                    <div className="PaymentAccountListItemIcon"></div>
                    <div className="PaymentAccountListItemInfo">
                        <div className="PaymentAccountListItemTitle">
                            {accountObject.company.name}
                            <div className="PaymentAccountListItemAccountBalance">
                                {Culture.getText('PAYMENT_ACCOUNT_BALANCE_TITLE')}:&nbsp;
                                {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                    style: 'currency', 
                                    currency: accountObject.balance.available.currencyCode
                                })).format(accountObject.balance.available.amount)}
                            </div>
                        </div>
                        <div className="PaymentAccountListItemSubtitle">
                            {Culture.getText('PAYMENT_ACCOUNT_TITLE_'+providerCode.toUpperCase().replace(/-/gmi,'_'))}
                            {accountObject.bankAccount !== false &&
                                <small>
                                    &nbsp;({accountObject.bankAccount.bankName})    
                                </small>
                            }
                        </div>
                    </div>
                    <div className="PaymentAccountListItemStatus">
                        <StandardButton className="PaymentAccountListButton"
                            text={Culture.getText('PAYMENT_ACCOUNT_MANAGE')}
                            onClick={(function(account){
                                this.setState({account: account});
                            }).bind(this,account)} />
                    </div>
                </div>
            );
        }else{
            return(
                <div className="PaymentAccountListItem"
                    data-configured="false" 
                    data-provider={providerCode}>
                    <div className="PaymentAccountListItemIcon"></div>
                    <div className="PaymentAccountListItemInfo">
                        <div className="PaymentAccountListItemTitle">
                            {Culture.getText('PAYMENT_ACCOUNT_TITLE_'+providerCode.toUpperCase().replace(/-/gmi,'_'))}
                        </div>
                        <div className="PaymentAccountListItemSubtitle">
                            {Culture.getText('PAYMENT_ACCOUNT_SUBTITLE_'+providerCode.toUpperCase().replace(/-/gmi,'_'))}
                        </div>
                    </div>
                    <div className="PaymentAccountListItemStatus">
                        {this.state.available === true &&
                            <StandardButton className="PaymentAccountListButton"
                                text={Culture.getText('PAYMENT_ACCOUNT_CREATE')}
                                onClick={(function(providerCode){
                                    this.setState({createProviderCode: providerCode})
                                }).bind(this,providerCode)} />
                        }
                        {this.state.available === false &&
                            <div className="PaymentAccountListNoteEligible">
                                {Culture.getText('PAYMENT_SERVICE_NOTELIGIBLE')}
                            </div>
                        }
                    </div>
                </div>
            );
        }
    }

    /**
     * Renders the payment view with either
     * the status indicator or the list of
     * payment accounts available
     */
    render(){
        if(this.state.loaded === true){
            if(this.state.account !== null){
                return(
                    <PaymentAccountView 
                        providerCode={this.state.account.providerCode}
                        account={this.state.account.config.config.account}
                        onUpdated={this.getPaymentConfigList.bind(this,this.state.account.providerCode)}
                        onClose={() => this.setState({account: null})} />
                );
            }else{
                if(this.state.createProviderCode === false){
                    return(
                        <div className="PaymentListView">
                            <ContentHeaderMenu 
                                title={Culture.getText('PAYMENT_ACCOUNT_TITLE')} 
                                subtitle={Culture.getText('PAYMENT_ACCOUNT_SUBTITLE')} />
                            <div className="PaymentAccountContent">
                                <div className="PaymentAccountContentContainer">
                                    <div className="PaymentAccountList">
                                        {this.renderPaymentAccount('stripe')}
                                        {this.renderPaymentAccount('stripe-market')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }else{
                    return(
                        <PaymentSetup providerCode={this.state.createProviderCode}
                            showCreateError={this.state.createFailed} 
                            onCreate={this.createAccount.bind(this,this.state.createProviderCode)}
                            onClose={() => this.setState({createProviderCode: false})} />
                    );
                }
            }
        }else{
            /* render the loading indicator */
            return(
                <div className="PaymentView">
                    <LoadingIndicator />
                </div>
            );
        }
    }
}

export default PaymentView;