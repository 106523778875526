/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';
import DataInputList from '../common/DataInputList';

/**
 * Select list component that allows to select
 * a single country from a list
 */
class CountrySelectList extends React.Component {
    /**
     * Returns the list with all countries, their
     * ISO-3166-1 country code and the localised name
     * in the currently set culture
     */
     getCountryList(){
        let result = [];

        /* get the list of countries and their localised name */
        let codeList = require('../../config/common/iso31661.json');
        codeList.forEach((code) => {
            let countryName = Culture.getText('COUNTRY_' + code.toUpperCase());
            result.push({
                value: code.toLowerCase(),
                text: countryName
            });
        });

        /* sort the country list by the localised name */
        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        /* add the empty item as first element regardless of sorting */
        if(this.props.disableNone !== true){
            result = [{
                value: "",
                text: Culture.getText('COUNTRY_NONE')
            }].concat(result);
        }

        return result;
    }

    /**
     * Renders the country selection list and
     * returns the rendered component
     */
    render(){
        let countryCode = this.props.value;
        if(typeof countryCode !== 'string'){
            countryCode = '';
        }

        return(
            <DataInputList title={this.props.title} 
            value={countryCode.toLowerCase()} disabled={this.props.disabled}
            list={this.getCountryList()} onChange={(value) => {
                if(typeof this.props.onChange === 'function'){
                    this.props.onChange(value);
                }
            }}/>
        );
    }
}

export default CountrySelectList;