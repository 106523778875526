/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import '../../style/DataInputField.css';

/**
 * This component allows the input of prices
 * with a price value as a floating point number
 * and the currency code as a 3-digit ISO 4217
 * compliant currency code
 */
class DataInputPrice extends React.Component {
    state = {
        valueText: ""
    }

    /**
     * Assigns the float values to the state
     * as a string upon mount of this component
     * to have the values properly initialised
     * within the input field
     */
    componentDidMount(){
        if(typeof this.props.value === 'object'){
            if(typeof this.props.value.value === 'number'){
                if(this.props.value.value > 0){
                    this.setState({valueText: Culture.formatFloatToString(this.props.value.value)});
                }
            }
        }
    }

    /**
     * Changes the actual float value for the
     * price and notifies the parent about the
     * change. Note that this method will not
     * notify any parent component if the price
     * or value inserted is not a valid float
     * 
     * @param {object} eventObject
     * the event object provided by the input component 
     */
    changeValue(eventObject){
        let formattedValue = eventObject.target.value.replace(/[^0-9.,]/gi,'');

        this.setState({ valueText: formattedValue });
        if(typeof this.props.onChange === 'function'){
            let updatedObject = this.props.value;
            updatedObject.value = Culture.stringToFloatLocalised(formattedValue);
            if(formattedValue === ""){updatedObject.value = 0;}
            this.props.onChange(updatedObject);
        }
    }

    /**
     * Changes the currency code to the selected
     * one and notifies the parent component
     * 
     * @param {object} eventObject
     * the event object provided by the select input 
     */
    changeCurrencyCode(eventObject){
        if(typeof this.props.onChange === 'function'){
            let updatedObject = this.props.value;
            updatedObject.currencyCode = eventObject.target.value;
            this.props.onChange(updatedObject);
        }
    }

    /**
     * Renders all the required elementts for this
     * component, checks if the field is disabled
     * and other minor operations for rendering
     */
    render(){
        let classList = ['DataInputField','DataInputPrice'];
        let inputDisabled = false;
        if(this.props.disabled === true){
            classList.push('DataInputPriceDisabled');
            inputDisabled = true;
        }

        let currencyCodeList = require('../../config/ota/CurrencyCode.json');
        let currencyCode = "EUR";
        if(typeof this.props.value === 'object'){
            if(typeof this.props.value.currencyCode === 'string'){
                if(currencyCodeList.includes(this.props.value.currencyCode.toUpperCase())){
                    currencyCode = this.props.value.currencyCode.toUpperCase();
                }
            }
        }

        let currencyOptionList = [];
        currencyCodeList.forEach((currencyCode) => {
            let currencyName = Culture.getText("CURRENCYNAME_"+currencyCode.toUpperCase());
            currencyOptionList.push(
                <option key={currencyCode} value={currencyCode}>
                    {currencyCode.toUpperCase() + " (" + currencyName + ")"}
                </option>
            );
        });
        
        return(
            <div className={classList.join(' ')}>
                <div className="DataInputFieldTitle">{this.props.title}</div>
                <div className="DataInputPriceField">
                    <input className="DataInputFieldPriceValue" type="text" 
                        value={this.state.valueText}
                        onChange={this.changeValue.bind(this)} 
                        disabled={inputDisabled}
                        maxLength={14}
                        placeholder="—" />

                    <select value={currencyCode} className="DataInputFieldCurrencyCode"
                        onChange={this.changeCurrencyCode.bind(this)}
                        disabled={inputDisabled}>
                        {currencyOptionList}
                    </select>
                </div>
                {typeof this.props.errorText === 'string' && this.props.errorText !== '' &&
                    <div className="DataInputFieldErrorText">
                        {this.props.errorText}
                    </div>
                }
            </div>
        );
    }
}

export default DataInputPrice;