/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../../class/Culture';

/* require the components for this component */
import ModalDrawerOverlay from '../../common/ModalDrawerOverlay';
import OptionListSelect from '../../common/OptionListSelect';

/**
 * This component provides the language filter
 * input to set the language for the filter
 */
class MarketplaceLanguageFilter extends React.Component {
    state = {
        languageList: []
    }

    /**
     * Initialise the current filter
     * values when the component is mounted
     */
    componentDidMount(){
        this.setState({languageList: ((this.props.value || {}).languageList) || []});
    }

    /**
     * Returns the list of all applicable
     * languages for the selection
     */
    getLanguageList(){
        let result = [];

        let codeList = require('../../../config/ota/OTALanguageCodeList.json');
        const languageList = this.state.languageList || [];
        Object.keys(codeList).forEach((itemKey) => {
            result.push({
                value: itemKey,
                text: Culture.getText('OTA_LANGUAGE_NAME_'+itemKey.toUpperCase()),
                checked: (languageList.includes(itemKey))
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Toggles the language in the language
     * list removing it when it exists or
     * adding it, when it doesn't
     * 
     * @param {string} code
     * the language code to toggle in the list 
     */
    toggleLanguage(code){
        let languageList = this.state.languageList;
        const index = languageList.indexOf(code);

        if (index === -1) {
            languageList.push(code);
        } else {
            languageList.splice(index, 1);
        }

        this.setState({languageList: languageList});
    }

    /**
     * Notifies the parent component about the
     * update of the search query
     */
    notifyUpdate(){
        let searchQuery = this.props.value || {};
        if(this.state.languageList.length > 0){
            searchQuery.languageList = this.state.languageList;
        }else{
            delete searchQuery.languageList;
        }

        this.props.onChange(searchQuery);
    }

    /**
     * Renders the component with the selection
     * option for the language filter
     */
    render(){
        return(
            <div className="ModalOptionListSelect">
                <ModalDrawerOverlay className="ModalOptionListDrawerOverlay"
                    submitButtonText={Culture.getText('APPLYSELECTION_TEXT')}
                    onSubmit={this.notifyUpdate.bind(this)}
                    onClose={this.props.onClose.bind(this)}>
                    <OptionListSelect enableFilter={true}
                        filterPlaceholderText={Culture.getText('MARKETPLACE_FILTER_LANGUAGE_SEARCH_PLACEHOLDER')}
                        list={this.getLanguageList()} 
                        onToggle={(item) => this.toggleLanguage(item.value)} />
                </ModalDrawerOverlay>
            </div>
        );
    }
}

export default MarketplaceLanguageFilter;