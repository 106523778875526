/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import InventoryEditorButton from './InventoryEditorButton';
import InventoryVersionEditor from './InventoryVersionEditor';

/**
 * The inventory header component that provides
 * an overview of the current property and 
 * all the controls for it
 */
class InventoryEditorHeader extends React.Component {
    state = {
        showVersionWindow: false,
        selectedVersionId: ''
    }

    /**
     * Creates the ref for this component to
     * be able to adjust the size based on the
     * scroll position to be sticky on the top
     * 
     * @param {object} props
     * the props of this component which
     * are just passed on to the base class 
     */
    constructor(props) {
        super(props);
        this.headerRef = React.createRef();
    }

    /**
     * Attach the handler for culture changes
     * as this component uses localised text.
     * Also attaches as a scroll handler to
     * adjust the size and position of the header
     * to make it sticky while scrolling through
     * the large forms
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Sets the active item for the navigation
     * and notifies the parent component through
     * the callback method if one was attached
     * 
     * @param {string} value
     * name of the active section to set for navigation 
     */
    setActiveItem(value){
        if(typeof this.props.onNavigation === 'function'){
            this.props.onNavigation(value);
        }
    }

    /**
     * Returns true when the section has a content
     * warning when it is missing content or information
     * required for this property to be bookable
     * 
     * @param {string} sectionCode 
     * section code to return warning flag for
     */
    hasSectionWarning(sectionCode){
        let result = false;

        try{
            if(sectionCode === 'information'){
                if(this.props.property.published !== true){
                    result = true;
                }
            }if(sectionCode === 'location'){
                if(typeof this.props.property.geo !== 'object' || this.props.property.geo === null){
                    result = true;
                }else{
                    if(Object.keys(this.props.property.geo) === 0){
                        result = true;
                    }
                }
            }if(sectionCode === 'facility'){
                let hasActiveUnit = false
                for(const unit of this.props.property.facilityInfo.guestRoomList){
                    if(unit.isActive === true){ hasActiveUnit = true; }
                }

                if(hasActiveUnit === false){
                    result = true;
                }
            }if(sectionCode === 'availability'){
                let hasActiveProduct = false;
                for(const unit of this.props.property.facilityInfo.guestRoomList){
                    if(unit.isActive === true){
                        /* check if this unit has active rate plans */
                        for(const product of this.props.productList){
                            for(const productUnit of product.roomTypeList){
                                if(productUnit.typeCode === unit.roomId){
                                    /* this product is for this unit, check if it has
                                        an activated rate plan inside */
                                    for(const ratePlan of product.ratePlanList){
                                        if(ratePlan.active === true){
                                            hasActiveProduct = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if(hasActiveProduct === false){
                    result = true;
                }
            }
        }catch(ex){
            /* just ignore and return true */
            result = true;
        }

        return result;
    }

    /**
     * Renders the menu with all menu elements
     * to navigate through the editor menus
     */
    renderMenu(){
        let result = [];

        let menuList = [ 'Information', 'Location', 'Facility', 'Service', 'Multimedia', 
                            'Policy', 'Availability', 'Connectivity'];

        let activeItem = menuList[0];
        if(menuList.includes(this.props.activeItem)){
            activeItem = this.props.activeItem;
        }

        menuList.forEach((item) => {
            let classList = [
                'InventoryEditorHeaderMenuItem',
                ('InventoryEditorHeaderMenuItem'+item)
            ];

            if(item === activeItem){
                classList.push('InventoryEditorHeaderMenuItemActive');
            }

            result.push(
                <div key={item} className={classList.join(' ')} 
                    data-haswarning={this.hasSectionWarning(item.toLowerCase())}
                    onClick={this.setActiveItem.bind(this,item)}>
                    <span className="InventoryEditorHeaderMenuItemText">
                        {Culture.getText('PROPERTY_EDITOR_SECTION_'+item.toUpperCase())}
                    </span>
                </div>
            );
        });

        return result;
    }

    /**
     * Requests the parent component to close
     * this editor view and return to the
     * previous stage
     */
    closeEditor(){
        if(typeof this.props.onClose === 'function'){
            this.props.onClose();
        }
    }

    /**
     * Returns the list of localised property 
     * category names as an array of string
     * 
     * @param {array} categoryList
     * the array with all property categories 
     */
    getCategoryNameList(categoryList){
        let result = [];

        if(Array.isArray(categoryList)){
            categoryList.forEach((categoryItem) => {
                /* push the translated property category */
                result.push(Culture.getText('OTA_PROPERTY_CATEGORY_'+categoryItem.code));
            });
        }

        return result;
    }

    /**
     * Returns the formatted address text from
     * the provided geo data for the current 
     * language set for the user interface
     * 
     * @param {object} geoData
     * the localised geo data of the property 
     */
    getPropertyAddressText(geoData){
        let result = '...';

        let defaultLanguage = 'en';
        let language = Culture.getCultureCode();
        if(geoData !== null && typeof geoData === 'object'){
            if(geoData.hasOwnProperty(language)){
                result = geoData[language]['formatted_address'];
            }else{
                if(geoData.hasOwnProperty(defaultLanguage)){
                    result = geoData[defaultLanguage]['formatted_address'];
                }
            }
        }

        return result;
    }

    /**
     * Returns the main image of the property
     * that is provided in the props params
     */
    getPropertyMainImage(){
        let result = '';

        if(this.props.property !== null){
            if(Array.isArray(this.props.property.mediaList)){
                this.props.property.mediaList.forEach((mediaItem) => {
                    if(result === '' && mediaItem.isMainImage === true){
                        result = ApiClient.getMediaContentUri(this.props.property.code,mediaItem.url,
                                                            this.props.property.recordModified.time);
                    }
                });
            }
        }

        return result;
    }

    /**
     * Returns true when this property is activated
     * and bookable. Which means the property iself
     * is activated, has activated rooms, rates and
     * prices for any date in the future
     */
    isPropertyBookable(){
        let result = false;

        try{
            let property = this.props.property;
            if(property.published === true){
                for(const unit of property.facilityInfo.guestRoomList){
                    if(unit.isActive === true){
                        /* check if this unit has active rate plans */
                        for(const product of this.props.productList){
                            for(const productUnit of product.roomTypeList){
                                if(productUnit.typeCode === unit.roomId){
                                    /* this product is for this unit, check if it has
                                        an activated rate plan inside */
                                    for(const ratePlan of product.ratePlanList){
                                        if(ratePlan.active === true){
                                            result = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }catch(ex){
            /* just ignore it and return false */
            result = false;
        }

        return result;
    }

    /**
     * Requests the parent component to
     * initiate the save or upload, if
     * the handler is attached
     */
    requestSave(){
        if(typeof this.props.onRequestSave === 'function'){
            this.props.onRequestSave();
        }
    }

    /**
     * Renders the version editor window
     * if the version editor is active
     */
    renderVersionWindow(){
        let result = [];

        if(this.state.showVersionWindow === true){
            result.push(
                <ModalDrawerOverlay key="InventoryVersionEditorWindow"
                        onClose={() => this.setState({showVersionWindow: false})}
                        titleText={Culture.getText('PROPERTY_VERSION_TITLE')}
                        subtitleText={Culture.getText('PROPERTY_VERSION_SUBTITLE')}
                        introText={Culture.getText('PROPERTY_VERSION_TEXT')}
                        submitButtonText={Culture.getText('PROPERTY_VERSION_SELECTBUTTON')}
                        submitDisabled={(this.state.selectedVersionId === '')}
                        onSubmit={(function(){
                            /* determine the selected version and notify the parent */
                            let versionId = this.state.selectedVersionId;
                            if(versionId === ''){ versionId = this.props.currentVersionId; }
                            this.props.onVersionSelected(versionId);
                        }).bind(this)}>
                    <InventoryVersionEditor property={this.props.property}
                        currentVersionId={this.props.currentVersionId}
                        selectedVersionId={this.state.selectedVersionId}
                        onVersionSelected={(versionId) => {
                            this.setState({selectedVersionId: versionId});
                        }} />
                </ModalDrawerOverlay>
            );
        }

        return result;
    }
    
    /**
     * Renders the header for the editor that
     * shows details about the property and 
     * allows navigating through the data
     */
    render(){
        /* define the data and details of the property */
        let propertyName = '...';
        let propertyId = '...';
        let propertyCategoryList = ['...'];
        let propertyAddress = '...';
        let propertyImageUrl = '';

        if(this.props.property !== null){
            propertyName = this.props.property.name;
            propertyId = this.props.property.code;

            /* get the localised category name list if categories are supplied */
            if(typeof this.props.property.propertyInfo === 'object'){
                propertyCategoryList = this.getCategoryNameList(this.props.property.propertyInfo.categoryList);
            }

            /* get the localised property address text if available */
            if(typeof this.props.property.geo === 'object'){
                propertyAddress = this.getPropertyAddressText(this.props.property.geo);
            }

            /* get the main image of this property */
            propertyImageUrl = this.getPropertyMainImage();
        }

        /* define the button based on the status of the property update */
        let updateButton = <InventoryEditorButton title={Culture.getText('PROPERTY_BUTTON_UPDATED')} 
                                className="InventoryEditorButtonUpdate" />;
        if(this.props.isSynchronised === false){
            updateButton = <InventoryEditorButton title={Culture.getText('PROPERTY_BUTTON_REQUIRE_UPDATE')} 
                            className="InventoryEditorButtonUpdate InventoryEditorButtonRequireUpdate"
                            onClick={this.requestSave.bind(this)} />;

            if(this.props.isSyncFailed === true){
                updateButton = <InventoryEditorButton title={Culture.getText('PROPERTY_BUTTON_UPDATE_FAILED')} 
                            className="InventoryEditorButtonUpdate InventoryEditorButtonUpdateFailed"
                            onClick={this.requestSave.bind(this)} />;
            }else{
                if(this.props.isPostPending === true){
                    updateButton = <InventoryEditorButton title={Culture.getText('PROPERTY_BUTTON_UPDATE_PENDING')} 
                                    className="InventoryEditorButtonUpdate InventoryEditorButtonUdatePending" />;
                }
            }
        }

        /* render the component */
        return(
            <div ref={this.headerRef} className="InventoryEditorHeader">
                <div className="InventoryEditorHeaderInner">
                    <div className="InventoryEditorHeaderBackLink" onClick={this.closeEditor.bind(this)}>{Culture.getText('PROPERTY_RETURN_TO_PROPERTYLIST')}</div>
                    <div className="InventoryEditorHeaderContent">
                        <div className="InventoryEditorHeaderActionList">
                            {updateButton}

                            <InventoryEditorButton title={Culture.getText('PROPERTY_BUTTON_RELOAD')} 
                                onClick={() => {
                                    if(typeof this.props.onRequestReload === 'function'){
                                        this.props.onRequestReload();
                                    }
                                }}
                                className="InventoryEditorButtonReload" />
                            <InventoryEditorButton title={Culture.getText('PROPERTY_BUTTON_VIEW_HISTORY')} 
                                onClick={() => this.setState({
                                    showVersionWindow: true,
                                    selectedVersionId: ''
                                })}
                                className="InventoryEditorButtonHistory" />
                        </div>
                        <div className="InventoryEditorHeaderData">
                            {propertyImageUrl !== '' && 
                                <div className="InventoryEditorHeaderImage" style={{backgroundImage:('url("'+propertyImageUrl+'")')}}></div>
                            }
                            {propertyImageUrl === '' && 
                                <div className="InventoryEditorHeaderImage InventoryEditorHeaderNoImage"></div>
                            }
                            <div className="InventoryEditorPropertyInfo">
                                <div className="InventoryEditorPropertyIdentification">
                                    <div className="InventoryEditorPropertyIdentificationStatus">
                                        {this.isPropertyBookable() === false &&
                                            <div className="InventoryEditorHeaderStatus" data-bookable="false">
                                                {Culture.getText('PROPERTY_NOT_BOOKABLE')}
                                            </div>
                                        }

                                        {this.isPropertyBookable() === true &&
                                            <div className="InventoryEditorHeaderStatus" data-bookable="true">
                                                {Culture.getText('PROPERTY_BOOKABLE')}
                                            </div>
                                        }

                                        <div className="InventoryEditorPropertyIdentificationId">
                                            {propertyId}
                                        </div>    
                                    </div>

                                    <div className="InventoryEditorPropertyIdentificationName">
                                        {propertyName}
                                    </div>
                                    <div className="InventoryEditorPropertyIdentificationAddress">
                                        {propertyAddress}
                                    </div>
                                </div>

                                <div className="InventoryEditorPropertyHeaderCategoryList">
                                    {(function(){
                                        let result = [];

                                        for(const propertyCategory of propertyCategoryList){
                                            result.push(
                                                <div key={propertyCategory} className="InventoryEditorPropertyHeaderCategoryListItem">
                                                    {propertyCategory}
                                                </div>
                                            );
                                        }

                                        return result;
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="InventoryEditorHeaderMenu">
                        {this.renderMenu()}
                    </div>
                </div>

                {this.renderVersionWindow()}
            </div>
        );
    }
}

export default InventoryEditorHeader;