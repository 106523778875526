/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/**
 * Represents a product with meal plan and
 * rate plan list and the associated rooms
 */
class Product {
    /**
     * Constructs the product with the 
     * provided object structure
     * 
     * @param {object} value
     * product structure to construct with 
     */
    constructor(value){
        /* assign the values to this object */
        Object.assign(this,value);
    }

    /**
     * Returns an array with all meal plan
     * codes this product includes
     */
    getMealPlanCodeList(){
        let result = [];

        if(Array.isArray(this.mealPlanList)){
            for(const mealPlan of this.mealPlanList){
                result.push(mealPlan.code);
            }
        }

        return result;
    }

    /**
     * Returns the name of this product
     * as a string
     */
    getName(){
        return this.name;
    }

    /**
     * Returns true when this product is
     * active which translates to it having
     * at least a single active rate plan
     */
    isActive(){
        let result = false;

        for(const ratePlan of this.ratePlanList){
            if(ratePlan.active === true){
                result = true;
            }
        }

        return result;
    }
}

export default Product;