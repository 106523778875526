/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../../class/Culture';

/* require the components for this component */
import ModalDrawerOverlay from '../../common/ModalDrawerOverlay';
import OptionListSelect from '../../common/OptionListSelect';

/**
 * This component provides the service filter
 * input to set the service for the filter
 */
class MarketplaceServiceFilter extends React.Component {
    state = {
        serviceList: []
    }

    /**
     * Initialise the current filter
     * values when the component is mounted
     */
    componentDidMount(){
        this.setState({serviceList: ((this.props.value || {}).serviceList) || []});
    }

    /**
     * Returns the list of all applicable
     * service type option as an array
     */
    getServiceList(){
        let result = [];

        let codeList = require('../../../config/ota/OTAHotelAmenityCode.json');
        const serviceList = this.state.serviceList || [];
        Object.keys(codeList).forEach((itemKey) => {
            result.push({
                value: Number(itemKey),
                text: Culture.getText('PROPERTY_AMENITYNAME_'+itemKey),
                checked: (serviceList.includes(Number(itemKey)))
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Toggles the service in the service
     * list removing it when it exists or
     * adding it, when it doesn't
     * 
     * @param {number} code
     * the service code to toggle in the list 
     */
    toggleService(code){
        let serviceList = this.state.serviceList;
        const index = serviceList.indexOf(code);

        if (index === -1) {
            serviceList.push(code);
        } else {
            serviceList.splice(index, 1);
        }

        this.setState({serviceList: serviceList});
    }

    /**
     * Notifies the parent component about the
     * update of the search query
     */
    notifyUpdate(){
        let searchQuery = this.props.value || {};
        if(this.state.serviceList.length > 0){
            searchQuery.serviceList = this.state.serviceList;
        }else{
            delete searchQuery.serviceList;
        }

        this.props.onChange(searchQuery);
    }

    /**
     * Renders the component with the selection
     * option for the service filter
     */
    render(){
        return(
            <div className="ModalOptionListSelect">
                <ModalDrawerOverlay className="ModalOptionListDrawerOverlay"
                    submitButtonText={Culture.getText('APPLYSELECTION_TEXT')}
                    onSubmit={this.notifyUpdate.bind(this)}
                    onClose={this.props.onClose.bind(this)}>
                    <OptionListSelect enableFilter={true}
                        filterPlaceholderText={Culture.getText('MARKETPLACE_FILTER_SERVICE_SEARCH_PLACEHOLDER')}
                        list={this.getServiceList()} 
                        onToggle={(item) => this.toggleService(Number(item.value))} />
                </ModalDrawerOverlay>
            </div>
        );
    }
}

export default MarketplaceServiceFilter;