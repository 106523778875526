/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* import the core classes required */
import Culture from '../../class/Culture';

/* require the components for the account view */
import ContentHeaderMenu from '../common/ContentHeaderMenu';
import PaymentCompanyEditor from './PaymentCompanyEditor';
import PaymentTransactionList from './PaymentTransactionList';
import PaymentTransferList from './PaymentTransferList';

import '../../style/PaymentAccountView.css';

/**
 * This component allows editing the payment account
 * of the tenant as well as managing transactions,
 * transfers (pay-outs) and the company information
 * of this tenant's payment account
 */
class PaymentAccountView extends React.Component {
    state = {
        activeSection: 'company'
    }
    
    /**
     * Renders the payment account component with
     * the menu and all required sub-components
     */
    render(){
        const providerCode = this.props.providerCode || 'stripe';
        let menuList = [];
        let sectionList = ['company'];
        if(providerCode === 'stripe'){
            sectionList = sectionList.concat(['transaction', 'transfer']);
        }

        sectionList.forEach((sectionCode) => {
            let isActive = false;
            if(this.state.activeSection === sectionCode){
                isActive = true;
            }
            
            menuList.push({
                code: sectionCode,
                active: isActive,
                title: Culture.getText('PAYMENT_ACCOUNTSECTION_'+sectionCode.toUpperCase())
            });
        });

        return(
            <div className="PaymentAccountView" data-verified={this.props.account.verified}>
                <ContentHeaderMenu menuList={menuList} 
                        title={Culture.getText('PAYMENT_ACCOUNT_TITLE_'+providerCode.toUpperCase().replace(/-/gmi,'_'))} 
                        subtitle={Culture.getText('PAYMENT_ACCOUNT_SUBTITLE_'+providerCode.toUpperCase().replace(/-/gmi,'_'))} 
                        onChange={(code) => this.setState({activeSection: code})}
                        backLinkText={Culture.getText('PAYMENT_ACCOUNT_BACK')}
                        onBackLinkClick={this.props.onClose} />
                <div className="PaymentAccountContent">
                    <div className="PaymentAccountContentContainer">
                    {this.state.activeSection === 'transaction' &&
                        <PaymentTransactionList />
                    }
                    {this.state.activeSection === 'transfer' &&
                        <PaymentTransferList providerCode={providerCode} />
                    }
                    {this.state.activeSection === 'company' &&
                        <PaymentCompanyEditor 
                            providerCode={providerCode}
                            account={this.props.account}
                            onUpdated={(function(){
                                if(typeof this.props.onUpdated === 'function'){
                                    this.props.onUpdated();
                                }
                            }).bind(this)} />
                    }
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentAccountView;