/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the range slider component */
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

/* require the classes for this component */
import Culture from '../../../class/Culture';
import ModalDrawerOverlay from '../../common/ModalDrawerOverlay';

/**
 * This component provides the star rating filter
 * input to set the star rating for the filter
 */
class MarketplaceOccupancyFilter extends React.Component {
    state = {
        occupancyRangeValue: [1, 20],
        adultOccupancyRangeValue: [1, 20],
        childOccupancyRangeValue: [0, 20]
    }

    /**
     * Initialise the current filter
     * values when the component is mounted
     */
    componentDidMount(){
        this.setState({
            occupancyRangeValue: [
                (Number(this.props.value.minOccupancy) || 1),
                (Number(this.props.value.maxOccupancy) || 20)
            ],
            adultOccupancyRangeValue: [
                (Number(this.props.value.minAdultOccupancy) || 1),
                (Number(this.props.value.maxAdultOccupancy) || 20)
            ],
            childOccupancyRangeValue: [
                (Number(this.props.value.minChildOccupancy) || 0),
                (Number(this.props.value.maxChildOccupancy) || 20)
            ]
        });
    }

    /**
     * Notifies the parent component about
     * any updates to the filter selection
     */
    notifyUpdate(){
        let searchQuery = this.props.value;

        if(this.state.occupancyRangeValue[0] === 1 && this.state.occupancyRangeValue[1] === 20){
            delete searchQuery.minOccupancy;
            delete searchQuery.maxOccupancy;
        }else{
            searchQuery.minOccupancy = this.state.occupancyRangeValue[0];
            searchQuery.maxOccupancy = this.state.occupancyRangeValue[1];
        }
        
        if(this.state.adultOccupancyRangeValue[0] === 1 && this.state.adultOccupancyRangeValue[1] === 20){
            delete searchQuery.minAdultOccupancy;
            delete searchQuery.maxAdultOccupancy;
        }else{
            searchQuery.minAdultOccupancy = this.state.adultOccupancyRangeValue[0];
            searchQuery.maxAdultOccupancy = this.state.adultOccupancyRangeValue[1];
        }
        
        if(this.state.occupancyRangeValue[0] === 1 && this.state.occupancyRangeValue[1] === 20){
            delete searchQuery.minChildOccupancy;
            delete searchQuery.maxChildOccupancy;
        }else{
            searchQuery.minChildOccupancy = this.state.childOccupancyRangeValue[0];
            searchQuery.maxChildOccupancy = this.state.childOccupancyRangeValue[1];
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(searchQuery);
        }
    }

    /**
     * Renders the component with the selection
     * option for the star rating filter
     */
    render(){
        return(
            <ModalDrawerOverlay
                onClose={this.props.onClose}
                titleText={Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_TITLE')}
                subtitleText={Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_TEXT')}
                introText={Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_INTRO')}
                submitButtonText={Culture.getText('MARKETPLACE_FILTER_APPLY')}
                onSubmit={this.notifyUpdate.bind(this)}>
                <div className="MarketplaceOccupancyFilter MarketplaceFilter">
                    <div className="MarketplaceOccupancyFilterText">
                        {Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_TOTALOCCUPANCY')
                            .replace('{{VAR_1}}',this.state.occupancyRangeValue[0] || 1)
                            .replace('{{VAR_2}}',this.state.occupancyRangeValue[1] || 20)}
                    </div>
                    <RangeSlider
                        min={1} max={20}
                        defaultValue={[
                            (Number(this.props.value.minOccupancy) || 1),
                            (Number(this.props.value.maxOccupancy) || 20)
                        ]}
                        onInput={(value) => this.setState({occupancyRangeValue: value})} />
                </div>
                <div className="MarketplaceOccupancyFilter MarketplaceFilter">
                    <div className="MarketplaceOccupancyFilterText">
                        {Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_ADULTOCCUPANCY')
                                .replace('{{VAR_1}}',this.state.adultOccupancyRangeValue[0] || 1)
                                .replace('{{VAR_2}}',this.state.adultOccupancyRangeValue[1] || 20)}
                    </div>
                    <RangeSlider
                        min={1} max={20}
                        defaultValue={[
                            (Number(this.props.value.minAdultOccupancy) || 1),
                            (Number(this.props.value.maxAdultOccupancy) || 20)
                        ]}
                        onInput={(value) => this.setState({adultOccupancyRangeValue: value})} />
                </div>
                <div className="MarketplaceOccupancyFilter MarketplaceFilter">
                    <div className="MarketplaceOccupancyFilterText">
                        {Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_CHILDOCCUPANCY')
                                .replace('{{VAR_1}}',this.state.childOccupancyRangeValue[0] || 0)
                                .replace('{{VAR_2}}',this.state.childOccupancyRangeValue[1] || 20)}
                    </div>
                    <RangeSlider
                        min={0} max={20}
                        defaultValue={[
                            (Number(this.props.value.minChildOccupancy) || 0),
                            (Number(this.props.value.maxChildOccupancy) || 20)
                        ]}
                        onInput={(value) => this.setState({childOccupancyRangeValue: value})} />
                </div>
            </ModalDrawerOverlay>
        );
    }
}

export default MarketplaceOccupancyFilter;