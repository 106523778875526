/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Cropper from 'react-easy-crop'

import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

import ModalDrawerOverlay from './ModalDrawerOverlay';
import StandardButton from './StandardButton';

import '../../style/DataInputField.css';

/* set maximum size to 64 megabytes as otherwise
    the UI will become insanely slow with larger
    files and thus should not process them */
const MAX_UPLOAD_FILESIZE = 64000000;

/**
 * Input component to select a single image
 * and display and selected image.
 */
class DataInputImageSelect extends React.Component {
    state = {
        showCropWindow: false,
        cropImageContent: '',
        cropImageMimeType: '',
        cropZoom: 1,
        cropValue: {x: 0, y: 0},
        fileTypeError: false
    }

    /**
     * Constructs this component and creates
     * the reference for the file input
     */    
    constructor(){
        super();
        this.fileInput = React.createRef(null);
    }

    /**
     * Returns an image element for the
     * source url provided in the parameter
     * 
     * @param {string} sourceUrl 
     * url of the image to return element for
     */
    async getImage(sourceUrl){
        return await (new Promise((resolve, reject) => {
            let image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.src = sourceUrl;
        }));
    }

    /**
     * Return the target mime type for
     * this image as configured in the
     * props or return the default
     */
    getTargetMimeType(){
        let result = 'image/png';

        if(typeof this.props.targetMimeType === 'string' && this.props.targetMimeType !== ''){
            result = this.props.targetMimeType;
        }

        return result;
    }

    /**
     * Crops the image with the defined parameters
     * and returns the contents as an object with
     * the base64 parameters and the mime type
     */
    async getCroppedImage(){
        /* construct the source and the image object */
        let imageSource = ('data:' + this.state.cropImageMimeType 
                        + ';base64,' + this.state.cropImageContent);
        let image = await this.getImage(imageSource);

        /* initialize the canvas to render the final image */
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const flip = { horizontal: false, vertical: false };
        const pixelCrop = this.state.croppedAreaPixels;

        /* ensure the context was initialized */
        if (!ctx) { return null }

        /* set canvas size to match the bounding box */
        canvas.width = image.width;
        canvas.height = image.height;

        /* translate canvas context to a central location 
            to allow rotating and flipping around the center */
        ctx.translate(image.width / 2, image.height / 2)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        /* draw rotated image */
        ctx.drawImage(image,0,0);

        /* croppedAreaPixels values are bounding box relative
            extract the cropped image using these values */
        const data = ctx.getImageData(pixelCrop.x,pixelCrop.y,
                            pixelCrop.width,pixelCrop.height);

        /* set canvas width to final desired crop size 
            as this will clear existing context */
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        /* paste generated image at the top left corner */
        ctx.putImageData(data,0,0);

        /* get the image element for the final cropped version */
        let croppedImageBase64 = canvas.toDataURL('image/png');
        let croppedImage = await this.getImage(croppedImageBase64);

        /* set the canvas to the required size, get the image
            and draw it in the required size of the image */
        canvas.width = this.props.targetSize.width;
        canvas.height = this.props.targetSize.height;

        /* draw the image in the canvas and in the target size */
        ctx.drawImage(croppedImage,0,0,croppedImage.width,croppedImage.height,0,0,
                        this.props.targetSize.width,this.props.targetSize.height);
        const croppedData = ctx.getImageData(0,0,this.props.targetSize.width,this.props.targetSize.height);
        ctx.putImageData(croppedData,0,0);

        /* set the quality of jpeg and webp to 80% to reduce the
            filesize while keeping good quality images */
        let targetMimeType = this.getTargetMimeType();
        let imageQualityValue = 0.8;
        if((targetMimeType === 'image/jpeg' || targetMimeType === 'image/webp')
            && isNaN(this.props.targetQuality === false)
            && this.props.targetQuality > 0 && this.props.targetQuality <= 1){
            imageQualityValue = this.props.targetQuality;
        }

        /* return the image as a jpeg data url */
        return canvas.toDataURL(targetMimeType,imageQualityValue);
    }

    /**
     * Notifies the parent component about
     * the change of the image content
     * 
     * @param {object} value
     * object with content and mimeType properties
     * or null if the image was removed
     * 
     * @param 
     */
    async updateImageContent(value,crop){
        /* check if the image contents need to be
            cropped to the desired selection */
        let imageData = value;
        if(crop === true){
            /* perform the actual image crop operation */
            let croppedImageData = await this.getCroppedImage();

            /* extract the base64 content string from the image string */
            let imageBase64 = croppedImageData.substring(croppedImageData.indexOf(';base64,')+8);

            imageData = {
                content: imageBase64,
                mimeType: this.getTargetMimeType()
            };
        }        

        /* notify the parent with the image data */
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(imageData);
        }

        /* reset the crop information of this control */
        this.setState({
            showCropWindow: false,
            cropImageContent: '',
            cropImageMimeType: '',
            fileTypeError: false
        });
    }    

    /**
     * Converts the provided array buffer
     * into a base64 string and returns it
     * 
     * @param {ArrayBuffer} buffer 
     * the array buffer to convert to base64
     */
    getBase64FromArrayBuffer(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[ i ]);
        }
        return window.btoa(binary);
    }

    /**
     * Returns the dimension of the provided
     * image as an object with width and height
     * or false if the dimensions could not
     * be determined
     * 
     * @param {string} base64Content 
     * base64 content of the image
     * 
     * @param {string} mimeType 
     * mime type of the image
     */
    async getImageDimension(base64Content,mimeType){
        let result = false;

        try{
            let imageSourceString = ('data:' + mimeType +';base64,' + base64Content);
            let imagePromise = new Promise((resolve, reject) => {
                let img = new Image()
                img.onload = () => resolve({
                    width: img.width,
                    height: img.height
                })
                img.onerror = reject
                img.src = imageSourceString
            });
            result = await imagePromise;
        }catch(ex){
            console.log('Failed to determine image dimensions: ' + ex);
        }

        return result;
    }

    /**
     * Processes the media file and sets
     * the state to updated in order to
     * upload the image
     */
    async handleFileSelect(){
        let maxFileSize = MAX_UPLOAD_FILESIZE;
        if(typeof this.props.maxFileSize === 'number'
            && this.props.maxFileSize < MAX_UPLOAD_FILESIZE){
            maxFileSize = this.props.maxFileSize;
        }

        let fileTypeList = [];
        if(Array.isArray(this.props.fileTypeList)){
            fileTypeList = this.props.fileTypeList;
        }

        if(this.fileInput !== null){
            if(this.fileInput.current !== null){
                let fileList = this.fileInput.current.files;
                if(fileList.length > 0){
                    let fileObject = fileList[0];

                    let isAllowedFileType = false;
                    if(fileTypeList.includes(fileObject.type) === true
                        || fileTypeList.length === 0){
                        isAllowedFileType = true;
                    }

                    if(fileObject.size <= maxFileSize && isAllowedFileType === true){
                        let fileContent = await ApiClient.getFileContent(fileObject);
                        let base64Content = this.getBase64FromArrayBuffer(fileContent);
                        let mimeType = fileObject.type;

                        /* check if the image already complies with the required
                            format provided in the props and if not, adjust the
                            image to the required target size */
                        let imageComplete = true;
                        if(typeof this.props.targetSize === 'object' && this.props.targetSize !== null){
                            let targetSize = this.props.targetSize;
                            let imageDimension = await this.getImageDimension(base64Content,mimeType);
                            imageComplete = (imageDimension.width === targetSize.width 
                                            && imageDimension.height === targetSize.height);
                        }

                        /* also mark the image complete when it is an SVG image */
                        if(mimeType === 'image/svg+xml'){ imageComplete = true; }

                        if(imageComplete){
                            this.updateImageContent({
                                content: base64Content,
                                mimeType: mimeType
                            },false);
                        }else{
                            /* show the crop window for this image */
                            this.setState({
                                showCropWindow: true,
                                cropImageContent: base64Content,
                                cropImageMimeType: mimeType,
                                fileTypeError: false
                            });
                        }
                    }else{
                        /* show the file type and size error */
                        this.setState({fileTypeError: true});
                    }
                }
            }
        }
    }

    /**
     * Opens the file selection dialog
     * to pick a file for upload
     */
    selectMediaFile(){
        if(this.fileInput !== null){
            if(this.fileInput.current !== null){
                this.fileInput.current.click();
            }
        }
    }

    /**
     * Renders the image input select component
     * with the selected image and the controls
     */
    render(){
        let imageContent = this.props.content;
        let imageMimeType = 'image/jpeg';
        let imageBase64;
        if(typeof imageContent !== 'string' || imageContent === ''){
            imageContent = '';
        }else{
            if(typeof this.props.sourceMimeType === 'string'
                && this.props.sourceMimeType !== ''){
                imageMimeType = this.props.sourceMimeType;
            }

            imageBase64 = imageContent;
            imageContent = 'data:' + imageMimeType 
                        + ';base64,' + imageContent;
        }

        /* define the image view element */
        let imageViewHtml = null;
        if(imageContent !== ''){
            imageViewHtml = <img src={imageContent} alt={this.props.title} />;
            if(imageMimeType === 'image/svg+xml'){
                let svgImageXml = Buffer.from(imageBase64,'base64').toString('utf8');
                let maxWidth = 1280;
                let maxHeight = 720;
                if(typeof this.props.targetSize === 'object' && this.props.targetSize !== null){
                    maxWidth = this.props.targetSize.width;
                    maxHeight = this.props.targetSize.height;
                }

                imageViewHtml = <div
                    className='DataInputImageSelectSvgImage'
                    style={{
                        maxHeight: maxHeight, maxWidth: maxWidth,
                        minHeight: maxHeight, minWidth: maxWidth
                    }} 
                    dangerouslySetInnerHTML={{__html: svgImageXml}} />;
            }
        }

        return(
            <div className="DataInputField DataInputImageSelect">
                <div className="DataInputFieldTitle">{this.props.title}</div>
                {typeof this.props.infoText === 'string' && this.props.infoText !== '' && 
                    <div className="DataInputImageSelectInfoText">
                        {this.props.infoText}
                    </div>
                }

                {this.state.fileTypeError === true &&
                    <div className="DataInputImageSelectError">
                        {Culture.getText('IMAGESELECT_FILETYPEERROR')}
                    </div>
                }

                {imageContent === '' &&
                    <StandardButton className="DataInputImageSelectButton"
                        text={Culture.getText('IMAGESELECT_ADDIMAGE')} 
                        onClick={this.selectMediaFile.bind(this)} />
                }

                <div className="DataInputImageContent" data-selected={(imageContent !== '')}>
                    <div className="DataInputImageSelectArea" onClick={this.selectMediaFile.bind(this)}>
                        {imageViewHtml}

                        <input ref={this.fileInput} type="file" 
                            className="DataInputImageSelectInput"
                            onChange={this.handleFileSelect.bind(this)} />
                    </div>

                    {imageContent !== '' &&
                        <div className="DataInputImageRemoveButton"
                            onClick={this.updateImageContent.bind(this,null,false)}>
                        </div>
                    }
                </div>

                {this.state.showCropWindow === true &&
                    <ModalDrawerOverlay className="DataInputImageCropWindow"
                        onClose={() => this.setState({
                            showCropWindow: false,
                            cropImageContent: '',
                            cropImageMimeType: ''
                        })}
                        titleText={Culture.getText('IMAGESELECT_CROP_TITLE')}
                        subtitleText={Culture.getText('IMAGESELECT_CROP_SUBTITLE')}
                        submitButtonText={Culture.getText('IMAGESELECT_CROP_APPLY')}
                        introText={Culture.getText('IMAGESELECT_CROP_INFOTEXT')}
                        transparentBackground={(this.props.transparentBackground || false)}
                        onSubmit={(function(){
                            this.updateImageContent({
                                content: this.state.cropImageContent,
                                mimeType: this.state.cropImageMimeType,
                            },true);
                        }).bind(this)}>
                        <div className="DataInputImageCropContainer">
                            <Cropper
                                image={(
                                    'data:' + this.state.cropImageMimeType 
                                    + ';base64,' + this.state.cropImageContent
                                )}
                                crop={this.state.cropValue}
                                minZoom={-10}
                                maxZoom={10}
                                objectFit="contain"
                                zoom={this.state.cropZoom}
                                onZoomChange={(value) => this.setState({cropZoom: value})}
                                aspect={(function(){
                                    let result = (1280 / 720);
                                    if(typeof this.props.targetSize === 'object' && this.props.targetSize !== null){
                                        result = (this.props.targetSize.width / this.props.targetSize.height);
                                    }
                                    return result;
                                }).bind(this)()} 
                                onCropChange={(value) => this.setState({cropValue: value})}
                                onCropComplete={(function(croppedArea, croppedAreaPixels){
                                    this.setState({
                                        croppedArea: croppedArea,
                                        croppedAreaPixels: croppedAreaPixels
                                    })
                                }).bind(this)} />
                        </div>
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default DataInputImageSelect;