/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';
import ManagementUser from '../../class/ManagementUser';

import LoadingIndicator from '../common/LoadingIndicator';
import EditorSection from '../common/EditorSection';
import DataInputField from '../common/DataInputField';
import CountrySelectList from '../common/CountrySelectList';
import USStateSelectList from '../common/USStateSelectList';
import StandardButton from '../common/StandardButton';
import SubNavigationList from '../common/SubNavigationList';
import UpdatePaymentInput from './UpdatePaymentInput';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';

import '../../style/BillingView.css';

/* require the product config */
const ProductConfig = require('../../config/ProductConfig.json');

/**
 * This component manages all billing account
 * information and functionality for the billing
 * such as upgrade, downgrade, account changes
 * etc
 */
class BillingView extends React.Component {
    state = {
        failed: false,
        updateFailed: false,
        loaded: false,
        accessCode: '',
        billingApiUrl: '',
        account: null,
        showPaymentWindow: false,
        showConfirmWindow: false,
        actionType: ''
    }

    /**
     * Mounts the component and retrieves
     * the billing access code as well as
     * the billing api url
     */
    async componentDidMount(){
        let codeResult = await ApiClient.execute('/account/billing');
        if(codeResult !== null){
            if(codeResult.failed === false && typeof codeResult.accessCode === 'string'){
                this.setState({
                    accessCode: codeResult.accessCode,
                    billingApiUrl: codeResult.apiUrl
                },this.fetchBillingInfo.bind(this));
            }else{
                this.setState({loaded: true, failed: true});
            }
        }else{
            this.setState({loaded: true, failed: true});
        }
    }

    /**
     * Fetches the billing information directly
     * from the billing api and sets the billing
     * information locally
     */
    async fetchBillingInfo(){
        this.setState({loaded: false, failed: false});

        let queryUrl = this.state.billingApiUrl + '/subscription/view';
        let response = await fetch(queryUrl,{
            method: 'post',
            body: JSON.stringify({
                tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                accessCode: this.state.accessCode
            })
        });

        /* get the json account response object */
        let accountResponse = await response.json();

        /* check if the subscription info was successfully
            retrieved from the billing api */
        if(accountResponse.failed === false){
            this.setState({
                loaded: true,
                failed: false,
                account: accountResponse.account
            });
        }else{
            /* set the retrieval to failed */
            this.setState({
                loaded: true,
                failed: true
            });
        }
    }

    /**
     * Updates the existing payment method
     * of the customer with the newly provided
     * payment id
     * 
     * @param {string} paymentId 
     * the setup and authorized payment id
     */
    async updatePayment(paymentId){
        try{
            this.setState({loaded: false});

            let queryUrl = this.state.billingApiUrl + '/subscription/update';
            let response = await fetch(queryUrl,{
                method: 'post',
                body: JSON.stringify({
                    tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                    accessCode: this.state.accessCode,
                    paymentId: paymentId
                })
            });

            /* get the json account response object */
            let accountResponse = await response.json();
            if(accountResponse.failed === false){
                this.setState({updateFailed: false, showPaymentWindow: false},
                    this.fetchBillingInfo.bind(this));
            }else{
                this.setState({updateFailed: true, showPaymentWindow: false});
            }
        }catch(ex){
            this.setState({updateFailed: true, showPaymentWindow: false});
        }        
    }

    /**
     * Updates the customer information of
     * this billing contact with the api
     */
    async updateCustomer(){
        try{
            this.setState({loaded: false});

            let queryUrl = this.state.billingApiUrl + '/subscription/update';
            let response = await fetch(queryUrl,{
                method: 'post',
                body: JSON.stringify({
                    tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                    accessCode: this.state.accessCode,
                    customer: this.state.account.customer
                })
            });

            /* get the json account response object */
            let accountResponse = await response.json();
            if(accountResponse.failed === false){
                this.setState({updateFailed: false});
            }else{
                this.setState({updateFailed: true});
            }

            /* fetch the billing information again */
            this.fetchBillingInfo();
        }catch(ex){
            this.setState({updateFailed: true});
        }
    }

    /**
     * Performs the action defined in the state
     * with the tenant subscription and reloads
     * the subscription
     */
    async performSubscriptionAction(){
        try{
            this.setState({loaded: false, showConfirmWindow: false});

            let queryUrl = this.state.billingApiUrl + '/subscription/update';
            let response = await fetch(queryUrl,{
                method: 'post',
                body: JSON.stringify({
                    tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                    accessCode: this.state.accessCode,
                    action: this.state.actionType
                })
            });

            /* get the json account response object */
            let accountResponse = await response.json();
            if(accountResponse.failed === false){
                if(this.state.actionType === 'DOWNGRADE' || this.state.actionType === 'UPGRADE'){
                    /* the user needs to be signed out for 
                        the upgrade/downgrade to take effect */
                    this.props.onSignOut();
                }else{
                    this.setState({updateFailed: false, actionType: ''});
                }
            }else{
                this.setState({updateFailed: true, actionType: ''});
            }

            /* fetch the billing information again */
            this.fetchBillingInfo();
        }catch(ex){
            this.setState({updateFailed: true, actionType: ''});
        }
    }

    /**
     * Returns true when this subscription
     * is marked as cancelled and otherwise
     * returns false
     */
    isCancelled(){
        return (typeof this.state.account.subscription.cancellationTime === 'number');
    }

    /**
     * Renders the billing account status fields
     * and returns them to be rendered in the component
     */
    renderAccountStatus(){
        /* determine the product code of this subscription */
        let productName = Culture.getText('BILLING_ACCOUNT_PRODUCT_EU_BASIC');
        for(const serviceItem of this.state.account.subscription.serviceList){
            if(serviceItem.serviceCode.startsWith('PRODUCT_')){
                productName = Culture.getText('BILLING_ACCOUNT_'
                        + serviceItem.serviceCode.toUpperCase());
            }
        }

        return(
            <div className="BillingAccountStatus">
                <div className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {Culture.getText('BILLING_ACCOUNT_STATUS')}
                    </div>
                    <div className="BillingAccountStatusFieldValue" data-status={this.state.account.status.toLowerCase()}>
                        {Culture.getText('BILLING_ACCOUNT_STATUS_'+this.state.account.status.toUpperCase())}
                    </div>
                </div>

                <div className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {Culture.getText('BILLING_ACCOUNT_PRODUCT_NAME')}
                    </div>
                    <div className="BillingAccountStatusFieldValue" data-status={this.state.account.status.toLowerCase()}>
                        {productName}
                    </div>
                </div>

                {this.state.account.subscription.isTrial === false &&
                    <div className="BillingAccountStatusField">
                        <div className="BillingAccountStatusFieldTitle">
                            {Culture.getText('BILLING_ACCOUNT_BILLINGPERIOD')}
                        </div>
                        <div className="BillingAccountStatusFieldValue">
                            {[
                                (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'medium'
                                })).format(this.state.account.subscription.periodFrom*1000),
                                (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'medium'
                                })).format(this.state.account.subscription.periodUntil*1000)
                            ].join(' — ')}
                        </div>
                    </div>
                }

                {this.state.account.subscription.isTrial === true &&
                    <div className="BillingAccountStatusField">
                        <div className="BillingAccountStatusFieldTitle">
                            {Culture.getText('BILLING_ACCOUNT_TRIALPERIODEND')}
                        </div>
                        <div className="BillingAccountStatusFieldValue">
                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'long'
                            })).format(this.state.account.subscription.trialExpiryTime*1000)}
                        </div>
                    </div>
                }

                {this.isCancelled() === true &&
                    <div className="BillingAccountStatusField">
                        <div className="BillingAccountStatusFieldTitle">
                            {Culture.getText('BILLING_ACCOUNT_CANCELLATIONDATE')}
                        </div>
                        <div className="BillingAccountStatusFieldValue">
                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                dateStyle: 'long'
                            })).format(this.state.account.subscription.cancellationTime*1000)}
                        </div>
                    </div>
                }

                {this.state.account.subscription.isDiscounted === true &&
                    <div className="BillingAccountStatusField">
                        <div className="BillingAccountStatusFieldTitle">
                            {Culture.getText('BILLING_ACCOUNT_DISCOUNTCODE')}
                        </div>
                        <div className="BillingAccountStatusFieldValue">
                            {this.state.account.subscription.discountCode}
                        </div>
                    </div>
                }
            </div>
        );
    }

    /**
     * Renders the services included in this
     * account and returns the result
     */
    renderAccountServices(){
        let result = [];

        /* determine the product code of this subscription */
        let productCode = 'BASIC';
        for(const serviceItem of this.state.account.subscription.serviceList){
            if(serviceItem.serviceCode.startsWith('PRODUCT_')){
                productCode = serviceItem.serviceCode.split('_')[2];
            }
        }

        let productConfig = ProductConfig[productCode];
        let productObject = (this.state.account.subscription.product || {});
        for(const includedItemCode of Object.keys(productConfig)){
            let featureValue = Number(productObject[includedItemCode] || productConfig[includedItemCode]);
            result.push(
                <div key={includedItemCode} className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {Culture.getText('BILLING_ACCOUNT_INCLUDED_'+includedItemCode.toUpperCase())}
                    </div>
                    <div className="BillingAccountStatusFieldValue">
                        {Intl.NumberFormat(Culture.getCultureCode()).format(featureValue)}
                    </div>
                </div>
            );
        }

        return result;
    }

    /**
     * Renders the excess charges that the usage
     * of the service might incurr when certain
     * limits are hit
     */
    renderExtraCharges(){
        let result = [];

        for(const serviceItem of this.state.account.subscription.serviceList){
            let serviceName = Culture.getText('BILLING_ACCOUNT_'+serviceItem.serviceCode);
            if(serviceItem.serviceCode.startsWith('PRODUCT_')){
                serviceName = Culture.getText('BILLING_ACCOUNT_PRODUCT_MONTHLY_FEE');
            }

            let chargeAmount = (new Intl.NumberFormat(Culture.getCultureCode(), {
                style: 'currency', 
                currency: serviceItem.serviceCharge.currencyCode,
                minimumFractionDigits: 2,
                maximumFractionDigits: 8
            })).format(serviceItem.serviceCharge.amount);

            if(serviceItem.serviceCode === 'AI_TRANSLATION'){
                /* calculate the translation charge for 1m characters */
                chargeAmount = (new Intl.NumberFormat(Culture.getCultureCode(), {
                    style: 'currency', 
                    currency: serviceItem.serviceCharge.currencyCode,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8
                })).format(serviceItem.serviceCharge.amount*1000000);
            }

            result.push(
                <div key={serviceItem.serviceCode} 
                    className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {serviceName}
                    </div>
                    <div className="BillingAccountStatusFieldValue">
                        {chargeAmount}
                    </div>
                </div>
            );
        }

        return result;
    }

    /**
     * Returns the billing currency code
     * for this subscription as string
     */
    getBillingCurrencyCode(){
        let result = false;

        for(const serviceItem of this.state.account.subscription.serviceList){
            result = serviceItem.serviceCharge.currencyCode.toUpperCase();
        }

        return result;
    }

    /**
     * Returns the payment information of
     * this subscription
     */
    renderPaymentInformation(){
        return(
            <>
                <div className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_TYPE')}
                    </div>
                    <div className="BillingAccountStatusFieldValue">
                        {Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_TYPE_'
                            + this.state.account.paymentMethod.type.toUpperCase())}
                    </div>
                </div>

                <div className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_BRAND')}
                    </div>
                    <div className="BillingAccountStatusFieldValue">
                        {this.state.account.paymentMethod.brand.toUpperCase()}
                    </div>
                </div>

                <div className="BillingAccountStatusField">
                    <div className="BillingAccountStatusFieldTitle">
                        {Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_NUMBER')}
                    </div>
                    <div className="BillingAccountStatusFieldValue">
                        {this.state.account.paymentMethod.lastFour}
                    </div>
                </div>
            </>
        );
    }

    /**
     * Renders the billing view as well as all
     * the related components to edit the billing
     * information
     */
    render(){
        if(this.state.loaded === false){
            return(
                <div className="BillingViewLoadingStatus">
                    <LoadingIndicator />
                </div>
            );
        }else{
            if(this.state.loaded === true && this.state.failed === true){
                return(
                    <SubNavigationList>
                        <section code="account" name={Culture.getText('SETTINGS_SECTION_BILLING')}>
                            <EditorSection title={Culture.getText('BILLING_NOACCOUNT_TITLE')}
                                subtitle={Culture.getText('BILLING_NOACCOUNT_SUBTITLE')}
                                helpText={Culture.getText('BILLING_NOACCOUNT_HELPTEXT')}
                                introText={Culture.getText('BILLING_NOACCOUNT_TEXT')}>
                            </EditorSection>
                        </section>
                    </SubNavigationList>
                );
            }else{
                /* define the billing issue message if there is
                    an issue with the billing of this tenant */
                let billingIssueMessage = null;
                if(this.props.billingIssue === true){
                    billingIssueMessage = (
                        <div className="BillingIssueMessage">
                            <div className="BillingIssueMessageContent">
                                <div className="BillingIssueMessageTitle">
                                    {Culture.getText('BILLINGISSUE_TITLE')}
                                </div>
                                <div className="BillingIssueMessageText">
                                    {Culture.getText('BILLINGISSUE_TEXT')}
                                </div>
                            </div>
                        </div>
                    );
                }

                /* determine the product code of this subscription */
                let productCode = 'BASIC';
                for(const serviceItem of this.state.account.subscription.serviceList){
                    if(serviceItem.serviceCode.startsWith('PRODUCT_')){
                        productCode = serviceItem.serviceCode.split('_')[2];
                    }
                }

                return(
                    <SubNavigationList>
                        <section code="account" name={Culture.getText('BILLING_ACCOUNT_TITLE')}>
                            {billingIssueMessage}

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_STATUS_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_STATUS_SUBTITLE')}
                                helpText={Culture.getText('BILLING_SUPPORT_TEXT')}>
                                {this.renderAccountStatus()}

                                <div className="BillingAccountActionList">
                                    {this.isCancelled() === true && this.state.account.subscription.isTrial !== true &&
                                        <StandardButton className="BillingAccountReactivateAction"
                                            text={Culture.getText('BILLING_ACCOUNT_REACTIVATE')}
                                            onClick={() => this.setState({showConfirmWindow: true, actionType: 'REACTIVATE'})} />
                                    }

                                    {this.isCancelled() === true && this.state.account.subscription.isTrial === true &&
                                        <StandardButton className="BillingAccountReactivateAction"
                                            text={Culture.getText('BILLING_ACCOUNT_REACTIVATE_ENDTRIAL')}
                                            onClick={() => this.setState({showConfirmWindow: true, actionType: 'REACTIVATE'})} />
                                    }

                                    {productCode === 'PRO' && this.isCancelled() === false &&
                                        <StandardButton className="BillingAccountDowngradeAction"
                                            text={Culture.getText('BILLING_ACCOUNT_DOWNGRADE')}
                                            onClick={() => this.setState({showConfirmWindow: true, actionType: 'DOWNGRADE'})} />
                                    }

                                    {this.isCancelled() === false &&
                                        <StandardButton className="BillingAccountCancelAction"
                                            text={Culture.getText('BILLING_ACCOUNT_CANCEL')}
                                            onClick={() => this.setState({showConfirmWindow: true, actionType: 'CANCEL'})} />
                                    }
                                </div>

                                {this.state.showConfirmWindow === true &&
                                    <ModalDrawerOverlay 
                                        titleText={Culture.getText('BILLING_ACCOUNT_CONFIRMACTION_'+this.state.actionType)}
                                        subtitleText={Culture.getText('BILLING_ACCOUNT_CONFIRMACTION_SUBTITLE')}
                                        introText={Culture.getText('BILLING_ACCOUNT_CONFIRMACTION_TEXT_'+this.state.actionType)}
                                        submitButtonText={Culture.getText('BILLING_ACCOUNT_CONFIRMACTION_BUTTON_'+this.state.actionType)}
                                        onSubmit={this.performSubscriptionAction.bind(this)}
                                        onClose={() => {
                                        this.setState({showConfirmWindow: false, actionType: ''})
                                    }} />
                                }
                            </EditorSection>

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_SERVICES_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_SERVICES_SUBTITLE')}
                                introText={Culture.getText('BILLING_ACCOUNT_SERVICES_INTROTEXT')}
                                helpText={Culture.getText('BILLING_ACCOUNT_SERVICES_HELPTEXT')}>
                                <div className="BillingAccountServiceList">
                                    {this.renderAccountServices()}
                                </div>
                            </EditorSection>

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_CHARGES_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_CHARGES_SUBTITLE')}
                                introText={Culture.getText('BILLING_ACCOUNT_CHARGES_INTROTEXT')}
                                helpText={Culture.getText('BILLING_ACCOUNT_CHARGES_HELPTEXT')}>
                                <div className="BillingAccountServiceList">
                                    {this.renderExtraCharges()}
                                </div>
                            </EditorSection>
                        </section>
                        <section code="payment" name={Culture.getText('BILLING_PAYMENT_TITLE')}>
                            {billingIssueMessage}

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_SUBTITLE')}
                                introText={Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_INTROTEXT')}
                                helpText={Culture.getText('BILLING_ACCOUNT_PAYMENTMETHOD_HELPTEXT')}>
                                {this.renderPaymentInformation()}

                                <div>
                                    <StandardButton className="BillingAccountUpdatePaymentAction"
                                        onClick={() => this.setState({showPaymentWindow: true})}
                                        text={Culture.getText('BILLING_ACCOUNT_UPDATEPAYMENT')} />
                                </div>

                                {this.state.showPaymentWindow === true &&
                                    <UpdatePaymentInput 
                                        sepaPaymentSupported={(this.getBillingCurrencyCode() === 'EUR')}
                                        customerName={this.state.account.customer.name}
                                        customerEmail={this.state.account.customer.email}
                                        billingApiUrl={this.state.billingApiUrl}
                                        onCancel={(function(){
                                            this.setState({showPaymentWindow: false});
                                        }).bind(this)}
                                        onFail={(function(){
                                            this.setState({
                                                showPaymentWindow: false, 
                                                updateFailed: true
                                            });
                                        }).bind(this)}
                                        onUpdate={this.updatePayment.bind(this)}
                                    />
                                }
                            </EditorSection>
                        </section>
                        <section code="invoice" name={Culture.getText('BILLING_INVOICE_TITLE')}>
                            {billingIssueMessage}

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_CONTACT_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_CONTACT_SUBTITLE')}
                                introText={Culture.getText('BILLING_ACCOUNT_CONTACT_TEXT')}
                                helpText={Culture.getText('BILLING_ACCOUNT_CONTACT_INFO')}>
                                {(function(){
                                    let result = [];

                                    let contactFieldList = ['name','email','phone'];
                                    for(const contactField of contactFieldList){
                                        result.push(
                                            <DataInputField key={contactField}
                                                title={Culture.getText('BILLING_ACCOUNT_CONTACT_'+contactField.toUpperCase())} 
                                                value={this.state.account.customer[contactField]} 
                                                onChange={(function(value){
                                                    let account = this.state.account;
                                                    account.customer[contactField] = value;
                                                    this.setState({account: account});
                                                }).bind(this)} />
                                        );
                                    }

                                    return result;
                                }).bind(this)()}

                                <div>
                                    <StandardButton className="BillingAccountUpdateContactInfoAction"
                                        onClick={this.updateCustomer.bind(this)}
                                        text={Culture.getText('BILLING_ACCOUNT_UPDATEPTCONTACTINFO')} />
                                </div>
                            </EditorSection>

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_CONTACT_ADDRESS_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_CONTACT_ADDRESS_SUBTITLE')}
                                introText={Culture.getText('BILLING_ACCOUNT_CONTACT_ADDRESS_TEXT')}
                                helpText={Culture.getText('BILLING_ACCOUNT_CONTACT_ADDRESS_HELPTEXT')}>
                                {(function(){
                                    let result = [];

                                    let addressFieldList = ['line1','postal_code','city'];
                                    for(const addressField of addressFieldList){
                                        result.push(
                                            <DataInputField key={addressField}
                                                title={Culture.getText('BILLING_ACCOUNT_CONTACT_'+addressField.toUpperCase())} 
                                                value={this.state.account.customer.address[addressField]} 
                                                onChange={(function(value){
                                                    let account = this.state.account;
                                                    account.customer.address[addressField] = value;
                                                    this.setState({account: account});
                                                }).bind(this)} />
                                        );
                                    }

                                    /* render the state as a dropdown list for us customers
                                        as the exact code of the state is required for payment
                                        authentication. All other countries can just use the
                                        name of their state */
                                    if(this.state.account.customer.address.country === 'US'){
                                        let stateCode = 'NY';
                                        if(typeof this.state.account.customer.address.state === 'string'
                                            && this.state.account.customer.address.state !== ''){
                                            /* set the state as defined in the customer account */
                                            stateCode = this.state.account.customer.address.state.toUpperCase();
                                        }

                                        result.push(
                                            <USStateSelectList key={'state'}
                                                title={Culture.getText('BILLING_ACCOUNT_CONTACT_STATE')} 
                                                value={stateCode} 
                                                onChange={(function(value){
                                                    let account = this.state.account;
                                                    account.customer.address.state = value.toUpperCase();
                                                    this.setState({account: account});
                                                }).bind(this)} />
                                        );
                                    }else{
                                        result.push(
                                            <DataInputField key='state'
                                                title={Culture.getText('BILLING_ACCOUNT_CONTACT_STATE')} 
                                                value={this.state.account.customer.address.state} 
                                                onChange={(function(value){
                                                    let account = this.state.account;
                                                    account.customer.address.state = value;
                                                    this.setState({account: account});
                                                }).bind(this)} />
                                        );
                                    }

                                    result.push(
                                        <CountrySelectList key={'countryCode'} disableNone={true}
                                            title={Culture.getText('BILLING_ACCOUNT_CONTACT_COUNTRY')} 
                                            value={this.state.account.customer.address.country.toLowerCase()} 
                                            onChange={(function(value){
                                                let account = this.state.account;
                                                account.customer.address.country = value.toUpperCase();
                                                this.setState({account: account});
                                            }).bind(this)} />
                                    )

                                    return result;
                                }).bind(this)()}

                                <div>
                                    <StandardButton className="BillingAccountUpdateContactInfoAction"
                                        onClick={this.updateCustomer.bind(this)}
                                        text={Culture.getText('BILLING_ACCOUNT_UPDATEPTCONTACTINFO')} />
                                </div>
                            </EditorSection>
                        </section>
                        <section code="tax" name={Culture.getText('BILLING_TAX_TITLE')}>
                            {billingIssueMessage}

                            <EditorSection title={Culture.getText('BILLING_ACCOUNT_TAX_TITLE')}
                                subtitle={Culture.getText('BILLING_ACCOUNT_TAX_SUBTITLE')}
                                introText={Culture.getText('BILLING_ACCOUNT_TAX_INFO')}
                                helpText={Culture.getText('BILLING_ACCOUNT_TAX_HELPTEXT')}>
                                <DataInputField 
                                    title={Culture.getText('BILLING_ACCOUNT_TAXID')} 
                                    value={this.state.account.customer.taxId} 
                                    onChange={(function(value){
                                        let account = this.state.account;
                                        account.customer.taxId = value;
                                        this.setState({account: account});
                                    }).bind(this)} />

                                <div>
                                    <StandardButton className="BillingAccountUpdateTaxInfoAction"
                                        onClick={this.updateCustomer.bind(this)}
                                        text={Culture.getText('BILLING_ACCOUNT_UPDATEPTAXINFO')} />
                                </div>
                            </EditorSection>
                        </section>
                    </SubNavigationList>
                );
            }
        }
    }
}

export default BillingView;