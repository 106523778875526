/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';
import ManagementUser from '../../class/ManagementUser';

/* require the components used */
import EditorSection from '../common/EditorSection';
import DataInputField from '../common/DataInputField';
import LoadingIndicator from '../common/LoadingIndicator';
import DataInputCheckBox from '../common/DataInputCheckBox';
import ColorSelectInput from '../common/ColorSelectInput';
import DataInputImageSelect from '../common/DataInputImageSelect';
import SubNavigationList from '../common/SubNavigationList';
import StandardButton from '../common/StandardButton';
import MessageListEditor from '../inventory/MessageListEditor';

import '../../style/MessagingConfigView.css';

/* require the default styles for messaging */
const MessagingDefaultStyleList = require('../../config/messaging/defaultStyle.json');

/**
 * Messaging config component that allows to manage
 * the messaging configuration for a tenant
 */
class MessagingConfigView extends React.Component {
    state = {
        loaded: false,
        config: {},
        failed: false,
        logoContent: '',
        logoMimeType: '',
        logoRemoved: false,
        isChanged: false
    }

    /**
     * Initially fetch the contents
     * of the messaging configuration
     * and render it
     */
    componentDidMount(){
        this.fetchConfig();
    }

    /**
     * Fetches config of the tenant and
     * renders the messaging config
     */
    async fetchConfig(){
        this.setState({loaded: false});
        let listResult = await ApiClient.execute('/messaging/config/get');
        if(listResult.failed === false){
            this.setState({
                loaded: true, 
                config: listResult.config,
                failed: false,
                isChanged: false,
                logoRemoved: false,
                logoContent: '',
                logoMimeType: ''
            });
        }else{
            this.setState({
                loaded: false,
                config: {},
                failed: false,
                isChanged: false,
                logoRemoved: false,
                logoContent: '',
                logoMimeType: ''
            });
        }
    }

    /**
     * Updates the configuration and
     * reloads the updated configuration
     */
    async updateConfig(){
        let configObject = {
            enabled: (this.state.config.enabled || false),
            footerText: (this.state.config.footerText || {}),
            replyToAddress: (this.state.config.replyToAddress || ''),
            senderName: (this.state.config.senderName || ''),
            titleName: (this.state.config.titleName || ''),
            style: (this.state.config.style || {})
        };

        if(this.state.logoRemoved === false){
            if(this.state.logoContent !== ''){
                configObject.logo = {
                    base64: this.state.logoContent,
                    mimeType: this.state.logoMimeType
                };
            }else{
                if(typeof this.state.config.logoContent === 'string'
                    && this.state.config.logoContent !== ''){
                    configObject.logo = {
                        base64: this.state.config.logoContent,
                        mimeType: this.state.config.logoMimeType
                    };
                }
            }
        }else{
            configObject.logoRemoved = true;
            delete configObject.logo;
        }

        /* push the config object to the api */
        this.setState({loaded: false});
        await ApiClient.execute('/messaging/configure',configObject);

        /* reload the updated config from the api */
        this.fetchConfig();
    }

    /**
     * Renders the style configuration for
     * the booking confirmation messages
     */
    renderStyleConfig(){
        let result = [];

        let varList = [ 
            "background-color", "text-color",
            "content-background-color", "button-background-color",
            "button-text-color", "footer-text-color"
        ];
        
        let tenantConfig = this.state.config;
        for(const styleVar of varList){
            let styleValue = MessagingDefaultStyleList[styleVar];
            if(typeof tenantConfig.style === 'object' && tenantConfig.style !== null){
                if(typeof tenantConfig.style[styleVar] === 'string'){
                    styleValue = tenantConfig.style[styleVar];
                }
            }

            result.push(
                <ColorSelectInput key={styleVar} 
                    title={Culture.getText('SETTINGS_MESSAGING_STYLE_'+styleVar.toUpperCase().replace(/-/gmi,'_'))}
                    value={styleValue}
                    onChange={(function(styleKey,value){
                        let config = this.state.config;
                        if(typeof config.style !== 'object' || config.style === null){
                            config.style = {};
                        }

                        config.style[styleKey] = value;
                        console.log(config);
                        this.setState({config: config, isChanged: true});
                    }).bind(this,styleVar)} />
            )
        }

        return result;
    }

    /**
     * Render the messaging config of
     * the currently active tenant
     */
    render(){
        if(this.state.loaded === false){
            return (
                <LoadingIndicator />
            );
        }else{
            let saveButtonList = null;

            if(this.state.isChanged === true){
                saveButtonList = (
                    <div className="MessagingConfigViewButtonList">
                        <StandardButton className="MessagingConfigViewSaveButton"
                            text={Culture.getText('SETTINGS_MESSAGING_CONFIRMSAVE')}
                            onClick={this.updateConfig.bind(this)} />
                        <StandardButton className="MessagingConfigViewDiscardButton"
                            text={Culture.getText('SETTINGS_MESSAGING_DISCARD')}
                            onClick={this.fetchConfig.bind(this)} />
                    </div>
                );
            }

            return (
                <SubNavigationList>
                    <section code="email" name={Culture.getText('SETTINGS_MESSAGING_CONFIG')}>
                        <EditorSection title={Culture.getText('SETTINGS_MESSAGING_CONFIG_STATUS_TITLE')}
                            subtitle={Culture.getText('SETTINGS_MESSAGING_CONFIG_STATUS_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_MESSAGING_CONFIG_STATUS_HELPTEXT')}>
                            <DataInputCheckBox title={Culture.getText('SETTINGS_MESSAGING_DISABLED')} 
                                checkedTitle={Culture.getText('SETTINGS_MESSAGING_ENABLED')}
                                checked={this.state.config.enabled || false} onClick={() => {
                                    let config = this.state.config;
                                    config.enabled = !(this.state.config.enabled || false);
                                    this.setState({config: config, isChanged: true});
                                }} 
                            />

                            {saveButtonList}
                        </EditorSection>

                        <EditorSection title={Culture.getText('SETTINGS_MESSAGING_EMAILCONFIG_TITLE')}
                            subtitle={Culture.getText('SETTINGS_MESSAGING_CONFIG_EMAILCONFIG_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_MESSAGING_CONFIG_EMAILCONFIG_HELPTEXT')}>
                            <DataInputField title={Culture.getText('SETTINGS_MESSAGING_REPLYTOADDRESS')} 
                                value={this.state.config.replyToAddress || ''} 
                                onChange={(value) => {
                                    let config = this.state.config;
                                    config.replyToAddress = value;
                                    this.setState({config: config, isChanged: true});
                                }} />
        
                            <DataInputField title={Culture.getText('SETTINGS_MESSAGING_SENDERNAME')} 
                                value={this.state.config.senderName || ''} 
                                onChange={(value) => {
                                    let config = this.state.config;
                                    config.senderName = value;
                                    this.setState({config: config, isChanged: true});
                                }} />
        
                            <DataInputField title={Culture.getText('SETTINGS_MESSAGING_TITLENAME')} 
                                value={this.state.config.titleName || ''} 
                                onChange={(value) => {
                                    let config = this.state.config;
                                    config.titleName = value;
                                    this.setState({config: config, isChanged: true});
                                }} />
        
                            <MessageListEditor title={Culture.getText('SETTINGS_MESSAGING_FOOTERTEXT')} 
                                languageList={ManagementUser.getCurrent().getTenantLanguageList()} 
                                content={(function(){
                                    let result = [];
        
                                    let footerText = this.state.config.footerText;
                                    if(typeof footerText === 'object' && footerText !== null){
                                        for(const langCode of Object.keys(footerText)){
                                            result.push({
                                                languageCode: langCode,
                                                text: footerText[langCode]
                                            });
                                        }
                                    }
        
                                    return result;
                                }).bind(this)()}
                                onChange={(value) => {
                                    let config = this.state.config;
        
                                    if(Array.isArray(value)){
                                        let textObject = {};
                                        for(const textItem of value){
                                            textObject[textItem.languageCode] = textItem.text;
                                        }
                                        config.footerText = textObject;
                                    }
        
                                    this.setState({config: config, isChanged: true});
                                }} />

                            {saveButtonList}    
                        </EditorSection>

                        <EditorSection title={Culture.getText('SETTINGS_MESSAGING_EMAIL_LOGO_TITLE')}
                            subtitle={Culture.getText('SETTINGS_MESSAGING_CONFIG_EMAIL_LOGO_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_MESSAGING_CONFIG_EMAIL_LOGO_HELPTEXT')}>
                            <DataInputImageSelect title={Culture.getText('SETTINGS_MESSAGING_LOGOIMAGE')}
                                infoText={Culture.getText('SETTINGS_MESSAGING_LOGOIMAGE_FILETYPE')}
                                fileTypeList={['image/jpeg','image/png','image/webp','image/gif']}
                                targetSize={{width: 260, height: 80}}
                                onChange={(function(value){
                                    if(value === null){
                                        this.setState({
                                            logoContent: '',
                                            logoMimeType: '',
                                            logoRemoved: true, 
                                            isChanged: true
                                        });
                                    }else{
                                        this.setState({
                                            logoContent: value.content,
                                            logoMimeType: value.mimeType,
                                            logoRemoved: false, 
                                            isChanged: true
                                        });
                                    }
                                }).bind(this)}
                                content={(function(){
                                    let result = '';

                                    let config = this.state.config;
                                    if(this.state.logoRemoved === false){
                                        if(this.state.logoContent === ''){
                                            if(typeof config.logoContent === 'string'){
                                                result = config.logoContent;
                                            }
                                        }else{
                                            result = this.state.logoContent;
                                        }
                                    }

                                    return result;
                                }).bind(this)()} />
                        
                            {saveButtonList}
                        </EditorSection>

                        <EditorSection title={Culture.getText('SETTINGS_MESSAGING_EMAIL_STYLE_TITLE')}
                            subtitle={Culture.getText('SETTINGS_MESSAGING_CONFIG_EMAIL_STYLE_SUBTITLE')}
                            helpText={Culture.getText('SETTINGS_MESSAGING_CONFIG_EMAIL_STYLE_HELPTEXT')}>
                            {(function(){
                                let result = [];

                                /* add the checkbox for the border-radius */
                                let hasBorderRadius = true;
                                let tenantConfig = this.state.config;
                                if(typeof tenantConfig.style === 'object' && tenantConfig.style !== null){
                                    if(tenantConfig.style['border-radius'] === '0px'){
                                        hasBorderRadius = false;
                                    }
                                }

                                result.push(
                                    <DataInputCheckBox key="border-radius"
                                        title={Culture.getText('SETTINGS_MESSAGING_STYLE_BORDER_RADIUS')} 
                                        checked={hasBorderRadius} onClick={() => {
                                            let config = this.state.config;
                                            if(typeof config.style !== 'object' || config.style === null){
                                                config.style = {};
                                            }

                                            /* toggle the border radius style setting */
                                            if(hasBorderRadius === true){
                                                config.style['border-radius'] = '0px';
                                            }else{
                                                config.style['border-radius'] = '4px';
                                            }
                                            
                                            this.setState({config: config});
                                        }} 
                                    />
                                );

                                return result;
                            }).bind(this)()}

                            <div className="MessagingConfigViewColorList">
                                {this.renderStyleConfig()}
                            </div>
                        
                            {saveButtonList}
                        </EditorSection>
                    </section>
                </SubNavigationList>
            );
        }
    }

}

export default MessagingConfigView;