/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../../class/Culture';

/* require the components for this component */
import ModalDrawerOverlay from '../../common/ModalDrawerOverlay';
import OptionListSelect from '../../common/OptionListSelect';

/**
 * This component provides the unit amenity filter
 * input to set the unit amenity for the filter
 */
class MarketplaceUnitTypeFilter extends React.Component {
    state = {
        unitTypeList: []
    }

    /**
     * Initialise the current filter
     * values when the component is mounted
     */
    componentDidMount(){
        this.setState({unitTypeList: ((this.props.value || {}).unitTypeList) || []});
    }

    /**
     * Returns the list of all applicable
     * unit amenities for the selection
     */
    getUnitTypeList(){
        let result = [];

        let codeList = require('../../../config/ota/OTARoomTypeCode.json');
        const unitTypeList = this.state.unitTypeList || [];
        Object.keys(codeList).forEach((itemKey) => {
            result.push({
                value: Number(itemKey),
                text: Culture.getText('UNIT_TYPENAME_'+itemKey),
                checked: (unitTypeList.includes(Number(itemKey)))
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Toggles the amenity in the amenity
     * list removing it when it exists or
     * amenity it, when it doesn't
     * 
     * @param {string} code
     * the amenity code to toggle in the list 
     */
    toggle(code){
        let unitTypeList = this.state.unitTypeList;
        const index = unitTypeList.indexOf(code);

        if (index === -1) {
            unitTypeList.push(code);
        } else {
            unitTypeList.splice(index, 1);
        }

        this.setState({unitTypeList: unitTypeList});
    }

    /**
     * Notifies the parent component about the
     * update of the search query
     */
    notifyUpdate(){
        let searchQuery = this.props.value || {};
        if(this.state.unitTypeList.length > 0){
            searchQuery.unitTypeList = this.state.unitTypeList;
        }else{
            delete searchQuery.unitTypeList;
        }

        this.props.onChange(searchQuery);
    }

    /**
     * Renders the component with the selection
     * option for the unit amenity filter
     */
    render(){
        return(
            <div className="ModalOptionListSelect">
                <ModalDrawerOverlay className="ModalOptionListDrawerOverlay"
                    submitButtonText={Culture.getText('APPLYSELECTION_TEXT')}
                    onSubmit={this.notifyUpdate.bind(this)}
                    onClose={this.props.onClose.bind(this)}>
                    <OptionListSelect enableFilter={true}
                        filterPlaceholderText={Culture.getText('MARKETPLACE_FILTER_UNITTYPE_SEARCH_PLACEHOLDER')}
                        list={this.getUnitTypeList()} 
                        onToggle={(item) => this.toggle(Number(item.value))} />
                </ModalDrawerOverlay>
            </div>
        );
    }
}

export default MarketplaceUnitTypeFilter;