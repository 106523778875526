/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';

import SubNavigationList from '../common/SubNavigationList';
import EditorSection from '../common/EditorSection';
import MultiMediaListInput from './MultiMediaListInput';

import '../../style/MultiMediaEditor.css';

/**
 * This component allows to manage all multimedia
 * content for the property and the units
 */
class MultiMediaEditor extends React.Component {
    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Notify the parent about changes to
     * the property media list and thus the
     * property object itself
     * 
     * @param {object} property
     * updated property object to notify about 
     */
    notifyPropertyUpdate(property){
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Returns the media file from all available
     * media lists from both the property as well
     * as the rooms or units and returns the item
     * 
     * @param {string} fileName
     * file name of the media item 
     */
    getMediaFileByFileName(fileName){
        let result = null;

        let property = new Property(this.props.property);
        property.getPropertyMediaList().forEach((propertyMediaItem) => {
            if(propertyMediaItem.url === fileName){
                result = propertyMediaItem;
            }
        });

        if(result === null){
            property.getGuestRoomList().forEach((guestRoom) => {
                property.getGuestRoomMediaList(guestRoom.roomId).forEach((guestRoomMediaItem) => {
                    if(guestRoomMediaItem.url === fileName){
                        result = guestRoomMediaItem;
                    }
                });
            });
        }

        return result;
    }

    /**
     * Adds a unit amenity to the unit
     * of the property and notifies
     * the parent about the property update
     * 
     * @param {string} unitCode
     * the identifier or code of the unit
     * also known as the 'roomId'
     * 
     * @param {object} value
     * the amenity object to add to the unit 
     */
    addUnitAmenityItem(unitCode,value){
        /* identify the guest room and add the amenity,
            then update guest room in the property and
            notify about the property update */
        let property = new Property(this.props.property);
        let updatedGuestRoom = null;
        property.getGuestRoomList().forEach((guestRoom) => {
            if(guestRoom.roomId === unitCode){
                updatedGuestRoom = guestRoom;
                if(!Array.isArray(updatedGuestRoom.amenityList)){
                    updatedGuestRoom.amenityList = [];
                }

                updatedGuestRoom.amenityList.push({
                    code: String(value.code),
                    quantity: 1
                });
            }
        });

        /* set the guest room when it exists and notify
            the parent about the change in the property */
        if(updatedGuestRoom !== null){
            property.updateGuestRoom(updatedGuestRoom);
            this.notifyPropertyUpdate(property);
        }
    }

    /**
     * Adds a property service item to
     * the property and notifies the
     * parent about the property update
     * 
     * @param {object} value
     * the service object to add to the property 
     */
    addPropertyServiceItem(value){
        /* create the service object and add it to the property */
        let property = new Property(this.props.property);
        let serviceList = property.getServiceList();
        serviceList.push({
            code: parseInt(value.code), name: value.name, exists: true, included: true, price: 0,
            currencyCode: property.getDefaultCurrencyCode(), operationTimeList: [], 
            featureList: [], typeList: [], itemList: []
        });
        property.setServiceList(serviceList);
        this.notifyPropertyUpdate(property);
    }

    /**
     * Renders the media input for the guest 
     * room or unit items to upload media
     * for these and the property
     */
    renderUnitList(){
        let result = [];

        if(this.props.property !== null){
            let property = new Property(this.props.property);
            property.getGuestRoomList().forEach((guestRoom) => {
                result.push(
                    <section key={'unit_'+guestRoom.roomId} 
                        code={'unit_'+guestRoom.roomId}
                        name={guestRoom.name}>
                        <EditorSection key={guestRoom.roomId} 
                            title={Culture.getText('PROPERTY_MEDIALIST_UNITMEDIA_TITLE')}
                            subtitle={Culture.getText('PROPERTY_MEDIALIST_UNITMEDIA_SUBTITLE')}
                            helpText={Culture.getText('PROPERTY_MEDIALIST_UNITMEDIA_HELPTEXT')}>
                            <MultiMediaListInput property={this.props.property}
                                propertyElement={guestRoom.roomId}
                                mediaList={property.getGuestRoomMediaList(guestRoom.roomId)}
                                getForeignMedia={this.getMediaFileByFileName.bind(this)}
                                onAddUnitAmenity={this.addUnitAmenityItem.bind(this,guestRoom.roomId)}
                                onChange={(list) => {
                                    property.setGuestRoomMediaList(guestRoom.roomId,list);
                                    this.notifyPropertyUpdate(property);
                                }} />
                        </EditorSection>
                    </section>
                );
            });
        }

        return result;
    }

    /**
     * Renders the media input section for 
     * the property and calls the method
     * to render the list for the units
     */
    render(){
        /* get the instance of the property object to
            obtain the media files assoaicated with it */
        let property = new Property(this.props.property);

        return(
            <div className="InventoryEditorSectionContent">
                <SubNavigationList>
                    <section code="media_property" name={property.name}>
                        <EditorSection 
                            title={Culture.getText('PROPERTY_MEDIALIST_PROPERTYMEDIA_TITLE')}
                            subtitle={Culture.getText('PROPERTY_MEDIALIST_PROPERTYMEDIA_SUBTITLE')}
                            helpText={Culture.getText('PROPERTY_MEDIALIST_PROPERTYMEDIA_HELPTEXT')}>
                            <MultiMediaListInput property={this.props.property} 
                                    mediaList={property.getPropertyMediaList()}
                                    propertyElement="$property"
                                    getForeignMedia={this.getMediaFileByFileName.bind(this)}
                                    onAddPropertyService={this.addPropertyServiceItem.bind(this)}
                                    onChange={(list) => {
                                        property.setPropertyMediaList(list);
                                        this.notifyPropertyUpdate(property);
                                    }} />
                        </EditorSection>
                    </section>

                    {this.renderUnitList()}
                </SubNavigationList>
            </div>
        );
    }
}

export default MultiMediaEditor;