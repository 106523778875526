/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../class/Culture';
import ManagementUser from '../class/ManagementUser';

class HeaderBar extends React.Component {
    render(){
        /* define the culture icon style with the corresponding flag */
        let cultureButtonStyle = {
            backgroundImage: 'url(/img/culture/flags/' + Culture.getCultureCode() + '.png)'
        }

        return (
            <div className="HeaderBar">
                <div className="HeaderBarContent">
                    <div className="TenantCode" onClick={() => {
                        if(typeof this.props.onShowAccountView === 'function'){
                            this.props.onShowAccountView();
                        }
                    }}>
                        {ManagementUser.getCurrent().getActiveTenant()}
                    </div>

                    <div className="UserCultureItem">
                        <div className="UserCultureButton" style={cultureButtonStyle} onClick={() => {
                            if(typeof this.props.onShowCultureView === 'function'){
                                this.props.onShowCultureView();
                            }
                        }}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderBar;