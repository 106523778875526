/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

 import React from 'react';

 import Culture from '../../class/Culture';
 import DataInputList from '../common/DataInputList';
 
 /**
  * Select list component that allows to select
  * a single US federal state from a list
  */
 class USStateSelectList extends React.Component {
    /**
     * Returns the list with all US states and
     * their localised name as well as the code
     * for the state selection list
     */
    getStateList(){
        let result = [];

        /* get the list of countries and their localised name */
        let codeList = Culture.getUSStateList();
        codeList.forEach((stateItem) => {
            result.push({
                value: stateItem.code.toUpperCase(),
                text: stateItem.name
            });
        });

        /* sort the country list by the localised name */
        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        return result;
    }
 
     /**
      * Renders the country selection list and
      * returns the rendered component
      */
     render(){
         let stateCode = this.props.value;
         if(typeof stateCode !== 'string'){
            stateCode = '';
         }
 
         return(
             <DataInputList title={this.props.title} 
             value={stateCode.toUpperCase()} disabled={this.props.disabled}
             list={this.getStateList()} onChange={(value) => {
                 if(typeof this.props.onChange === 'function'){
                     this.props.onChange(value);
                 }
             }}/>
         );
     }
 }
 
 export default USStateSelectList;