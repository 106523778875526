/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the stylesheet for this editor */
import '../../style/ConnectionEditor.css';

/* require the core classes for this component */
import Culture from '../../class/Culture';

/* require the components to render this component */
import OpenTravelConnectionEditor from './OpenTravelConnectionEditor';
import BookingPalConnectionEditor from './BookingPalConnectionEditor';
import DIRS21ConnectionEditor from './DIRS21ConnectionEditor';
import RoomRaccoonConnectionEditor from './RoomRaccoonConnectionEditor';
import SmoobuConnectionEditor from './SmoobuConnectionEditor';
import IGMSConnectionEditor from './IGMSConnectionEditor';
import ApaleoConnectionEditor from './ApaleoConnectionEditor';
import VOfficeConnectionEditor from './VOfficeConnectionEditor';
import HostfullyConnectionEditor from './HostfullyConnectionEditor';
import HostawayConnectionEditor from './HostawayConnectionEditor';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import HelpArticleView from '../account/HelpArticleView';

/* require the available channel list configuration */
const ChannelList = require('../../config/connectivity/channel.json');

/**
 * The connection editor allows creating and editing
 * existing and new connections and notifies the parent
 * about any changes or created connections
 */
class ConnectionEditor extends React.Component {
    state = {
        config: null
    }

    /**
     * Notifies the parent component
     * about the request to close
     */
    notifyClose(){
        /* reset the value in the state */
        this.setState({config: null});

        /* notify the parent about the close-requestt */
        if(typeof this.props.onClose === 'function'){
            this.props.onClose();
        }
    }

    /**
     * Returns the name of the channel
     * to editor or create with this editor
     */
    getChannelName(){
        let result = '';

        for(const channelItem of ChannelList){
            if(channelItem.code === this.props.channelCode){
                result = channelItem.name;
            }
        }

        return result;
    }

    /**
     * Returns true when the config is completed
     * and contains all required information
     */
    isCompleted(){
        let result = true;

        let configObject = this.state.config;
        if(configObject === null && this.props.editMode === true){
            configObject = this.props.config;
        }

        if(configObject === null){
            result = false;
        }else{
            /* ensure the opentravel connection data requirements are met */
            if(configObject.providerCode.startsWith('opentravel')){
                if(configObject.providerCode.split('#')[1] === ''){
                    result = false;
                }if(configObject.password === ''){
                    result = false;
                }    
                
                if(Array.isArray(configObject.propertyList)){
                    if(configObject.propertyList.length === 0){
                        result = false;
                    }
                }else{
                    result = false;
                }
    
                if(configObject.pushReservation === true){
                    if(typeof configObject.remoteEndpointUrl === 'string'){
                        if(configObject.remoteEndpointUrl.startsWith('http://') === true
                            || configObject.remoteEndpointUrl.startsWith('https://') === true){
                            let urlRegex = new RegExp('[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.'
                            + '[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%'
                            + '_\\+.~#?&//=]*)?','gi');
                            if(!configObject.remoteEndpointUrl.match(urlRegex)){
                                result = false;
                            }
                        }else{
                            result = false;
                        }
                    }else{
                        result = false;
                    }
                }
            }
            
            /* ensure the smoobu connection requirements are met */
            if(configObject.providerCode.startsWith('smoobu')){
                if(typeof configObject.providerCode.split('#')[1] !== 'string' 
                    || configObject.providerCode.split('#')[1] === ''){
                    result = false;
                }

                /* ensure the mandatory fields exist */
                ['apiKey','channelId'].forEach((fieldKey) => {
                    if(typeof configObject[fieldKey] !== 'string' || configObject[fieldKey] === ''){
                        result = false;
                    }
                });

                /* ensure there is at least one mapping present in the list and
                    that the list does not have any empty items in it */
                if(typeof configObject.mappingList === 'object' && configObject.mappingList !== null){
                    if(Object.keys(configObject.mappingList).length > 0){
                        for(const mapKey of Object.keys(configObject.mappingList)){
                            if(mapKey === '' || configObject.mappingList[mapKey] === ''){
                                result = false;
                            }
                        }
                    }else{
                        result = false;
                    }
                }else{
                    result = false;
                }
            }

            /* ensure the bookingpal connection requirements are met */
            if(configObject.providerCode === 'bookingpal'){
                if(configObject.apiKey === ''){
                    result = false;
                }
            }
        }

        return result;
    }

    /**
     * Updates the configuration value
     * in the state so it can be posted
     * once it is completed
     * 
     * @param {object} value
     * the updated config object value 
     */
    updateConfig(value){
        this.setState({config: value});
    }

    /**
     * Renders the component
     */
    render(){
        /* determine the title text for the window and buttons */
        let titleText = Culture.getText('CONNECTIVITY_ADDCONNECTION');
        if(this.props.editMode === true){
            titleText = Culture.getText('CONNECTIVITY_EDITCONNECTION');
        }

        /* determine which config object to use */
        let configObject = null;
        let lastSyncTimeText = Culture.getText('CONNECTIVITY_CONNECTION_SYNCPENDING');
        let syncStatusCode = 'wait';
        let syncStatusText = Culture.getText('CONNECTIVITY_SYNCSTATUS_WAIT');
        if(this.props.editMode === true){
            configObject = this.props.config;

            if(isNaN(configObject.lastSync) === false && configObject.lastSync > 0){
                lastSyncTimeText = (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                    dateStyle: 'full',
                    timeStyle: 'medium'
                })).format(configObject.lastSync);
            }

            if(isNaN(configObject.lastSync) === false){
                if(configObject.lastSync<((new Date()).getTime()-604800000)){
                    /* report contact lost with last contact date */
                    syncStatusText = Culture.getText('CONNECTIVITY_SYNCSTATUS_DISCONNECTED');
                    syncStatusCode = 'disconnected';
                }else{
                    /* show the last sync status time and date in short */
                    syncStatusText = Culture.getText('CONNECTIVITY_SYNCSTATUS_UPDATED');
                    syncStatusCode = 'updated';
                }
            }
        }if(this.state.config !== null){
            configObject = this.state.config;
        }

        /* get the component for the connection provider */
        let componentList = {
            'opentravel': <OpenTravelConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode}
                                onChange={this.updateConfig.bind(this)} />,
            'bookingpal': <BookingPalConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)} />,
            'dirs21': <DIRS21ConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)} />,
            'hostfully': <HostfullyConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)} />,
            'hostaway': <HostawayConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)} />,
            'roomraccoon': <RoomRaccoonConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)} />,
            'smoobu': <SmoobuConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)} />,
            'voffice': <VOfficeConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={this.updateConfig.bind(this)}
                                onCreate={(function(configObject){
                                    if(typeof this.props.onChange === 'function'){
                                        this.props.onChange(configObject);
                                    }
                                }).bind(this)} />,
            'igms': <IGMSConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={(function(configObject){
                                    if(typeof this.props.onChange === 'function'){
                                        this.props.onChange(configObject);
                                    }
                                }).bind(this)}
                                onClose={this.notifyClose.bind(this)} />,
            'apaleo': <ApaleoConnectionEditor config={configObject} 
                                editMode={this.props.editMode}
                                channelCode={this.props.channelCode} 
                                onChange={(function(configObject){
                                    if(typeof this.props.onChange === 'function'){
                                        this.props.onChange(configObject);
                                    }
                                }).bind(this)}
                                onClose={this.notifyClose.bind(this)} />
        }

        return(
            <div className="ConnectionEditorContainer" data-channelcode={this.props.channelCode}>
                <ModalDrawerOverlay className="ConnectionEditorWindow"
                    subtitleText={titleText} titleText={this.getChannelName()}
                    introText={Culture.getText('CONNECTIVITY_CHANNEL_INTROTEXT_'
                                + this.props.channelCode.toUpperCase())} 
                    submitButtonText={titleText}
                    onSubmit={(function(){
                        if(typeof this.props.onChange === 'function'){
                            this.props.onChange(this.state.config);
                        }
                    }).bind(this)}
                    onClose={this.notifyClose.bind(this)}>
                    <div className="ConnectionEditorProviderLogo" 
                        data-channelcode={this.props.channelCode}></div>

                    <HelpArticleView article={['connectivity',this.props.channelCode].join('_')} />

                    {this.props.editMode === true && 
                        <>
                            <div className="ConnectionEditorInformationField">
                                <div className="ConnectionEditorInformationFieldTitle">
                                    {Culture.getText('CONNECTIVITY_CONNECTION_STATUS')}
                                </div>
                                <div className="ConnectionEditorInformationFieldValue">
                                    <div className="ConnectionEditorInformationStatusValue">
                                        <div className="ConnectivityItemSyncStatus"  data-code={syncStatusCode}>
                                            {syncStatusText}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ConnectionEditorInformationField">
                                <div className="ConnectionEditorInformationFieldTitle">
                                    {Culture.getText('CONNECTIVITY_CONNECTION_LASTSYNC')}
                                </div>
                                <div className="ConnectionEditorInformationFieldValue">
                                    {lastSyncTimeText}
                                </div>
                            </div>
                        </>
                    }

                    {this.props.updateFailed === true &&
                        <div className="ConnectionEditorErrorMessage">
                            {Culture.getText('CONNECTIVITY_CHANNELUPDATE_FAILED')}
                        </div>
                    }
                    
                    {componentList[this.props.channelCode]}
                </ModalDrawerOverlay>
            </div>
        );
    }
}

export default ConnectionEditor;