/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import '../../style/ReservationList.css';

import Culture from '../../class/Culture';

import LoadingSlider from '../common/LoadingSlider';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';
import PropertySelectInput from '../inventory/PropertySelectInput';
import DateRangeSelect from '../common/DateRangeSelect';
import DataInputCheckBox from '../common/DataInputCheckBox';

class ReservationList extends React.Component {
    state = {
        searchText: '',
        showFilterWindow: false,
        filterPropertyCheck: false,
        filterProperty: null,
        filterArrivalCheck: false,
        filterArrivalFrom: 0,
        filterArrivalUntil: 0,
        filterCreatedCheck: false,
        filterCreatedFrom: 0,
        filterCreatedUntil: 0,
        filterEnabled: false
    }

    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);
    }

    /**
     * Notifies the parent component about
     * the filter update and forces a reload
     * of the filter list
     */
    notifyFilterUpdate(){
        if(typeof this.props.onFilterUpdated === 'function'){
            let filterObject = {};
            if(this.state.searchText.length > 3){
                filterObject.customerName = this.state.searchText;
            }
            
            let isFilterEnabled = false;
            if(this.state.filterPropertyCheck === true 
                && this.state.filterProperty !== null){
                filterObject.propertyCode = this.state.filterProperty;
                isFilterEnabled = true;
            }

            if(this.state.filterArrivalCheck === true){
                filterObject.arrivalFrom = Math.round(this.state.filterArrivalFrom/1000);
                filterObject.arrivalUntil = Math.round(this.state.filterArrivalUntil/1000);
                isFilterEnabled = true;
            }

            if(this.state.filterCreatedCheck === true){
                filterObject.createdFrom = Math.round(this.state.filterCreatedFrom/1000);
                filterObject.createdUntil = Math.round(this.state.filterCreatedUntil/1000);
                isFilterEnabled = true;
            }

            this.setState({filterEnabled: isFilterEnabled});
            this.props.onFilterUpdated(filterObject);
        }
    }

    /**
     * Returns true if a filter is enabled for
     * this reservation list and false if not
     */
    isFilterEnabled(){
        let result = false;

        if(this.state.filterPropertyCheck === true 
            && this.state.filterProperty !== null){
            result = true;
        }

        if(this.state.filterArrivalCheck === true){
            result = true;
        }

        if(this.state.filterCreatedCheck === true){
            result = true;
        }

        return result;
    }

    /**
     * Updates the search text in the local
     * state and notifies the parent when
     * sufficient search text was entered
     * 
     * @param {string} value
     * string value to set in the state 
     */
    updateSearchText(value){
        this.setState({searchText: value},(function(){
            if(value.length > 3 || value.length === 0){ 
                this.notifyFilterUpdate();
            }
        }).bind(this));
    }

    /**
     * Notifies the parent component about
     * the selection of the reservation
     * 
     * @param {string} recordLocator
     * the selected record locator
     *  
     * @param {string} accessKey 
     * the access key of the record locator
     */
    notifySelection(recordLocator,accessKey){
        if(typeof this.props.onSelect === 'function'){
            this.props.onSelect(recordLocator,accessKey);
        }
    }

    /**
     * Renders the reservation list with all
     * available reservations of this tenant
     */
    renderList(){
        let result = [];

        if(this.props.list.length === 0){
            result.push(
                <div key="ReservationListEmpty" className="ReservationListEmpty">
                    {Culture.getText('RESERVATION_LIST_EMPTY')}
                </div>
            );
        }else{
            let reservationList = [];
            for(const reservation of this.props.list){
                let itemSelected = false;
                if(reservation.recordLocator === this.props.recordLocator){
                    itemSelected = true;
                }

                let propertyName = reservation.propertyName;
                if(propertyName.length > 32){
                    propertyName = propertyName.substring(0,29) + '...';
                }

                let customerName = reservation.customerName;
                if(customerName.length > 32){
                    customerName = customerName.substring(0,29) + '...';
                }

                reservationList.push(
                    <tr key={reservation.recordLocator} data-selected={itemSelected} 
                        data-status={reservation.status} className="ReservationListItem"
                        onClick={this.notifySelection.bind(this,reservation.recordLocator,reservation.accessKey)}>
                        <td>
                            <div className="ReservationListItemStatus" data-status={reservation.status}>
                                {Culture.getText('RESERVATION_STATUS_'+reservation.status.toUpperCase())}
                            </div>
                        </td>
                        <td className="ReservationListItemCustomerName">
                            {customerName}
                        </td>
                        <td>
                            <div className="ReservationListItemPropertyName">
                                {propertyName}
                            </div>
                        </td>
                        <td className="ReservationListItemArrivalDate">
                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                dateStyle: 'full'
                            })).format(reservation.arrival*1000)}
                        </td>
                        <td className="ReservationListItemCreatedDate">
                            {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                dateStyle: 'medium',
                                timeStyle: 'medium'
                            })).format(reservation.created*1000)}
                        </td>
                        <td>
                            <div className="ReservationListItemRecordLocator">
                                {reservation.recordLocator}
                            </div>
                        </td>
                        <td>
                            <div className="ReservationListItemMenuButton"></div>
                        </td>
                    </tr>
                );
            }

            result = (
                <table cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <th>{Culture.getText('RESERVATION_LIST_STATUS')}</th>
                            <th>{Culture.getText('RESERVATION_LIST_CUSTOMERNAME')}</th>
                            <th>{Culture.getText('RESERVATION_LIST_PROPERTYNAME')}</th>
                            <th>{Culture.getText('RESERVATION_LIST_ARRIVALDATE')}</th>
                            <th>{Culture.getText('RESERVATION_LIST_CREATEDDATE')}</th>
                            <th>{Culture.getText('RESERVATION_LIST_RECORDLOCATOR')}</th>
                            <th></th>
                        </tr>
                        {reservationList}
                    </tbody>
                </table>
            );
        }

        return result;
    }

    /**
     * Renders the reservation list with the
     * reservation search input component
     */
    render(){
        return(
            <div className="ReservationListView">
                <div className="ReservationListSearch" 
                    data-issearching={this.props.isSearching} 
                    data-filterenabled={this.state.filterEnabled}>
                    <div className="ReservationListSearchContent">
                        <div className="ReservationListSearchField">
                            <input className="ReservationListSearchInputField" 
                                type="text" value={this.state.searchText}
                                onChange={(e) => this.updateSearchText(e.target.value)}
                                placeholder={Culture.getText('RESERVATION_SEARCH')} />
                        </div>
                        <StandardButton className="ReservationListFilterButton"
                            text={Culture.getText('RESERVATION_FILTER')}
                            onClick={() => {
                                this.setState({showFilterWindow: true});
                            }} />
                    </div>

                    {this.isFilterEnabled() === true &&
                        <div className="ReservationListSearchFilterList">
                            {this.state.filterPropertyCheck === true &&
                                <div className="ReservationListSearchFilterListItem">
                                    {Culture.getText('PROPERTY_ID') + ': ' + this.state.filterProperty}
                                    <div className="ReservationListSearchFilterListItemRemove" 
                                        onClick={() => {
                                            this.setState({
                                                filterPropertyCheck: false, 
                                                filterProperty: null
                                            },this.notifyFilterUpdate.bind(this));
                                        }}></div>
                                </div>
                            }

                            {this.state.filterArrivalCheck === true &&
                                isNaN(this.state.filterArrivalFrom) === false &&
                                isNaN(this.state.filterArrivalUntil) === false &&
                                <div className="ReservationListSearchFilterListItem">
                                    {Culture.getText('RESERVATION_LIST_ARRIVALDATE') + ': '
                                     + (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                        dateStyle: 'short'
                                    })).format(this.state.filterArrivalFrom) + ' - '
                                    + (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                        dateStyle: 'short'
                                    })).format(this.state.filterArrivalUntil)}
                                    <div className="ReservationListSearchFilterListItemRemove"
                                        onClick={() => {
                                            this.setState({
                                                filterArrivalCheck: false,
                                                filterArrivalFrom: 0,
                                                filterArrivalUntil: 0
                                            },this.notifyFilterUpdate.bind(this));
                                        }}></div>
                                </div>
                            }

                            {this.state.filterCreatedCheck === true &&
                                isNaN(this.state.filterCreatedFrom) === false &&
                                isNaN(this.state.filterCreatedUntil) === false &&
                                <div className="ReservationListSearchFilterListItem">
                                    {Culture.getText('RESERVATION_LIST_CREATEDDATE') + ': '
                                    + (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                        dateStyle: 'short'
                                    })).format(this.state.filterCreatedFrom) + ' - '
                                    + (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                        dateStyle: 'short'
                                    })).format(this.state.filterCreatedUntil)}
                                    <div className="ReservationListSearchFilterListItemRemove" 
                                        onClick={() => {
                                            this.setState({
                                                filterCreatedCheck: false,
                                                filterCreatedFrom: 0,
                                                filterCreatedUntil: 0
                                            },this.notifyFilterUpdate.bind(this));
                                        }}></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className="ReservationList">
                    {this.props.isSearching === true &&
                        <LoadingSlider />
                    }

                    {this.renderList()}
                </div>

                {this.state.showFilterWindow === true &&
                    <ModalDrawerOverlay onClose={() => this.setState({
                            showFilterWindow: false,
                            filterPropertyCheck: false,
                            filterProperty: null,
                            filterArrivalCheck: false,
                            filterArrivalFrom: 0,
                            filterArrivalUntil: 0,
                            filterCreatedCheck: false,
                            filterCreatedFrom: 0,
                            filterCreatedUntil: 0,
                            filterEnabled: false
                        })}
                        titleText={Culture.getText('RESERVATION_FILTER_TITLE')}
                        subtitleText={Culture.getText('RESERVATION_FILTER_SUBTITLE')}
                        submitButtonText={Culture.getText('RESERVATION_APPLY')}
                        onSubmit={(function(){
                            this.setState({showFilterWindow: false});
                            this.notifyFilterUpdate();
                        }).bind(this)}>

                        <div className="ReservationFilterSection">
                            <DataInputCheckBox title={Culture.getText('RESERVATION_FILTER_PROPERTY')} 
                                checked={this.state.filterPropertyCheck} onClick={() => {
                                    this.setState({filterPropertyCheck: !this.state.filterPropertyCheck})
                            }} />
                            {this.state.filterPropertyCheck === true &&
                                <PropertySelectInput title={Culture.getText('RESERVATION_FILTER_PROPERTY')}
                                    selected={(function(){
                                        let result = [];

                                        if(this.state.filterProperty !== null){
                                            result = [this.state.filterProperty];
                                        }

                                        return result;
                                    }).bind(this)()} 
                                    onChange={(list) => {
                                        if(list.length > 0){
                                            this.setState({filterProperty: list[list.length-1]});
                                        }else{
                                            this.setState({filterProperty: null});
                                        }
                                    }} />
                            }
                        </div>

                        <div className="ReservationFilterSection">
                            <DataInputCheckBox title={Culture.getText('RESERVATION_FILTER_ARRIVAL')} 
                                checked={this.state.filterArrivalCheck} onClick={() => {
                                    this.setState({filterArrivalCheck: !this.state.filterArrivalCheck})
                            }} />

                            {this.state.filterArrivalCheck === true &&
                                <DateRangeSelect onChange={(function(from,until){
                                        this.setState({
                                            filterArrivalFrom: from,
                                            filterArrivalUntil: until
                                        });
                                    }).bind(this)} />
                            }
                        </div>

                        <div className="ReservationFilterSection">
                            <DataInputCheckBox title={Culture.getText('RESERVATION_FILTER_CREATED')} 
                                checked={this.state.filterCreatedCheck} onClick={() => {
                                    this.setState({filterCreatedCheck: !this.state.filterCreatedCheck})
                            }} />

                            {this.state.filterCreatedCheck === true &&
                                <DateRangeSelect onChange={(function(from,until){
                                        this.setState({
                                            filterCreatedFrom: from,
                                            filterCreatedUntil: until
                                        });
                                    }).bind(this)} />
                            }
                        </div>
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default ReservationList;