/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

 /* require the necessary core classes */
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';

/* require the components for this control */
import StandardButton from '../common/StandardButton';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import LoadingSlider from '../common/LoadingSlider';

/* require the stylesheet for this component */
import '../../style/PropertySelectInput.css';

/**
 * Property select input component that allows
 * selecting and unselecting properties
 */
class PropertySelectInput extends React.Component {
    state = {
        searchId: 0,
        keyword: '',
        nextPageToken: '',
        list: [],
        showSelectWindow: false,
        searchLoading: false
    }

    /**
     * Toggles the item for which the property code
     * or property id was provided in the parameter
     * 
     * @param {string} propertyCode
     * id or code of the property to toggle 
     */
    toggleItem(propertyCode){
        let updatedList = [];
        if(Array.isArray(this.props.selected)){
            let itemExists = false;
            for(const selectedCode of this.props.selected){
                if(selectedCode === propertyCode){
                    itemExists = true;
                }else{
                    updatedList.push(selectedCode);
                }
            }

            if(itemExists === false){
                updatedList.push(propertyCode);
            }
        }else{
            updatedList.push(propertyCode);
        }

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(updatedList);
        }
    }

    /**
     * Fetches the property list from the
     * inventory api when the component is mounted
     */
    componentDidMount(){
        this.fetchList(false);
    }

    /**
     * Fetches the property list with the
     * keyword defined in the state and updates
     * the list in the state
     * 
     * @param {boolean} usePageToken
     * if false, does not use the page token
     */
    async fetchList(usePageToken){
        /* set the search id to avoid a race condition */
        let querySearchId = Date.now();
        this.setState({ searchId: querySearchId, searchLoading: true });

        let requestObject = { keyword: this.state.keyword };
        if(usePageToken !== false){
            requestObject.pageToken = this.state.nextPageToken;
        }

        let list = [];
        let requestMore = true;
        let nextPageToken = '';
        while(requestMore === true){
            requestMore = false;

            let apiResult = await ApiClient.execute('/inventory/property/list',requestObject);
            if(apiResult !== null){
                if(Array.isArray(apiResult.list)){
                    list = list.concat(apiResult.list);

                    /* query as many pages as required to have at least
                        10 results or no additional result pages available */
                    if(typeof apiResult.pageToken === 'string' 
                        && apiResult.pageToken !== ''){
                        if(list.length < 10){
                            /* indicate to have more and update the page token */
                            requestObject.pageToken = apiResult.pageToken;
                            requestMore = true;
                        }

                        nextPageToken = apiResult.pageToken;
                    }else{
                        nextPageToken = '';
                    }

                    if(this.state.searchId !== querySearchId){
                        /* abandon any subsequent queries as a new
                            search has already been initiated */
                        requestMore = false;
                    }
                }
            }
        }

        /* only update when this query is still the active search */
        if(this.state.searchId === querySearchId){
            /* append the result to the existing list if
                this is a paginated result */
            let resultList = this.state.list;
            if(usePageToken !== false){
                resultList = resultList.concat(list);
            }else{
                resultList = list;
            }

            this.setState({
                list: resultList, 
                nextPageToken: nextPageToken,
                searchLoading: false
            });
        }
    }

    /**
     * Renders the list of all available properties
     * with the checkbox and allows to check or uncheck
     * the listed properties
     */
    renderList(){
        let result = [];

        for(const propertyItem of this.state.list){
            let isSelected = false;
            if(Array.isArray(this.props.selected)){
                if(this.props.selected.includes(propertyItem.propertyId)){
                    isSelected = true;
                }
            }

            result.push(
                <div key={propertyItem.propertyId} 
                    className="PropertySelectInputListItem" data-selected={isSelected} 
                    onClick={this.toggleItem.bind(this,propertyItem.propertyId)}>
                    <div className="PropertySelectInputListItemName">
                        {propertyItem.name}
                    </div>
                </div>
            );
        }

        if(result.length === 0){
            result.push(
                <div key="PropertySelectInputEmpty" className="PropertySelectInputEmpty">
                    {Culture.getText('PROPERTYSELECTLIST_EMPTY')}
                </div>
            )
        }

        return result;
    }

    /**
     * Renders the list of selected properties
     * and returns the rendered elements
     */
    renderSelectedList(){
        let result = [];

        if(Array.isArray(this.props.selected)){
            for(const selectedItem of this.props.selected){
                let itemName = selectedItem;
                if(typeof this.props.tenantPrefix === 'string'
                    && this.props.tenantPrefix !== ''){
                    itemName = [this.props.tenantPrefix,itemName].join('$');
                }

                result.push(
                    <div key={selectedItem} className="PropertySelectInputSelectedListItem">
                        <div className="PropertySelectInputSelectedListItemName">
                            {itemName}
                        </div>

                        <div className="PropertySelectInputSelectedListItemRemove"
                            onClick={this.toggleItem.bind(this,selectedItem)}></div>
                    </div>
                );
            }
        }

        return result;
    }

    /**
     * Renders the property select window
     * that allows the user to search properties
     * and add them to the selected list
     */
    renderSelectWindow(){
        let result = null;

        if(this.state.showSelectWindow === true){
            let transparentBackground = true;
            if(this.props.transparentBackground === false){
                transparentBackground = false;
            }

            result = (
                <ModalDrawerOverlay className="PropertySelectInputOverlay"
                    onClose={() => this.setState({showSelectWindow: false})}
                    submitButtonText={Culture.getText('PROPERTYLIST_SELECT_APPLYBUTTON')}
                    onSubmit={() => this.setState({showSelectWindow: false})}
                    transparentBackground={transparentBackground}>
                    <div className="PropertySelectInputFilter">
                        <input type="text" value={this.state.keyword} 
                            placeholder={Culture.getText('PROPERTYSELECTLIST_FILTERTITLE')}
                            onChange={(function(e){
                                this.setState({keyword: e.target.value, list: []},this.fetchList);
                            }).bind(this)} />
                    </div>
                    {this.state.searchLoading === true && <LoadingSlider /> } 
                    <div className="PropertySelectInputList">
                        {this.renderList()}

                        {this.state.nextPageToken !== '' &&
                            <div className="PropertySelectInputOverlayNextButton" 
                                onClick={this.fetchList.bind(this)}>
                                {Culture.getText('SHOW_PROPERTYLIST_NEXTPAGE')}
                            </div>
                        }
                    </div>
                </ModalDrawerOverlay>
            );
        }

        return result;
    }

    /**
     * Renders the component with the property
     * list selection and the search filter
     */
    render(){
        /* get the rendered selected list */
        let selectedList = this.renderSelectedList();
        if(selectedList.length === 0){
            selectedList = (
                <div className="PropertySelectInputSelectedListEmpty">
                    {this.props.emptyText || Culture.getText('PROPERTYLIST_SELECT_EMPTY')}
                </div>
            );
        }

        return(
            <div className="PropertySelectInput">
                <div className="PropertySelectInputTitle">
                    {this.props.title}
                </div>
                <div className="PropertySelectInputSelectedList">
                    {selectedList}
                </div>
                <div>
                    <StandardButton className="PropertySelectInputSelectButton"
                        text={Culture.getText('PROPERTYLIST_SELECT_BUTTON')} 
                        onClick={() => this.setState({showSelectWindow: true})} />
                </div>
                {this.renderSelectWindow()}
            </div>
        );
    }
}

export default PropertySelectInput;