/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/**
 * Represents a rate plan with a pricing list
 * alongisde the basic values for a rate plan
 * such as it's activity, code and name.
 */
class RatePlan {
    /**
     * Constructs the rate plan object with
     * the fields provided in the object supplied
     * as a parameter value
     * 
     * @param {object} value
     * the rate plan object with the values for this object 
     */
    constructor(value){
        /* assign the values to this object */
        Object.assign(this,value);

        /* ensure the pricing list array exists */
        if(!Array.isArray(this.pricingList)){
            this.pricingList = [];
        }

        /* ensure the active indicator is set */
        if(typeof this.active !== 'boolean'){
            this.active = false;
        }
    }

    /**
     * Returns true when this rate plan
     * is active and false if it is not
     */
    isActive(){
        return this.active;
    }

    /**
     * Sets this rate plan to active or
     * inactive depending on the provided
     * value.
     * 
     * @param {boolean} value 
     * If true, this rate plan will be set
     * to active and otherwise inactive
     */
    setActive(value){
        if(value === true){
            this.active = true;
        }else{
            this.active = false;
        }
    }

    /**
     * Returns the timestamp for the date
     * in UTC at midnight of the provided day
     * 
     * @param {object} date
     * the date to return the UTC date for 
     */
    getUTCDateObject(date){
        return new Date(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate()));
    }

    /**
     * Removes any length of stay pricing item
     * in any price list which overlaps with the
     * date range provided in the parameters
     * 
     * @param {object} fromDate
     * the start date to block availability from
     *  
     * @param {object} toDate
     * the end date to block availability until 
     */
    blockDateRange(fromDate,toDate){
        /* get the timestamp for the start and end date */
        let fromTs = this.getUTCDateObject(fromDate).getTime();
        let toTs = this.getUTCDateObject(toDate).getTime();
        
        let updatedList = [];
        for(let l=0; l<this.pricingList.length; l++){
            let item = this.pricingList[l];

            if(item.dateTime < fromTs){
                let updatedPriceList = {};
                let hasApplicablePrice = false;
                Object.keys(item.priceList).forEach((lengthOfStay) => {
                    let checkOutDate = item.dateTime + ((parseInt(lengthOfStay)-1) * 86400000);
                    if(checkOutDate < fromTs){
                        updatedPriceList[lengthOfStay] = item.priceList[lengthOfStay];
                        hasApplicablePrice = true;
                    }
                });

                if(hasApplicablePrice === true){
                    item.priceList = updatedPriceList;
                    updatedList.push(item);
                }
            }else{
                if(item.dateTime > toTs){
                    updatedList.push(item);
                }
            }
        }

        this.pricingList = updatedList;
    }

    /**
     * Returns the pricing list for the
     * date range provided in the parameters
     * 
     * @param {date} fromDate
     * the from date inclusive
     *  
     * @param {date} toDate 
     * the to date inclusive
     * 
     * @param {number} occupancy
     * the occupancy to get the prices for
     */
    getPricingListByDateRange(fromDate,toDate,occupancy){
        let result = {};

        /* get the timestamp for the start and end date */
        let fromTs = this.getUTCDateObject(fromDate).getTime();
        let toTs = this.getUTCDateObject(toDate).getTime();

        let currencyCode = '';
        this.pricingList.forEach((item) => {
            if(item.dateTime >= fromTs && item.dateTime <= toTs && item.occupancy === occupancy){
                /* define the currency code if not yet set */
                if(currencyCode === ''){ currencyCode = item.currencyCode; }

                if(typeof item.priceList === 'object' && item.priceList !== null){
                    Object.keys(item.priceList).forEach((lengthOfStay) => {
                        let priceValue = item.priceList[lengthOfStay];
                        if(result.hasOwnProperty(lengthOfStay)){
                            if(result[lengthOfStay].amount !== priceValue){
                                result[lengthOfStay] = {
                                    amount: false,
                                    currencyCode: item.currencyCode
                                };
                            }
                        }else{
                            result[lengthOfStay] = {
                                amount: priceValue,
                                currencyCode: item.currencyCode
                            };
                        }
                    });
                }
            }
        });

        return result;
    }

    /**
     * Changes a single LOS price item in the
     * date range provided in the parameter with
     * the amount and currency code provided
     * 
     * @param {object} fromDate
     * the date from which to start changing the price
     *  
     * @param {object} toDate
     * the date until which to change the price inclusively
     *  
     * @param {number} lengthOfStay 
     * the length of stay to change the price amount for
     * 
     * @param {number} occupancy
     * the occupancy value to change the price value for
     *  
     * @param {number} amount 
     * the amount as float to change into
     * 
     * @param {string} currencyCode 
     * the currency code as string to change into
     */
    changeSinglePriceInDateRange(fromDate,toDate,lengthOfStay,occupancy,amount,currencyCode){
        let fromTs = this.getUTCDateObject(fromDate).getTime();
        let toTs = this.getUTCDateObject(toDate).getTime();
        for(let i=0; i<this.pricingList.length; i++){
            let pricingItem = this.pricingList[i];
            if(pricingItem.dateTime >= fromTs && pricingItem.dateTime <= toTs && pricingItem.occupancy === occupancy){
                if(amount > 0){
                    /* change the LOS pricing item when it is greater than 0 */
                    pricingItem.priceList[parseInt(lengthOfStay)] = amount;
                    pricingItem.currencyCode = currencyCode;
                }else{
                    /* delete the LOS-pricing value when it is below 0 or smaller */
                    delete pricingItem.priceList[parseInt(lengthOfStay)];
                }
            }
        }
    }

    /**
     * Changes the length of stay price in
     * the pricing list for the date provided
     * and the minimum as well as the maximum
     * length of stay. The daily rate will be
     * multiplied by the length of stay for
     * each length of stay item
     * 
     * @param {object} date
     * the date object to change the price for
     *  
     * @param {number} dailyRate 
     * the daily rate to multiply by length of stay
     * 
     * @param {string} currencyCode
     * the currency code of the provided daily rate
     * 
     * @param {number} occupancy
     * the occupancy to change the rate for
     * 
     * @param {number} minLOS 
     * the minimum length of stay for the pricing
     * 
     * @param {number} maxLOS
     * the maximum length of stay for the pricing 
     */
    changeLOSPrice(date,dailyRate,currencyCode,occupancy,minLOS,maxLOS){
        /* create the price list object */
        let priceList = {};
        for(let l=minLOS; l<=maxLOS; l++){
            priceList[l] = (dailyRate * l);
        }

        let timeStamp = this.getUTCDateObject(date).getTime();
        let hasEntry = false;
        for(let i=0; i<this.pricingList.length; i++){
            let pricingItem = this.pricingList[i];
            /* check if this pricing item matches the critera */
            if(pricingItem.dateTime === timeStamp 
                && pricingItem.occupancy === occupancy){                
                /* update the pricing item */
                pricingItem.currencyCode = currencyCode;
                pricingItem.priceList = priceList;
                hasEntry = true;

                this.pricingList[i] = pricingItem;
            }
        }

        /* add the pricing item if it does not exist */
        if(hasEntry === false){
            this.pricingList.push({
                dateTime: timeStamp,
                occupancy: occupancy,
                priceList: priceList,
                currencyCode: currencyCode
            });
        }
    }

    /**
     * Returns the lowest price by date
     * and if there is no price for the
     * provided date, it'll return false
     * 
     * @param {object} date
     * the date object to get the price for 
     */
    getLowestPriceByDate(date){
        let result = false;

        let timeStamp = this.getUTCDateObject(date).getTime();
        this.pricingList.forEach((item) => {
            if(item.dateTime === timeStamp){
                Object.keys(item.priceList).forEach((lengthOfStay) => {
                    if(result === false || item.priceList[lengthOfStay] < result){
                        result = {
                            amount: item.priceList[lengthOfStay],
                            currencyCode: item.currencyCode
                        };
                    }
                });
            }
        });

        return result;
    }

    /**
     * Clears the price list in the date
     * range provided as a parameter
     * 
     * @param {object} dateObject
     * date of which to clear prices
     */
    clearPriceList(dateObject){
        let updatedList = [];

        for(let i=0; i<this.pricingList.length; i++){
            let pricingItem = this.pricingList[i];
            if(pricingItem.dateTime === this.getUTCDateObject(dateObject).getTime()){                
                updatedList.push(pricingItem);
            }
        }

        this.pricingList = updatedList;
    }
}

export default RatePlan;