/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import '../../style/ModalDrawerOverlay.css';

class ModalDrawerOverlay extends React.Component {
    render(){
        let classList = ['ModalDrawerOverlayWindow'];
        if(typeof this.props.className === 'string' && this.props.className !== ''){
            classList.push(this.props.className);
        }

        return(
            <div className="ModalDrawerOverlayBackground"
                data-backgroundtransparent={(this.props.transparentBackground === true)} 
                onClick={(function(e){
                /* close the drawer when the background was clicked, but
                    do not close if when the windows was clicked */
                if(e.target.className === 'ModalDrawerOverlayBackground'){
                    if(typeof this.props.onClose === 'function'){
                        this.props.onClose();
                    }
                }
            }).bind(this)}>
                <div className={classList.join(' ')}>
                    <div className="ModalDrawerOverlayWindowCloseButton" onClick={(function(){
                        if(typeof this.props.onClose === 'function'){
                            this.props.onClose();
                        }
                    }).bind(this)}></div>

                    {typeof this.props.titleText === 'string' &&
                        <div className="ModalDrawerOverlayWindowTitle">
                            <div className="ModalDrawerOverlayWindowTitleText">
                                {this.props.titleText}
                            </div>

                            {typeof this.props.subtitleText === 'string' &&
                                <div className="ModalDrawerOverlayWindowSubTitleText">
                                    {this.props.subtitleText}
                                </div>
                            }
                        </div>
                    }

                    <div className="ModalDrawerOverlayWindowContent">
                        {typeof this.props.introText === 'string' &&
                            this.props.introText !== '' &&
                            <div className="ModalDrawerOverlayWindowIntroText">
                                {this.props.introText}
                            </div>
                        }

                        {this.props.children}
                    </div>

                    {typeof this.props.submitButtonText === 'string' && 
                        <div className="ModalDrawerOverlayWindowButtonBar">
                            <div className="ModalDrawerOverlayWindowSubmitButton"
                                data-disabled={(this.props.submitDisabled === true)}
                                onClick={(function(){
                                    if(this.props.submitDisabled !== true){
                                        if(typeof this.props.onSubmit === 'function'){
                                            this.props.onSubmit();
                                        }
                                    }
                                }).bind(this)}>
                                {this.props.submitButtonText}
                            </div>

                            {typeof this.props.onClose === 'function' &&
                                <div className="ModalDrawerOverlayWindowAbortButton" 
                                    onClick={(function(){this.props.onClose();}).bind(this)}>
                                    {this.props.abortButtonText || Culture.getText('CANCELACTION_TEXT')}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ModalDrawerOverlay;