/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the necessary core classes */
import Culture from '../../class/Culture';

/* require the necessary components */
import DataInputField from '../common/DataInputField';
import DataInputList from '../common/DataInputList';
import DataInputCheckBox from '../common/DataInputCheckBox';
import PropertySelectInput from '../inventory/PropertySelectInput';
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import ConnectivityEndpointView from './ConnectivityEndpointView';

/**
 * This editor form allows creating and editing
 * opentravel connections and notifies the parent
 * about any changes to the configuration object
 * provided in the parameters
 */
class OpenTravelConnectionEditor extends GenericProviderConnectionEditor {
    /**
     * Notifies the parent object about
     * the change and attaches the provider
     * specific configuration values
     * 
     * @param {object} configObject 
     * the config object to notify update of
     */
    notifyUpdate(configObject){
        /* set the opentravel specific settings */
        configObject.remoteSystemCode = 'opentravel';
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(configObject);
        }
    }

    /**
     * Renders the component to edit
     * or create OpenTravel providers
     */
    render(){
        return(
            <div className="ProviderConnectionEditor">
                <ConnectivityEndpointView channelCode={this.props.channelCode} />

                {this.props.editMode === false &&
                    <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERUSERNAME')}
                        value={this.getUserName()} onChange={(value) => this.updateConfigValue('userName',value)} />
                }

                {this.props.editMode === true &&
                    <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERUSERNAME')}
                        value={this.getUserName()} disabled={true} />
                }

                <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERPASSWORD')}
                    value={this.getPassword()} isPassword={true}
                    onChange={(value) => this.updateConfigValue('password',value)} />

                <PropertySelectInput title={Culture.getText('CONNECTIVITY_LINKEDPROPERTIES')}
                        selected={this.getLinkedPropertyList()} 
                        onChange={this.updateLinkedProperty.bind(this)} />

                <DataInputCheckBox title={Culture.getText('CONNECTIVITY_ENABLERESERVATIONPUSH')} 
                        checkedTitle={Culture.getText('CONNECTIVITY_RESERVATIONPUSHENABLED')}
                        checked={this.getConfigValue('pushReservation',false)} onClick={(function(){
                            /* toggle the config value for reservation push */
                            this.updateConfigValue('pushReservation',!this.getConfigValue('pushReservation'));
                        }).bind(this)} 
                    />

                {this.getConfigValue('pushReservation',false) === true &&
                    <>
                        <DataInputField title={Culture.getText('CONNECTIVITY_REMOTEENDPOINT')}
                            value={this.getConfigValue('remoteEndpointUrl')}
                            onChange={(function(value){
                                this.updateConfigValue('remoteEndpointUrl',value);
                            }).bind(this)} />

                        <DataInputCheckBox title={Culture.getText('CONNECTIVITY_RESERVATIONPUSH_TENANTPREFIX')} 
                            checkedTitle={Culture.getText('CONNECTIVITY_RESERVATIONPUSH_TENANTPREFIX')}
                            checked={this.getConfigValue('requireTenantPrefixPush',false)} onClick={(function(){
                                /* toggle the config value for tenant prefix in reservation push */
                                this.updateConfigValue('requireTenantPrefixPush',!this.getConfigValue('requireTenantPrefixPush',false));
                            }).bind(this)} 
                        />

                        <DataInputList title={Culture.getText('CONNECTIVITY_PROTOCOLVERSION')} 
                            value={this.getConfigValue('remoteSoapTag','soap')} 
                            list={[
                                /* define the list of available SOAP standards to use for push */
                                {value: 'soap', text: Culture.getText('CONNECTIVITY_PROTOCOLVERSION_SOAP')},
                                {value: 'SOAP-ENV', text: Culture.getText('CONNECTIVITY_PROTOCOLVERSION_SOAP_ENV')},
                                {value: 'soapenv', text: Culture.getText('CONNECTIVITY_PROTOCOLVERSION_SOAPENV')},
                                {value: '_xml', text: Culture.getText('CONNECTIVITY_PROTOCOLVERSION_XMLONLY')}
                            ]} 
                            onChange={(value) => {
                                this.updateConfigValue('remoteSoapTag',value);
                        }}/>

                        <DataInputField title={Culture.getText('CONNECTIVITY_REMOTEPUSHUSERNAME')}
                            placeholderText={Culture.getText('CONNECTIVITY_REMOTEPUSH_USEDEFAULTCREDENTIAL')}
                            value={this.getConfigValue('remotePushUserName','')} isPassword={false}
                            onChange={(value) => this.updateConfigValue('remotePushUserName',value)} />

                        <DataInputField title={Culture.getText('CONNECTIVITY_REMOTEPUSHPASSWORD')}
                            placeholderText={Culture.getText('CONNECTIVITY_REMOTEPUSH_USEDEFAULTCREDENTIAL')}
                            value={this.getRemotePushPassword()} isPassword={true}
                            onChange={(value) => this.updateConfigValue('remotePushPassword',value)} />

                        <DataInputField title={Culture.getText('CONNECTIVITY_REMOTEPUSHTARGET')}
                            value={this.getConfigValue('remotePushTarget','')} isPassword={false}
                            onChange={(value) => this.updateConfigValue('remotePushTarget',value)} />
                    </>
                }
            </div>
        );
    }
}

export default OpenTravelConnectionEditor;