/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import '../../style/OperationTimeInput.css';

class OperationTimeInput extends React.Component {
    state = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        openFrom: 0,
        openFromText: "00:00",
        openFromTextValid: true,
        openUntil: 0,
        openUntilText: "00:00",
        openUntilTextValid: true
    }

    /**
     * 
     * @param {*} timeType 
     * @param {*} value 
     */
    changeTimeValue(timeType,value){
        let timestampValue = this.state[timeType];
        let timestampChanged = false;
        if(value.match(/[0-9]{1,2}:[0-9]{1,2}/)){
            let timePart = value.split(':');
            timestampValue = (parseInt(timePart[0])*3600000)
                            + (parseInt(timePart[1])*60000);
            timestampChanged = true;
        }

        this.setState({
            [(timeType+'Text')]: value.replace(/[^0-9:]/gi,''),
            [timeType]: timestampValue,
            [(timeType)+'TextValid']: timestampChanged
        });
    }

    /**
     * 
     * @param {*} value 
     */
    getHourStringFromTimestamp(value){
        let dateObject = new Date(value);
        return String(dateObject.getUTCHours()).padStart(2,'0') + ":"
                + String(dateObject.getUTCMinutes()).padStart(2,'0');
    }

    /**
     * 
     * @param {*} index 
     */
    removeTimeItem(index){
        /* remove this item from the list */
        let updatedList = [];
        for(let i=0; i<this.props.list.length; i++){
            if(i !== index){ updatedList.push(this.props.list[i]); }
        }

        if(typeof this.props.onChange === 'function'){
            updatedList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
            this.props.onChange(updatedList);
        }
    }

    /**
     * 
     */
    renderTimeList(){
        let componentList = [];
        if(Array.isArray(this.props.list)){
            let t = 0;
            this.props.list.forEach((timeItem) => {
                let weekComponentList = [];
                let weekList = ['monday', 'tuesday', 'wednesday', 'thursday', 
                                'friday', 'saturday', 'sunday'];
                weekList.forEach((weekDay) => {
                    let weekDayClassList = ["OperationTimeWeekday"];
                    if(timeItem[weekDay] === true){
                        weekDayClassList.push("OperationTimeWeekdayActive");
                    }

                    weekComponentList.push(
                        <div key={weekDay} className={weekDayClassList.join(' ')}>
                            {Culture.getText('WEEKDAY_SHORTNAME_'+weekDay.toUpperCase())}
                        </div>
                    );
                });

                componentList.push(
                    <div key={"operationTime"+t} className="OperationTimeItem">
                        <div className="OperationTimeItemInput">
                            <div className="OperationTimeWeekdayList">
                                {weekComponentList}
                            </div>
                            <div className="OperationTimePeriod">
                                    <div className="OperationTimeFrom">
                                        {this.getHourStringFromTimestamp(timeItem.start)}
                                    </div>
                                    <div className="OperationTimeUntil">
                                        {this.getHourStringFromTimestamp(timeItem.end)}
                                    </div>
                            </div>
                        </div>
                        <div className="OperationTimeItemRemoveButton" 
                            onClick={this.removeTimeItem.bind(this,t)}></div>
                    </div>
                );

                t++;
            });
        }

        if(componentList.length === 0){
            componentList.push(
                <div key={"operationTimeEmpty"} className="OperationTimeListEmpty">
                    {Culture.getText('OPERATIONTIMELIST_EMPTY')}
                </div>
            );
        }

        return componentList;
    }

    /**
     * 
     */
    renderInputField(){
        let weekComponentList = [];
        let weekList = ['monday', 'tuesday', 'wednesday', 'thursday', 
                        'friday', 'saturday', 'sunday'];
        weekList.forEach((weekDay) => {
            let weekDayClassList = ["OperationTimeWeekday"];
            if(this.state[weekDay] === true){
                weekDayClassList.push("OperationTimeWeekdayActive");
            }

            weekComponentList.push(
                <div key={weekDay} className={weekDayClassList.join(' ')} onClick={() => {
                    this.setState({[weekDay]: !this.state[weekDay]});
                }}>
                    {Culture.getText('WEEKDAY_SHORTNAME_'+weekDay.toUpperCase())}
                </div>
            );
        });

        let addButton = (<div className="OperationTimeAddButton OperationTimeAddButtonDisabled"></div>);
        if(this.state.openFromTextValid === true && this.state.openUntilTextValid === true){
            let isWeekdaySelected = false;
            weekList.forEach((weekDay) => {
                if(this.state[weekDay] === true){
                    isWeekdaySelected = true;
                }
            });

            if(isWeekdaySelected === true){
                addButton = (
                    <div className="OperationTimeAddButton" onClick={() => {
                        let timeList = [];
                        if(Array.isArray(this.props.list)){ timeList = this.props.list; }

                        /* define the new operation time object */
                        let timeObject = {start: this.state.openFrom, end: this.state.openUntil};
                        weekList.forEach((weekDay) => {
                            timeObject[weekDay] = this.state[weekDay];
                        });

                        /* push the new opening hour onto the list */
                        timeList.push(timeObject);

                        /* notify the parent about the update */
                        if(typeof this.props.onChange === 'function'){
                            timeList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0));
                            this.props.onChange(timeList);
                        }

                        /* reset the state to the initial values */
                        this.setState({
                            monday: false, tuesday: false, wednesday: false, thursday: false,
                            friday: false, saturday: false, sunday: false, openFrom: 0,
                            openFromText: "00:00", openFromTextValid: true, openUntil: 0,
                            openUntilText: "00:00", openUntilTextValid: true
                        });
                    }}></div>
                );
            }
        }

        return(
            <div className="OperationTimeItem">
                <div className="OperationTimeItemInput">
                    <div className="OperationTimeWeekdayList">
                        {weekComponentList}
                    </div>
                    <div className="OperationTimePeriod">
                            <div className="OperationTimeFrom">
                                <input type="text" value={this.state.openFromText} 
                                    onChange={(e) => {
                                        this.changeTimeValue('openFrom',e.target.value);
                                    }} />
                            </div>
                            <div className="OperationTimeUntil">
                                <input type="text" value={this.state.openUntilText} 
                                    onChange={(e) => {
                                        this.changeTimeValue('openUntil',e.target.value);
                                    }} />
                            </div>
                    </div>
                </div>

                {addButton}
            </div>
        );
    }

    /**
     * 
     */
    render(){
        let classList = ["OperationTimeInput"];
        if(typeof this.props.className === "string"){
            classList.push(this.props.className);
        }
        
        return(
            <div className={classList.join(' ')}>
                {typeof this.props.title === "string" && this.props.title !== "" &&
                    <div className="OperationTimeInputTitle">
                        {this.props.title}
                    </div>
                }

                <div className="OperationTimeList">
                    {this.renderTimeList()}
                </div>

                <div className="AddOperationTime">
                    {this.renderInputField()}
                </div>
            </div>
        );
    }
}

export default OperationTimeInput;