/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import '../../style/CancellationPolicyInput.css';

/* require the sub components for this component */
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputFieldUnit from '../common/DataInputFieldUnit';

/**
 * Allows editing cancellation policy documents
 * as well as selecting available policies
 */
class CancellationPolicyInput extends React.Component {
    state = {
        showPolicyCreateWindow: false,
        deadlineValue: 0,
        deadlineUnit: 'days',
        deadlineChargeValue: 0,
        deadlineChargeUnit: 'percent',
        reservationChargeValue: 0,
        reservationChargeUnit: 'percent'
    }

    /**
     * Creates a new policy with the values
     * from the state, adds it to the list
     * and notifies the parent about the
     * change of the list
     */
    createPolicy(){
        let updatedList = this.props.list;
        if(!Array.isArray(updatedList)){ updatedList = []; }

        let policyDocument = {
            code: 20000,
            percentAfterReservation: 0,
            nightsAfterReservation: 0,
            deadlineDays: 0,
            deadlineHours: 0,
            percentAfterDeadline: 0,
            nightsAfterDeadline: 0,
            noShowPolicy: 'default'
        };

        if(this.state.deadlineChargeValue > 0 && this.state.deadlineValue > 0){
            if(this.state.deadlineUnit === 'hours'){
                policyDocument.deadlineHours = this.state.deadlineValue;
                policyDocument.deadlineDays = Math.round(this.state.deadlineValue/24);
            }if(this.state.deadlineUnit === 'days'){
                policyDocument.deadlineHours = (this.state.deadlineValue*24);
                policyDocument.deadlineDays = this.state.deadlineValue;
            }
        }

        if(this.state.deadlineChargeUnit === 'nights'){
            policyDocument.nightsAfterDeadline = (Math.round(this.state.deadlineChargeValue*100)/100);
        }if(this.state.deadlineChargeUnit === 'percent'){
            policyDocument.percentAfterDeadline = (Math.round(this.state.deadlineChargeValue*100)/100);
            if(policyDocument.percentAfterDeadline > 100){
                policyDocument.percentAfterDeadline = 100;
            }
        }

        if(this.state.reservationChargeValue > 0){
            if(this.state.reservationChargeUnit === 'nights'){
                policyDocument.nightsAfterReservation = (Math.round(this.state.reservationChargeValue*100)/100);
            }if(this.state.reservationChargeUnit === 'percent'){
                policyDocument.percentAfterReservation = (Math.round(this.state.reservationChargeValue*100)/100);
                if(policyDocument.percentAfterReservation > 100){
                    policyDocument.percentAfterReservation = 100;
                }
            }
        }

        this.setState({
            showPolicyCreateWindow: false,
            deadlineValue: 0,
            deadlineUnit: 'days',
            deadlineChargeValue: 0,
            deadlineChargeUnit: 'percent',
            reservationChargeValue: 0,
            reservationChargeUnit: 'percent'
        });

        updatedList.push(policyDocument);
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(updatedList);
        }
    }

    /**
     * Returns the text for the cancellation
     * charge defined in both parameters, prefering
     * the nights over the percentage
     * 
     * @param {number} percentValue 
     * percent value of the cancellation charge
     * is only used when night equals 0
     * 
     * @param {number} nightValue 
     * night value of the cancellation charge
     */
    getChargeValueText(percentValue,nightValue){
        let result = '—';

        if(nightValue > 0){
            if(nightValue === 1){
                /* return the text for a single night */
                result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_SINGLENIGHT');
            }else{
                /* return the text for multiple nights */
                result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_NIGHT')
                                            .replace('{$value}',nightValue);
            }
        }else{
            /* return the text for a percent value */
            if(percentValue > 0){
                result = (percentValue + ' %');
            }
        }

        return result;
    }

    /**
     * Returns the text for the deadline 
     * hours before arrival string
     * 
     * @param {number} deadlineHours 
     * hours before arrival when the deadline kicks in
     */
    getDeadlineText(deadlineHours){
        let result = '—';
    
        if(deadlineHours > 0){
            let dayCount = Math.floor(deadlineHours/24);
            let hourCount = (Math.floor((deadlineHours-(dayCount*24)))%24);

            /* define the default text for days and hours */
            result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_DAYSHOURS')
                                    .replace('{$days}',dayCount).replace('{$hours}',hourCount);
            if(hourCount === 1){
                result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_DAYSHOURS_SINGLEHOUR')
                                                .replace('{$days}',dayCount).replace('{$hours}',hourCount);

                if(dayCount === 1){
                    result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_DAYSHOURS_SINGLEDAYHOUR');
                }
            }if(hourCount === 0){
                result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_DAYS').replace('{$days}',dayCount);
                if(dayCount === 1){
                    result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_DAYS_SINGLE');
                }
            }if(dayCount === 0){
                let timeString = (new Intl.DateTimeFormat(Culture.getCultureCode(),{
                    timeStyle: 'short', timeZone: 'UTC'
                })).format((24-deadlineHours)*3600000);

                result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_ARRIVALDAY')
                                                                .replace('{$time}',timeString);
            }
        }else{
            result = Culture.getText('PROPERTY_CANCELLATIONPOLICY_REMOVE_DEADLINE_ARRIVALDAY_DEFAULT');
        }

        return result;
    }

    /**
     * Renders the cancellation policy
     * input component to edit and create
     * policy documents
     */
    render(){
        let classList = ["CancellationPolicyInput"];
        if(typeof this.props.className === 'string'){
            classList.push(this.props.className);
        }

        /* determine the text of the add button and window title */
        let actionText = Culture.getText('PROPERTY_CANCELLATIONPOLICY_ADD');
        if(typeof this.props.addButtonText === 'string' && this.props.addButtonText !== ''){
            actionText = this.props.addButtonText;
        }

        return(
            <div className={classList.join(' ')}>
                <div className="CancellationPolicyInputList">
                    {this.props.list.length > 0 &&
                        <table cellPadding={0} cellSpacing={0}>
                            <thead>
                                <tr>
                                    <td className="CancellationPolicyInputListDeadlineHead">
                                        {Culture.getText('PROPERTY_CANCELLATIONPOLICY_DEADLINE')}
                                    </td>
                                    <td className="CancellationPolicyInputListHead">
                                        {Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGEAFTERDEADLINE')}
                                    </td>
                                    <td className="CancellationPolicyInputListHead">
                                        {Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGEAFTERRESERVATION')}
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {(function(){
                                    let result = [];
    
                                    if(Array.isArray(this.props.list)){
                                        let policyList = this.props.list;
                                        policyList.sort((a,b) => (a.deadlineHours < b.deadlineHours) 
                                                    ? 1 : ((b.deadlineHours < a.deadlineHours) ? -1 : 0));

                                        for(let i=0; i<policyList.length; i++){
                                            let item = policyList[i];

                                            result.push(
                                                <tr key={i}>
                                                    <td className="CancellationPolicyInputListDeadlineValue">
                                                        {this.getDeadlineText(item.deadlineHours)}
                                                    </td>
                                                    <td className="CancellationPolicyInputListValue">
                                                        {this.getChargeValueText(item.percentAfterDeadline,
                                                                                item.nightsAfterDeadline)}
                                                    </td>
                                                    <td className="CancellationPolicyInputListValue">
                                                        {this.getChargeValueText(item.percentAfterReservation,
                                                                                item.nightsAfterReservation)}
                                                    </td>
                                                    <td className="CancellationPolicyInputListValue">
                                                        <div className="CancellationPolicyInputListItemRemove" 
                                                            onClick={(function(existList,index){
                                                                /* remove the item at the index */
                                                                let updatedList = [];
                                                                for(let p=0; p<existList.length; p++){
                                                                    if(p !== index){
                                                                        updatedList.push(existList[p]);
                                                                    }
                                                                }
                                                                
                                                                if(typeof this.props.onChange === 'function'){
                                                                    this.props.onChange(updatedList);
                                                                }
                                                            }).bind(this,policyList,i)}>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    }
    
                                    return result;
                                }).bind(this)()}
                            </tbody>
                        </table>
                    }

                    {this.props.list.length === 0 &&
                        <div className="CancellationPolicyInputListItemEmpty">
                            {this.props.emptyText}
                        </div>
                    }
                </div>
                <div>
                    <div className="CancellationPolicyInputAddButton" 
                        onClick={() => this.setState({showPolicyCreateWindow: true})}>
                        {actionText}
                    </div>
                </div>

                {this.state.showPolicyCreateWindow === true &&
                    <ModalDrawerOverlay titleText={(this.props.titleText || Culture.getText('PROPERTY_POLICY_CANCELLATIONPOLICY_TITLE'))}
                        subtitleText={(this.props.subtitleText || Culture.getText('PROPERTY_CANCELLATIONPOLICY_ADD'))}
                        onClose={() => this.setState({showPolicyCreateWindow: false})}
                        submitButtonText={actionText}
                        onSubmit={this.createPolicy.bind(this)}>                        
                        <DataInputFieldUnit title={Culture.getText('PROPERTY_CANCELLATIONPOLICY_DEADLINE')}
                            value={{
                                value: this.state.deadlineValue, 
                                unit: this.state.deadlineUnit
                            }}
                            unitTypeList={[
                                {code: 'days', name: Culture.getText('PROPERTY_CANCELLATIONPOLICY_DEADLINE_UNITDAYS')},
                                {code: 'hours', name: Culture.getText('PROPERTY_CANCELLATIONPOLICY_DEADLINE_UNITHOURS')}
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    deadlineValue: value.value,
                                    deadlineUnit: value.unit
                                });
                            }}
                        />

                        <DataInputFieldUnit title={Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGEAFTERDEADLINE')}
                            value={{
                                value: this.state.deadlineChargeValue, 
                                unit: this.state.deadlineChargeUnit
                            }}
                            unitTypeList={[
                                {code: 'percent', name: Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGE_UNITPERCENT')},
                                {code: 'nights', name: Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGE_UNITNIGHTS')}
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    deadlineChargeValue: value.value,
                                    deadlineChargeUnit: value.unit
                                });
                            }}
                        />

                        <DataInputFieldUnit title={Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGEAFTERRESERVATION')}
                            value={{
                                value: this.state.reservationChargeValue, 
                                unit: this.state.reservationChargeUnit
                            }}
                            unitTypeList={[
                                {code: 'percent', name: Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGE_UNITPERCENT')},
                                {code: 'nights', name: Culture.getText('PROPERTY_CANCELLATIONPOLICY_CHARGE_UNITNIGHTS')}
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    reservationChargeValue: value.value,
                                    reservationChargeUnit: value.unit
                                });
                            }}
                        />
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default CancellationPolicyInput;