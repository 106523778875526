/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

class HubSpotAnalytics {
    /**
     * Creates the tracking code for the HubSpot CRM
     * to track the users activity in the system
     */
    static initialize(){
        try{
            /* attach the hubspot tracking code to the system */
            const hubSpotScript = document.createElement('script');
            hubSpotScript.setAttribute('id','hs-script-loader');
            hubSpotScript.setAttribute('type','text/javascript');
            hubSpotScript.setAttribute('src','https://js.hs-scripts.com/9176402.js');
            document.head.appendChild(hubSpotScript);
        }catch(ex){
            console.log('Failed to attach CRM: ' + ex);
        }
    }

    /**
     * Identifies the contact by setting the contact
     * id from HubSpot in the tracking code
     * 
     * @param {string} contactId 
     * id of this user as a contact in HubSpot
     * 
     * @param {string} contactEmail
     * email address of this user in HubSpot
     */
    static identifyContact(contactId,contactEmail){
        try{
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(["identify",{ 
                email: contactEmail,
                id: contactId
            }]);
        }catch(ex){
            console.log('Failed CRM ident: ' + ex);
        }
    }

    /**
     * Manually tracks a page view for the navigation
     * through the different sections of the application
     * 
     * @param {string} pseudoUrl 
     * the pseudo url of the section
     */
    static trackSectionNavigation(pseudoUrl){
        try{
            let urlValue = pseudoUrl;
            if(urlValue.startsWith('/') === false){
                urlValue = '/' + urlValue;
            }

            let _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', urlValue]);
            _hsq.push(['trackPageView']);
        }catch(ex){
            console.log('Failed to log navigation: ' + ex);
        }
    }
}

export default HubSpotAnalytics;