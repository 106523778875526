/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/DataInputField.css';

class DataInputField extends React.Component {
    /**
     * Notifies the parent component about
     * the updated value
     * 
     * @param {object} e
     * the input control event object
     */
    changeValue(e){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(e.target.value);
        }
    }

    /**
     * Notifies the parent object about
     * the blur event and provides the value
     * 
     * @param {object} e
     * the input control event object
     */
    handleBlur(e){
        if(typeof this.props.onBlur === 'function'){
            this.props.onBlur(e.target.value);
        }
    }

    /**
     * Renders the component for the data input
     */
    render(){
        let classList = ['DataInputField'];
        let inputDisabled = false;
        if(this.props.disabled === true){
            classList.push('DataInputFieldDisabled');
            inputDisabled = true;
        }

        if(typeof this.props.className === 'string'
            && this.props.className !== ''){
            classList.push(this.props.className);
        }

        let fieldValue = "";
        if(this.props.value !== null || this.props.value !== undefined){
            fieldValue = this.props.value;
        }

        let placeholderText = "—";
        if(typeof this.props.placeholderText === 'string'){
            placeholderText = this.props.placeholderText;
        }

        let inputType = 'text';
        if(this.props.isPassword === true){
            inputType = 'password';
        }

        return(
            <div className={classList.join(' ')} 
                data-required={(this.props.required === true)}
                data-empty={(fieldValue === '')}>
                <div className="DataInputFieldTitle">{this.props.title}</div>
                {this.props.isLargeText !== true &&
                    <input className="DataInputFieldValue" type={inputType} value={fieldValue}
                        onChange={this.changeValue.bind(this)} disabled={inputDisabled}
                        onBlur={this.handleBlur.bind(this)}
                        placeholder={placeholderText} />
                }

                {this.props.isLargeText === true &&
                    <textarea className="DataInputFieldValue" type={inputType} value={fieldValue}
                        rows={this.props.rowCount || 3}
                        onChange={this.changeValue.bind(this)} disabled={inputDisabled}
                        onBlur={this.handleBlur.bind(this)}
                        placeholder={placeholderText} />
                }

                {typeof this.props.errorText === 'string' && this.props.errorText !== '' &&
                    <div className="DataInputFieldErrorText">
                        {this.props.errorText}
                    </div>
                }
            </div>
        );
    }
}

export default DataInputField;
