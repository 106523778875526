/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

/* require the stylesheet for this component */
import '../../style/DataInputArray.css';
import StandardButton from './StandardButton';

/**
 * Data input component that allows creating and
 * editing arrays with strings
 */
class DataInputArray extends React.Component {
    state = {
        addTextValue: ''
    };

    /**
     * Renders the component with the editing
     * input as well as the removal input
     */
    render(){
        return(
            <div className="DataInputArray">
                <div className="DataInputArrayTitle">
                    {this.props.title}
                </div>
                <div className="DataInputArrayList">
                    {this.renderList()}
                </div>
                <div className="DataInputArrayAdd">
                    <input className="DataInputArrayAddValue" type="text" 
                        value={this.state.addTextValue}
                        onChange={(e) => this.setState({addTextValue: e.target.value})}
                        placeholder={(this.props.addTextPlaceholder || '')} />
                    <StandardButton className="DataInputArrayAddButton"
                        text={Culture.getText('ADD_TEXT')}
                        disabled={(this.state.addTextValue === '' || (this.props.list || [])
                                    .includes(this.state.addTextValue) === true)}
                        onClick={(function(){
                            if(this.state.addTextValue !== ''){
                                let list = (this.props.list || []);
                                if(list.includes(this.state.addTextValue) === false){
                                    list.push(this.state.addTextValue.trim());
                                    if(typeof this.props.onChange === 'function'){
                                        this.props.onChange(list);
                                        this.setState({addTextValue: ''});
                                    }
                                }
                            }
                        }).bind(this)} />
                </div>
            </div>
        );
    }

    /**
     * Returns the rendered list 
     */
    renderList(){
        let result = [];

        let list = (this.props.list || []);
        for(let i=0; i<list.length; i++){
            result.push(
                <div key={i} className="DataInputArrayListItem">
                    <div className="DataInputArrayListItemValue">
                        {list[i]}
                    </div>
                    <div className="DataInputArrayListItemRemoveButton" onClick={(function(index){
                        let updatedList = [];
                        let list = (this.props.list || []);
                        for(let n=0; n<list.length; n++){
                            if(n !== index){ updatedList.push(list[n]); }
                        }

                        if(typeof this.props.onChange === 'function'){
                            this.props.onChange(updatedList);
                        }
                    }).bind(this,i)}></div>
                </div>
            );
        }

        if(result.length === 0){
            result.push(
                <div key="DataInputArrayListEmpty" className="DataInputArrayListEmpty">
                    {this.props.listEmptyText}
                </div>
            )
        }

        return result;
    }
}

export default DataInputArray;