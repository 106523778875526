/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../style/ConsoleApp.css';
import ApiClient from '../class/ApiClient';
import AccountView from './account/AccountView';
import CultureView from './account/CultureView';
import HeaderBar from './HeaderBar';
import InventoryView from './inventory/InventoryView';
import SideBar from './SideBar';
import Culture from '../class/Culture';
import ReservationView from './reservation/ReservationView';
import SettingsView from './settings/SettingsView';
import StoreFrontView from './storefront/StoreFrontView';
import SetupView from './account/SetupView';
import ConnectivityView from './connectivity/ConnectivityView';
import PaymentView from './payment/PaymentView';
import SupportView from './account/SupportView';
import ModalDrawerOverlay from './common/ModalDrawerOverlay';
import AccountInformationMessage from './account/AccountInformationMessage';

/* defines the threshold after which to show the 
    cancellation and deletion information message */
const CANCELLATION_TIME_THRESHOLD = 864000;

/**
 * Main component for the management console application
 * that controls all components and the flow for signed
 * in users
 */
class ConsoleApp extends React.Component {
    state = {
        activeView: 'inventory',
        showAccountView: false,
        showCultureView: false,
        networkError: null,
        authFailed: false,
        ignoreCancelMessage: false
    }

    /**
     * Attaches the global error and authentication
     * failure handlers to the api client
     */
    componentDidMount(){
        ApiClient.attachNetworkErrorListener((ex) => {
            this.setState({networkError: ex});
        });

        ApiClient.attachAuthFailListener(() => {
            this.setState({authFailed: true});
        });

        if(this.props.billingIssue === true
            && this.state.activeView !== 'settings'){
            this.setState({activeView: 'settings'});
        }
    }

    /**
     * Handle the component update to handle any
     * billing issue that might exist with the tenant
     */
    componentDidUpdate(){
        if(this.props.billingIssue === true
            && this.state.activeView !== 'settings'){
            this.setState({activeView: 'settings'});
        }
    }

    /**
     * Navigates the user to the view 
     * defined in the parameter
     * 
     * @param {string} viewName 
     * name of the view to navigate to
     */
    navigate(viewName){
        this.setState({activeView: viewName});
    }

    /**
     * Renders the display of the error
     * messages for authentication failures
     */
    renderAuthFailError(){
        let result = null;

        if(this.state.authFailed === true){
            result = (
                <ModalDrawerOverlay className="NetworkErrorWindow AuthFailWindow"
                    titleText={Culture.getText('AUTHENTICATION_FAILED_TITLE')}
                    subtitleText={Culture.getText('AUTHENTICATION_FAILED_SUBTITLE')}
                    introText={Culture.getText('AUTHENTICATION_FAILED_TEXT')}
                    submitButtonText={Culture.getText('AUTHENTICATION_FAILED_SIGNIN_BUTTON')}
                    onSubmit={() => {
                        /* notify the parent to initiate the local sign out */
                        if(typeof this.props.onSignOut === 'function'){
                            this.props.onSignOut();
                        }
                    }} />
            );
        }

        return result;        
    }

    /**
     * Renders the display of the error
     * for nettwork access failures
     */
    renderNetworkError(){
        let result = null;

        if(this.state.networkError !== null){
            let networkErrorCode = 'UNKNOWN';
            if(typeof this.state.networkError === 'object' && this.state.networkError !== null){
                networkErrorCode = this.state.networkError.toString();
            }

            result = (
                <ModalDrawerOverlay className="NetworkErrorWindow"
                    titleText={Culture.getText('NETWORK_ERROR_TITLE')}
                    subtitleText={Culture.getText('NETWORK_ERROR_SUBTITLE')}
                    introText={Culture.getText('NETWORK_ERROR_TEXT')}
                    submitButtonText={Culture.getText('NETWORK_ERROR_RELOAD_BUTTON')}
                    onSubmit={() => window.location.reload()}>
                    <div className="NetworkErrorDescription">
                        {networkErrorCode}
                    </div>
                </ModalDrawerOverlay>
            );
        }

        return result;
    }

    /**
     * Renders a cancalletion warning when the cancellation
     * is in effect within the defined timeframe (number of
     * days before cancellation is in effect)
     */
    renderCancellationWarning(){
        let result = null;

        if(this.props.subscriptionCancellationTime !== false && this.props.billingIssue === false){
            /* there's no billing issue (payment outstanding), but
                the account is already cancelled */
            let timeLeft = Math.floor(this.props.subscriptionCancellationTime-((new Date()).getTime()/1000));
            
            /* show the message when it was not previously ignored and is
                within the timeframe in which the information should be
                shown to the user */
            if(timeLeft < CANCELLATION_TIME_THRESHOLD 
                && this.state.ignoreCancelMessage === false){
                /* define the title for the warning message */
                let deleteTitle = Culture.getText('ACCOUNTINFORMATION_CANCELLED_TITLE_DAYS').replace('{number}',Math.floor(timeLeft/86400));
                if(timeLeft < 86400){
                    deleteTitle = Culture.getText('ACCOUNTINFORMATION_CANCELLED_TITLE_HOURS').replace('{number}',Math.floor(timeLeft/3600));
                }if(timeLeft < 3600){
                    deleteTitle = Culture.getText('ACCOUNTINFORMATION_CANCELLED_TITLE_MINUTES').replace('{number}',Math.floor(timeLeft/60));
                }

                result = (
                    <AccountInformationMessage title={deleteTitle}
                        subtitle={Culture.getText('ACCOUNTINFORMATION_CANCELLED_SUBTITLE')}
                        confirmText={Culture.getText('ACCOUNTINFORMATION_CANCELLED_CONFIRMTEXT')}
                        dismissText={Culture.getText('ACCOUNTINFORMATION_CANCELLED_DISMISSTEXT')}
                        onDismiss={() => this.setState({ignoreCancelMessage: true})}
                        onConfirm={(function(){
                            if(typeof this.props.onSubscriptionReactivate === 'function'){
                                this.props.onSubscriptionReactivate();
                            }

                            this.setState({ignoreCancelMessage: true});
                        }).bind(this)}>
                        <div className="AccountInformationMessageDeleteText">
                            {Culture.getText('ACCOUNTINFORMATION_CANCELLED_INTROTEXT')}
                        </div>
                        <div className="AccountInformationMessageDeleteDate">
                            <div className="AccountInformationMessageDeleteDateTitle">
                                {Culture.getText('ACCOUNTINFORMATION_CANCELLED_DATETITLE')}
                            </div>
                            <div className="AccountInformationMessageDeleteDateValue">
                                {(new Date(this.props.subscriptionCancellationTime*1000).toLocaleString(Culture.getCultureCode(),{
                                    weekday: 'long', 
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    timeZoneName: 'short'
                                }))}
                            </div>
                        </div>
                        <div className="AccountInformationMessageDeleteText">
                            {Culture.getText('ACCOUNTINFORMATION_CANCELLED_SUBTEXT')}
                        </div>
                    </AccountInformationMessage>
                );
            }
        }

        return result;
    }

    /**
     * Renders the console application
     * user interface with all required
     * components
     */
    render(){
        /**
         * Defines all available views for navigation
         * and renders the view based on the view set 
         * as the navigation target
         */
        const viewList = {
            'inventory': <InventoryView onShowView={(value) => this.setState({activeView: value})} />,
            'storefront': <StoreFrontView />,
            'booking': <ReservationView />,
            'payment': <PaymentView />,
            'connectivity': <ConnectivityView />,
            'support': <SupportView />,
            'settings': <SettingsView onSignOut={this.props.onSignOut} 
                            billingIssue={this.props.billingIssue} />
        }

        /* define the main view component for the current
            navigation state or view to render as content */
        let viewComponent = <div></div>;
        if(viewList.hasOwnProperty(this.state.activeView)){
            viewComponent = viewList[this.state.activeView];
        }

        /* check the global states if any failures occured */
        if(this.state.networkError !== null){ viewComponent = this.renderNetworkError(); }
        if(this.state.authFailed === true){ viewComponent = this.renderAuthFailError(); }

        /* check if this tenant requires initial setup
            and how the setup view to configure the tenant
            account and the inventory for the first time */
        let setupRequired = false;
        let tenantConfig = this.props.user.getTenantConfig();
        if(typeof tenantConfig === 'object' && tenantConfig !== null){
            if(tenantConfig.setup === false){
                setupRequired = true;
            }
        }

        return (
            <div className={['Main',('Main_' + this.state.activeView)].join(' ')}>
                {this.state.showAccountView === true &&
                    <ModalDrawerOverlay className="AccountViewWindow"
                        onClose={() => this.setState({showAccountView: false})}
                        titleText={this.props.user.user.name}
                        subtitleText={Culture.getText('ROLE_'+this.props.user.getUserRole().toUpperCase())}
                        introText={Culture.getText('ACCOUNT_VIEW_INTROTEXT')}
                        submitButtonText={Culture.getText('SIGNOUT')}
                        onSubmit={() => this.props.onSignOut()}>
                        <AccountView user={this.props.user} onTenantChanged={() => {
                                /* this is some kind of ugly hack to force
                                    the inventory component to properly reload
                                    when the tenant is changed */
                                this.setState({
                                    showAccountView: false, 
                                    activeView: '',
                                },(function(){
                                    this.setState({activeView: 'inventory'});
                                }).bind(this));

                                /* call the parent if it needs an info about it */
                                if(typeof this.props.onTenantChange === 'function'){
                                    this.props.onTenantChange();
                                }
                            }} />
                    </ModalDrawerOverlay>
                }
                {this.state.showCultureView === true &&
                    <ModalDrawerOverlay 
                        titleText={Culture.getText('SELECT_CULTURE_TITLE')}
                        subtitleText={Culture.getText('SELECT_CULTURE_SUBTITLE')}
                        introText={Culture.getText('SELECT_CULTURE_INTROTEXT')}
                        onClose={() => this.setState({showCultureView: false})}>
                        <CultureView onCultureChange={(code) => {
                            if(typeof this.props.onCultureChange === 'function'){
                                this.props.onCultureChange(code);
                                this.setState({showCultureView: false});
                            }
                        }} />
                    </ModalDrawerOverlay>
                }

                <SideBar user={this.props.user} 
                    activeItem={this.state.activeView} 
                    disabled={(setupRequired === true || this.props.billingIssue === true)}
                    onNavigate={(targetName) => this.navigate(targetName)}
                    onShowAccountView={() => this.setState({showAccountView: true})} />

                <div className="MainContent">
                    <HeaderBar user={this.props.user} onSignOut={() => this.props.onSignOut()} 
                            onShowAccountView={() => this.setState({showAccountView: true})}
                            onShowCultureView={() => this.setState({showCultureView: true})} />
                            
                    {setupRequired === true &&
                        <SetupView onCompleted={() => this.props.onSignOut()} />
                    }
                    {setupRequired === false &&
                        <div className="MainContentView">{viewComponent}</div>
                    }
                </div>

                {this.renderCancellationWarning()}
            </div>
        );
    }
}

export default ConsoleApp;