/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the api client to fetch the article info */
import ApiClient from '../../class/ApiClient';
import Culture from '../../class/Culture';

/* require the stylesheet for this view */
import '../../style/HelpArticleView.css';

/* import the help articles and their urls */
const HelpArticleList = require('../../config/HelpArticleList.json');

/**
 * Renders a help article after fetching the
 * contents of the help article from the api
 */
class HelpArticleView extends React.Component {
    state = {
        article: null,
        articleKey: null
    };

    /**
     * Initially fetches the help article
     * and updates the information in the
     * state of this component
     */
    componentDidMount(){
        this.fetchArticle();
    }

    /**
     * Fetch a new article when the article
     * of this component changes
     */
    componentDidUpdate(){
        if(this.state.articleKey !== this.props.article){
            this.fetchArticle();
        }
    }

    /**
     * Fetches the article information from
     * the knowledge base and stores it
     */
    async fetchArticle(){
        if(typeof this.props.article === 'string' && this.props.article !== ''){
            let articleItem = this.props.article;
            if(typeof HelpArticleList[articleItem] === 'string'){
                let articleUrl = HelpArticleList[this.props.article];
                let response = await ApiClient.execute('/account/getHelpArticle',{
                    url: articleUrl
                });
    
                /* check if the article fetched is still the one defined in the props */
                if(typeof response === 'object' && response !== null){
                    if(response.failed !== true && articleItem === this.props.article){
                        this.setState({
                            article: response.article,
                            articleKey: articleItem
                        });
                    }
                }
            }
        }
    }

    /**
     * Renders the help article when fetched
     * or returns nothing when no article is
     * available for the article URL defined
     * in the props of this component
     */
    render(){
        let result = null;

        if(this.state.article !== null){
            let article = this.state.article;
            result = (
                <div className="HelpArticleView" onClick={() => {
                    window.open(article.url);
                }}>
                    <div className="HelpArticleViewTitle" 
                        dangerouslySetInnerHTML={{__html: article.title}}>
                    </div>
                    {typeof article.subtitle === 'string' && article.subtitle !== '' &&
                        <div className="HelpArticleViewSubtitle" 
                            dangerouslySetInnerHTML={{__html: article.subtitle}}>
                        </div>
                    }
                    <div className="HelpArticleViewLink">
                        {Culture.getText('HELPVIEW_OPENKNOWLEDGEBASE')}
                    </div>
                </div>
            );
        }

        return result;
    }
}

export default HelpArticleView;