/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/**
 * Standard button to be used in the application
 */
class StandardButton extends React.Component {
    /**
     * Handles the click on this button, checks
     * if there is a listener in the props and
     * calls that listener
     */
    handleClick(){
        if(this.props.disabled !== true){
            if(typeof this.props.onClick === 'function'){
                this.props.onClick();
            }
        }
    }

    /**
     * Renders the standard button interface
     * with the data provided in the props
     */
    render(){
        let classList = ['StandardButton'];
        if(typeof this.props.className === 'string'){
            classList.push(this.props.className);
        }

        return(
            <div className={classList.join(' ')} 
                data-disabled={this.props.disabled || false}
                onClick={this.handleClick.bind(this)}>
                {this.props.text}
            </div>
        );
    }
}

export default StandardButton;