/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import ManagementUser from '../../class/ManagementUser';

import '../../style/RestaurantEditor.css';
import StandardButton from '../common/StandardButton';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';
import ModalOptionListSelect from '../common/ModalOptionListSelect';
import OperationTimeInput from '../common/OperationTimeInput';
import MessageListEditor from '../inventory/MessageListEditor';

/**
 * This component allows to manage a list of restaurants,
 * edit the restaurants, delete or create them
 */
class RestaurantEditor extends React.Component {
    state = {
        editIndex: null,
        showCreateWindow: false,
        restaurantName: "",
        restaurantNameErrorText: "",
        restaurantMessageList: {},
        restaurantOffersBreakfast: false,
        restaurantOffersBrunch: false,
        restaurantOffersLunch: false,
        restaurantOffersDinner: false,
        restaurantCuisineCodeList: [],
        restaurantFeatureList: [],
        restaurantAmbienceList: [],
        restaurantDietaryOptionList: [],
        restaurantOperationTime: []
    }

    /**
     * Adds or updates a restaurant depending
     * on the state and adds it to the list, 
     * notifying the parent component afterwards
     */
    addRestaurant(){
        /* ... */
        if(this.state.restaurantName.length > 0){
            /* push the new restaurant to the list */
            let restaurantList = this.props.list;

            /* create the restaurant object to upsertion */
            let restaurantObject = {
                name: this.state.restaurantName,
                breakfast: this.state.restaurantOffersBreakfast,
                brunch: this.state.restaurantOffersBrunch,
                lunch: this.state.restaurantOffersLunch,
                dinner: this.state.restaurantOffersDinner,
                cuisineList: this.state.restaurantCuisineCodeList,
                operationTimeList: this.state.restaurantOperationTime,
                featureList: this.state.restaurantFeatureList,
                ambianceList: this.state.restaurantAmbienceList,
                dietaryOptionList: this.state.restaurantDietaryOptionList,
                messageList: this.state.restaurantMessageList
            };

            if(this.state.editIndex === null){
                /* add this object as a new restaurant */
                restaurantList.push(restaurantObject);
            }else{
                /* update the existing restaurant */
                for(let r=0; r<restaurantList.length; r++){
                    if(r === this.state.editIndex){
                        restaurantList[r] = restaurantObject;
                    }
                }
            }

            /* notify the parent component */
            if(typeof this.props.onChange === 'function'){
                this.props.onChange(restaurantList);
            }
            
            /* reset the state values */
            this.setState({
                showCreateWindow: false,
                restaurantName: "",
                restaurantNameErrorText: "",
                restaurantOffersBreakfast: false,
                restaurantOffersBrunch: false,
                restaurantOffersLunch: false,
                restaurantOffersDinner: false,
                restaurantCuisineCodeList: [],
                restaurantFeatureList: [],
                restaurantAmbienceList: [],
                restaurantDietaryOptionList: [],
                restaurantOperationTime: [],
                messageList: {}
            });
        }else{
            /* show an error as the restaurant name is required */
            this.setState({
                restaurantNameErrorText: "PROPERTY_SERVICE_RESTAURANTNAMEREQUIRED"
            });
        }
    }

    /**
     * Removes the restaurant at the defined
     * index from the list of restaurants and
     * notifies the parent component afterwards 
     * 
     * @param {number} index
     * index of the restaurant to remove from list 
     */
    removeRestaurant(index){
        let updatedList = [];
        for(let i=0; i<this.props.list.length; i++){
            if(i !== index){
                updatedList.push(this.props.list[i]);
            }
        }

        /* notify the parent component */
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(updatedList);
        }
    }

    /**
     * Defines the index of the restaurant to edit,
     * prepares the create window for edit mode and
     * resets all state values
     * 
     * @param {number} index
     * index of the restaurant to show editor for 
     */
    editRestaurant(index){
        for(let i=0; i<this.props.list.length; i++){
            if(i === index){
                /* update the state with this item */
                let item = this.props.list[i];
                this.setState({
                    editIndex: index,
                    showCreateWindow: true,
                    restaurantName: item.name,
                    restaurantNameErrorText: "",
                    restaurantOffersBreakfast: item.breakfast,
                    restaurantOffersBrunch: item.brunch,
                    restaurantOffersLunch: item.lunch,
                    restaurantOffersDinner: item.dinner,
                    restaurantCuisineCodeList: item.cuisineList,
                    restaurantFeatureList: item.featureList,
                    restaurantAmbienceList: item.ambianceList,
                    restaurantDietaryOptionList: item.dietaryOptionList,
                    restaurantOperationTime: item.operationTimeList,
                    restaurantMessageList: item.messageList                   
                })
            }
        }
    }

    /**
     * Renders the list of available restaurants
     * for preview with edit and remove buttons
     */
    renderList(){
        let list = [];
        if(Array.isArray(this.props.list)){
            list = this.props.list;
        }

        let componentList = [];
        let r = 0;
        list.forEach((restaurant) => {
            let classList = ["RestaurantItem"];
            if(Array.isArray(restaurant.cuisineList)){
                restaurant.cuisineList.forEach((cuisineCode) => {
                    classList.push("RestaurantItem_"+cuisineCode);
                });
            }

            componentList.push(
                <div key={"restaurant"+r} className={classList.join(' ')}>
                    <div className="RestaurantItemName" onClick={this.editRestaurant.bind(this,r)}>
                        <span className="RestaurantItemNameText">{restaurant.name}</span>
                    </div>
                    <div className="RestaurantItemDelete" onClick={this.removeRestaurant.bind(this,r)}></div>
                </div>
            );
            r++
        });

        if(list.length === 0){
            componentList.push(
                <div key="restaurantempty" className="RestaurantListEmpty">
                    {Culture.getText('PROPERTY_SERVICE_RESTAURANTLISTEMPTY')}
                </div>
            );
        }

        return componentList;
    }

    /**
     * Returns the option list with all available
     * cuisine type information and their localised name
     */
    getCuisineTypeOptionList(){
        let result = [];

        let cuisineCodeList = require('../../config/ota/OTAMainCuisineCode.json');
        Object.keys(cuisineCodeList).forEach((cuisineCode) => {
            result.push({
                value: cuisineCode,
                text: Culture.getText('RESTAURANT_CUISINENAME_'+cuisineCode),
                checked: this.state.restaurantCuisineCodeList.includes(cuisineCode)
            });
        });

        result.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

        return result;
    }

    /**
     * Returns the option list of all available
     * restaurant features and the localised
     * name of each feature
     */
    getFeatureOptionList(){
        let featureList = ["a la carte", "buffet", "guests only", 
                    "accepts reservations", "outdoor seating"];

        let result = [];
        featureList.forEach((featureText) => {
            let localisedText = Culture.getText('RESTAURANT_FEATURENAME_' 
                        + featureText.replace(/ /gi,'_').toUpperCase());

            result.push({
                value: featureText,
                text: localisedText,
                checked: this.state.restaurantFeatureList.includes(featureText)
            });
        });

        return result;
    }

    /**
     * Returns the option list with all
     * available restaurant ambiances and
     * their localised name
     */
    getAmbianceOptionList(){
        let ambianceList = ["family/kids friendly", "modern", "romantic", "traditional"];

        let result = [];
        ambianceList.forEach((ambianceText) => {
            let localisedText = Culture.getText('RESTAURANT_AMBIANCENAME_' 
                        + ambianceText.replace(/[ /]/gi,'_').toUpperCase());

            result.push({
                value: ambianceText,
                text: localisedText,
                checked: this.state.restaurantAmbienceList.includes(ambianceText)
            });
        });

        return result;
    }

    /**
     * Returns the option list with all available
     * dietary options and their localised name
     */
    getDietaryOptionList(){
        let dietList = ["dairy free", "gluten free", "halal", "kosher", "vegan", "vegetarian"];

        let result = [];
        dietList.forEach((dietText) => {
            let localisedText = Culture.getText('RESTAURANT_DIETNAME_' 
                        + dietText.replace(/[ /]/gi,'_').toUpperCase());

            result.push({
                value: dietText,
                text: localisedText,
                checked: this.state.restaurantDietaryOptionList.includes(dietText)
            });
        });

        return result;
    }

    /**
     * Toggles a list item of a list such as
     * the dietary options in the state
     * 
     * @param {string} listName
     * name of the list property in the state
     *  
     * @param {string} item
     * name of the item to toggle in the list 
     */
    toggleListItem(listName,item){
        /* toggle the item in the state's code list */
        let updatedList = [];
        if(this.state[listName].includes(item.value)){
            this.state[listName].forEach((code) => {
                if(item.value !== code){
                    updatedList.push(code);
                }
            });
        }else{
            /* add the new code item to the list */
            updatedList = this.state[listName];
            updatedList.push(item.value);
        }

        /* update the cuisine code list in the state */
        this.setState({[listName]: updatedList});
    }

    /**
     * Renders this component with the restaurant
     * list and the editor to edit or create a new
     * restaurant for this property
     */
    render(){
        let buttonText = Culture.getText('PROPERTY_SERVICE_ADDRESTAURANT');
        let windowTitle = Culture.getText('PROPERTY_SERVICE_ADDRESTAURANT_TITLE');
        let windowSubtitle = Culture.getText('PROPERTY_SERVICE_ADDRESTAURANT_SUBTITLE');
        if(typeof this.state.editIndex === 'number'){
            buttonText = Culture.getText('PROPERTY_SERVICE_UPDATERESTAURANT');
            windowTitle = Culture.getText('PROPERTY_SERVICE_UPDATERESTAURANT_TITLE');
            windowSubtitle = Culture.getText('PROPERTY_SERVICE_UPDATERESTAURANT_SUBTITLE');
        }

        return(
            <div className="RestaurantEditor">
                <div className="RestaurantEditorList">
                    {this.renderList()}
                </div>

                <StandardButton className="RestaurantEditorAddRestaurant"
                    text={Culture.getText('PROPERTY_SERVICE_ADDRESTAURANT')} 
                    onClick={() => this.setState({
                        editIndex: null,
                        showCreateWindow: true,
                        restaurantName: "",
                        restaurantNameErrorText: "",
                        restaurantOffersBreakfast: false,
                        restaurantOffersBrunch: false,
                        restaurantOffersLunch: false,
                        restaurantOffersDinner: false,
                        restaurantCuisineCodeList: [],
                        restaurantFeatureList: [],
                        restaurantAmbienceList: [],
                        restaurantDietaryOptionList: [],
                        restaurantOperationTime: [],
                        restaurantMessageList: []
                    })} />

                {this.state.showCreateWindow === true &&
                    <ModalDrawerOverlay 
                        onClose={() => this.setState({showCreateWindow: false})}
                        titleText={windowTitle}
                        subtitleText={windowSubtitle}
                        submitButtonText={buttonText}
                        onSubmit={this.addRestaurant.bind(this)}>
                        <DataInputField title={Culture.getText('PROPERTY_RESTAURANT_NAME')} 
                            value={this.state.restaurantName} 
                            errorText={Culture.getText(this.state.restaurantNameErrorText)}
                            onChange={(value) => this.setState({restaurantName: value})} />

                        <MessageListEditor title={Culture.getText('PROPERTY_RESTAURANT_DESCRIPTION')} 
                            languageList={ManagementUser.getCurrent().getTenantLanguageList()} 
                            content={this.state.restaurantMessageList}
                            onChange={(value) => {
                                /* update the message list of the restaurant */
                                this.setState({restaurantMessageList: value});
                            }}
                        />

                        <OperationTimeInput list={this.state.restaurantOperationTime}
                            title={Culture.getText('PROPERTY_RESTAURANT_OPERATIONTIME')}
                            onChange={(value) => this.setState({restaurantOperationTime: value})} />
                    
                        <DataInputCheckBox title={Culture.getText('PROPERTY_RESTAURANT_OFFERSBREAKFAST')} 
                            checked={this.state.restaurantOffersBreakfast} onClick={() => {
                                this.setState({restaurantOffersBreakfast: !this.state.restaurantOffersBreakfast});
                        }} />

                        <DataInputCheckBox title={Culture.getText('PROPERTY_RESTAURANT_OFFERSBRUNCH')} 
                            checked={this.state.restaurantOffersBrunch} onClick={() => {
                                this.setState({restaurantOffersBrunch: !this.state.restaurantOffersBrunch});
                        }} />

                        <DataInputCheckBox title={Culture.getText('PROPERTY_RESTAURANT_OFFERSLUNCH')} 
                            checked={this.state.restaurantOffersLunch} onClick={() => {
                                this.setState({restaurantOffersLunch: !this.state.restaurantOffersLunch});
                        }} />

                        <DataInputCheckBox title={Culture.getText('PROPERTY_RESTAURANT_OFFERSDINNER')} 
                            checked={this.state.restaurantOffersDinner} onClick={() => {
                                this.setState({restaurantOffersDinner: !this.state.restaurantOffersDinner});
                        }} />

                        <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_RESTAURANT_ADDCUISINE')} 
                            filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                            title={Culture.getText('PROPERTY_RESTAURANT_CUISINETYPE')}
                            list={this.getCuisineTypeOptionList()} 
                            onToggle={this.toggleListItem.bind(this,'restaurantCuisineCodeList')}
                            emptyText={Culture.getText('PROPERTY_RESTAURANT_ADDCUISINE_EMPTY')}
                            transparentBackground={true} />

                        <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_RESTAURANT_ADDFEATURE')} 
                            filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                            title={Culture.getText('PROPERTY_RESTAURANT_FEATURETYPE')}
                            list={this.getFeatureOptionList()}
                            emptyText={Culture.getText('PROPERTY_RESTAURANT_ADDFEATURE')} 
                            onToggle={this.toggleListItem.bind(this,'restaurantFeatureList')}
                            transparentBackground={true} />

                        <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_RESTAURANT_ADDDIET')} 
                            filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                            title={Culture.getText('PROPERTY_RESTAURANT_DIETTYPE')}
                            list={this.getDietaryOptionList()}
                            emptyText={Culture.getText('PROPERTY_RESTAURANT_ADDDIET_EMPTY')} 
                            onToggle={this.toggleListItem.bind(this,'restaurantDietaryOptionList')}
                            transparentBackground={true} />

                        <ModalOptionListSelect addButtonText={Culture.getText('PROPERTY_RESTAURANT_ADDAMBIANCE')} 
                            filterPlaceholderText={Culture.getText('FILTER_SEARCH_TITLE')}
                            title={Culture.getText('PROPERTY_RESTAURANT_AMBIANCE')}
                            list={this.getAmbianceOptionList()}
                            emptyText={Culture.getText('PROPERTY_RESTAURANT_ADDAMBIANCE')} 
                            onToggle={this.toggleListItem.bind(this,'restaurantAmbienceList')}
                            transparentBackground={true} />
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default RestaurantEditor;