/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import ManagementUser from '../../class/ManagementUser';
import Culture from '../../class/Culture';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import StandardButton from '../common/StandardButton';

/* define the constants for the apaleo credentials */
const APALEO_AUTH_CLIENT_ID = 'ZPSD-AC-LODGEA';

/* the scope required to be requested for the integration to have
    sufficient privileges to access the desired information */
const APALEO_SCOPELIST = [
    'profile',
    'openid',
    'offline_access',
    'availability.read',
    'rates.read',
    'reservations.read',
    'reservations.manage',
    'reservations.force-manage',
    'setup.read',
    'folios.manage',
    'folios.read'
];

/**
 * The Apaleo editor allows performing auth with the Apaleo system
 * and once successful creates the configuration for Apaleo where
 * the server-side will finalise the connection
 */
class ApaleoConnectionEditor extends GenericProviderConnectionEditor {
    /* state handling the current state
        of config creation to avoid multiple
        calls to the config creation */
    state = {
        configCreate: false
    }

    /**
     * Mounts the component and adds the listener
     * for the window event with the authentication
     * information attached
     */
    componentDidMount() {
        /* bind the event listener to the message event */
        window.addEventListener('message', this.handleAuthEvent.bind(this), false);

        /* ensure the window URL is clean before doing auth */
        window.history.pushState(null, null, '/');
    }

    /**
     * Handles the authentication event and processes
     * the result of the event based on the message
     * received from the callback
     * 
     * @param {object} event 
     * the event object received from the callback
     */
    handleAuthEvent(event) {
        try {
            if (this.state.configCreate === false) {
                let messageString = String(event.data);
                if (messageString.indexOf('connectivitiy_provider') >= 0) {
                    const message = JSON.parse(event.data);
                    if (message.connectivitiy_provider === 'apaleo') {
                        /* close the previously opened window */
                        this.windowHandle.close();

                        /* set the state to creating */
                        this.setState({ configCreate: true });

                        /* the auth came back with a response */
                        if (typeof message.code === 'string' && message.code !== '') {
                            /* auth was successful and return the correct code */
                            if (typeof this.props.onChange === 'function') {
                                this.props.onChange({
                                    tenantCode: ManagementUser.getCurrent().getActiveTenant(),
                                    providerCode: 'apaleo',
                                    code: message.code,
                                    requireSetup: true,
                                    requireDisconnect: true,
                                    redirectUrl: this.getCallbackUrl()
                                });
                            }
                        } else {
                            /* auth failed, just close the config */
                            if (typeof this.props.onClose === 'function') {
                                this.props.onClose();
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            console.log('Failed to handle auth event: ' + ex);
        }
    }

    /**
     * Returns the remote url for the authentication
     * with the apaleo system to be used in the window
     */
    getRemoteAuthUrl() {
        return 'https://identity.apaleo.com/connect/authorize?response_type=code'
            + '&scope=' + encodeURIComponent(APALEO_SCOPELIST.join(' '))
            + '&client_id=' + APALEO_AUTH_CLIENT_ID
            + '&redirect_uri=' + encodeURIComponent(this.getCallbackUrl())
            + '&state=' + [ManagementUser.getCurrent().activeTenant, (new Date()).getTime()].join('-');
    }

    /**
     * Creates the target url and returns it
     * as a string to be used for the callback
     */
    getCallbackUrl() {
        let targetEnv = 'eu';
        let targetHost = 'https://' + window.location.hostname;
        if (window.location.hostname === 'localhost') {
            targetEnv = 'dev';
            targetHost = 'http://localhost:3000';
        } else {
            let matchList = (/console\.([a-z]{2,3})\./gmi).exec(window.location.hostname);
            if (Array.isArray(matchList)) {
                if (matchList.length === 2) {
                    targetEnv = matchList[1];
                }
            }
        }

        /* create the complete target url and return it */
        return targetHost + '/callback.html?connectivitiy_provider=' + ['1', targetEnv, 'apaleo'].join(':');
    }

    /**
     * Renders the iframe or the connection information
     * and also handles the events of the iframe
     */
    render() {
        return (
            <>
                {this.props.editMode === false &&
                    <>
                        <div className="ApaleoConnectionEditorText">
                            {Culture.getText('CONNECTIVITY_APALEO_CONNECT_INTRO')}
                        </div>

                        <div>
                            <StandardButton className="ApaleoConnectionEdtiorConnectButton"
                                text={Culture.getText('CONNECTIVITY_APALEO_CONNECTACCOUNT')}
                                onClick={() => {
                                    /* define the position of the window */
                                    const width = 800;
                                    const height = 650;
                                    const leftPos = window.screen.width / 2 - width / 2;
                                    const topPos = window.screen.height / 2 - height / 2;

                                    /* open the window with the connection */
                                    const url = this.getRemoteAuthUrl();


                                    console.log(url);


                                    this.windowHandle = window.open(url, 'apaleo_window', 'toolbar=no,location=no,status=no,'
                                        + 'menubar=no,scrollbars=yes,resizable=yes,width=' + width
                                        + ',height=' + height + ',left=' + leftPos + ',top=' + topPos);

                                    /* add the wait attribute to the container */
                                    let containerList = document.getElementsByClassName('ConnectionEditorContainer');
                                    for (let container of containerList) {
                                        container.setAttribute('data-waiting', 'true');
                                    }

                                    /* handle the closing of the window */
                                    this.closeInterval = setInterval((function () {
                                        if (this.windowHandle.closed) {
                                            clearInterval(this.closeInterval);
                                            if (typeof this.props.onClose === 'function') {
                                                this.props.onClose();
                                            }
                                        }
                                    }).bind(this), 1000);
                                }} />
                        </div>
                    </>
                }

                <div className="ApaleoConnectionEditorWaitMessage">
                    {Culture.getText('CONNECTIVITY_APALEO_CONNECT_WAITMESSAGE')}
                </div>
            </>
        );
    }
}

export default ApaleoConnectionEditor;