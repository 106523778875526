/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import ApiClient from '../class/ApiClient';
import ManagementUser from '../class/ManagementUser';
import Authentication from '../class/Authentication';
import Culture from '../class/Culture';

import LoadingIndicator from './common/LoadingIndicator';

import '../style/SignInView.css';

/* list of available regions to sign in */
const SystemRegionConfig = require('../config/SystemRegionConfig.json');

/**
 * This component handles the sign in process
 * with the authentication providers
 */
class SignInView extends React.Component {
    state = {
        inProgress: false,
        failed: false,
        unassigned: false,
        networkFailure: false,
        failedUserObject: null
    }

    /**
     * Loads the Google Identity library
     * when this component is mounted
     */
    componentDidMount(){
        let googleSignInScript = document.createElement('script');
        googleSignInScript.src = 'https://accounts.google.com/gsi/client';
        document.head.appendChild(googleSignInScript);
        this.renderGoogleButton();
    }

    /**
     * Re-render the Google button when
     * the component updated and thus
     * reset the created button
     */
    componentDidUpdate(){
        this.renderGoogleButton();
    }

    /**
     * Renders the Google Button when the
     * component was initially rendered or
     * updated at a later stage
     */
    renderGoogleButton(){
        if(window.google === undefined){
            setTimeout(this.renderGoogleButton.bind(this),500);
        }else{
            if(document.getElementById('SignInButtonGoogle') !== null){
                /* initialise the google identity sdk */
                window.google.accounts.id.initialize({
                    client_id: Authentication.getGoogleClientId(),
                    callback: this.signInWithGoogle.bind(this)
                });

                /* render the sign in with google button */
                window.google.accounts.id.renderButton(
                    document.getElementById('SignInButtonGoogle'),
                    {
                        theme: 'filled_blue', 
                        size: 'large',
                        locale: Culture.getCultureCode(),
                        text: 'continue_with',
                        shape: 'rectangular',
                        logo_alignment: 'left'
                    }
                );

                /* show the prompt for google signin */
                window.google.accounts.id.prompt();
            }
        }
    }

    /**
     * Returns the setup credentials if
     * any are present or returns false
     * when no credentials for the initial
     * setup are present
     */
    getSetupCredentials() {
        let result = false;

        const urlParam = new URLSearchParams(window.location.search);
        if (urlParam.get('tenantCode') !== null && urlParam.get('setupKey') !== null) {
            result = {
                tenantCode: urlParam.get('tenantCode'),
                setupKey: urlParam.get('setupKey')
            };
        }

        return result;
    }

    /**
     * Returns the invitation code from
     * the query string or false if no
     * invitation code is present
     */
    getInvitationCode() {
        let result = false;

        const urlParam = new URLSearchParams(window.location.search);
        let invitationCode = urlParam.get('invitationCode');
        if (typeof invitationCode === 'string' && invitationCode !== '') {
            result = invitationCode;
        }

        return result;
    }

    /**
     * Returns the authentication options
     * set in the querystring or elsewhere
     * to be used for authentication with
     * the api
     */
    getAuthOption() {
        let result = {};

        /* try to authenticate with the microsoft credentials */
        let setupCredentials = this.getSetupCredentials();
        if (setupCredentials !== false) {
            /* setup credentials are present, execute auth with 
                setup credentials attached to the auth request */
            result.setupTenantCode = setupCredentials.tenantCode;
            result.setupKey = setupCredentials.setupKey;
        }

        /* attach the invitation code if any is present */
        let invitationCode = this.getInvitationCode();
        if (invitationCode !== false) {
            result.invitationCode = invitationCode;
        }

        return result;
    }

    /**
     * Provides authentication with the
     * microsoft sign in provider
     */
    async signInWithMicrosoft() {
        this.setState({ inProgress: true, failed: false },(async function(){
            console.log('in progess: ' + this.state.inProgress);

            let microsoftToken = await Authentication.authMicrosoft();
            if (microsoftToken.length > 0) {
                let userObject = null;
                let isNetworkError = false;
    
                try {
                    /* execute the authentication with the options */
                    userObject = await ApiClient.authenticateUser('microsoft', microsoftToken, this.getAuthOption());
                } catch (ex) {
                    /* set the network failure indicator */
                    isNetworkError = true;
                    userObject = null;
                }
    
                /* handle the microsoft user auth response */
                this.handleAuthResponse(userObject, isNetworkError);
            } else {
                /* reset the status and set failed to true */
                this.setState({ inProgress: false, failed: true, networkFailure: false });
            }
        }).bind(this));
    }

    /**
     * Performs authentication with the google
     * authenticatioon provider handling the
     * result from the Google Sign-In button
     * 
     * @param {object} response 
     * response provided by the google button
     */
    async signInWithGoogle(response) {
        this.setState({ inProgress: true, failed: false });

        /* extract the token id required to pass to the api */
        let tokenId = response.credential;

        /* instanciate the user object */
        let userObject = null;
        let isNetworkError = false;

        try {
            /* execute the authentication with the options */
            userObject = await ApiClient.authenticateUser('google', tokenId, this.getAuthOption());
        } catch (ex) {
            /* set the network failure indicator */
            isNetworkError = true;
            userObject = null;
        }

        /* handle the google user auth response */
        this.handleAuthResponse(userObject, isNetworkError);
    }

    /**
     * Handles the auth response from the api
     * and notifies the parent component about
     * the success or shows and error message
     * 
     * @param {object} userObject 
     * the user object received from the api
     * 
     * @param {boolean} isNetworkError 
     * true, if auth failed with a network error
     */
    handleAuthResponse(userObject, isNetworkError) {
        if (userObject !== null && userObject.failed !== true) {
            /* ensure the window URL is clean before continuing */
            window.history.pushState(null, null, '/');

            /* pass the user object to the parent component */
            this.props.onLoggedIn(userObject);
        } else {
            if (userObject.failed === true && userObject.unassigned === true
                && typeof userObject.userObject === 'object'
                && userObject.userObject !== null) {
                /* this is a valid user, but this user is not assigned to
                    any existing tenant, show a message on how to get assigned
                    to an active tenant */
                this.setState({
                    failed: true,
                    unassigned: true,
                    failedUserObject: userObject.userObject,
                    networkFailure: isNetworkError,
                    inProgress: false
                });
            } else {
                /* reset the status, show failure message */
                this.setState({ inProgress: false, failed: true, networkFailure: isNetworkError });
            }
        }
    }

    /**
     * Calls the parent component to initiate a
     * change of the current culture if the handler
     * was provided to this component
     * 
     * @param {string} code
     * the new culture code set for the session 
     */
    changeCulture(code) {
        if (typeof this.props.onCultureChange === 'function') {
            this.props.onCultureChange(code);
            document.getElementById('SignInButtonGoogle').setAttribute('data-enabled','false');
        }
    }

    /**
     * Select the region provided in the param
     * and direct the user to the console of
     * that region if the user is not currently
     * in that region
     * 
     * @param {string} regionCode
     * the region code as defined in the region config 
     */
    selectRegion(regionCode) {
        /* only send the user to the console url
            if it is not the current region */
        if (ManagementUser.getSystemRegionCode() !== regionCode) {
            /* send the user to the desired console */
            window.location.href = ('https://' + SystemRegionConfig[regionCode].consoleUrl);
        }
    }

    /**
     * Renders the list of available cultures,
     * their icons and handles the change of
     * cultures through this list
     */
    renderCultureList() {
        let result = [];

        let currentCulture = Culture.getCultureCode();
        let cultureList = Culture.getAvailableCultureList();
        cultureList.forEach((cultureItem) => {
            let classList = ['SignInCultureItem'];
            if (cultureItem.code === currentCulture) {
                classList.push('SignInCultureItemActive');
            }

            let flagUrl = ('/img/culture/flags/' + cultureItem.code + '.png');
            result.push(
                <div key={cultureItem.code} className={classList.join(' ')}>
                    <img className="SignInCultureItemFlag" src={flagUrl}
                        alt={cultureItem.name} title={cultureItem.name}
                        onClick={this.changeCulture.bind(this, cultureItem.code)} />
                </div>
            );
        });

        return result;
    }

    /**
     * Renders the sign in component with
     * all the available authentication
     * providers and manages the authentication
     * with the desired provider
     */
    render() {
        /* extract any failed user account id if present */
        let failedUserAccountId = "";
        if (typeof this.state.failedUserObject === 'object'
            && this.state.failedUserObject !== null) {
            if (typeof this.state.failedUserObject.id === 'string'
                && this.state.failedUserObject.id !== '') {
                let partList = this.state.failedUserObject.id.split(':');
                if (partList.length > 2) {
                    failedUserAccountId = partList[2];
                }
            }
        }

        /* define the title and text based on mode */
        let signInTitleText = Culture.getText('SIGNIN_TITLE');
        let signInIntroText = Culture.getText('SIGNIN_INTROTEXT');
        if(this.props.isSetupSession === true){
            signInTitleText = Culture.getText('SIGNIN_SETUP_TITLE');
            signInIntroText = Culture.getText('SIGNIN_SETUP_INTROTEXT');
        }else{
            if(this.getInvitationCode() !== false){
                signInTitleText = Culture.getText('SIGNIN_INVITATION_TITLE');
                signInIntroText = Culture.getText('SIGNIN_INVITATION_INTROTEXT');
            }
        }

        return (
            <div className="SignInContainer">
                <div className="SignInView">
                    <div className="SignInViewContent">
                        <div className="SignInViewContentContainer">
                            <div className="SignInViewContentMain">
                                <div className="SignInViewLogo">
                                    <img src="/img/logo-large-dark.svg" alt="LODGEA" />
                                </div>
                            </div>

                            <div className="SignInCultureList">
                                {this.renderCultureList()}
                            </div>
                        </div>
                    </div>
                    <div className="SignInViewForm">
                        <div className="SignInViewFormContainer">
                            {this.state.inProgress === true &&
                                <LoadingIndicator />
                            }

                            {this.state.inProgress === false &&
                                <>
                                    <div className="SignInViewIntro">
                                        <h1>{signInTitleText}</h1>
                                        <div className="SignInViewIntroText">
                                            {signInIntroText}
                                        </div>
                                    </div>

                                    {this.state.failed === true &&
                                        this.state.networkFailure === false &&
                                        this.state.unassigned === false &&
                                        <div className="SignInFailedMessage">
                                            {Culture.getText('SIGNIN_ERRORTEXT')}
                                        </div>
                                    }

                                    {this.state.failed === true && this.state.networkFailure === true &&
                                        <div className="SignInFailedMessage">
                                            {Culture.getText('SIGNIN_NETWORKERRORTEXT')}
                                        </div>
                                    }

                                    {this.state.failed === true &&
                                        this.state.networkFailure === false &&
                                        this.state.unassigned === true &&
                                        <div className="SignInUnassignedUser">
                                            <div className="SignInUnassignedUserText">
                                                {Culture.getText('SIGNIN_UNASSIGNED_USER_TEXT')}
                                            </div>

                                            <div className="SignInUnassignedUserInfo">
                                                <div className="SignInUnassignedUserField">
                                                    <div className="SignInUnassignedUserFieldTitle">
                                                        {Culture.getText('SIGNIN_UNASSIGNED_USER_NAME')}
                                                    </div>
                                                    <div className="SignInUnassignedUserFieldText">
                                                        {this.state.failedUserObject.name}
                                                    </div>
                                                </div>

                                                <div className="SignInUnassignedUserField">
                                                    <div className="SignInUnassignedUserFieldTitle">
                                                        {Culture.getText('SIGNIN_UNASSIGNED_USER_ID')}
                                                    </div>
                                                    <div className="SignInUnassignedUserFieldText">
                                                        {failedUserAccountId}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.props.isSetupSession === false && this.getInvitationCode() === false &&
                                        <div className="SignInRegionView">
                                            <div className="SignInRegionText">
                                                {Culture.getText('SIGNIN_REGION_TEXT')}
                                            </div>
                                            <div className="SignInRegionList">
                                                {(function () {
                                                    let result = [];

                                                    let activeRegionCode = ManagementUser.getSystemRegionCode();
                                                    for (const regionCode of Object.keys(SystemRegionConfig)) {
                                                        result.push(
                                                            <div key={regionCode}
                                                                className="SignInRegionItem"
                                                                data-selected={(activeRegionCode === regionCode)}
                                                                data-regioncode={regionCode}
                                                                onClick={this.selectRegion.bind(this, regionCode)}>
                                                                {Culture.getText('SYSTEM_REGION_' + regionCode)}
                                                            </div>
                                                        );
                                                    }

                                                    return result;
                                                }).bind(this)()}
                                            </div>
                                        </div>
                                    }

                                    <div className="SignInButtonList">
                                        <div className="SignInButton SignInButtonMicrosoft" onClick={() => this.signInWithMicrosoft()}>
                                            <img className="SignInButtonMicrosoftButton"
                                                src={"/img/signin/microsoftbutton-"+Culture.getCultureCode()+".png"} 
                                                title={Culture.getText('SIGNIN_MICROSOFT_BUTTONTEXT')} 
                                                alt={Culture.getText('SIGNIN_MICROSOFT_BUTTONTEXT')} />
                                        </div>

                                        <div id="SignInButtonGoogle" className="SignInButton SignInButtonGoogle">
                                            {Culture.getText('SIGNIN_GOOGLE_BUTTONTEXT')}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignInView;