/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import Culture from "./Culture";

/**
 * The recommender class provides recommendations
 * for additional data and information based on 
 * certain input data.
 */
class Recommender {
    /**
     * Returns a list of recommended or suggested
     * amenity codes for the message list provided
     * as a parameter
     * 
     * @param {array} messageList
     * the message list that contains all the unit
     * descriptions to check for amenity suggestions 
     */
    getUnitAmenityFromMessageList(messageList){
        let result = [];

        if(Array.isArray(messageList)){
            let amenityList = require('../config/ota/OTARoomAmenityType.json');

            messageList.forEach((message) => {
                let text = message.text.toLowerCase();
                if(typeof text === 'string' && text !== ""){                
                    /* iterate through the amenities and check if 
                        they exist inside the text */
                    Object.keys(amenityList).forEach((amenityKey) => {
                        /* check the text for the local language */
                        if(Culture.isCultureAvailable(message.languageCode) === true){
                            let amenityText = Culture.getText('UNIT_AMENITYTYPENAME_'
                                            + amenityKey,message.languageCode).toLowerCase();

                            if(text.indexOf(amenityText) >= 0){
                                if(!result.includes(amenityKey)){
                                    result.push(amenityKey);
                                }
                            }
                        }if(message.languageCode === 'en'){
                            let amenityText = amenityList[amenityKey].toLowerCase();
                            if(text.indexOf(amenityText) >= 0){
                                if(!result.includes(amenityKey)){
                                    result.push(amenityKey);
                                }
                            }
                        }
                    });
                }
            });
        }

        return result;
    }
}

export default Recommender;