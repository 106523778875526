/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/EditorSection.css';

class EditorSection extends React.Component {
    /**
     * Renders the section control and also operates
     * the collapse functionality if it is enabled
     */
    render(){
        /* render the section with title and content */
        return(
            <div className="ContentEditorSectionContainer">
                <div className="ContentEditorSection">
                    <div className="ContentEditorTitle">
                        <div className="ContentEditorTitleText">
                            {this.props.title || '...'}
                        </div>
                        <div className="ContentEditorSubtitleText">
                            {this.props.subtitle || '...'}
                        </div>
                    </div>

                    {typeof this.props.introText === 'string' && this.props.introText !== '' &&
                        <div className="ContentEditorIntroText">
                            {this.props.introText}
                        </div>
                    }

                    <div className="ContentEditorContent">
                        {this.props.children}
                    </div>
                </div>
                <div className="ContentEditorSectionHelp">
                    <div className="ContentEditorSectionHelpTitle">
                        {this.props.title || '...'}
                    </div>
                    <div className="ContentEditorSectionHelpText">
                        {this.props.helpText || '...'}
                    </div>
                </div>
            </div>
        );
    }
}

export default EditorSection;
