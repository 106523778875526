/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import '../../style/InventoryView.css';

import InventoryTable from './InventoryTable';
import InventoryEditor from './InventoryEditor';
import ContentHeaderMenu from '../common/ContentHeaderMenu';

class InventoryView extends React.Component {
    state = {
        selectedProperty: null
    }

    /**
     * Renders the editor and the table of properties. 
     * This component also manages the navigation between
     * the list and the editor.
     */
    render(){
        if(typeof this.state.selectedProperty === 'string'){
            return(
                <InventoryEditor property={this.state.selectedProperty} 
                    onClose={() => this.setState({selectedProperty: null})} />
            );
        }else{
            return(
                <div>
                    <ContentHeaderMenu title={Culture.getText('INVENTORY_TITLE')}
                        subtitle={Culture.getText('INVENTORY_SUBTITLE')} />

                    <InventoryTable onShowView={this.props.onShowView}
                        onSelectProperty={(propertyId) => {
                        /* set the selected property for this view */
                        this.setState({selectedProperty: propertyId});
                    }} />
                </div>
            );
        }
    }
}

export default InventoryView;