/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/DataInputField.css';

/**
 * Input field that represents a select
 * list from which the user can choose
 * a single option
 */
class DataInputList extends React.Component {
    /**
     * Notifies the parent component about
     * the updated value
     * 
     * @param {object} e
     * the input control event object
     */
    changeValue(e){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(e.target.value);
        }
    }

    /**
     * Render the select option
     * list from the list provided
     * in the props
     */
    renderList(){
        let result = [];

        if(Array.isArray(this.props.list)){
            this.props.list.forEach((item) => {
                result.push(
                    <option key={item.value} value={item.value}>{item.text}</option>
                );
            });
        }

        return result;
    }

    /**
     * Renders the component for the data input
     */
    render(){
        let disabled = false;
        if(this.props.disabled === true){ disabled = true; }

        let classList = ['DataInputField', 'DataInputList'];
        if(disabled === true){
            classList.push('DataInputFieldDisabled');
        }

        return(
            <div className={classList.join(' ')} data-disabled={disabled}>
                <div className="DataInputFieldTitle">{this.props.title}</div>
                <div className="DataInputFieldListInputElement">
                    <select className="DataInputFieldList" type="text" 
                            value={this.props.value} disabled={disabled}
                            onChange={this.changeValue.bind(this)}>
                        {this.renderList()}
                    </select>
                </div>
            </div>
        );
    }
}

export default DataInputList;