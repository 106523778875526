/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import Culture from '../../class/Culture';
import Property from '../../class/Property';
import Product from '../../class/Product';

import DataInputList from '../common/DataInputList';
import DataInputField from '../common/DataInputField';
import StandardButton from '../common/StandardButton';
import SubNavigationList from '../common/SubNavigationList';
import ProductEditor from './ProductEditor';
import EditorSection from '../common/EditorSection';

import '../../style/AvailabilityEditor.css';

/* require the connectivity config to determine whether
    editing ARI information (rate plans) is blocked */
const ConnectivityConfig = require('../../config/connectivity/channel.json');

/**
 * This component allows to create products, rate plan,
 * prices and manage the availability of these
 */
class AvailabilityEditor extends React.Component {
    state = {
        showCreateProductWindow: false,
        newProductRoomId: '',
        newProductName: '',
        productErrorText: ''
    }

    /**
     * Attach the handler for culture changes
     * as this component uses localised text
     */
    componentDidMount(){
        Culture.attachCultureComponent(this);

        let property = new Property(this.props.property);
        let guestRoomList = property.getGuestRoomList();
        if(Array.isArray(guestRoomList)){
            if(guestRoomList.length > 0){
                this.setState({newProductRoomId: guestRoomList[0].roomId});
            }
        }
    }

    /**
     * Creates a new product in the product list
     * or shows an error if there is insufficient
     * data. Notifies the parent update the update
     */
    createProduct(){
        /* ensure the rate plan name is at least 3 chars long */
        if(this.state.newProductName.length >= 3){
            /* create a default name for the rate plan */
            let defaultRatePlanRandom = Math.floor(Math.random() * (10000 - 1000) + 1000);
            let defaultRatePlanName = (this.state.newProductName + ' ' + defaultRatePlanRandom);
            let defaultRatePlanCode = this.state.newProductName.toLowerCase()
                                .replace(' ','-').replace(/[^a-z0-9\\-]/gmi,'')
                                + '-' + defaultRatePlanRandom;

            /* update the product list with the new product */
            let productList = this.props.productList;
            if(!Array.isArray(productList)){ productList = []; }
            productList.push({
                propertyId: this.props.property.code,
                name: this.state.newProductName,
                mealPlanList: [],
                roomTypeList: [{typeCode: this.state.newProductRoomId}],
                isLOSPricing: true,
                isOBPPricing: false,
                ratePlanList: [{
                    active: false,
                    /* create a default name for the rate plan */
                    name: defaultRatePlanName,
                    code: defaultRatePlanCode,
                    propertyId: this.props.property.code,
                    pricingList: []
                }]
            });            

            /* notify the parent about the update */
            if(typeof this.props.onProductListUpdated === 'function'){
                this.props.onProductListUpdated(productList);
            }

            /* reset any previous error text in the state */
            this.setState({
                productErrorText: '',
                showCreateProductWindow: false
            });
        }else{
            /* show an error when the rate plan name is invalid */
            this.setState({productErrorText: Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME_ERROR')});
        }
    }

    /**
     * Returns the list of accommodation
     * units for the unit selection list
     */
    getAccommodationUnitList(){
        let result = [];

        let property = new Property(this.props.property);
        property.getGuestRoomList().forEach((unitItem) => {
            let unitName = (
                unitItem.name + ' — '
                + Culture.getText('UNIT_TYPENAME_' + unitItem.roomTypeCode) 
                + ' (' + unitItem.roomId + ')' 
            );
            
            result.push({
                value: unitItem.roomId, text: unitName
            });
        });

        return result;
    }

    /**
     * Updates the product in the product
     * list and return 
     * 
     * @param {number} index
     * the index of the product to update
     * 
     * @param {object} value 
     * the product object to update with
     */
    updateProduct(index,value){
        let productList = this.props.productList;
        if(productList.length > index){
            productList[index] = value;
        }

        /* notify the parent about the update */
        if(typeof this.props.onProductListUpdated === 'function'){
            this.props.onProductListUpdated(productList);
        }
    }

    /**
     * Returns an array with all unit names
     * of the units included in the product
     * provided as parameter
     * 
     * @param {object} product
     * the product to return the unit names for
     */
    getProductUnitNameList(product){
        let result = [];

        if(Array.isArray(product.roomTypeList)){
            let property = new Property(this.props.property);
            let roomList = property.getGuestRoomList();

            for(const roomType of product.roomTypeList){
                let typeCode = roomType.typeCode;
                for(const guestRoom of roomList){
                    if(guestRoom.roomId === typeCode){
                        result.push(guestRoom.name);
                    }
                }
            }
        }

        return result;
    }

    /**
     * Returns true when editing the rate plan
     * is allowed and false if rate plans are
     * locked as the connected channel prohibits
     * editing the rates and rate plans
     */
    isRatePlanEditAllowed(){
        let result = true;

        let propertyObject = this.props.property;
        if(typeof propertyObject === 'object' && propertyObject !== null){
            if(typeof propertyObject.connection === 'object' && propertyObject.connection !== null){
                if(typeof propertyObject.connection.provider === 'string'
                    && propertyObject.connection.provider !== ''){
                    /* determine the provider code and check if the provider is
                        configured to block editing the ARI data in the property */
                    let providerCode = propertyObject.connection.provider;
                    if(providerCode.indexOf('#') > 0){ providerCode = providerCode.split('#')[0]; }

                    for(const providerConfig of ConnectivityConfig){
                        if(providerConfig.code === providerCode 
                            && providerConfig.allowEditAri === false){
                            /* editing rate plans is not allowed for this provider */
                            result = false;
                            console.log('Rate Plan editing not allowed');
                        }
                    }
                }
            }
        }

        return result;
    }

    /**
     * Renders the list of all products with the component
     * to edit the product, prices and the availability
     */
    renderList(){
        let result = [];

        if(Array.isArray(this.props.productList)){
            /* sort the product list alphabetically */
            let sortedList = this.props.productList;
            sortedList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            for(let i=0; i<sortedList.length; i++){
                let product = new Product(sortedList[i]);
                let productNameLabel = product.name;
                if(productNameLabel.length > 32){
                    productNameLabel = (productNameLabel.substring(0,29) + '...');
                }

                result.push(
                    <section key={productNameLabel} code={productNameLabel} name={productNameLabel}>
                        <ProductEditor product={product} property={this.props.property}
                            isRatePlanEditAllowed={this.isRatePlanEditAllowed()}
                            onProductUpdated={(function(index,product){
                                this.updateProduct(index,product);
                            }).bind(this,i)}
                            subNavList={[
                                Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME_TITLE'),
                                Culture.getText('PROPERTY_AVAILABILITY_RATEPLAN_TITLE'),
                                Culture.getText('PROPERTY_AVAILABILITY_UNIT_TITLE'),
                                Culture.getText('PROPERTY_AVAILABILITY_MEALPLAN_TITLE'),
                                Culture.getText('PROPERTY_AVAILABILITY_POLICY_TITLE')
                            ]} />

                        <EditorSection title={Culture.getText('PROPERTY_PRODUCT_DELETE_TITLE')}
                            subtitle={Culture.getText('PROPERTY_PRODUCT_DELETE_SUBTITLE')}
                            helpText={Culture.getText('PROPERTY_PRODUCT_DELETE_HELPTEXT')}
                            introText={Culture.getText('PROPERTY_PRODUCT_DELETE_INTROTEXT')}>
                            <div>
                                <StandardButton className="AvailabilityEditorProductRemoveButton StandardRemoveButton"
                                    text={Culture.getText('PROPERTY_PRODUCT_DELETE')}
                                    onClick={(function(){
                                        let productList = this.props.productList;
                                        let updatedList = [];
                                        for(let x=0; x<productList.length; x++){
                                            if(x !== i){ updatedList.push(productList[x]); }
                                        }
        
                                        if(typeof this.props.onProductListUpdated === 'function'){
                                            this.props.onProductListUpdated(updatedList);
                                        }
                                    }).bind(this)} />
                            </div>
                        </EditorSection>
                    </section>
                );
            }
        }

        return result;
    }

    /**
     * Renders the component with all elements
     * and sub-components as well as the form
     * to create new products
     */
    render(){
        /* create the property object */
        let property = new Property(this.props.property);
        if(property.getGuestRoomList().length === 0){
            return(
                <div className="InventoryEditorSectionContent AvailabilityEditorContent">
                    <SubNavigationList>
                        <section code="nounit" name={Culture.getText('PROPERTY_AVAILABILITY_NOUNIT')}>
                            <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_NOUNIT_TITLE')}
                                subtitle={Culture.getText('PROPERTY_AVAILABILITY_NOUNIT_SUBTITLE')}
                                introText={Culture.getText('PROPERTY_AVAILABILITY_NOUNIT_INTRO')}
                                helpText={Culture.getText('PROPERTY_AVAILABILITY_NOUNIT_HELP')}>
                            </EditorSection>
                        </section>
                    </SubNavigationList>
                </div>
            );
        }else{
            return(
                <div className="InventoryEditorSectionContent AvailabilityEditorContent">
                    <SubNavigationList>
                        {this.renderList()}

                        <section code="__new_product" name={Culture.getText('PROPERTY_AVAILABILITY_ADDPRODUCT')} type="action">
                            <EditorSection title={Culture.getText('PROPERTY_AVAILABILITY_ADDPRODUCT_TITLE')}
                                subtitle={Culture.getText('PROPERTY_AVAILABILITY_ADDPRODUCT_SUBTITLE')}
                                helpText={Culture.getText('PROPERTY_AVAILABILITY_ADDPRODUCT_HELPTEXT')}
                                introText={Culture.getText('PROPERTY_AVAILABILITY_ADDPRODUCT_TEXT')}>
                                <DataInputField title={Culture.getText('PROPERTY_AVAILABILITY_PRODUCTNAME')} 
                                    value={this.state.newProductName} 
                                    onChange={(value) => this.setState({newProductName: value})}
                                    errorText={this.state.productErrorText} />

                                <DataInputList title={Culture.getText('PROPERTY_AVAILABILITY_ROOMID')} 
                                    value={this.state.newProductRoomId} 
                                    list={this.getAccommodationUnitList()} onChange={(value) => {
                                        this.setState({newProductRoomId: value});
                                }}/>

                                <div>
                                    <StandardButton text={Culture.getText('PROPERTY_AVAILABILITY_ADDPRODUCT')} 
                                        onClick={this.createProduct.bind(this)} />
                                </div>
                            </EditorSection>
                        </section>
                    </SubNavigationList>
                </div>
            );
        }
    }
}

export default AvailabilityEditor;