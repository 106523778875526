/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* list of property type classes */
const PropertyTypeClassList = require("../config/ota/OTAPropertyClassType.json");

/* default type for new guest rooms */
const NEW_GUESTROOM_DEFAULTTYPE = 9;
const NEW_GUESTROOM_DEFAULTOCCUPANCY = 2;

/**
 * This class represents the property and provides
 * additional methods for interacting and updating
 * the property and its contents
 */
class Property {
    /**
     * Constructs the property object with the json data
     * structure that contains all the contents of the property
     * 
     * @param {object} value
     * the property json data structure to use 
     */
    constructor(value){
        /* assign the values to this object */
        Object.assign(this,value);
    }

    /**
     * Returns true when this property is connected to
     * a channel and otherwise returns false if it is
     * not (yet) connected to any channel
     */
    isChannelConnected(){
        let result = false;

        if(typeof this.connection === 'object' 
            && this.connection !== null){
            if(typeof this.connection.provider === 'string' 
                && this.connection.provider !== ''){
                result = true;
            }
        }

        return result;
    }

    /**
     * Returns the code of the transaction method
     * used by this property to execute the transaction
     */
    getTransactionMethodCode(){
        let result = "MANDATORY_BOOKING";

        if(typeof this.transaction === 'object' && this.transaction !== null){
            if(typeof this.transaction.method === 'string'){
                result = this.transaction.method;
            }
        }

        return result;
    }

    /**
     * Sets the transaction method for this
     * property which defines how transactions
     * are being processed
     * 
     * @param {string} code
     * the transaction method code to set for
     * this property which can be on the following:
     * 
     * MANDATORY_BOOKING
     * RECONFIRM_BOOKING
     * REQUEST_ONLY
     * EXTERNAL_LINK
     *  
     */
    setTransactionMethod(code){
        /* set the default transaction type if none is set */
        if(typeof this.transaction !== 'object'){
            this.transaction = {method: 'MANDATORY_BOOKING'};
        }

        if(typeof code === 'string'){
            this.transaction.method = code;
        }
    }

    /**
     * Sets the list of URLs with the external links
     * to the external booking systems when the transaction
     * method is 'EXTERNAL_LINK'
     * 
     * @param {array} value
     * the list with external links for transactions 
     */
    setTransactionExternalLinkList(value){
        if(typeof this.transaction !== 'object'){
            this.transaction = {method: 'EXTERNAL_LINK'};
        }

        this.transaction.externalList = value;
    }

    /**
     * Returns the list of URLs to external
     * booking systems to use with transactions
     * that hare handled through external systems
     */
    getTransactionExternalLinkList(){
        let result = [];

        if(typeof this.transaction === 'object'){
            if(Array.isArray(this.transaction.externalList)){
                result = this.transaction.externalList;
            }
        }

        return result;
    }

    /**
     * Returns the contact types to use for
     * booking requests and booking confirmations
     */
    getTransactionContactList(){
        let result = [];

        if(typeof this.transaction === 'object'){
            if(Array.isArray(this.transaction.contactTypeList)){
                result = this.transaction.contactTypeList;
            }
        }

        return result;
    }

    /**
     * Sets the transaction contact list for
     * this property to handle bookings and 
     * requests with
     * 
     * @param {array} value
     * the list of contact types to use 
     */
    setTransactionContactList(value){
        if(typeof this.transaction !== 'object'){
            this.transaction = {method: 'MANDATORY_BOOKING'};
        }

        if(Array.isArray(value)){
            this.transaction.contactTypeList = value;
        }
    }

    /**
     * Returns the URI name of the property
     * as a string and if none is selected,
     * it'll create one and return it
     */
    getUriName(){
        if(typeof this.uri !== 'string'){
            this.setUriName(this.name);
        }

        return this.uri;
    }

    /**
     * Returns a new empty policy document
     * with the default value for policies
     */
    getEmptyPolicy(){
        return {
            checkInTime: 0,
            checkOutTime: 0,
            totalGuestCount: 0,
            cancellationPolicyList: [],
            advanceBookingMin: 34560000,
            advanceBookingMax: 0,
            petsPolicy: {
                allowed: true,
                byArrangement: true,
                freeOfCharge: false
            },
            prepaymentPolicy: '',
            guaranteePolicy: null,
            taxPolicyList: [],
            feePolicyList: []
        };
    }

    /**
     * Sets the URI name for this property
     * locally and also replaces all characters
     * from the value that are not a-z or '-'
     * 
     * @param {string} value
     * the uri name of the property 
     */
    setUriName(value){
        let cleanUriName = value.toLowerCase()
            .replace(new RegExp("[^a-z0-9\\-]","gmi"),'-')
            .replace(new RegExp("[\\-]{2,99}","gmi"),'-');
    
        if(cleanUriName.endsWith('-')){
            cleanUriName = cleanUriName.substring(0,cleanUriName.length-1);
        } if(cleanUriName.startsWith('-')){
            cleanUriName = cleanUriName.substring(1);
        }

        if(cleanUriName !== ""){
            this.uri = cleanUriName;
        }
    }

    /**
     * Returns true when this property
     * is published and otherwise it'll
     * return false
     */
    isPublished(){
        if(this.published === true){
            return true;
        }

        return false;
    }

    /**
     * Sets the indicator of whether this
     * property is published or not
     * 
     * @param {boolean} value
     * true when published and otherwise
     * false if not published 
     */
    publish(value){
        if(typeof value === 'boolean'){
            this.published = value;
        }
    }

    /**
     * Sets the message list with the property description
     * from the message list provided in the parameter. 
     * This method will also initialise the propertyInfo
     * object if not present.
     * 
     * @param {array} messageList
     * the message list to set for the property info 
     */
    setPropertyMessageList(messageList){
        if(Array.isArray(messageList)){
            if(typeof this.propertyInfo !== 'object' || this.propertyInfo === null){
                this.propertyInfo = {};
            }
    
            this.propertyInfo.messageList = messageList;
        }
    }

    /**
     * Returns all media files attached
     * to this property as an array
     */
    getPropertyMediaList(){
        let result = [];

        if(Array.isArray(this.mediaList)){
            result = this.mediaList;
        }

        return result;
    }

    /**
     * Sets the property media list
     * with all media files associated
     * with this property
     * 
     * @param {array} list
     * the media list to set for this property 
     */
    setPropertyMediaList(list){
        if(Array.isArray(list)){
            this.mediaList = list;
        }
    }

    /**
     * Returns the message list array of the 
     * property info with the text description 
     * of the property in the available languages
     */
    getPropertyInfoText(){
        let result = [];

        if(typeof this.propertyInfo === 'object'){
            if(Array.isArray(this.propertyInfo.messageList)){
                result = this.propertyInfo.messageList;
            }
        }

        return result;
    }

    /**
     * Returns an array with all policies
     * currently set for this property on
     * a property level
     */
    getPolicyList(){
        let result = [];

        if(Array.isArray(this.policyList)){
            result = this.policyList;
        }

        return result;
    }

    /**
     * Sets a new policy list with property
     * level policies for this property
     * 
     * @param {array} value
     * the array with all policy objects 
     */
    setPolicyList(value){
        if(Array.isArray(value)){
            this.policyList = value;
        }
    }

    /**
     * Returns the cancellation grace period
     * setting for this property
     */
    getCancellationGracePeriod(){
        let result = {
            hoursAfterBooking: 0,
            weeksBeforeCheckIn: 0
        };

        if(typeof this.cancellationGracePeriod === 'object'){
            result = this.cancellationGracePeriod;
        }

        return result;
    }

    /**
     * Sets the cancellation grace period
     * for this property to the defined value
     * 
     * @param {object} value
     * object with the props 'hoursAfterBooking' 
     * and/or 'weeksBeforeCheckIn' as number
     */
    setCancellationGracePeriod(value){
        if(typeof this.cancellationGracePeriod !== 'object'){
            this.cancellationGracePeriod = {
                hoursAfterBooking: 0,
                weeksBeforeCheckIn: 0
            };
        }

        ['hoursAfterBooking', 'weeksBeforeCheckIn'].forEach((key) => {
            if(value.hasOwnProperty(key)){
                this.cancellationGracePeriod[key] = value[key];
            }
        });    
    }

    /**
     * Sets the default currency code for
     * this property to charge in
     * 
     * @param {string} value
     * 3-digit ISO currency code 
     */
    setDefaultCurrencyCode(value){
        if(typeof value === 'string'){
            if(value.length === 3){
                this.currencyCode = value.toUpperCase();
            }
        }
    }

    /**
     * Returns the default currecny code
     * for this property and if none is set
     * it'll return "EUR" (Euro)
     */
    getDefaultCurrencyCode(){
        let result = "EUR";

        if(typeof this.currencyCode === 'string'){
            return this.currencyCode;
        }

        return result;
    }

    /**
     * Returns all guest rooms of this
     * property as an array. If none are
     * attached, it'll return an empty
     * array with no items
     */
    getGuestRoomList(){
        let result = [];

        if(typeof this.facilityInfo === 'object' && this.facilityInfo !== null){
            if(Array.isArray(this.facilityInfo.guestRoomList)){
                result = this.facilityInfo.guestRoomList;
            }
        }

        return result;
    }

    /**
     * Returns the media list of the guest
     * room for which the id was provided
     * 
     * @param {string} roomId 
     * the id of the guest room to return
     * the media list for
     */
    getGuestRoomMediaList(roomId){
        let result = [];

        this.getGuestRoomList().forEach((guestRoom) => {
            if(guestRoom.roomId === roomId){
                if(Array.isArray(guestRoom.mediaList)){
                    result = guestRoom.mediaList;
                }
            }
        });

        return result;
    }

    /**
     * Updates the media list for the
     * guest room provided in the parameters
     * 
     * @param {string} roomId
     * identifier of the room or unit to update
     * the media list for
     *  
     * @param {array} list
     * the updated media list to set for the guest room 
     */
    setGuestRoomMediaList(roomId,list){
        let propertyObject = this;
        this.getGuestRoomList().forEach((guestRoom) => {
            if(guestRoom.roomId === roomId){
                guestRoom.mediaList = list;
                propertyObject.updateGuestRoom(guestRoom);
            }
        });
    }

    /**
     * Updates the guest room provided
     * as a parameter in this property
     * 
     * @param {object} value
     * the guest room to update 
     */
    updateGuestRoom(value){
        if(typeof this.facilityInfo === 'object' && this.facilityInfo !== null){
            if(Array.isArray(this.facilityInfo.guestRoomList)){
                for(let g=0; g<this.facilityInfo.guestRoomList.length; g++){
                    let guestRoom = this.facilityInfo.guestRoomList[g];

                    if(guestRoom.roomId === value.roomId){
                        this.facilityInfo.guestRoomList[g] = value;
                    }
                }
            }
        }
    }

    /**
     * Creates a new guest room in this property
     * with the id and name provided in the parameters
     * 
     * @param {string} id
     * identification of the new guest room
     *  
     * @param {string} name
     * name of the new guest room 
     */
    createGuestRoom(id,name){
        let guestRoomExists = false;
        this.getGuestRoomList().forEach((guestRoom) => {
            if(guestRoom.roomId === id){
                guestRoomExists = true;
            }
        });

        if(!guestRoomExists){
            /* create the facility info with the guest room list if it does
                not already exist in the facility information */
            if(typeof this.facilityInfo !== 'object' || this.facilityInfo === null){
                this.facilityInfo = {guestRoomList: []};
            }

            /* initialise the guest room list if it does not yet exist */
            if(!Array.isArray(this.facilityInfo.guestRoomList)){
                this.facilityInfo.guestRoomList = [];
            }

            /* add the new empty guest room object */
            this.facilityInfo.guestRoomList.push({
                isActive: false,
                roomId: id,
                name: name,
                typeName: "",
                descriptionText: "",
                imageList: [],
                roomTypeCode: NEW_GUESTROOM_DEFAULTTYPE,
                isNonSmoking: false,
                roomTypeName: "",
                amenityList: [],
                maxOccupancy: NEW_GUESTROOM_DEFAULTOCCUPANCY
            });
        }
    }

    /**
     * Returns the attraction list of this
     * property. If it does not have one, this
     * method will return an empty array
     */
    getAttractionList(){
        let result = [];

        if(typeof this.areaInfo === 'object'){
            if(Array.isArray(this.areaInfo.attractionList)){
                result = this.areaInfo.attractionList;
            }
        }

        return result;
    }

    /**
     * Updates the attraction list of this property
     * with the value provided as parameter
     * 
     * @param {array} list
     * the new attraction list for this property 
     */
    setAttractionList(list){
        if(typeof this.areaInfo !== 'object'){
            this.areaInfo = {
                attractionList: []
            }
        }

        this.areaInfo.attractionList = list;
    }

    /**
     * Removes the attraction at the provided 
     * index from the list of nearby attractions
     *  
     * @param {number} index
     * index number of the attraction to remove 
     */
    removeAttractionItem(index){
        let list = this.getAttractionList();
        let updatedList = [];
        for(let i=0; i<list.length; i++){
            if(i !== index){
                updatedList.push(list[i]);
            }
        }
        this.setAttractionList(updatedList);
    }

    /**
     * Removes the guest room for which the
     * room id was provided from this propertty
     * 
     * @param {string} roomId
     * id of the room to remove from this property 
     */
    removeGuestRoom(roomId){
        if(typeof this.facilityInfo === 'object'){
            if(Array.isArray(this.facilityInfo.guestRoomList)){
                let updatedList = [];
                this.facilityInfo.guestRoomList.forEach((guestRoom) => {
                    if(guestRoom.roomId !== roomId){
                        updatedList.push(guestRoom);
                    }
                });
                this.facilityInfo.guestRoomList = updatedList;
            }
        }
    }

    /**
     * Returns the list of  all available payment
     * methods that can be used in the property
     */
    getPaymentMethodList(){
        let result = [];

        if(typeof this.propertyInfo === 'object'){
            if(Array.isArray(this.propertyInfo.acceptedPaymentList)){
                result = this.propertyInfo.acceptedPaymentList;
            }
        }

        return result;
    }

    /**
     * Enables or disables the provided payment
     * method as a payment method in this property
     * 
     * @param {object} value
     * the payment method object to enable or disable 
     */
    togglePaymentMethod(value){
        if(typeof this.propertyInfo === 'object'){
            if(Array.isArray(this.propertyInfo.acceptedPaymentList)){
                let updatedList = [];
                let methodExists = false;
                this.propertyInfo.acceptedPaymentList.forEach((paymentMethod) => {
                    if(paymentMethod.code !== value.code){
                        updatedList.push(paymentMethod);
                    }else{
                        methodExists = true;
                    }
                });

                if(methodExists === false){
                    updatedList.push(value);
                }

                this.propertyInfo.acceptedPaymentList = updatedList;
            }else{
                this.propertyInfo.acceptedPaymentList = [value];
            }
        }else{
            this.propertyInfo = {
                acceptedPaymentList: [value]
            };
        }
    }

    /**
     * Returns the guest information and
     * requirements for this property as
     * defined in the property. If a settings
     * is undefined, this method returns the
     * default value for the setting
     */
    getGuestInfo(){
        let result = {
            guestAddressRequired: false,
            guestContactNumberRequired: false,
            minimumStayRequired: false,
            guestNameListRequired: false,
            hasAgeRestriction: false,
            ageRestrictionMin: 0,
            ageRestrictionMax: 120,
            hasCurfew: false,
            curfewStart: 3600000,
            curfewEnd: 18000000
        };

        if(typeof this.guestInfo === 'object'){
            Object.keys(result).forEach((key) => {
                if(this.guestInfo.hasOwnProperty(key)){
                    result[key] = this.guestInfo[key];
                }
            });
        }

        return result;
    }

    /**
     * Returns the list of all restaurants
     * of this property or an empty array if
     * this property does not have any
     */
    getRestaurantList(){
        let result = [];

        if(typeof this.facilityInfo === 'object'){
            if(Array.isArray(this.facilityInfo.restaurantList)){
                result = this.facilityInfo.restaurantList;
            }
        }

        return result;
    }

    /**
     * Updates the restaurant list of this
     * property with the list provided as
     * a parameter
     * 
     * @param {array} value
     * the updated restaurant list to apply 
     */
    setRestaurantList(value){
        if(typeof this.facilityInfo === 'object'){
            this.facilityInfo.restaurantList = value;
        }
    }

    /**
     * Returns the list with all available
     * services of this property
     */
    getServiceList(){
        let result = [];

        if(Array.isArray(this.serviceList)){
            result = this.serviceList;
        }

        return result;
    }

    /**
     * Updates the service list of the property
     * with the list provided as parameter
     * 
     * @param {array} value
     * the service list to set for this property 
     */
    setServiceList(value){
        if(Array.isArray(value)){
            this.serviceList = value;
        }
    }

    /**
     * Updates a guest information setting
     * of this property or creates it if
     * it does not yet exist
     * 
     * @param {string} key
     * the key name of the guest information setting
     *  
     * @param {string} value
     * the value of the guest information setting 
     */
    updateGuestInfo(key,value){
        if(typeof this.guestInfo === 'object'){
            this.guestInfo[key] = value;
        }else{
            this.guestInfo = {
                [key]: value
            }
        }
    }

    /**
     * Returns the geo data for this property
     */
    getGeoData(){
        let result = {};

        if(typeof this.geo === 'object'){
            result = this.geo;
        }

        return result;
    }

    /**
     * Returns the latidue and longitude
     * of this location as an object
     */
    getLocationCoordinates(){
        let result = {
            latitude: 0,
            longitude: 0
        };

        if(typeof this.propertyInfo === 'object' && this.propertyInfo !== null){
            if(typeof this.propertyInfo.location === 'object' && this.propertyInfo.location !== null){
                if(typeof this.propertyInfo.location.latitude === 'number'
                    && typeof this.propertyInfo.location.longitude === 'number'){
                    /* set the result values */
                    result = {
                        latitude: this.propertyInfo.location.latitude,
                        longitude: this.propertyInfo.location.longitude
                    };
                }
            }
        }

        return result;
    }

    /**
     * Updates the latitude and longitude
     * of the position of this property. 
     * 
     * If either latitude or longitude provided
     * are null, only the field that is not null
     * will be updated
     * 
     * @param {number} latitude
     * latitude in decimal degrees as float
     * (pass 'null' if update to be omitted)
     *  
     * @param {number} longitude 
     * longitude in decimal degrees as float
     * (pass 'null' if update to be omitted)
     */
    setLocationCoordinates(latitude,longitude){
        /* ensure the property info structure is present */
        if(typeof this.propertyInfo !== 'object' || this.propertyInfo === null){
            this.propertyInfo = {};
        }

        /* check if location data is present or needs to be created */
        if(typeof this.propertyInfo.location === 'object' && this.propertyInfo.location !== null){
            /* attach the location information to the existing object */
            if(typeof latitude === 'number'){ this.propertyInfo.location.latitude = latitude; }
            if(typeof longitude === 'number'){ this.propertyInfo.location.longitude = longitude; }
        }else{
            let latitudeValue, longitudeValue = 0;
            if(typeof latitude === 'number'){ latitudeValue = latitude; }
            if(typeof longitude === 'number'){ longitudeValue = longitude; }

            /* create the location object when it does not exist */
            this.propertyInfo.location = {
                latitude: latitudeValue, longitude: longitudeValue
            }
        }
    }

    /**
     * Returns all contacts of this property
     * except the physical location of it
     */
    getContactList(){
        let result = [];

        if(Array.isArray(this.contactList)){
            this.contactList.forEach((contactItem) => {
                if(typeof contactItem.profileType === 'string'){
                    if(contactItem.profileType.toLowerCase() !== 'physicallocation'){
                        result.push(contactItem);
                    }
                }
            });
        }

        return result;
    }

    /**
     * Updates or inserts a new contact to
     * the contact list of this property
     * 
     * @param {number} index
     * optional index of the contact to replace. If
     * this is a new contact, pass in null
     *  
     * @param {string} profileType
     * profile type as string
     *  
     * @param {string} gender
     * gender as string (Male, Female, Unspecified)
     *  
     * @param {string} firstName 
     * first name or given name as string
     * 
     * @param {string} lastName
     * last name or family name as string
     *  
     * @param {string} addressLine 
     * address line as string
     * 
     * @param {string} postalCode
     * postal code as string
     *  
     * @param {string} cityName
     * city name as string
     *  
     * @param {string} stateName
     * state name or code as string
     *  
     * @param {string} countryCode 
     * the ISO-3166-1 country code
     * 
     * @param {string} jobTitle 
     * the job title as string
     * 
     * @param {string} emailAddress
     * email address as string
     *  
     * @param {number} phoneNumber 
     * phone number as number in ITU E.164 format
     * 
     * @param {number} mobileNumber 
     * mobile phone number as number in ITU E.164 format
     * 
     * @param {number} faxNumber 
     * fax number as number in ITU E.164 format
     */
    updateContact(index,profileType,gender,firstName,lastName,
        addressLine,postalCode,cityName,stateName,countryCode,
        companyName,jobTitle,emailAddress,phoneNumber,mobileNumber,
        faxNumber){
        /* ensure the profile is not the physical location */
        if(profileType.toLowerCase() !== 'physicallocation'){
            /* add the basic information */
            let contactEntry = {
                profileType: profileType,
                addressList: [{
                        usageType: "physical",
                        companyName: companyName,
                        addressLine: addressLine,
                        cityName: cityName,
                        postalCode: postalCode,
                        state: stateName,
                        countryCode: countryCode,
                        jobTitle: jobTitle
                }],
                emailList: [emailAddress],
                phoneList: [],
                personList: [{
                    gender: gender,
                    firstName: firstName,
                    lastName: lastName
                }]
            };

            /* add the phone number as defined in:
                (PTT) Phone Technology Type Codes (OTA 2014B) */
            if(typeof phoneNumber === 'number'){
                contactEntry.phoneList.push({
                    typeCode: 1, /* 1 = voice */
                    number: phoneNumber
                });
            }if(typeof mobileNumber === 'number'){
                contactEntry.phoneList.push({
                    typeCode: 5, /* 5 = mobile */
                    number: mobileNumber
                });
            }if(typeof faxNumber === 'number'){
                contactEntry.phoneList.push({
                    typeCode: 3, /* 3 = fax */
                    number: faxNumber
                });
            }

            /* replace the existing contact if the provided
                index is a number and defines the position
                to replace the contact at */
            let insertContact = true;
            if(typeof index === 'number'){
                let updatedList = this.getContactList();
                if(updatedList.length > index){
                    updatedList[index] = contactEntry;

                    if(Array.isArray(this.contactList)){
                        this.contactList.forEach((contactItem) => {
                            if(contactItem.profileType.toLowerCase() === 'physicallocation'){
                                updatedList.push(contactItem);
                            }
                        });
                    }

                    this.contactList = updatedList;
                    insertContact = false;
                }
            }
            
            if(insertContact === true){
                /* insert the contact as a new contact */
                this.contactList.push(contactEntry);
            }

        }
    }

    /**
     * Removes the contact at the provided index
     * from the contact lis of this property
     * 
     * @param {number} index
     * the index to remove from the contact list 
     */
    removeContact(index){
        let contactArray = this.getContactList();
        if(Array.isArray(contactArray)){
            let updatedList = [];
            for(let c=0; c<contactArray.length; c++){
                if(c !== index){
                    updatedList.push(contactArray[c]);
                }
            }

            /* add the physical location if present
                to complete the contact list as this
                method only updates the existing
                contacts without the physical location */
            if(Array.isArray(this.contactList)){
                this.contactList.forEach((contactItem) => {
                    if(contactItem.profileType.toLowerCase() === 'physicallocation'){
                        updatedList.push(contactItem);
                    }
                });
            }

            this.contactList = updatedList;
        }
    }

    /**
     * Returns the property's physical address from
     * the contact information of this property. If 
     * none exists, it'll return a structure with
     * empty strings
     */
    getPhysicalAddress(){
        let result = {
            propertyName: "",
            addressLine: "",
            cityName: "",
            postalCode: "",
            state: "",
            countryCode: ""
        };

        if(Array.isArray(this.contactList)){
            this.contactList.forEach((contactItem) => {
                if(typeof contactItem.profileType === 'string'){
                    if(contactItem.profileType.toLowerCase() === 'physicallocation'){
                        if(Array.isArray(contactItem.addressList)){
                            contactItem.addressList.forEach((addressItem) => {
                                Object.keys(result).forEach((fieldName) => {
                                    if(addressItem.hasOwnProperty(fieldName)){
                                        result[fieldName] = addressItem[fieldName];
                                    }
                                });
                            });
                        }
                    }
                }
            });
        }

        return result;
    }

    /**
     * Updates a location field of the physical
     * address of the property location information
     * 
     * @param {string} fieldName
     * name of the field to update in the physical address
     *  
     * @param {any} fieldValue
     * value of the field ot update in the physical address
     */
    setPhysicalAddressField(fieldName,fieldValue){
        if(typeof fieldName === 'string' && fieldName !== ''){
            if(!Array.isArray(this.contactList)){
                this.contactList = [];
            }

            let physicalAddressExists = false;
            for(let c=0; c<this.contactList.length; c++){
                if(Array.isArray(this.contactList[c].addressList) === true
                    && this.contactList[c].profileType.toLowerCase() === 'physicallocation'){
                    for(let a=0; a<this.contactList[c].addressList.length; a++){
                        this.contactList[c].addressList[a][fieldName] = fieldValue;
                        physicalAddressExists = true;
                    }
                }
            }

            /* when the physical address does not yet exist, create it */
            if(physicalAddressExists === false){
                this.contactList.push({
                    profileType: 'physicallocation',
                    addressList: [{
                        [fieldName]: fieldValue
                    }]
                });
            }
        }
    }

    /**
     * Returns the list of languages that this property
     * supports on-site and in communication
     */
    getLanguageList(){
        let result = [];

        if(typeof this.propertyInfo === 'object'){
            if(Array.isArray(this.propertyInfo.languageList)){
                result = this.propertyInfo.languageList;
            }
        }

        return result;
    }

    /**
     * Sets the language list for this property which
     * defines which languages are available on-site in
     * in communication with the property
     * 
     * @param {array} list
     * list of language code string 
     */
    setLanguageList(list){
        if(typeof this.propertyInfo === 'object'){
            this.propertyInfo.languageList = list;
        }else{
            this.propertyInfo = {
                languageList: list
            };
        }   
    }

    /**
     * Adds the category to this property for which
     * the category code was provided as a parameter
     * 
     * @param {number} code
     * the code of the category to add to the property 
     */
    addCategory(code){
        if(!this.getCategoryList().includes(parseInt(code))){
            /* get the property type defintion */
            if(PropertyTypeClassList.hasOwnProperty(String(code))){
                let categoryObject = {
                    exists: true,
                    code: parseInt(code),
                    name: PropertyTypeClassList[String(code)]
                };
    
                /* ensure the property info has a valid instance */
                if(typeof this.propertyInfo === 'object'){
                    if(Array.isArray(this.propertyInfo.categoryList)){
                        /* add the category to the list */
                        this.propertyInfo.categoryList.push(categoryObject);
                    }else{
                        /* create the category list */
                        this.propertyInfo.categoryList = [categoryObject];
                    }
                }else{
                    /* create the property info and add the category list */
                    this.propertyInfo = {
                        categoryList: [categoryObject]
                    }
                }
            }            
        }
    }

    /**
     * Removes the defined category from the
     * property category list
     * 
     * @param {number} code
     * category code to remove from property 
     */
    removeCategory(code){
        if(typeof this.propertyInfo === 'object'){
            if(Array.isArray(this.propertyInfo.categoryList)){
                let updatedList = [];
                this.propertyInfo.categoryList.forEach((categoryItem) => {
                    if(parseInt(categoryItem.code) !== parseInt(code)){
                        updatedList.push(categoryItem);
                    }
                });

                /* update the local category list if it's not empty */
                if(updatedList.length > 0){
                    this.propertyInfo.categoryList = updatedList;
                }
            }
        }
    }

    /**
     * Returns the category codes of this property
     * as an array of numbers
     */
    getCategoryList(){
        let result = [];

        if(typeof this.propertyInfo === 'object'){
            if(Array.isArray(this.propertyInfo.categoryList)){
                this.propertyInfo.categoryList.forEach((categoryItem) => {
                    if(categoryItem.hasOwnProperty('code')){
                        /* add the code to the selected listt */
                        result.push(parseInt(categoryItem.code));
                    }
                });
            }
        }

        return result;
    }

    /**
     * Sets the unit count value for this
     * property which defines the number
     * of units this property has
     * 
     * @param {int} value
     * the number of units this property has 
     */
    setUnitCount(value){
        let unitCountValue = parseInt(value);
        if(value >= 0){
            if(typeof this.propertyInfo === 'object'){
                this.propertyInfo.unitCount = unitCountValue;
            }else{
                this.propertyInfo = {unitCount: unitCountValue};
            }
        }
    }

    /**
     * Returns the unit count of this property
     * as an integer. If none is defined it'll
     * just return '0'
     */
    getUnitCount(){
        let result = 0;

        if(typeof this.propertyInfo === 'object'){
            if(typeof this.propertyInfo.unitCount === 'number'){
                result = this.propertyInfo.unitCount;
            }
        }

        return result;
    }

    /**
     * Returns an integer with the star rating of this
     * property or the value '0' when the property does
     * not have any star rating
     */
    getStarRating(){
        let result = 0;

        if(Array.isArray(this.awardList)){
            this.awardList.forEach((awardItem) => {
                if(awardItem.provider === 'star-rating'){
                    result = parseInt(awardItem.rating);
                }
            });
        }

        return result;
    }

    /**
     * Sets the star rating for this property object
     * 
     * @param {number} value
     * star rating from 0 (none) to 5 
     */
    setStarRating(value){
        if(Array.isArray(this.awardList)){
            let ratingExists = false;
            for(let a=0; a<this.awardList.length; a++){
                if(this.awardList[a].provider === 'star-rating'){
                    ratingExists = true;
                    this.awardList[a].rating = value;
                }
            }

            if(!ratingExists){
                this.awardList.push({
                    provider: 'star-rating',
                    rating: value
                });
            }
        }else{
            this.awardList = [{
                provider: 'star-rating',
                rating: value
            }];
        }
    }
}

export default Property;