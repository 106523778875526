/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import '../../style/RecommenderOptionInput.css';

/**
 * Provide the recommender component that allows
 * to recommend options to the user
 */
class RecommenderOptionInput extends React.Component {
    render(){
        let result = null;

        if(Array.isArray(this.props.optionList)){
            if(this.props.optionList.length > 0){
                let componentList = [];

                /* sort the available options */
                this.props.optionList.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));

                this.props.optionList.forEach((option) => {
                    componentList.push(
                        <div key={option.value} className="RecommenderOptionInputItem" onClick={() => {
                            if(typeof this.props.onSelect === 'function'){
                                this.props.onSelect(option);
                            }
                        }}>
                            <div className="RecommenderOptionInputItemText">{option.text}</div>
                        </div>
                    );
                });

                result = (
                    <div className="RecommenderOptionInput">
                        {this.props.title !== "" && this.props.title !== null &&
                            <div className="RecommenderOptionInputTitle">{this.props.title}</div>
                        }
                        {this.props.text !== "" && this.props.text !== null &&
                            <div className="RecommenderOptionInputText">{this.props.text}</div>
                        }

                        <div className="RecommenderOptionInputList">
                            {componentList}
                        </div>
                    </div>
                );
            }
        }

        return result;
    }
}

export default RecommenderOptionInput;