/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import '../../style/PolicyEditor.css';

import Culture from '../../class/Culture';

import DataInputTime from '../common/DataInputTime';
import DataInputField from '../common/DataInputField';
import DataInputCheckBox from '../common/DataInputCheckBox';
import DataInputList from '../common/DataInputList';
import CancellationPolicyInput from './CancellationPolicyInput';
import FeeTaxPolicyInput from './FeeTaxPolicyInput';
import EditorSection from '../common/EditorSection';
import HelpArticleView from '../account/HelpArticleView';

/**
 * This component allows to edit single policy
 * documents with their included policies and
 * other settings for policies
 */
class PolicyDocumentEditor extends React.Component {
    /**
     * Notifies the parent component about
     * changes to the policy document
     * 
     * @param {object} policyDocument 
     * the object of the updated policy document
     */
    notifyPolicyUpdate(policyDocument){
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(policyDocument);
        }
    }

    /**
     * Returns whether this policy defines
     * the property as adults only
     */
    isAdultsOnly(){
        let result = false;

        if(this.props.policy.acceptedGuestType === "AdultOnly"){
            result = true;
        }

        return result;
    }

    /**
     * Returns the currently defined prepayment
     * policy from this policy document
     */
    getPrePaymentPolicyValue(){
        let result = "";

        if(typeof this.props.policy.prepaymentPolicy === 'string'){
            result = this.props.policy.prepaymentPolicy;
        }

        return result;
    }

    /**
     * Returns the option list for the guarantee
     * policy which can only be a single policy
     */
    getGuaranteePolicyOptionList(){
        let result = [];

        if(typeof this.props.policy.guaranteePolicy === 'object'
            && this.props.policy.guaranteePolicy !== null){
            result = [this.props.policy.guaranteePolicy];
        }

        return result;
    }

    /**
     * Returns the name of this policy if any
     * was defined or returns an empty string
     */
    getPolicyName(){
        let result = "";

        if(typeof this.props.policy.name === 'string'){
            result = this.props.policy.name;
        }

        return result;
    }

    /**
     * Renders this component and all the
     * data fields to edit the policy document
     */
    render(){
        let policy = this.props.policy;

        /* ensure the policy has a default pets policy */
        if(!policy.hasOwnProperty("petsPolicy")){
            policy.petsPolicy = {
                allowed: false,
                byArrangement: false,
                freeOfCharge: false
            };
        }

        return(
            <>
                <EditorSection title={Culture.getText('PROPERTY_POLICY_NAME_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_NAME_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_NAME_HELPTEXT')}>
                    <DataInputField title={Culture.getText('PROPERTY_POLICY_NAME')}
                        value={this.getPolicyName()} onChange={(value) => {
                            policy.name = value;
                            this.notifyPolicyUpdate(policy);
                    }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_CHECKINOUTTIME_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_CHECKINOUTTIME_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_CHECKINOUTTIME_HELPTEXT')}>
                    <DataInputTime title={Culture.getText('PROPERTY_POLICY_CHECKINTIME')} value={policy.checkInTime} 
                        onChange={(value) => { policy.checkInTime = value; this.notifyPolicyUpdate(policy); }} />

                    <DataInputTime title={Culture.getText('PROPERTY_POLICY_CHECKOUTTIME')} value={policy.checkOutTime} 
                        onChange={(value) => { policy.checkOutTime = value; this.notifyPolicyUpdate(policy); }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_ADVANCEBOOKING_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_ADVANCEBOOKING_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_ADVANCEBOOKING_HELPTEXT')}>
                    <DataInputField title={Culture.getText('PROPERTY_POLICY_ADVANCEBOOKINGMINDAYS')} 
                        value={Math.floor(policy.advanceBookingMin/86400)} 
                        onChange={(value) => { 
                            if(parseInt(value) < 0 || isNaN(parseInt(value)) === true){ value = 0; }
                            policy.advanceBookingMin = Math.floor(parseInt(value)*86400); 

                            /* the max days cannot be higher or equal than the min days */
                            if(policy.advanceBookingMin >= 3600 && policy.advanceBookingMin <= policy.advanceBookingMax){
                                policy.advanceBookingMax = policy.advanceBookingMin-3600;
                            }

                            /* set the prebooking to at least 1 day */
                            if(policy.advanceBookingMax < 3600){
                                policy.advanceBookingMax = 3600;
                            }

                            this.notifyPolicyUpdate(policy); 
                    }} />

                    <DataInputField title={Culture.getText('PROPERTY_POLICY_ADVANCEBOOKINGMAXDAYS')} 
                        value={Math.floor(policy.advanceBookingMax/86400)} 
                        onChange={(value) => { 
                            if(parseInt(value) < 0 || isNaN(parseInt(value)) === true){ value = 0; }
                            policy.advanceBookingMax = Math.floor(parseInt(value)*86400); 

                            /* the max days cannot be higher or equal than the min days */
                            if(policy.advanceBookingMax >= 3600 && policy.advanceBookingMin <= policy.advanceBookingMax){
                                policy.advanceBookingMax = policy.advanceBookingMin-3600;
                            }

                            this.notifyPolicyUpdate(policy); 
                    }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_ADULTSONLY_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_ADULTSONLY_SUBTITLE')}
                    introText={Culture.getText('PROPERTY_POLICY_ADULTSONLY_INTROTEXT')}
                    helpText={Culture.getText('PROPERTY_POLICY_ADULTSONLY_HELPTEXT')}>
                    <DataInputCheckBox title={Culture.getText('PROPERTY_POLICY_ADULTSONLY')} 
                            checked={this.isAdultsOnly()} onClick={() => {
                            /* toggle the accepted guest type */
                            if(policy.acceptedGuestType === "AdultOnly"){
                                policy.acceptedGuestType = "ChildrenAllowed";
                            }else{
                                policy.acceptedGuestType = "AdultOnly";
                            }
                            this.notifyPolicyUpdate(policy);
                    }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_PETSPOLICY_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_PETSPOLICY_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_PETSPOLICY_HELPTEXT')}>
                    <DataInputCheckBox title={Culture.getText('PROPERTY_POLICY_PETSALLOWED')} 
                        checked={policy.petsPolicy.allowed} onClick={() => {
                        policy.petsPolicy.allowed = !policy.petsPolicy.allowed;
                        if(policy.petsPolicy.allowed === false){
                            policy.petsPolicy.byArrangement = false;
                            policy.petsPolicy.freeOfCharge = false;
                        }
                        this.notifyPolicyUpdate(policy); 
                    }} />

                    <DataInputCheckBox title={Culture.getText('PROPERTY_POLICY_PETSBYARRANGEMENT')} 
                        disabled={!policy.petsPolicy.allowed}
                        checked={policy.petsPolicy.byArrangement} onClick={() => {
                        policy.petsPolicy.byArrangement = !policy.petsPolicy.byArrangement;
                        this.notifyPolicyUpdate(policy); 
                    }} />

                    <DataInputCheckBox title={Culture.getText('PROPERTY_POLICY_PETSFREEOFCHARGE')}
                        disabled={!policy.petsPolicy.allowed} 
                        checked={policy.petsPolicy.freeOfCharge} onClick={() => {
                        policy.petsPolicy.freeOfCharge = !policy.petsPolicy.freeOfCharge;
                        this.notifyPolicyUpdate(policy); 
                    }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_PREPAYMENT_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_PREPAYMENT_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_PREPAYMENT_HELPTEXT')}>
                    <HelpArticleView article="payment_enable" />

                    <DataInputList title={Culture.getText('PROPERTY_POLICY_PREPAYMENT')} 
                        value={this.getPrePaymentPolicyValue()} 
                        list={[
                            {value: "", text: Culture.getText('PROPERTY_POLICY_PREPAYMENT_NONE')},
                            {value: "after_reservation_is_made", text: Culture.getText('PROPERTY_POLICY_PREPAYMENT_AFTERRESERVATIONISMADE')},
                            {value: "after_cancellation_fee_begins", text: Culture.getText('PROPERTY_POLICY_PREPAYMENT_WHENCANCELLATIONFEEBEGINS')}
                        ]} onChange={(value) => {
                            policy.prepaymentPolicy = value;
                            this.notifyPolicyUpdate(policy);
                    }}/>
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_GUARANTEEPOLICY_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_GUARANTEEPOLICY_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_GUARANTEEPOLICY_HELPTEXT')}>
                    <CancellationPolicyInput title={Culture.getText('PROPERTY_POLICY_GUARANTEEPOLICY')}
                        addButtonText={Culture.getText('PROPERTY_POLICY_SELECTGUARANTEEPOLICY')}
                        titleText={Culture.getText('PROPERTY_POLICY_GUARANTEEPOLICY_TITLE')}
                        subtitleText={Culture.getText('PROPERTY_POLICY_SELECTGUARANTEEPOLICY')}
                        emptyText={Culture.getText('PROPERTY_POLICY_NOGUARANTEEPOLICY')}
                        list={this.getGuaranteePolicyOptionList()} onChange={(list) => {
                            if(Array.isArray(list)){
                                if(list.length > 0){
                                    policy.guaranteePolicy = list[list.length-1];
                                }else{
                                    policy.guaranteePolicy = null;
                                }
                            }
                            this.notifyPolicyUpdate(policy);
                    }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_CANCELLATIONPOLICY_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_CANCELLATIONPOLICY_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_CANCELLATIONPOLICY_HELPTEXT')}>
                    <CancellationPolicyInput title={Culture.getText('PROPERTY_POLICY_CANCELLATIONPOLICY')}
                        addButtonText={Culture.getText('PROPERTY_CANCELLATIONPOLICY_ADD')}
                        emptyText={Culture.getText('PROPERTY_CANCELLATIONPOLICY_DEFAULT')}
                        list={policy.cancellationPolicyList} onChange={(list) => {
                            policy.cancellationPolicyList = list;
                            this.notifyPolicyUpdate(policy);
                    }} />
                </EditorSection>

                <EditorSection title={Culture.getText('PROPERTY_POLICY_FEETAX_TITLE')}
                    subtitle={Culture.getText('PROPERTY_POLICY_FEETAX_SUBTITLE')}
                    helpText={Culture.getText('PROPERTY_POLICY_FEETAX_HELPTEXT')}>
                    <FeeTaxPolicyInput isTax={true} property={this.props.property}
                        title={Culture.getText('PROPERTY_POLICY_TAXPOLICIES')}
                        addTitleText={Culture.getText('PROPERTY_POLICY_ADDTAXPOLICY_TITLE')}
                        addSubtitleText={Culture.getText('PROPERTY_POLICY_ADDTAXPOLICY_SUBTITLE')}
                        addButtonText={Culture.getText('PROPERTY_POLICY_ADDTAXPOLICY')}
                        editButtonText={Culture.getText('PROPERTY_POLICY_EDITTAXPOLICY')}
                        editTitleText={Culture.getText('PROPERTY_POLICY_EDITTAXPOLICY_TITLE')}
                        editSubtitleText={Culture.getText('PROPERTY_POLICY_EDITTAXPOLICY_SUBTITLE')}
                        emptyListText={Culture.getText('PROPERTY_POLICY_EMPTYTAXLIST')}
                        list={policy.taxPolicyList} onChange={(list) => {
                            policy.taxPolicyList = list;
                            this.notifyPolicyUpdate(policy);
                    }} />

                    <FeeTaxPolicyInput isTax={false} property={this.props.property}
                        title={Culture.getText('PROPERTY_POLICY_FEEPOLICIES')}
                        addTitleText={Culture.getText('PROPERTY_POLICY_ADDFEEPOLICY_TITLE')}
                        addSubtitleText={Culture.getText('PROPERTY_POLICY_ADDFEEPOLICY_SUBTITLE')}
                        addButtonText={Culture.getText('PROPERTY_POLICY_ADDFEEPOLICY')}
                        editTitleText={Culture.getText('PROPERTY_POLICY_EDITFEEPOLICY_TITLE')}
                        editSubtitleText={Culture.getText('PROPERTY_POLICY_EDITFEEPOLICY_SUBTITLE')}
                        editButtonText={Culture.getText('PROPERTY_POLICY_EDITFEEPOLICY')}
                        emptyListText={Culture.getText('PROPERTY_POLICY_EMPTYFEELIST')}
                        list={policy.feePolicyList} onChange={(list) => {
                            policy.feePolicyList = list;
                            this.notifyPolicyUpdate(policy);
                    }} />
                </EditorSection>
            </>
        );
    }
}

export default PolicyDocumentEditor;