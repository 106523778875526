/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/**
 * Defines the configuration for the authentication
 * providers like Microsoft or Google to provide
 * authentication for the user
 */
module.exports = {
    microsoft: {
        clientId: '8210d6e0-a143-4343-978a-40c9f987a848',
        scopeList: [ 'user.read' ]
    },
    google: {
        clientId: '275306733918-9g9qi9goq15okdlna70gveaepu15v8ev.apps.googleusercontent.com'
    }
};