/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';

import '../../style/DataInputField.css';

/**
 * Provides an input component that allows
 * to insert percentage values
 */
class DataInputPercent extends React.Component {
    state = {
        valueText: ""
    }

    /**
     * Assigns the float values to the state
     * as a string upon mount of this component
     * to have the values properly initialised
     * within the input field
     */
    componentDidMount(){
        if(typeof this.props.value === 'number'){
            if(this.props.value > 0){
                this.setState({valueText: Culture.formatFloatToString(this.props.value)});
            }
        }
    }

    /**
     * Changes the actual float value for the
     * percentage and notifies the parent about the
     * change. Note that this method will not
     * notify any parent component if the percentage
     * or value inserted is not a valid float
     * 
     * @param {object} eventObject
     * the event object provided by the input component 
     */
    changeValue(eventObject){
        let formattedValue = eventObject.target.value.replace(/[^0-9.,]/gi,'');

        this.setState({ valueText: formattedValue });
        if(typeof this.props.onChange === 'function'){
            let updatedValue = Culture.stringToFloatLocalised(formattedValue);
            if(formattedValue === ""){updatedValue = 0;}
            this.props.onChange(updatedValue);
        }
    }

    /**
     * Renders the percent input component
     * and handles the changes to the component
     */
    render(){
        let classList = ['DataInputField','DataInputPercent'];
        let inputDisabled = false;
        if(this.props.disabled === true){
            classList.push('DataInputPercentDisabled');
            inputDisabled = true;
        }

        return(
            <div className={classList.join(' ')}>
                <div className="DataInputFieldTitle">{this.props.title}</div>
                <div className="DataInputPercentField">
                    <input className="DataInputFieldPercentValue" type="text" 
                        value={this.state.valueText}
                        onChange={this.changeValue.bind(this)} 
                        disabled={inputDisabled}
                        maxLength={9}
                        placeholder="—" />
                </div>
                {typeof this.props.errorText === 'string' && this.props.errorText !== '' &&
                    <div className="DataInputFieldErrorText">
                        {this.props.errorText}
                    </div>
                }
            </div>
        );      
    }
}

export default DataInputPercent;