/**
 * (C) 2023 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../class/Culture';

/* include the style for this component */
import '../../style/MarketplaceSearchInput.css'
import '../../style/OptionListSelect.css';

/* include all components required in this component */
import LoadingIndicator from '../common/LoadingIndicator';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import OptionListSelect from '../common/OptionListSelect';
import MarketplaceFilterInput from './MarketplaceFilterInput';
import MarketplaceGeofenceEditor from './MarketplaceGeofenceEditor';

/* include all filter editor windows */
import MarketplaceStarRatingFilter from './filter/MarketplaceStarRatingFilter';
import MarketplaceOccupancyFilter from './filter/MarketplaceOccupancyFilter';
import MarketplaceServiceFilter from './filter/MarketplaceServiceFilter';
import MarketplaceLanguageFilter from './filter/MarketplaceLanguageFilter';
import MarketplaceCategoryFilter from './filter/MarketplaceCategoryFilter';
import MarketplacePaymentMethodFilter from './filter/MarketplacePaymentMethodFilter';
import MarketplaceReviewFilter from './filter/MarketplaceReviewFilter';
import MarketplaceUnitAmenityFilter from './filter/MarketplaceUnitAmenityFilter';
import MarketplaceUnitTypeFilter from './filter/MarketplaceUnitTypeFilter';

/* require geojson to calculate the size of the area */
const geojsonArea = require('@mapbox/geojson-area');

/* require the marketplace configuration files */
const MarketplaceCountryList = require('../../config/marketplace/MarketplaceCountryList.json');

/**
 * This class provides the search and filter input
 * controls to search for marketplace properties
 */
class MarketplaceSearchInput extends React.Component {
    state = {
        showGeofenceView: false,
        showFilterView: false,
        showFilterTypeCode: false
    };

    /**
     * Returns the currently defined search
     * query or the default search query
     */
    getSearchQuery(){
        return Object.assign({},(this.props.value || {}));
    }

    /**
     * Notifies the parent component about the
     * update of the search query 
     */
    notifyUpdate(){
        let requestObject = Object.assign({}, this.state.searchQuery);
        for (const parameter of Object.keys(requestObject)) {
            if (typeof requestObject[parameter] === 'string'
                && requestObject[parameter] === '') {
                /* delete any empty string in the query */
                delete requestObject[parameter];
            }
        }

        if (typeof this.props.onUpdate === 'function') {
            this.props.onUpdate(requestObject);
        }
    }
    
    /**
     * Updates a parameter in the search query
     * and notifies the parent about it
     * 
     * @param {string} parameterName
     * name of the search parameter to update
     *  
     * @param {any} parameterValue 
     * value to set for the search parameter
     */
    updateSearchQuery(parameterName,parameterValue){
        let searchQuery = Object.assign({}, this.getSearchQuery());

        let isEmptyParameter = false;
        if (typeof parameterName === 'string' && parameterName === '') {
            isEmptyParameter = true;
        } if (Array.isArray(parameterValue) && parameterValue.length === 0) {
            isEmptyParameter = true;
        }

        if(isEmptyParameter === false){
            searchQuery[parameterName] = parameterValue;
            this.setState({
                searchQuery: searchQuery,
                showGeofenceView: false
            },this.notifyUpdate.bind(this));
        }
    }

    /**
     * Returns the title text of the geofence
     * if any is present or the default text
     * when no fence is present
     */
    getGeofenceTitle(){
        let result = Culture.getText('MARKETPLACE_FILTER_GEOFENCE_NONE');

        if((this.getSearchQuery().geo || []).length > 0){
            const geoFenceList = this.getSearchQuery().geo;
            const geoFenceArea = Math.round(geojsonArea.geometry({
                type: 'Polygon',
                coordinates: [geoFenceList]
            }) || 0);

            /* show the area size in either sqm or sqkm */
            let geoFenceText = (new Intl.NumberFormat(Culture.getCultureCode()))
                            .format(Math.round(geoFenceArea)) + ' m²';
            if(geoFenceArea > 1000000){
                geoFenceText = (new Intl.NumberFormat(Culture.getCultureCode()))
                            .format(Math.round(geoFenceArea / 100)) + ' km²';
            }

            result = Culture.getText('MARKETPLACE_FILTER_GEOFENCE_NAME')
                    + ' (' + geoFenceText + ')';
        }

        return result;
    }

    /**
     * Renders the filter type editor
     * for the selected filter if any
     * is selected or renders nothing
     * when no filter is selected
     */
    renderFilterTypeEditor(){
        let result = null;

        if(typeof this.state.showFilterTypeCode === 'string' 
            && this.state.showFilterTypeCode !== ''){
            /* check which filter type editor to render */
            const filterTypeCode = this.state.showFilterTypeCode;
            if(filterTypeCode === 'starrating'){
                result = <MarketplaceStarRatingFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'occupancy'){
                result = <MarketplaceOccupancyFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'country'){
                result = this.renderCountryFilter();
            }if(filterTypeCode === 'service'){
                result = <MarketplaceServiceFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'language'){
                result = <MarketplaceLanguageFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'category'){
                result = <MarketplaceCategoryFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'payment'){
                result = <MarketplacePaymentMethodFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'guestreview'){
                result = <MarketplaceReviewFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'unitamenity'){
                result = <MarketplaceUnitAmenityFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }if(filterTypeCode === 'unittype'){
                result = <MarketplaceUnitTypeFilter 
                            value={(this.getSearchQuery() || {})}
                            onClose={() => this.setState({showFilterTypeCode: false})}
                            onChange={(value) => this.setState({
                                searchQuery: value,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this))} />;
            }
        }

        return result;
    }

    /**
     * Renders the country filter input
     * which is just a modal option select
     */
    renderCountryFilter(){
        return (
            <div className="ModalOptionListSelect">
                <ModalDrawerOverlay className="ModalOptionListDrawerOverlay"
                    onClose={() => this.setState({
                        showFilterTypeCode: false
                    },this.notifyUpdate.bind(this))}>
                    <OptionListSelect enableFilter={true}
                        filterPlaceholderText={Culture.getText('MARKETPLACE_FILTER_COUNTRY_SEARCH_PLACEHOLDER')}
                        list={(function(){
                            let result = [];

                            for(const item of MarketplaceCountryList){
                                result.push({
                                    value: item.toUpperCase(),
                                    text: Culture.getText('COUNTRY_'+item.toUpperCase()),
                                    checked: (item.toUpperCase() === (this.getSearchQuery() || {}).countryCode)
                                });
                            }

                            return result;
                        }).bind(this)()} 
                        onToggle={(item) => {
                            let selectedCountryCode = item.value.toUpperCase();
                            let searchQuery = (this.getSearchQuery() || {});
                            if(searchQuery.countryCode === selectedCountryCode){
                                delete searchQuery.countryCode;
                            }else{
                                searchQuery.countryCode = selectedCountryCode;
                            }

                            this.setState({
                                searchQuery: searchQuery,
                                showFilterTypeCode: false
                            },this.notifyUpdate.bind(this));
                        }} />
                </ModalDrawerOverlay>
            </div>
        );
    }

    /**
     * Renders all currently active filters
     */
    renderActiveFilterList(){
        let result = [];

        let searchQuery = (this.getSearchQuery() || {});
        if((searchQuery.minStarRating || 0) !== 0 || (searchQuery.maxStarRating || 5) !== 5){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_STARRATING_FILTERITEM')
                        .replace('{{VAR_1}}',(searchQuery.minStarRating || 0))
                        .replace('{{VAR_2}}',(searchQuery.maxStarRating || 5)),
                    'starrating',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'starrating'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.minStarRating;
                        delete searchQuery.maxStarRating;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.minOccupancy || 1) !== 1 || (searchQuery.maxOccupancy || 20) !== 20){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_TOTALOCCUPANCY')
                        .replace('{{VAR_1}}',(searchQuery.minOccupancy || 0))
                        .replace('{{VAR_2}}',(searchQuery.maxOccupancy || 5)),
                    'occupancy',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'occupancy'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.minOccupancy;
                        delete searchQuery.maxOccupancy;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.minAdultOccupancy || 1) !== 1 || (searchQuery.maxAdultOccupancy || 20) !== 20){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_ADULTOCCUPANCY')
                        .replace('{{VAR_1}}',(searchQuery.minAdultOccupancy || 0))
                        .replace('{{VAR_2}}',(searchQuery.maxAdultOccupancy || 5)),
                    'adultoccupancy',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'occupancy'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.minAdultOccupancy;
                        delete searchQuery.maxAdultOccupancy;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.minChildOccupancy || 0) !== 0 || (searchQuery.maxChildOccupancy || 20) !== 20){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_OCCUPANCY_CHILDOCCUPANCY')
                        .replace('{{VAR_1}}',(searchQuery.minChildOccupancy || 0))
                        .replace('{{VAR_2}}',(searchQuery.maxChildOccupancy || 5)),
                    'childoccupancy',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'occupancy'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.minChildOccupancy;
                        delete searchQuery.maxChildOccupancy;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if(typeof searchQuery.countryCode === 'string' && searchQuery.countryCode !== ''){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('COUNTRY_' + searchQuery.countryCode.toUpperCase()),
                    'country',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'country'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.countryCode;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.serviceList || []).length > 0){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_SERVICE_FILTERITEM')
                        .replace('{{VAR_1}}',searchQuery.serviceList.length),
                    'service',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'service'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.serviceList;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.languageList || []).length > 0){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_LANGUAGE_FILTERITEM')
                        .replace('{{VAR_1}}',searchQuery.languageList.length),
                    'language',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'language'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.languageList;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.categoryList || []).length > 0){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_CATEGORY_FILTERITEM')
                        .replace('{{VAR_1}}',searchQuery.categoryList.length),
                    'category',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'category'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.categoryList;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.paymentMethodList || []).length > 0){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_PAYMENTMETHOD_FILTERITEM')
                        .replace('{{VAR_1}}',searchQuery.paymentMethodList.length),
                    'payment',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'payment'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.paymentMethodList;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.minReview || 0) !== 0 || (searchQuery.maxReview || 20) !== 20){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_REVIEW')
                        .replace('{{VAR_1}}',(searchQuery.minReview || 0))
                        .replace('{{VAR_2}}',(searchQuery.maxReview || 100)),
                    'guestreview',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'guestreview'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.minReview;
                        delete searchQuery.maxReview;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.minRecommendation || 0) !== 0 || (searchQuery.maxRecommendation || 20) !== 20){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_RECOMMENDATION')
                        .replace('{{VAR_1}}',(searchQuery.minRecommendation || 0))
                        .replace('{{VAR_2}}',(searchQuery.maxRecommendation || 100)),
                    'guestrecommendation',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'guestreview'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.minRecommendation;
                        delete searchQuery.maxRecommendation;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.unitAmenityList || []).length > 0){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_UNITAMENITY_FILTERITEM')
                        .replace('{{VAR_1}}',searchQuery.unitAmenityList.length),
                    'unitamenity',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'unitamenity'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.unitAmenityList;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }if((searchQuery.unitTypeList || []).length > 0){
            result.push(
                this.renderActiveFilterItem(
                    Culture.getText('MARKETPLACE_FILTER_UNITTYPE_FILTERITEM')
                        .replace('{{VAR_1}}',searchQuery.unitTypeList.length),
                    'unittype',
                    (function(){
                        this.setState({
                            showFilterTypeCode: 'unittype'
                        });
                    }).bind(this),
                    (function(){
                        delete searchQuery.unitTypeList;
                        this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                    }).bind(this)
                )
            );
        }

        return result;
    }

    /**
     * Returns true when a filter item is
     * active and false if none is active
     */
    hasActiveFilterItem(){
        let searchQuery = Object.assign({},(this.getSearchQuery() || {}));
        delete searchQuery.searchText;
        delete searchQuery.sortBy;
        return (Object.keys(searchQuery).length > 0);
    }

    /**
     * Renders an active filer item and returns
     * the rendered code as JSX
     * 
     * @param {string} title
     * title to display for the filter
     *  
     * @param {string} filterTypeCode 
     * code of the filter type
     * 
     * @param {function} onClick
     * handler for when the filter is clicked
     *  
     * @param {function} onRemove 
     * handler for when the filter is removed
     */
    renderActiveFilterItem(title,filterTypeCode,onClick,onRemove){
        return(
            <div key={title}
                className="MarketplaceSearchInputFilterItem" 
                data-filtertype={filterTypeCode}
                data-enabled={true}
                onClick={(function(e){
                    if(e.target.className !== 'MarketplaceSearchInputFilterItemRemove'){
                        onClick();
                    }
                })}>
                {title}
                <div className="MarketplaceSearchInputFilterItemRemove" 
                        onClick={onRemove}></div>
            </div>
        );
    }

    /**
     * Renders the search input controls that
     * allow to search for properties and apply
     * the desired filters for the search
     */
    render() {
        /* get the search query or an empty object if undefined */
        let searchQuery = (this.getSearchQuery() || {});
        return(
            <>
                <div className="MarketplaceSearchInput">
                    <div className="MarketplaceSearchInputBar">
                        <div className="MarketplaceSearchInputFilterAddButton"
                            onClick={() => this.setState({showFilterView: true})}>
                            {Culture.getText('MARKETPLACE_FILTER_ADDBUTTON')}
                        </div>

                        <div className="MarketplaceSearchInputBarTextField" 
                            data-loading={(this.props.isLoading === true)}>
                            {this.props.isLoading === true &&
                                <LoadingIndicator />
                            }
                            <input type="text" value={this.state.searchText}
                                placeholder={Culture.getText('MARKETPLACE_SEARCHTEXT_PLACEHOLDER')}
                                onChange={(function(e){
                                    this.updateSearchQuery('searchText', e.target.value);
                                }).bind(this)} 
                            />
                        </div>
                    </div>

                    {this.hasActiveFilterItem() === true &&
                        <div className="MarketplaceSearchInputFilter">
                            {((searchQuery.geo || []).length > 0) &&
                                <div className="MarketplaceSearchInputFilterItem" 
                                    data-filtertype="geofence"
                                    data-enabled={((searchQuery.geo || []).length > 0)}
                                    onClick={(function(e){
                                        if(e.target.className !== 'MarketplaceSearchInputFilterItemRemove'){
                                            this.setState({ showGeofenceView: true });
                                        }
                                    }).bind(this)}>
                                    {this.getGeofenceTitle()}
                                    <div className="MarketplaceSearchInputFilterItemRemove"
                                        onClick={(function(){
                                            delete searchQuery.geo;
                                            this.setState({searchQuery: searchQuery},this.notifyUpdate.bind(this));
                                        }).bind(this)}></div>
                                </div>
                            }
    
                            {this.renderActiveFilterList()}
                        </div>
                    }
                </div>

                <div className="MarketplaceSearchInputBarListHeader"
                    data-resultcount={this.props.totalPropertyCount}>
                    <div className="MarketplaceSearchInputBarResultCount">
                        {this.props.totalPropertyCount < 0 && Culture.getText('MARKETPLACE_TOTALPROPERTYCOUNT_LOADING')}
                        {this.props.totalPropertyCount === 0 && Culture.getText('MARKETPLACE_TOTALPROPERTYCOUNT_NONE')}
                        {this.props.totalPropertyCount > 0 && 
                            Culture.getText('MARKETPLACE_TOTALPROPERTYCOUNT')
                                .replace('{{VAR_1}}',(new Intl.NumberFormat(Culture.getCultureCode()))
                                                    .format(Math.round(this.props.totalPropertyCount)))}
                    </div>

                    <div className="MarketplaceSearchInputBarSortSelect">
                        <select value={(searchQuery.sortBy || 'recommendation')}
                            onChange={(e) => {
                                this.updateSearchQuery('sortBy', e.target.value);
                            }}>
                            {(function(){
                                let result = [];

                                const sortList = ['recommendation','review','commission'];
                                for(const sortOption of sortList){
                                    result.push(
                                        <option key={sortOption} value={sortOption}>
                                            {Culture.getText('MARKETPLACE_SORTOPTION_'+sortOption.toUpperCase())}
                                        </option>
                                    );
                                }
                                
                                return result;
                            })()}
                        </select>
                    </div>
                </div>

                {this.state.showFilterView === true &&
                    <MarketplaceFilterInput value={this.getSearchQuery() || {}}
                        onClose={() => this.setState({ showFilterView: false })}
                        onSelect={(value) => {
                            if(value === 'geofence'){
                                this.setState({
                                    showGeofenceView: true,
                                    showFilterView: false
                                });
                            }else{
                                this.setState({
                                    showFilterTypeCode: value,
                                    showFilterView: false
                                });
                            }
                        }} />
                }

                {this.state.showGeofenceView === true &&
                    <MarketplaceGeofenceEditor value={this.getSearchQuery().geo || []}
                        onClose={() => this.setState({ showGeofenceView: false })}
                        onChange={(value) => this.updateSearchQuery('geo', value)} />
                }

                {this.renderFilterTypeEditor()}
            </>
        );
    }
}

export default MarketplaceSearchInput;