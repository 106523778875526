/**
 * (C) 2022 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

import ApiClient from '../../class/ApiClient';

class DataInputDocumentFile extends React.Component {
    /**
     * Constructs this component and creates
     * the reference for the file input
     */    
    constructor(){
        super();
        this.fileInput = React.createRef(null);
    }

    /**
     * Converts the provided array buffer
     * into a base64 string and returns it
     * 
     * @param {ArrayBuffer} buffer 
     * the array buffer to convert to base64
     */
     getBase64FromArrayBuffer(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[ i ]);
        }
        return window.btoa(binary);
    }

    /**
     * Handles the file selection and updates
     * the file in the state if the file is valid
     */
    async handleFileSelect(){
        const maxFileSize = (this.props.maxFileSize || 2000000);
        let fileTypeList = [];
        if(Array.isArray(this.props.fileTypeList)){
            fileTypeList = this.props.fileTypeList;
        }

        if(this.fileInput !== null){
            if(this.fileInput.current !== null){
                let fileList = this.fileInput.current.files;
                if(fileList.length > 0){
                    let fileObject = fileList[0];

                    let isAllowedFileType = false;
                    if(fileTypeList.includes(fileObject.type) === true
                        || fileTypeList.length === 0){
                        isAllowedFileType = true;
                    }

                    if(fileObject.size <= maxFileSize && isAllowedFileType === true){
                        let fileContent = await ApiClient.getFileContent(fileObject);
                        if(typeof this.props.onChange === 'function'){
                            this.props.onChange({
                                name: fileObject.name,
                                mimeType: fileObject.type,
                                content: this.getBase64FromArrayBuffer(fileContent)
                            });
                        }
                    }
                }
            }
        }
    }

    /**
     * Opens the file selection dialog
     * to pick a file for upload
     */
    selectMediaFile(){
        if(this.fileInput !== null){
            if(this.fileInput.current !== null){
                this.fileInput.current.click();
            }
        }
    }

    /**
     * Renders the file selection input
     * component and allows selecting the file
     */
    render() {
        return(
            <div className="DataInputField DataInputFieldDocumentFile">
                <div className="DataInputFieldTitle">{this.props.title}</div>
                {this.props.value === null &&
                    <div className="DataInputFieldDocumentFileSelect">
                        <div className="DataInputFieldDocumentFileSelectItem"
                            onClick={this.selectMediaFile.bind(this)}>
                            {this.props.selectFileTitle}
                        </div>
                    </div>
                }

                {this.props.value !== null &&
                    <div className="DataInputFieldDocumentFileSelect"
                        data-selected={true}>
                        <div className="DataInputFieldDocumentFileSelectItem"
                            onClick={this.selectMediaFile.bind(this)}>
                            {this.props.value.name}
                        </div>
                        <div className="DataInputFieldDocumentFileRemove" onClick={(function(){
                            if(typeof this.props.onChange === 'function'){
                                this.props.onChange(null);
                            }
                        }).bind(this)}></div>
                    </div>
                }

                <div className="DataInputFieldFileFormatInfo">
                    {this.props.fileFormatInfoText}
                </div>

                <input ref={this.fileInput} type="file" 
                    className="DataInputFieldDocumentFileSelectInput"
                    onChange={this.handleFileSelect.bind(this)} />
            </div>
        );
    }
}

export default DataInputDocumentFile;