/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import '../../style/QuotationView.css';

import Culture from '../../class/Culture';
import Quotation from '../../class/Quotation';
import Property from '../../class/Property';

/**
 * Class that represents the view component
 * for the quotation of a property
 */
class QuotationView extends React.Component {
    /**
     * Renders the cancellation policies, 
     * guarantee payment policies and
     * deadlines as well as the charges for
     * the provided quotation
     * 
     * @param {object} quotation 
     * the quotation object to render the
     * cancellation and guarantee payment
     * policies for
     */
    renderCancellationPolicy(quotation){
        let policyComponentList = [];

        /* check if any policies are defined and only
            render when there are any cancellation or
            guarantee policies */
        let hasCancellationPolicy = false;
        let hasGuaranteePolicy = false;
        if(typeof quotation.cancellationList === 'object' && quotation.cancellationList !== null){
            if(typeof quotation.cancellationList.afterReservation === 'object'
                && typeof quotation.cancellationList.beforeArrival === 'object'
                && quotation.cancellationList.afterReservation !== null
                && quotation.cancellationList.beforeArrival !== null){
                if(quotation.cancellationList.afterReservation !== false
                    || quotation.cancellationList.beforeArrival !== false){
                    hasCancellationPolicy = true;
                }
            }
        }if(typeof quotation.guaranteeList === 'object' && quotation.guaranteeList !== null){
            if(typeof quotation.guaranteeList.afterReservation === 'object'
                && typeof quotation.guaranteeList.beforeArrival === 'object'
                && quotation.guaranteeList.afterReservation !== null
                && quotation.guaranteeList.beforeArrival !== null){
                if(quotation.guaranteeList.afterReservation !== false
                    || quotation.cancellationList.beforeArrival !== false){
                        hasGuaranteePolicy = true;
                }
            }
        }

        let renderPolicy = function(policyList){
            let result = [];

            if(policyList.afterReservation !== false && policyList.afterReservation !== undefined){
                result.push(
                    <div key="QuotationReceiptPolicyAfterReservation" 
                        className="QuotationReceiptPolicyDeadline">
                        <div className="QuotationReceiptPolicyDeadlineTime">
                            {Culture.getText('QUOTATION_POLICY_CHARGEAFTERESERVATION_TITLE')}
                            {typeof policyList.afterReservation.percent === 'number'
                                && policyList.afterReservation.percent > 0 &&
                                <div className="QuotationReceiptPolicyDeadlineChargePercent">
                                    {policyList.afterReservation.percent} %
                                </div>
                            }
                        </div>
                        <div className="QuotationReceiptPolicyDeadlineCharge">
                            <div className="QuotationReceiptPolicyDeadlineChargeAmount">
                                {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                    style: 'currency',
                                    currency: quotation.price.currencyCode
                                })).format(policyList.afterReservation.amount)}
                            </div>
                        </div>
                    </div>
                );
            }

            if(Array.isArray(quotation.policy.cancellationPolicyList)){
                let cancellationPolicyList = quotation.policy.cancellationPolicyList;
                cancellationPolicyList.sort((a,b) => (a.deadlineHours < b.deadlineHours) 
                                    ? 1 : ((b.deadlineHours < a.deadlineHours) ? -1 : 0));

                let policyIndex = 0;
                for(const cancellationPolicyItem of cancellationPolicyList){
                    /* calculate the deadline date of this policy item */
                    let arrivalTimeStamp = (quotation.arrival * 1000);
                    let cancellationTimeStamp = (arrivalTimeStamp - (cancellationPolicyItem.deadlineHours*3600000));

                    /* calculate the cancellation charge fopr this policy item */
                    let cancellationChargeAmount = ((quotation.price.amount/quotation.lengthOfStay)
                                                    * cancellationPolicyItem.nightsAfterDeadline);
                    if(cancellationPolicyItem.percentAfterDeadline > 0){
                        cancellationChargeAmount = (quotation.price.amount * 
                            (cancellationPolicyItem.percentAfterDeadline/100));
                    }

                    result.push(
                        <div key={"QuotationReceiptPolicyBeforeArrival"+policyIndex}
                            className="QuotationReceiptPolicyDeadline">
                            <div className="QuotationReceiptPolicyDeadlineTime">
                                {(new Intl.DateTimeFormat(Culture.getCultureCode(),{
                                    dateStyle: 'full'
                                })).format(cancellationTimeStamp)}
                                {typeof policyList.beforeArrival.percent === 'number'
                                    && policyList.beforeArrival.percent > 0 &&
                                    <div className="QuotationReceiptPolicyDeadlineChargePercent">
                                        {policyList.beforeArrival.percent} %
                                    </div>
                                }
                            </div>
                            <div className="QuotationReceiptPolicyDeadlineCharge">
                                <div className="QuotationReceiptPolicyDeadlineChargeAmount">
                                    {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                        style: 'currency',
                                        currency: quotation.price.currencyCode
                                    })).format(cancellationChargeAmount)}
                                </div>
                            </div>
                        </div>
                    );

                    policyIndex++;
                }
            }

            return result;
        }

        if(hasCancellationPolicy === true || hasGuaranteePolicy === true){
            policyComponentList.push(
                <div key="QuotationReceiptPolicyList" className="QuotationReceiptPolicyList">
                    {hasCancellationPolicy === true &&
                        <div className="QuotationReceiptPolicyItem">
                            <div className="QuotationReceiptPolicyItemTitle">
                                {Culture.getText('QUOTATION_CANCELLATIONPOLICY_TITLE')}
                            </div>
                            <div className="QuotationReceiptPolicyItemContent">
                                {renderPolicy(quotation.cancellationList)}
                            </div>
                        </div>
                    }
                    {hasGuaranteePolicy === true &&
                        <div className="QuotationReceiptPolicyItem">
                            <div className="QuotationReceiptPolicyItemTitle">
                                {Culture.getText('QUOTATION_GUARANTEEPOLICY_TITLE')}
                            </div>
                            <div className="QuotationReceiptPolicyItemContent">
                                {renderPolicy(quotation.guaranteeList)}
                            </div>
                        </div>
                    }
                </div>
            );
        }

        return policyComponentList;
    }

    /**
     * Renders a fee or tax list and returns
     * the list of components as an array
     * 
     * @param {object} quotation
     * the quotation to render the fee or tax from
     * 
     * @param {string} listType 
     * the list type to render (feeList or taxList)
     * 
     * @param {string} chargeType 
     * the charge type to render (included or exclusive)
     */
    renderFeeTaxList(quotation,listType,chargeType){
        let result = [];

        let feeTaxCount = 0;
        for(const feeTaxObject of quotation.feeTaxList[listType]){
            if(feeTaxObject.chargeType === chargeType){
                result.push(
                    <div key={feeTaxCount} className="QuotationReceiptComponent">
                        <div className="QuotationReceiptComponentName">
                            {Culture.getText('PROPERTY_POLICY_FEETAXTYPENAME_'+feeTaxObject.typeCode)}
                        </div>
                        <div className="QuotationReceiptComponentAmount">
                            {(new Intl.NumberFormat(Culture.getCultureCode(), {
                                style: 'currency',
                                currency: feeTaxObject.calculated.currencyCode
                            })).format(feeTaxObject.calculated.amount)}
                        </div>
                    </div>
                );
                feeTaxCount++;
            }
        }

        return result;
    }

    /**
     * Renders the receipt with information
     * of the quotation with pricing, fees and taxes
     * 
     * @param {object} property 
     * property object the quotation is for
     * 
     * @param {object} quotation 
     * the quotation to render the receipt for 
     */
    renderReceiptView(property,quotation){
        /* determine the name of the unit */
        let unitName = '';
        for(const unitItem of property.getGuestRoomList()){
            if(unitItem.roomId === quotation.unitId){
                unitName = unitItem.name;
            }
        }

        let hasExcludedTaxFee = false;
        let excludedFeeList = this.renderFeeTaxList(quotation,'feeList','exclusive');
        let excludedTaxList = this.renderFeeTaxList(quotation,'taxList','exclusive');
        if(excludedFeeList.length > 0 || excludedTaxList.length > 0){
            hasExcludedTaxFee = true;
        }

        return(
            <div key="QuotationReceipt" className="QuotationReceipt">
                <div className="QuotationReceiptComponent">
                    <div className="QuotationReceiptComponentName">
                        {unitName}
                    </div>
                    <div className="QuotationReceiptComponentAmount">
                        {(new Intl.NumberFormat(Culture.getCultureCode(), {
                            style: 'currency',
                            currency: quotation.netPrice.currencyCode
                        })).format(quotation.netPrice.amount)}
                    </div>
                </div>

                {this.renderFeeTaxList(quotation,'feeList','included')}
                {this.renderFeeTaxList(quotation,'taxList','included')}

                <div className="QuotationReceiptComponent QuotationReceiptTotalPriceComponent">
                    <div className="QuotationReceiptComponentName">
                        {Culture.getText('QUOTATION_TOTALPRICE')}
                    </div>
                    <div className="QuotationReceiptComponentAmount">
                        {(new Intl.NumberFormat(Culture.getCultureCode(), {
                            style: 'currency',
                            currency: quotation.price.currencyCode
                        })).format(quotation.price.amount)}
                    </div>
                </div>

                {hasExcludedTaxFee === true &&
                    <div className="QuotationReceiptExcludedList">
                        <div className="QuotationReceiptExcludedTitle">
                            {Culture.getText('QUOTATION_EXCLUDED_FEETAX_TITLE')}
                        </div>
                        {excludedFeeList}
                        {excludedTaxList}
                    </div>
                }

                {this.renderCancellationPolicy(quotation)}
            </div>
        );
    }

    /**
     * Renders the quotation component with all
     * products, units and cost of the quotation
     */
    render(){
        let content = this.props.content;
        let quotation = new Quotation(content.quotation);
        let property = new Property(content.property);
        return this.renderReceiptView(property,quotation);
    }
}

export default QuotationView;