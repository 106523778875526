/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import ApiClient from '../../class/ApiClient';

import '../../style/MessageListEditor.css';

/* defines the minimum text length required for translation */
const TRANSLATION_MINIMUM_TEXTLENGTH = 5;

/**
 * This component provides the editing capability
 * for any text content which is represented as 
 * a multilingial message list data structure
 */
class MessageListEditor extends React.Component {
    state = {
        selectedLanguage: 'en',
        messageList: [],
        isTranslationInProgress: false,
        isTranslationCompleted: false
    }

    /**
     * Mounts the component and sets the values that
     * were define the props of this component for
     * initialisation
     */
    componentDidMount(){
        if(Array.isArray(this.props.languageList)){
            this.setState({selectedLanguage: this.props.languageList[0]});
        }

        if(typeof this.props.content === 'object'){
            if(Array.isArray(this.props.content)){
                this.setState({messageList: this.props.content});
            }
        }

        /* Attach the handler for culture changes
            as this component uses localised text */
        Culture.attachCultureComponent(this);
    }

    /**
     * Automatically translates this message list
     * by requesting the translation through the api
     */
    async translate(){
        if(Array.isArray(this.props.languageList)){
            /* activate the activity indicator */
            this.setState({isTranslationInProgress: true, isTranslationCompleted: false});

            /* build the request object for the translation */
            let translationRequest = {
                refinement: {
                    languageList: this.props.languageList,
                    translate: true
                },
                sourceLang: this.state.selectedLanguage,
                messageList: this.state.messageList
            };

            /* execute the api call for the translation operation */
            let translated = await ApiClient.execute('/refinement/messageList',translationRequest);

            /* check if the translation was successful */
            if(translated !== null && translated.failed === false){
                if(Array.isArray(translated.messageList)){
                    this.setState({
                        messageList: translated.messageList,
                        isTranslationInProgress: false,
                        isTranslationCompleted: true
                    });

                    /* notify the parent about the change */
                    if(typeof this.props.onChange === 'function'){
                        this.props.onChange(translated.messageList);
                    }
                }
            }else{
                /* set the current state to failed */
                this.setState({
                    isTranslationInProgress: false,
                    isTranslationCompleted: true
                });
            }
        }
    }

    /**
     * Switches the language for which the text
     * is currently shown and edited in this component
     * 
     * @param {string} code
     * the language code as string 
     */
    changeLanguage(code){
        this.setState({selectedLanguage: code});
    }

    /**
     * Returns true when the text for the provided
     * language exists and false if there is no
     * text for the provided language
     * 
     * @param {string} languageCode
     * the language code of the language to check 
     */
    languageTextExists(languageCode){
        let result = false;

        if(Array.isArray(this.state.messageList)){
            this.state.messageList.forEach((messageItem) => {
                if(messageItem.languageCode === languageCode){
                    if(typeof messageItem.text === 'string'){
                        if(messageItem.text.length > 3){
                            result = true;
                        }
                    }
                }
            });
        }

        return result;
    }

    /**
     * Renders the language list that allows switching
     * to the different languages of this message list
     */
    renderLanguageList(){
        let result = [];

        if(Array.isArray(this.props.languageList)){
            /* create the sortable array with the language code and name */
            let languageNameList = [];
            this.props.languageList.forEach((languageCode) => {
                languageNameList.push({
                    code: languageCode,
                    name: Culture.getText('OTA_LANGUAGE_NAME_'+languageCode.toUpperCase())
                });
            });

            /* sort the array of languages by the localised language name */
            languageNameList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            /* create the dom elementts for the language items */
            languageNameList.forEach((languageItem) => {
                let classList = ["MessageListLanguageItem"];
                if(languageItem.code === this.state.selectedLanguage){
                    classList.push("MessageListLanguageItemSelected");
                }

                /* append a class when the text exists to show the notification */
                if(this.languageTextExists(languageItem.code) === true){
                    classList.push("MessageListLanguageItemTextExists");
                }

                result.push(
                    <div key={languageItem.code} className={classList.join(' ')} onClick={this.changeLanguage.bind(this,languageItem.code)}>
                        <div className="MessageListLanguageItemName">{Culture.getText('OTA_LANGUAGE_NAME_'+languageItem.code.toUpperCase())}</div>
                    </div>
                );
            }); 
        }

        return result;
    }

    /**
     * Returns the text content for the
     * currently selected editing language
     */
    getTextContent(){
        let result = "";

        let languageCode = this.state.selectedLanguage;
        this.state.messageList.forEach((messageItem) => {
            if(messageItem.languageCode === languageCode
                && typeof messageItem.text === 'string'){
                result = messageItem.text;
            }
        });

        return result;
    }

    /**
     * Sets the text content for the currently
     * selected language and notifies the parent
     * component about this content update
     * 
     * @param {string} value
     * the text content to set for current language 
     */
    updateTextContent(value){
        if(typeof value === 'string' && this.state.isTranslationInProgress === false){
            let list = this.state.messageList;
            let languageExists = false;
            for(let i=0; i<list.length; i++){
                if(list[i].languageCode === this.state.selectedLanguage){
                    list[i].text = value;
                    languageExists = true;
                }
            }

            /* push the new text value if none exists
                in the message list already */
            if(!languageExists){
                list.push({
                    languageCode: this.state.selectedLanguage,
                    text: value
                });
            }

            /* update the local state of this component */
            this.setState({messageList: list, isTranslationCompleted: false});

            /* notify the parent about the change */
            if(typeof this.props.onChange === 'function'){
                this.props.onChange(list);
            }
        }
    }

    /**
     * Returns true when this is a multi-language
     * text and otherwise it will return false
     */
    isMultiLanguageText(){
        let result = false;

        if(Array.isArray(this.props.languageList)){
            if(this.props.languageList.length > 1){
                result = true;
            }
        }

        return result;
    }

    /**
     * Renders the automatic translation
     * input when it is available
     */
    renderAutomaticTranslation(){
        let result = [];

        /* get the text content to determine whether there
            is text that can be translated or not */
        let textContent = this.getTextContent();
        if(this.isMultiLanguageText() === true 
            && textContent.length >= TRANSLATION_MINIMUM_TEXTLENGTH
            && this.props.disableAutoTranslate !== true){
            /* return the translation component depending on whether
                the translation is currently in progress or not */
            if(this.state.isTranslationInProgress === true){
                result = (
                    <div className="MessageListEditorTranslationInProgress">
                        <div className="MessageListEditorTranslationInProgressText">
                            {Culture.getText('MESSAGELIST_TRANSLATION_IN_PROGRESS')}
                        </div>
                        <div className="MessageListEditorTranslationInProgressBar"></div>
                    </div>
                );
            }else{
                if(this.state.isTranslationCompleted === true){
                    result = (
                        <div className="MessageListEditorTranslate">
                            <div className="MessageListEditorTranslateCompleted">
                                {Culture.getText('MESSAGELIST_TRANSLATION_COMPLETED')}
                            </div>
                        </div>
                    );
                }else{
                    result = (
                        <div className="MessageListEditorTranslate">
                            <div className="MessageListEditorTranslateTitle">
                                {Culture.getText('MESSAGELIST_TRANSLATE_TITLE')}
                            </div>
                            <div className="MessageListEditorTranslateButton" onClick={this.translate.bind(this)}>
                                {Culture.getText('MESSAGELIST_TRANSLATE_LANGUAGETEXT')}
                            </div>
                        </div>
                    );
                }
            }
        }

        return result;
    }

    /**
     * Renders this component
     */
    render(){
        let textAreaDisabled = false;
        if(this.props.disabled === true){
            textAreaDisabled = true;
        }

        let classList = ['MessageListEditor'];
        if(typeof this.props.className === 'string' && this.props.className !== ''){
            classList.push(this.props.className);
        }

        return(
            <div className={classList.join(' ')} data-disabled={textAreaDisabled}>
                <div className="MessageListEditorTitle">{this.props.title}</div>
                <div className="MessageListBody">
                    <div className="MessageListLanguageList">
                        {this.renderLanguageList()}
                    </div>
                    <textarea data-languagecode={this.state.selectedLanguage}
                        value={this.getTextContent()} disabled={textAreaDisabled}
                        onChange={(e) => this.updateTextContent(e.target.value)} />
                    {this.renderAutomaticTranslation()}
                </div>
            </div>
        );
    }
}

export default MessageListEditor;