/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

/* define the published keys for the stripe library */
const STRIPE_PROD_KEY = 'pk_live_51IOKfGLlwNOZnWzX05yJJNy4r33l5M1Pe68VWy6ZmCtB40603pNT08puELsUAjTMlLptaXYGp8W8uI8dDdK3uhEK00sSSPfs1F';
const STRIPE_TEST_KEY = 'pk_test_51IOKfGLlwNOZnWzXwxARr9QftlDvjlNZhBfal4Jp2tDD4GpJ256VQrlsyRrTFJT5bofCRDGuwkYA2XKPNmF8TYxv00y8vTlGW1';

/**
 * Handles some basic payment processing
 * and management methods to coordinate
 * between the Stripe React SDK and the
 * server-side implementations
 */
class PaymentClient {
    /**
     * Returns the stripe key for production
     * or returns the test key if this frontend
     * is operated on a test site or locally
     */
    static getStipeKey(){
        let result = STRIPE_PROD_KEY;

        if(window.location.hostname === 'localhost' 
            || window.location.hostname.endsWith('uat.lodgea.net')){
            result = STRIPE_TEST_KEY;
        }

        return result;
    }    

    /**
     * Creates the payment method and
     * returns the result payment object
     * 
     * @param {string} customerName
     * name of the customer as provided
     * 
     * @param {string} customerEmail
     * email address of the customer
     * 
     * @param {string} clientSecret
     * the client secret for the card operation
     * 
     * @param {string} paymentTypeCode
     * the type code of the payment (card or sepa_debit)
     * 
     * @param {object} stripe 
     * the stripe sdk handle-object to use
     * 
     * @param {object} element 
     * the stripe element handle-object to use
     */
    static async getPaymentMethod(customerName,customerEmail,clientSecret,paymentTypeCode,stripe,element){
        let result = false;

        let response = null;
        if(paymentTypeCode === 'sepa_debit'){
            /* confirm the sepa debit payment method */
            response = await stripe.confirmSepaDebitSetup(clientSecret, {
                payment_method: {
                    sepa_debit: element,
                    billing_details: {
                        name: customerName,
                        email: customerEmail
                    }
                }
            });
        }else{
            /* authorize the card payment method */
            response = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {card: element}
            });
        }
      
        if(response.error){
            console.log('Failed to setup payment (Auth rejected)');
        }else{
            result = response.setupIntent.payment_method;
        }

        return result;
    }
}

export default PaymentClient;