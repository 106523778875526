/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the necessary core classes */
import Culture from '../../class/Culture';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import DataInputField from '../common/DataInputField';
import PropertySelectInput from '../inventory/PropertySelectInput';
import ManagementUser from '../../class/ManagementUser';

/**
 * This editor form allows creating and editing
 * opentravel connections and notifies the parent
 * about any changes to the configuration object
 * provided in the parameters
 */
class DIRS21ConnectionEditor extends GenericProviderConnectionEditor {
    /**
     * Notifies the parent object about
     * the change and attaches the provider
     * specific configuration values
     * 
     * @param {object} configObject 
     * the config object to notify update of
     */
    notifyUpdate(configObject){
        if(configObject.providerCode.indexOf('#') > 0){
            let userName = configObject.providerCode.split('#')[1];
            configObject.providerCode = ['opentravel',userName].join('#');
        }

        /* set the DIRS21 specific settings */
        configObject.remoteSystemCode = 'dirs21';
        configObject.pushReservation = false;

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(configObject);
        }
    }

    /**
     * Renders the component with all
     * the required fields for this provider
     */
    render() {
        /* get the currently active tenant code for the prefix */
        let tenantCode = ManagementUser.getCurrent().getActiveTenant();
        return (
            <div className="ProviderConnectionEditor">
                {this.props.editMode === false &&
                    <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERUSERNAME')}
                        value={this.getUserName()} onChange={(value) => this.updateConfigValue('userName',value)} />
                }

                {this.props.editMode === true &&
                    <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERUSERNAME')}
                        value={[tenantCode,this.getUserName()].join('$')} disabled={true} />
                }

                <DataInputField title={Culture.getText('CONNECTIVITY_PROVIDERPASSWORD')}
                    value={this.getPassword()} isPassword={true}
                    onChange={(value) => this.updateConfigValue('password',value)} />

                <PropertySelectInput title={Culture.getText('CONNECTIVITY_LINKEDPROPERTIES')}
                        tenantPrefix={tenantCode}
                        selected={this.getLinkedPropertyList()} 
                        onChange={this.updateLinkedProperty.bind(this)} />
            </div>
        );
    }
}

export default DIRS21ConnectionEditor;