/**
 * (C) 2020 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';
import Culture from '../../class/Culture';
import Property from '../../class/Property';

import DataInputField from '../common/DataInputField';
import DataInputList from '../common/DataInputList';
import ModalDrawerOverlay from '../common/ModalDrawerOverlay';
import StandardButton from '../common/StandardButton';

import '../../style/AttractionEditor.css';

/**
 * This component allows to manage and create
 * new attractions for the property supplied
 */
class AttractionEditor extends React.Component {
    state = {
        showCreateWindow: false,
        newAttractionTypeCode: 62, /* 62 = other attraction type */
        newAttractionName: "",
        newAttractionNameErrorText: "",
        newAttractionDistance: 0,
        newAttractionDistanceUnit: "meters"
    }

    /**
     * Returns the option list with all available
     * attraction types and their localised name
     */
    getAttractionTypeList(){
        let result = [];

        let attractionTypeList = require('../../config/ota/OTAAttractionCategoryCode.json');
        Object.keys(attractionTypeList).forEach((attractionTypeCode) => {
            result.push({
                value: attractionTypeCode,
                text: Culture.getText('PROPERTY_ATTRACTIONTYPENAME_'+attractionTypeCode)
            });
        });

        result.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });

        return result;
    }

    /**
     * Returns the option list with all
     * available distance units
     */
    getDistanceUnitList(){
        let result = [];

        let unitList = ['meters', 'kilometers', 'feet',' miles'];
        unitList.forEach((unitType) => {
            result.push({
                value: unitType,
                text: Culture.getText('DISTANCEUNIT_NAME_'+unitType.toUpperCase())
            });
        });

        return result;
    }

    /**
     * Adds a new attraction to the property
     * using the values available from the state
     */
    addNewAttraction(){
        if(this.state.newAttractionName.length < 1){
            this.setState({newAttractionNameErrorText: "LOCATION_ATTRACTION_NAME_REQUIRED"});
        }else{
            let property = new Property(this.props.property);
            let attractionList = property.getAttractionList();

            /* add the new attraction to the list */
            attractionList.push({
                typeCode: this.state.newAttractionTypeCode,
                name: this.state.newAttractionName,
                distance: this.state.newAttractionDistance,
                distanceUnit: this.state.newAttractionDistanceUnit
            });

            property.setAttractionList(attractionList);

            /* notify the parent component abou the updated property */
            if(typeof this.props.onPropertyUpdated === 'function'){
                this.props.onPropertyUpdated(property);
            }

            /* reset the state variables */
            this.setState({
                showCreateWindow: false,
                newAttractionTypeCode: 62, /* 62 = other attraction type */
                newAttractionName: "",
                newAttractionNameErrorText: "",
                newAttractionDistance: 0,
                newAttractionDistanceUnit: "meters"
            });
        }
    }

    /**
     * Removes the attraction at the index
     * 
     * @param {number} index
     * index of the attraction to remove 
     */
    removeAttraction(index){
        /* remove the attraction and notify the parent */
        let property = new Property(this.props.property);
        property.removeAttractionItem(index);
        if(typeof this.props.onPropertyUpdated === 'function'){
            this.props.onPropertyUpdated(property);
        }
    }

    /**
     * Renders the attraction list of this property
     * 
     * @param {array} attractionList
     * list of attractions to render 
     */
    renderList(attractionList){
        let result = [];

        let a = 0;
        attractionList.forEach((attraction) => {
            result.push(
                <div className="AttractionListItem" key={'attraction'+a}>
                    <div className="AttractionListItemName">{attraction.name}</div>

                    {typeof attraction.distance === 'number' && attraction.distance > 0 &&
                        <div className="AttractionListItemDistance">
                            {attraction.distance} {Culture.getText('DISTANCEUNIT_ABBREVIATION_'+attraction.distanceUnit.toUpperCase())}
                        </div>
                    }

                    <div className="AttractionListItemRemove" onClick={this.removeAttraction.bind(this,a)}></div>
                </div>
            );
            a++;
        });

        if(attractionList.length === 0){
            result.push(
                <div className="AttractionListEmptyText" key={'attractionempty'}>
                    {Culture.getText('PROPERTY_ATTRACTION_NONE')}
                </div>
            );
        }

        return result;
    }
    
    /**
     * Renders the component with the attraction
     * list as well as the editor to add new
     * attractions to the property
     */
    render(){
        let property = new Property(this.props.property);

        return(
            <div className="AttractionEditor">
                <div className="AttractionList">
                    {this.renderList(property.getAttractionList())}
                </div>

                <div>
                    <StandardButton className="LocationEditorAddAttraction"
                        text={Culture.getText('PROPERTY_LOCATION_ADDATTRACTION')} 
                        onClick={() => this.setState({showCreateWindow: true})} />
                </div>

                {this.state.showCreateWindow === true &&
                    <ModalDrawerOverlay onClose={() => this.setState({showCreateWindow: false})}
                        titleText={Culture.getText('PROPERTY_LOCATION_ADDATTRACTION_TITLE')}
                        subtitleText={Culture.getText('PROPERTY_LOCATION_ADDATTRACTION_SUBTITLE')}
                        submitButtonText={Culture.getText('PROPERTY_LOCATION_ADDATTRACTION')}
                        onSubmit={this.addNewAttraction.bind(this)}
                        submitDisabled={(this.state.newAttractionName === '')}>                        
                        <DataInputField required={true}
                            title={Culture.getText('PROPERTY_ATTRACTION_NAME')} 
                            value={this.state.newAttractionName} 
                            errorText={Culture.getText(this.state.newAttractionNameErrorText)}
                            onChange={(value) => this.setState({newAttractionName: value})} />

                        <DataInputList title={Culture.getText('PROPERTY_ATTRACTION_TYPE')} 
                            value={this.state.newAttractionTypeCode} 
                            list={this.getAttractionTypeList()} onChange={(value) => {
                                this.setState({newAttractionTypeCode: value});
                        }}/>

                        <DataInputField title={Culture.getText('PROPERTY_ATTRACTION_DISTANCE')} 
                            value={this.state.newAttractionDistance} 
                            onChange={(value) => {
                                if(value === ""){
                                    this.setState({newAttractionDistance: ""});
                                }else{
                                    let numberValue = parseInt(value);
                                    if(isNaN(numberValue) === false){
                                        this.setState({newAttractionDistance: numberValue});
                                    }
                                }
                            }} />

                        <DataInputList title={Culture.getText('PROPERTY_ATTRACTION_DISTANCE_UNIT')} 
                            value={this.state.newAttractionDistanceUnit} 
                            list={this.getDistanceUnitList()} onChange={(value) => {
                                this.setState({newAttractionDistanceUnit: value});
                        }}/>
                    </ModalDrawerOverlay>
                }
            </div>
        );
    }
}

export default AttractionEditor;