/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the necessary core classes */
import Culture from '../../class/Culture';

/* require the components for this component */
import GenericProviderConnectionEditor from './GenericProviderConnectionEditor';
import PropertySelectInput from '../inventory/PropertySelectInput';
import ManagementUser from '../../class/ManagementUser';

/* define the remote endpoint for the room raccon provider */
const ROOMRACCOON_REMOTEENDPOINT_URL = 'https://api.roomraccoon.com/api/';

/**
 * This editor form allows creating and editing
 * opentravel connections and notifies the parent
 * about any changes to the configuration object
 * provided in the parameters
 */
class RoomRaccoonConnectionEditor extends GenericProviderConnectionEditor {
    /**
     * Notifies the parent object about
     * the change and attaches the provider
     * specific configuration values
     * 
     * @param {object} configObject 
     * the config object to notify update of
     */
     notifyUpdate(configObject){
        /* set the RoomRaccoon-specific settings */
        configObject.providerCode = 'opentravel#_global_roomraccoon';
        configObject.remoteSystemCode = 'roomraccoon';
        configObject.pushReservation = true;
        configObject.remoteSoapTag = 'SOAP-ENV';
        configObject.requireTenantPrefixPush = true;
        configObject.remoteEndpointUrl = ROOMRACCOON_REMOTEENDPOINT_URL;
        configObject.password = false;

        if(typeof this.props.onChange === 'function'){
            this.props.onChange(configObject);
        }
    }

    /**
     * Renders the RoomRaccoon configuration
     * editing form with all required forms
     */
    render() {
        return (
            <div className="ProviderConnectionEditor">
                <PropertySelectInput title={Culture.getText('CONNECTIVITY_LINKEDPROPERTIES')}
                    tenantPrefix={ManagementUser.getCurrent().getActiveTenant()}
                    selected={this.getLinkedPropertyList()}
                    onChange={this.updateLinkedProperty.bind(this)} />
            </div>
        );
    }
}

export default RoomRaccoonConnectionEditor;