/**
 * (C) 2021 LODGEA GmbH
 * All Rights Reserved.
 * 
 * All information contained herein is, and remains
 * the property of LODGEA GmbH and its suppliers,
 * if any.  The intellectual and technical concepts 
 * contained herein are proprietary to LODGEA GmbH
 * and its suppliers and may be covered by EU 
 * and other Foreign Patents, patents in process, and 
 * are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction 
 * of this material is strictly forbidden unless prior 
 * written permission is obtained from LODGEA GmbH.
 */

import React from 'react';

/* require the classes for this component */
import Culture from '../../class/Culture';
import ManagementUser from '../../class/ManagementUser';

/**
 * Displays the endpoint for the channel
 * provided in the properties of this component
 */
class ConnectivityEndpointView extends React.Component {
    /**
     * Renders the view with the endpoint
     * for the provided connectivity service
     */
    render(){
        /* get the tenant code currently active */
        let tenantCode = ManagementUser.getCurrent().getActiveTenant();

        /* define the endpoint url to display */
        let endpointUrl = 'https://' + tenantCode + '.' 
            + this.props.channelCode + '.connect.lodgea.com';
        
        /* check if this is the UAT environment and show the UAT url */
        if(window.location.hostname.endsWith('.uat.lodgea.net') === true
            || window.location.hostname === 'localhost'
            || window.location.hostname === '127.0.0.1'){
            /* set the UAT url for this endpoint */
            endpointUrl = 'https://' + tenantCode + '.' 
                        + this.props.channelCode 
                        + '.connect.uat.lodgea.net';
        }

        /* check if there's a path part for this endpoint provided */
        if(typeof this.props.path === 'string' && this.props.path !== ''){
            endpointUrl = [endpointUrl,this.props.path].join('/');
        }

        /* return the rendered contents */
        return(
            <div className="ConnectivityEndpointView">
                <div className="ConnectivityEndpointTitle">
                    {Culture.getText('CONNECTIVITY_ENDPOINT_TITLE')}
                </div>
                <div className="ConnectivityEndpointValue">
                    {endpointUrl}
                    <div className="ConnectivityEndpointCopyButton" 
                        onClick={() => navigator.clipboard.writeText(endpointUrl)}>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConnectivityEndpointView;